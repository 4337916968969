import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, Checkbox, Input } from 'semantic-ui-react'
import { order } from '../services/Api'
import { loadStripe } from '@stripe/stripe-js'
import { toast } from 'react-toastify'
import { stripePublicKEY } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { Config } from '../config'
import ReactGA from 'react-ga'
const { termsUrl } = Config

export class PaymentButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: this.props.buttonText,
      buttonTextConfirm: this.props.buttonTextConfirm,
      checkout: false,
      checked: false,
      coupon: '',
    }
  }

  toggle = () => this.setState((prevState) => ({ checked: !prevState.checked }))
  componentDidMount() {
    var locale =
      window.storage && window.localStorage.getItem('RWJlocale')
        ? window.localStorage.getItem('RWJlocale')
        : 'en-us'
    this.setState({ locale })
  }
  makeOrder = async (body) => {
    ReactGA.event({ category: 'Service', action: 'Order started' })

    const { prices, serviceName } = this.props
    const locale = this.state.locale.split('-')[0]
    if (prices.length === 0) {
      return
    }

    body = {
      prices: prices.map((x) => ({
        id: x.id,
        type: x.type,
        nickname: x.nickname,
      })),
      serviceName,
      locale,
      allow_promotion_codes: true,
      coupon: this.state.coupon,
    }

    if (!this.state.checkout) {
      this.setState({ checkout: true })
      return
    }

    if (!this.state.checked) {
      toast.error(this.props.polyglot.t('signup.message.fields.terms'))
      return
    }

    this.setState({
      buttonText: <Icon loading name="circle notch"></Icon>,
    })

    const response = await order(body)
    if (response.ok) {
      var data = response.data
      if (data.redirect_url) {
        this.props.history.push(data.redirect_url)
      }
      var sessionId = data.id
      const stripe = await loadStripe(stripePublicKEY)
      const { error } = await stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId,
      })

      if (error.message) {
        this.props.handleError(error.message)
      }
    } else {
      let {
        data: { message },
      } = response
      if (message.includes('No such coupon')) {
        this.setState({
          couponError: this.props.polyglot.t(
            'services.coupon.couponNotFoundOrInvalid'
          ),
        })
      } else this.props.handleError(response)
    }
  }

  handleValue(e, key) {
    let { value } = e.target
    let newState = { ...this.state, [key]: value.toUpperCase() }
    if (value.length === 1) {
      newState = { ...newState, couponError: '' }
    }
    this.setState(newState)
  }

  render() {
    const { disabled } = this.props
    const { locale, checkout, coupon, couponError } = this.state
    var { polyglot } = this.props
    return (
      <Fragment>
        <Input
          className="bottom-10 coupon-input"
          name="coupon"
          placeholder={polyglot.t('services.coupon')}
          onChange={(e) => this.handleValue(e, 'coupon')}
          inverted
          value={coupon}
        />
        {couponError && coupon && (
          <div style={{ fontSize: '1.2em', color: 'red' }}>{couponError}</div>
        )}
        {!checkout && (
          <div
            className={
              'call-to-buy ' +
              (this.props.className ? this.props.className : '') +
              (disabled ? ' disabled' : '')
            }
            onClick={() => this.makeOrder()}
            style={{ margin: '0 auto', float: 'none' }}
            data-testid="register-button"
          >
            <span className={'action '}>
              <span className="button-text">{this.state.buttonText}</span>
            </span>
          </div>
        )}
        {checkout && (
          <Fragment>
            <div style={{ marginBottom: 5 }}>
              <Checkbox
                onChange={this.toggle}
                checked={this.state.checked}
                style={{ marginRight: '10px' }}
                data-testid="terms"
              />
              <span>
                {polyglot.t('signup.message')}{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={termsUrl[locale]}
                  style={{ textDecoration: 'underline' }}
                  data-testid="terms-checkbox"
                >
                  {polyglot.t('signup.terms')}
                </a>
              </span>
            </div>
            <div
              className={
                'call-to-buy ' +
                (this.props.className ? this.props.className : '') +
                (disabled ? ' disabled' : '')
              }
              style={{ margin: '0 auto', float: 'none' }}
              onClick={() => this.makeOrder()}
              data-testid="confirm-button"
            >
              <span className={'action '}>
                <span className="button-text">
                  {this.state.buttonTextConfirm}
                </span>
              </span>
            </div>
          </Fragment>
        )}{' '}
      </Fragment>
    )
  }
}

PaymentButton.propTypes = {
  buttonText: PropTypes.string,
  buttonTextConfirm: PropTypes.string,
  serviceName: PropTypes.string,
  className: PropTypes.string,
  prices: PropTypes.array,
  polyglot: PropTypes.object,
  history: PropTypes.object,
  handleError: PropTypes.func,
  disabled: PropTypes.bool,
}

PaymentButton = withPolyglot()(PaymentButton)
export default PaymentButton
