import React, { Component, Fragment, createRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Message } from 'semantic-ui-react'

import { withPolyglot } from '../../vendor/polyglot-react'

//
import {
  logFields,
  logFieldsRL,
  timeToMs,
  countFrames,
  handleUpload,
  holdingPoseScoreSide,
} from './analysis.utils'

import {
  captureVideoFrame,
  codeColors,
  countLines,
  defaultColor,
  imgFieldUrl,
} from './analysis.canvas.utils'
import CanvasDraw from '../../vendor/react-canvas-draw'
import AnalysisVideoCriterias from './AnalysisVideoCriterias'

class AnalysisVideo extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      isInvalid: 0,
      isPlayerPaused: true,
      fieldsData: {},
      brushColor: defaultColor,
      brushRadius: 3,
      currentTimeImageExists: false,
      stayOnVideo: false,
      noCacheKey: Math.random(),
      canvasLinesCount: null,
    }
    this.player = createRef()
    this.analysisVideoCriterias = createRef()
    this.screenshot = createRef()
    this.canvas = createRef()
  }
  componentDidMount() {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    // this.syncPlayerTime()
    //   if (Object.keys(this.props.screenshots).length === 0)
    //  this.generateScreenshots()
  }
  syncPlayerTime = () => {
    if (this.player.current)
      this.player.current.onseeked = () => {
        if (
          this.player?.current?.currentTime &&
          this.props?.currentTime !==
            this.player?.current?.currentTime.toFixed(4)
        )
          this.props.updateAnalysisState({
            currentTime: this.player.current.currentTime.toFixed(4),
          })
      }

    if (
      this.player.current &&
      this.player.current.currentTime === 0 &&
      this.props.currentTime
    ) {
      //   this.player.current.currentTime = parseFloat(this.props.currentTime)
    }
  }
  componentDidUpdate(prevProps) {
    let targetTime = this.props.currentTime
    if (~~targetTime === 0) return

    if (targetTime !== prevProps.currentTime) {
      this.player.current.currentTime = targetTime
    }

    if (this.player.current.currentTime === 0 && this.props.currentTime) {
      this.player.current.currentTime = parseFloat(this.props.currentTime)
    }

    if (this.props.currentFieldIndex !== prevProps.currentFieldIndex) {
      this.setState({ canvasLinesCount: null })
    }
  }
  resizeHandler = () => {
    const canvasWidth = this.videoContainer?.current?.clientWidth
    const canvasHeight = this.videoContainer?.current?.clientHeight
    this.setState({ canvasWidth, canvasHeight })
  }
  stepForward = (frames = 1) => {
    this.player.current.currentTime += (1 / 60) * frames

    this.props.updateAnalysisState({
      currentTime: this.player.current.currentTime.toFixed(4),
    })
  }
  stepBackward = (frames = 1) => {
    this.player.current.currentTime =
      this.player.current.currentTime - (1 / 60) * frames
    this.props.updateAnalysisState({
      currentTime: this.player.current.currentTime.toFixed(4),
    })
  }

  togglePlayPause() {
    if (this.player.current.paused) {
      this.player.current.play()
    } else this.player.current.pause()
    this.setState({ isPlayerPaused: this.player.current.paused })
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      //this.props.logTime()
    }
  }

  generateScreenshots = async () => {
    let { currentFieldIndex } = this.props

    let currentField = logFields[currentFieldIndex]

    let playerInitialTime = this.player.current?.currentTime
    if (!playerInitialTime) return

    await this.generateAndUploadAllScreenshotsForField({
      fieldName: currentField,
      playerInitialTime,
    })
  }

  progressTracker = (progress) => {
    let { currentFieldIndex } = this.props

    let currentField = logFields[currentFieldIndex]

    const { loaded, total } = progress

    let uploadPct = Math.round((loaded / total) * 100) - 1
    uploadPct = uploadPct < 0 ? 0 : uploadPct
    this.props.updateAnalysisState({
      uploadStatus: { [currentField]: uploadPct },
    })
  }

  gotoTime = (targetTime) => {
    return new Promise((resolve) => {
      this.player.current.currentTime = targetTime

      this.player.current.addEventListener('seeked', function () {
        return resolve(targetTime)
      })
    })
  }

  generateAndUploadScreenshot = async ({ playerTime, quality, fileName }) => {
    let { updateAnalysisState } = this.props

    await this.gotoTime(playerTime)
    let { blob } = captureVideoFrame({
      video: this.player.current,
      quality,
    })

    return await handleUpload({
      blob,
      fileName,
      setState: updateAnalysisState,
      progressTracker: this.progressTracker,
    })
  }

  generateAndUploadAllScreenshotsForField = async ({
    fieldName,
    playerInitialTime,
  }) => {
    let { currentFieldIndex } = this.props

    let currentField = logFields[currentFieldIndex]

    let { analysisData } = this.props

    let framesToSub, quality
    framesToSub = 2
    quality = 0.5
    let uploaded = 0

    uploaded += await this.generateAndUploadScreenshot({
      playerTime: playerInitialTime - framesToSub * (1 / 60),
      quality,
      fileName: `${analysisData.id}_${fieldName}${
        framesToSub ? '-' + framesToSub : ''
      }.jpg`,
    })

    framesToSub = 1
    quality = 0.5
    uploaded += await this.generateAndUploadScreenshot({
      playerTime: playerInitialTime - framesToSub * (1 / 60),
      quality,
      fileName: `${analysisData.id}_${fieldName}${
        framesToSub ? '-' + framesToSub : ''
      }.jpg`,
    })

    framesToSub = 0
    quality = 0.5
    uploaded += await this.generateAndUploadScreenshot({
      playerTime: playerInitialTime - framesToSub * (1 / 60),
      quality,
      fileName: `${analysisData.id}_${fieldName}${
        framesToSub ? '-' + framesToSub : ''
      }.jpg`,
    })

    if (uploaded === 3) {
      this.props.updateAnalysisState({
        uploadStatus: { [currentField]: true },
      })
    }
  }

  logTime = async (addHalfFrame) => {
    let {
      currentTime,
      updateAnalysisData,
      analysisData,
      updateAnalysisState,
      screenshots,
      currentFieldIndex,
    } = this.props

    let currentField = logFields[currentFieldIndex]
    let newFieldsData = analysisData
    let tms = timeToMs(currentTime)
    if (addHalfFrame) {
      tms += 8
    }

    let isTimelineField = logFieldsRL.includes(currentField) ? true : false
    //true : not cadence field

    newFieldsData[currentField] = tms
    let playerInitialTime = this.player.current.currentTime
    if (playerInitialTime === 0) return

    if (isTimelineField) {
      //capture 2 images en amont

      await this.generateAndUploadAllScreenshotsForField({
        fieldName: currentField,
        playerInitialTime,
      })

      screenshots[currentField] = `${analysisData.id}_${currentField}.jpg`
    }

    let computeFrames = true

    logFieldsRL.every((field) => {
      if (newFieldsData[field] === 0 || newFieldsData[field] === '') {
        computeFrames = false
        return false
      }
      computeFrames = true
      return true
    })

    let computedFramesFields = {}
    if (computeFrames) {
      computedFramesFields = countFrames(newFieldsData)

      newFieldsData.fallExecutionTimeL = holdingPoseScoreSide(
        computedFramesFields,
        'L'
      )
      newFieldsData.fallExecutionTimeR = holdingPoseScoreSide(
        computedFramesFields,
        'R'
      )
    }
    let noCacheKey = Math.random()

    this.setState({ noCacheKey })
    updateAnalysisData({
      ...newFieldsData,
      ...computedFramesFields,
      // screenshots,
    })
    updateAnalysisState({ screenshots, noCacheKey })
    //  this.props.setCurrentField(currentFieldIndex + 1)
  }

  currentTimeImage = () => {
    /*  if (this.state.stayOnVideo) {
      return false
    }
*/
    const { analysisData, currentTime } = this.props
    let currentimeField = null

    logFieldsRL.every((timeField) => {
      let timeFieldTime = analysisData[timeField]
      let currentTimeString = timeToMs(currentTime)
      if (timeFieldTime === currentTimeString) {
        currentimeField = timeField
        return false //arrete la boucle
      }

      return true
    })

    return currentimeField
  }

  call_lineCountTrigger = () => {
    let savedDrawings = this.canvas.current.getSaveData()
    let canvasLinesCount = countLines(savedDrawings)
    this.setState({ canvasLinesCount })
  }

  toggleStayOnVideo = () => {
    this.setState({ stayOnVideo: !this.state.stayOnVideo })
  }

  render() {
    const {
      polyglot,
      originalVideo,
      currentTime,
      analysisData,
      currentFieldIndex,
    } = this.props
    let currentField = logFields[currentFieldIndex]
    let currentTimeImage = this.currentTimeImage()
    let {
      noCacheKey,
      screenshotHeight,
      screenshotWidth,
      currentTimeImageExists,
      stayOnVideo,
    } = this.state

    return (
      <div>
        <div onKeyPress={this.handleKeyPress}>
          <Fragment>
            <p id="time"></p>
            <video
              className={'videoInsert'}
              id="v0"
              controls
              autobuffer="true"
              preload="true"
              playsInline={true}
              ref={this.player}
              style={{
                // height: '100%',
                // width: '100%',
                width: '800px',
                maxHeight: '85vh',
                display:
                  currentTimeImage && currentTimeImageExists && !stayOnVideo
                    ? 'none'
                    : 'block',
              }}
              crossOrigin={'anonymous'}
              onLoadedData={() => {
                if (!screenshotWidth) {
                  this.setState({
                    //  screenshotWidth: this.player.current.offsetWidth,
                    //screenshotHeight: this.player.current.offsetHeight,
                  })
                }
              }}
            >
              <source type="video/mp4" src={originalVideo + '?nc='}></source>
            </video>
            <img
              style={{ position: 'absolute', left: 0, visibility: 'hidden' }}
              src={
                imgFieldUrl(analysisData.id, currentTimeImage) +
                '?nc=' +
                noCacheKey
              }
              alt=""
              ref={this.screenshot}
              onLoad={() => {
                this.setState({ currentTimeImageExists: true })
                if (!screenshotWidth) {
                  this.setState({
                    currentTimeImageExists: true,
                    screenshotWidth: this.screenshot?.current.offsetWidth,
                    screenshotHeight: this.screenshot?.current.offsetHeight,
                  })
                }
              }}
              //  onError={this.setState({ currentTimeImageExists: false })}
            />
            {!stayOnVideo && currentTimeImage && (
              /*screenshotWidth &&*/ <div
                onMouseUp={this.call_lineCountTrigger}
              >
                <CanvasDraw
                  ref={this.canvas}
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid red',
                    position: 'relative',
                    // visibility: screenshotWidth ? 'visible' : 'hidden',
                    display: screenshotWidth ? 'block' : 'none',
                    left: 0,
                  }}
                  //  imgSrc={this.player.current}
                  lazyRadius={0}
                  brushRadius={this.state.brushRadius}
                  brushColor={codeColors[this.state.brushColor]}
                  hideGrid={true}
                  gridColor="transparent"
                  imgSrc={
                    imgFieldUrl(analysisData.id, currentTimeImage) +
                    '?nc1=' +
                    noCacheKey
                  }
                  canvasWidth={screenshotWidth}
                  canvasHeight={screenshotHeight}
                  enablePanAndZoom
                  clampLinesToDocument
                  catenaryColor="red"
                />
              </div>
            )}
            <div className={'top-5'}>
              <Button.Group>
                <Button onClick={() => this.togglePlayPause()}>
                  <Icon name={this.state.isPlayerPaused ? 'play' : 'pause'} />
                </Button>

                <Button onClick={() => this.stepBackward(10)}>
                  <Icon name="fast backward" />
                </Button>
                <Button onClick={() => this.stepBackward()}>
                  <Icon name="step backward" />
                </Button>

                <Button
                  style={{
                    border: currentTimeImage ? '1px solid red' : 'none',
                  }}
                  onClick={() => this.logTime()}
                >
                  {currentTime}
                </Button>

                <Button onClick={() => this.stepForward()}>
                  <Icon name="step forward" />
                </Button>
                <Button onClick={() => this.stepForward(10)}>
                  <Icon name="fast forward" />
                </Button>
              </Button.Group>
              <Button.Group>
                {/*
                  <Button onClick={() => this.logTime(true)}>
                    <span style={{ fontSize: 8 }}>
                      1/2
                      <Icon name="step forward" />
                    </span>

                    <Icon name="save" />
					</Button>*/}
                <Button onClick={() => this.generateScreenshots()}>
                  <Icon name="images" />
                </Button>
              </Button.Group>
              <Button.Group>
                <Button
                  onClick={this.toggleStayOnVideo}
                  active={!this.state.stayOnVideo}
                  basic={this.state.stayOnVideo}
                  style={
                    this.state.stayOnVideo ? { border: '1px solid grey' } : {}
                  }
                >
                  <Icon name="random" />
                </Button>
              </Button.Group>
            </div>
            {!stayOnVideo &&
              currentTimeImage &&
              screenshotWidth &&
              this.canvas.current && (
                <AnalysisVideoCriterias
                  canvas={this.canvas.current}
                  setParentState={this.setState}
                  // ref={this.analysisVideoCriterias}
                  canvasLinesCount={this.state.canvasLinesCount}
                  updateAnalysisData={this.props.updateAnalysisData}
                  updateAnalysisState={this.props.updateAnalysisState}
                  {...this.props}
                />
              )}
            <Message compact style={{ textAlign: 'left' }}>
              <Message.Header>Analysis cue</Message.Header>
              {polyglot.t(`analysis.cues.${currentField}`)}
            </Message>
          </Fragment>
        </div>
        {/* {originalVideo}*/}
      </div>
    )
  }
}
AnalysisVideo.propTypes = {
  polyglot: PropTypes.object,
  originalVideo: PropTypes.string,
  currentTime: PropTypes.string,
  updateAnalysisData: PropTypes.func,
  updateAnalysisState: PropTypes.func,
  analysisData: PropTypes.object,
  setCurrentField: PropTypes.func,
  currentFieldIndex: PropTypes.number,
  logTime: PropTypes.func,
  screenshots: PropTypes.object,
  handleRadio: PropTypes.func,
}
AnalysisVideo = withPolyglot()(AnalysisVideo)
export default AnalysisVideo
