import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { withPolyglot } from '../vendor/polyglot-react'
import { Icon, Button } from 'semantic-ui-react'
//import CanvasDraw from 'react-canvas-draw'
import CanvasDraw from '../vendor/react-canvas-draw'
import { logFieldsRL } from './Analysis/analysis.utils'
import {
  delay,
  drawLinesForCriteria,
  imgFieldUrl,
} from './Analysis/analysis.canvas.utils'

const fieldsNotToDelayDrawings = [
  'pullExecutionDirectionNextPoseR',
  'pullExecutionDirectionNextPoseL',
]

class UserAnalysisDrawings extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      searching: false,
      drawingKey: null,
      showDrawings: true,
      imgDynamic: null,
      screenshotsCodes: [],
    }
    this.screenshot = createRef()
  }
  componentDidMount = () => {
    // this.setImgEffect()
    this.preloadImages()
  }
  preloadImages = () => {
    const { analysisId } = this.props
    let url
    let screenshotsCodes = []

    logFieldsRL.forEach((fieldName) => {
      url = imgFieldUrl(analysisId, fieldName + '-2')
      screenshotsCodes = [...screenshotsCodes, fieldName + '-2']
      new Image().src = url
      url = imgFieldUrl(analysisId, fieldName + '-1')
      screenshotsCodes = [...screenshotsCodes, fieldName + '-1']
      new Image().src = url
      url = imgFieldUrl(analysisId, fieldName)
      screenshotsCodes = [...screenshotsCodes, fieldName]
      new Image().src = url
    })
    this.setState({ screenshotsCodes })
    this.setImgEffect()
  }

  componentDidUpdate(prevProps, prevState) {
    const { drawingKey, currentTimeIndex, noCacheKey } = this.props
    if (
      prevProps.currentTimeIndex !== currentTimeIndex ||
      prevProps.noCacheKey !== noCacheKey
    ) {
      this.clearDrawings()
      this.setImgEffect()
    }

    if (
      prevProps.currentTimeIndex === currentTimeIndex &&
      prevProps.drawingKey !== drawingKey
    ) {
      this.clearDrawings()
      this.handleDisplayDrawings()
    }

    if (prevState.showDrawings !== this.state.showDrawings) {
      this.handleDisplayDrawings()
    }

    //  this.setState({ imgDynamic: imgSrc })
  }

  runningTimeindex = ''

  isCurrentTimeIndexCheck = (currentTimeIndex) => {
    return this.runningTimeindex === currentTimeIndex
  }

  setImgEffect = async () => {
    const { drawingKey } = this.props
    const { currentTimeIndex } = this.props

    this.runningTimeindex = currentTimeIndex

    let side = currentTimeIndex.slice(-1) === 'L' ? 'L' : ''
    let criteriaSide = side === 'L' ? 'L' : 'R'

    const preImagesConfig = {
      fallEndTime: { imageCode: 'inPoseTime' },
      fallEndTimeL: { imageCode: 'inPoseTimeL' },
      middleOfPullTime: { imageCode: 'lastContactTime' },
      middleOfPullTimeL: { imageCode: 'lastContactTimeL' },
    }

    const postImagesConfig = {
      middleOfPullTime: { imageCode: 'inNextPoseTime' },
      middleOfPullTimeL: { imageCode: 'inNextPoseTimeL' },
    }

    let hasPreImage = Object.keys(preImagesConfig).includes(currentTimeIndex)
    let hasPostImage = Object.keys(postImagesConfig).includes(currentTimeIndex)

    if (hasPreImage) {
      this.setState({
        currentImg: preImagesConfig[currentTimeIndex].imageCode,
      })
      this.handleDisplayDrawings()
      await delay(1000)
    }
    if (!this.isCurrentTimeIndexCheck(currentTimeIndex)) {
      return
    }
    if (fieldsNotToDelayDrawings.includes(drawingKey)) {
      this.handleDisplayDrawings()
    }
    if (!this.isCurrentTimeIndexCheck(currentTimeIndex)) {
      return
    }
    await this.displayImageSeries(currentTimeIndex)

    if (hasPostImage) {
      if (!this.isCurrentTimeIndexCheck(currentTimeIndex)) {
        return
      }
      await delay(1000)
      if (!this.isCurrentTimeIndexCheck(currentTimeIndex)) {
        return
      }
      this.handleDisplayDrawings(
        'pullExecutionDirectionNextPose' + criteriaSide
      )
      await delay(1000)
      await this.displayImageSeries(
        postImagesConfig[currentTimeIndex].imageCode
      )
      //
    }

    if (!hasPreImage && !hasPostImage) {
      this.handleDisplayDrawings()
    }
  }

  async displayImageSeries(currentTimeIndex) {
    this.setState({
      currentImg: currentTimeIndex + '-2',
    })
    await delay(300)

    this.setState({
      currentImg: currentTimeIndex + '-1',
    })
    await delay(300)

    this.setState({
      currentImg: currentTimeIndex,
    })
  }
  clearDrawings = () => {
    this.drawableCanvas && this.drawableCanvas.clear()
  }
  loadDrawings = (drawings) => {
    this.drawableCanvas && this.drawableCanvas.loadSaveData(drawings, false)
  }
  toggleShowDrawings = () => {
    this.setState({ showDrawings: !this.state.showDrawings })
  }

  createLinesForCriteria = (criteria) => {
    const { drawingsTemplate, automaticScoresData, finalScore } = this.props

    return JSON.stringify({
      ...drawingsTemplate,
      lines: drawLinesForCriteria({
        criteria,
        automaticScoresData,
        finalScore,
      }),
    })
  }

  handleDisplayDrawings = async (drawingKeyOverride) => {
    const { drawingKey } = this.props
    const { showDrawings } = this.state

    let currentDrawingKey = drawingKeyOverride || drawingKey

    this.clearDrawings()
    let drawings = this.createLinesForCriteria(currentDrawingKey)
    if (showDrawings && drawings) {
      if (!fieldsNotToDelayDrawings.includes(currentDrawingKey)) {
        await delay(300)
      }
      this.loadDrawings(drawings)
    }
  }

  render() {
    let {
      screenshotsCodes,
      screenshotHeight,
      screenshotWidth,
      showDrawings,
      currentImg,
    } = this.state
    const { analysisId } = this.props
    return (
      <div>
        <style>
          {`
			.screenshot-init{visibility:hidden}
			.screenshot{display:none}
			.screenshot.${currentImg} {display:block}
			/*.blur {filter: blur(0.05em)}*/
		`}
        </style>
        <div
          style={{ position: 'relative', textAlign: 'center', minHeight: 200 }}
        >
          <img
            //this is only to set canvas size
            src={imgFieldUrl(analysisId, screenshotsCodes[0])}
            alt=""
            className={`screenshot-init `}
            style={{
              width: '100%',
              position: 'absolute',
              left: 0,
            }}
            ref={this.screenshot}
            onLoad={() => {
              if (!screenshotHeight) {
                this.setState({
                  screenshotWidth: this.screenshot.current.offsetWidth,
                  screenshotHeight: this.screenshot.current.offsetHeight,
                })
              }
            }}
          />
          {screenshotsCodes.map((code) => (
            <img
              key={code}
              alt=""
              src={imgFieldUrl(analysisId, code)}
              className={`screenshot ${code} ${
                code.includes('-2') || code.includes('-1') ? 'blur' : ''
              } `}
              style={{
                width: '100%',
                position: 'absolute',
                left: 0,
              }}
            />
          ))}
          {screenshotHeight && (
            <CanvasDraw
              ref={(canvasDraw) => (this.drawableCanvas = canvasDraw)}
              style={{
                backgroundColor: 'transparent',
                margin: '0 auto',
              }}
              disabled={true}
              hideGrid={true}
              loadTimeOffset={75}
              hideInterface={true}
              gridColor="transparent"
              canvasWidth={screenshotWidth}
              canvasHeight={screenshotHeight}
            />
          )}
          {!screenshotWidth && (
            <div className="loader" style={{ paddingTop: 100 }}>
              <Icon loading name="circle notch" />
            </div>
          )}
        </div>
        {screenshotHeight && (
          <>
            {' '}
            <Button
              style={{
                position: 'relative',
                top: '-40px',
                padding: '.58571429em 0.7em .58571429em',
              }}
              basic={!showDrawings}
              color="black"
              onClick={this.toggleShowDrawings}
            >
              <Icon
                name={showDrawings ? 'eye' : 'eye slash outline'}
                style={{ margin: 0 }}
              />
            </Button>
          </>
        )}
      </div>
    )
  }
}
UserAnalysisDrawings.propTypes = {
  polyglot: PropTypes.object,
  automaticScoresData: PropTypes.object,
  drawingsTemplate: PropTypes.object,
  drawingKey: PropTypes.string,
  finalScore: PropTypes.number,
  showDrawings: PropTypes.bool,
  analysisId: PropTypes.string,
  currentTimeIndex: PropTypes.string,
  noCacheKey: PropTypes.number,
}

UserAnalysisDrawings = withPolyglot()(UserAnalysisDrawings)
export default UserAnalysisDrawings
