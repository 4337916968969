import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Container, Header, Segment } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import Metronome from '../components/Metronome'

import ReactGA from 'react-ga'

class CadenceMetronome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: false,
    }
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    /* this.props.notificationRefresh()*/
  }

  render() {
    const { polyglot } = this.props

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page ">
        <Container>
          <style>{`
							.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
						`}</style>
          <Header as="h2" className="color-white center-text uppercase  top-10">
            {polyglot.t('metronome.title')}
          </Header>
          <Divider />

          <Segment style={{ padding: '1em' }}>
            <Metronome />
          </Segment>
        </Container>
      </div>
    )
  }
}
CadenceMetronome.propTypes = {
  polyglot: PropTypes.object,
}

CadenceMetronome = withPolyglot()(CadenceMetronome)
export default CadenceMetronome
