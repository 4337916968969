import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Message,
  Feed,
  Loader,
  Container,
  Placeholder,
  Header,
  Segment,
  Icon,
} from 'semantic-ui-react'
import { setMyNotificationAsRead } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import _ from 'lodash'
import moment from 'moment'
import ReactGA from 'react-ga'
import { getNotificationsForMe, deleteNotification } from '../services/Api'
import AppError from '../components/AppError'
import {
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list'
import '@sandstreamdev/react-swipeable-list/dist/styles.css'
import { toast } from 'react-toastify'

class UserNotifications extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      notifications: [],
    }
  }
  componentDidMount() {
    this.call_getNotificationForMe()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  componentWillUnmount() {
    setMyNotificationAsRead().then((response) => {
      if (response.ok) {
        this.props.forceNotificationUpdate()
      }
    })
  }
  async call_getNotificationForMe() {
    const response = await getNotificationsForMe()
    if (response.ok) {
      const data = response.data.rows
      const newState = Object.assign({}, this.state, {
        notificationCount: response.data.unreadCount,
        notifications: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
      })
      // store the new state object in the component's state
      this.setState(newState)
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  removeItem = async (id) => {
    var notifications = this.state.notifications.filter(
      (x) => x.id.toString() !== id.toString()
    )
    var response = await deleteNotification(id)
    if (response.ok)
      toast.success(this.props.polyglot.t('notifications.item.deleted'), {
        autoClose: 2000,
      })

    this.setState({ notifications })
  }
  render() {
    const { polyglot } = this.props
    return (
      <div className="page ">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {polyglot.t('notification.notifications')}
        </Header>
        {this.state.isLoading && (
          <Container
            style={{ marginTop: '10px', height: '100%', textAlign: 'center' }}
          >
            <Segment inverted>
              <Loader active />
              <Placeholder inverted fluid>
                {_.times(20, String).map(function (item, i) {
                  return (
                    <Placeholder.Header image key={i}>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  )
                })}
              </Placeholder>
            </Segment>
          </Container>
        )}
        {this.state.hasError && !this.state.isLoading && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && !this.state.isLoading && (
          <div className="notifications page">
            <style>{`
              .notifications .feed .event {
                border-bottom: 1px solid grey;
                margin-top:13px
              }
              .read{
                opacity:0.65
							}
							.feed div{
								background-color:transparent;
							}
							.event {
								display: flex;
								flex-direction: row;
								width: 100%;
								padding: .21428571rem 0;
								margin: 0;
								background: 0 0;
								border-top: none;
						}
							.event>.label {
								display: block;
								flex: 0 0 auto;
								width: 2.5rem;
								height: auto;
								align-self: stretch;
								text-align: left;
						}
						.event>.label img {
							width: 100%;
							height: auto;
							border-radius: 500rem;
					}
					.event>.content .date {
						margin: -.5rem 0 0;
						padding: 0;
						font-weight: 400;
						font-size: 1em;
						font-style: normal;
						color: rgba(0,0,0,.4);
						}
					.event>.content {
					display: block;
					flex: 1 1 auto;
					align-self: stretch;
					text-align: left;
					word-wrap: break-word;
					}
					.event>.label+.content {
					margin: .5em 0 .35714286em 1.14285714em;
					}
					.summary{
						font-weight:bold;
					}
						`}</style>
            <Container style={{ height: '100%', textAlign: 'left' }}>
              {Array.isArray(this.state.notifications) &&
                !!this.state.notifications.length && (
                  <SwipeableList className="feed" threshold={0.25}>
                    {({ className }) => (
                      <Feed className={className}>
                        {this.state.notifications.map((notification) => (
                          <SwipeableListItem
                            key={notification.id}
                            swipeRight={{
                              content: (
                                <div
                                  style={{
                                    color: 'red',
                                    padding: 4,
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: '#000',
                                  }}
                                >
                                  <Icon
                                    name="trash"
                                    color="red"
                                    size="large"
                                    style={{ marginTop: 30, marginLeft: 30 }}
                                  ></Icon>
                                </div>
                              ),
                              action: () => this.removeItem(notification.id),
                            }}
                            className={'event'}
                          >
                            <Feed.Event
                              className={notification.read ? 'read' : ''}
                              image={'/icons/icon-72x72.png'}
                              date={moment(notification.createdAt).format(
                                'ddd DD/MM/YYYY HH:mm:ss'
                              )}
                              summary={
                                notification.title.indexOf(
                                  'user.notification.'
                                ) !== -1
                                  ? polyglot.t(notification.title, {
                                      firstName:
                                        localStorage.getItem('RWJFirstName'),
                                    })
                                  : notification.title
                              }
                              extraText={
                                notification.message.indexOf(
                                  'user.notification.'
                                ) !== -1
                                  ? polyglot.t(notification.message, {
                                      firstName:
                                        localStorage.getItem('RWJFirstName'),
                                    })
                                  : notification.message
                              }
                            />
                          </SwipeableListItem>
                        ))}
                      </Feed>
                    )}
                  </SwipeableList>
                )}
              <Feed>
                {Array.isArray(this.state.notifications) &&
                  !this.state.notifications.length && (
                    <Fragment>
                      <Message color="black">
                        {' '}
                        {polyglot.t('notifications.none')}
                      </Message>
                    </Fragment>
                  )}
              </Feed>
            </Container>
          </div>
        )}
      </div>
    )
  }
}

UserNotifications.propTypes = {
  forceNotificationUpdate: PropTypes.func,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
UserNotifications = withPolyglot()(UserNotifications)
export default UserNotifications
