import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

let coachOrAdminRoutes = ['/protocols-bytype/:type']
const PrivateRoute = ({
  component: Component,
  forceNotificationUpdate,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let role = localStorage.getItem('RWJRole')
        let {
          match: { path },
        } = props

        if (role === 'user' && coachOrAdminRoutes.includes(path)) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                // state: { from: props.location },
              }}
            />
          )
        }

        return localStorage.getItem('RWJToken') ? (
          <Fragment>
            <Component
              {...props}
              forceNotificationUpdate={forceNotificationUpdate}
            />
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}
PrivateRoute.propTypes = {
  component: PropTypes.func,
  forceNotificationUpdate: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.string,
}
export default PrivateRoute
