import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import TrainingLog from '../components/TrainingLog'
import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'

class UserTrainingLog extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: false,
    }
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }
  componentDidMount() {}

  render() {
    let { trainingId } = this.props.match.params
    return (
      <Fragment>
        <Dimmer active={this.state.isLoading}>
          <Loader />
        </Dimmer>
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && (
          <div className="user-training-log page">
            <style>{`
								.user-training-log .form textarea,
								.user-training-log .form textarea:focus {
									background-color: rgba(0, 0, 0, 0.32);
								color:	rgba(255, 255, 255, 0.43);
									border: 1px solid rgba(34,36,38,.15);
									}
							`}</style>
            <Container style={{ height: '100%', textAlign: 'left' }}>
              <TrainingLog trainingId={trainingId} />
            </Container>
          </div>
        )}
      </Fragment>
    )
  }
}

UserTrainingLog.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

UserTrainingLog = withPolyglot()(UserTrainingLog)
export default UserTrainingLog
