import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { List, Icon, Transition, Header } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import AppCard from './AppCard'
import Countdown from './Countdown'

class DrillDetails extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      showSetupDetails: false,
      showInstructions: false,
      showFocus: true,
      issueReported: false,
    }
  }

  toggleSetupDetailsVisibility = () => {
    this.setState({ showSetupDetails: !this.state.showSetupDetails })
  }

  showInstructions = () => {
    this.setState({
      showFocus: false,
    })
    this.setState({
      showInstructions: !this.state.showInstructions,
    })
  }
  showFocus = () => {
    this.setState({
      showInstructions: false,
    })
    this.setState({
      showFocus: !this.state.showFocus,
    })
  }

  render() {
    const { polyglot } = this.props
    const { trainingMode, learningMode, element } = this.props

    element.id = element.id ? element.id : element._id
    return (
      <Fragment>
        <style>{`
			.repetitions, .focus{
				font-size:1.2em!important;
			}
			.drill-video .item .content .header{
				border-bottom : 1px solid #636363;
			}
			.drill-video  .item .content .description{
				padding-left:5px;
				color: rgba(255,255,255,.7)!important;
			}
		`}</style>

        <Fragment>
          <Header
            textAlign="center"
            style={{ color: 'white', marginTop: 10, marginBottom: 0 }}
          >
            {element.name}
          </Header>
          {trainingMode && !this.state.showInstructions && (
            <div style={{ textAlign: 'right', marginBottom: -5 }}>
              <Icon
                name={'help'}
                circular
                style={{
                  color: '#fff',
                  cursor: 'pointer',
                  fontSize: '1.0em',

                  border: '1px solid #292929',
                }}
                onClick={this.showInstructions}
              />
            </div>
          )}

          <Transition
            visible={!trainingMode || this.state.showInstructions}
            animation="fade down"
            duration={{ hide: 0, show: 200 }}
          >
            <div>
              <AppCard heightOverride="auto">
                {trainingMode && (
                  <Icon
                    name={'close'}
                    circular
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                    }}
                    onClick={this.showInstructions}
                  />
                )}
                <List style={{ textAlign: 'left', marginTop: 0 }}>
                  <List.Item>
                    <List.Content>
                      <List.Header
                        className="bottom-5 no-capitalize"
                        style={{
                          fontSize: '1.0em',
                        }}
                      >
                        {polyglot.t('sessionElement.setup')}
                      </List.Header>
                      <List.Description>
                        {polyglot.t(element.id + '.setup') && (
                          <span
                            onClick={this.toggleSetupDetailsVisibility}
                            style={{
                              marginTop: '2px',
                              marginLeft: '25px',
                            }}
                          >
                            {polyglot.t(element.id + '.setup')}
                            {polyglot.t(element.id + '.setup.1') && (
                              <Icon
                                name={
                                  this.state.showSetupDetails
                                    ? 'caret down'
                                    : 'caret right'
                                }
                                className="color-grey"
                                size="large"
                                style={{ marginLeft: '10px' }}
                              />
                            )}
                          </span>
                        )}

                        {(this.state.showSetupDetails ||
                          !polyglot.t(element.id + '.setup')) &&
                          polyglot.t(element.id + '.setup.1') && (
                            <List>
                              {polyglot.t(element.id + '.setup.1') && (
                                <List.Item>
                                  <List.Icon name="dot circle outline" />
                                  <List.Content>
                                    {polyglot.t(element.id + '.setup.1')}
                                  </List.Content>
                                </List.Item>
                              )}
                              {polyglot.t(element.id + '.setup.2') && (
                                <List.Item>
                                  <List.Icon name="dot circle outline" />
                                  <List.Content>
                                    {polyglot.t(element.id + '.setup.2')}
                                  </List.Content>
                                </List.Item>
                              )}

                              {polyglot.t(element.id + '.setup.3') && (
                                <List.Item>
                                  <List.Icon name="dot circle outline" />
                                  <List.Content>
                                    {polyglot.t(element.id + '.setup.3')}
                                  </List.Content>
                                </List.Item>
                              )}
                            </List>
                          )}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  {polyglot.t(element.id + '.execution.1') && (
                    <List.Item>
                      <List.Content>
                        <List.Header
                          className="bottom-5 no-capitalize"
                          style={{
                            fontSize: '1.0em',
                          }}
                        >
                          {polyglot.t('sessionElement.execution')}
                        </List.Header>
                        <List.Description>
                          <List>
                            {polyglot.t(element.id + '.execution.1') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(element.id + '.execution.1')}
                                </List.Content>
                              </List.Item>
                            )}
                            {polyglot.t(element.id + '.execution.2') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(element.id + '.execution.2')}
                                </List.Content>
                              </List.Item>
                            )}

                            {polyglot.t(element.id + '.execution.3') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(element.id + '.execution.3')}
                                </List.Content>
                              </List.Item>
                            )}
                          </List>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                </List>
              </AppCard>
            </div>
          </Transition>

          <Transition
            visible={learningMode === true || trainingMode === true}
            animation="fade down"
            duration={{ hide: 0, show: 200 }}
          >
            <AppCard heightOverride="auto">
              <List
                className={'repetitions'}
                style={{
                  display: 'inline-block',
                  textAlign: 'left',
                  marginBottom: 0,
                }}
              >
                {polyglot.t(element.id + '.repetitions.1') && (
                  <List.Item>
                    <List.Content>
                      <List.Icon
                        name="dot circle"
                        size="mini"
                        style={{ verticalAlign: 'middle' }}
                      />
                      {polyglot.t(element.id + '.repetitions.1')}
                    </List.Content>
                  </List.Item>
                )}
                {polyglot.t(element.id + '.repetitions.2') && (
                  <List.Item>
                    <List.Content>
                      <List.Icon
                        name="dot circle"
                        size="mini"
                        style={{ verticalAlign: 'middle' }}
                      />
                      {polyglot.t(element.id + '.repetitions.2')}
                    </List.Content>
                  </List.Item>
                )}
                {polyglot.t(element.id + '.repetitions.3') && (
                  <List.Item>
                    <List.Content>
                      <List.Icon
                        name="dot circle"
                        size="mini"
                        style={{ verticalAlign: 'middle' }}
                      />
                      {polyglot.t(element.id + '.repetitions.3')}
                    </List.Content>
                  </List.Item>
                )}
              </List>
              <div style={{ textAlign: 'right', position: 'relative' }}>
                {polyglot.has(element.id + '.time') && (
                  <Countdown
                    style={{
                      position: 'relative',
                      left: 'calc(50% - 20px)',
                      bottom: 2,
                      top: 2,
                      marginTop: 10,
                    }}
                    seconds={parseInt(polyglot.t(element.id + '.time'))}
                  />
                )}
              </div>
            </AppCard>
          </Transition>

          <Transition
            visible={!trainingMode || this.state.showFocus}
            animation="fade down"
            duration={{ hide: 0, show: 200 }}
          >
            <AppCard
              heightOverride="auto"
              style={{
                textAlign: 'left',
                border: '2px solid red',
                backgroundColor: '#740909',
                backgroundImage: 'none',
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              <List style={{ textAlign: 'left' }} className={'focus'}>
                {polyglot.t(element.id + '.focus.1') && (
                  <List.Item>
                    <List.Icon name="dot circle outline" />
                    <List.Content>
                      {polyglot.t(element.id + '.focus.1')}
                    </List.Content>
                  </List.Item>
                )}
                {polyglot.t(element.id + '.focus.2') && (
                  <List.Item>
                    <List.Icon name="dot circle outline" />
                    <List.Content>
                      {polyglot.t(element.id + '.focus.2')}
                    </List.Content>
                  </List.Item>
                )}

                {polyglot.t(element.id + '.focus.3') && (
                  <List.Item>
                    <List.Icon name="dot circle outline" />
                    <List.Content>
                      {polyglot.t(element.id + '.focus.3')}
                    </List.Content>
                  </List.Item>
                )}
              </List>
            </AppCard>
          </Transition>
        </Fragment>
      </Fragment>
    )
  }
}

DrillDetails.propTypes = {
  trainingMode: PropTypes.bool,
  learningMode: PropTypes.bool,
  polyglot: PropTypes.object,
  element: PropTypes.object,
}
DrillDetails = withPolyglot()(DrillDetails)
export default DrillDetails
