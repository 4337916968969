import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Header, Icon } from 'semantic-ui-react'
import { getProtocolsByType } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ReactGA from 'react-ga'
import focus from '../images/sections/focus.jpg'
import long from '../images/sections/long.jpg'
import short from '../images/sections/short.jpg'
import work from '../images/sections/work.jpg'

import SectionCard from '../components/SectionCard'

import { withPolyglot } from '../vendor/polyglot-react'
import Drill from './Drill'
import AppError from '../components/AppError'
import AppCard from '../components/AppCard'
import FocusModal from '../components/FocusModal'

const protocolsDisplayData = [
  { key: 'short', img: short },
  { key: 'long', img: long },
  { key: 'focus', img: focus },
  { key: 'work', img: work },
]
class UserProtocolsByType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      hasError: 0,
      error: false,
      isLoading: true,
      currentDrillProps: false,
      focus: null,
    }
  }

  async getData() {
    const response = await getProtocolsByType(this.props.match.params.type)
    if (response.ok) {
      const data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        items: data,

        isLoading: false,
        hasError: false,
      })

      if (this.props.location.hash !== '') {
        let drillId = this.props.location.hash.replace('#', '')
        this.setCurrentDrill(drillId)
      }
      //	this.setState({ redirectToReferrer: true })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  componentDidUpdate(prevProps) {
    ReactGA.pageview(window.location.pathname + window.location.search)
    if (prevProps.location.hash !== this.props.location.hash) {
      let drillId = this.props.location.hash.replace('#', '')
      // if (prevProps.match.params.id !== this.props.match.params.id) {
      //let drillId = this.props.match.params.id
      //Perform some operation here
      //this.setState({someState: someValue});

      this.setCurrentDrill(drillId)
    }
  }

  componentDidMount() {
    if (
      !protocolsDisplayData.find((e) => e.key === this.props.match.params.type)
    ) {
      this.setState({
        isLoading: false,
        hasError: true,
      })
    } else {
      this.getData()
    }
  }
  setCurrentDrill(drillId) {
    //return false
    if (drillId) {
      let customProps = this.props
      customProps.match.params.id = drillId
      this.setState({ currentDrillProps: customProps })
    } else {
      this.setState({ currentDrillProps: false })
    }
  }
  render() {
    const {
      polyglot,
      match: {
        params: { type },
      },
      location,
    } = this.props

    let focus = location?.state?.focus

    const { currentDrillProps } = this.state

    let wheight = window.innerHeight - 100
    wheight += 'px'

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <Fragment>
        <div
          className={'page ' + (!currentDrillProps ? '' : 'hidden')}
          style={{ height: wheight, overflow: 'auto' }}
        >
          <div className="page ">
            <style>{`

						`}</style>

            <SectionCard
              cardData={protocolsDisplayData.find(
                (e) => e.key === this.props.match.params.type
              )}
              history={this.props.history}
              asHeader={true}
              asDrawer
            />

            <div className="bottom-20 " />

            {focus && <FocusModal focus={focus} />}
            {this.state.items.length !== 0 &&
              this.state.items.map((item) => (
                <span
                  onClick={() =>
                    item.hideLink
                      ? this.props.history.push(`/services`)
                      : this.props.history.push(
                          `/protocols/type/${type}/${item.id}`
                        )
                  }
                  key={item.id}
                >
                  <AppCard
                    id={item.id}
                    heightOverride="auto"
                    style={{ marginTop: 10 }}
                  >
                    {' '}
                    <Header
                      style={{
                        color: '#fff',
                        marginTop: -3,
                      }}
                      data-testid={item.personalizedFocus}
                    >
                      {item.label}
                      <Icon
                        name={item.hideLink ? 'lock' : 'chevron right'}
                        color={item.hideLink ? 'blue' : 'grey'}
                        data-testid={item.hideLink ? 'lock' : 'link-to-item'}
                        style={{
                          position: 'absolute',
                          right: 10,
                          fontSize: '.95rem',
                        }}
                      />
                    </Header>
                  </AppCard>
                </span>
              ))}
            <div className="bottom-20 " />
            {this.state.items.length === 0 && (
              <div className="pad-top-20">
                {polyglot.t('generic.message.empty')}
              </div>
            )}
          </div>
        </div>
        {currentDrillProps && <Drill {...currentDrillProps} />}
      </Fragment>
    )
  }
}

UserProtocolsByType.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

UserProtocolsByType = withPolyglot()(UserProtocolsByType)
export default UserProtocolsByType
