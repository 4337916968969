import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'

import * as serviceWorker from './serviceWorker'

import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'
import AppSidebar from './components/AppSidebar'

import PWAPrompt from 'react-ios-pwa-prompt'

import Routes from './components/Routes'
import { Slide /*, Zoom, Flip, Bounce*/ } from 'react-toastify'
import { toast } from 'react-toastify'
import { withPolyglot } from './vendor/polyglot-react'

import './App.css'
import { Container, Sidebar, Message, Button } from 'semantic-ui-react'
import { Config } from './config'
import ReactGA from 'react-ga'
import MenuTop from './components/MenuTop'
import MenuBottom from './components/MenuBottom'

ReactGA.initialize(Config.gaId, {
  testMode: process.env.NODE_ENV === 'production' ? false : true,
})

toast.configure({
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2500,
  //autoClose: false,
  toastClassName: 'roundToast',
  closeButton: false,
  transition: Slide,
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // visible: ['coach', 'admin'].includes(localStorage.getItem('RWJRole')),
      visible: false,
      forceNotificationUpdate: false,
      newVersionAvailable: false,
      waitingWorker: {},
    }
  }

  onServiceWorkerUpdate = (registration) => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    })

    toast.configure({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
      toastClassName: 'roundToast',
      closeButton: true,
      //transition: Slide,
    })

    toast.dark(
      <Fragment>
        {this.props.polyglot.t('generic.message.app.update')}{' '}
        <Button
          content={this.props.polyglot.t('generic.message.app.update.button')}
          color="green"
          style={{ marginLeft: 5 }}
          onClick={this.updateServiceWorker}
        />
      </Fragment>
    )
  }

  updateServiceWorker = () => {
    const { waitingWorker } = this.state
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    this.setState({ newVersionAvailable: false })
    window.location.reload()
  }
  componentDidMount = () => {
    if (!this.props.polyglot.has('loaded.check')) {
      window.location.reload()
    }

    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate })
    }
  }

  handleShowClick = () => {
    this.setState({ visible: true })
  }
  handleSidebarHide = () => {
    if (['coach', 'admin'].includes(localStorage.getItem('RWJRole'))) {
      return
    }

    //  this.setState({ visible: false })
  }

  toggleSidebar = () => {
    this.setState({ visible: !this.state.visible })
  }

  forceNotificationUpdate = () =>
    this.setState({ forceNotificationUpdate: true })

  render() {
    const { polyglot } = this.props
    localStorage.getItem && (window.storage = localStorage)

    const { visible } = this.state
    return (
      <Fragment>
        {window.storage && (
          <Router>
            <div
              className={
                'App ' +
                (window.localStorage.getItem('RWJRole')
                  ? window.localStorage.getItem('RWJRole')
                  : '')
              }
            >
              {visible && (
                <style>{`
					.ui.fluid.container.pushable {padding-left:160px !important;}
			`}</style>
              )}
              <MenuTop
                toggleSidebar={this.toggleSidebar}
                isCoach={['coach', 'admin'].includes(
                  window.localStorage.getItem('RWJRole')
                )}
              />

              <MenuBottom
                handleShowClick={this.handleShowClick}
                forceNotificationUpdate={this.state.forceNotificationUpdate}
              />

              {localStorage.getItem('RWJToken') &&
                window.localStorage.getItem('RWJRole') !== 'user' && (
                  <AppSidebar
                    visible={visible}
                    handleSidebarHide={this.handleSidebarHide}
                  />
                )}

              {/*<Sidebar.Pushable as={Container} fluid style={{height:'100vh'}}>*/}
              <Sidebar.Pushable as={Container} fluid>
                <Sidebar.Pusher style={{ height: '100%' }}>
                  <Container
                    fluid
                    style={{ marginTop: '30px', paddingBottom: '20px' }}
                    className={'main-content '}
                  >
                    <Routes
                      forceNotificationUpdate={this.forceNotificationUpdate}
                    />
                  </Container>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
              <PWAPrompt
                debug={false}
                timesToShow={5}
                copyTitle={polyglot.t('app.ios.prompt.title')}
                copyBody={polyglot.t('app.ios.prompt.body')}
                copyShareButtonLabel={polyglot.t(
                  'app.ios.prompt.shareButtonLabel'
                )}
                copyAddHomeButtonLabel={polyglot.t(
                  'app.ios.prompt.addHomeButtonLabel'
                )}
                copyClosePrompt={polyglot.t('app.ios.prompt.closePrompt')}
              />
            </div>
          </Router>
        )}
        {!window.storage && (
          <Container style={{ marginTop: '40px' }}>
            <Message negative>
              <Message.Header>
                Cookies désactivés / Disabled cookies
              </Message.Header>
              <p>
                Cette application utilise les cookies uniquement pour vous
                authentifier, veuillez les reactiver
              </p>
              <p>
                This app uses cookies only for authentication purpose, please
                allow them.
              </p>
            </Message>
          </Container>
        )}
      </Fragment>
    )
  }
}

App.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
App = withPolyglot()(App)
export default App
