import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Container, Header } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import 'react-datepicker/dist/react-datepicker.css'
import PerfInputSet from '../components/PerfInputSet'

import ReactGA from 'react-ga'

class UserPerfs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: false,
      allPerfsArray: [],
    }
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)

    /* this.props.notificationRefresh()*/
  }

  render() {
    const { polyglot } = this.props

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page ">
        <Container text>
          <style>{`
							.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
						`}</style>
          <Header as="h2" className="color-white center-text uppercase  top-10">
            {polyglot.t('home.perf.new')}
          </Header>
          <Divider />
          <div>
            <PerfInputSet history={this.props.history} />
          </div>
        </Container>
      </div>
    )
  }
}
UserPerfs.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

UserPerfs = withPolyglot()(UserPerfs)
export default UserPerfs
