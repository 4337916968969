import React, { Component, Fragment, createRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dimmer,
  Loader,
  Segment,
  Container,
  Card,
  Header,
  Button,
  Dropdown,
  Form,
  Radio,
  Icon,
  Image,
  List,
} from 'semantic-ui-react'

import {
  getAnalysis,
  updateAnalysis,
  getFaults,
  setIsInvalid,
  getCues,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import moment from 'moment'
import AppError from '../components/AppError'
import { AnalysisText } from '../components/AnalysisText'
import { minBy } from 'lodash'
import AnalysisVideo from '../components/Analysis/AnalysisVideo'

import {
  logFieldsR,
  logFieldsL,
  logFields,
  logFieldsCadence,
  mstoTime,
  focus,
  computePoseScore,
  computeFallScore,
  computePullScore,
  computeGlobalScore,
  computeCadence,
  holdingPoseScoreSide,
  checkAnalysisFieldsAndPointsComplete,
  criteriasToDisplayMap,
  checkNewFormatComplete,
} from '../components/Analysis/analysis.utils'
import AnalysisMenu from '../components/Analysis/AnalysisMenu'
import AnalysisOverview from '../components/Analysis/AnalysisOverview'
import UserDetailedAnalysis from '../components/UserDetailedAnalysis'
import {
  drawingsMandatoryPoints,
  imgFieldUrl,
} from '../components/Analysis/analysis.canvas.utils'
import UserAnalysisAbstract from '../components/UserAnalysisAbstract'

const invalidOptions = [
  {
    key: 0,
    text: 'Valid',
    value: 0,
  },
  {
    key: 1,
    text: 'Phone Orientation',
    value: 1,
  },
  {
    key: 2,
    text: 'Left to Right',
    value: 2,
  },
  {
    key: 3,
    text: 'Even surface',
    value: 3,
  },
]

class Analysis extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      analysisData: { currentFieldIndex: 0 },
      activeItem: 'video',
      screenshots: {},
      currentTime: '00:00',
      currentFieldIndex: 0,
      noCacheKey: Math.random(),
    }
    this.player = createRef()
  }
  componentDidMount() {
    this.call_getAnalysis()
    this.call_getFaults()
    this.call_getCues()
  }

  async call_getAnalysis() {
    const response = await getAnalysis(this.props.match.params.id)

    if (response.ok) {
      // store the new state object in the component's state
      const analysisData = { ...response.data /*...this.state.analysisData */ }
      this.setState({
        isLoading: false,
        hasError: false,
        analysisData,
        isInvalid: response.data.isInvalid,
        /*
        analysisFault: response.data.fault
          ? {
              key: response.data.fault._id,
              text: polyglot.t('faults.' + response.data.fault.code),
            }
          : { id: '-', text: polyglot.t('generic.select.empty') },*/
      })

      document.title = analysisData.user.name
      return true
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  async call_getFaults() {
    const response = await getFaults()
    if (response.ok) {
      const data = response.data.rows

      const { polyglot } = this.props
      var data_translated = data.map((fault) => {
        return {
          value: fault.id,
          key: fault.id,
          //  id: fault.id,
          text: polyglot.t('faults.' + fault.code),
        }
      })

      data_translated.unshift({
        value: '-',
        key: null,
        text: polyglot.t('generic.select.empty'),
      })

      this.setState({ faults: data_translated })
    }
  }
  async call_getCues() {
    const response = await getCues()
    if (response.ok) {
      const cues = response.data.rows
      this.setState({ cues })
    }
  }
  handleMenuClick = (e, { code }) => {
    this.goToMenuTarget(code)
  }
  goToMenuTarget = (code) => {
    this.setState({ activeItem: code })
  }

  handleRadio = (e, { value, propname }) => {
    if (propname.indexOf('fallExecutionAlignment') !== -1) {
      //pas de dissociation pour ce critere, on force les deux meme valeurs
      this.updateAnalysisData({
        fallExecutionAlignmentR: parseInt(value),
        fallExecutionAlignmentL: parseInt(value),
      })
    } else if (['focus', 'cue', 'fault'].includes(propname)) {
      this.updateAnalysisData({ [propname]: value })
    } else this.updateAnalysisData({ [propname]: parseInt(value) })
  }

  handleIsInvalidChange = (e, { value }) => this.setState({ isInvalid: value })

  call_computeCadence = () => {
    this.updateAnalysisData(this.state.analysisData)
  }

  updateAnalysisData = (dataToMerge = {}) => {
    const { analysisData } = this.state

    dataToMerge.fallExecutionTimeL = holdingPoseScoreSide(analysisData, 'L')
    dataToMerge.fallExecutionTimeR = holdingPoseScoreSide(analysisData, 'R')

    let newAnalysisData = { ...analysisData, ...dataToMerge }

    const poseScore = computePoseScore(newAnalysisData)
    const fallScore = computeFallScore(newAnalysisData)
    const pullScore = computePullScore(newAnalysisData)
    const globalScore = computeGlobalScore(poseScore, fallScore, pullScore)
    const cadence = computeCadence(newAnalysisData)
    //   const analysisText = generateAnalysisText(newAnalysisData)

    let minScoreElement = minBy(
      [
        { element: 'pose', score: poseScore || 100 },
        { element: 'fall', score: fallScore || 100 },
        { element: 'pull', score: pullScore || 100 },
      ],
      function (o) {
        return o.score
      }
    )

    let focus = minScoreElement.element
    if (dataToMerge.hasOwnProperty('focus')) {
      // si le focus est passé manuellement on override celui calculé
      //  focus = dataToMerge['focus']
    }

    newAnalysisData = {
      ...newAnalysisData,
      poseScore,
      fallScore,
      pullScore,
      globalScore,
      cadence,
      focus,
      //   analysisText,
    }
    this.setState({ analysisData: newAnalysisData })
    return newAnalysisData
  }

  updateAnalysisState = (dataToMerge) => {
    this.setState({ ...dataToMerge })
  }

  checkFieldsAndPointBeforeSave = (preventSave) => {
    const displayMethod = preventSave ? toast.error : toast.warn

    let { missingFields, missingPoints } = checkAnalysisFieldsAndPointsComplete(
      this.state.analysisData
    )
    if (missingFields.length !== 0) {
      displayMethod(
        <List>
          {missingFields.map((field) => (
            <List.Item key={field}>{field}</List.Item>
          ))}
        </List>
      )
      if (preventSave) return false
    }

    if (missingPoints.length !== 0) {
      displayMethod(
        <List>
          {missingPoints.map((field) => (
            <List.Item key={field}>{field}</List.Item>
          ))}
        </List>
      )

      if (preventSave) return false
    }
    return true
  }

  toggleIsAvailableAndSave = () => {
    let isAvailable = !this.state.analysisData.isAvailable

    let preventSave = true

    let analysisData = {
      ...this.state.analysisData,
      isAvailable,
      toUpdate:
        this.state.analysisData.toUpdate === true
          ? false
          : this.state.analysisData.toUpdate,
    }

    this.saveAnalysis(analysisData, preventSave)
  }

  saveAnalysis = (
    analysisData = this.state.analysisData,
    preventSave = false
  ) => {
    const { polyglot, match } = this.props
    delete analysisData.screenshots
    this.updateAnalysisData(analysisData)

    if (!this.checkFieldsAndPointBeforeSave(preventSave)) {
      return
    }

    updateAnalysis(match.params.id, analysisData)
      .then((response) => {
        if (response.ok) {
          //this.setState(newState)
          toast.success(polyglot.t('generic.message.update'), {})
        } else {
          toast.error(polyglot.t('generic.message.error'), {})
          // this.call_getAnalysis()
        }
      })
      .catch()
  }

  call_setIsInvalid = async () => {
    const { polyglot, match } = this.props
    const { isInvalid } = this.state

    const response = await setIsInvalid(match.params.id, {
      isInvalid,
    })
    if (response.ok) {
      //this.setState(newState)
      toast.success(polyglot.t('generic.message.update'), {})
    } else {
      toast.error(polyglot.t('generic.message.error'), {})
    }
  }
  goToPlayerTime = (time) => {
    let currentTime = mstoTime(time)
    this.setState({
      currentTime,
    })
  }

  setCurrentField = (fieldOrIndex, matchPlayerTime = true) => {
    const { analysisData } = this.state
    let fieldName
    let currentFieldIndex
    if (isNaN(fieldOrIndex)) {
      //fieldname
      fieldName = fieldOrIndex
      currentFieldIndex = logFields.indexOf(fieldName)
    } else {
      //index du champ
      currentFieldIndex = fieldOrIndex
      fieldName = logFields[currentFieldIndex]
    }
    //    let currentFieldIndex = fieldName.indexOf(field)

    let targetTime = analysisData[fieldName]

    if (matchPlayerTime) {
      if (targetTime !== 0) {
        this.goToPlayerTime(targetTime)
      } else {
        if (currentFieldIndex > 0) {
          let previousField = logFields[currentFieldIndex - 1]
          let previousTime = analysisData[previousField]
          this.goToPlayerTime(previousTime)
        }
      }
    }

    this.updateAnalysisState({ currentFieldIndex })
  }
  checkCriteriaForField = (field) => {
    let mandatoryFields = criteriasToDisplayMap[field]

    let doneFields = this.state.analysisData

    let points = []

    mandatoryFields.forEach((field) => {
      points = [
        ...points,
        <Icon
          key={field.key}
          size="tiny"
          className={'color' + doneFields?.[field.prop]}
          name="square"
        />,
      ]
    })
    return points
  }

  checkPointsForField = (field) => {
    let mandatoryPoints = drawingsMandatoryPoints[field]

    let donePoints =
      this.state.analysisData.automaticScoresData?.bodyPoints?.[field]

    let points = []

    mandatoryPoints.forEach((point) => {
      points = [
        ...points,
        <Icon
          key={point}
          size="tiny"
          color={donePoints?.[point] ? 'green' : 'grey'}
          name="circle"
        />,
      ]
    })
    return points
  }

  render() {
    const { polyglot } = this.props
    const {
      analysisData,
      activeItem,
      screenshots,
      currentTime,
      currentFieldIndex,
      noCacheKey,
    } = this.state
    const addDefaultSrc = (ev) => {
      ev.target.src = '/video-poster.png'
    }
    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
				.pushable:not(body) {
					transform: none
				}
				.card .content{
					padding:0.3em 0.3em 0.1em 0.3em !important;
				}
				.ui.form .inline.fields>label {
					min-width: 10em!important;
				}

			`}</style>
              <Container>
                <Segment>
                  <Grid columns={3} divided>
                    <Grid.Row>
                      <Grid.Column width={4} textAlign="left">
                        <div>
                          {moment(analysisData.date).format('DD/MM/YYYY')}
                        </div>
                        <div>
                          Due :
                          {moment(analysisData.estimatedPublished).format(
                            'ddd MM/DD/YYYY'
                          )}
                        </div>
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Header>{analysisData.user?.name}</Header>
                        <div>{analysisData.user?.email}</div>
                        {analysisData.poseScore?.toFixed(0)} % /
                        {analysisData.fallScore?.toFixed(0)} % /
                        {analysisData.pullScore?.toFixed(0)} % /
                        <span style={{ marginLeft: 5 }}>
                          {analysisData.globalScore?.toFixed(0)} %{' '}
                          <b>{analysisData.focus}</b>
                        </span>
                      </Grid.Column>

                      <Grid.Column width={7}>
                        <Button.Group>
                          {!this.state.isInvalid ? (
                            <Button
                              positive={
                                analysisData.isAvailable &&
                                !analysisData.toUpdate
                              }
                              color={
                                analysisData.toUpdate
                                  ? 'yellow'
                                  : !analysisData.isAvailable
                                  ? 'orange'
                                  : null
                              }
                              onClick={this.toggleIsAvailableAndSave}
                            >
                              {analysisData.isAvailable
                                ? polyglot.t('analysis.isAvailable')
                                : polyglot.t('analysis.isAvailable.not')}
                            </Button>
                          ) : (
                            <Button
                              negative
                              onClick={() => this.call_setIsInvalid()}
                            >
                              {polyglot.t('analysis.isInvalid')}
                            </Button>
                          )}
                          <Button onClick={() => this.saveAnalysis()}>
                            {polyglot.t('generic.message.save')}
                          </Button>
                          <Button onClick={() => this.updateAnalysisData()}>
                            {polyglot.t('generic.message.refresh')}
                          </Button>
                          <Dropdown
                            className="button"
                            placeholder="Select"
                            selection
                            onChange={this.handleIsInvalidChange}
                            value={this.state.isInvalid}
                            options={invalidOptions}
                          />
                        </Button.Group>
                        New Analysis Format :
                        <Icon
                          name={
                            checkNewFormatComplete(analysisData)
                              ? 'check'
                              : 'close'
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <AnalysisMenu
                  activeItem={activeItem}
                  handleMenuClick={this.handleMenuClick}
                />

                <Segment className="pad-bottom-10">
                  <Grid className="pad-bottom-10">
                    {['video'].includes(activeItem) && (
                      <Grid.Row>
                        <Grid.Column width={3}>
                          <Grid>
                            <Grid.Row>
                              {[logFieldsR, logFieldsL].map(
                                (arrayOfFields, index) => (
                                  <Grid.Column key={arrayOfFields[0]} width={8}>
                                    {arrayOfFields.map((field) => (
                                      <Card
                                        key={field}
                                        style={
                                          logFields[currentFieldIndex] === field
                                            ? { borderBottom: '1px solid red' }
                                            : {}
                                        }
                                        onClick={() => {
                                          this.setCurrentField(field)
                                        }}
                                      >
                                        <Card.Content>
                                          <Image
                                            floated="left"
                                            size="mini"
                                            src={
                                              imgFieldUrl(
                                                analysisData.id,
                                                field
                                              ) +
                                              '?ncm=' +
                                              noCacheKey
                                            }
                                            onError={addDefaultSrc}
                                          />
                                          <Card.Header
                                            style={{
                                              fontSize: '0.90em',
                                              color: 'grey',
                                            }}
                                          >
                                            {polyglot.t(`analysis.${field}`)}{' '}
                                            {this.state.uploadStatus?.[
                                              field
                                            ] === true ? (
                                              <Icon
                                                name="check"
                                                color="green"
                                              />
                                            ) : null}
                                          </Card.Header>
                                          <Card.Description>
                                            {analysisData[field]}
                                          </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                          {this.checkCriteriaForField(field)}

                                          {this.checkPointsForField(field)}
                                        </Card.Content>
                                      </Card>
                                    ))}

                                    <div
                                      className="top-10"
                                      style={{
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {index === 0 ? (
                                        <>
                                          {analysisData.framesToPoseR}:
                                          {analysisData.framesFromPoseR}
                                        </>
                                      ) : (
                                        <>
                                          {analysisData.framesToPoseL}:
                                          {analysisData.framesFromPoseL}
                                        </>
                                      )}
                                    </div>
                                  </Grid.Column>
                                )
                              )}
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: 0, paddingTop: 0 }}>
                              <Grid.Column>
                                <div
                                  style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {analysisData.framesToPose}:
                                  {analysisData.framesFromPose}
                                </div>
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row style={{ marginTop: 0, paddingTop: 0 }}>
                              <Grid.Column width={16}>
                                {logFieldsCadence.map((field) => (
                                  <Card
                                    key={field}
                                    header={
                                      <div
                                        style={{
                                          fontSize: '0.90em',
                                          color: 'grey',
                                        }}
                                      >
                                        {polyglot.t(`analysis.${field}`)}
                                      </div>
                                    }
                                    description={analysisData[field]}
                                    onClick={() => this.setCurrentField(field)}
                                    style={{
                                      width: '30%',
                                      display: 'inline-block',
                                      ...(logFields[currentFieldIndex] === field
                                        ? {
                                            borderBottom: '1px solid red',
                                          }
                                        : {}),
                                    }}
                                  />
                                ))}
                                <div
                                  className="top-10"
                                  style={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {analysisData.cadence}
                                </div>
                                <div
                                  className="top-10"
                                  style={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {' '}
                                  <Button
                                    className={'app-button	'}
                                    onClick={this.call_computeCadence}
                                  >
                                    {polyglot.t('analysis.cadence.compute')}
                                  </Button>
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>{' '}
                        </Grid.Column>
                        <Grid.Column width={13}>
                          {activeItem === 'video' && (
                            <AnalysisVideo
                              originalVideo={analysisData.originalVideo}
                              updateAnalysisData={this.updateAnalysisData}
                              updateAnalysisState={this.updateAnalysisState}
                              analysisData={analysisData}
                              setCurrentField={this.setCurrentField}
                              currentFieldIndex={currentFieldIndex}
                              screenshots={screenshots}
                              currentTime={currentTime}
                              handleRadio={this.handleRadio}
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    )}

                    {['focus'].includes(activeItem) && (
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Card fluid>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <Form>
                                <Form.Group inline className="top-5">
                                  <label>{polyglot.t('analysis.focus')} </label>

                                  {focus.map((x) => (
                                    <Form.Field key={x}>
                                      <Radio
                                        label={
                                          x.slice(0, 1).toUpperCase() +
                                          x.slice(1, x.length)
                                        }
                                        value={x}
                                        propname="focus"
                                        checked={analysisData.focus === x}
                                        onChange={this.handleRadio}
                                      />
                                    </Form.Field>
                                  ))}
                                </Form.Group>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <Form.Field>
                                    <label>
                                      {polyglot.t('analysis.fault')}
                                    </label>

                                    <Dropdown
                                      selection
                                      propname="fault"
                                      value={analysisData.fault}
                                      options={this.state.faults}
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                              {['pose', 'fall', 'pull', 'general'].map(
                                (category) => (
                                  <Fragment key={category}>
                                    <h3>{category}</h3>

                                    {this.state.cues
                                      ?.filter((cue) =>
                                        cue.code.startsWith(category)
                                      )
                                      .map((cue) => (
                                        <Form.Field
                                          key={cue.id}
                                          style={{
                                            backgroundColor:
                                              analysisData.focus === category
                                                ? '#dbdbdb'
                                                : 'transparent',
                                          }}
                                        >
                                          <Radio
                                            label={polyglot.t(
                                              `cues.${cue.code}`
                                            )}
                                            value={cue.code}
                                            propname="cue"
                                            checked={
                                              analysisData.cue === cue.code
                                            }
                                            onChange={this.handleRadio}
                                          />{' '}
                                        </Form.Field>
                                      ))}
                                  </Fragment>
                                )
                              )}
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      </Grid.Row>
                    )}

                    {['overview'].includes(activeItem) && (
                      <AnalysisOverview
                        analysisData={analysisData}
                        polyglot={polyglot}
                      />
                    )}
                    {['preview'].includes(activeItem) && (
                      <Grid.Row>
                        <Grid.Column width={10}>
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {polyglot.t('analysis.analysis')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <AnalysisText
                                analysisText={analysisData.analysisText}
                              />
                            </Card.Content>
                          </Card>
                        </Grid.Column>

                        <Grid.Column width={6}>
                          <Card
                            className="analysis"
                            style={{ backgroundColor: '#000' }}
                          >
                            <UserAnalysisAbstract
                              key={analysisData._id || analysisData.id}
                              toggleshowDetailedAnalysis={
                                this.toggleshowDetailedAnalysis
                              }
                              {...analysisData}
                            />
                            <Card.Content style={{ textAlign: 'left' }}>
                              <UserDetailedAnalysis
                                polyglot={this.props.polyglot}
                                analysis={analysisData}
                              />
                            </Card.Content>{' '}
                          </Card>{' '}
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </Segment>
              </Container>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}
Analysis.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}
Analysis = withPolyglot()(Analysis)
export default Analysis
