import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Segment, Label, Modal, Card, Divider, Popup } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import { getDrill } from '../services/Api'
import Metronome from './Metronome'

import DrillDetails from './DrillDetails'
import DrillVideo from './DrillVideo'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

class Workout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  showDrill = async (id) => {
    const response = await getDrill(id)
    if (response.ok) {
      this.setState({
        drill: response.data,
        open: true,
      })
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  displayDistanceInMileOrKm = (mileOrKmData) => {
    //  mileOrKmData = '16 km|10.2 mi'
    let result = mileOrKmData

    let distanceUnit = localStorage.getItem('RWJMileOrKm')

    if (!distanceUnit) {
      let localStorageLocale = localStorage.getItem('RWJlocale')
      distanceUnit = localStorageLocale === 'en-us' ? 'mi' : 'km'
    }

    if (mileOrKmData.includes('|')) {
      let mileOrKmArray = mileOrKmData.split('|')
      let km, mi
      if (mileOrKmArray[0].includes('k')) {
        km = mileOrKmArray[0].trim()
        mi = mileOrKmArray[1].trim()
      } else {
        km = mileOrKmArray[1].trim()
        mi = mileOrKmArray[0].trim()
      }
      result = distanceUnit === 'mi' ? mi : km
    }

    return result
  }

  render() {
    const { polyglot, workout, view } = this.props
    return (
      <Fragment>
        <style>{`
					.segments .segment.noborder {
					border: 0 !important;
					}
					.work{
						color:red;
						font-size: 1.2em;
    				font-weight: 600;
					}
					.rest{
						color:#fff;
						font-size: 1.2em;
    				font-weight: 600;
					}

			`}</style>
        {workout?.content.map((c, index) => (
          <Segment.Group
            id="segment-container"
            className=" top-5 pad-top-10"
            key={index}
            style={{ border: '1px solid #3a3939' }}
          >
            {c.repeat > 1 && (
              <Label color="red" attached="top left">
                {polyglot.t(
                  'protocol.workout.repeat' + (c.repeat > 1 ? 's' : ''),
                  {
                    repeat: c.repeat,
                  }
                )}
              </Label>
            )}
            {c.amrap && (
              <Label color="red" attached="top left">
                {polyglot.t('protocol.workout.amrap', {
                  amrap: c.amrap,
                })}
              </Label>
            )}

            {c.repeat <= 1 && !c.amrap && <Divider hidden />}
            {c.intervals.map((i, index) => (
              <Segment
                style={{
                  textAlign: 'left',
                  height: 'auto',
                  minHeight: 25,
                  padding: '0.4em 0.2em 0.5em 1.0em',
                }}
                className="noborder"
                clearing
                key={index}
              >
                {i.repeat > 1 && i.repeat + ' x '}
                {i.repeat > 1 &&
                  (i.drill || i.personalizedDrill) &&
                  (i.time || i.distance) &&
                  ' ( '}
                {i.drill && (
                  <>
                    {/*polyglot.t('protocol.workout.drill')*/}
                    <span
                      style={{
                        cursor: 'pointer',
                        color: '#ababab',
                      }}
                      onClick={() => this.showDrill(i.drill._id)}
                    >
                      {i.drill.name}
                      <Button
                        icon={'play'}
                        size={'mini'}
                        className="app-button"
                        circular
                        style={{ marginLeft: 5 }}
                      />
                    </span>
                  </>
                )}
                {i.personalizedDrill && (
                  <>
                    {/*polyglot.t('protocol.workout.drill')*/}
                    <span
                      style={{
                        cursor: 'pointer',
                        color: '#ababab',
                      }}
                      onClick={() =>
                        i.personalizedDrill._id
                          ? this.showDrill(i.personalizedDrill._id)
                          : null
                      }
                    >
                      {i.personalizedDrill.notAvailable ? (
                        polyglot.t(i.personalizedDrill.name)
                      ) : (
                        <>
                          {i.personalizedDrill.name}
                          <Button
                            icon={'play'}
                            size={'mini'}
                            className="app-button"
                            circular
                            style={{ marginLeft: 5 }}
                          />
                        </>
                      )}
                    </span>
                    {/*(!!i.distance || !!i.time) &&
                      polyglot.t('protocol.workout.into')*/}
                  </>
                )}

                {!!i.custom && polyglot.has(i.custom.trim())
                  ? polyglot.t(i.custom.trim())
                  : i.custom + ' '}

                {!i.personalizedDrill &&
                  !i.drill &&
                  !i.custom &&
                  !i.rest &&
                  `${polyglot.t('run')} `}

                {!!i.distance && (
                  <>
                    {(i.drill || i.personalizedDrill) &&
                      polyglot.t('protocol.workout.into')}

                    <span className="work">
                      {' '}
                      {this.displayDistanceInMileOrKm(i.distance)}{' '}
                    </span>
                  </>
                )}
                {!!i.time && (
                  <>
                    <span className="work"> {i.time} </span>
                  </>
                )}

                {!!i.cadence && (
                  <span>
                    {polyglot.t('protocol.workout.at.cadence')}
                    <span className="work"> {i.cadence} </span>
                    <span style={{ margin: '0px 5px' }}>
                      <Metronome embedded={true} bpm={+i.cadence} />
                    </span>
                  </span>
                )}

                {!!i.pace &&
                polyglot.has(
                  `protocol.pace.${i.pace.toLowerCase().trim()}.detail`
                ) ? (
                  <Popup
                    trigger={
                      <span>
                        <span
                          style={{
                            textDecoration: 'underline',
                            color: '#fff',
                            fontWeight: 'bold',
                          }}
                        >
                          {polyglot.has(
                            `protocol.pace.${i.pace.toLowerCase().trim()}`
                          )
                            ? ' ' +
                              polyglot.t(
                                `protocol.pace.${i.pace.toLowerCase().trim()}`
                              )
                            : ' ' + i.pace}
                        </span>
                        {/*
                        <Icon
                          name="stopwatch"
                          size="small"
                          style={{ verticalAlign: 'super', color: '#fff' }}
                        />
												 */}
                      </span>
                    }
                    content={
                      <Fragment>
                        {polyglot.t(
                          `protocol.pace.${i.pace.toLowerCase().trim()}.detail`
                        )}
                      </Fragment>
                    }
                    on="click"
                    position="bottom center"
                    hideOnScroll
                  />
                ) : polyglot.has(
                    `protocol.pace.${i.pace.toLowerCase().trim()}`
                  ) ? (
                  ' ' +
                  polyglot.t(`protocol.pace.${i.pace.toLowerCase().trim()}`)
                ) : (
                  ' ' + i.pace
                )}
                <span />

                <span style={{ whiteSpace: 'nowrap' }}>
                  {!!i.rest && (
                    <>
                      {(!!i.distance || i.drill || !!i.time) && ` /`}

                      {polyglot.t('protocol.workout.rest.for')}
                      <span className="rest"> {i.rest}</span>
                    </>
                  )}
                  {i.repeat > 1 &&
                    (i.drill || i.personalizedDrill) &&
                    (i.time || i.distance) &&
                    ' ) '}
                </span>

                {!!i.instruction &&
                view !== 'list ' &&
                i.instruction.indexOf('instructions.') !== -1 &&
                polyglot.has(i.instruction)
                  ? `
									` + polyglot.t(i.instruction)
                  : `
									` + i.instruction}
              </Segment>
            ))}
          </Segment.Group>
        ))}{' '}
        {this.props.workout.comments && (
          <Segment.Group
            id="segment-container"
            className=" top-5 pad-top-10"
            style={{ border: '1px solid #3a3939' }}
          >
            <Segment
              style={{
                textAlign: 'left',
                height: 'auto',
                minHeight: 25,
                padding: '0.4em 0.1em 0.4em 0.5em',
              }}
              className="noborder"
              clearing
            >
              {this.props.workout.comments}
            </Segment>
          </Segment.Group>
        )}
        <Modal
          basic
          onClose={() => this.setState({ open: false })}
          onOpen={() => this.setState({ open: true })}
          open={this.state.open}
          size="tiny"
          closeIcon
          centered={false}
          className="discrete"
        >
          <Modal.Content>
            <Card fluid className="dark drill-video">
              {this.state.drill?.video && (
                <DrillVideo
                  element={this.state.drill}
                  activeIndex={1}
                  drillIndex={1}
                />
              )}
              <Card.Content style={{ paddingTop: 0 }}>
                <DrillDetails element={this.state.drill} trainingMode={false} />
              </Card.Content>
            </Card>
          </Modal.Content>
        </Modal>
      </Fragment>
    )
  }
}
Workout.propTypes = {
  polyglot: PropTypes.object,
  workout: PropTypes.object,
  view: PropTypes.string,
}

Workout = withPolyglot()(Workout)
export default Workout
