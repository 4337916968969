import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'

import { withPolyglot } from '../vendor/polyglot-react'

import { detailedAnalysisCriteriaMap } from './Analysis/analysis.utils'

class UserDetailedAnalysisTimeLine extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let { currentTimeIndex, setCurrentTimeIndex, polyglot } = this.props

    return (
      <div
        style={{
          borderTop: '2px solid grey',
          marginLeft: 10,
          marginRight: 10,
          marginTop: 40,
          marginBottom: 40,
          position: 'relative',
          color: 'grey',
        }}
      >
        {Object.keys(detailedAnalysisCriteriaMap).map((timeIndex, index) => (
          <Fragment key={timeIndex}>
            <div
              style={{
                margin: 5,
                position: 'absolute',
                top: -40,
                left: `${index * 20}%`,
                height: 65,
                zIndex: 100,
                width: '20%',
                textAlign: 'center',
              }}
              data-testid={`timeindex-${timeIndex}`}
              onClick={() => setCurrentTimeIndex(timeIndex)}
            ></div>
            <div
              key={timeIndex}
              className={timeIndex === currentTimeIndex ? 'active label' : null}
              style={{
                margin: 5,
                position: 'absolute',
                top: index % 2 === 0 ? -45 : 7,
                left: `${index * 20}%`,

                width: '20%',
                textAlign: 'center',
              }}
            >
              {polyglot.t(`detailedanalysis.timeLine.${timeIndex}`)}
            </div>
            <div
              className={timeIndex === currentTimeIndex ? 'active' : null}
              style={{
                margin: 5,
                position: 'absolute',
                top: -16,
                left: `${index * 20}%`,
                textAlign: 'center',
                width: '20%',
              }}
            >
              <Icon
                name="minus"
                rotated="clockwise"
                style={{
                  position: 'absolute',
                  margin: 0,
                  top: index % 2 === 0 ? -7 : 18,
                  marginLeft: 4,
                }}
                size="tiny"
              />
              <Icon
                name={`dot circle ${
                  timeIndex !== currentTimeIndex ? 'outline' : 'outline'
                }`}
                style={{
                  margin: 0,
                }}
              />{' '}
            </div>
          </Fragment>
        ))}
      </div>
    )
  }
}

UserDetailedAnalysisTimeLine.propTypes = {
  polyglot: PropTypes.object,
  currentTimeIndex: PropTypes.string,
  setCurrentTimeIndex: PropTypes.func,
}
UserDetailedAnalysisTimeLine = withPolyglot()(UserDetailedAnalysisTimeLine)
export default UserDetailedAnalysisTimeLine
