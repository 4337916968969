import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Message,
  Icon,
  Grid,
  Button,
  List,
  Container,
  Segment,
} from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import moment from 'moment'
import { deleteMyPerf } from '../services/Api'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import AppError from '../components/AppError'
import { Link } from 'react-router-dom'

class PerfDisplaySet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  render() {
    let vals = this.props
    const { polyglot } = this.props

    const noValues = vals.perfArray.join('').toString() ? false : true
    if (this.props.isLoading) {
      return null
    }
    return (
      <Fragment>
        {this.state.hasError && <AppError />}
        <Container>
          <Link to="/userNewPerf">
            <Button circular icon="plus" className="bottom-10 app-button" />
          </Link>
        </Container>

        {noValues && (
          <Card
            fluid
            className={'card-dark bottom-30'}
            style={{ margin: 'auto' }}
          >
            <Card.Content>{polyglot.t('home.perf.none')}</Card.Content>
          </Card>
        )}
        {!this.state.hasError && (
          <Fragment>
            <Card.Group centered>
              {vals.perfArray.map((aPerf) =>
                aPerf.length ? (
                  <PerfDisplay perfdata={aPerf} key={aPerf[0]['_id']} />
                ) : null
              )}
            </Card.Group>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

class PerfDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayRoadMaps: [],
      perfsPr: 0,
      goalArray: [],
      realPerfsArray: [],
    }
    this.lastPerf = this.props.perfdata[0]
    this.goalArray = []
    this.realPerfsArray = []

    this.toggle = this.toggle.bind(this)
  }

  organizeData() {
    if (Array.isArray(this.props.perfdata) && this.props.perfdata) {
      this.props.perfdata.map((perf) => {
        if (perf.isReal) {
          this.realPerfsArray.push(perf)
        } else {
          this.goalArray.push(perf)
        }
        return null
      })
    }
    this.setState({ goalArray: this.goalArray })
    this.setState({ realPerfsArray: this.realPerfsArray })
    var lowest = Number.POSITIVE_INFINITY
    var tmp
    for (var i = this.realPerfsArray.length - 1; i >= 0; i--) {
      tmp = this.realPerfsArray[i].time
      if (tmp < lowest) lowest = tmp
    }
    this.setState({ perfsPr: lowest })
  }

  componentDidMount() {
    this.organizeData()
  }
  roadmap = () => {
    const time = this.lastPerf.time
    const distance = this.lastPerf.distance
    if (distance === 400) {
      let time100m = time / 4
      const roadmapArray = [1, 2]

      const timesheet = roadmapArray.map((d) => {
        return (
          <div key={d * 100}>
            <strong>{d * 100}m:</strong>{' '}
            {moment('1900-01-01 00:00:00')
              .add(d * time100m, 'seconds')
              .format('mm:ss')}
          </div>
        )
      })
      return timesheet
    }
    if (distance === 1600) {
      let time100m = time / 16
      const roadmapArray = [1, 2, 3, 4, 6, 8, 10, 12, 14]

      const timesheet = roadmapArray.map((d) => {
        return (
          <div key={d * 100}>
            <strong>{d * 100}m:</strong>{' '}
            {moment('1900-01-01 00:00:00')
              .add(d * time100m, 'seconds')
              .format('mm:ss')}
          </div>
        )
      })
      return timesheet
    }
  }
  call_deletePerf(perfId) {
    const { polyglot } = this.props
    return deleteMyPerf(perfId)
      .then((response) => {
        if (response.ok) {
          toast.success(polyglot.t('generic.message.done'), {})

          this.realPerfsArray = this.realPerfsArray.filter(function (obj) {
            return obj._id !== perfId
          })
          this.setState({ realPerfsArray: this.realPerfsArray })
        } else {
          toast.error(polyglot.t('generic.message.error'), {})
        }
      })
      .catch(() => {
        toast.error(polyglot.t('generic.message.error'), {})
        // store the new state object in the component's state
        //this.setState(newState);
      })
  }

  isRoadmapHidden(d) {
    return this.state.displayRoadMaps.indexOf(d) !== -1 ? false : true
  }
  toggle(d) {
    let newState
    if (this.state.displayRoadMaps.indexOf(d) !== -1) {
      let tmpArray = this.state.displayRoadMaps.join().split(d)
      newState = Object.assign({}, this.state, {
        displayRoadMaps: tmpArray,
      })
    } else {
      let tmpArray = this.state.displayRoadMaps.concat(d)
      newState = Object.assign({}, this.state, {
        displayRoadMaps: tmpArray,
      })
    }
    this.setState(newState)
  }

  render() {
    const { polyglot } = this.props
    return (
      <Card className="perf-display" fluid>
        <style>{`
					 .page	.segment {
						color: #ababab;
							 border-top: 1px solid #3a3939  !important;
							 border-right: 1px solid #3a3939 !important;
							 border-left: 1px solid #3a3939 !important;

							 border-bottom: 1px solid #3a3939 !important;
						 }
						 `}</style>
        <Card.Content className="pad-top-15">
          <Icon
            name="stopwatch"
            className="floated right"
            size="large"
            style={{ color: 'rgba(210, 209, 209, 0.4)' }}
          />
          <h3 className="color-white center-text uppercase bolder top-5">
            {this.lastPerf['distance']}
          </h3>
          <Card.Header className="top-10" />
          <Card.Meta>
            <strong>
              {this.lastPerf.isReal
                ? polyglot.t('home.perf.isReal')
                : polyglot.t('home.perf.isReal.not')}
            </strong>
            {' - '}
            {moment(this.lastPerf['date']).format('ll')}
          </Card.Meta>
          <Card.Description>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Segment compact basic style={{ margin: '10px auto 10px' }}>
                    <div
                      className=" "
                      style={{
                        fontSize: '20px',
                      }}
                    >
                      {moment('1900-01-01 00:00:00')
                        .add(this.lastPerf['time'], 'seconds')
                        .format('mm:ss')}{' '}
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Description>

          <h3
            className=" center-text  top-10"
            style={{ color: 'grey', fontSize: '1.1em' }}
          >
            {' '}
            {polyglot.t('home.perf.log')}
          </h3>
          <List divided inverted relaxed>
            {Array.isArray(this.state.realPerfsArray) &&
            this.state.realPerfsArray.length ? (
              this.state.realPerfsArray.map((perf, i) => {
                if (perf.isReal) {
                  return (
                    <List.Item key={i}>
                      <List.Content
                        floated="right"
                        style={{ paddingTop: '10px' }}
                      >
                        {
                          <Icon
                            name="trash"
                            color="red"
                            onClick={() => this.call_deletePerf(perf._id)}
                          />
                        }
                      </List.Content>
                      <List.Content>
                        <List.Header
                          style={{ fontSize: '16px' }}
                          className="bottom-5"
                        >
                          {' '}
                          {moment('1900-01-01 00:00:00')
                            .add(perf['time'], 'seconds')
                            .format('mm:ss')}
                          {perf['time'] === this.state.perfsPr ? (
                            <Icon
                              style={{
                                color: 'red',
                                position: 'absolute',
                                left: '25%',
                                paddingTop: '8px',
                              }}
                              name="bolt"
                            />
                          ) : null}
                        </List.Header>
                        <span style={{ color: '#d2d2d2' }}>
                          {moment(perf['date']).format('DD/MM/YYYY')}
                        </span>
                      </List.Content>
                    </List.Item>
                  )
                }

                return null
              })
            ) : (
              <Fragment>
                <Message color="black">
                  {' '}
                  {polyglot.t('generic.message.empty')}
                </Message>
              </Fragment>
            )}
          </List>
        </Card.Content>
      </Card>
    )
  }
}

PerfDisplaySet.propTypes = {
  perfArray: PropTypes.array,
  isLoading: PropTypes.bool,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
PerfDisplay.propTypes = {
  perfdata: PropTypes.array,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

PerfDisplay = withPolyglot()(PerfDisplay)
PerfDisplaySet = withPolyglot()(PerfDisplaySet)
export default PerfDisplaySet
