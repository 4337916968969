import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container as div, Header, Icon, Segment } from 'semantic-ui-react'
import { getProtocolsPlanByLabel, getSubscriptionStatus } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { withPolyglot } from '../vendor/polyglot-react'
import FocusModal from '../components/FocusModal'
import AppError from '../components/AppError'
import ReactGA from 'react-ga'
import SectionCard from '../components/SectionCard'
import AppCard from '../components/AppCard'

class UserPlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      session: [],
      hasError: 0,
      error: false,
      isLoading: true,
      view: 'byweek',
    }
    this.isTrainingPlan = ['5k', '10k', 'half', 'marathon'].includes(
      this.props.match.params.label
    )
  }

  async getData() {
    const response = await getProtocolsPlanByLabel(
      this.props.match.params.label
    )
    if (response.ok) {
      const plan = response.data
      const { weeks, history } = plan

      let protocols = plan.protocols.filter((x) => x.isAvailable)

      var currentFound = false
      let byWeek = {}
      if (this.isTrainingPlan) {
        byWeek = this.protocolsByWeek(weeks, protocols)
      }

      protocols.forEach((p) => {
        if (history.includes(p._id)) {
          p.status = 'done'
        } else if (!history.includes(p._id) && !currentFound) {
          p.status = 'current'
          currentFound = true
        }
      })

      // store the new state object in the component's state
      this.setState({
        protocols,
        plan,
        weeks,
        byWeek,
        isLoading: false,
        hasError: false,
      })
      //	this.setState({ redirectToReferrer: true })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  protocolsByWeek = (weeks, protocols) => {
    let byWeek = {}
    if (!weeks) return {}
    Object.keys(weeks)
      .sort((a, b) => b - a)
      .forEach((protocolId) => {
        let week = weeks[protocolId]
        let currentWeekSessions = byWeek['' + week.weekNumber]
        if (!Array.isArray(currentWeekSessions)) currentWeekSessions = []
        let protocolFromId = protocols.find(
          (x) => '' + x._id === '' + protocolId
        )
        if (!protocolFromId) return null
        currentWeekSessions.push({
          ...protocolFromId,
          order: week.order,
          weekNumber: week.weekNumber,
        })

        currentWeekSessions.sort((a, b) => a.order - b.order)

        byWeek['' + week.weekNumber] = currentWeekSessions
      })

    return byWeek
  }

  async componentDidMount() {
    var subscriptionStatus = await this.call_getSubscriptionStatus()
    sessionStorage.setItem(
      'RWJSubscriptionStatus',
      JSON.stringify(subscriptionStatus)
    )
    this.setState({ subscriptionStatus })

    this.getData()
    ReactGA.pageview(window.location.pathname + window.location.search)
    window.sessionStorage.setItem(
      'RWJLastPlanUrl',
      this.props.location.pathname
    )
  }
  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  render() {
    const { polyglot, location } = this.props
    const { plan, protocols, byWeek } = this.state

    let focus = location?.state?.focus

    let wheight = window.innerHeight - 100
    wheight += 'px'

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <Fragment>
        <div className={'page '} style={{ height: wheight, overflow: 'auto' }}>
          <div>
            <style>{`
				@media  (min-width: 768px)
				{
					.ui.relaxed.grid
					{
						margin-left: 0em!important;
						margin-right: 0!important;
					}
				}
				.segments{
					background: rgba(0, 0, 0, 0.6) !important;
					background: transparent !important;
				}
				.segments .segment {
					/*border-top: 1px solid #3a3939 !important;
					border-right: 1px solid #3a3939 !important;
					border-left: 1px solid #3a3939 !important;*/
					clear:both;
					height: 62px;
				}

				.segments .segment:after {
					border-bottom: 1px solid #1f1f1f;
					content: '';
					display: block;
					width: 75%;
					margin-left: auto;
					margin-right: auto;
				}

				.segments .segment .content-text
				{
					margin-left: 40px;
					height: 50px;
					display: flex;
					align-items: center;
				}
					.segment .image {
						margin-right:1em !important;
					}

					.page	.ui.avatar.image {
						width: 3.5em;
						height: 3.5em;
						margin-top: 0.2rem;
						margin-bottom: 0.2rem;
						float:left;
					}
					.page .ui.segment
					{
						padding:0.4em;
					}
						`}</style>
            <SectionCard
              asDrawer
              cardData={{ key: this.props.match.params.label }}
              history={this.props.history}
              asHeader={true}
            />
            <div className="bottom-20 " />

            {focus && !this.isTrainingPlan && <FocusModal focus={focus} />}

            {!this.isTrainingPlan && (
              <Segment.Group id="segment-container">
                {plan &&
                  protocols.length !== 0 &&
                  protocols.map((session) => (
                    <Segment
                      key={session._id}
                      id={session._id}
                      basic
                      textAlign="left"
                      onClick={() =>
                        session.hideLink
                          ? this.props.history.push(`/services`)
                          : session.status || !plan.isSequential
                          ? this.props.history.push(
                              `/plan/session/${session._id}`
                            )
                          : null
                      }
                      data-testid={
                        session.hideLink ? 'no-link' : 'link-to-protocol'
                      }
                      className={
                        session.status && !session.hideLink
                          ? 'color-white'
                          : 'color-grey'
                      }
                      style={session.status ? { cursor: 'pointer' } : {}}
                    >
                      <Icon
                        name={session.hideLink ? 'lock' : 'chevron right'}
                        color={session.hideLink ? 'blue' : 'grey'}
                        size="small"
                        className="floated right  top-20"
                        data-testid={
                          session.hideLink ? 'premium-lock' : 'link-to-item'
                        }
                      />

                      {!session.hideLink &&
                        (session.status === 'done' ? (
                          <Icon
                            name="check"
                            className="floated left green top-20"
                          />
                        ) : session.status === 'current' &&
                          plan.isSequential ? (
                          <Icon
                            name="lock open"
                            className="floated left white top-20"
                          />
                        ) : plan.isSequential ? (
                          <Icon
                            name="lock"
                            className="floated left grey top-20"
                          />
                        ) : (
                          ''
                        ))}

                      <div className="content-text">
                        {polyglot.has(`${session._id}.label`)
                          ? polyglot.t(`${session._id}.label`)
                          : polyglot.has(session.label)
                          ? polyglot.t(session.label)
                          : session.label}
                      </div>
                    </Segment>
                  ))}
              </Segment.Group>
            )}
            {plan &&
              this.isTrainingPlan &&
              Object.keys(byWeek)
                .sort((a, b) => b - a)
                .map((x) => (
                  <div key={x} className="bottom-20">
                    <Header> {`${polyglot.t('generic.week')} ${x}`}</Header>
                    {byWeek[x].map((session) => (
                      <span
                        onClick={() =>
                          session.hideLink
                            ? this.props.history.push(`/services`)
                            : this.props.history.push(
                                `/plan/session/${session._id}`
                              )
                        }
                        key={session._id}
                      >
                        <AppCard
                          id={session.id}
                          heightOverride="auto"
                          style={{ marginTop: 10 }}
                        >
                          {' '}
                          <Header
                            style={{
                              color: '#fff',
                              marginTop: -3,
                            }}
                            data-testid={session.personalizedFocus}
                          >
                            {polyglot.has(`${session._id}.label`)
                              ? polyglot.t(`${session._id}.label`)
                              : polyglot.has(session.label)
                              ? polyglot.t(session.label)
                              : session.label}
                            <Icon
                              name={session.hideLink ? 'lock' : 'chevron right'}
                              color={session.hideLink ? 'blue' : 'grey'}
                              data-testid={
                                session.hideLink ? 'lock' : 'link-to-item'
                              }
                              style={{
                                position: 'absolute',
                                right: 10,
                                fontSize: '.95rem',
                              }}
                            />
                          </Header>
                        </AppCard>
                      </span>
                    ))}
                  </div>
                ))}

            {plan && plan.protocols.length === 0 && (
              <span>{polyglot.t('generic.message.empty')}</span>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}
UserPlan.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
UserPlan = withPolyglot()(UserPlan)
export default UserPlan
