import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Grid,
  Segment,
  Message,
  Icon,
  Checkbox,
} from 'semantic-ui-react'
import { Redirect, Link } from 'react-router-dom'
import { signup } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { toast } from 'react-toastify'
import { Config } from '../config'
import 'react-toastify/dist/ReactToastify.min.css'
import ReactGA from 'react-ga'

const { localeOptions, translations, termsUrl } = Config
const registrationOpened = true
class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      country: '', //honeypot
      password_confirm: '',
      redirectToReferrer: false,
      hasError: false,
      error: false,
      locale: 'en-us',
      checked: false,
      canSubmit: true,
    }
  }

  toggle = () => this.setState((prevState) => ({ checked: !prevState.checked }))

  componentDidMount() {
    var locale =
      window.storage && window.localStorage.getItem('RWJlocale')
        ? window.localStorage.getItem('RWJlocale')
        : 'en-us'
    locale = window.location.href.includes('?lang=fr') ? 'fr-fr' : locale

    this.setState({ locale })
    ReactGA.pageview(window.location.pathname + window.location.search)
    if (localStorage.getItem('RWJToken')) {
      this.setState({ redirectToReferrer: true })
    }
  }

  async call_signup() {
    const { polyglot } = this.props
    let canSubmit = this.state.canSubmit
    const { firstName, lastName, email, password, checked, locale, country } =
      this.state

    if (country) {
      this.props.history.push('/login')
      return
    }

    if (!lastName || !firstName || !email || !password) {
      toast.error(polyglot.t('signup.message.fields.mandatory'))
      return
    }
    if (!canSubmit) {
      return
    }
    if (!checked) {
      toast.error(polyglot.t('signup.message.fields.terms'))
      return
    }

    const response = await signup({
      lastName,
      firstName,
      email,
      password,
      locale,
    })
    if (response.ok) {
      ReactGA.event({ category: 'Register', action: 'Register success' })
      localStorage.RWJToken = response.data.token
      localStorage.RWJRole = response.data.user.role
      localStorage.RWJUserId = response.data.user.id
      localStorage.RWJUserIsActive = response.data.user.isActive
      const newState = Object.assign({}, this.state, {
        hasError: false,
        redirectToReferrer: true,
      })
      this.props.forceNotificationUpdate()
      this.setState(newState)
    } else {
      var data = response.data
      var err_param = response.data.param
      var err_name = response.data.name
      if (err_param === 'email' && err_name === 'match') {
        data.message = 'signup.message.fields.email.wrong'
      } else if (err_param === 'email' && !err_name) {
        data.message = 'signup.message.fields.email.exists'
      } else if (err_param === 'password' && err_name === 'minlength') {
        data.message = 'signup.message.fields.password.short'
      }
      const newState = Object.assign({}, this.state, {
        hasError: true,
        error: response,
      })
      toast.error(polyglot.t(data.message))
      this.setState(newState)
    }
  }

  checkPassword() {
    if (this.state.password.length < 6) {
      this.setState({ passwordToShort: true, canSubmit: false })
    } else {
      this.confirmPassword()
    }
  }

  confirmPassword() {
    if (this.state.password !== this.state.password_confirm) {
      this.setState({ passwordsNoMatch: true, canSubmit: false })
    }
  }

  resetCheckPassword() {
    this.setState({
      passwordToShort: false,
      canSubmit: true,
    })
  }
  resetConfirmPassword() {
    this.setState({
      passwordsNoMatch: false,
      canSubmit: true,
    })
  }

  handleValue(e, key) {
    this.setState({ ...this.state, [key]: e.target.value })
  }

  handleLocale = (e, { value }) => {
    try {
      localStorage.getItem && (window.storage = localStorage)
    } catch (exception) {
      console.log('no localstorage')
    }
    if (window.storage) {
      window.localStorage.setItem('RWJlocale', value)
    }
    this.setState({ locale: value })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer, locale } = this.state
    var { polyglot } = this.props
    polyglot.locale(locale)
    polyglot.replace(translations[locale])

    if (redirectToReferrer) {
      return <Redirect to={from} />
    }
    return (
      <div className="signup-form">
        <style>{`
              .main-menu .menu-btn, .main-menu .menu-btn icon  {
								display:none!important;
							}
							.language-selector .icon
							{
								opacity:.5;
							}
							.h_country{
								display:none;
							}

						`}</style>
        <Grid
          textAlign="center"
          style={{ height: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            {!registrationOpened && (
              <Segment basic>
                <h1>Registration are not opened yet. Stay tuned !</h1>
              </Segment>
            )}
            {registrationOpened && (
              <Form size="large">
                <Segment basic>
                  <Form.Dropdown
                    trigger={
                      <span>
                        <Icon name="flag" style={{ marginRight: '0.5rem' }} />
                        {
                          localeOptions.find(
                            (x) => x.value === this.state.locale
                          ).text
                        }
                      </span>
                    }
                    className="language-selector"
                    fluid
                    value={this.state.locale}
                    options={localeOptions}
                    onChange={this.handleLocale}
                    placeholder={polyglot.t('profile.language')}
                  />
                  <Form.Input
                    className="top-25"
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder={polyglot.t('signup.firstname')}
                    onChange={(e) => this.handleValue(e, 'firstName')}
                    value={this.state.firstName}
                  />
                  <Form.Input
                    className="top-25"
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder={polyglot.t('signup.lastname')}
                    onChange={(e) => this.handleValue(e, 'lastName')}
                    value={this.state.lastName}
                  />
                  <Form.Input
                    className="top-25"
                    fluid
                    icon="at"
                    iconPosition="left"
                    placeholder={polyglot.t('signup.email')}
                    onChange={(e) => this.handleValue(e, 'email')}
                    value={this.state.email}
                  />
                  <Form.Input
                    className="top-25"
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder={polyglot.t('signup.password')}
                    type="password"
                    onChange={(e) => this.handleValue(e, 'password')}
                    onBlur={() => this.checkPassword()}
                    onFocus={() => this.resetCheckPassword()}
                    value={this.state.password}
                  />
                  {this.state.passwordToShort && (
                    <div
                      style={{ color: 'red', marginTop: -10, marginBottom: 10 }}
                    >
                      {' '}
                      {polyglot.t('signup.message.fields.password.short')}
                    </div>
                  )}
                  <Form.Input
                    className="top-25"
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder={polyglot.t('signup.password.confirm')}
                    type="password"
                    onChange={(e) => this.handleValue(e, 'password_confirm')}
                    onBlur={() => this.confirmPassword()}
                    onFocus={() => this.resetConfirmPassword()}
                    value={this.state.password_confirm}
                  />
                  {this.state.passwordsNoMatch && (
                    <div
                      style={{ color: 'red', marginTop: -10, marginBottom: 10 }}
                    >
                      {polyglot.t('signup.message.fields.password.no.match')}
                    </div>
                  )}

                  <Form.Input
                    className="h_country"
                    fluid
                    icon="at"
                    iconPosition="left"
                    onChange={(e) => this.handleValue(e, 'country')}
                    value={this.state.country}
                  />
                  <Checkbox
                    onChange={this.toggle}
                    checked={this.state.checked}
                    style={{ marginRight: '10px' }}
                  />
                  <span>
                    {polyglot.t('signup.message')}{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={termsUrl[locale]}
                      style={{ textDecoration: 'underline' }}
                    >
                      {polyglot.t('signup.terms')}
                    </a>
                  </span>

                  <Button
                    className="app-button top-10"
                    fluid
                    size="large"
                    onClick={this.call_signup.bind(this)}
                  >
                    {polyglot.t('signup.register')}
                  </Button>
                  <Link
                    className="top-10"
                    to="/login"
                    style={{ display: 'block' }}
                  >
                    <Button fluid size="large" color="grey" basic>
                      {polyglot.t('login.login')}
                    </Button>
                  </Link>
                </Segment>
              </Form>
            )}{' '}
          </Grid.Column>
        </Grid>
        {this.state.hasError && (
          <div>
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p> {this.state.error.data.message}</p>
            </Message>
          </div>
        )}
      </div>
    )
  }
}

Signup.propTypes = {
  polyglot: PropTypes.object,
  location: PropTypes.object,
  forceNotificationUpdate: PropTypes.func,
}

Signup = withPolyglot()(Signup)
export default Signup
