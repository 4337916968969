const fr_fr = {
  "undefined": "",
  "locale": "fr",
  "loaded.check": true,
  "generic": {
    "message.app.update": "Nouvelle version disponible !",
    "message.app.update.button": "Mettre à jour",
    "message.save": "Enregistrer",
    "message.refresh": "Rafraichir",
    "message.update": "Enregistrement effectué",
    "message.created": "Création reussie",
    "message.error": "Echec de l'opération",
    "message.done": "Modification effectuée",
    "message.empty": "Rien à afficher pour l'instant",
    "message.delete": "Ceci supprimera l'élément !",
    "message.notfound":
      "Désolé, impossible de trouver l'information demandée ...",
    "proud.partner": "Nos partenaires ",
    "select.empty": "- Sélectionner -",
    "select.label": "Selectionner",
    "phone.use.title": "Conçu pour Mobile",
    "phone.use": `Molokoy est conçue pour une utilisation mobile et l'avoir à portée, n'importe où, n'importe quand!
 Démarrez votre navigateur mobile et allez à :`,
    "or": "ou",

    "today": `aujourd'hui`,
    "week": "Semaine",
  },
  "error": {
    "oops": "Oups!",
    "message": "Petit souci ...",
    "retry": "Réessayer",
    "unknown": "Erreur inconnue",
    "wrong.video.ext": `Veuillez sélectionner un fichier vidéo (.mov ou .mp4)`,
    "wrong.image.ext": `Veuillez sélectionner un fichier vidéo (.jpg, .jpeg, .png ou .gif)`,
  },
  "focus": {
    pose: "Pose",
    fall: "Chute",
    pull: "Tirage",
    neutral: "Neutre",
  },

  "sidebar": {
    "home": "Accueil",
    "trainings": "Entrainements",
    "profile": "Profil",
    "notification.center": "Centre de Notification",
    "courses": "Séances",
    "athletes": "Athlètes",
    "analyses": "Analyse Technique",
    "faults": "Erreurs",
    "clues": "Conseils",
    "drills": "Educatifs",
    "protocols": "Protocoles",
    "protocols.plans": "Plans",
    "update.check": "Rechercher les mises à jour",
    "help": `Besoin d'aide ?`,
    "faq": `FAQ`,
    "faq.url": "https://molokoy.io/faq",
  },

  "athlete": {
    details: "Details",
    home: "Accueil",
    courses: "Entrainements",
  },

  "app": {
    "ios.prompt.title": `Ajouter à l'écran d'accueil`,
    "ios.prompt.body": `Ce site est une Application Légère(PWA).
 Ajoutez la à votre écran d'accueil pour un accès rapide, en plein écran.`,
    "ios.prompt.shareButtonLabel": `1) Activer le bouton "Partager`,
    "ios.prompt.addHomeButtonLabel": `2) Sur l'écran d'accueil" `,
    "ios.prompt.closePrompt": "Annuler",
  },

  "login": {
    "login": "Connexion",
    "email": "Adresse email",
    "password": "Mot de passe",
    "signup": "S'inscrire",
    "password.forgot": "Mot de passe oublié",
    "message.error": "Echec d'authentification",
  },

  "forgot": {
    "email": "Adresse email",
    "reset": "Reinitialiser",
    "login": "S'identifier",
    "password.new": "Nouveau mot de passe",
    "message.error": "Cette adresse est inconnue!",
    "message.mail.sent": `Un email vous a été envoyé à l'adresse %{email} afin de réinitialiser votre mot de passe.
Vérifiez si il n'est pas arrivé dans votre dossier "Indésirables".`,
    "message.password.changed":
      "Votre mot de passe a bien été changé, vous pouvez maintenant vous reconnecter",
    "message.error.invalid.link": "Lien de réinitialisation non valide !",
    "password.change": "Changer",
    "message.fields.password.short":
      "Votre mot de passe devrait contenir au moins 6 caractères",
  },

  "signup": {
    "firstname": "Prénom",
    "lastname": "Nom",
    "email": "Adresse email",
    "password": "Mot de passe",
    "password.confirm": "Mot de passe (encore)",
    "register": "S'inscrire",
    "login.go": "Déjà inscrit ? Connectez vous ici",
    "message.fields.mandatory":
      "Veuillez renseigner les champs obligatoires (*)",
    "message.fields.email.wrong": "Email non valide",
    "message.fields.email.exists": "Email déja enregistré",
    "message.fields.password.short":
      "Votre mot de passe doit contenir au moins 6 caractères",
    "message.fields.password.no.match":
      "Les mots de passe ne correspondent pas",
    "message.fields.terms": `Veuillez accepter les conditions d'utilisation`,
    "message": `J'ai lu et j'accepte les`,
    "terms": `conditions d'utilisation`,
  },
  "ruffier": {
    "title": "Test de Ruffier",
    "heart.rate.instructions": {
      1: `Allongez-vous pendant 3 minutes, puis saisissez votre fréquence cardiaque`,
      2: `Faites 30 squats complets en 45 secondes, puis saisissez immédiatement votre fréquence cardiaque`,
      3: `Allongez-vous pendant 1 minute, puis saisissez votre rythme cardiaque`,
    },
    "heart.rate": {
      1: "Pulsations/minute",
      2: "Pulsations/minute",
      3: "Pulsations/minute",
    },
    "score.label": {
      100: `Très bonne adaptation à l'effort`,
      75: `Bonne adaptation à l'effort `,
      50: `Adaptation à l'effort moyenne`,
      25: `Adaptation à l'effort insuffisante`,
      5: `Mauvaise adaptation à l'effort,`,
    },
    "compute": "Calculer",
    "reset": "Réinitialiser",
  },

  "passwordChange": {
    "page.title": "Changer de mot de passe",
    "password.actual": "Mot de passe actuel",
    "password.new": "Nouveau Mot de passe",
    "password.confirm": "Nouveau mot de passe (encore)",
    "change": "Modifier",
    "error": "Echec, vérifier le mot de passe",
  },
  "account": {
    "page.title": "Compte",
    "general.settings": "Réglages généraux",
    "tools": "Pratique",
    "premium": `Premium jusqu'au %{endDate}`,
    "no.valid.premium": "Devenez Premium",
    "analysis.available": `Analyses restantes : %{analysesAvailable}`,
    "no.analysis.available": "Faites analyser votre technique",
    "last.analysis.date": "Analyse précédente :",
    "next.analysis.date": "Prochaine analyse :",
    "next.analysis.date.available": "Disponible",
    "manage.stripe": `Abonnement et paiement`,

    "find.us": "Retrouvez nous",
    "personal.infos": "Informations personnelles",
    "services": "Mes services",
    "my": "My account",
    "password.change": "Changer de mot de passe",
    "sign.out": "Déconnexion",
    "delete": "Supprimer mon compte",
    "delete.warning": `Cette action supprimera votre compte et toutes les données associées. Elle est irréversible.
Voulez vous continuer ?
`,
    "delete.cancel": "Non, conserver mon compte",
    "delete.confirm": "Oui, supprimer",
  },
  "profile": {
    "profile": "Informations personnelles",
    "firstname": "Prénom",
    "lastname": "Nom",
    "email": "Adresse email",
    "gender": "Sexe",
    "age": "Age",
    "year": "Année",
    "month": "Mois",
    "day": "Jour",
    "country": "Pays",
    "language": "Langue",
    "distance.unit": "Unité de distance",
    "distance.mile": "Mile",
    "distance.kilometer": "Kilometre",
    "message.update.done": "Profil mis à jour",
  },
  "M": "Homme",
  "F": "Femme",
  "O": "Autre",

  "pending": {
    label: "Bienvenue %{firstName} !",
    content: `Votre inscription est en cours de validation.
Encore un peu de patience !`,
  },

  "home": {
    "shoesPrescription": "Conseil chausures",
    "coach": "Coach :",
    "slowingFactor": "Coeff ralentissement :",
    "improvement.area": "Axe d'amélioration :",
    "improvement.area.speed": "Vitesse",
    "improvement.area.endurance": "Endurance",
    "action.times": "Mes chronos",
    "action.times.add": "Ajouter",
    "action.training.next": "Prochaine séance",
    "action.training.sessions": "Séances",
    "action.runner.profile": "Profil coureur",
    "action.course": "Séance",
    "action.book": "Réserver",
    "action.analysis": "Mes Analyses Techniques",
    "action.drills": `Bibliothèque d'Exericices`,
    "action.learn": `Online course`,
    "action.programs": `Programmes`,
    "action.stats": "Stats",
    "action.paceSpeed": `Convertisseur d'allure`,
    "action.warmup.technique": "Echauffement & Technique",
    "action.auto.masssage": "Auto Massage (bientôt)",
    "action.analysis.get": "Faire analyser ma technique",
    "action.premium.get": "Devenez premium !",
    "action.analysis.wait": "%{days} jours avant la prochaine analyse",
    "action.analysis.inprogress.0": `C'est pour aujourd'hui !`,
    "action.analysis.inprogress.1":
      "Analyse disponible demain, ou plus tôt :-)",
    "action.analysis.inprogress": "Analyse disponible dans %{days} jours ...",
    "action.analysis.inprogress.late": `Oups, on dirait que j'ai été submergé ...`,
    "action.analysis.ready": `Votre analyse est disponible !!!`,
    "action.analysis.invalid":
      "Vidéo à refaire, details dans les notifications",
    "action.daily.cue": "Conseil du jour",
    "class.next": "Prochaine séance",
    "class.next.none": "Pas d'entrainement à afficher pour l'instant",
    "perf.time": "temps",
    "perf.date": "date",
    "perf.distance": "distance",
    "perf.new": "Nouveau chrono",
    "perf.isReal": "Réalisé",
    "perf.isReal.not": "Allure de travail",
    "perf.new.hour": "heures",
    "perf.new.minute": "minutes",
    "perf.new.seconds": "secondes",
    "perf.all": "Derniers chronos",
    "perf.log": "Performances précédentes",
    "perf.none": "Aucun chrono à afficher pour l'instant !",
    "perf.error.submission": "Veuillez renseigner les champs obligatoires",
  },

  "usertraining": {
    "course.warmup": "Echauffement",
    "course.technique": "Technique",
    "course.warmup.technique": "Echauffement & Technique",
    "course.training": "Entrainement",
    "course.focus": "Focus / Commentaires",
    "course.cooldown": "Retour au calme",
    "course.speed": "Vitesse",
    "course.pace": "Allure",
  },

  "paceSpeed": {
    minutes: "minutes",
    seconds: "secondes",
    Semi: "Semi",
  },

  "metronome": {
    title: "Métronome",
  },
  "faults": {
    label: "Axe d'amélioration :",
    scratching_landing: "Frottements à l'atterrissage",
    overpulling: "Tirage exagéré",
    excessive_arm_movement: "Mouvement excessif des bras",
    vertical_oscillation: "Oscillation verticale",
    resistance_to_falling: "Resistance à la chute",
    active_landing: "Atterrissage actif",
    heel_striking: "Attaque talon",
    bending_waist: "Flexion à la hanche",
    overstriding: "Amplitude de foulée exagérée",
    pulling_late: "Tirage tardif",
    noisy_landing: "Atterrissage bruyant",
  },

  "AdviceEdit.title": "Le conseil du coach",

  "runnerprofile": {
    "runner.profile": "Profil coureur",
    "fatigueFactor": "Facteur de fatigue",
    "noPerfs": `Données non disponibles,
 saissez vos temps sur 400m and 1600m`,
    "improvement.area": "Axe d'amélioration",
    "improvement.area.speed": "Vitesse",
    "improvement.area.endurance": "Endurance",
    "main.deviation": `Ecart technique identifié`,
    "technique.focus": `Focus technique configuré`,
    "perf.all": "Derniers chronos",
  },

  "courses.new": "Nouvelle séance",

  "course": {
    "isAvailable": "Publié",
    "isAvailable.not": "Brouillon",
    "attendees": "Participants",
    "group": "Groupe",
    "group.or.new": " Ou créer ",
    "flexibility": "Flexibilité",
    "warmup": "Echauffement",
    "warmup.drills": "Echauffement & Technique",
    "training": "Entrainement",
    "drills": "Educatifs",
    "technique": "Technique",
    "strength": "Renforcement",
    "selfcare": "Selfcare",
    "mobility": "Mobilité",
    "preview": "Aperçu",
    "lesson": "Leçon",
    "focus": "Focus/Commentaires",
    "cooldown": "Retour au calme",
    "athletes.my": "Mes athlètes",
    "description": "Description",
    "publicDescription": "Description publique",
    "trainingLogs": "Commentaires",
    "drill.name": "Nom",
    "drill.category": "Categorie",
    "drill.description": "Description",
    "drill.duration": "Durée",
    "drill.video.url": "Video",
    "notification.default.title": "Nouvelle séance mise en ligne",
    "notification.default.message":
      "Bonjour, la prochaine séance est disponible!",
  },

  "help": {
    "focus.header": "Focus ",
    "focus.content.pose": `L'analyse technique indique que l'axe d'amélioration principal est le travail de Pose.`,
    "focus.content.fall": `L'analyse technique indique que l'axe d'amélioration principal est le travail de chute (Fall).`,
    "focus.content.pull": `L'analyse technique indique que l'axe d'amélioration principal est le travail de tirage (Pull).`,
    "focus.content.neutral": `L'analyse technique indique que vous n'avez pas d'axe de travail prédominant.
(Ou, que votre analyse est en cours de traitement )`,
    "focus.content.generic.pose": `Les protocoles d'échauffement et de travail techniques générés seront spécifiques à l'amélioration de la composante Pose.
Ils intégreront plus d'éducatifs qui aideront à arriver plus vite en Pose, avec plus de précision.`,
    "focus.content.generic.fall": `Les protocoles d'échauffement et de travail techniques générés seront spécifiques à l'amélioration de la composante chute (Fall).
Ils intégreront plus d'éducatifs qui aideront à utiliser plus efficacement la gravité et gagner en vitesse.`,
    "focus.content.generic.pull": `Les protocoles d'échauffement et de travail techniques générés seront spécifiques à l'amélioration de la composante tirage (Pull).
Ils intégreront plus d'éducatifs qui vous aideront à diminuer le temps de support au sol et aideront à gagner en endurance.`,
    "focus.content.generic.neutral": `Les protocoles d'échauffement et de travail techniques générés travailleront l'ensemble des composantes de la foulée, Pose, chute (Fall), tirage (Pull).
Ils vous aideront à améliorer la précision de la Pose, l'utilisation de la gravité et l'endurance.`,
    "cadence.header": "Cadence",
    "cadence.content": `La cadence est le nombre de foulées par minutes.
La valeur la plus petite recommandée est 180.
Cette cadende (ou plus) permet d'utiliser les propriétés élastiques de nos muscles.
L'utilisation de ces propriétés élastiques des muscles, après un temps d'adaptation, permet une réduction de la consommation d'oxygène de 20% .`,
    "slowingFactorAndProfile.header":
      "Coefficient de ralentissement et axe de travail",
    "slowingFactorAndProfile.content": `Le coefficient de ralentissement est calculé avec vos dernières performances réalisées sur 400 et 1600m.
  Si elles sont espacées de:
  - moins de 30 jours, le coefficient est considéré comme fiable(coche verte),
  - moins de 60 jours, moyennement fiable (signe moins orange),
  - au dela de 60 jours, peu fiable (point d'exclamation rouge).
  Un coefficient de 20 et en dessous indique un manque vitesse.
  Un coefficient au dessus de 20 indique un manque d'endurance.`,
    "framecount.header": "Décompte d'image",
    "framecount.content": `Le décompte d'image est un des outils aidant à estimer le niveau technique d'une foulée.
Le score théorique idéal avec une caméra filmant à 60 image par secondes est de 2:2:
- 2 images (Frames) entre le contact initial et la Pose de course (le votre est de %{framesToPose})
- 2 images (Frames) entre la Pose de courser et le décollage du pied au sol (le votre est de %{framesFromPose})`,
    "faults.overstriding.content": `Une amplitude trop grande, diminue l'efficacité.
  Un point de contact avec le sol trop en avant provoque un effet de frein.
  Une tentative de poussée, augmente le temps nécessaire à revenir en Pose `,
    "faults.scratching_landing.content": "Frottements à l'atterrissage",
    "faults.overpulling.content": `Le tirage exagéré est une erreur normale, induite par la volonté de bien faire en tirant le pied du sol.
Toutefois la hauteur du tirage doit dépendre de l'angle de chute. Un tirage excessif provoquera une fatigue précoce et inutile`,
    "faults.excessive_arm_movement.content": "Mouvement excessif des bras",
    "faults.vertical_oscillation.content": `L'oscillation est une perte d'efficacité dans un mouvement qui se veut le plus linéaire possible.
  Elle peut etre dûe à une tentative de poussée soit au niveau du pied, soit au niveau du quadriceps.
  Une autre raison possible est une tension continue du pied avec le talon qui ne touche jamais le sol`,
    "faults.resistance_to_falling.content": `La Resistance à la chute est le principal frein à la progression en vitesse.
  Le travail technique sur la chute en avant est primordial, car la peur de la chute est naturelle chez l'adulte.`,
    "faults.active_landing.content": `L'atterrissage actif est une tentative d'éviter l'attaque talon en posant l'avant du pied de façon
exagérée, cela entraine en général une tension inutile du pied et un atterrissage souvent en avant du corps induisant une tension inutile
de la voute plantaire, et un temps de support inutilement long`,
    "faults.heel_striking.content": `L'attaque par le talon, provoque un frein à l'avancement,
  provoque une charge excessive au niveau des articulations du genou, de la hanche, et des disques intervertébraux.
  C'est la cause majoritaire des blessures chez le coureur.`,
    "faults.bending_waist.content": `La position idéale pour utiliser la gravité est POSE où sont alignés, l'avant pied, la hanche épaules.
   La flexion à la hanche brise cet alignement, diminue le potentiel de chute, et donc la vitesse`,
    "faults.pulling_late.content": `Un tirage tardif, indique que l'action de tirage intervient trop tard dans le timing de la foulée, indiquant soit:
  - une tentative de poussée
  - soit une trop grande attention sur l'atterrisage`,
    "faults.noisy_landing.content": "Atterrissage bruyant",
    "gear.available.content": `Si certains équipements ne sont pas disponibles, Molokoy ne proposera pas de session les incluant.
Toutefois, un équipement disponible ne sera pas forcément inclus dans la session proposée.`,
  },

  "notifications.none": "Pas de notification à afficher pour l'instant !",
  "notifications.item.deleted": "Notification supprimée !",

  "notification": {
    "athletes.my": "Mes athlètes",
    "sendTo": "Destinataires",
    "notification": "Notification",
    "notifications": "Notifications",
    "content": "Contenu",
    "title": "Titre",
    "message": "Message",
  },
  "user.notification": {
    "welcome.title": "Bienvenue sur Molokoy, %{firstName}!",
    "welcome.content": `Molokoy a été conçue  pour vous accompagner dans votre projet de devenir un meilleur coureur,
J'espère qu'elle vous aidera tout au long de votre chemin.
N'hésite pas à me faire des retours et remarques, envoie un mail a help@molokoy.io.
A bientôt en vidéo,
Jey.`,
    "quick.access.title": `Astuce : Ajout à l'écran d'accueil`,
    "quick.access.content": `Pour une utilisation plus fluide, ajoutez Molokoy à l'écran d'accueil de votre smartphone, vous y accéderez comme une application classique.
 `,
    "video.received.title": `%{firstName}! Vidéo reçue !`,
    "video.received.content": `Les sessions génériques d'échauffement et de travail technique sont d'ores et déja disponibles.
Il vous suffit d'utiliser le lien "Vos échauffement & éducatifs du jour" pour profiter d'une session guidée!
Une fois l'analyse réalisée ces protocoles seront personnalisés.
A bientôt en vidéo,
Jey.`,
    "video.ready.title": `L'analyse est prête !`,
    "video.ready.content": `Elle est accessible par le premier bouton du menu principal.
Désormais, toutes les sessions seront personalisées et cibleront votre axe d'amélioration principal !
A bientôt en vidéo,
Jey.`,

    "analysis.8.reminder.title": `Il est temps de refaire une analyse !`,
    "analysis.8.reminder.content": `Cela fait longtemps que vous n'avez pas fait d'analyse, voyons comment votre technique a évolué !
N'oubliez pas de m'envoyer une vidéo,
Jey.`,

    "video.invalid.title": `Votre video n'est pas exploitable :-(`,
    "video.invalid.content.1": `Je suis désolé, mais je n'ai pas pu analyser la vidéo, je ne vois pas assez de foulées.
Voulez-vous bien refaire une video, avec le téléphone en mode PAYSAGE ?
Merci !
Jey.`,

    "video.invalid.content.2": `Je suis désolé, mais je n'ai pas pu analyser la vidéo.
Est-il possible de refaire une vidéo en tenant compte des indications, que le coureur soit bien filmé de côté et qu'il coure de la GAUCHE vers la DROITE.
Merci !
Jey.`,

    "video.invalid.content.3": `Je suis désolé, mais je n'ai pas pu analyser la vidéo.
Est-il possible de refaire une vidéo en tenant compte des indications, que les pieds du coureur soient clairement visibles à chaque instant ?
 Merci !
Jey.`,
  },

  "drillByCategory": {
    title: "Educatifs",
    warmup: "Echauffement",
    pose: "Pose",
    fall: "Fall",
    pull: "Pull",
    combined: "Combinés",
    strength: "Renforcement",
    correction: "Correction",
    flexibility: "Flexibilité",
  },

  "drillCreate": {
    "name": "Nom de l'éducatif",
    "category": "Categorie",
    "description": "Description",
    "image.url": "Url de l'image",
    "video.url": "Url de la vidéo",
    "duration": "Durée",
    "gear": "Equipement",
  },

  "sessionElement": {
    setup: "Position de départ",
    execution: "Exécution",
    focus: "Point d'attention",
    instructions: "Instructions",
    repetitions: "Repetitions",
  },

  "usertrainings": {
    none: "Pas d'entrainement à afficher pour l'instant !",
    nextWeeks: "Prochaines semaines",
  },

  "traininglog": {
    "comments.label": "Commentaires",
    "comments.placeholder": "Chronos, sensations ...",
    "done.label": "Réalisée",
    "done.not.label": " Non réalisée",
    "date.label": "le",
  },
  "protocolsPlan": {
    new: "Nouveau plan",
    protocols: "Protocoles",
  },
  "protocol": {
    "page.title": "Echauffement & Technique",
    "warmup": "Echauffement",
    "drills": "Technique",
    "technique": "Technique",
    "strength": "Renforcement",
    "selfcare": "SelfCare",
    "selfCare": "Auto-Soins",
    "flexibility": "Flexibilité",
    "lightFlexibility": "Assouplissements légers ",
    "cooldown": "Retour au calme",
    "workout": "Entrainement",
    "focus": "Focus",
    "pose": "Pose",
    "fall": "Fall",
    "pull": "Pull",
    "neutral": "Neutral",
    "new": "Nouveau protocole",
    "isAvailable": "Publié",
    "isAvailable.not": "Brouillon",
    "suggestRunWarmup": "Running Warmup",
    "copy": "Dupliquer",
    "delete": "Supprimer",
    "isSequential.not": "Non sequentiel",
    "isSequential": "Sequentiel",
    "type": "Type",
    "gear.needed": "Matériel nécessaire :",
    "gear.available": "Sélectionnez votre matériel disponible",
    "gear.additional": "Matériel additionnel disponible",
    "gear.roller": "Rouleau de massage",
    "gear.lacrosse": "Balle de massage type Lacrosse",
    "gear.bar": "Barre d'halterophilie",
    "gear.wall": "Mur à proximité",
    "gear.wall.details": `ou quelque chose contre quoi s'appuyer`,
    "gear.jump rope": "Corde à sauter",
    "gear.band": "Elastique",
    "generate": "Créer la session du jour",
    "start": "Allons y !",

    "workout.amrap": "Pendant %{amrap}",
    "workout.repeat": "Répéter %{repeat} fois",
    "workout.repeats": "Répéter %{repeat} fois",
    "workout.at.cadence": "à une cadence de ",
    "workout.rest.for": "recupérez ",
    "workout.drill": "Educatif : ",
    "workout.drill.not.available":
      "Votre exercice personnalisé sera disponible dès que votre analyse sera disponible.",
    "workout.into": " enchainé avec ",
    "workout.run.for": "Courir : ",
    "include.prerun": "Inclure mon échauffement & travail technique",
    "include.prerun.start": "Commencer",
    "pace": {
      "faster": "très rapide",
      "faster.detail": "Sprint maintenable sur toute la série",
      "fast": "rapide",
      "fast.detail": "Allure Mile (1600m)",
      "tempo": "tempo",
      "tempo.detail": "Une allure plutôt exigeante mais soutenable.",
      "moderate.fast": "moderé/rapide",
      "moderate.fast.detail": "Allure 5k",
      "moderate": "moderé",
      "moderate.detail": "Allure 10k",
      "easy": "facile",
      "easy.detail": "Plus lent que votre allure Marathon",
      "slow": "lent",
      "slow.detail": "Allure de récupération",
      "race pace": "Allure de course",
      "race pace.detail": "Votre allure cible pour la course préparée",
      "race pace ++": "Allure de course ++",
      "race pace ++.detail":
        "Légèrement plus rapide que votre allure cible pour la course préparée",
    },
  },
  "workout.edit": {
    repeat: "Répétition",
    distance: "Distance",
    time: "Temps",
    drill: "Educatif",
    drillPose: "Pose Educ.",
    drillFall: "Fall Educ.",
    drillPull: "Pull Educ.",
    custom: "Personnalisé",
    cadence: "Cadence",
    pace: "Allure",
    rest: "Récup",
    instruction: "Instruction",
  },

  "analysisCreate": {
    athlete: "Nom de l'athlète",
    urlCode: "Code de la vidéo Youtube",
  },

  "analysis": {
    "analysis": "Analyse",
    "update.needed": "Nouvelles fonctionnalités  !",
    "update.explanation": `Nous avons publié un nouveau format, avec de nouvelles fonctionnalités pour vous aider à mieux comprendre vos analyses.
L'ancien format n'est plus disponible, mais vous pouvez obtenir une mise à jour gratuite de cette analyse!`,
    "update.get": "Mettre à jour!",
    "update.inprogress": "Mise à jour en cours!",
    "update.inprogress.explanation": `La mise à jour de votre analyse sera disponible dans un jour ou deux !`,
    "fps": "images/s",
    "isAvailable.not": "Brouillon",
    "isAvailable": "Publié",
    "isInvalid": "Invalide",
    "framecount": "Décompte d'images",
    "frameToPose": "Images jusqu'à Pose",
    "frameFromPose": "Images en Pose",
    "display": "Afficher l'analyse",
    "score.display": "Masquer l'analyse",
    "technique.score": "Score Technique",
    "time.initialContact": "Contact initial",
    "time.inPose": "Pose",
    "time.endFall": "Fin de chute",
    "time.lastContact": "Dernier contact",
    "time.pulling": "Milieu du tirage ",
    "count.frames": "Décompter les images",
    "execution.cadence": "Pas/min",
    "cadence.mark": "Repère",
    "cadence.compute": "Calculer",
    "unified": "Unifié",
    "dissociated": "Dissocié",
    "right": "Droite",
    "left": "Gauche",
    "support.foot": "Pied au sol",
    "video": "Analyse détaillée",
    "full": `Voir l'analyse`,
    "initialContactTime": "Landing",
    "inPoseTime": "Pose",
    "fallEndTime:": "Fall",
    "lastContactTime": "Take off",
    "middleOfPullTime": "Tirage",
    "initialContactTimeL": "Landing",
    "inPoseTimeL": "Pose",
    "fallEndTimeL": "Fall",
    "lastContactTimeL": "Take off",
    "middleOfPullTimeL": "Tirage",
    "cadenceMark0": "Cad. 0",
    "cadenceMark3": "Cad. 3",
    "cadenceMark4": "Cad. 4",

    "execution.pose": "POSE",
    "execution.framesToPose": "Aterrissage -> Pose",
    "execution.poseExecutionLanding": "Contact initial",
    "execution.poseExecutionLanding.1": "Loin du corps",
    "execution.poseExecutionLanding.2": "Proche du corps",
    "execution.poseExecutionLanding.3": "Sous le corps",
    "execution.poseFramesToPose": `Images jusqu'à Pose`,
    "execution.poseExecutionKnee": "Genou fléchi",
    "execution.poseExecutionKnee.1": "Vérouillé",
    "execution.poseExecutionKnee.3": "Déverouillé",
    "execution.poseExecutionVertical": "Verticalité en Pose",
    "execution.poseExecutionVertical.1": "Tres en avant ",
    "execution.poseExecutionVertical.2": "Un peu en avant",
    "execution.poseExecutionVertical.3": "Verticale",
    "execution.fall": "CHUTE (fall)",
    "execution.fallExecutionAlignment": "Alignement du corps",
    "execution.framesFalling": "Temps de chute",
    "execution.fallExecutionAlignment.0": "Epaules en arrière",
    "execution.fallExecutionAlignment.1": "Fléchi à la hanche",
    "execution.fallExecutionAlignment.2": "Légèrement fléchi à la hanche",
    "execution.fallExecutionAlignment.3": "Conservé",
    "execution.fallExecutionTime": "Maintient de Pose",
    "execution.fallExecutionTime.1": "Court",
    "execution.fallExecutionTime.2": "Moyen",
    "execution.fallExecutionTime.3": "Long",
    "execution.fallExecutionFrames": `Nombre d'images de Fall`,
    "execution.pull": "TIRAGE (pull)",
    "execution.pullExecutionTiming": "Images sur support après Fall (timing)",
    "execution.framesOnSupportAfterFalling": "Temps de support inutile",
    "execution.pullExecutionTiming.1": "Tardif",
    "execution.pullExecutionTiming.2": "Moyen",
    "execution.pullExecutionTiming.3": "Bon",
    "execution.pullExecutionLegBent": "Jambe arrière fléchie",
    "execution.pullExecutionLegBent.1": "Complètement tendue",
    "execution.pullExecutionLegBent.2": "Presque tendue",
    "execution.pullExecutionLegBent.3": "Fléchie",
    "execution.pullExecutionDirection": "Direction du tirage",
    "execution.pullExecutionDirection.0": "Tres en arriere/pas de tirage",
    "execution.pullExecutionDirection.1": "En arriere",
    "execution.pullExecutionDirection.2": "Légèrement en arriere",
    "execution.pullExecutionDirection.3": "Vers la hanche",
    "execution.pullBreakingAngle": "Angle de Freinage",
    "execution.notes": "Remarques",
    "fault.focus": "Erreur & Focus technique",
    "fault": "Erreur principale",
    "focus": "Axe de travail principal",
    "focus.display": "Focus",
    "video.loading": "Chargement de votre vidéo",
    "video.searching": "On y est presque...",
  },

  "cues": {
    "label": "Le conseil du coach",

    "general.loose.ankle": `Appliquez vous à maintenir la cheville relâchée, une tension excessive en l'air ne fera qu'exercer une pression inutile sur votre jambe inférieure. Votre cheville s'adaptera naturellement à la tension nécessaire lorsqu'elle sera en charge.`,
    "general.less.noise": `Essayez de courir aussi silencieusement que possible. Trop de bruit à l'atterrissage signifie un manque d'absorption des impacts. En essayant de courir silencieusement, vous contribuerez inconsciemment à réduire les chocs au sol.`,
    "general.heels.touch": `Laissez vos talons effleurer le sol à chaque foulée, afin de libérer la tension du tendon d'Achille. N'essayez pas de courir uniquement sur la plante de votre pied.`,

    "pose.alignement": `Gardez le buste droit en regardant loin vers l'avant. Lorsque vous êtes penché en avant avec les fesses en arrière, vous ne pouvez pas chuter efficacement vers l'avant.`,
    "pose.bodyweight": `Sentez le poids de votre corps sur l'avant-pied, mais laissez votre talon effleurer le sol à chaque foulée.`,
    "pose.knee.bent.reaching": `Gardez le genou de votre jambe d'appui fléchi lorsque vous courez, cela évite que votre jambe ne se tende vers l'avant en provoquant un effet de freinage néfaste.`,
    "pose.knee.bent": `Gardez vos genoux pliés lorsque vous courez afin de diminuer les impacts lors de l'atterrissage et tirer parti de l'élasticité musculaire.`,

    "fall.from.hips": `Sentez la chute à partir des hanches, et non des épaules, avec un torse droit. C'est la manière correcte d'utiliser la gravité pour créer de la vitesse lorsque l'on court.`,
    "fall.intention": `Gardez à l'esprit votre "intention" de vous laisser chuter vers l'avant quelle que soit la vitesse et surtout lorsque vous commencez à être fatigué.`,
    "fall.waist.pulled": `Imaginez que vous êtes tiré vers l'avant par une corde attachée à votre taille. Cela vous aidera à sentir comment le fait de déplacer vos hanches vers l'avant crée du mouvement et de la vitesse.`,
    "fall.minimal.effort": `Laissez-vous chuter intentionnellement et laissez la gravité vous tirer vers l'avant, utilisez le moins d'effort musculaire possible.`,

    "pull.actively": `Tirez activement votre pied d'appui vers vos hanches pour revenir en POSE, ne le laissez pas traîner derrière, cela empêche le bon timing de votre foulée et vous ralentit.
    `,

    "pull.active.landing": `Tirez votre pied d'appui en ligne droite vers vos hanches, ne vous concentrez pas sur la façon dont votre autre pied atterrit. Cela vous aidera à ne plus avancer exagérément le pied vers l'avant en essayant activement d'atterrir sur l'avant-pied.`,
    "pull.support.foot.first": `Concentrez-vous uniquement sur le fait de décoller votre pied au sol avant votre pied en suspension. Ne vous concentrez pas du tout sur la façon dont votre pied atterrit.`,

    "pull.too.high": `Conservez votre timing de votre tirage qui est bon, mais réduisez l'amplitude. Vous tirez votre pied trop haut par rapport à votre vitesse, ce qui gaspille de l'énergie.`,
    "pull.to.hips": `Tirez votre pied du sol en ligne droite vers vos hanches plutôt qu'en arc de cercle pour revenir en Pose avec un timing optimal.`,
    "pull.cadence": `Essayez de tirer votre pied du sol à une cadence plus élevée que celle que vous utiliseriez normalement (Le nombre minimum de pas par minute est d'environ 180). Sur le long terme, vous serez plus efficace et utiliserez moins d'énergie.`,
  },

  "useranalyses": {
    "analysis": "Analyse",
    "technique.analysis": "Analyse technique",
    "technique.analysis.none": `Pas encore d'analyse technique ?`,
  },

  "detailedanalysis": {
    initialContactTime: "Atterrissage",
    inPoseTime: "Pose (Position médiane)",
    fallEndTime: `Accéleration`,
    lastContactTime: `Décollage`,
    middleOfPullTime: "Tirage",
    timeLine: {
      initialContactTime: "Atterrissage",
      inPoseTime: "Pose",
      fallEndTime: `Accéleration`,
      lastContactTime: `Décollage`,
      middleOfPullTime: "Tirage",
    },
  },

  "userstats": {
    "stats": "Statistiques",
    "drills.performed": "Educatifs réalisés",
    "drills.performed.display": "Afficher les éducatifs",
    "technical.score": "Score Technique",
    "pose.score": "Pose",
    "fall.score": "Fall",
    "pull.score": "Pull",
    "score.drills": "Score technique et exercices par mois",
    "evolution": "Evolution de mon score technique",
  },

  "analyseme": {
    "send.video": "Envoyer ma vidéo",
    "sent.video": "Vidéo déposée !",
    "select.video": "Choisir la vidéo",
    "tosend": "Envoyer",
    "message.sucess.header": `Formidable !`,
    "message.sucess.content": ` J'ai bien reçu votre vidéo,
votre analyse sera disponible dans environ %{delay} jours !`,
    "message.sucess.content.tomorrow": ` J'ai bien reçu votre vidéo,
votre analyse sera disponible demain.`,
    "message.sucess.content.1": `Regarde dans tes mails pour la suite :-)`,
    "home.link": `Revenir à l'accueil`,
    "inprogress": "Votre vidéo est déjà programmée pour être analysée :-)",
    "instructions.intro": "Veuillez suivre les instructions pour votre filmage",
    "instructions.intro.1": `Vous aurez besoin d'un ami... ou d'un trépied`,
    "instructions.outside": `A l'extérieur`,
    "instructions.midday": "En plein jour",
    "instructions.flat": "Surface plane",
    "instructions.even": "Sol stable et uniforme",
    "instructions.zoom.landscape": "Orientation PAYSAGE",
    "instructions.zoom.profile": `Le coureur est de PROFIL`,
    "instructions.zoom.distance": `Filmer a 5 grands pas de la trajectoire du coureur`,
    "instructions.run.left": "Gauche",
    "instructions.run.right": "Droite",
    "instructions.run.no.move": "La camera NE BOUGE PAS",
    "instructions.run.left.to.right":
      "Le coureur va de la GAUCHE vers la DROITE",
    "instructions.run.speed.1": `Accélération jusqu'à l'allure objectif de course`,
    "instructions.go.settings": `Paramétrez votre capture vidéo à 60fps, pour une meilleure analyse`,
    "instructions.equipement": `Utilisez le même appareil pour toutes vos vidéos envoyées`,
    "instructions.check.review": `Veuillez vérifier les points suivants `,
    "instructions.check.strides": `4 foulées COMPLETES sont visibles`,
  },
  "services": {
    "services": "Services",
    "page.title": "Améliorez et suivez votre technique de course!",

    "promise": "Courez mieux, plus vite, plus longtemps.",
    "promise.content.1": `Votre technique analysée par un Instructeur Pose Method®`,
    "promise.content.2": `Programmes et séances qui s'adaptent à vos résultats d'analyse`,
    "promise.content.3":
      "Evaluation innovante pour suivre facilement vos progrès",
    "Fakealign.1": `
`,
    "choose.plan": "Choisissez votre abonnement",
    "current.plan": "Votre abonnement actuel",
    "choose.analyses": "Choisissez votre pack",
    "analyses.explanation": `Le rapport d'analyse innovant vous aide à comprendre les déviations de votre technique de course.
Molokoy ajuste en permanence votre travail technique en fonction des résultats de votre dernière analyse.
Les packs d'analyses n'expirent pas, vous pouvez les utiliser à votre guise avec ou sans plan actif.`,
    "shop": "Commander",
    "checkout": "Valider",
    "line.ali": "",
    "coupon": "Code promotionnel",
    "coupon.couponNotFoundOrInvalid":
      "Code promotionnel inexistant ou invalide",
    "item.unavailable.title": "Service redondant",
    "item.unavailable.content":
      "Vos services actuels rendent cet achat inutile pour l`instant :-)",
    "userservice.link.label": `Voir vos services actuels`,
    "premium.monthly": "mensuel",
    "premium.biannual": "semestriel",
    "premium.analysis.included": "1 analyse offerte",
    "analysis_1": "1 Analyse",
    "analysis_2": "2 Analyses",
    "analysis_4": "4 Analyses",
    "analysis": "analys",
    "analyses": "analyses",
    "per.month": "mois",
  },
  "userServices": {
    "page.title": "Mes services",
    "active.services": "Services actifs",
    "inactive.services": "Services passés",
    "running": "Services en cours",
    "available.analyses": `Analyses disponibles`,
    "used.analysis": `Analyses passées`,
    "available.analyses.left": "restante",
    "available.analyses.lefts": "restante(s)",
    "until": `Valide jusqu'au`,
    "since": "Commencé le",
    "buy": `Voir les services`,
    "none": ` Pas de service en cours pour l'instant...`,
  },

  "shoesPrescriptions": {
    "page.title": "Conseil chaussures",
    "surface": "Terrain",
    "trainingFrequency": "Frequence hebdomadaire",
    "cushion": "Amorti",
    "drop": "Drop",
    "stack": "Stack",
    "model": "modèle",

    "surface.road": "Route",
    "surface.off-road": "Chemin",
    "surface.trail": "Trail",

    "trainingFrequency.1": "1 fois",
    "trainingFrequency.3": "3 fois",
    "trainingFrequency.more": "plus  de 3 fois",

    "cushion.none": "aucun",
    "cushion.light": "léger",
    "cushion.medium": "moyen",

    "stack.minimalist": "minimaliste",
    "stack.light": "léger",
    "stack.medium": "moyen",
  },

  "success": {
    "page.title": "C'est parti !",
    "page.content.title": "Inscription validée",
    "page.content": `Les services associés sont maintenant disponibles !`,
  },
  "analysis.text": {
    "left": "gauche",
    "right": "droit",
    "nl": `
`,
    "userAnalysisVideo": {
      true: "Droite",
      false: "Gauche",
    },

    "standard": "Le standard ",
    "yourExecution": "Votre analyse",
    "pose": `pose`,
    "fall": `fall (chute)`,
    "pull": `pull (tirage)`,
    "misc": `divers`,
    "analysing": "Analyse de ",
    "********": `
***************************************************************************************************
`,
    "POSEcriteria": `POSE`,
    "poseIntro": `La Pose est la position du corps qui nous permet de nous déplacer en chutant sous l'effet de la gravité.
Le but ici est d'être en POSE aussi vite que possible. Votre pied doit atterrir directement sous votre corps, avec un genou plié.
L'avant du pied doit toucher le sol en premier, suivi du talon.
Votre point d'appui au sol, vos hanches et vos épaules doivent être sur une ligne droite et verticale.`,
    "poseExecutionLanding.1": `Vous atterrissez trop en avant de votre corps.
Cela crée un effet de freinage à chaque réception et soumet vos genoux à une contrainte inutile. `,
    "poseExecutionLanding.2": `Vous atterrissez près de votre corps mais toujours un peu en avant.
Cela exerce un effet de levier inutile sur vos genoux et crée un effet de freinage qui vous ralentit.`,
    "poseExecutionLanding.3": `Vous atterrissez très près de votre corps, sur l'avant pied. Cela réduit au minimum l'effet de freinage de l'atterrissage. C'est formidable ! `,
    "poseExecutionLanding.side.1": `Votre pied %{side} atterrit trop loin en avant de votre corps.
Cela crée un effet de freinage à chaque atterrissage et exerce une pression inutile sur vos genoux.  `,
    "poseExecutionLanding.side.2": `Votre pied %{side} atterrit près de votre corps mais toujours un peu en avant.
Cela exerce un effet de levier inutile sur %{side} vos genoux et crée un effet de freinage qui vous ralentit.`,
    "poseExecutionLanding.side.3": `Votre pied %{side} atterrit très près de votre corps, sur l'avant du pied.
Cela réduit au minimum l'effet de freinage de l'atterrissage. C'est formidable !`,

    "poseExecutionKnee.1": `Vous atterrissez également avec la cheville, le genou et la hanche verrouillés.
Atterrir avec un genou fléchi réduirait l'impact sur vos articulations.`,
    "poseExecutionKnee.3": `Vous atterrissez avec un genou fléchi. Cela permet de réduire l'impact de la course sur vos articulations.`,
    "poseExecutionKnee.side": ``,
    "poseExecutionKnee.side.1": `Du côté %{side}, vous atterrissez avec la cheville, le genou et la hanche verrouillés.
Atterrir avec un genou fléchi réduirait l'impact sur vos articulations %{side} genou.`,
    "poseExecutionKnee.side.3": `Sur votre côté %{side}, vous atterrissez avec un genou fléchi. Cela permet de réduire l'impact de la course sur vos articulations. Génial !`,

    "poseExecutionVertical.1": `En position médiane (POSE), vous êtes déjà incliné vers l'avant. Vous devriez être complètement vertical pour bénéficier de tout votre potentiel de chute.
`,
    "poseExecutionVertical.2": `En position médiane (POSE), vous êtes déjà incliné vers l'avant. Vous devriez être complètement vertical pour bénéficier de tout votre potentiel de chute.
`,
    "poseExecutionVertical.3": `En position médiane (POSE), vous êtes complètement vertical, bravo ! C'est le point de départ idéal de la chute pour utiliser au mieux l'accélération de la gravité.`,
    "poseExecutionVertical.side": ``,
    "poseExecutionVertical.side.1": `En position médiane (POSE) sur votre pied %{side}, vous êtes déjà trop incliné. Vous devriez être complètement vertical pour profiter de votre plein potentiel de chute.`,
    "poseExecutionVertical.side.2": `Du coté %{side}, en position médiane (POSE) vous êtes déjà incliné. Vous devriez être complètement à la verticale pour profiter de votre plein potentiel de chute et gagner en vitesse.`,
    "poseExecutionVertical.side.3": `Du côté %{side}, vous êtes complètement vertical. C'est bien ! C'est le point de départ idéal de la chute pour utiliser au mieux l'accélération de la gravité.`,

    "FALLcriteria": `FALL`,
    "fallIntro": `La clé de la vitesse est la capacité à chuter efficacement vers l'avant sous l'effet de la gravité.
Plus votre angle de chute est élevé, plus vous courrez vite.

Il a été démontré que pour chuter efficacement, vous devez garder votre pied en suspension sous vos hanches aussi longtemps que possible.
Dès que votre pied commence à se déplacer vers l'avant, la géométrie du système de chute change et cesse d'accélérer sous l'effet de la gravité.

La peur de chuter est très ancrée chez les adultes, c'est une barrière psychologique qui se manifeste souvent par des postures inconscientes qui tendent à ralentir la chute vers l'avant (épaules ou bassin en arrière)
`,
    "fallExecutionAlignment.0": `Vous résistez à la chute en maintenant vos épaules en arrière, elles doivent être au-dessus de vos hanches, presque sur la même ligne verticale. Vous pourriez courir beaucoup plus vite avec le même effort en utilisant la gravité de manière plus efficace.`,
    "fallExecutionAlignment.1": `Vous avez le buste trop penché, vos hanches sont EN ARRIÈRE de vos épaules, elles devraient être EN DESSOUS d'elles, pratiquement sur la même ligne verticale. Cette inclinaison du buste vous empêche d'utiliser votre centre de masse pour chuter, vous pourriez aller plus vite avec le même effort.`,
    "fallExecutionAlignment.2": `Vous avez le buste un peu trop penché vers l'avant, vos hanches sont EN ARRIÈRE de vos épaules, elles devraient être EN DESSOUS d'elles, pratiquement sur la même ligne verticale. Cette inclinaison du buste vous empêche d'utiliser votre centre de masse pour chuter, vous pourriez aller plus vite avec le même effort.`,
    "fallExecutionAlignment.3": `Vous conservez le buste vertical, en gardant vos épaules au-dessus de vos hanches. C'est excellent !
C'est la position idéale qui permet le meilleur alignement de votre corps pour créer de la vitesse en utilisant la gravité.`,
    "fallExecutionAlignment.side.1": `Du coté %{side}, vous avez le buste trop penché en avant, vos hanches sont EN ARRIÈRE de vos épaules, elles devraient être EN DESSOUS, presque sur la même ligne verticale.  Cette inclinaison du buste vous empêche d'utiliser votre centre de masse pour chuter, vous pourriez aller plus vite avec le même effort.`,
    "fallExecutionAlignment.side.2": `Du coté %{side}, vous avez le buste un peu trop penché en avant, vos hanches sont EN ARRIÈRE de vos épaules, elles devraient être EN DESSOUS, presque sur la même ligne verticale.  Cette inclinaison du buste vous empêche d'utiliser votre centre de masse pour chuter, vous pourriez aller plus vite avec le même effort.`,
    "fallExecutionAlignment.side.3": `Du coté %{side}, vous conservez un buste vertical, en gardant les épaules sur les hanches.  C'est excellent !
C'est la position idéale qui permet le meilleur alignement de votre corps pour créer de la vitesse en utilisant la gravité.`,

    "fallExecutionTime.1": `Votre pied en suspension se déplace trop tot vers l'avant et vers le bas, il devrait rester sous votre hanche.
Concentrez-vous sur la sensation de chute, pas sur l'atterrissage, votre pied redescendra naturellement (instinctivement) à la fin de votre phase de chute.`,
    "fallExecutionTime.2": `Votre pied en suspension se déplace un peu trop tot vers vers l'avant et le vers le bas, il devrait rester sous votre hanche.
Concentrez-vous sur la sensation de chute, pas sur l'atterrissage, votre pied redescendra naturellement (instinctivement) à la fin de votre phase de chute.`,
    "fallExecutionTime.3": `Votre pied reste sous votre hanche pendant votre phase de chute ! Vous chutez comme une unité cohérente, et vous tirez le meilleur parti de votre chute.`,
    "fallExecutionTime.side": ``,
    "fallExecutionTime.side.1": `Votre pied %{side} se déplace trop tot vers l'avant et le vers le bas,il devrait rester sous votre hanche.
Concentrez-vous sur la chute, pas sur l'atterrissage, votre pied s'abaissera naturellement (instinctivement) à la fin de votre phase de chute.`,
    "fallExecutionTime.side.2": `Votre pied %{side} se déplace un peu trop tot vers l'avant.
Concentrez-vous sur la chute, pas sur l'atterrissage, votre pied s'abaissera naturellement (instinctivement) à la fin de votre phase de chute.`,
    "fallExecutionTime.side.3": `Votre pied %{side} reste sous votre hanche pendant votre phase de chute ! Vous chutez en une seul unité, de manière très efficace, et tirez le meilleur parti de la gravité.
Vous devez faire de même avec l'autre pied maintenant !`,

    "PULLcriteria": `PULL`,
    "pullIntro": `Une fois la phase de chute terminée, il est temps de changer d'appui pour arriver en POSE sur l'autre pied et continuer à chuter.
La seule action requise pour ce faire est de TIRER le pied du sol, l'autre pied se posera automatiquement sans que vous ayez à y penser.

En fait, Tirer est la seule action musculaire consciente sur laquelle vous devez vous concentrer pour bien courir.
Tirez ! Tirez ! Tirez !

Vous devez tirer votre pied en direction de l'aplomb de vos hanches (sous vos hanches et non vers l'arrière) pour arriver à la prochaine pose À TEMPS et maximiser le temps que vous passerez à chuter.
Une tirage efficace - à temps et dans la bonne direction - éliminera tous les mouvements inutiles et rendra votre course plus efficace.`,
    "framesOnSupportAfterFalling.1": `Votre pied d'appui passe trop de temps au sol, il doit être activement tiré après la phase de chute pour réduire votre temps de contact avec le sol.`,
    "framesOnSupportAfterFalling.2": `Votre pied d'appui passe trop de temps au sol, il doit être activement tiré après la phase de chute pour réduire votre temps de contact avec le sol.`,
    "framesOnSupportAfterFalling.3": `Votre timing de tirage est idéal, vous tirez votre pied dès que la phase de chute est terminée. Vous optimisez votre temps de contact avec le sol, c'est remarquable !`,
    "framesOnSupportAfterFalling.side": ``,
    "framesOnSupportAfterFalling.side.1": `Vous tirez votre pied %{side} en retard. Il doit être tiré activement du sol après la phase de chute pour réduire votre temps de contact avec le sol.`,
    "framesOnSupportAfterFalling.side.2": `Vous tirez votre pied %{side} en retard. Il doit être tiré activement du sol après la phase de chute pour réduire votre temps de contact avec le sol.`,
    "framesOnSupportAfterFalling.side.3": `Votre timing de tirage est idéal, vous tirez votre pied %{side} dès que la phase de chute est terminée. Vous optimisez votre temps de contact au sol, c'est remarquable ! Essayez de faire la même chose avec l'autre pied !`,

    "pullExecutionLegBent.1": `Votre jambe arrière est tendue lorsque votre pied se décolle du sol. Vous essayez de vous propulser en poussant, c'est inutile.
Vous devez plutôt TIRER votre pied pour le ramener sous votre hanche à temps.`,
    "pullExecutionLegBent.2": `Votre jambe arrière est presque tendue lorsque votre pied freine le contact avec le sol. Vous n'essayez PAS de vous propulser en poussant, ce qui est bien, mais votre pied devrait être tiré plus agressivement pour revenir à la position médiane (Pose) au bon timing.`,
    "pullExecutionLegBent.3": `Votre jambe arrière est fléchie lorsque votre pieddécolle du sol, ce qui est excellent car vous tirez votre pied et optimisez le timing de votre mouvement. C'est la clé d'une course efficace, bon travail !`,
    "pullExecutionLegBent.side": ``,
    "pullExecutionLegBent.side.1": `Votre genou %{side} est tendu lorsque votre pied se décolle du sol. Vous essayez de vous propulser en poussant, c'est inutile.
Vous devez plutôt TIRER votre pied pour le ramener sous votre hanche à temps.`,
    "pullExecutionLegBent.side.2": `Votre genou %{side} gauche est pratiquement tendu lorsque votre pied arrière décolle du du sol
Vous n'essayez PAS de vous propulser en poussant, ce qui est bien, mais votre pied devrait être tiré plus agressivement pour revenir en position médiane (Pose) à temps`,
    "pullExecutionLegBent.side.3": `Votre genou %{side} est fléchi lorsque votre pied arrière rompt le contact avec le sol, ce côté est OK !
Vous tirez votre pied et optimisez le timing de votre mouvement avec ce pied.`,

    "pullExecutionDirection.0": `Vous ne tirez pas du tout votre pied, il traîne derrière ce qui nuit à l'efficacité de votre mouvement.
Votre pied doit être activement tiré sous vos hanches.`,
    "pullExecutionDirection.1": `Votre pied monte et reste en arrière, au lieu de se déplacer vers le dessous de vos hanches.
La trajectoire de votre pied n'est pas optimale, il devrait se déplacer en ligne droite du sol vers le dessous de vos hanches.
`,
    "pullExecutionDirection.2": `Votre pied monte et reste légèrement en arrière, au lieu de se déplacer vers le dessous de vos hanches.
La trajectoire de votre pied est presque optimale, mais votre pied devrait se déplacer en ligne droite du sol vers le dessous de vos hanches.
`,
    "pullExecutionDirection.3": `Votre pied se déplace en ligne droite du sol jusqu'au dessous de vos hanches. La trajectoire de votre pied est optimale !`,
    "pullExecutionDirection.side": `La trajectoire optimale de votre pied doit être une ligne droite entre le sol et le dessous de vos hanches (Pose).`,
    "pullExecutionDirection.side.0": `Vous ne tirez pas du tout votre pied %{side}, il est à la traîne, ce qui nuit à l'efficacité de votre mouvement.
Votre pied doit être activement tiré sous vos hanches.`,
    "pullExecutionDirection.side.1": `Vous tirez votre pied %{side} derrière, il monte et reste derrière, au lieu de se déplacer vers le dessous de vos hanches.
La trajectoire de votre pied n'est pas optimale, votre pied devrait se déplacer en ligne droite du sol vers le dessous de vos hanches. `,
    "pullExecutionDirection.side.2": `Vous tirez votre pied %{side} légèrement en arrière, il monte et reste un peu en arrière de votre corps, au lieu de se déplacer vers le dessous de vos hanches.
La trajectoire de votre pied est presque optimale,mais votre pied devrait se déplacer en ligne droite du sol vers le dessous de vos hanches.`,
    "pullExecutionDirection.side.3": `Vous tirez votre pied %{side} directement vers la hanche. Sa trajectoire est optimale !`,

    "*******": `
***************************************************************************************************
`,
  },

  "line.alignement.2": `

`,
  "userAnalysisVideo": {
    "isright.true": "Droite",
    "isright.false": "Gauche",
    "R": "Droite",
    "L": "Gauche",
  },
  "sections": {
    "analysis.explanation": {
      required:
        "Analyse requise: le travail technique est individualisé et nécessite une analyse de votre technique de course.",
      recommended:
        "Analyse recommandée : le travail technique sera personnalisé grace à l'analyse de votre technique de course.",
    },
    "premium.required": "premium",
    "sessions": "séances",
    "title": "Programmes",
    "description":
      "Les programmes sont conçus pour vous aider à améliorer votre technique et vous aider de courir sans blessure",
    "category": {
      programs: "Initiation",
      sessions: "Entrainements",
      selfcare: `Bibliothèque d'autosoins`,
      drills: "Bibliothèque d'exercices & éducatifs",
      race: "Plans d'entrainement",
      custom: "Specific programs",
    },
    "welcome": {
      title: `Bienvenue, %{firstname} !`,
      description: "",
    },
    "technique": {
      title: "Vos échauffement & éducatifs du jour",
      description: `Une séance complète d'échauffement et de travail technique avant votre course du jour.
Chaque jour, vous bénéficiez d'une séance personnalisée différente.
Les exercices techniques sélectionnés sont axés sur votre principal domaine d'amélioration, mis en évidence par votre analyse vidéo.`,
    },
    "selfcare": {
      title: "Mobilité et autosoins du jour",
      description: `Des autosoins pour vous aider à ne pas vous blesser.
Chaque jour une session différente pour que cela reste plaisant.`,
    },
    "library": {
      title: `Programmes & exercises`,
      description: "Tout le necessaire pour être un meilleur coureur",
    },
    "learn": {
      title: "Apprenez à mieux courir",
      description: `Mieux courir, c'est courir plus vite.
Avec une meilleure technique, vous ameliorerez facilement votre temps de course de quelques minutes !
L'amélioration de votre technique de course vous permettra de tirer le meilleur parti de votre potentiel athlétique. Lancez-vous !
`,
      sessions: "8",
    },
    "cadence": {
      title: "Améliorez votre efficacité",
      description: `L'amélioration de votre cadence de course vous permet de gagner en efficacité.
Ce programme vous permettrant de construire graduellement votre capacité a utiliser votre elasticité muscle-tendon pour augmenter votre cadence sans fatigue supplémentaire.
Il fait suite au travail entamé lors du programme "Apprenez à mieux courir" `,
      sessions: "12",
    },
    "transition": {
      title: "Développez votre endurance technique",
      description: `Une fois que votre technique commence à s'améliorer, il est important de pouvoir la conserver sur la longueur.
L'objectif de ce programme est de vous aider passer le premier cap, pouvoir courir 30 minutes avec une bonne technique de course.`,
      sessions: "12",
    },

    "speed": {
      title: "Gagnez en vitesse",
      description: "Augmentez votre vitesse",
      sessions: "12",
    },
    "short": {
      title: "Fractionné court",
      description: "Développez & entretenez votre vitesse",
    },
    "long": {
      title: "Fractionné long",
      description: "Développez et entretenez votre résistance",
    },
    "focus": {
      title: "Footing productif",
      description: `30 ou 45 minutes de footing avec des éducatifs personnalisés.
Ces séances sont conçues pour vous aider à renforcer la bonne technique pendant vos sorties faciles.`,
    },
    "work": {
      title: "Session technique",
      description: `Des séances de technique personnalisées qui peuvent être effectuées même les jours de repos.
Elles sont axées sur la technique et ne vous fatigueront pas.`,
    },
    "selfcare_lowerleg": {
      title: "Zone inférieure de la jambe",
      description: "Pied, mollets et muscles tibiaux antérieurs",
    },
    "selfcare_upperleg": {
      title: "Zone supérieure de la jambe",
      description: "Quadriceps, ischio-jambiers et fessiers",
    },

    "selfcare_back": {
      title: "Dos",
      description: "Zones thoraciques et lombaire",
    },

    "warmup": {
      title: "Echauffement",
      description: "",
    },
    "pose": {
      title: "Pose",
      description: "",
    },
    "fall": {
      title: "Fall",
      description: "",
    },
    "pull": {
      title: "Pull",
      description: "",
    },
    "strength": {
      title: "Renforcement",
      description: "",
    },
    "flexibility": {
      title: "Flexibilité",
      description: "",
    },
    "marathon": {
      title: "Marathon",
      description: `Ce programme de 16 semaines pour le marathon est conçu pour vous aider à courir votre meilleur marathon.
Les 4 premières semaines (16-13) servent de travail de base. Vous pouvez choisir de les sauter et de préparer votre marathon à partir de la semaine 12.
Les allures sont destinées à un coureur de niveau moyen, n'hésitez pas à les adapter si nécessaire, le but est de courir tous les intervalles à la même allure.
Vous devez avoir fait une analyse de votre course avant de commencer. Ensuite, vous avez deux options :
- Deux analyses, une à la semaine 12, 8 et 4, ou
- Une analyse à la 8ᵉ semaine
Après chaque analyse, Molokoy s'adapte à votre progression pour mettre à jour le travail technique.`,
    },

    "half": {
      title: "Semi-marathon",
      description: `Ce programme de 12 semaines pour le semi-marathon est conçu pour vous aider à mieux courir et à tirer le meilleur parti de votre potentiel athlétique.
Les allures sont destinées à un coureur de niveau moyen, n'hésitez pas à les adapter si nécessaire, le but est de courir tous les intervalles à la même allure..
Vous devez avoir fait une analyse de votre course avant de commencer. Ensuite, vous avez deux options :
- Deux analyses, une à la 4ᵉ et une à la 8ᵉ semaine, ou
- Une analyse à la 6ᵉ semaine
Après chaque analyse, Molokoy s'adapte à votre progression pour mettre à jour le travail technique.`,
    },

    "5k": {
      title: "5k",
      description: `Ce programme de 8 semaines est conçu pour vous aider à performer lors de votre prochain 5 km.
Les allures sont celles d'un coureur de niveau moyen, n'hésitez pas à les adapter si nécessaire, le but est de courir tous les intervalles à la même allure.
Vous devez avoir fait une analyse de course avant de commencer. Ensuite, vous devriez en faire une deuxième la semaine 4.
Après chaque analyse, Molokoy s'adapte à votre progression pour mettre à jour le travail technique.`,
    },
    "10k": {
      title: "10k",
      description: `Ce programme de 8 semaines est conçu pour vous permettre de vous dépasser lors de votre prochain 10 km.
Les allures sont celles d'un coureur de niveau moyen, n'hésitez pas à les adapter si nécessaire, le but est de courir tous les intervalles à la même allure..
Vous devez avoir fait une analyse de course avant de commencer. Ensuite, vous devriez en faire une deuxième la semaine 4.
Après chaque analyse, Molokoy s'adapte à votre progression pour mettre à jour le travail technique.`,
    },
  },

  "plan.details":
    "**********************************************************************************************",
  "Half-marathon": "Semi-marathon",

  "in place": "sur place",
  "run": "courir",
  "into": "puis",
  "into run": "puis courir",
  "left": "gauche",
  "right": "droite",
  "plank": "Planche (position de pompe)",
  "plank face up": "Planche dorsale",
  "alternate left and right": "alterner droite et gauche",

  "5fe370cb576a965e62f8d306": {
    label: "Introduction",
    focus: ``,
    text: `Bienvenue dans cette introduction à une meilleure technique de course.

    Elle vous permettra de vous familiariser avec les concepts fondamentaux de la méthode Pose créée par le Dr. Nicholas Romanov, et vous guidera progressivement à travers différents types d'exercices de flexibilité, d'entraînement et de force de base pour les coureurs.

   Concentrez-vous sur l'amélioration de votre technique pendant ces leçons et essayez d'éviter d'ajouter un volume de course supplémentaire à ce programme.
   2 à 3 séances par semaine constituent un bon rythme, chaque séance nécessitant entre 15 et 40 minutes de votre temps.
   En général, prenez votre temps et laissez vos perceptions et votre corps s'adapter.
   N'hésitez pas à insister sur un exercice particulier qui vous pose problème, ou à refaire une leçon à votre prochaine séance avant de passer à la suivante.
    Si vous êtes un débutant complet, vous devez faire chaque leçon 2 fois en 2 séances consécutives.

    À l'issue de cette introduction, vous connaîtrez tous les exercices de base et aurez déjà amélioré votre technique de course. Vous pourrez ensuite utiliser des séances d'échauffement et de technique personnalisées pour continuer à affiner votre technique et continuer à courir plus vite et plus loin, tout en limitant le risque de blessure.

   Chaque leçon comporte une explication théorique suivie d'exercices ou d'éducatifs.
    Une fois que vous avez terminé une leçon, la suivante est disponible.

   Les exercices modifieront votre façon de courir, et solliciteront différemment vos muscles et tendons. Mais, si vous ressentez une douleur aiguë et inhabituelle, arrêtez-vous immédiatement et consultez votre médecin.`,
  },
  "5fe21c3f2272d45e4ff01271": {
    label: "Ressentir la chute",
    focus: `Concentrez-vous sur le fait de sentir comment la gravité peut vous entrainer vers l'avant tout en réduisant l'effort musculaire nécessaire pour avancer.`,
    text: `C'est en apprenant à mieux utiser la gravité que nous devenons de meilleurs coureurs.
La gravité est obligatoire pour se déplacer, et pour courir vite. A titre d'illustration, considérez qu'il y a moins de gravité sur la lune, voyez-vous comment les astronautes se déplacent lentement ?
Votre capacité à utiliser efficacement la gravité est directement liée à votre technique. Cela signifie qu'à potentiel athlétique égal, l'amélioration de votre technique vous fera courir plus vite !

Notre vitesse est corrélée à l'angle avec lequel nous chutons.

Usain Bolt détient le meilleur temps sur 100 mètres et le plus grand angle de chute réalisé : 21,5° !
Notre première leçon sera de commencer à ressentir la gravité en courant.
`,
  },
  "5fe2e7292272d45e4ff01272": {
    label: "Correction de la chute",
    focus: ``,
    text: `Lorsque nous commençons à nous concentrer sur la chute, nous avons tendance à incliner le torse vers l'avant et à vouloir chuter en avançant les épaules.
En fait, la véritable chute vient de notre centre de gravité général situé approximativement au-dessus des hanches, autour du nombril.

Cela signifie que pour tomber efficacement, nous devons nous concentrer sur le maintien d'un torse plutôt vertical, et essayer de "tomber des hanches". Il faut avancer légèrement le bassin, sans cambrer le dos.

Les exercices d'aujourd'hui se concentreront sur le maintien d'un torse vertical pendant la chute`,
  },
  "5fe370e9576a965e62f8d307": {
    label: "Meilleure position de chute : La Pose",
    focus: ``,
    text: `La position la plus efficace pour utiliser la gravité s'appelle Pose, c'est la position où votre corps est chargé d'une énergie potentielle qui peut être transformée en accélération.
Dans cette position :
- votre avant-pied, vos hanches et vos épaules sont alignés.
- votre pied en suspension est sous votre hanche (ne faites pas attention à la hauteur, nous en parlerons dans la leçon 6)
- votre jambe de soutien est fléchie
- le poids de votre corps repose sur l'avant-pied

Mettez-vous dans cette position, et sentez comme votre corps est stable, mais peut rapidement basculer vers l'avant, lorsque le poids du corps repose sur la plante de votre pied.

Dans cette leçon, nous nous familiariserons avec cette position clé de la course
`,
  },
  "5fe370f1576a965e62f8d308": {
    label: "Connexion entre la Pose de course et la chute",
    focus: ``,
    text: `Pour tirer le meilleur parti de votre angle de chute, vous devez garder votre pied en suspension sous votre hanche aussi longtemps que possible.
Le fait de garder ce pied sous la hanche maintient l'équilibre du système et permet à votre corps d'accélérer autant que le permet la gravité.

Dans cette leçon, nous allons relier la perception de la chute en Pose`,
  },
  "5fe370f9576a965e62f8d309": {
    label: "Tirez, ne poussez pas !",
    focus: `Tirez votre pied pour arriver en Pose. Votre image mentale est d'atterrir en Pose, le pied en suspension sous votre hanche`,
    text: `Ok, nous avons maintenant compris que l'accélération vient d'une chute vers l'avant en Pose.

Une fois la phase de chute terminée, nous devons changer de support, et revenir en Pose aussi vite que possible pour tomber à nouveau.
Cela se fait en TIRANT le pied du sol.
Si nous ne le faisons pas, nous ne faisons que passer un temps inutile sur notre point de support.

La course à pied comporte 3 éléments invariables : Pose, Chute, Tirage.
La Pose est la meilleure position du corps qui nous permet de chuter.
La Chute est l'élément principal qui nous permet de nous déplacer rapidement grâce à la gravité.
Le Tirage est le moyen le plus rapide de revenir en Pose et de tout recommencer.

Ces éléments sont les seuls éléments nécessaires à la course. Un bon coureur ne fera que ces éléments, dans un timing approprié.
Un  coureur moins performant ajoutera des éléments parasites qui entraveront son efficacité.
Agrandir exagérément la foulée en atterrissant le pied très en avant augmentera le temps nécessaire pour arriver en Pose et provoquera un effet de freinage.
Tenter de "Pousser au sol" augmente le temps nécessaire pour revenir en Pose, augmentant le temps gaspillé à "ne pas tomber".

Pendant la course, imaginez-vous en train de tirer le pied dès qu'il touche le sol.

Conseil : ne pas raidir le pied, le relâcher pour ne pas courir que sur l'avant pied. Votre talon doit toucher le sol après l'avant pied à chaque foulée. Cela permet d'éviter des tensions inutiles sur votre tendon d'Achille`,
  },
  "5fe37101576a965e62f8d30a": {
    label: "Ajustement de la hauteur du tirage",
    focus: `Ajustement de la hauteur du tirage`,
    text: `Lorsque nous tirons notre pied du sol, il doit s'agir d'une action rapide et courte  utilisant les ischio-jambiers.

La hauteur idéale de votre tirage est dictée par votre angle de chute (votre vitesse).
Un tirage de faible amplitude lors du jogging, un tirage de grande amplitude lors du sprint.

Le but de cette leçon est d'apprendre à tirer le pied vers la hanche en POSE de course à des hauteurs différentes.`,
  },
  "5fe37112576a965e62f8d30b": {
    label: "Élasticité muscle-tendon et cadence",
    focus: `Élasticité muscle-tendon et cadence`,
    text: `Économiser et réutiliser l'énergie est essentiel pour améliorer votre capacité à mieux courir.
L'une des forces gratuites qui peuvent être utilisées pour courir est l'élasticité muscle-tendon. Il a été démontré qu'un entraînement et une utilisation corrects de cette faculté réduisent la consommation d'oxygène et augmentent l'efficacité jusqu'à 50 %.
Ce complexe est activé avec une cadence minimale de 180 pas par minute, apprendre à courir à cette cadence est une grande partie du développement de votre technique de coureur.


Pour travailler cette compétence, nous utilisons des exercices de sauts rythmés et un travail de cadence avec un métronome.

Lorsque vous travaillez sur le métronome, vous devez vous concentrer sur la synchronisation du tirage du pied en l'air en Pose sur le bip. C'est essentiel !
Si vous vous concentrez sur la synchronisation du bip avec votre pied qui touche le sol, cela conduira à un mauvais schéma de mouvement, appelé atterrissage actif.
Si vous vous concentrez sur la synchronisation du bip avec la traction de votre pied en Pose, cela vous aidera à vous mettre en Pose rapidement, prêt à chuter vers l'avant.

L'objectif de cette section est de commencer à améliorer votre cadence.`,
  },
  "5fe37122576a965e62f8d30c": {
    label: "180 est la clé",
    focus: `180 est la clé`,
    text: `Lors de la session précédente, vous avez peut-être eu du mal à courir à 180 pas par minute sans vous essouffler. Ne vous inquiétez pas, c'est normal, vos muscles et vos tendons ont besoin de temps pour développer cette aptitude.
De plus, ce genre de travail est tout à fait nouveau pour vous, il est très probable que vous ayez tiré plus haut que nécessaire avec des muscles très tendus !

Dans cette session, nous travaillerons à maintenir une cadence élevée, mais en nous concentrant sur la décontraction autant que possible`,
  },
  "5ffc3b1b92910d5db9167e45": {
    label: "Conclusion",
    focus: ``,
    text: `Félicitations !
Votre initiation à une meilleure technique de course terminée, il est temps de faire une analyse technique de course pour voir vos progrès !

Continuez à progresser en utilisant les programmes de Molokoy !

Pour aller plus loin :
- Inscrivez-vous à un séminaire de la Méthode Pose : https://posemethod.com/seminars/pose-method-of-running/
- Contactez un coach certifié de la méthode Pose dans votre région à l'adresse https://techniquespecialist.com/,`,
  },

  "instructions": {
    "01": { 1: "Concentrez-vous sur la sensation de chute vers l'avant" },
    "02": {
      1: "Ressentez la sensation de chute en avançant le bassin, avec le buste pratiquement vertical",
    },
    "03": {
      1: "Essayez de ressentir le moment fugace où vous êtes en Pose à chaque foulée",
    },
    "04": {
      1: "Ne cherchez pas à avancer le pied en l'air. Essayez de le garder sous la hanche aussi longtemps que possible",
    },
    "05": { 1: "Tirez votre pied du sol directement sous le bassin, en Pose" },
    "06": {
      1: "Allure lente, tirez le pied à la hauteur du tibia de la jambe d'appui",
      2: "Allure modérée, tirez le pied plus haut que précédemment",
      3: "Allure rapide, tirez le pied encore plus haut que précédemment",
    },
    "07": {
      1: "Votre pied en suspension doit être en Pose (sous la hanche) à chaque bip",
    },
    "08": {
      1: "Votre pied en suspension doit être en Pose (sous la hanche) à chaque bip, en étant le plus relâché possible",
    },
    "focus.cadence.30": `Lorsque vous ne pouvez pas maintenir la cadence, reposez-vous pendant 1 minute. N'arrêtez pas le chrono.
L'objectif est de développer au fil du temps votre capacité à courir pendant 30 minutes à une cadence de 180 pas par minute.`,
    "focus.cadence.01": `Votre pied en suspension doit être en Pose (sous la hanche) à chaque bip.`,
    "focus.pose.01": `Essayez d'être en pose à chaque fois que votre pied atterrit.`,
    "focus.fall.01": `Ressentez la sensation de chute en avançant le bassin, avec le buste pratiquement vertical.`,
    "focus.pull.01": `Concentrez-vous sur le tirage comme votre seule action musculaire lorsque vous courez (la chute et la pose sont plutôt passives).`,
  },

  "cadence.details":
    "**********************************************************************************************",

  "601152b4f3accc319ae01648": {
    label: "Session 1",
    focus: ``,
    text: ``,
  },
  "60115a4c20828c437c0ee184": {
    label: "Session 2",
    focus: ``,
    text: ``,
  },
  "60129debf8115d436e31d037": {
    label: "Session 3",
    focus: ``,
    text: ``,
  },
  "6012bcf6f8115d436e31d057": {
    label: "Session 4",
    focus: ``,
    text: ``,
  },
  "6012beb7f8115d436e31d06e": {
    label: "Session 5",
    focus: ``,
    text: ``,
  },
  "6012c4a39c2aef437d5c91d0": {
    label: "Session 6",
    focus: ``,
    text: ``,
  },
  "6012c59c333ff7436fddfc3b": {
    label: "Session 7",
    focus: ``,
    text: ``,
  },
  "6012c0b69c2aef437d5c91b5": {
    label: "Session 8",
    focus: ``,
    text: ``,
  },
  "6012cf8f20828c437c0ee1b4": {
    label: "Session 9",
    focus: ``,
    text: ``,
  },
  "6012d19ef8115d436e31d082": {
    label: "Session 10",
    focus: ``,
    text: ``,
  },
  "6012d316f8115d436e31d08d": {
    label: "Session 11",
    focus: ``,
    text: ``,
  },
  "6012d472333ff7436fddfc6a": {
    label: "Session 12",
    focus: ``,
    text: ``,
  },

  "drills.details": `**********************************************************************************************
    **********************************************************************************************
    **********************************************************************************************`,

  "5dbee93cd3bebd1001b278db": {
    "label": "Banded run",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Pousser le bassin vers l'avant",
    "execution.3": "",
    "focus.1": "Conserver l'alignement du corps ",
    "focus.2": "Garder une cadence élevée",
    "focus.3": "Tirer sur le pied, éviter de pousser",
    "repetitions.1": "30s avec l'élastique",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5d797bd5da8c422b903f2e9c": {
    "label": "Body weight perception",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Déplacer le poids du corps alternativement vers l'avant et vers l'arrière",
    "execution.2": "Maintenir la position vers l'avant puis vers l'arrière",
    "execution.3": "",
    "focus.1":
      "Percevoir les changements de pression au sol en fonction de la position du corps ",
    "focus.2": "Conserver l'alignement du corps ",
    "focus.3": "Garder l'équilibre",
    "repetitions.1": "10 bascules d'avant en arrière",
    "repetitions.2": "10 s vers l'arrière / 10 s vers l'avant",
    "repetitions.3": "Courir 10 m ",
  },
  "5d797bdeda8c422b903f2e9d": {
    "label": "Body weight perception in Pose",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Déplacer le poids du corps alternativement vers l'avant et vers l'arrière",
    "execution.2": "Maintenir la position vers l'avant puis vers l'arrière",
    "execution.3": "Réaliser l'exercice du côté opposé.",
    "focus.1":
      "Percevoir les changements de pression au sol en fonction de la position du corps ",
    "focus.2": "Conserver l'alignement du corps ",
    "focus.3": "Garder l'équilibre",
    "repetitions.1": "10 bascules d'avant en arrière",
    "repetitions.2": "10 s vers l'arrière / 10 s vers l'avant",
    "repetitions.3": "Changer de côté puis courir 10 m ",
  },
  "5c13d0b8df79e52f10967f03": {
    "label": "Bunny Hops",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sautiller sur place puis se laisser tomber vers l'avant ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Maintenir la même cadence de saut, sur place et en avançant",
    "focus.2":
      "Chercher la plus petite inclinaison possible permettant d'avancer",
    "focus.3": "Minimiser les efforts musculaires",
    "repetitions.1":
      "Alterner 3 sauts sur place et 3 sauts en avançant, 10 fois",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5c14199489d7e907146f58c7": {
    "label": "Bunny hops backward",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Sautiller sur place puis se laisser tomber légèrement vers arrière",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Maintenir la même cadence de saut, sur place et en reculant",
    "focus.2":
      "Chercher la plus petite inclinaison possible permettant de reculer",
    "focus.3": "Minimiser les efforts musculaires",
    "repetitions.1": "Alterner 3 sauts sur place, 3 sauts en reculant, 10 fois",
    "repetitions.2": "Se retourner et Courir 10 mètres",
    "repetitions.3": "",
  },
  "5d797e07da8c422b903f2ead": {
    "label": "Carioca",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Se laisser tomber légèrement sur le côté",
    "execution.2": "Poser le pied alternativement devant puis derrière",
    "execution.3": "",
    "focus.1": "Limiter le mouvement des épaules",
    "focus.2": "Percevoir le poids du corps sur l'avant-pied",
    "focus.3": "",
    "repetitions.1": "20 foulées vers la droite",
    "repetitions.2": "20 foulées vers la gauche",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d797d21da8c422b903f2eaa": {
    "label": "Change of support",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Faire un saut sur place puis tirer le pied au sol, directement vers le haut",
    "execution.2": "Atterrir en pose",
    "execution.3": "Alterner droite et gauche",
    "focus.1":
      "Le pied de support doit bouger en premier, avant que le pied en suspension n'atterrisse",
    "focus.2": "Laisser le pied en Pose tomber et atterrir naturellement",
    "focus.3":
      "Lors du saut sur place s'assurer que la cheville en Pose est au niveau du genou opposé",
    "repetitions.1": "10 sur place, 10 avançant",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5c0515517590d85294234f18": {
    "label": "Change of support on line",
    "setup": "Pose, pied sur la ligne",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Faire un saut sur place puis tirer le pied au sol directement vers le haut",
    "execution.2": "Atterrir en pose sur la ligne",
    "execution.3": "",
    "focus.1": "Tirer le pied directement sous le bassin",
    "focus.2":
      "Laisser le pied en Pose tomber et atterrir naturellement, sur la ligne",
    "focus.3":
      "Lors du saut sur place s'assurer que la cheville en Pose est au niveau du genou opposé",
    "repetitions.1": "10 sur place, 10 avançant",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5c14167d89d7e907146f58bf": {
    "label": "Criss-Cross",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sautiller en se laissant tomber en avant",
    "execution.2": "Croiser alternativement les pieds",
    "execution.3": "",
    "focus.1": "Le talon doit brièvement se poser au sol",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 avançant",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f33aeeb9b46709fe0d32e17": {
    "label": "Criss-Cross high & wide",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sautiller en se laissant tomber en avant",
    "execution.2": "Croiser alternativement les pieds",
    "execution.3": "Accentuer la hauteur du saut et l'écartement des pieds",
    "focus.1": "Le talon doit brièvement se poser au sol",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 avançant",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f3cf1dfd6692d69800007c1": {
    "label": "Curb jumps",
    "setup": "Ressort face au trottoir",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter et redescendre du trottoir",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Hausser agressivement les épaules pour vous alléger",
    "focus.2":
      "Se concentrer sur le tirage des pieds du sol et non sur une poussée dans le sol",
    "focus.3": "Garder un rythme constant",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f3cf1e8d6692d69800007c2": {
    "label": "Curb jumps land in pose",
    "setup": "Ressort face au trottoir",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter sur le trottoir ",
    "execution.2": "Atterrir en Pose",
    "execution.3": "Changer de Pose à chaque atterrissage sur le disque",
    "focus.1": "Hausser agressivement les épaules pour vous alléger",
    "focus.2":
      "Se concentrer sur le tirage du pied du sol et non sur une poussée dans le sol",
    "focus.3": "Atterrir en Pose correctement",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f3cf1f3d6692d69800007c3": {
    "label": "Curb jumps in pose",
    "setup": "Pose, face au trottoir",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sauter et redescendre du trottoir en Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Se concentrer sur le tirage du pied du sol et non sur une poussée dans le sol",
    "focus.2": "Hausser agressivement les épaules pour vous alléger",
    "focus.3": "Conserver une Pose correcte",
    "repetitions.1": "5 à droite / 5  à gauche",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f3cf1fcd6692d69800007c4": {
    "label": "Curb foot switch",
    "setup": "Ressort face au trottoir, pied gauche sur le trottoir",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter et intervertir la position des pieds",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Hausser agressivement les épaules pour vous alléger",
    "focus.2": "Conserver un rythme constant",
    "focus.3": "",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f3cf205d6692d69800007c5": {
    "label": "Curb drop pull",
    "setup": "Pose, un pied sur le trottoir, parallèle au bord",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Laisser passivement le pied en suspension tomber au sol, tirer l'autre en Pose",
    "execution.2": "Dès que le pied touche le sol, le tirer vers le haut",
    "execution.3":
      "Reprendre la position de départ le plus rapidement possible",
    "focus.1":
      "Le réflexe de tirage est déclenché par le contact du pied sur le sol",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 à droite / 10  à gauche",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f3cf20ed6692d69800007c6": {
    "label": "Curb lunge back foot elevated",
    "setup": "Fente, le pied arrière sur le trottoir",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Tirer le pied avant en Pose, sous la hanche",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Hausser agressivement les épaules pour vous alléger",
    "focus.2": "Minimiser l'oscillation verticale",
    "focus.3": "",
    "repetitions.1": "5 à droite / 5  à gauche",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5d797d12da8c422b903f2ea9": {
    "label": "Double lunge",
    "setup": "Petite fente",
    "setup.1": "Un pied devant l'autre, un pied d'écart environ",
    "setup.2": "Genoux déverrouillés",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Amener alternativement le pied avant puis le pied arrière sous le bassin",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Tirer le pied avant et le pied arrière en ligne droite sous le bassin ",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1":
      "10 foulées pied droit en avant, 10 foulées pied gauche en avant",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5d8921a7a518513ee9179ea3": {
    "label": "Double pull",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Tirer simultanément les deux pieds sous le bassin, en haussant activement les épaules",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Accentuer le tirage, minimiser la poussée sur les jambes",
    "focus.2": "Minimiser les oscillations verticales",
    "focus.3": "",
    "repetitions.1": "2 fois : ",
    "repetitions.2": "5 sur place",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d797e74da8c422b903f2eb0": {
    "label": "Double pull to switch lunge in place",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Tirer simultanément les deux pieds sous le bassin, en haussant activement les épaules",
    "execution.2": "Atterrir en fente ",
    "execution.3": "Recommencer et atterrir en fente du côté opposé",
    "focus.1": "Accentuer le tirage, minimiser la poussée sur les jambes",
    "focus.2": "Minimiser les oscillations verticales",
    "focus.3": "",
    "repetitions.1": "2 fois : ",
    "repetitions.2": "5 sur place / 5 en avançant",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d8a82b75d8e453dc992784e": {
    "label": "Double pull forward",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Tirer simultanément les deux pieds sous le bassin, en haussant activement les épaules",
    "execution.2": "Se laisser chuter vers l'avant",
    "execution.3": "",
    "focus.1": "Accentuer le tirage, minimiser la poussée sur les jambes",
    "focus.2": "Minimiser les oscillations verticales",
    "focus.3": "",
    "repetitions.1": "2 fois : ",
    "repetitions.2": "5 sur place / 5 en avançant",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d892188a518513ee9179ea2": {
    "label": "Double pull to switch lunge forward",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Tirer simultanément les deux pieds sous le bassin, en haussant activement les épaules",
    "execution.2": "Atterrir en fente et recommencer du côté opposé",
    "execution.3": "Se laisser chuter vers l'avant",
    "focus.1": "Accentuer le tirage, minimiser la poussée sur les jambes",
    "focus.2": "Minimiser les oscillations verticales",
    "focus.3": "",
    "repetitions.1": "2 fois : ",
    "repetitions.2": "6 sur place / 6 en avançant",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d87c15fcdbf8aa87234c258": {
    "label": "Exaggerated high pull one foot",
    "setup": "Pose, un bras tendu le long du corps",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Trottiner en tirant le pied aussi haut que possible",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Garder le buste droit, sans incliner l'incliner vers l'avant ou sur le côté",
    "focus.2": "Tirer le pied verticalement, en ligne droite",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant puis ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5d797ca9da8c422b903f2ea3": {
    "label": "Exaggerated pull one foot",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Trottiner en amenant une cheville à hauteur du genou opposé",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Après le tirage, ramener le pied directement au sol en ligne droite",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5d797cbfda8c422b903f2ea4": {
    "label": "Exaggerated pull one foot 3 heights",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Trottiner en amenant une cheville à mi-tibia opposé, à hauteur du genou opposé puis à la fesse",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Marquer clairement la différence entre les 3 hauteurs",
    "focus.2":
      "Après le tirage, ramener le pied directement au sol en ligne droite",
    "focus.3": "",
    "repetitions.1": "5 fois d'un côté, 5 fois de l'autre",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5d797e8dda8c422b903f2eb2": {
    "label": "Face up hip dips",
    "setup": "Assis jambes tendues",
    "setup.1": "Mains au sol en arrière du bassin",
    "setup.2": "Epaules basses",
    "setup.3": "",
    "execution.1":
      "Monter le bassin jusqu'à l'alignement épaules, bassin et chevilles",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder les jambes tendues",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3X 8 répétitions",
    "repetitions.2": "Courir 10 mètres entre chaque série",
    "repetitions.3": "",
  },
  "5d797e9eda8c422b903f2eb3": {
    "label": "Face up hip dips one leg up",
    "setup": "Assis jambes tendues, un pied surélevé",
    "setup.1": "Mains au sol en arrière du bassin",
    "setup.2": "Epaules basses",
    "setup.3": "",
    "execution.1":
      "Monter le bassin jusqu'à l'alignement épaules, bassin et chevilles",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder les jambes tendues",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x10 répétitions de chaque côté",
    "repetitions.2": "Courir 10 mètres entre chaque série",
    "repetitions.3": "",
  },
  "5f34e829ee7ed9a31442c749": {
    "label": "Fast Pony",
    "setup": "Pony Pose",
    "setup.1": "Pied sous le bassin, orteils en contact avec le sol",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Tirer le pied de support vers le haut, et atterrir en Pony Pose sur l'autre pied",
    "execution.2": "Recommencer immédiatement de l'autre côté",
    "execution.3": "",
    "focus.1": "Tirer le pied de support le moins haut possible",
    "focus.2": "Hausser activement les épaules pour faciliter le tirage",
    "focus.3": "",
    "repetitions.1": "10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d8921dba518513ee9179ea5": {
    "label": "Finger on belly button",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place, placer deux doigts sur le nombril",
    "execution.2":
      "Se laisser tomber en avant en appliquant une pression sur les doigts",
    "execution.3": "",
    "focus.1": "Ressentir la pression sur les doigts en gardant le buste droit",
    "focus.2": "Conserver la sensation de chute lors de la course",
    "focus.3": "",
    "repetitions.1": "2 séries : ",
    "repetitions.2": "10 foulées puis courir 10 mètres",
    "repetitions.3": "",
  },
  "5d797d02da8c422b903f2ea8": {
    "label": "Foot tapping",
    "setup": "Ressort, un bras tendu le long du corps",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Amener la cheville contact de la main en sautillant",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Garder le buste droit, sans l'incliner vers l'avant ou sur le côté",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5d797ce1da8c422b903f2ea6": {
    "label": "Front lunge",
    "setup": "Petite fente",
    "setup.1": "Un pied devant l'autre, un pied d'écart environ",
    "setup.2": "Genoux déverrouillés",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Amener le pied avant sous le bassin",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Tirer le pied en ligne droite sous le bassin",
    "focus.2": "Maintenir l'écart entre les pieds",
    "focus.3": "",
    "repetitions.1": "10 foulées pied droit en avant",
    "repetitions.2": "10 foulées pied gauche en avant",
    "repetitions.3": "Courir 10 mètres",
  },
  "5c1416d889d7e907146f58c1": {
    "label": "Front to back Pendulum",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Monter une jambe tendue vers l'avant puis la ramener au centre",
    "execution.2":
      "Avant que le pied ne touche le sol, changer d'appui et amener l'autre jambe tendue vers l'arrière",
    "execution.3": "",
    "focus.1":
      "Maintenir une cadence de saut identique sur place et lors de la réalisation de l'exercice",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 foulées jambe gauche en avant",
    "repetitions.2": "10 foulées jambe droite en avant",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d83fd73e778a0ed50b04215": {
    "label": "Get in Pose",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Amener une cheville à hauteur du genou opposé",
    "execution.2": "Ramener le pied au sol",
    "execution.3": "",
    "focus.1": "Garder la cheville relâchée",
    "focus.2": "Genou de la jambe d'appui déverrouillé",
    "focus.3": "Garder le poids du corps sur l'avant pied",
    "repetitions.1": "20 répétitions pied droit  / 20  pied gauche",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f4571ada84cf8784884e0a6": {
    "label": "Get in Pose hands overhead",
    "setup": "Ressort, bras tendus au-dessus de la tête",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Amener une cheville à hauteur du genou opposé",
    "execution.2": "Ramener le pied au sol",
    "execution.3": "",
    "focus.1": "Garder la cheville relâchée",
    "focus.2": "Genou de la jambe d'appui déverrouillé",
    "focus.3": "Garder le buste bien vertical",
    "repetitions.1": "20 répétitions pied droit  / 20  pied gauche",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5d8921f3a518513ee9179ea6": {
    "label": "Hands clasped behind",
    "setup":
      "Pose, bras tendus dans le dos, mains jointes et doigts entrelacés ",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Se laisser tomber en éloignant le bassin des mains",
    "execution.3": "",
    "focus.1": "Conserver la sensation de chute lors de la course",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "2 fois :",
    "repetitions.2": "10 sur place, 10 en avançant ",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d89211aa518513ee9179ea0": {
    "label": "Hands clasped in front",
    "setup":
      "Pose, bras tendus horizontalement, mains jointes doigts entrelacés",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place en limitant les oscillations des mains",
    "execution.2":
      "Se laisser tomber en avant en gardant les mains le plus immobile possible",
    "execution.3": "",
    "focus.1": "Conserver la sensation de chute lors de la course",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "2 fois :",
    "repetitions.2": "10 sur place, 10 en avançant ",
    "repetitions.3": "Courir 10 mètres",
  },
  "5d797e4eda8c422b903f2eae": {
    "label": "Hip dips",
    "setup": "Position de planche sur les mains",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener le basin vers le bas puis vers le haut ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder les bras tendus",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x8 répétitions",
    "repetitions.2": "Courir 10 mètres entre chaque série",
    "repetitions.3": "",
  },
  "5d797e5cda8c422b903f2eaf": {
    "label": "Hip dips one leg up",
    "setup": "Position de planche sur les mains, un pied surélevé",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener le basin vers le bas puis vers le haut ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder les bras tendus",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1":
      "3x 5 répétitions avec le pied droit surélevé/5 avec le pied gauche surélevé",
    "repetitions.2": "Courir 10 mètres entre chaque série",
    "repetitions.3": "",
  },
  "5f3c2685d6692d69800007b9": {
    "label": "Hip dips one leg one arm",
    "setup":
      "Position de planche sur les mains, pied gauche et bras droit surélevés",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener le basin vers le bas puis vers le haut ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder les bras tendus",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 séries :",
    "repetitions.2":
      "5 répétitions avec pied gauche et bras droit / 5 avec pied droit et bras gauche",
    "repetitions.3": "Courir 10 mètres entre chaque série",
  },
  "5d797e81da8c422b903f2eb1": {
    "label": "Hip dips one arm",
    "setup": "Position de planche, une main surélevée au niveau des épaules",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener le basin vers le bas puis vers le haut ",
    "execution.2":
      "5 répétitions avec la main droite surélevée, 5 avec la main gauche surélevée",
    "execution.3": "",
    "focus.1": "Garder le bras tendu",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 fois : ",
    "repetitions.2":
      "5 répétitions avec la main droite surélevée/5 avec la main gauche surélevée",
    "repetitions.3": "Courir 10 mètres entre chaque série",
  },
  "5d797ea8da8c422b903f2eb4": {
    "label": "Hip dips lateral",
    "setup": "Position de planche sur le côté",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener le basin vers le bas puis vers le haut ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Corps aligné, les fesses ne doivent pas reculer",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 séries : ",
    "repetitions.2": "5 à droite/5 à gauche",
    "repetitions.3": "Courir 10 mètres entre chaque série",
  },
  "5f3c2691d6692d69800007ba": {
    "label": "Hip dips lateral one leg",
    "setup": "Position de planche sur le côté, jambe supérieure surélevée",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener le basin vers le bas puis vers le haut ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Corps aligné, les fesses ne doivent pas reculer",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 séries : ",
    "repetitions.2": "3x 5 à droite/5 à gauche",
    "repetitions.3": "Courir 10 mètres entre chaque série",
  },
  "5d892135a518513ee9179ea1": {
    "label": "Hops in Pose",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sautiller sur place, en Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Maintenir le pied en Pose, sous le bassin, cheville au niveau du genou opposé",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5f33b0289b46709fe0d32e19": {
    "label": "Lateral Hops in Pose",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sautiller sur place, en Pose",
    "execution.2": "Atterrir alternativement à droite et à gauche",
    "execution.3": "",
    "focus.1":
      "Maintenir le pied en Pose, sous le bassin, cheville au niveau du genou opposé",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5d8a822d5d8e453dc992784c": {
    "label": "Jump rope change of support",
    "setup": "Pose, avec la corde",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sauter à la corde en Pose",
    "execution.2": "Changer de pied tous les deux sauts",
    "execution.3": "",
    "focus.1": "Garder le pied en Pose exactement sous le bassin",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 sauts",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "",
  },
  "5d8a83095d8e453dc9927850": {
    "label": "Jump rope double pull",
    "setup": "Ressort, avec la corde",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter à la corde, pieds joints",
    "execution.2": "Amener les deux pieds simultanément à hauteur de Pose",
    "execution.3": "",
    "focus.1":
      "Garder la tête à la même  hauteur que lors de sauts à la corde classiques",
    "focus.2": "Pousser le moins possible, accentuer le tirage",
    "focus.3": "",
    "repetitions.1": "20 sauts",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "",
  },
  "5d8a81f45d8e453dc992784b": {
    "label": "Jump rope in Pose",
    "setup": "Pose, avec la corde",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sauter à la corde en Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder le pied en Pose exactement sous le bassin",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x 10 à droite/10 à gauche",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "",
  },
  "5d8a832e5d8e453dc9927851": {
    "label": "Jump rope pony",
    "setup": "Pony Pose, avec la corde",
    "setup.1": "Pied en Pose sous le bassin, orteils en contact avec le sol",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sauter à la corde en Pony Pose",
    "execution.2": "Sauter une fois sur place puis changer de pied",
    "execution.3": "",
    "focus.1": "Sauter le moins haut possible",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 sauts",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "",
  },
  "5bf48c43a3e5f36b48bfec46": {
    "label": "Jump rope pony quick",
    "setup": "Pony Pose, avec la corde",
    "setup.1": "Pied en Pose sous le bassin, orteils en contact avec le sol",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sauter à la corde en Pony Pose",
    "execution.2": "Changer de pied à chaque saut",
    "execution.3": "",
    "focus.1": "Sauter le moins haut possible",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 sauts",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "",
  },
  "5d8a828e5d8e453dc992784d": {
    "label": "Jump rope run in place",
    "setup": "Pose, avec la corde",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place avec la corde",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Tirer le pied du sol avant que celui en l'air ne commence à descendre",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 séries :",
    "repetitions.2": "20 foulées sur place",
    "repetitions.3": "Poser la corde puis courir 10 mètres",
  },
  "5d797eb2da8c422b903f2eb5": {
    "label": "Jump rope run",
    "setup": "Pose, avec la corde",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Courir sur place avec la corde puis se laisser chuter vers l'avant",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Tirer le pied du sol avant que celui en l'air ne commence à descendre",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 séries :",
    "repetitions.2": "10 mètres avec la corde ",
    "repetitions.3": "Poser la corde puis courir 10 mètres de plus",
  },
  "5d8a82d95d8e453dc992784f": {
    "label": "Jump rope simple under",
    "setup": "Ressort, avec la corde",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter à la corde, pieds joints",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder les genoux fléchis",
    "focus.2": "Amener les talons au sol à chaque saut",
    "focus.3": "",
    "repetitions.1": "3 séries : ",
    "repetitions.2": "20 sauts",
    "repetitions.3": "Poser la corde puis courir 10 mètres",
  },
  "5f3aa469ee7ed9a31442c773": {
    "label": "Jump rope support foot pull",
    "setup": "Pose, avec la corde",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Sauter à la corde sur le pied de support en l'amenant à hauteur du pied en suspension",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Hausser activement les épaules pour faciliter le tirage",
    "focus.2": "Accentuer le tirage du pied, limiter la poussée",
    "focus.3": "Pendant la course, se focaliser sur le tirage actif du pied",
    "repetitions.1": "2 séries :",
    "repetitions.2": "5 à droite/5 à gauche ",
    "repetitions.3": "Courir 10 mètres",
  },
  "5f3aa56eee7ed9a31442c774": {
    "label": "Jump rope skip",
    "setup": "Ressort, avec la corde",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Amener le pied gauche en Pose tout en sautant à la corde",
    "execution.2": "Atterrir simultanément avec les deux pieds",
    "execution.3": "Recommencer de l'autre côté",
    "focus.1": "Tirer le pied vers le haut en ligne droite",
    "focus.2": "Laisser passivement le pied en suspension tomber au sol",
    "focus.3": "",
    "repetitions.1": "10 sauts",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5d8931b7befa6e434dc8f8f8": {
    "label": "Jump rope foot tapping",
    "setup": "Ressort, avec la corde",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Amener le pied gauche en Pose tout en sautant à la corde",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Garder le buste droit, sans incliner l'incliner vers l'avant ou sur le côté",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sauts",
    "repetitions.2": "Poser la corde puis courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5c13d155df79e52f10967f04": {
    "label": "Jumping jack to Pose",
    "setup": "Ressort, pieds écartés",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "De la position pieds écartés, sauter sur place et atterrir en Pose",
    "execution.2": "Alterner pied gauche et pied droit",
    "execution.3": "",
    "focus.1":
      "Bien atterrir en Pose à chaque saut, pied sous le bassin, genou vers l'avant",
    "focus.2": "Eviter les déplacements latéraux du corps",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797b7bda8c422b903f2e9b": {
    "label": "Jumps feet together and apart",
    "setup": "Ressort,",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter en écartant les pieds",
    "execution.2": "Atterrir pieds joints",
    "execution.3": "",
    "focus.1": "Garder la même cadence sur place et en avançant",
    "focus.2": "Utiliser la gravité pour avancer, aucun effort musculaire",
    "focus.3": "Le talon doit brièvement se poser au sol",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797b62da8c422b903f2e9a": {
    "label": "Jumps feet apart and together",
    "setup": "Ressort, pieds écartés",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "De la position pieds écartés, amener les pieds en contact en l'air ",
    "execution.2": "Atterrir pieds écartés",
    "execution.3": "",
    "focus.1": "Garder la même cadence sur place et en avançant",
    "focus.2": "Utiliser la gravité pour avancer, aucun effort musculaire",
    "focus.3": "Le talon doit brièvement se poser au sol",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b2b2ee7ed9a31442c743": {
    "label": "Lunge switch",
    "setup": "Petite fente",
    "setup.1": "Un pied devant l'autre, un pied d'écart environ",
    "setup.2": "Genoux déverrouillés",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Sauter et atterrir dans la position de fente opposée",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder la même cadence sur place et en avançant",
    "focus.2": "Utiliser la gravité pour avancer, aucun effort musculaire",
    "focus.3": "Le talon doit brièvement se poser au sol",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d8921c2a518513ee9179ea4": {
    "label": "Palm on lower back",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Placer la paume d'une main sur les lombaires",
    "execution.2":
      "Se laisser tomber vers l'avant, comme si vous eloigniez vos lombaires de vos mains",
    "execution.3": "",
    "focus.1": "Ressentir la chute à partir du bassin",
    "focus.2": "Garder le buste vertical",
    "focus.3": "",
    "repetitions.1": "2 séries :",
    "repetitions.2": "10 sur place, 10 en avançant ",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5d797c92da8c422b903f2ea2": {
    "label": "Pony",
    "setup": "Pony Pose",
    "setup.1": "Pied sous le bassin, orteils en contact avec le sol",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Tirer le pied de support vers le haut, et atterrir en Pony Pose sur l'autre pied",
    "execution.2": "Faire un sursaut sur place et recommencer",
    "execution.3": "",
    "focus.1": "Tirer le pied de support le moins haut possible",
    "focus.2": "Hausser activement les épaules pour faciliter le tirage",
    "focus.3": "",
    "repetitions.1": "10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797cf8da8c422b903f2ea7": {
    "label": "Running backward",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Se laisser tomber vers l'arrière",
    "execution.3": "",
    "focus.1": "Le contact initial du pied se fait par l'avant pied",
    "focus.2": "Le talon se pose brièvement au sol à chaque foulée",
    "focus.3": "",
    "repetitions.1": "10 en reculant",
    "repetitions.2": "Se retourner et courir 10 mètres",
    "repetitions.3": "",
  },
  "5f376515ee7ed9a31442c769": {
    "label": "Running forward",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Se laisser tomber vers l'avant",
    "execution.3": "",
    "focus.1": "Le contact initial du pied se fait par l'avant pied",
    "focus.2": "Le talon se pose brièvement au sol à chaque foulée",
    "focus.3": "",
    "repetitions.1": "Courir sur 20 mètres",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5d8920d3a518513ee9179e9f": {
    "label": "Running hands overhead",
    "setup": "Pose, bras tendus au-dessus de la tête",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Se laisser tomber vers l'avant",
    "execution.3": "",
    "focus.1": "Le buste est vertical, les épaules au-dessus du bassin",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797ccdda8c422b903f2ea5": {
    "label": "Running in place",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Se laisser tomber vers l'avant",
    "execution.3": "",
    "focus.1": "Le contact initial du pied se fait par l'avant pied",
    "focus.2": "Le talon se pose brièvement au sol à chaque foulée",
    "focus.3": "",
    "repetitions.1": "3 séries :",
    "repetitions.2": "20 sur place",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5f356b56ee7ed9a31442c75c": {
    "label": "Run into fall over line",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Se tenir devant la ligne, de talons juste en avant de la ligne",
    "execution.2":
      "Commencer à courir sur place, puis poser  les pieds derrière la ligne",
    "execution.3": "Maintenir l'angle de chute et courir vers l'avant",
    "focus.1":
      "Garder le torse au même endroit lors de l'atterrissage derrière la ligne",
    "focus.2": "Conserver l'angle de chute",
    "focus.3": "",
    "repetitions.1": "3 séries :",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797c58da8c422b903f2e9e": {
    "label": "Running Pose",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Monter un pied directement sous le bassin, cheville au niveau du genou opposé",
    "execution.2": "Garder le genou de la jambe de support fléchi",
    "execution.3": "Conserver le poids du corps sur l'avant pied",
    "focus.1": "Se focaliser sur la trajectoire rectiligne du pied",
    "focus.2":
      "Cheville à la hauteur du genou (les faire se toucher pour vérifier)",
    "focus.3": "",
    "repetitions.1": "30 s à droite",
    "repetitions.2": "30 s à gauche",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5f4571a2a84cf8784884e0a5": {
    "label": "Running Pose hands overhead",
    "setup": "Ressort, bras tendus au-dessus de la tête",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Monter un pied directement sous le bassin, cheville au niveau du genou opposé",
    "execution.2": "Garder le genou de la jambe de support fléchi",
    "execution.3": "Conserver le poids du corps sur l'avant pied",
    "focus.1": "Se focaliser sur la trajectoire rectiligne du pied",
    "focus.2": "Ne pas monter activement le genou",
    "focus.3": "",
    "repetitions.1": "30 s à droite",
    "repetitions.2": "30 s à gauche",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5d87c249cdbf8aa87234c259": {
    "label": "Running with exagerated pull",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Tirer alternativement le pied le plus haut possible",
    "execution.3": "Se laisser tomber vers l'avant",
    "focus.1": "Le pied doit monter en ligne droite sous le bassin",
    "focus.2": "Le contact initial du pied se fait par l'avant pied",
    "focus.3": "Le talon se pose brièvement au sol à chaque foulée",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d801d8fa7450b4876bb8a94": {
    "label": "Side to side pendulum",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Monter la jambe gauche tendue sur le côté en sautillant puis la ramener au centre",
    "execution.2":
      "Avant que le pied touche le sol, amener l'autre jambe tendue de l'autre côté",
    "execution.3": "",
    "focus.1": "Maintenir la même cadence de saut sur place et en avançant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33afc49b46709fe0d32e18": {
    "label": "Side pendulum to pose",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Monter la jambe gauche tendue sur le côté en sautillant puis la ramener au centre",
    "execution.2":
      "Avant que le pied touche le sol, amener l'autre jambe en Pose",
    "execution.3": "",
    "focus.1": "Maintenir la même cadence de saut sur place et en avançant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "changer de côté",
  },
  "5c1417a489d7e907146f58c2": {
    "label": "Skip",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Sauter en tirant activement un pied vers le haut, sous le bassin",
    "execution.2": "Atterrir simultanément avec les deux pieds ",
    "execution.3": "",
    "focus.1": "Tirer rapidement le pied en ligne droite et verticale",
    "focus.2": "Laisser tomber le pied en l'air naturellement vers le sol",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5c1417be89d7e907146f58c3": {
    "label": "Skip alternating ",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter en tirant un pied vers le haut, sous le bassin",
    "execution.2": "Atterrir simultanément avec les deux pieds ",
    "execution.3": "Recommencer de l'autre côté",
    "focus.1": "Tirer rapidement le pied en ligne droite et verticale",
    "focus.2": "Laisser tomber le pied naturellement vers le sol",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b0f6c30309477cb647e0": {
    "label": "Skip alternating fast",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter en tirant un pied vers le haut, sous le bassin",
    "execution.2": "Atterrir simultanément avec les deux pieds ",
    "execution.3": "Recommencer de l'autre côté",
    "focus.1": "Tirer rapidement le pied en ligne droite et verticale",
    "focus.2": "Laisser tomber le pied naturellement vers le sol",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b2d4ee7ed9a31442c744": {
    "label": "Spring position to lunge",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sauter et atterrir en fente, pied gauche en avant",
    "execution.2": "Sauter pour revenir à la position de départ",
    "execution.3": "Sauter et atterrir en fente, pied droit en avant",
    "focus.1": "Garder la même cadence sur place et en avançant",
    "focus.2": "Utiliser la gravité pour avancer, aucun effort musculaire",
    "focus.3": "Le talon doit brièvement se poser au sol",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797d6bda8c422b903f2eab": {
    "label": "Support foot pull",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Sauter sur le pied de support en l'amenant à hauteur du pied en Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Hausser activement les épaules pour faciliter le tirage",
    "focus.2": "Accentuer le tirage du pied, limiter la poussée",
    "focus.3": "Pendant la course, se focaliser sur le tirage actif du pied",
    "repetitions.1": "2 séries :",
    "repetitions.2": "5 à droite/5 à gauche ",
    "repetitions.3": "Courir 10 mètres",
  },
  "5c14185a89d7e907146f58c5": {
    "label": "Support foot pull forward",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Sauter sur le pied de support en l'amenant à hauteur du pied en Pose",
    "execution.2": "Se laisser tomber vers l'avant",
    "execution.3": "",
    "focus.1": "Hausser activement les épaules pour faciliter le tirage",
    "focus.2": "Accentuer le tirage du pied, limiter la poussée",
    "focus.3": "Pendant la course, se focaliser sur le tirage actif du pied",
    "repetitions.1": "5 sur place, 5 en avançant ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5d797c65da8c422b903f2e9f": {
    "label": "Timber drill",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Se laisser tomber vers l'avant et se rattraper au dernier moment",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Ressentir la chute à partir du bassin",
    "focus.2": "Garder le buste droit",
    "focus.3": "",
    "repetitions.1": " 5 à droite/5 à gauche ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5c0520ec1d7f3447b4c01cc1": {
    "label": "Timber drill in Pose",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Se laisser tomber vers l'avant et se rattraper au dernier moment",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Ressentir la chute à partir du bassin",
    "focus.2": "Garder le buste droit",
    "focus.3": "",
    "repetitions.1": " 5 à droite/5 à gauche ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5c141c0289d7e907146f58c8": {
    "label": "Timber Drill with change of support",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Se laisser tomber vers l'avant ",
    "execution.2":
      "Tirer sur le pied de support pour atterrir en Pose de l'autre côté",
    "execution.3": "",
    "focus.1": "Tirer activement le pied de support dès la fin de la chute",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": " 5 à droite/5 à gauche ",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5f356aa9ee7ed9a31442c759": {
    "label": "Timber Drill with change of support",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Se laisser tomber vers l'avant ",
    "execution.2":
      "Tirer sur le pied de support pour atterrir en Pose de l'autre côté",
    "execution.3": "Recommencer de l'autre côté",
    "focus.1": "Tirer activement le pied de support dès la fin de la chute",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 fois",
    "repetitions.2": "Courir 10 mètres",
    "repetitions.3": "",
  },
  "5c1417f589d7e907146f58c4": {
    "label": "Toes in and Out",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Sautiller en orientant les orteils vers l'extérieur, vers l'avant puis vers l'intérieur",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Le talon doit brièvement se poser au sol",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b18eee7ed9a31442c73f": {
    "label": "Toes in and out feet apart",
    "setup": "Ressort, pieds écartés",
    "setup.1": "Pieds écartés, genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Sautiller en orientant les orteils vers l'extérieur puis vers l'intérieur",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Le talon doit brièvement se poser au sol",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b273ee7ed9a31442c740": {
    "label": "Toes in and out in Pose",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1":
      "Sautiller en orientant les orteils vers l'extérieur puis vers l'intérieur",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Le talon doit brièvement se poser au sol",
    "focus.2": "Maintenir la Pose, pied aérien sous le bassin",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "Changer de côté",
  },
  "5c14192e89d7e907146f58c6": {
    "label": "Twist",
    "setup": "Ressort",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Sautiller en orientant alternativement le bassin d'un côté puis de l'autre",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder au maximum les épaules de face",
    "focus.2": "Le talon doit brièvement se poser au sol",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b28eee7ed9a31442c741": {
    "label": "Twist feet apart",
    "setup": "Ressort, pieds écartés",
    "setup.1": "Pieds écartés, genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1":
      "Sautiller en orientant alternativement le bassin d'un côté puis de l'autre",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder au maximum les épaules de face",
    "focus.2": "Le talon doit brièvement se poser au sol",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f33b2a0ee7ed9a31442c742": {
    "label": "Twist to crossed lunge",
    "setup": "Ressort, pieds écartés",
    "setup.1": "Pieds écartés, genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "Coudes fléchis à 90°",
    "execution.1": "Sautiller en orientant le bassin vers la gauche",
    "execution.2": "Atterrir en fente vers la gauche",
    "execution.3":
      "Revenir à la position de départ en sautant et changer de côté",
    "focus.1": "Garder au maximum les épaules de face",
    "focus.2": "Le talon doit brièvement se poser au sol",
    "focus.3": "",
    "repetitions.1": "10 sur place, 10 en avançant ",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5f34e6adee7ed9a31442c748": {
    "label": "Wall Pose",
    "setup": "Ressort, adossé au mur",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "",
    "execution.1": "Tirer le pied en Pose sans toucher le mur",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Tirer le pied vers le haut, sous la hanche",
    "focus.2": "Garder le pied proche du mur",
    "focus.3": "",
    "repetitions.1": "10 avec le pied gauche / 10 avec le pied droit",
    "repetitions.2": "Courir sur 10 mètres",
    "repetitions.3": "",
  },
  "5d797c75da8c422b903f2ea0": {
    "label": "Wall fall",
    "setup": "Ressort, à environ 1 mètre du mur",
    "setup.1": "Genoux fléchis",
    "setup.2": "Poids du corps sur l'avant-pied",
    "setup.3": "",
    "execution.1": "Se laisser tomber vers l'avant ",
    "execution.2": "Se rattraper au dernier moment en posant les mains au mur",
    "execution.3": "",
    "focus.1": "Chuter à partir du bassin",
    "focus.2": "Garder le buste droit",
    "focus.3": "",
    "repetitions.1": "3 séries :",
    "repetitions.2": "8 répétitions",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5d797c83da8c422b903f2ea1": {
    "label": "Wall fall in Pose",
    "setup": "Pose, à environ 1 mètre du mur",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Se laisser tomber vers l'avant ",
    "execution.2": "Se rattraper au dernier moment en posant les mains au mur",
    "execution.3": "",
    "focus.1": "Chuter à partir du bassin",
    "focus.2": "Garder le buste droit",
    "focus.3": "",
    "repetitions.1": "2 séries : ",
    "repetitions.2": "5 à droite, 5 à gauche ",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5f356aebee7ed9a31442c75b": {
    "label": "Wall fall with change of support",
    "setup": "Pose, à environ 1.20 mètre du mur",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Se laisser tomber vers l'avant ",
    "execution.2": "Tirer le pied du sol et atterir en Pose",
    "execution.3": "Maintenir la chute et se rattraper au mur",
    "focus.1": "Chuter à partir du bassin",
    "focus.2": "Garder le buste droit",
    "focus.3": "Maintenir la chute lors du changement de support",
    "repetitions.1": "2 séries : ",
    "repetitions.2": "5 à droite, 5 à gauche ",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5f356ad7ee7ed9a31442c75a": {
    "label": "Wall run",
    "setup": "Pose, à environ 1.20 mètre du mur",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "S'appuyer contre le mur",
    "execution.2": "Courir en chutant du bassin",
    "execution.3": "",
    "focus.1": "Chuter à partir du bassin",
    "focus.2": "Garder le buste droit",
    "focus.3": "",
    "repetitions.1": "2 séries : ",
    "repetitions.2": "20 tirages",
    "repetitions.3": "Courir sur 10 mètres",
  },
  "5f463240198824d49ce9aed0": {
    "label": "90 to 90",
    "setup":
      "Pieds écartés plus larges que la largeur des épaules, genoux fléchis à 90",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles autour de l'articulation de la hanche",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f459585f53aec6cb4d40395": {
    "label": "90 to 90 in half lotus",
    "setup": "Un genou plié à 90°, la cheville opposée sur le genou",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles autour de l'articulation de la hanche",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463269198824d49ce9aed3": {
    "label": "Alternate knee to floor hands in front",
    "setup": "Position large, mains posées au sol",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "La face interne du pied doit être au sol lorsque le genou touche le sol",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46328d198824d49ce9aed7": {
    "label": "Ankle supination alternate feet crossed",
    "setup": "Pieds croisés, un pied sur le bord extérieur",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles de la face externe de la cheville.",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463284198824d49ce9aed6": {
    "label": "Ankle supination foot in front",
    "setup": "Pieds écartés à la largeur des hanches, un pied devant",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles de la face externe de la cheville",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463273198824d49ce9aed4": {
    "label": "Ankle supination one foot",
    "setup": "Pieds écartés à la largeur des hanches",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles de la face externe de la cheville",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46327c198824d49ce9aed5": {
    "label": "Ankle supination two feet",
    "setup": "Pieds écartés à la largeur des épaules",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles de la face externe de la cheville",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463295198824d49ce9aed8": {
    "label": "Floor touch",
    "setup": "Pieds écartés à la largeur des hanches",
    "setup.1": "Jambes tendues",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "5f46329d198824d49ce9aed9": {
    "label": "Floor touch feet apart",
    "setup": "Pieds très écartés",
    "setup.1": "Jambes tendues",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4632a5198824d49ce9aeda": {
    "label": "Floor touch feet inline",
    "setup": "Un pied devant, aligné avec l'autre",
    "setup.1": "Jambes tendues",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 petits rebonds en fin d'amplitude de mouvement, ",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "Changer de côté",
  },
  "5f4632b1198824d49ce9aedb": {
    "label": "Floor touch feet supinated",
    "setup":
      "Pieds écartés à la largeur des hanches, les deux pieds sur le bord extérieur",
    "setup.1": "Jambes tendues",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4632c2198824d49ce9aedd": {
    "label": "Floor touch toes out",
    "setup": "Pieds écartés à la largeur des hanches, orteils vers l'extérieur",
    "setup.1": "Jambes tendues",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4632b9198824d49ce9aedc": {
    "label": "Front bend hands clasped in the back",
    "setup":
      "Pieds écartés à la largeur des hanches, doigts entrelacés dans le dos",
    "setup.1": "Jambes tendues",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relâcher les muscles ischio-jambiers",
    "focus.2": "Laisser les bras pendre passivement",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "5f463261198824d49ce9aed2": {
    "label": "Half lotus head to foot",
    "setup": "Un genou plié à 90°, la cheville opposée sur le genou",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre les muscles autour de l'articulation de la hanche",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4631ec198824d49ce9aec7": {
    "label": "Hand beetween shoulder blades overhead stretch",
    "setup": "Une main entre l'omoplate, le coude vers le haut",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre les muscles autour de l'articulation de l'épaule",
    "focus.2": "La main doit descendre autant que possible ",
    "focus.3": "Garder le buste vertical",
    "repetitions.1": "20 secondes de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4631e4198824d49ce9aec6": {
    "label": "Hand beetween shoulder blades stretch",
    "setup": "Une main entre l'omoplate, le coude vers le bas",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre les muscles autour de l'articulation de l'épaule",
    "focus.2": "La main doit monter le plus haut possible ",
    "focus.3": "Gardez la cage thoracique baissée.",
    "repetitions.1": "20 secondes de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46320c198824d49ce9aecb": {
    "label": "Interlaced wrist rotation",
    "setup": "Bras entrelacés, mains jointes, main droite sur le dessus ",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre les muscles des avant-bras",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 répétitions main droite en haut",
    "repetitions.2": "5 répétitions main gauche en haut",
    "repetitions.3": "",
  },
  "5f463220198824d49ce9aecc": {
    "label": "Interlaced wrist rotation down to overhead",
    "setup": "Bras entrelacés, mains jointes, main droite sur le dessus ",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre les muscles des avant-bras",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 répétitions main droite en haut",
    "repetitions.2": "5 répétitions main gauche en haut",
    "repetitions.3": "",
  },
  "5f463204198824d49ce9aeca": {
    "label": "Inverted prayer",
    "setup": "Position de prière à l'envers",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Coudes légèrement en avant",
    "focus.2": "Extension des poignets, paumes jointes ",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f48c3c3c378c23548cf4d38": {
    "label": "Lateral hurdle",
    "setup": "Pieds rapprochés",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Le genou doit passer au-dessus du pli de la hanche",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 allers-retours",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463121198824d49ce9aec3": {
    "label": "Rear fists bump shoulder stretch",
    "setup": "Poings fermés dans le dos",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Détendre le muscle de l'épaule en amenant les coudes vers l'avant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds en fin d'amplitude de mouvement",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4630f9198824d49ce9aebf": {
    "label": "Reverse prayer",
    "setup": "Position de prière mains dans le dos",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Détendre le muscle de l'épaule en amenant les coudes vers l'avant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 secondes",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463105198824d49ce9aec0": {
    "label": "Shoulder blade scratch",
    "setup":
      "Une main dans le dos, les doigts touchant l'omoplate correspondante",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder la cage thoracique baissée",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 secondes de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46310f198824d49ce9aec1": {
    "label": "Shoulder blade scratch two hands",
    "setup":
      "Les deux mains dans le dos, les doigts touchant l'omoplate correspondante",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder la cage thoracique baissée",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 secondes",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4631f3198824d49ce9aec8": {
    "label": "Shoulder stretch palms on the back",
    "setup":
      "Paumes dans le dos aussi haut que possible, doigts pointant vers le bas",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre les muscles autour de l'articulation de l'épaule",
    "focus.2": "Gardez les épaules basses",
    "focus.3": "",
    "repetitions.1": "10 allers et retours",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463228198824d49ce9aecd": {
    "label": "Standing hip inward rotation",
    "setup": "Un genou relevé sur le côté",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Gardez le genou au-dessus du pli de la hanche en le déplaçant du côté vers l'avant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions sur chaque jambe",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463230198824d49ce9aece": {
    "label": "Standing hip outward rotation",
    "setup": "Un genou devant",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Maintenez le genou au-dessus du pli de la hanche en le déplaçant de l'avant vers le côté.",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions sur chaque jambe",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463238198824d49ce9aecf": {
    "label": "Standing hip rotation holding foot",
    "setup": "Un genou devant, la main tenant le pied levé",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder le torse aussi vertical que possible",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 répétitions sur chaque jambe",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4631fc198824d49ce9aec9": {
    "label": "Standing wrist opener",
    "setup": "Un bras plié, le poignet étiré par la main opposée",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Détendre l'avant-bras du poignet en extension",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6016fb19a9a25e7d498454cb": {
    "label": "standing quad stretch",
    "setup": "",
    "setup.1": "Une jambe pliée, tenir la cheville",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amenez votre pied aux fesses",
    "execution.2": "Avancez le bassin vers l'avant",
    "execution.3": "",
    "focus.1": "Sentez l'étirement sur le quadriceps",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 secondes minimum de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6016fb4ab4d2ad7d5aeb2f35": {
    "label": "standing quad stretch with floor touch ",
    "setup": "",
    "setup.1": "Une jambe pliée, tenir la cheville",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Apportez votre pied au derrière",
    "execution.2":
      "Se pencher en avant, en essayant de garder le genou, le bassin et l'épaule alignés",
    "execution.3": "",
    "focus.1": "Sentez l'étirement sur le quadriceps",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 secondes minimum de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6017c649bffb647d5bc52639": {
    "label": "seated legs appart head to floor",
    "setup": "",
    "setup.1": "Assis avec les jambes écartées",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amenez vos mains loin devant",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Sentez la tension sur les ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6017c6a23157887d48886fab": {
    "label": "seated legs apart head to knee",
    "setup": "",
    "setup.1": "Assis avec les jambes écartées",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Rapprochez votre tête d'un genou",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Sentez la tension sur les ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds à la fin de l'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "",
  },
  "6017c665bffb647d5bc5263a": {
    "label": "seated one leg behind head to knee",
    "setup": "",
    "setup.1": "Assis avec une jambe devant, l'autre pliée derrière",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amenez votre tête vers le genou de devant",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Sentez la tension sur les ischio-jambiers",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 petits rebonds à la fin de l'amplitude de mouvement",
    "repetitions.2": "20 secondes en statique",
    "time": "20",
    "repetitions.3": "Changez de côté",
  },
  "5f463117198824d49ce9aec2": {
    "label": "Upper and lower hands touch in the back",
    "setup":
      "Attrapez vos mains dans le dos, un coude au-dessus, l'autre coude en bas",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder la cage thoracique baissée",
    "focus.2": "Relâcher les muscles des épaules",
    "focus.3": "",
    "repetitions.1": "20 secondes de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5ffc856692910d5db9167e4b": {
    "label": "Warm-up run",
    "setup": "Pose",
    "setup.1": "Pied sous le bassin, cheville au niveau du genou opposé",
    "setup.2": "Genou de la jambe de support déverrouillé",
    "setup.3": "Poids du corps sur l'avant-pied",
    "execution.1": "Courir sur place",
    "execution.2": "Se laisser tomber vers l'avant",
    "execution.3": "",
    "focus.1": "Le contact initial du pied se fait par l'avant pied",
    "focus.2": "Le talon se pose brièvement au sol à chaque foulée",
    "focus.3": "",
    "repetitions.1": "Courir entre 5 et 15 minutes",
    "repetitions.2": "",
    "repetitions.3": "",
  },

  "5fe21af84d1d8c5b2d3f3711": {
    "label": "Achilles eccentric ",
    "setup": "",
    "setup.1":
      "Debout avec l'avant des pieds sur la marche, les talons à l'extérieur",
    "setup.2": "Jambes tendues",
    "setup.3": "",
    "execution.1": "Soulevez les deux talons puis retirez un pied de la marche",
    "execution.2": "Abaissez le talon du pied laissé sur la marche,",
    "execution.3": "Remettre le pied en l'air sur la marche",
    "focus.1":
      "Vous devez sentir l'étirement de votre tendon d'Achille en descendant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 à 30 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa0e3db441bdc7f651759": {
    "label": "Achilles eccentric stretch bent knee",
    "setup":
      "Debout avec l'avant des pieds sur la marche, les talons à l'extérieur",
    "setup.1": "Jambes pliées",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Soulevez les deux talons puis retirez un pied de la marche",
    "execution.2": "Abaissez le talon du pied laissé sur la marche,",
    "execution.3": "Remettre le pied en l'air sur la marche",
    "focus.1":
      "Vous devez sentir l'étirement de votre tendon d'Achille en descendant",
    "focus.2": "Gardez vos jambes pliées pendant tout le mouvement",
    "focus.3": "",
    "repetitions.1": "20 à 30 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa164db441bdc7f65175a": {
    "label": "achilles eccentric straight to bent knee",
    "setup": "",
    "setup.1":
      "Debout avec l'avant des pieds sur la marche, les talons à l'extérieur",
    "setup.2": "Jambes tendues",
    "setup.3": "",
    "execution.1": "Soulevez les deux talons puis retirez un pied de la marche",
    "execution.2":
      "Abaissez le talon restant tout en fléchissant légèrement le genou",
    "execution.3": "Remettre le pied en l'air sur la marche",
    "focus.1":
      "Vous devez sentir l'étirement de votre tendon d'Achille en descendant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 à 30 répétitions de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa18ddb441bdc7f65175b": {
    "label": "achilles eccentric weighted",
    "setup": "",
    "setup.1":
      "Debout avec l'avant des pieds sur la marche, les talons à l'extérieur",
    "setup.2": "Tenir la charge du côté travaillé",
    "setup.3": "Utilisez un poids modéré avec lequel vous êtes à l'aise",
    "execution.1": "Soulevez les deux talons puis retirez un pied de la marche",
    "execution.2": "Abaissez le talon du pied laissé sur la marche,",
    "execution.3": "Remettre le pied en l'air sur la marche",
    "focus.1":
      "Vous devez sentir l'étirement de votre tendon d'Achille en descendant",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 représentants de chaque côté",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1b6db441bdc7f65175c": {
    "label": "Achilles myofascial release",
    "setup": "",
    "setup.1": "Tendon d'Achille sur le rouleau, l'autre jambe sur le dessus",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Rotations de la cheville dans les deux sens",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1ccdb441bdc7f65175d": {
    "label": "Achilles roll",
    "setup": "",
    "setup.1": "Tendon d'Achille sur le rouleau, l'autre jambe sur le dessus",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le tendon puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1dedb441bdc7f65175e": {
    "label": "Achilles smash",
    "setup": "",
    "setup.1": "Tendon d'Achille sur la balle",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Des mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa3d3db441bdc7f651760": {
    "label": "Achilles transverse massage",
    "setup": "",
    "setup.1": "Assis, une jambe croisée ",
    "setup.2": "Les deux pouces sur votre tendon d'Achille",
    "setup.3": "",
    "execution.1": "Masser perpendiculairement au tendon d'Achille",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le tendon puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa3e3db441bdc7f651761": {
    "label": "Adductor roll",
    "setup": "",
    "setup.1": "Jambe repliée sur le côté à 90° sur le rouleau",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1efdb441bdc7f65175f": {
    "label": "calf bar smash",
    "setup": "",
    "setup.1": "Barre perpendiculaire au mollet",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Faire rouler la barre sur le mollet",
    "execution.2": "Ajuster la pression de la barre",
    "execution.3": "",
    "focus.1": "Garder le mollet relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4bedb441bdc7f651763": {
    "label": "calf smash myofascial release",
    "setup": "",
    "setup.1": "Mollet sur la balle",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Trouver un point sensible et y rester ",
    "execution.2": "Croiser l'autre jambe par dessus",
    "execution.3": "Ankle circles in both directions",
    "focus.1": "Garder le mollet relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4c9db441bdc7f651764": {
    "label": "calf myofascial release",
    "setup": "",
    "setup.1": "Un mollet sur le rouleau, l'autre jambe par dessus",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Trouver un point sensible et y rester ",
    "execution.2": "Rotations de la cheville dans les deux sens",
    "execution.3": "",
    "focus.1": "Garder le mollet relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4d3db441bdc7f651765": {
    "label": "calf roll one leg",
    "setup": "",
    "setup.1": "Un mollet sur le rouleau, l'autre jambe par dessus",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fb180db441bdc7f65177c": {
    "label": "calf roll two legs",
    "setup": "",
    "setup.1": "Les deux mollets sur le rouleau",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fb1b3db441bdc7f65177d": {
    "label": "calf smash",
    "setup": "",
    "setup.1": "Un mollet sur la balle ",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder le mollet relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fb20adb441bdc7f65177e": {
    "label": "external shin myofascial release",
    "setup": "",
    "setup.1": "Jambe pliée avec le muscle tibial externe sur la balle",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Trouver un point sensible et y rester ",
    "execution.2": "Cercles de cheville dans les deux sens",
    "execution.3": "",
    "focus.1": "Garder le muscle relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa3f0db441bdc7f651762": {
    "label": "external shin smash",
    "setup": "",
    "setup.1": "Jambe pliée avec le muscle tibial externe sur la balle",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4f1db441bdc7f651767": {
    "label": "shin myofascial release",
    "setup": "",
    "setup.1": "Muscles antérieurs du tibia sur le rouleau",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Trouver un point sensible et y rester ",
    "execution.2": "Cercles de cheville dans les deux sens",
    "execution.3": "",
    "focus.1": "Garder le muscle relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4f9db441bdc7f651768": {
    "label": "shin roll one leg",
    "setup": "",
    "setup.1": "Muscles antérieurs du tibia sur le rouleau",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa501db441bdc7f651769": {
    "label": "shin roll two legs",
    "setup": "",
    "setup.1": "Muscles antérieurs des tibias sur le rouleau",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder le muscle relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa50adb441bdc7f65176a": {
    "label": "glute roll",
    "setup": "",
    "setup.1": "S'asseoir sur le rouleau",
    "setup.2": "Jambes croisées, cheville sur le genou opposé",
    "setup.3": "",
    "execution.1": "Rouler la fesse de la jambe croisée",
    "execution.2": "Mouvements lents et contrôlés ",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa513db441bdc7f65176b": {
    "label": "glute smash",
    "setup": "",
    "setup.1": "Jambes croisées, cheville sur le genou opposé",
    "setup.2": "Mettre la balle sous la fesse de la jambe croisée",
    "setup.3": "",
    "execution.1": "Rouler la fesse de la jambe croisée",
    "execution.2": "Mouvements lents et contrôlés ",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa51bdb441bdc7f65176c": {
    "label": "hamstring roll one leg",
    "setup": "",
    "setup.1": "Ischio-jambier sur le rouleau",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa523db441bdc7f65176d": {
    "label": "hamstring roll two legs",
    "setup": "",
    "setup.1": "Les deux ischio-jambiers sur le rouleau",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa52adb441bdc7f65176e": {
    "label": "hamstring roll crossed leg",
    "setup": "",
    "setup.1": "Ischio-jambier sur le rouleau",
    "setup.2": "Autre jambe croisée par dessus",
    "setup.3": "Fesses décollées du sol",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa534db441bdc7f65176f": {
    "label": "Internal shin myofascial release",
    "setup": "",
    "setup.1": "Boule sur la face interne du tibia, légèrement en arrière",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Trouver un point sensible et y rester ",
    "execution.2": "Cercles de cheville dans les deux sens",
    "execution.3": "",
    "focus.1": "Insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa53bdb441bdc7f651770": {
    "label": "Internal shin smash",
    "setup": "",
    "setup.1": "Boule sur la face interne du tibia, légèrement en arrière",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa544db441bdc7f651771": {
    "label": "kneeling toe stretch one foot",
    "setup": "",
    "setup.1": "A genoux d'un côté, les orteils fléchis",
    "setup.2": "Autre pied à plat sur le sol",
    "setup.3": "",
    "execution.1":
      "Appliquez une légère pression sur vos orteils fléchis en vous asseyant",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Appliquer la tension de façon progressive",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa54edb441bdc7f651772": {
    "label": "kneeling toe stretch two feet",
    "setup": "",
    "setup.1": "A genoux les orteils fléchis",
    "setup.2": "",
    "setup.3": "",
    "execution.1":
      "Appliquez une légère pression sur vos orteils fléchis en vous asseyant",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Appliquer la tension de façon progressive",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa556db441bdc7f651773": {
    "label": "lower leg shin saw",
    "setup": "",
    "setup.1": "A genoux d'un côté",
    "setup.2": "L'autre tibia perpendiculairement au mollet",
    "setup.3": "",
    "execution.1":
      "Appliquez une légère pression pour masser l'ensemble du mollet avec le tibia",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Garder le muscle relaché",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa55fdb441bdc7f651774": {
    "label": "Piriformis Roll",
    "setup": "",
    "setup.1": "S'asseoir sur le rouleau",
    "setup.2": "Jambes croisées, cheville sur le genou opposé",
    "setup.3": "Incliner la jambe croisée sur le côté",
    "execution.1": "Faire rouler la fesse de la jambe croisée sur la balle",
    "execution.2": "Mouvements lents et contrôlés ",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa567db441bdc7f651775": {
    "label": "Piriformis smash",
    "setup": "",
    "setup.1": "Jambes croisées, cheville sur le genou opposé",
    "setup.2": "Mettre la balle sous la fesse de la jambe croisée",
    "setup.3": "Incliner la jambe croisée sur le côté",
    "execution.1": "Faire rouler la fesse de la jambe croisée sur la balle",
    "execution.2": "Mouvements lents et contrôlés ",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa570db441bdc7f651776": {
    "label": "quad roll one leg",
    "setup": "",
    "setup.1": "Quadriceps sur le rouleau",
    "setup.2": "Reposez-vous sur vos avant-bras",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa579db441bdc7f651777": {
    "label": "quad roll two legs",
    "setup": "",
    "setup.1": "Les deux quadriceps sur le rouleau",
    "setup.2": "Reposez-vous sur vos avant-bras",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6017c513a9a25e7d498454cd": {
    "label": "quad smash",
    "setup": "",
    "setup.1": "Le quadriceps sur la balle",
    "setup.2": "Reposez-vous sur vos avant-bras",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa582db441bdc7f651778": {
    "label": "quad bar smash",
    "setup": "",
    "setup.1": "Assis, barre posée perpendiculairement sur le quadriceps",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Masser tout le muscle puis insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa58ddb441bdc7f651779": {
    "label": "toe to calf stretch",
    "setup": "",
    "setup.1": "Orteils fléchis contre un mur ou une marche",
    "setup.2": "Le reste du pied à plat sur le sol",
    "setup.3": "",
    "execution.1": "Amenez doucement votre genou vers l'avant",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Appliquer la tension de manière progressive",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa595db441bdc7f65177a": {
    "label": "Underfoot ball of foot smash",
    "setup": "",
    "setup.1": "Le pied sur la balle,",
    "setup.2": "Balle juste derrière l'avant du pied",
    "setup.3": "",
    "execution.1": "Gardez le talon au sol",
    "execution.2": "Déplacer l'avant-pied d'un côté à l'autre",
    "execution.3": "",
    "focus.1": "Insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa5a4db441bdc7f65177b": {
    "label": "Underfoot Pre heel  smash",
    "setup": "",
    "setup.1": "Le pied sur la balle,",
    "setup.2": "La balle juste en avant du talon",
    "setup.3": "",
    "execution.1": "Garder l'avant-pied au sol",
    "execution.2": "Déplacer le talon d'un côté à l'autre",
    "execution.3": "",
    "focus.1": "Insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 secondes minimum de chaque côté",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f225c7dc86d3d655edeee": {
    "label": "Lower back roll",
    "setup": "",
    "setup.1": "Rouleau sur le bas du dos",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Masser les muscles lombaires et insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f233e7dc86d3d655edeef": {
    "label": "Upper back roll",
    "setup": "",
    "setup.1": "Rouleau sur le haut du dos",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Masser les muscles du haut du dos et insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f23987dc86d3d655edef0": {
    "label": " Lower back Side roll",
    "setup": "",
    "setup.1": "Rouleau sur le côté du bas du dos",
    "setup.2": "Fesses décollées du sol",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Masser les muscles latéraux du bas du dos et insister sur les zones douloureuses",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 secondes minimum de chaque côté",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f23d27dc86d3d655edef1": {
    "label": "T-spine extensions",
    "setup": "",
    "setup.1": "Rouleau sur le haut du dos, sous les omoplates.",
    "setup.2": "Mains ouvertes, poignets croisés derrière la tête",
    "setup.3": "",
    "execution.1": "Mouvements lents et contrôlés",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Inspirez en ouvrant la cage thoracique, expirez en revenant en position initiale.",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 secondes minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f3c29a7d6692d69800007bd": {
    "label": "forward lunge",
    "setup": "Tenez-vous debout, les pieds écartés sous vos hanches.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Faites un grand pas en avant",
    "execution.2":
      "Descendez le buste jusqu'à ce que votre genou arrière touche légèrement le sol.",
    "execution.3":
      "Appuyez sur votre talon avant pour revenir à la position de départ. Changez de jambe.",
    "focus.1": "Gardez votre buste vertical.",
    "focus.2": "Votre genou avant ne doit pas dépasser vos orteils.",
    "focus.3": "",
    "repetitions.1": "12 lunges alternés",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f3c285fd6692d69800007bc": {
    "label": "backward lunge",
    "setup": "Tenez-vous debout, les pieds écartés sous vos hanches.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Faites un grand pas en arrière",
    "execution.2":
      "Descendez le buste jusqu'à ce que votre genou arrière touche légèrement le sol.",
    "execution.3":
      "Poussez sur votre pied arrière pour revenir à la position de départ. Changez de jambe.",
    "focus.1": "Gardez votre buste vertical.",
    "focus.2": "Votre genou avant ne doit pas dépasser vos orteils.",
    "focus.3": "",
    "repetitions.1": "12 lunges alternés",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fb75961e44ba484f6ab04": {
    "label": "Sit ups",
    "setup": "Assis, les deux pieds au sol, les bras croisés sur la poitrine.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amenez vos omoplates au sol",
    "execution.2": "Revenez à la position de départ ",
    "execution.3": "",
    "focus.1": "Gardez une position neutre de la tête",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fb76761e44ba484f6ab05": {
    "label": "Leg raises",
    "setup": "Allongé sur le dos, les genoux légèrement fléchis.",
    "setup.1": "Les talons décollés du sol",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Amener les deux jambes à la verticale",
    "execution.2": "Revenez à la position de départ ",
    "execution.3": "",
    "focus.1": "",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fb77161e44ba484f6ab06": {
    "label": "Good morning",
    "setup": "Tenez-vous debout, les pieds écartés sous vos hanches.",
    "setup.1": "Bras croisés sur la poitrine",
    "setup.2": "Genoux légèrement fléchis.",
    "setup.3": "",
    "execution.1":
      "Reculez les hanches en gardant une courbure lombaire neutre.",
    "execution.2":
      "Descendez jusqu'à ce que votre poitrine soit parallèle au sol",
    "execution.3": "Revenez à la position de départ ",
    "focus.1": "No rounding the lower back",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fd18261e44ba484f6ab08": {
    "label": "Burpee",
    "setup": "Tenez-vous debout, les pieds écartés sous vos hanches.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1":
      "Placez les mains sur le sol à la largeur des épaules, sautez en arrière en position de push-up.",
    "execution.2": "Amenez la poitrine et les cuisses au sol.",
    "execution.3":
      "Pousser, sauter pour ramener les pieds vers les mains et sauter en hauteur",
    "focus.1": "Gardez un rythme régulier",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 répétitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fd1d061e44ba484f6ab09": {
    "label": "Air Squat",
    "setup": "Tenez-vous bien droit, les pieds à la largeur des épaules.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Les hanches descendent vers l'arrière et vers le bas",
    "execution.2": "Ne pas arrondir le bas du dos",
    "execution.3": "Genou dans l'axe des orteils",
    "focus.1": "Gardez votre poids sur vos talons",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "12 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f3c2772d6692d69800007bb": {
    "label": "Runner squat",
    "setup": "Tenez-vous bien droit, les pieds à la largeur des épaules.",
    "setup.1": "Transférez votre poids vers l'avant du pied",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Les hanches descendent vers l'arrière et vers le bas",
    "execution.2": "Ne pas arrondir le bas du dos",
    "execution.3": "Genou dans l'axe des orteils",
    "focus.1": "Concentrez-vous sur le maintien de votre équilibre",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "12 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
}
export default fr_fr
