import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Header,
  Dimmer,
  Loader,
  Card,
  Icon,
  Segment,
  Image,
  Button,
  List,
} from 'semantic-ui-react'
import { getProtocol, logDrills, getSubscriptionStatus } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { isEmpty } from 'lodash'
import AppError from '../components/AppError'
import Workout from '../components/Workout'
import ReactGA from 'react-ga'

class UserProtocolDetails extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      protocol: {},
      includePrerun: true,
    }
  }
  async componentDidMount() {
    this.call_getProtocol()
    this.call_getSubscriptionStatus()
  }

  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  call_getProtocol = async () => {
    const protocolId = this.props.match.params.id
    let response
    //protocol already chosen display it
    response = await getProtocol(protocolId)
    let displayWorkout = this.props.location?.state?.displayWorkout

    if (response.ok && !isEmpty(response.data)) {
      let protocol = response.data
      // this.props.history.replace('/userProtocol/view/' + protocol.id)

      let {
        lightFlexibilityDrills,
        warmupDrills,
        strengthDrills,
        drills,
        flexibilityDrills,
        workout,
        selfCareDrills,
        suggestRunWarmup,
      } = protocol

      workout['_id'] = 'workout'
      workout.comments = protocol.comments

      let protocolDrills = // si le workout est vide on ne l'ajoute pas
        workout.content?.length !== 0
          ? [
              ...lightFlexibilityDrills,
              ...warmupDrills,
              ...strengthDrills,
              ...drills,
              workout,
              ...flexibilityDrills,
              ...selfCareDrills,
            ]
          : [
              ...lightFlexibilityDrills,
              ...warmupDrills,
              ...strengthDrills,
              ...drills,
              ...flexibilityDrills,
              ...selfCareDrills,
            ]

      this.setState({
        protocol,
        protocolDrills,
        protocolDrillsIdByType: {
          lightFlexibility: lightFlexibilityDrills.map((x) => x._id),
          warmup: warmupDrills.map((x) => x._id),
          strength: strengthDrills.map((x) => x._id),
          technique: drills.map((x) => x._id),
          workout: workout.content?.length !== 0 ? ['workout'] : [],
          flexibility: flexibilityDrills.map((x) => x._id),
          selfCare: selfCareDrills.map((x) => x._id),
        },
        protocolWorkout: workout,
        isLoading: false,
        hasError: false,
        suggestRunWarmup,
        includePrerun: suggestRunWarmup,
      })
      // store the new state object in the component's state

      // if no drill set as done immediatly //intro for example
      if (protocolDrills.length === 0) {
        await logDrills({ doneDrills: [], protocolId })
      }

      if (displayWorkout) {
        sessionStorage.removeItem('RWJWorkoutUrl')
        this.startProtocol()
      }

      if (protocolDrills.length === 1 && protocolDrills[0]._id === 'workout') {
        // this.startProtocol()
      }

      ReactGA.pageview(window.location.pathname + window.location.search)
      ReactGA.event({ category: 'Protocol ', action: protocol.label })
      return false
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  startProtocol() {
    ReactGA.event({ category: 'Protocol', action: 'Start Protocol' })
    this.props.history.replace({
      pathname: '/drills/' + this.state.protocolDrills[0]._id,
      state: {
        protocolId: this.props.match.params.id,
        drills: this.state.protocolDrills,
        drillsIdByType: this.state.protocolDrillsIdByType,
        trainingMode: true,
      },
    })
  }
  toggleInludePrerun = () =>
    this.setState((prevState) => ({ includePrerun: !prevState.includePrerun }))

  render() {
    const { polyglot, history } = this.props
    const {
      protocol,
      protocolDrills,
      protocolDrillsIdByType,
      protocolWorkout,
      includePrerun,
      suggestRunWarmup,
    } = this.state

    return (
      <Fragment>
        <Dimmer active={this.state.isLoading}>
          <Loader />
        </Dimmer>
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && (
          <div className="page ">
            <style>{`
				@media  (min-width: 768px)
				{
					.ui.relaxed.grid
					{
						margin-left: 0em!important;
						margin-right: 0!important;
					}
				}
				.segments{
					background: rgba(0, 0, 0, 0.6) !important;
					background: transparent !important;
				}
				.segments .segment {
					/*border-top: 1px solid #3a3939 !important;
					border-right: 1px solid #3a3939 !important;
					border-left: 1px solid #3a3939 !important;*/
					clear:both;
					height: 62px;
				}

				.segments .segment:after {
					border-bottom: 1px solid #1f1f1f;
					content: '';
					display: block;
					width: 75%;
					margin-left: auto;
					margin-right: auto;
				}

				.segments .segment .content-text
				{
					margin-left: 60px;
					height: 50px;
					display: flex;
					align-items: center;
				}


				.segments .segment .content-text
				{
					margin-left: 60px;
					height: 50px;
					display: flex;
					align-items: center;
				}

				.segments .segment:last-child 					{
					/*border-bottom: 1px solid #3a3939 !important;*/
				}
				.segment .image {
					margin-right:1em !important;
				}

				.page	.ui.avatar.image {
					width: 3.5em;
					height: 3.5em;
					margin-top: 0.2rem;
					margin-bottom: 0.2rem;
					float:left;
				}
				.page .ui.segment
				{
					padding:0.4em;
				}

				`}</style>
            <Header
              as="h2"
              className="color-white center-text uppercase  top-10"
            >
              <span>
                <div style={{ display: 'inline-block', marginLeft: 10 }}>
                  {protocol.id && polyglot.has(`${protocol.id}.label`)
                    ? polyglot.t(`${protocol.id}.label`)
                    : polyglot.has(protocol.label)
                    ? polyglot.t(protocol.label)
                    : protocol.label}
                </div>
              </span>
            </Header>
            {protocol.id &&
              polyglot.has(`${protocol.id}.text`) &&
              polyglot.t(`${protocol.id}.text`) && (
                <div
                  style={{ height: '100%', textAlign: 'left' }}
                  className="bottom-20"
                >
                  <div className="lesson-card-text">
                    {polyglot.t(`${protocol.id}.text`)}
                  </div>
                </div>
              )}
            <div className="next-class pad-bottom-10">
              {[
                {
                  key: 'lightFlexibility',
                  drills: protocol.lightFlexibilityDrills,
                },
                { key: 'warmup', drills: protocol.warmupDrills },
                {
                  key: 'strength',
                  drills: protocol.strengthDrills,
                },
                {
                  key: 'technique',
                  drills: protocol.drills,
                },
                {
                  key: 'workout',
                  workout: protocolWorkout,
                },
                {
                  key: 'cooldown',
                  drills: protocol.flexibilityDrills,
                },
              ].map((s) =>
                s.key === 'workout' && !!protocolWorkout?.content?.length ? (
                  <Card.Content className="bottom-20" key={s.key}>
                    {!protocolDrills.length !==
                      1 /* on ecrit le texte workout que si il y a les autres blocs de drills */ && (
                      <Card.Meta style={{ clear: 'both', marginBottom: 0 }}>
                        <h4 style={{ textTransform: 'capitalize' }}>
                          {polyglot.t('protocol.' + s.key)}
                        </h4>
                      </Card.Meta>
                    )}

                    <span
                      onClick={() =>
                        protocolDrills.length === 1
                          ? false
                          : this.props.history.push({
                              pathname: '/drills/workout',
                              state: {
                                drills: protocolDrills,
                                drillsIdByType: protocolDrillsIdByType,
                                learningMode: true,
                                protocolId: this.props.match.params.id,
                              },
                            })
                      }
                    >
                      <Workout
                        workout={s.workout}
                        protocolId={protocol.id}
                        location={this.props.location}
                        comments={protocol.comments}
                      />
                    </span>

                    {protocolDrills.length ===
                      1 /*il n' a que le workout */ && (
                      <>
                        <Segment
                          className="dark-grey services-card"
                          style={{ fontSize: '1.1em' }}
                        >
                          <List
                            divided
                            inverted
                            style={{ textAlign: 'left' }}
                            className="bottom-5"
                          >
                            {suggestRunWarmup && (
                              <List.Item onClick={this.toggleInludePrerun}>
                                <List.Content floated="right">
                                  <Icon
                                    inverted
                                    name={
                                      'toggle ' + (includePrerun ? 'on' : 'off')
                                    }
                                    color={includePrerun ? 'green' : 'grey'}
                                    size="large"
                                  />
                                </List.Content>
                                <List.Content
                                  style={{
                                    fontSize: '1em',
                                    color: 'white',
                                  }}
                                >
                                  {polyglot.t('protocol.include.prerun')}
                                </List.Content>
                              </List.Item>
                            )}
                          </List>
                        </Segment>
                        <Button
                          className="app-button top-10 bottom-10"
                          onClick={() =>
                            includePrerun
                              ? history.push({
                                  pathname: '/userProtocol',
                                  state: {
                                    workoutUrl: this.props.location.pathname,
                                  },
                                })
                              : history.push({
                                  pathname: '/drills/workout',
                                  state: {
                                    drills: protocolDrills,
                                    drillsIdByType: protocolDrillsIdByType,
                                    learningMode: true,
                                    protocolId: this.props.match.params.id,
                                  },
                                })
                          }
                        >
                          <span>
                            {includePrerun
                              ? polyglot.t('protocol.include.prerun.start')
                              : polyglot.t('protocol.start')}
                          </span>{' '}
                        </Button>
                      </>
                    )}
                  </Card.Content>
                ) : s.drills && s.drills.length > 0 ? (
                  <Card.Content className="bottom-20" key={s.key}>
                    <Card.Meta style={{ clear: 'both', marginBottom: -15 }}>
                      <h4 style={{ textTransform: 'capitalize' }}>
                        {polyglot.t('protocol.' + s.key)}
                      </h4>
                    </Card.Meta>
                    <Segment.Group id="segment-container">
                      {s.drills.map((drill) => (
                        <Segment
                          key={drill._id}
                          id={drill._id}
                          basic
                          textAlign="left"
                        >
                          <Link
                            to={{
                              pathname: '/drills/' + drill._id,
                              state: {
                                drills: protocolDrills,
                                drillsIdByType: protocolDrillsIdByType,
                                learningMode: true,
                                protocolId: this.props.match.params.id,
                              },
                            }}
                          >
                            <Icon
                              name="chevron right"
                              size="small"
                              className="floated right color-grey top-20"
                            />
                            {drill.image ? (
                              <Image src={'/drills/' + drill.image} avatar />
                            ) : (
                              <Image src={'/icons/icon-72x72.png'} avatar />
                            )}
                            <div className="content-text">{drill.name}</div>
                          </Link>
                        </Segment>
                      ))}
                    </Segment.Group>
                  </Card.Content>
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
UserProtocolDetails.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
UserProtocolDetails = withPolyglot()(UserProtocolDetails)
export default UserProtocolDetails
