export const round = (score) => Math.round(score)

export const getColor = (x) => {
  x = round(x)
  var color = '0, 255, 0' //'green light'

  if (x <= 20) {
    color = '250, 0, 28' //'red'
    return color
  }
  if (x <= 25) {
    color = '253, 30, 30'
    return color
  }
  if (x <= 30) {
    color = '252, 63, 34'
    return color
  }
  if (x <= 35) {
    color = '254, 92, 37'
    return color
  }
  if (x <= 40) {
    color = '254, 125, 39'
    return color
  }
  if (x <= 45) {
    color = '255, 156, 41'
    return color
  }
  if (x <= 50) {
    color = '255, 187, 44'
    return color
  }
  if (x <= 55) {
    color = '255, 218, 46'
    return color
  }
  if (x <= 60) {
    color = '243, 218, 40'
    return color
  }
  if (x <= 65) {
    color = '228, 217, 34'
    return color
  }
  if (x <= 70) {
    color = '215, 216, 27'
    return color
  }
  if (x <= 75) {
    color = '188, 216, 18'
    return color
  }
  if (x <= 80) {
    color = '173, 215, 12'
    return color
  }
  if (x <= 85) {
    color = '159, 216, 5'
    return color
  }
  if (x <= 90) {
    color = '143, 214, 1'
    return color
  }
  return color
}

export const frameCountColor = (count) => {
  var color

  if (count >= 7) {
    color = 'red'
  } else if (count >= 5) {
    color = 'orange'
  } else if (count >= 3) {
    color = 'yellow'
  } else {
    color = 'green'
  }
  return color
}

export const uselessFrameCountColor = (count) => {
  var color
  if (count >= 3) {
    color = 'red'
  } else if (count >= 2) {
    color = 'orange'
  } else if (count > 1) {
    color = 'yellow'
  } else {
    color = 'green'
  }
  return color
}

export const fallingFrameCountColor = (count) => {
  var color

  if (count === 2) {
    color = 'green'
  } else if (count < 2) {
    color = 'red'
  } else if (count > 2) {
    color = 'yellow'
  }
  return color
}
export const cadenceColor = (cadence) => {
  var color
  var count = cadence + 1

  if (count >= 178) {
    color = 'rgb(0,255,0)'
    return color
  } else if (count >= 175) {
    color = 'orange'
  } else if (count >= 170) {
    color = 'yellow'
  } else {
    color = 'red'
  }
  return color
}
