import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Sidebar, Icon, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withPolyglot } from '../vendor/polyglot-react'
import ReactGA from 'react-ga'
import { version } from '../version'

import { logout } from '../services/Api'
localStorage.getItem && (window.storage = localStorage)
if (window.storage) {
  ReactGA.set({
    dimension1: localStorage.RWJUserId,
    dimension2: localStorage.RWJUserName,
  })
}

class AppSidebar extends Component {
  render() {
    const { polyglot } = this.props
    return (
      <>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          onHide={this.props.handleSidebarHide}
          vertical
          visible={this.props.visible}
          width="thin"
          style={{ paddingTop: '30px', color: 'grey' }}
          data-testid="left-menu"
        >
          {/*
				<Menu.Item as="a" href="/" onClick={() => this.props.handleSidebarHide()}>
					<Icon name="home" />
					{polyglot.t("sidebar.home")}
				</Menu.Item>
		*
				<Menu.Item as={Link} to="/profile" onClick={() => this.props.handleSidebarHide()}>
					<Icon name="user" />
					{polyglot.t("sidebar.profile")}
				</Menu.Item>
				<Menu.Item as={Link} to="/userTrainings" onClick={() => this.props.handleSidebarHide()}>
					<Icon corner name="heartbeat" />
					{polyglot.t("sidebar.trainings")}
				</Menu.Item>
				 */}

          {localStorage.getItem('RWJRole') === 'coach' && (
            <Fragment>
              <Menu.Item
                as={Link}
                to="/analyses/todo"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="video play" />
                {polyglot.t('sidebar.analyses')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/protocolsPlans"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="star" />
                {polyglot.t('sidebar.protocols.plans')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/protocols"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="wizard" />
                {polyglot.t('sidebar.protocols')}
              </Menu.Item>

              <Menu.Item
                as={Link}
                to="/notification-center"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="bell" />
                {polyglot.t('sidebar.notification.center')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/classes"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="calendar alternate outline" />
                {polyglot.t('sidebar.courses')}
              </Menu.Item>

              <Menu.Item
                as={Link}
                to="/athletes"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="group" />
                {polyglot.t('sidebar.athletes')}
              </Menu.Item>
              {/*
              <Menu.Item
                as={Link}
                to="/faults"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="frown outline" />
                {polyglot.t('sidebar.faults')}
              </Menu.Item>


            <Menu.Item as='a' href="/clues"  >
              <Icon name='smile outline' />
              {polyglot.t('sidebar.clues')}
            </Menu.Item>
          */}

              <Menu.Item
                as={Link}
                to="/drills"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="wizard" />
                {polyglot.t('sidebar.drills')}
              </Menu.Item>
            </Fragment>
          )}
          {localStorage.getItem('RWJRole') === 'admin' && (
            <Fragment>
              <Menu.Item
                as={Link}
                to="/protocolsPlans"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="star" />
                {polyglot.t('sidebar.protocols.plans')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/protocols"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="wizard" />
                {polyglot.t('sidebar.protocols')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/drills"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="list ul" />
                {polyglot.t('sidebar.drills')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/drills"
                onClick={() => this.props.handleSidebarHide()}
              >
                <Icon name="frown outline" />
                {polyglot.t('sidebar.faults')}
              </Menu.Item>
            </Fragment>
          )}
          <Menu.Item
            as={Link}
            to="/settings"
            onClick={() => this.props.handleSidebarHide()}
          >
            <Icon name="cog" />
            {polyglot.t('account.general.settings')}
          </Menu.Item>

          <Menu.Item as={Link} to="/" onClick={() => logout()}>
            <Icon name="sign out" />
            {polyglot.t('account.sign.out')}
          </Menu.Item>
          {/**
          <Menu.Item
            as={Link}
            to="/services"
            onClick={() => this.props.handleSidebarHide()}
          >
            <Icon name="bullseye" />
            {polyglot.t('services.services')}
          </Menu.Item>
          <Menu.Item
            as={'a'}
            onClick={() => {
              ReactGA.event({ category: 'User', action: 'FAQ' })
              window.location = polyglot.t('sidebar.faq.url')
              this.props.handleSidebarHide()
            }}
          >
            <Icon name="question circle outline" />
            {polyglot.t('sidebar.faq')}
          </Menu.Item>
          <Menu.Item
            as={'a'}
            onClick={() => {
              ReactGA.event({ category: 'User', action: 'Help' })
              window.location = 'mailto:help@molokoy.io'
              this.props.handleSidebarHide()
            }}
          >
            <Icon name="life ring" />
            {polyglot.t('sidebar.help')}
          </Menu.Item>
          <Menu.Item
            as={'a'}
            onClick={() => {
              ReactGA.event({ category: 'User', action: 'Refresh' })
              window.location.reload(true)
            }}
          >
            <Icon name="refresh" />
            {polyglot.t('sidebar.update.check')}
          </Menu.Item>
		*/}
          <div
            style={{
              borderTop: '1px solid grey',
              padding: '10px',
              height: '48px',
              width: '100%',
              position: 'absolute',
              bottom: 0,
              fontSize: '0.7rem',
            }}
          >
            {version}
          </div>
        </Sidebar>
      </>
    )
  }
}
AppSidebar.propTypes = {
  visible: PropTypes.bool,
  handleSidebarHide: PropTypes.func,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

AppSidebar = withPolyglot()(AppSidebar)
export default AppSidebar
