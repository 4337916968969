import { Component } from 'react'
import PropTypes from 'prop-types'

import { Howl } from 'howler'

import click3SoundFileMP3 from './sounds/click3.mp3'
import click3SoundFileOGG from './sounds/click3.ogg'
import click3SoundFileAAC from './sounds/click3.aac'

import click2SoundFileMP3 from './sounds/click2.mp3'
import click2SoundFileOGG from './sounds/click2.ogg'
import click2SoundFileAAC from './sounds/click2.aac'

import click1SoundFileMP3 from './sounds/click1.mp3'
import click1SoundFileOGG from './sounds/click1.ogg'
import click1SoundFileAAC from './sounds/click1.aac'

class ProMetronome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qNote: 1,
      subNote: 1,
    }
  }

  clickSounds = [
    new Howl({
      src: [click1SoundFileMP3, click1SoundFileOGG, click1SoundFileAAC],
      preload: true,
    }),
    new Howl({
      src: [click2SoundFileMP3, click2SoundFileOGG, click2SoundFileAAC],
      preload: true,
    }),
    new Howl({
      src: [click3SoundFileMP3, click3SoundFileOGG, click3SoundFileAAC],
      preload: true,
    }),
  ]

  update = () => {
    const { soundEnabled, soundPattern, subdivision } = this.props
    const { qNote, subNote } = this.state

    if (soundEnabled && soundPattern.length === 4 * subdivision) {
      const soundLevel = soundPattern.charAt(
        (qNote - 1) * subdivision + subNote - 1
      )
      if (soundLevel > 0 && soundLevel <= 3)
        this.clickSounds[soundLevel - 1].play()
    }

    if (subNote < subdivision) {
      this.setState((prevState) => ({
        subNote: prevState.subNote + 1,
      }))
    } else {
      this.setState((prevState) => ({
        qNote: prevState.qNote === 4 ? 1 : prevState.qNote + 1,
        subNote: 1,
      }))
    }
  }

  calculateInterval = (bpm, subdivision) => {
    return Math.floor(60000 / (bpm * subdivision))
  }

  componentDidMount() {
    if (this.props.isPlaying) {
      this.timerID = setInterval(
        this.update,
        this.calculateInterval(this.props.bpm, this.props.subdivision)
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isPlaying !== this.props.isPlaying) {
      if (nextProps.isPlaying) {
        this.timerID = setInterval(
          this.update,
          this.calculateInterval(nextProps.bpm, nextProps.subdivision)
        )
      } else {
        clearInterval(this.timerID)
      }
    } else if (
      nextProps.isPlaying &&
      (nextProps.bpm !== this.props.bpm ||
        nextProps.subdivision !== this.props.subdivision)
    ) {
      clearInterval(this.timerID)
      this.timerID = setInterval(
        this.update,
        this.calculateInterval(nextProps.bpm, nextProps.subdivision)
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  render() {
    return this.props.render(this.props, this.state)
  }
}

ProMetronome.propTypes = {
  bpm: PropTypes.number,
  subdivision: PropTypes.number,
  isPlaying: PropTypes.bool,
  soundEnabled: PropTypes.bool,
  soundPattern: PropTypes.string,
  render: PropTypes.func,
}
ProMetronome.defaultProps = {
  bpm: 180,
  subdivision: 1,
  //isPlaying: true,
  soundEnabled: true,
  soundPattern: '',
}

export default ProMetronome
