import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form, Dimmer, Loader, Card, Icon, Checkbox } from 'semantic-ui-react'
import {
  getTrainingLogForCourse,
  createTrainingLog,
  updateTrainingLog,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import AppError from '../components/AppError'
import enUS from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
registerLocale('fr', fr)
registerLocale('en-US', enUS)
class TrainingLog extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      isEditing: false,
      comment: '',
      done: false,
      date: false,
      hasLog: false,
      logId: '',
    }
  }
  componentDidMount() {
    this.call_getTrainingLogForCourse()
  }
  call_getTrainingLogForCourse = async () => {
    let { trainingId } = this.props

    if (trainingId) {
      const response = await getTrainingLogForCourse(trainingId)
      if (response.ok) {
        const data = response.data
        if (data.count !== 0) {
          const log = data.rows[0]
          if (log) {
            const { comment, date, done, id } = log
            this.setState({
              comment,
              done,
              date,
              logId: id,
              hasLog: true,
              isEditing: comment.trim() === '' && !done,
              isLoading: false,
            })
          } else {
            this.setState({ isLoading: false })
          }
        } else {
          this.setState({ isLoading: false })
        }
      } else {
        // store the new state object in the component's state
        this.setState({
          isLoading: false,
          hasError: true,
          error: response,
        })
      }
    }
  }

  call_createOrUpdateTrainingLog = async () => {
    let { trainingId } = this.props
    let payload = {
      done: this.state.done,
      comment: this.state.comment,
      course: trainingId,
      date: this.state.date,
    }
    const response = this.state.isEditing
      ? await updateTrainingLog(this.state.logId, payload)
      : await createTrainingLog(payload)
    if (response.ok) {
      const data = response.data
      const log = data

      const { comment, date, done } = log
      let logId = log.id || log._id

      this.setState({
        comment,
        done,
        date,
        hasLog: true,
        logId,
        isLoading: false,
        isEditing: comment.trim() === '' && !done,
      })
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }
  onDatePickerChange = (date) => {
    if (date) {
      date = moment(date, 'DD-MM-YYYY')
    }
    this.setState({ date })
  }
  toggleDone = () => this.setState((prevState) => ({ done: !prevState.done }))
  toggleEdit = (e) => {
    e.preventDefault()
    this.setState({ isEditing: true })
  }

  render() {
    const { polyglot } = this.props

    return (
      <Fragment>
        <Dimmer active={this.state.isLoading}>
          <Loader />
        </Dimmer>
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && (
          <div className="training-log ">
            <style>{`
						  .react-datepicker__input-container input#date.inverted {
								/*border:none;*/
								background-color:transparent;
								color:#fff;
								padding:1px;
								padding-left:10px;
								width:100px
							}
							`}</style>
            {(!this.state.hasLog || this.state.isEditing) && (
              <Card fluid>
                <Card.Content className={'pad-top-5 pad-bottom-5'}>
                  <Card.Description>
                    <Form inverted>
                      <Form.Group>
                        <Form.Field>
                          <Checkbox
                            label={polyglot.t('traininglog.done.label')}
                            onChange={this.toggleDone}
                            checked={this.state.done}
                          />
                        </Form.Field>
                        {this.state.done && (
                          <Form.Field inline>
                            <label>
                              {' '}
                              {polyglot.t('traininglog.date.label')}
                            </label>
                            <DatePicker
                              id="date"
                              dateFormat="dd/MM/yyyy"
                              selected={
                                this.state.date
                                  ? moment(this.state.date).toDate()
                                  : ''
                              }
                              onChange={this.onDatePickerChange}
                              propName="date"
                              locale={polyglot.t('locale')}
                              className="inverted"
                            />
                          </Form.Field>
                        )}
                      </Form.Group>
                      <Form.Field className="top-10">
                        <label>
                          {' '}
                          {polyglot.t('traininglog.comments.label')}
                        </label>
                        <Form.TextArea
                          rows={5}
                          name="comment"
                          placeholder={polyglot.t(
                            'traininglog.comments.placeholder'
                          )}
                          onChange={this.handleChange}
                          value={this.state.comment}
                        />
                      </Form.Field>
                      <div style={{ textAlign: 'center' }}>
                        <Icon
                          link
                          name="save outline"
                          size="big"
                          inverted
                          onClick={this.call_createOrUpdateTrainingLog}
                        />
                      </div>
                    </Form>
                  </Card.Description>
                </Card.Content>
              </Card>
            )}
            {!this.state.isEditing && this.state.hasLog && (
              <Card fluid>
                <a
                  href="/"
                  onClick={this.toggleEdit}
                  style={{ position: 'absolute', top: '2px', right: '5px' }}
                >
                  <Icon name="edit outline" size="large" />
                </a>
                <Card.Content className={'pad-top-5 pad-bottom-5'}>
                  <Card.Description>
                    {this.state.done && (
                      <Fragment>
                        {polyglot.t('traininglog.done.label')}{' '}
                        {polyglot.t('traininglog.date.label')}{' '}
                        {moment(this.state.date).format('ddd D MMM YYYY')}
                      </Fragment>
                    )}
                    {!this.state.done && (
                      <Fragment>
                        {polyglot.t('traininglog.done.not.label')}
                      </Fragment>
                    )}
                  </Card.Description>
                  <Card.Description className={'pad-top-5 pad-bottom-5'}>
                    <h5>{polyglot.t('traininglog.comments.label')}</h5>
                    {this.state.comment}
                  </Card.Description>
                </Card.Content>
              </Card>
            )}
          </div>
        )}
      </Fragment>
    )
  }
}

TrainingLog.propTypes = {
  polyglot: PropTypes.object,
  trainingId: PropTypes.string,
}
TrainingLog = withPolyglot()(TrainingLog)
export default TrainingLog
