import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { withPolyglot } from '../vendor/polyglot-react'
import { getNextClass } from '../services/Api'
import { Config } from '../config'
import { Icon, Label, Transition } from 'semantic-ui-react'

class HomeButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  async componentDidMount() {
    const myNextClass = await this.call_getNextClass()
    let isOpen = false

    this.setState({
      isOpen,
      myNextClass,
      hasError: false,
      isLoading: false,
      visible: true,
    })
  }

  async call_getNextClass() {
    var response = await getNextClass()
    if (response.ok && response.data) {
      var myNextClasses = response.data.rows
      myNextClasses = myNextClasses.filter(function (o) {
        if (!o.trainingLog || o.trainingLog.done === false) {
          return true
        }
        return false
      })
      // store the new state object in the component's state
      return myNextClasses[0]
    } else {
      return false
    }
  }
  buttonsConfig = () => [
    {
      key: 'todayTraining',
      icon: 'heartbeat',
      url: '/todayTraining/' + this.state.myNextClass?.id,
      disabled: !this.state.myNextClass,
      textKey: 'training.next',
      additionalContent: this.state.myNextClass && (
        <Label color="grey" circular floating>
          {' '}
          <Icon name="exclamation" />{' '}
        </Label>
      ),
    },
    {
      key: 'userTrainings',
      icon: 'calendar alternate outline',
      url: '/userTrainings',
      textKey: 'training.sessions',
    },
    {
      key: 'userPerfs',
      icon: 'stopwatch',
      url: '/userPerfs',
      textKey: 'times',
    },
    {
      key: 'bookingUrl',
      icon: 'calendar plus',
      url: Config.bookingUrl,
      isExternalUrl: true,
      textKey: 'book',
    },
  ]

  toggleOpen = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { polyglot, homeButtons } = this.props
    const { isOpen } = this.state
    return (
      <Fragment>
        <style>{`


			`}</style>
        <Transition visible={isOpen} animation="scale" duration={500}>
          <div className="call-to-action" style={{ margin: 0 }}>
            {this.buttonsConfig()?.map((button) =>
              homeButtons.includes(button.key) ? (
                <span
                  key={button.key}
                  className={'action ' + (button.disabled && 'disabled')}
                  {...(!button.disabled && {
                    onClick: () =>
                      button.isExternalUrl
                        ? (window.location.href = button.url)
                        : this.props.history.push(button.url),
                  })}
                >
                  <Icon name={button.icon} />
                  {button.additionalContent}
                  <em>{polyglot.t(`home.action.${button.textKey}`)} </em>
                </span>
              ) : (
                <></>
              )
            )}
          </div>
        </Transition>
        <div style={{ textAlign: 'center', position: 'relative' }}>
          {this.state.myNextClass && !isOpen && (
            <Label color="grey" circular floating>
              <Icon name="exclamation" style={{ margin: 0 }} />{' '}
            </Label>
          )}

          <Icon
            name={'chevron ' + (isOpen ? 'up' : 'down')}
            size="large"
            onClick={this.toggleOpen}
          />
        </div>
      </Fragment>
    )
  }
}

HomeButtons.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  homeButtons: PropTypes.array,
}

HomeButtons = withPolyglot()(HomeButtons)
export default HomeButtons
