import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Grid, Segment, Message, Header } from 'semantic-ui-react'
import { signup } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import ReactGA from 'react-ga'
import GaugeCircular from '../components/GaugeCircular'
const white = 'rgb(200, 200, 200)'
class RuffierTest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hr1: '',
      hr2: '',
      hr3: '',
      hasError: false,
      error: false,
      score: null,
    }
  }

  toggle = () => this.setState((prevState) => ({ checked: !prevState.checked }))

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  async call_signup() {
    const { polyglot } = this.props
    let canSubmit = this.state.canSubmit
    const { firstName, lastName, email, password, checked, locale, country } =
      this.state

    if (country) {
      this.props.history.push('/login')
      return
    }

    if (!lastName || !firstName || !email || !password) {
      toast.error(polyglot.t('signup.message.fields.mandatory'))
      return
    }
    if (!canSubmit) {
      return
    }
    if (!checked) {
      toast.error(polyglot.t('signup.message.fields.terms'))
      return
    }

    const response = await signup({
      lastName,
      firstName,
      email,
      password,
      locale,
    })
    if (response.ok) {
      ReactGA.event({ category: 'Register', action: 'Register success' })
      localStorage.RWJToken = response.data.token
      localStorage.RWJRole = response.data.user.role
      localStorage.RWJUserId = response.data.user.id
      localStorage.RWJUserIsActive = response.data.user.isActive
      const newState = Object.assign({}, this.state, {
        hasError: false,
        redirectToReferrer: true,
      })
      this.props.forceNotificationUpdate()
      this.setState(newState)
    } else {
      var data = response.data
      var err_param = response.data.param
      var err_name = response.data.name
      if (err_param === 'email' && err_name === 'match') {
        data.message = 'signup.message.fields.email.wrong'
      } else if (err_param === 'email' && !err_name) {
        data.message = 'signup.message.fields.email.exists'
      } else if (err_param === 'password' && err_name === 'minlength') {
        data.message = 'signup.message.fields.password.short'
      }
      const newState = Object.assign({}, this.state, {
        hasError: true,
        error: response,
      })
      toast.error(polyglot.t(data.message))
      this.setState(newState)
    }
  }
  compute = () => {
    const { hr1, hr2, hr3 } = this.state
    let score = null

    if (hr1 && hr2 && hr3) {
      score = (~~hr1 + ~~hr2 + ~~hr3 - 200) / 10
    }

    this.setState({ score })
  }
  reset = () => {
    let score = null
    this.setState({ score })
  }

  handleValue(e, key) {
    this.setState({ ...this.state, [key]: e.target.value })
  }

  render() {
    var { polyglot } = this.props
    var { score } = this.state

    return (
      <div className="ruffier-form">
        <style>{`
		.ruffier-form .ui.form .field {
		margin: 0 auto;
	}
	`}</style>
        <Header as="h2" className="color-white center-text uppercase  top-10">
          {polyglot.t('ruffier.title')}
        </Header>

        {score !== null && (
          <Grid textAlign="center">
            <Grid.Column style={{ maxWidth: 450, paddingTop: 50 }}>
              <GaugeCircular
                getGaugeValue={getGaugeValue}
                getColor={getColor}
                score={score}
                totalImprovement={false}
                totalImprovementVisible={false}
                improvementColor={white}
              />
              <div
                className="top-5 bottom-20"
                style={{ color: '#fff', fontSize: '1.2em' }}
              >
                {polyglot.t('ruffier.score.label.' + getGaugeValue(score))}
              </div>
              <Button
                className="app-button top-10"
                fluid
                size="large"
                onClick={() => this.reset()}
              >
                {polyglot.t('ruffier.reset')}
              </Button>
            </Grid.Column>
          </Grid>
        )}
        <Grid
          textAlign="center"
          style={{ height: '100%' }}
          verticalAlign="middle"
        >
          {score === null && (
            <Grid.Column style={{ maxWidth: 450, paddingTop: 50 }}>
              <Form size="large">
                {['1', '2', '3'].map((fieldIndex) => (
                  <Segment inverted key={fieldIndex}>
                    <div>
                      {polyglot.t(
                        'ruffier.heart.rate.instructions.' + fieldIndex
                      )}
                    </div>
                    <Form.Input
                      className="top-15"
                      style={{ margin: '0 auto' }}
                      width={8}
                      icon="heartbeat"
                      iconPosition="left"
                      placeholder={polyglot.t(
                        'ruffier.heart.rate.' + fieldIndex
                      )}
                      onChange={(e) => this.handleValue(e, 'hr' + fieldIndex)}
                      value={this.state['hr' + fieldIndex]}
                    />
                  </Segment>
                ))}

                <Button
                  className="app-button top-10"
                  fluid
                  size="large"
                  onClick={() => this.compute()}
                >
                  {polyglot.t('ruffier.compute')}
                </Button>
              </Form>
            </Grid.Column>
          )}
        </Grid>
        {this.state.hasError && (
          <div>
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p> {this.state.error.data.message}</p>
            </Message>
          </div>
        )}
      </div>
    )
  }
}

RuffierTest.propTypes = {
  polyglot: PropTypes.object,
  location: PropTypes.object,
  forceNotificationUpdate: PropTypes.func,
}

RuffierTest = withPolyglot()(RuffierTest)
export default RuffierTest

function getGaugeValue(score) {
  score = Math.round(score)

  var x = 0

  if (score >= 15) {
    x = 5
    return x
  }

  if (score >= 10) {
    x = 25
    return x
  }
  if (score >= 5) {
    x = 50
    return x
  }
  if (score >= 0) {
    x = 75
    return x
  }
  if (score < 0) {
    x = 100
    return x
  }
}

function getColor(score) {
  var x = getGaugeValue(score)

  let color = '0, 255, 0' //'green light'
  if (x <= 20) {
    color = '250, 0, 28' //'red'
    return color
  }
  if (x <= 25) {
    color = '253, 30, 30'
    return color
  }
  if (x <= 30) {
    color = '252, 63, 34'
    return color
  }
  if (x <= 35) {
    color = '254, 92, 37'
    return color
  }
  if (x <= 40) {
    color = '254, 125, 39'
    return color
  }
  if (x <= 45) {
    color = '255, 156, 41'
    return color
  }
  if (x <= 50) {
    color = '255, 187, 44'
    return color
  }
  if (x <= 55) {
    color = '255, 218, 46'
    return color
  }
  if (x <= 60) {
    color = '243, 218, 40'
    return color
  }
  if (x <= 65) {
    color = '228, 217, 34'
    return color
  }
  if (x <= 70) {
    color = '215, 216, 27'
    return color
  }
  if (x <= 75) {
    color = '188, 216, 18'
    return color
  }
  if (x <= 80) {
    color = '173, 215, 12'
    return color
  }
  if (x <= 85) {
    color = '159, 216, 5'
    return color
  }
  if (x <= 90) {
    color = '143, 214, 1'
    return color
  }

  return color
}
