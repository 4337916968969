import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Container,
  Transition,
  Segment,
  Icon,
  Grid,
  List,
  Label,
  Dropdown,
} from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import PaymentButton from '../components/PaymentButton'
import { getPricesAndCurrency, getSubscriptionStatus } from '../services/Api'
import ReactGA from 'react-ga'

class Services extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      selectedSubscription: '',
      analysisPack: null,
      services: null,
      subscriptionStatus: {},
      currency: 'usd',
    }
    this.downRef = React.createRef()
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)

    const [subscriptionStatus, PricesAndCurrency] = await Promise.all([
      this.call_getSubscriptionStatus(),
      this.call_getPricesAndCurrency(),
    ])
    const stripePrices = PricesAndCurrency.prices

    const prices = this.setPricesData(stripePrices)

    let { currency, canChangeCurrency } = PricesAndCurrency
    this.setState({
      prices,
      subscriptionStatus,
      selectedSubscription: !subscriptionStatus.subscription ? 'biannual' : '',
      currency,
      canChangeCurrency,
      isLoading: false,
    })
  }

  setPricesData = (stripePrices) => {
    const monthly = stripePrices.find((x) => x.nickname === 'monthly')
    const biannual = stripePrices.find((x) => x.nickname === 'biannual')
    const analysis_1 = stripePrices.find((x) => x.nickname === 'analysis_1')
    const analysis_2 = stripePrices.find((x) => x.nickname === 'analysis_2')
    const analysis_4 = stripePrices.find((x) => x.nickname === 'analysis_4')
    const prices = { monthly, biannual, analysis_1, analysis_2, analysis_4 }
    return prices
  }

  call_getPricesAndCurrency = async (currency) => {
    const response = await getPricesAndCurrency(currency)
    if (response.ok) {
      return response.data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  call_getSubscriptionStatus = async () => {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data
      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  selectSubscription = (selectedSubscription) => {
    if (this.state.selectedSubscription === selectedSubscription) {
      selectedSubscription = null
    }
    this.setState({ selectedSubscription })
  }
  selectAnalysisPack = (analysisPack) => {
    if (this.state.analysisPack === analysisPack) {
      analysisPack = null
    }
    this.setState({ analysisPack })
  }

  handleStripeError = () => {
    this.setState({ hasError: true })
  }

  arrayOfSelectedStripePrices = () => {
    let result = []
    let { prices, selectedSubscription, analysisPack } = this.state
    if (selectedSubscription) result = [...result, prices[selectedSubscription]]
    if (analysisPack) result = [...result, prices[analysisPack]]

    return result
  }

  handleChangeCurrency = async (e, { value }) => {
    const PricesAndCurrency = await this.call_getPricesAndCurrency(value)
    const stripePrices = PricesAndCurrency.prices
    const prices = this.setPricesData(stripePrices)
    let { currency, canChangeCurrency } = PricesAndCurrency
    this.setState({
      prices,
      currency,
      canChangeCurrency,
      isLoading: false,
    })
  }

  render() {
    const { polyglot } = this.props
    const {
      prices,
      currency,
      selectedSubscription,
      analysisPack,
      subscriptionStatus,
      canChangeCurrency,
    } = this.state
    const { hasValidSubscription, subscription } = subscriptionStatus

    return (
      <div className="page ">
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && !this.state.isLoading && (
          <Transition
            text
            visible={this.state.visible}
            animation="scale"
            duration={100}
          >
            <div className="services page">
              <style>{`
		.ui.dimmer{
			background-color:#000;
		}
		.ui.input.coupon-input>input:active,
		.ui.input.coupon-input>input
		{
			background-color:transparent;
				border: 1px solid grey;
				color:grey;
				text-transform:uppercase;
				text-align:center;
		}
		i.circular.icon.lineHeight08
		{
			line-height:0.8!important;
		}

		.ui.small.label.discount{
		padding: .4em .33em .3em .33em;
		font-size:0.8em;
		min-height: 1.7em;
		position: absolute;
		top: 0px;
		right: 0px;
		background:transparent!important;
		color:#fff !important;
		border-width:2px;

		}
		.custom-responsive-991{
			@media only screen and (min-width: 992px) and (max-width: 1199px)
		{

			padding-left: 1 em!important;
			padding-right: 1 em!important;
		}

		}

		`}</style>
              <Container style={{ height: '100%' }}>
                <Segment inverted className="dark-grey">
                  <Header>{polyglot.t('services.promise')}</Header>
                  <div></div>
                  <List inverted style={{ textAlign: 'left' }}>
                    {[1, 2, 3].map((a) => {
                      const key = 'services.promise.content.' + a
                      if (polyglot.has(key)) {
                        return (
                          <List.Item
                            key={key}
                            style={{ marginBottom: '0.4em' }}
                          >
                            <Icon
                              name="check"
                              size={'large'}
                              style={{ verticalAlign: 'middle' }}
                            />
                            <List.Content style={{ fontSize: '1.0em' }}>
                              {polyglot.t(key)}
                            </List.Content>
                          </List.Item>
                        )
                      } else return ''
                    })}
                  </List>
                </Segment>

                <Header
                  sub
                  data-testid={
                    subscription ? 'current-subscription-title' : 'choose-plan'
                  }
                >
                  {subscription
                    ? polyglot.t('services.current.plan')
                    : polyglot.t('services.choose.plan')}
                </Header>
                <div style={{ position: 'relative', marginBottom: '1em' }}>
                  {canChangeCurrency && (
                    <Dropdown
                      className="language-selector"
                      value={currency}
                      compact
                      options={['eur', 'usd'].map((cur) => ({
                        text: cur.toUpperCase(),
                        value: cur,
                        key: cur,
                      }))}
                      style={{ position: 'absolute', right: '1em', top: 0 }}
                      onChange={this.handleChangeCurrency}
                    />
                  )}
                </div>
                <Grid
                  columns={3}
                  textAlign="center"
                  className="top-10 bottom-10"
                >
                  <Grid.Row>
                    {[{ key: 'biannual' }, { key: 'monthly' }].map((e) => (
                      <Grid.Column
                        key={e.key}
                        width={7}
                        color={
                          selectedSubscription === e.key ||
                          subscription === e.key
                            ? 'blue'
                            : 'black'
                        }
                        className={
                          subscription && subscription !== e.key ? 'hidden' : ''
                        }
                        style={{
                          paddingLeft: '0.2em',
                          paddingRight: '0.2em',
                          borderRadius: '0.3rem',
                          border:
                            selectedSubscription === e.key
                              ? '1px solid #2185d0'
                              : '1px solid grey',
                          margin: '0 auto',
                        }}
                        onClick={() =>
                          hasValidSubscription
                            ? null
                            : this.selectSubscription(e.key)
                        }
                        data-testid={'premium-' + e.key}
                      >
                        <div
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '1em',
                          }}
                        >
                          {polyglot.t('services.premium.' + e.key)}
                        </div>
                        {currency === 'usd' && '$'}{' '}
                        <span style={{ fontSize: '1.3em', fontWeight: 'bold' }}>
                          {e.key === 'biannual'
                            ? (prices[e.key].unit_amount / 100 / 6).toFixed(2) -
                              0.01
                            : prices[e.key].unit_amount / 100}
                        </span>
                        {currency === 'eur' && '€'}
                        <span> /{polyglot.t('services.per.month')}</span>
                        {e.key === 'biannual' && (
                          <List style={{ fontSize: '1em' }}>
                            <List.Item>
                              <Icon
                                circular
                                inverted
                                color={
                                  selectedSubscription === e.key ||
                                  subscription === e.key
                                    ? 'blue'
                                    : 'black'
                                }
                                size="small"
                                name="chart bar"
                                className="lineHeight08"
                                style={{
                                  verticalAlign: 'baseline',
                                  marginRight: 5,
                                  border: '2px solid white',
                                }}
                              />
                              {polyglot.t('services.premium.analysis.included')}
                            </List.Item>
                          </List>
                        )}
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
                <Header sub>{polyglot.t('services.choose.analyses')}</Header>
                <div
                  style={{
                    fontSize: '0.9em',
                    color: 'grey',
                    lineHeight: 1,
                    textAlign: 'left',
                    marginTop: 10,
                    //whiteSpace: 'pre-line',
                  }}
                >
                  {polyglot.t('services.analyses.explanation')}
                </div>
                <Grid
                  columns={5}
                  textAlign="center"
                  className="top-5 custom-responsive-991"
                >
                  <Grid.Row>
                    {[{ key: '1' }, { key: '2' }, { key: '4' }].map((e) => (
                      <Grid.Column
                        key={e.key}
                        width={5}
                        color={'black'}
                        style={{
                          paddingTop: '2.0em',
                          paddingBottom: '1em',
                          paddingLeft: '0.2em',
                          paddingRight: '0.2em',
                          borderRadius: '0.3rem',
                          margin: '0 auto',
                          borderBottom:
                            analysisPack === 'analysis_' + e.key
                              ? '3px solid #2185d0'
                              : '3px solid transparent',
                        }}
                        onClick={() =>
                          this.selectAnalysisPack('analysis_' + e.key)
                        }
                        data-testid={'analysis_' + e.key}
                      >
                        {[2, 4].includes(~~e.key) && (
                          <Label
                            color="blue"
                            basic
                            size="small"
                            className="discount"
                          >
                            {~~e.key === 2 ? '-15%' : '-30%'}
                          </Label>
                        )}
                        {[...Array(~~e.key)].map((x, i) => (
                          <Icon
                            key={i}
                            circular
                            size="tiny"
                            inverted
                            color={
                              analysisPack === 'analysis_' + e.key
                                ? 'blue'
                                : 'black'
                            }
                            style={{
                              border: '1px solid white',
                            }}
                            className="lineHeight08"
                            name="chart bar"
                          />
                        ))}
                        <div
                          style={{
                            textTransform: 'uppercase',
                            fontSize: '0.9em',
                          }}
                        >
                          {polyglot.t('services.analysis_' + e.key)}
                        </div>
                        {currency === 'usd' && '$'}{' '}
                        <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                          {prices['analysis_' + e.key].unit_amount / 100}
                        </span>
                        {currency === 'eur' && '€'}
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>

                <div style={{ textAlign: 'center', marginTop: 30 }}>
                  {[prices.monthly].map((x) => (
                    <PaymentButton
                      key={x.id}
                      {...this.props}
                      handleError={this.handleStripeError}
                      buttonText={polyglot.t('services.shop')}
                      buttonTextConfirm={polyglot.t('services.checkout')}
                      className="fullwidth green"
                      prices={this.arrayOfSelectedStripePrices()}
                    />
                  ))}
                </div>
              </Container>
            </div>
          </Transition>
        )}
        <div style={{ marginTop: '100px' }} ref={this.downRef} />
        <div
          ref={(el) => {
            this.el = el
          }}
        />
      </div>
    )
  }
}

Services.propTypes = {
  history: PropTypes.object,
  polyglot: PropTypes.object,
}

Services = withPolyglot()(Services)
export default Services
