import React, { Component } from 'react'
import { Button, Dropdown, List } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { updateShoePrescription } from '../services/Api'

class ShoeEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shoesOptions: [],
    }
  }

  componentDidMount = () => {
    let { previousShoesList, shoePrescription } = this.props
    let shoesOptions = previousShoesList?.map((shoe) => ({
      value: shoe,
      text: shoe,
    }))
    let currentShoe = shoePrescription.shoe

    this.setState({ shoesOptions, currentShoe })
  }

  handleChange = (e, { value }) => this.setState({ currentShoe: value })
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      shoesOptions: [{ text: value, value }, ...prevState.shoesOptions],
    }))
  }

  save = async () => {
    const { currentShoe } = this.state
    const { shoePrescription, afterSave } = this.props
    const { user, creator } = shoePrescription

    await updateShoePrescription(shoePrescription.id, {
      ...shoePrescription,
      shoe: currentShoe,
      user: user._id,
      //    vendor: vendor._id,
      creator: creator._id,
    })

    afterSave(shoePrescription.id)
  }

  render() {
    const { currentShoe, shoesOptions } = this.state

    return (
      <>
        <List.Header
          style={{
            textTransform: 'lowercase',
          }}
        >
          <Dropdown
            fluid
            search
            selection
            upward
            allowAdditions
            options={shoesOptions}
            value={currentShoe}
            onAddItem={this.handleAddition}
            onChange={this.handleChange}
          />
        </List.Header>
        <div className="top-10" style={{ textAlign: 'center' }}>
          <Button
            circular
            icon="save"
            className="app-button"
            onClick={this.save}
          />
        </div>
      </>
    )
  }
}

ShoeEdit.propTypes = {
  previousShoesList: PropTypes.array,
  shoePrescription: PropTypes.object,
  afterSave: PropTypes.func,
}
export default ShoeEdit
