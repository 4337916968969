import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Header, List, Segment } from 'semantic-ui-react'
import { getSubscriptionStatus } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import moment from 'moment'
import 'moment/locale/fr'
import ReactGA from 'react-ga'
import AppError from '../components/AppError'

class UserServices extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      visible: false,
    }
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const subscriptionStatus = await this.call_getSubscriptionStatus()
    this.setState({ subscriptionStatus, isLoading: false })
  }

  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data
      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  handleOpenLanguage = () => this.setState({ modalLanguageOpen: true })
  handleCloseLanguage = () => this.setState({ modalLanguageOpen: false })

  render() {
    const { polyglot } = this.props
    const { subscriptionStatus } = this.state
    return (
      <div className="page account">
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && !this.state.isLoading && (
          <Container className="main-container">
            <style>{`
							.ui.list>.item>.icon+.content, .ui.list>.item>.image+.content {
								display: table-cell;
								width: 100%;
								padding: 0 0 0 .5em;
								vertical-align: middle;
							}
								.ui.modal .scrolling.content {
									max-height: calc(90vh);
									overflow: auto;
							}

						`}</style>

            {!subscriptionStatus.allServices.length &&
              ((
                <Header
                  as="h2"
                  className="color-white center-text uppercase  top-00"
                >
                  {polyglot.t('userServices.page.title')}
                </Header>
              ),
              (
                <Segment
                  className="dark-grey services-card top-30"
                  style={{ fontSize: '1.2em' }}
                >
                  <span
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    {polyglot.t('userServices.none')}
                  </span>

                  <div
                    className={'call-to-buy fullwidth top-20'}
                    onClick={() => this.props.history.push('/services')}
                  >
                    <span className={'action '}>
                      <span className="button-text">
                        {polyglot.t('userServices.buy')}
                      </span>
                    </span>
                  </div>
                </Segment>
              ))}
            {!!subscriptionStatus.servicesActive.length && (
              <Fragment>
                <Header
                  as="h2"
                  className="color-white center-text uppercase  top-00"
                >
                  {polyglot.t('userServices.active.services')}
                </Header>

                {subscriptionStatus.servicesActive.map((x) => (
                  <Segment
                    key={x._id}
                    className="dark-grey services-card top-30"
                    style={{ fontSize: '1.2em' }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        color: ' #e6000e',
                      }}
                    >
                      {polyglot.t('services.' + x.service.name)}
                    </span>

                    <List
                      divided
                      inverted
                      style={{ textAlign: 'left' }}
                      className="bottom-5"
                    >
                      <List.Item>
                        <List.Content
                          floated="right"
                          style={
                            x.analysesAvailable > 0 ? { color: '#07bc0c' } : {}
                          }
                        >
                          {x.analysesAvailable}
                          <span
                            style={{ fontSize: '0.8em', marginLeft: '0.2em' }}
                          >
                            {x.analysesAvailable <= 1
                              ? polyglot.t(
                                  'userServices.available.analyses.left'
                                )
                              : polyglot.t(
                                  'userServices.available.analyses.lefts'
                                )}
                          </span>
                        </List.Content>
                        <List.Content>
                          <List.Description>
                            {polyglot.t('userServices.available.analyses')}
                          </List.Description>
                        </List.Content>
                      </List.Item>

                      {!!x.analyses.length && (
                        <List.Item>
                          <List.Content floated="right">
                            <List bulleted>
                              {x.analyses.map((a) => (
                                <List.Item
                                  key={a._id}
                                  className="pad-bottom-5 pad-top-0 "
                                >
                                  {moment(a.createdAt).format('LL')}
                                </List.Item>
                              ))}
                            </List>
                          </List.Content>
                          <List.Content>
                            <List.Description>
                              {polyglot.t('userServices.used.analysis')}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      )}
                      <List.Item>
                        <List.Content floated="right">
                          {' '}
                          {moment(x.createdAt).format('LL')}{' '}
                        </List.Content>
                        <List.Content>
                          <List.Description>
                            {polyglot.t('userServices.since')}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content
                          floated="right"
                          style={
                            moment().isBefore(x.endDate)
                              ? { color: '#07bc0c' }
                              : {}
                          }
                        >
                          {moment(x.endDate).format('LL')}{' '}
                        </List.Content>
                        <List.Content>
                          <List.Description>
                            {polyglot.t('userServices.until')}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Segment>
                ))}
              </Fragment>
            )}

            {!!subscriptionStatus.servicesInactive.length && (
              <Fragment>
                <Header
                  as="h2"
                  className="color-white center-text uppercase  top-00"
                >
                  {polyglot.t('userServices.inactive.services')}
                </Header>

                {subscriptionStatus.servicesInactive.map((x) => (
                  <Segment
                    key={x._id}
                    className="dark-grey services-card top-30"
                    style={{ fontSize: '1.2em' }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}
                    >
                      {polyglot.t('services.' + x.service.name)}
                    </span>

                    <List
                      divided
                      inverted
                      style={{ textAlign: 'left' }}
                      className="bottom-5"
                    >
                      <List.Item>
                        <List.Content floated="right">
                          {x.analysesAvailable}{' '}
                          <span
                            style={{ fontSize: '0.8em', marginLeft: '0.2em' }}
                          >
                            {x.analysesAvailable <= 1
                              ? polyglot.t(
                                  'userServices.available.analyses.left'
                                )
                              : polyglot.t(
                                  'userServices.available.analyses.lefts'
                                )}
                          </span>
                        </List.Content>
                        <List.Content>
                          <List.Description>
                            {polyglot.t('userServices.available.analyses')}
                          </List.Description>
                        </List.Content>
                      </List.Item>

                      {!!x.analyses.length && (
                        <List.Item>
                          <List.Content floated="right">
                            <List bulleted>
                              {x.analyses.map((a) => (
                                <List.Item
                                  key={a._id}
                                  className="pad-bottom-5 pad-top-0 "
                                >
                                  {moment(a.createdAt).format('LL')}
                                </List.Item>
                              ))}
                            </List>
                          </List.Content>
                          <List.Content>
                            <List.Description>
                              {polyglot.t('userServices.used.analysis')}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      )}
                      <List.Item>
                        <List.Content floated="right">
                          {' '}
                          {moment(x.createdAt).format('LL')}{' '}
                        </List.Content>
                        <List.Content>
                          <List.Description>
                            {polyglot.t('userServices.since')}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          {moment(x.endDate).format('LL')}{' '}
                        </List.Content>
                        <List.Content>
                          <List.Description>
                            {polyglot.t('userServices.until')}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Segment>
                ))}
              </Fragment>
            )}
          </Container>
        )}
      </div>
    )
  }
}
UserServices.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

UserServices = withPolyglot()(UserServices)
export default UserServices
