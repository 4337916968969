import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Link, NavLink } from 'react-router-dom'

// This component ios HoC that prevents the user from accessing a route if he's not logged in

import { Menu, Image, Icon, Transition } from 'semantic-ui-react'

class MenuBottom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      forceNotificationUpdate: false,
      newVersionAvailable: false,
      waitingWorker: {},
      statsGlow: true,
      mainGlow: true,
      settingsGlow: true,
    }
  }
  render() {
    const { statsGlow, mainGlow, settingsGlow } = this.state

    var additionnalSettingsRoutes = [
      '/profile',
      '/passwordChange',
      '/userServices',
      '/settings',
      '/paceConverter',
      '/metronome',
      '/services',
      '/ruffier',
    ]

    return (
      <>
        <Route
          path={[
            '/login',
            '/signup',
            '/forgot',
            // chemins pour lesquels on doit pouvoir revenir en arriere
          ]}
          render={() => (
            <style>
              {`
				.main-menu.bottom{display:none}
			`}
            </style>
          )}
        />

        <Menu fixed="bottom" inverted className="main-menu bottom">
          <style>
            {`
		.ui.menu.fixed .menu-button.item:first-child,
		 .ui.menu.fixed .menu-button.item:last-child
		 {
			border-radius: 50px!important;
			padding: 8px;
			border-bottom: none !important;

			background-image: linear-gradient(
				to bottom,
				#1f1f1f 0%,
				#101010 100%
				 ) !important

			}
		.menu-button i.large.icon{
			font-size:1.3em;
			color:#7d7d7d!important;
		}


				`}
          </style>
          <div className="item-container">
            <Route
              render={(props) => {
                return (
                  <Transition animation={'pulse'} visible={statsGlow}>
                    <Menu.Item
                      as={NavLink}
                      to="/userAnalyses"
                      style={{
                        marginLeft: -10,
                      }}
                      className={
                        'menu-button' +
                        (['/userStats'].includes(
                          props.history.location.pathname
                        )
                          ? ' active'
                          : '')
                      }
                      data-testid="menu-analysis"
                      onClick={() =>
                        this.setState((prevState) => ({
                          statsGlow: !prevState.statsGlow,
                        }))
                      }
                    >
                      <Icon
                        name="chart bar"
                        size="large"
                        className="menu-btn"
                      />
                    </Menu.Item>
                  </Transition>
                )
              }}
            />
          </div>
          <div className="item-container">
            <Route
              render={(props) => {
                var mainIsActive = ![
                  ...additionnalSettingsRoutes,
                  '/userAnalyses',
                  '/userStats',
                ].includes(props.history.location.pathname)
                return (
                  <Transition animation={'pulse'} visible={mainGlow}>
                    <Menu.Item
                      as={Link}
                      to="/"
                      className={'main' + (mainIsActive ? ' active' : '')}
                      data-testid="menu-main"
                      onClick={() =>
                        this.setState((prevState) => ({
                          mainGlow: !prevState.mainGlow,
                        }))
                      }
                    >
                      <Image
                        className="app-logo-menu menu-btn"
                        src={
                          mainIsActive
                            ? '/app-logo-menu.png'
                            : '/app-logo-menu_inactive.png'
                        }
                      />
                    </Menu.Item>
                  </Transition>
                )
              }}
            />
          </div>
          <div className="item-container">
            <Route
              render={() => {
                return (
                  <Transition animation={'pulse'} visible={settingsGlow}>
                    <Menu.Item
                      as={NavLink}
                      to="/settings"
                      className={
                        'menu-button' +
                        (additionnalSettingsRoutes.indexOf(
                          window.location.pathname
                        ) !== -1
                          ? ' active'
                          : '')
                      }
                      style={{ marginRight: -10 }}
                      data-testid="menu-settings"
                      onClick={() =>
                        this.setState((prevState) => ({
                          settingsGlow: !prevState.settingsGlow,
                        }))
                      }
                    >
                      <Icon name="cog" size="large" className="menu-btn" />
                    </Menu.Item>
                  </Transition>
                )
              }}
            />
          </div>
        </Menu>
      </>
    )
  }
}
MenuBottom.propTypes = {
  handleShowClick: PropTypes.func,
  forceNotificationUpdate: PropTypes.bool,
}

export default MenuBottom
