import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'

import {
  Header,
  Grid,
  Card,
  Dimmer,
  Loader,
  Feed,
  List,
  Button,
  Table,
  Dropdown,
  Icon,
  Modal,
} from 'semantic-ui-react'
import {
  getProtocolsPlan,
  updateProtocolsPlan,
  getAllProtocols,
  addProtocolToProtocolsPlan,
  removeProtocolFromProtocolsPlan,
  deleteProtocolPlan,
  searchMyAthletes,
  updateProtocolsPlanUsers,
  createProtocol,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { differenceBy } from 'lodash'
import { RIEInput, RIEToggle } from '@attently/riek'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

import moment from 'moment'

import AppError from '../components/AppError'
import { toast } from 'react-toastify'
import Workout from '../components/Workout'
const selectOptions = [
  { key: 'half', text: 'Half-marathon', value: 'half' },
  { key: 'marathon', text: 'Marathon', value: 'marathon' },
  { key: '5k', text: '5k', value: '5k' },
  { key: '10k', text: '10k', value: '10k' },
  { key: 'custom', text: 'Custom', value: 'custom' },
  { key: 'neutral', text: 'Neutral', value: 'neutral' },
  { key: 'pose', text: 'Pose', value: 'pose' },
  { key: 'fall', text: 'Fall', value: 'fall' },
  { key: 'pull', text: 'Pull', value: 'pull' },
  { key: 'learn', text: 'Learn', value: 'learn' },
  { key: 'short', text: 'Short', value: 'short' },
  { key: 'long', text: 'Long', value: 'long' },
  { key: 'focus', text: 'Focus', value: 'focus' },
  { key: 'selfcare', text: 'SelfCare', value: 'selfcare' },
]

const weekOptions = Array(16)
  .fill(0)
  .map((e, i) => ({
    key: `week ${i + 1}`,
    text: `week ${i + 1}`,
    value: i + 1,
  }))
  .reverse()

class ProtocolsPlan extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      players: [],
      allProtocols: [],
      protocols: [],
      currentDrillBloc: 'warmup',
      protocolsPlanData: {},
      userOptions: [],
      allProtocolsFilter: this.props.match.params.type || 'custom',
      previewedProtocol: null,
      open: false,
      weeks: {},
      filter: 'byweek',
    }
  }
  componentDidMount() {
    const { allProtocolsFilter } = this.state
    if (this.call_getProtocolsPlan()) {
      this.call_getProtocols(allProtocolsFilter)
    }
  }
  async call_getProtocolsPlan() {
    const response = await getProtocolsPlan(this.props.match.params.id)
    if (response.ok) {
      this.setState({
        isLoading: false,
        hasError: false,
        protocols: response.data.protocols || [],
        filteredProtocols: response.data.protocols || [],
        protocolsPlanData: response.data,
        users: response.data.users || [],
        weeks: response.data.weeks || [],
      })
      return true
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  async call_getProtocols(type) {
    let response

    // const { allProtocolsFilter } = this.state

    response = await getAllProtocols(type)
    if (response.ok) {
      // const { polyglot } = this.props;
      const data = response.data.rows
      this.setState({
        allProtocols: data.sort((a, b) =>
          moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1
        ),
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
      })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  call_deleteProtocolPlan = async () => {
    const response = await deleteProtocolPlan(this.props.match.params.id)
    if (response.ok) {
      toast.success(this.props.polyglot.t('generic.message.done'))
      this.props.history.push('/protocolsPlans/')

      return false
    } else {
      toast.error(this.props.polyglot.t('generic.message.error'))
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
      return false
    }
  }
  async addProtocol(protocolId) {
    var response
    response = await addProtocolToProtocolsPlan(this.props.match.params.id, {
      protocolId,
    })

    let protocols = response.data.protocols
    this.applyFilter(this.state.filter, protocols)

    if (response.ok) {
      this.setState({
        protocols,
      })
    }
  }
  async removeFromProtocolsPlan(protocol) {
    var response
    response = await removeProtocolFromProtocolsPlan(
      this.props.match.params.id,
      {
        protocolId: protocol._id || protocol.id,
      }
    )
    let protocols = response.data.protocols
    this.applyFilter(this.state.filter, protocols)
    if (response.ok) {
      this.setState({
        protocols,
      })
    }
  }
  onDatePickerChange(date) {
    this.call_updateProtocolsPlan({ date })
  }
  call_updateProtocolsPlan = (prop) => {
    if (Object.prototype.hasOwnProperty.call(prop, 'date')) {
      prop['date'] = moment(prop.date, 'DD-MM-YYYY')
    }
    if (typeof prop.type === 'object') {
      prop = { type: prop.type.id }
    }

    let newprotocolsPlanData = this.state.protocolsPlanData
    newprotocolsPlanData.protocols = this.state.protocols.map(
      (x) => x._id || x.id
    )
    Object.assign(newprotocolsPlanData, prop)
    const newState = Object.assign({}, this.state, {
      protocolsPlanData: newprotocolsPlanData,
    })

    updateProtocolsPlan(this.props.match.params.id, newprotocolsPlanData)
      .then((response) => {
        if (response.ok) {
          // const { polyglot } = this.props;

          //   toast.success(this.props.polyglot.t('generic.message.done'))
          this.setState({ newState })
        } else {
          // Error
          toast.error(this.props.polyglot.t('generic.message.error'))
          this.setState({
            isLoading: false,
            hasError: true,
            error: response,
          })
        }
      })
      .catch()
  }
  setAllProtocolsFilter = (e, { value }) => {
    this.setState({ allProtocolsFilter: value })
    this.call_getProtocols(value)
  }

  updateUser = async (selectedUserId, action) => {
    let { users } = this.state
    // users.push(selectedUserId)
    let usersIds = users.map((x) => x.id || x._id)
    if (action === 'add') {
      usersIds.push(selectedUserId)
    } else {
      usersIds = usersIds.filter((x) => '' + x !== selectedUserId)
    }

    let response = await updateProtocolsPlanUsers(this.props.match.params.id, {
      users: usersIds,
    })

    if (response.ok) {
      // const { polyglot } = this.props;
      toast.success(this.props.polyglot.t('generic.message.done'))
      if (this.call_getProtocolsPlan()) {
        this.call_getProtocols()
      }
    } else {
      // Error
      toast.error(this.props.polyglot.t('generic.message.error'))
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
    //call_getProtocols(value)
  }

  onSearchChange = async (e, value) => {
    let pattern = value.searchQuery
    if (pattern.length < 3) return
    let response = await searchMyAthletes(pattern)
    if (response.ok) {
      let rows = response.data.rows
      if (response.data.count === 0) {
        return
      }
      let userOptions = rows.map((x) => ({
        key: x.id,
        value: x.id,
        text: `${x.firstName} ${x.lastName} - ${x.email}`,
      }))
      this.setState({
        userOptions,
      })
    }
  }

  call_createProtocol = async () => {
    const { polyglot } = this.props
    const defaultParams = {
      label: polyglot.t('protocol.new'),
    }
    let response = await createProtocol(defaultParams)
    if (response.ok) {
      let protocolId = response.data._id

      await this.addProtocol(protocolId)
      this.props.history.push('/protocols/' + protocolId)
    }
  }

  previewProtocol = (protocol) => {
    this.setState({ previewedProtocol: protocol })
  }

  addProtocolToWeek = ({ protocolId, week }) => {
    this.setState((prevState) => (prevState.weeks[protocolId] = week))
    let weeks = { ...this.state.weeks, [protocolId]: week }

    weeks = Object.fromEntries(
      Object.entries(weeks).sort(([, a], [, b]) => {
        if (a.weekNumber < b.weekNumber) return 1
        if (a.weekNumber > b.weekNumber) return -1
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1

        return 0
      })
    )

    this.call_updateProtocolsPlan({ weeks })
  }

  applyFilter = (filter, protocols = this?.state?.protocols) => {
    let { weeks } = this.state
    let filteredProtocols
    if ([20, 1000].includes(filter)) {
      filteredProtocols = protocols.slice(protocols.length - filter)

      this.setState({ filteredProtocols, filter })
    } else if (filter === 'weekly') {
      filteredProtocols = Object.keys(weeks).map((protocolId) =>
        protocols.find((protocol) => protocol._id === protocolId)
      )

      this.setState({ filteredProtocols, filter })
    } else {
      let filteredWeeks = Object.keys(weeks).filter((protocolId) => {
        return weeks[protocolId].weekNumber === filter
      })

      filteredProtocols = filteredWeeks.map((protocolId) =>
        protocols.find((protocol) => '' + protocol._id === '' + protocolId)
      )

      this.setState({ filteredProtocols, filter })
    }
  }

  handleRLDDChange = (reorderedItems) => {
    let { weeks } = this.state

    //mise à jour de l'ordre dans semaine
    reorderedItems.forEach((element, index) => {
      weeks[element._id].order = index
      element.id = index
    })

    this.setState({ filteredProtocols: reorderedItems })
    this.call_updateProtocolsPlan({
      weeks,
      protocols: reorderedItems.map((item) => item._id),
    })
  }

  setOpen = (open) => {
    this.setState({ open })
  }

  render() {
    const { polyglot } = this.props
    var _this = this
    const {
      protocols,
      allProtocols,
      users,
      filteredProtocols,
      previewedProtocol,
      weeks,
    } = this.state

    let allProtocolsList = () =>
      differenceBy(allProtocols, protocols, 'label')
        .sort((a, b) =>
          moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1
        )
        .map(function (protocol) {
          return (
            <Table.Row key={protocol.id}>
              <Table.Cell>
                <Link to={'/protocols/' + protocol.id}>{protocol.label}</Link>
              </Table.Cell>
              <Table.Cell>
                <Button
                  icon="check"
                  color="green"
                  size="mini"
                  onClick={() => _this.addProtocol(protocol.id)}
                  style={{ marginLeft: '10px', verticalAlign: 'top' }}
                />
              </Table.Cell>
            </Table.Row>
          )
        })

    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
            .react-datepicker__input-container input {
              border:none;
						}
						.ui.header>.icon.small
						{
							font-size: .75em!important;
							cursor:pointer;

						}
						.drill-label{
							width:200px;
							display:inline-block;
						}

						`}</style>

              <Grid stackable columns={3} divided style={{ height: '100%' }}>
                <Grid.Row
                  style={{
                    paddingLeft: '2rem',
                  }}
                >
                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    <div style={{ float: 'right' }}>
                      <RIEToggle
                        value={this.state.protocolsPlanData.isAvailable || ''}
                        className={this.state.highlight ? 'editable' : ''}
                        change={this.call_updateProtocolsPlan}
                        classLoading="loading"
                        propName="isAvailable"
                        textTrue={polyglot.t('protocol.isAvailable')}
                        textFalse={polyglot.t('protocol.isAvailable.not')}
                      />
                      <span style={{ margin: '0 5px' }}>
                        <RIEToggle
                          value={
                            this.state.protocolsPlanData.isSequential || ''
                          }
                          className={this.state.highlight ? 'editable' : ''}
                          change={this.call_updateProtocolsPlan}
                          classLoading="loading"
                          propName="isSequential"
                          textTrue={polyglot.t('protocol.isSequential')}
                          textFalse={polyglot.t('protocol.isSequential.not')}
                        />
                      </span>
                      <Button onClick={() => this.setOpen(true)}>users</Button>

                      <Icon
                        color="red"
                        name="trash"
                        onClick={this.call_deleteProtocolPlan}
                        style={{ marginLeft: '10px', float: 'right' }}
                      />
                    </div>
                    <Header as="h2">
                      <RIEInput
                        value={this.state.protocolsPlanData.label || ''}
                        change={this.call_updateProtocolsPlan}
                        propName="label"
                        className={
                          this.state.protocolsPlanData.label ? ' ' : ' riek'
                        }
                        classLoading="loading"
                        classInvalid="invalid"
                      />
                    </Header>
                    <Grid stackable>
                      <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('protocolsPlan.protocols')}{' '}
                              <Button
                                icon="add"
                                color="blue"
                                size="mini"
                                onClick={() => this.call_createProtocol()}
                                style={{
                                  marginLeft: '10px',
                                  verticalAlign: 'top',
                                }}
                              />
                              <Dropdown
                                selection
                                compact
                                style={{ float: '	right' }}
                                options={[20, 'all', 'weekly']
                                  .map((x) => ({
                                    key: x,
                                    value: x === 'all' ? 1000 : x,
                                    text: x.toString(),
                                  }))
                                  .concat(weekOptions)}
                                onChange={(e, { value }) =>
                                  this.applyFilter(value)
                                }
                                value={this.state.filter || 10}
                              />
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>
                              <RLDD
                                items={filteredProtocols?.map(
                                  (item, index) => ({
                                    ...item,
                                    id: weeks[item?._id]?.order || index,
                                  })
                                )}
                                itemRenderer={(element, index) => {
                                  return (
                                    <List.Item key={element._id || element.id}>
                                      <List.Content>
                                        <List.Header>
                                          <Link
                                            to={
                                              '/protocols/' +
                                              (element._id || element.id)
                                            }
                                          >
                                            <span className="drill-label">
                                              {index + 1} - {element.label}
                                            </span>
                                          </Link>
                                          <Button
                                            icon="close"
                                            color="red"
                                            size="mini"
                                            onClick={() =>
                                              this.removeFromProtocolsPlan(
                                                element
                                              )
                                            }
                                            style={{
                                              marginLeft: '10px',
                                              marginBottom: '5px',
                                              verticalAlign: 'top',
                                            }}
                                          />

                                          <Button
                                            icon="eye"
                                            color={
                                              previewedProtocol?._id !==
                                              element._id
                                                ? 'blue'
                                                : 'grey'
                                            }
                                            size="mini"
                                            onClick={() =>
                                              this.previewProtocol(element)
                                            }
                                            style={{
                                              marginLeft: '10px',
                                              marginBottom: '5px',
                                              verticalAlign: 'top',
                                            }}
                                          />
                                          <Button
                                            basic
                                            icon={
                                              element.isAvailable
                                                ? 'check circle outline'
                                                : 'circle outline'
                                            }
                                            style={{
                                              marginLeft: '10px',
                                              marginBottom: '5px',
                                              verticalAlign: 'top',
                                            }}
                                          />
                                          <Button
                                            basic
                                            icon={
                                              element.suggestRunWarmup
                                                ? 'toggle on'
                                                : 'toggle off'
                                            }
                                            style={{
                                              marginLeft: '10px',
                                              marginBottom: '5px',
                                              verticalAlign: 'top',
                                            }}
                                          />
                                          <Dropdown
                                            selection
                                            compact
                                            style={{ float: '	right' }}
                                            options={weekOptions}
                                            onChange={(e, data) =>
                                              this.addProtocolToWeek({
                                                protocolId: element._id,
                                                week: {
                                                  weekNumber: data.value,
                                                  order: element.id,
                                                },
                                              })
                                            }
                                            value={
                                              this.state.weeks[element._id]
                                                ?.weekNumber
                                            }
                                          />
                                        </List.Header>
                                        <List.Description />
                                      </List.Content>
                                    </List.Item>
                                  )
                                }}
                                onChange={this.handleRLDDChange}
                              />
                            </Feed>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    <Header as="h2"></Header>
                    <Grid stackable>
                      <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header></Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed style={{ backgroundColor: 'black' }}>
                              <style>
                                {`
																	.ui.segment{background-color:black}
																.segments{
																	background: rgba(0, 0, 0, 0.6) !important;
																}
														.segments .segment {
																color: #ababab;
																 border-top: 1px solid #3a3939 !important;
																 border-right: 1px solid #3a3939 !important;
																 border-left: 1px solid #3a3939 !important;
															 }
															 .page	.segments .segment a{
																color: #ababab;
															 }

															 .segments .segment:last-child 					{
																 border-bottom: 1px solid #3a3939 !important;
															 }
																`}
                              </style>
                              {previewedProtocol && (
                                <Workout
                                  polyglot={polyglot}
                                  workout={previewedProtocol.workout}
                                />
                              )}
                            </Feed>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    <Fragment>
                      <Table basic="very" celled collapsing>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>
                              {polyglot.t('course.drill.name')}
                              <Dropdown
                                selection={true}
                                value={this.state.allProtocolsFilter}
                                placeholder="Type"
                                options={selectOptions}
                                onChange={this.setAllProtocolsFilter}
                              />
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>{allProtocolsList()}</Table.Body>
                      </Table>
                    </Fragment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </div>
        <Modal
          centered={false}
          open={this.state.open}
          onClose={() => this.setOpen(false)}
          onOpen={() => this.setOpen(true)}
        >
          <Modal.Header>Users</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Fragment>
                <Table basic="very" celled collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {polyglot.t('course.drill.name')}

                        <Dropdown
                          onSearchChange={this.onSearchChange}
                          search
                          selection
                          options={this.state.userOptions}
                          onChange={(e, data) =>
                            this.updateUser(data.value, 'add')
                          }
                          value={this.state.selectedUser || ''}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {users?.map((x) => (
                      <Table.Row key={x.id || x._id}>
                        <Table.Cell>{`${x.firstName} ${x.lastName} `}</Table.Cell>
                        <Table.Cell>
                          <Button
                            icon="close"
                            color="red"
                            size="mini"
                            onClick={() =>
                              this.updateUser(x.id || x._id, 'del')
                            }
                            style={{
                              marginLeft: '10px',
                              marginBottom: '5px',
                              verticalAlign: 'top',
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Fragment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setOpen(false)}>OK</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}
ProtocolsPlan.propTypes = {
  match: PropTypes.object,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

ProtocolsPlan = withPolyglot()(ProtocolsPlan)
export default withRouter(ProtocolsPlan)
