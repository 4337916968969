import en_us from './loc/en-us'
import fr_fr from './loc/fr-fr'

export const Config = {
  bookingUrl: 'https://runwithjey.simplybook.it',
  //gaId: 'UA-163473915-1',

  gaId: 'UA-163473915-2',

  localeOptions: [
    {
      text: 'Français',
      value: 'fr-fr',
    },
    {
      text: 'English',
      value: 'en-us',
    },
  ],
  translations: {
    'fr-fr': fr_fr,
    'en-us': en_us,
  },
  termsUrl: {
    'fr-fr': '//molokoy.io/conditions',
    'en-us': '//molokoy.io/terms',
  },
}
