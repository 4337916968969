import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Segment as div } from 'semantic-ui-react'

import ProMetronome from './ProMetronome'

import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import ReactNoSleep from 'react-no-sleep'

class Metronome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: false,
      isPlaying: false,
      bpm: this.props.bpm || 180,
    }
  }

  render() {
    const { isPlaying } = this.state

    const { embedded } = this.props

    return (
      <span className={'metronome ' + (embedded === true ? 'embedded' : '')}>
        {document.addEventListener('visibilitychange', function () {
          if (document.hidden) {
            //clearTimeout(noSleepTimer);
          } else {
            // alert('visible')
            window.location.reload()
            // Now do your normal enable code or rely on your click handlers etc
          }
        })}
        <ReactNoSleep>
          {({ isOn, enable, disable }) => (
            <>
              <Button
                icon={isPlaying ? 'pause' : 'play'}
                size={embedded ? 'mini' : 'big'}
                className="app-button"
                circular
                onClick={() => {
                  this.setState({ isPlaying: !isPlaying })
                  isOn ? disable() : enable()
                }}
              />
            </>
          )}
        </ReactNoSleep>
        <div className="metronome__bpm top-15 bottom-25">{this.state.bpm}</div>
        <Slider
          min={160}
          max={200}
          value={this.state.bpm}
          onChange={(value) => this.setState({ bpm: value })}
          style={{ userSelect: 'none' }}
        />

        <ProMetronome
          bpm={this.state.bpm}
          subdivision={1}
          soundEnabled={true}
          soundPattern="3232"
          isPlaying={isPlaying}
          render={() => null}
        />
      </span>
    )
  }
}
Metronome.propTypes = {
  bpm: PropTypes.number,
  embedded: PropTypes.bool,
}

export default Metronome
