import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import { isEmpty } from 'lodash'
import pose from '../images/pose_no_contour.png'
import { getMyLastAnalysis } from '../services/Api'

class getAnalysis extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      myLastAnalysis: {},
    }
  }

  async componentDidMount() {
    const myLastAnalysis = await this.call_getMyLastAnalysis()

    this.setState({
      myLastAnalysis,
    })
  }

  async call_getMyLastAnalysis() {
    var response = await getMyLastAnalysis()
    if (response.ok && response.data) {
      var data = response.data
      return data.length ? data[0] : {}
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  render() {
    const { polyglot } = this.props
    const { myLastAnalysis } = this.state
    /*on peut avoir une analyse si on en a pas encore ou si la derniere n'est pas en cours d'analyse */
    const analysisInProgress =
      !isEmpty(myLastAnalysis) && myLastAnalysis.isAvailable !== true

    return (
      <div>
        {analysisInProgress === false &&
          polyglot.t('useranalyses.technique.analysis.none')}

        <div className="actions-placeholder">
          <div className="call-to-action">
            <span
              className={
                'action top-20 highlight standalone' +
                (analysisInProgress ? ' analysis-waiting' : '')
              }
              onClick={() =>
                analysisInProgress
                  ? false
                  : this.props.history.push('/analyseMe')
              }
            >
              <Icon
                style={{
                  top: '8px',
                }}
              >
                <img
                  src={pose}
                  alt={pose}
                  style={{
                    height: '42px',
                    borderRadius: '500rem',
                    border: '4px solid white',
                    padding: 4,
                  }}
                />
              </Icon>
              <em>
                {' '}
                {analysisInProgress
                  ? polyglot.t('home.action.analysis.inprogress')
                  : polyglot.t('home.action.analysis.get')}{' '}
              </em>
            </span>
          </div>
        </div>
      </div>
    )
  }
}
getAnalysis.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
getAnalysis = withPolyglot()(getAnalysis)
export default getAnalysis
