import React, { Component } from 'react'
import { Route, Switch, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu, Container, Image, Icon } from 'semantic-ui-react'

class MenuTop extends Component {
  render() {
    const { toggleSidebar, isCoach } = this.props
    return (
      <Menu fixed="top" inverted className="main-menu">
        <Container>
          <Menu.Item
            position="left"
            style={{ marginLeft: '0px', width: '5px' }}
          >
            <Switch>
              <Route
                path={[
                  // chemins pour lesquels on doit pouvoir revenir en arriere
                  '/analyseMe',
                  '/profile/',
                  '/drills/',
                  '/notifications',
                  '/paceConverter',
                  '/metronome',
                  '/ruffier',
                  '/passwordChange',
                  '/runnerProfile',
                  '/userAnalyses',
                  '/userNewPerf',
                  '/userProtocol',
                  '/userServices',
                  '/userTrainingLog/',
                  '/userTrainings',
                  '/userperfs',
                  '/services',
                  '/plan/',
                  '/protocols/',
                  '/library',
                  '/shelf',
                  '/userSelfCare',
                  '/shoesPrescriptions',
                ]}
                render={(props) => (
                  <span
                    onClick={() => {
                      let qs = props.location.search
                      if (qs) {
                        const urlParams = new URLSearchParams(qs)
                        const returnUrl = urlParams.get('returnUrl')
                        props.history.push(returnUrl)
                      } else {
                        props.history.goBack()
                      }
                    }}
                    style={{ paddingLeft: '10px', marginRight: '10px' }}
                  >
                    <Icon
                      name="arrow left"
                      size="large"
                      className="floated left  top-10 back-arrow"
                      style={{ color: 'grey' }}
                      data-testid="back-arrow"
                    />
                  </span>
                )}
              />
              <Route
                path={['/todayTraining/']} //acces par la home page
                render={() => (
                  <Link to="/" style={{ paddingLeft: '5px' }}>
                    <Icon
                      name="arrow left"
                      size="large"
                      className="floated left  top-10"
                      style={{ color: 'grey' }}
                      data-testid="back-arrow"
                    />
                  </Link>
                )}
              />
              <Route
                path={['/userTraining/']}
                render={() => (
                  <Link to="/userTrainings" style={{ paddingLeft: '5px' }}>
                    <Icon
                      name="arrow left"
                      size="large"
                      className="floated left  top-10"
                      style={{ color: 'grey' }}
                      data-testid="back-arrow"
                    />
                  </Link>
                )}
              />
            </Switch>
          </Menu.Item>
          <Menu.Item as="a" header href="/">
            <Image className="app-logo" src="/app-logo.png" />
          </Menu.Item>

          <Menu.Item position="right">
            {isCoach && (
              <Icon
                onClick={toggleSidebar}
                name="bars"
                size="large"
                className="floated right  top-10"
                style={{ color: 'grey', marginRight: 10 }}
                data-testid="burger"
              />
            )}
          </Menu.Item>
        </Container>
      </Menu>
    )
  }
}

MenuTop.propTypes = {
  toggleSidebar: PropTypes.func,
  isCoach: PropTypes.bool,
}
export default MenuTop
