import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Grid,
  Card,
  Dimmer,
  Loader,
  Button,
  Icon,
  Feed,
  List,
  Image,
  Container,
} from 'semantic-ui-react'
import { getMyAthletes, createNotifications } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { differenceBy, pullAllBy } from 'lodash'
import { RIEInput, RIETextArea } from '@attently/riek'
import { toast } from 'react-toastify'
import AppError from '../components/AppError'

class Notification extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      players: [],
      myAthletes: [],
      componentData: {},
    }
    this.updateNotification = this.updateNotification.bind(this)
  }
  componentDidMount() {
    this.call_getMyAthletes()
  }

  async call_getMyAthletes() {
    const response = await getMyAthletes()
    if (response.ok) {
      let data = response.data.rows
      const newState = Object.assign({}, this.state, {
        myAthletes: data,
        isLoading: false,
        hasError: false,
      })
      // store the new state object in the component's state
      this.setState(newState)
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }

  call_createNotifications() {
    const { polyglot } = this.props
    let body = this.state.componentData
    let sendTos = []

    sendTos = this.state.players.map((player) => {
      return player.id
    })
    body.sendTos = sendTos

    createNotifications(body)
      .then((response) => {
        if (response.ok) {
          //this.setState(newState)
          toast.success(polyglot.t('generic.message.update'), {})
        } else {
          toast.error(polyglot.t('generic.message.error'), {})
        }
      })
      .catch()
  }
  addToSendTos(player) {
    const newArray = this.state.players
    newArray.push(player)
    this.setState({ players: newArray })
  }
  removeFromSendTos(player) {
    const newArray = this.state.players
    pullAllBy(newArray, [player], 'email')
    this.setState({ players: newArray })
  }

  updateNotification(prop) {
    let newcomponentData = this.state.componentData
    Object.assign(newcomponentData, prop)
    const newState = Object.assign({}, this.state, {
      componentData: newcomponentData,
    })
    this.setState(newState)
  }

  render() {
    const { polyglot } = this.props
    var _this = this
    let myAthletes = this.state.myAthletes
    let players = this.state.players

    myAthletes.sort(function (a, b) {
      if (!a.name && !b.name) return 0
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

    players.sort(function (a, b) {
      if (!a.name && !b.name) return 0
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

    const playerList = players.map(function (player) {
      return (
        <List.Item key={player.id}>
          <Image avatar src={player.picture} />
          <List.Content>
            <List.Header>
              {' '}
              {player.name}
              <Icon
                name="close"
                color="red"
                onClick={() => _this.removeFromSendTos(player)}
                style={{ marginLeft: '10px' }}
              />
            </List.Header>
            <List.Description />
          </List.Content>
        </List.Item>
      )
    })

    /*on enleve le coach courant de la liste des athetes*/
    myAthletes = differenceBy(
      myAthletes,
      [{ id: localStorage.RWJUserId }],
      'id'
    )
    let athletesList = differenceBy(myAthletes, players, 'email').map(function (
      player
    ) {
      return (
        <List.Item key={player.id}>
          <Image avatar src={player.picture} />
          <List.Content>
            <List.Header>
              {' '}
              {player.name}
              <Icon
                name="check"
                color="green"
                onClick={() => _this.addToSendTos(player)}
                style={{ marginLeft: '10px' }}
              />
            </List.Header>
            <List.Description>
              {player.faults[0]
                ? polyglot.t('faults.' + player.faults[0].fault.code)
                : ''}
            </List.Description>
          </List.Content>
        </List.Item>
      )
    })

    return (
      <Fragment>
        <div className="main-container ">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <Container>
                <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
						`}</style>

                <Grid stackable divided style={{ height: '100%' }}>
                  <Grid.Row>
                    <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                      <Header as="h2" />
                      <Grid stackable columns={3}>
                        <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('notification.athletes.my')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              <List style={{ textAlign: 'left' }}>
                                {athletesList}
                              </List>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                        <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('notification.sendTo')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              <List style={{ textAlign: 'left' }}>
                                {playerList}
                              </List>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                        <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                          <Card className="card-description">
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('notification.content')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              <Feed>
                                <Feed.Summary>
                                  <strong>
                                    {polyglot.t('notification.title')}
                                  </strong>
                                  <RIEInput
                                    value={this.state.componentData.title || ''}
                                    change={this.updateNotification}
                                    propName="title"
                                    className={
                                      this.state.highlight
                                        ? 'editable riek'
                                        : 'riek'
                                    }
                                    classLoading="loading"
                                    classInvalid="invalid"
                                  />
                                </Feed.Summary>
                                <Feed.Extra text>
                                  <strong>
                                    {polyglot.t('notification.message')}
                                  </strong>
                                  <RIETextArea
                                    value={
                                      this.state.componentData.description || ''
                                    }
                                    change={this.updateNotification}
                                    propName="message"
                                    className={
                                      this.state.highlight
                                        ? 'editable riek'
                                        : 'riek'
                                    }
                                    classLoading="loading"
                                    classInvalid="invalid"
                                  />
                                </Feed.Extra>
                              </Feed>
                            </Card.Content>
                            <Card.Content extra>
                              <Button
                                color="black"
                                onClick={this.call_createNotifications.bind(
                                  this
                                )}
                              >
                                Envoyer
                              </Button>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

Notification.propTypes = {
  polyglot: PropTypes.object,
}

Notification = withPolyglot()(Notification)
export default Notification
