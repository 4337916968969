import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Segment,
  Label,
  Table,
  Dropdown,
  Button,
  Icon,
} from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

let emptyInterval = {
  repeat: 1,
  distance: '',
  time: '',
  drill: null,
  custom: '',
  cadence: '',
  pace: '',
  rest: '',
  instruction: '',
}
class WorkoutEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  intervalsKeys = [
    'repeat',
    'drill',
    'drillPose',
    'drillFall',
    'drillPull',
    'custom',
    'distance',
    'time',
    'cadence',
    'pace',
    'rest',
    'instruction',
  ]

  componentDidMount = () => {
    const { workout } = this.props
    if (!workout) {
      return
    }
    workout.content = workout?.content.map((block, blockIndex) => {
      let newblock = {
        ...block,
        id: blockIndex,
      }

      newblock.intervals = newblock.intervals.map(
        (interval, intervalIndex) => ({
          ...interval,
          id: intervalIndex,
          blockId: blockIndex,
        })
      )

      return newblock
    })
    this.setState({ workout })
  }

  onFieldUpdate = ({ contentIndex, intervalsIndex, field, value }) => {
    //{contentIndex: 0, intervalsIndex: 1, field: 'custom', value: '0'}
    const { workout } = this.state

    let newWorkout = { ...workout }

    if (contentIndex !== false && intervalsIndex !== false) {
      newWorkout.content[contentIndex].intervals[intervalsIndex][field] = value
    }
    if (contentIndex !== false && intervalsIndex === false) {
      newWorkout.content[contentIndex][field] = value
    }
    this.setState({ workout: newWorkout })
    // this.props.updateWorkout(newWorkout)
  }

  addIntervalToBloc = (contentIndex) => {
    const { workout } = this.state
    let newWorkout = { ...workout }
    newWorkout.content[contentIndex].intervals.push({
      ...emptyInterval,
      id: Date.now(),
    })
    this.setState({ workout: newWorkout })
    // this.props.updateWorkout(newWorkout)
  }

  addBloc = () => {
    let emptyBloc = {
      repeat: 1,
      intervals: [],
      id: Date.now(),
    }
    const { workout } = this.state
    let newWorkout = { ...workout }
    let newsize = newWorkout.content.push(emptyBloc)
    newWorkout.content[newsize - 1].intervals.push({
      ...emptyInterval,
      id: Date.now(),
    })
    this.setState({ workout: newWorkout })
    // this.props.updateWorkout(newWorkout)
  }

  removeInterval = (contentIndex, intervalsIndex) => {
    const { workout } = this.state
    let newWorkout = { ...workout }
    newWorkout.content[contentIndex].intervals.splice(intervalsIndex, 1)
    this.setState({ workout: newWorkout })
    // this.props.updateWorkout(newWorkout)
  }

  removeBloc = (contentIndex) => {
    const { workout } = this.state
    let newWorkout = { ...workout }
    newWorkout.content.splice(contentIndex, 1)
    this.setState({ workout: newWorkout })
  }

  handleRLDDIntervalsChange = ({ newItems, blockId }) => {
    let { workout } = this.state

    let newWorkout = { ...workout }
    /*
    let blockIntervals = newWorkout.content.find(
      (block) => block.id === blockId
    ).intervals
    blockIntervals = newItems
*/
    newWorkout.content.find((block) => block.id === blockId).intervals =
      newItems

    this.setState({ workout: newWorkout })
  }
  render() {
    const { polyglot, allDrills } = this.props
    const { workout } = this.state

    return (
      <Form style={{ position: 'relative' }}>
        <style>
          {`
						input.small{
							width: 40px !important;
							padding: 0.1em !important;
							text-align: center;
						}
					`}
        </style>
        <div style={{ textAlign: 'right' }}>
          <Button className={'app-button '} onClick={() => this.addBloc()}>
            Add block
          </Button>
        </div>
        {workout?.content.map((c, contentIndex) => (
          <Segment key={contentIndex} style={{ paddingTop: 30 }}>
            <Button
              className={'app-button right floated'}
              style={{ marginRight: 30, marginTop: 10 }}
              onClick={() => this.addIntervalToBloc(contentIndex)}
            >
              Add interval
            </Button>
            <Label
              attached="top right"
              icon="trash"
              className={'app-button right floated'}
              onClick={() => this.removeBloc(contentIndex)}
            ></Label>
            <Label color="red" attached="top left">
              <span style={{ verticalAlign: 'sub', marginRight: 5 }}>
                Repeat
              </span>
              <input
                className="small"
                value={c.repeat}
                onChange={(e) =>
                  this.onFieldUpdate({
                    contentIndex,
                    intervalsIndex: false,
                    field: 'repeat',
                    value: e.target.value,
                  })
                }
              />
              <span
                style={{ verticalAlign: 'sub', marginRight: 5, marginLeft: 5 }}
              >
                or AMRAP
              </span>

              <input
                className="small"
                value={c.amrap}
                onChange={(e) =>
                  this.onFieldUpdate({
                    contentIndex,
                    intervalsIndex: false,
                    field: 'amrap',
                    value: e.target.value,
                  })
                }
              />
            </Label>

            {c.intervals && (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                    {this.intervalsKeys.map((k) => (
                      <Table.HeaderCell textAlign="center" key={k}>
                        {polyglot.t('workout.edit.' + k)}
                      </Table.HeaderCell>
                    ))}
                    <Table.HeaderCell textAlign="center" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {c.intervals.map((interval, intervalsIndex) => (
                    <Table.Row key={intervalsIndex}>
                      {intervalsIndex === 0 && (
                        <Table.Cell collapsing rowSpan="10">
                          <RLDD
                            items={c.intervals}
                            itemRenderer={(item) => {
                              return (
                                <div
                                  className="item"
                                  style={{ padding: '1.3em .1em' }}
                                >
                                  <Icon name="bars" />
                                </div>
                              )
                            }}
                            onChange={(newItems) =>
                              this.handleRLDDIntervalsChange({
                                newItems,
                                blockId: c.id,
                              })
                            }
                          />
                        </Table.Cell>
                      )}

                      {this.intervalsKeys.map((k) => (
                        <Table.Cell textAlign="center" key={k}>
                          {[
                            'drill',
                            'drillPose',
                            'drillFall',
                            'drillPull',
                          ].includes(k) ? (
                            <>
                              <Dropdown
                                fluid
                                search
                                selection
                                clearable
                                options={allDrills.map((x) => ({
                                  value: x.id,
                                  key: x.id,
                                  text: x.name,
                                }))}
                                value={interval[k]?._id}
                                onChange={(e, field) =>
                                  this.onFieldUpdate({
                                    contentIndex,
                                    intervalsIndex,
                                    field: k,
                                    value:
                                      field.value === '' ? null : field.value,
                                  })
                                }
                              />
                            </>
                          ) : (
                            <Fragment>
                              <input
                                className={
                                  ['custom', 'instruction'].includes(k)
                                    ? ''
                                    : 'small'
                                }
                                value={interval[k] || ''}
                                onChange={(e) =>
                                  this.onFieldUpdate({
                                    contentIndex,
                                    intervalsIndex,
                                    field: k,
                                    value: e.target.value,
                                  })
                                }
                              />{' '}
                              {k === 'repeat' && ' x '}
                            </Fragment>
                          )}
                        </Table.Cell>
                      ))}

                      <Table.Cell>
                        {' '}
                        <Icon
                          name="trash"
                          onClick={() =>
                            this.removeInterval(contentIndex, intervalsIndex)
                          }
                        />{' '}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </Segment>
        ))}
        <Button
          className={'app-button	'}
          onClick={() => this.props.saveWorkout(workout)}
        >
          Save
        </Button>{' '}
      </Form>
    )
  }
}

WorkoutEdit.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  workout: PropTypes.object,
  saveWorkout: PropTypes.func,
  allDrills: PropTypes.array,
}
WorkoutEdit = withPolyglot()(WorkoutEdit)
export default WorkoutEdit
