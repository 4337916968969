import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withPolyglot } from '../vendor/polyglot-react'

class GetPremium extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { polyglot, subscriptionStatus } = this.props
    const { hasValidSubscription } = subscriptionStatus

    /*on peut avoir une analyse si on en a pas encore ou si la derniere n'est pas en cours d'analyse */

    return (
      <>
        {!hasValidSubscription && (
          <div
            className="call-to-action incard bottom-10"
            style={{ flexGrow: 'unset' }}
          >
            <span
              className={'action top-0 highlight premium fullwidth'}
              style={{
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                fontSize: '1.5rem',
                paddingTop: 25,
                textTransform: 'uppercase',
              }}
              onClick={() => this.props.history.push('/services')}
              data-testid="get-premium"
            >
              {polyglot.t('home.action.premium.get')}
            </span>
          </div>
        )}
      </>
    )
  }
}

GetPremium.propTypes = {
  subscriptionStatus: PropTypes.object,
  history: PropTypes.object,
  polyglot: PropTypes.object,
}

GetPremium = withPolyglot()(GetPremium)
export default GetPremium
