import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Icon, Transition } from 'semantic-ui-react'

class AnalysisGaugeCircular extends PureComponent {
  render() {
    const {
      getColor,
      score,
      totalImprovement,
      totalImprovementVisible,
      improvementColor,
    } = this.props

    let gradientTransform = `rotate(${80})`

    return (
      <div className="circular-progress" id={`progress_${score}`}>
        <style>{`
						#progress_${score}	.CircularProgressbar .CircularProgressbar-path {
								stroke: url(#idCSS${score});
							}
						`}</style>

        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient
              id={'idCSS' + score}
              gradientTransform={gradientTransform}
            >
              <stop offset="5%" stopColor={`rgb(${getColor(0)})`} />,
              <stop offset="20%" stopColor={`rgb(${getColor(50)})`} />,
              <stop offset="70%" stopColor={`rgb(${getColor(score)})`} />,
            </linearGradient>
          </defs>
        </svg>

        <CircularProgressbarWithChildren
          value={score}
          text={`${score}%`}
          circleRatio={0.75}
          strokeWidth={8}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            trailColor: 'rgba(75, 74, 74, 0.32)',
            textColor: '#fff' /*'#a1a1a1'*/,
            //pathColor: `rgb(${getColor(score)})`,
          })}
        >
          {totalImprovement !== false && (
            <Transition
              visible={totalImprovementVisible}
              animation="scale"
              duration={500}
            >
              <div
                style={{
                  marginTop: 45,
                  color: improvementColor,
                }}
              >
                <Icon
                  name={
                    totalImprovement > 0
                      ? 'arrow alternate circle up outline'
                      : totalImprovement < 0
                      ? 'arrow alternate circle down outline'
                      : 'arrow alternate circle right outline'
                  }
                />
                <Icon
                  name={
                    totalImprovement < 0
                      ? 'minus'
                      : totalImprovement > 0
                      ? 'plus'
                      : ''
                  }
                  size="mini"
                  style={{
                    verticalAlign: 'middle',
                    paddingBottom: '1.3em',
                    marginRight: totalImprovement !== 0 ? '0px' : '-12px',
                    marginLeft: totalImprovement !== 0 ? '-1px' : '2px',
                  }}
                />
                {totalImprovement !== 0 && Math.abs(totalImprovement)}
              </div>
            </Transition>
          )}
        </CircularProgressbarWithChildren>
      </div>
    )
  }
}

AnalysisGaugeCircular.propTypes = {
  getColor: PropTypes.func,
  score: PropTypes.number,
  totalImprovement: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  totalImprovementVisible: PropTypes.bool,
  improvementColor: PropTypes.string,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

export default AnalysisGaugeCircular
