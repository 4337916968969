import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withPolyglot } from '../vendor/polyglot-react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { Icon } from 'semantic-ui-react'
import pose from '../images/pose_no_contour.png'
import AppCard from '../components/AppCard'

class GetAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { polyglot, subscriptionStatus } = this.props
    const { lastAnalysis } = subscriptionStatus

    /*on peut avoir une analyse si on en a pas encore ou si la derniere n'est pas en cours d'analyse */
    const analysisInProgress =
      !isEmpty(lastAnalysis) &&
      !lastAnalysis.isInvalid &&
      lastAnalysis.isAvailable !== true

    const lastAnalysisIsOneMonthOldAtLeast =
      isEmpty(lastAnalysis) ||
      (!isEmpty(lastAnalysis) &&
        !!moment().diff(moment(lastAnalysis.published), 'months'))

    /* const isRecent =
      !!lastAnalysis?.isAvailable && !!lastAnalysis?.published
        ? moment().diff(moment(lastAnalysis.published), 'days') < 2
        : false
	*/
    const analysisInProgressDelay = analysisInProgress
      ? moment(lastAnalysis.estimatedPublished)
          .startOf('day')
          .diff(moment().startOf('day'), 'days') + 1
      : 0

    return (
      <>
        <div
          className="call-to-action incard bottom-0"
          style={{ flexGrow: 'unset' }}
        >
          {(lastAnalysisIsOneMonthOldAtLeast || !!lastAnalysis?.isInvalid) && (
            <span
              className={'action top-0 highlight fullwidth'}
              style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}
              onClick={() => this.props.history.push('/analyseMe')}
              data-testid="analyse-me"
            >
              <Icon
                style={{
                  top: '8px',
                }}
              >
                <img
                  src={pose}
                  alt={pose}
                  style={{
                    height: '42px',
                    borderRadius: '500rem',
                    border: '4px solid white',
                    padding: 4,
                  }}
                />
              </Icon>
              <em>{polyglot.t('home.action.analysis.get')}</em>
            </span>
          )}
          {analysisInProgress && (
            <AppCard
              heightOverride={'auto'}
              style={{
                textAlign: 'center',
                marginTop: 10,
                border: '2px solid red',
              }}
            >
              <div data-testid="card-analysis-inprogress">
                {polyglot.t(
                  analysisInProgressDelay < 0
                    ? 'home.action.analysis.inprogress.late'
                    : analysisInProgressDelay === 0
                    ? 'home.action.analysis.inprogress.0'
                    : analysisInProgressDelay === 1
                    ? 'home.action.analysis.inprogress.1'
                    : 'home.action.analysis.inprogress',
                  { days: analysisInProgressDelay }
                )}
              </div>
            </AppCard>
          )}
        </div>
      </>
    )
  }
}

GetAnalysis.propTypes = {
  subscriptionStatus: PropTypes.object,
  history: PropTypes.object,
  polyglot: PropTypes.object,
}

GetAnalysis = withPolyglot()(GetAnalysis)
export default GetAnalysis
