import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Container,
  Icon,
  Confirm,
  Button,
  Segment,
  Pagination,
  Dropdown,
} from 'semantic-ui-react'
import {
  /* getMyAnalysesCreated, */ getAllAnalyses,
  getTodoAnalyses,
  deleteAnalysis,
  searchMyAthletes,
} from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { withPolyglot } from '../vendor/polyglot-react'
import moment from 'moment'
import AppError from '../components/AppError'
import { Link } from 'react-router-dom'

const itemsPerPage = 20

class Analyses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [
        {
          dataField: 'estimatedPublished',
          text: 'Due Date',
          formatter: (cell, row) => {
            const delayDays = moment(cell)
              .startOf('day')
              .diff(moment().startOf('day'), 'days')
            return cell && (!row.isAvailable || row.toUpdate) ? (
              <Button
                as="a"
                href={'/analysis/' + row.id}
                color={
                  row.toUpdate
                    ? 'yellow'
                    : row.isInvalid
                    ? 'grey'
                    : delayDays <= 0
                    ? 'red'
                    : delayDays < 2
                    ? 'orange'
                    : 'green'
                }
              >
                {moment(cell).format(' ddd DD/MM/YYYY')} ({delayDays})
              </Button>
            ) : (
              ''
            )
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          formatter: (cell, row) => (
            <a href={'/analysis/' + row.id}>
              {' '}
              {moment(cell).format(' ddd DD/MM/YYYY kk:mm')}{' '}
            </a>
          ),
        },
        {
          dataField: 'user',
          text: 'User',
          formatter: (cell) => (cell && cell.name ? cell.name : ''),
        },
        /*
        {
          dataField: 'originalVideo',
          text: 'View',
          formatter: (cell, row) =>
            cell ? (
              <a href={cell}>
                <Icon name="eye" size="large" />{' '}
              </a>
            ) : (
              ''
            ),
        },
				*/
        {
          dataField: 'id',
          text: 'Frames To pose/ falling',
          formatter: (cell, row) => (
            <>{`${row.framesToPose} / ${row.framesFalling}`}</>
          ),
        },

        {
          dataField: 'custom1',
          text: 'Frames falling/after pose',
          formatter: (cell, row) =>
            `${row.framesFalling} / ${row.framesFromPose}`,
        },
        {
          dataField: 'custom',
          text: 'Ratio falling/after pose',
          formatter: (cell, row) =>
            `${(row.framesFalling / row.framesFromPose).toFixed(2)}`,
        },

        {
          dataField: 'globalScore',
          text: 'globalScore',
          formatter: (cell, row) => (cell ? Math.round(cell) : ''),
        },

        {
          dataField: 'focus',
          text: 'focus',
          formatter: (cell, row) => cell,
        },

        {
          dataField: 'isAvailable',
          text: 'Etat',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red',
          },
        },
        {
          dataField: '',
          text: '',
          formatter: (cell, row) => (
            <Fragment>
              <Icon
                name="trash alternate outline"
                onClick={() => this.open(row.id)}
              ></Icon>
            </Fragment>
          ),
        },
      ],
      data: [],
      hasError: 0,
      error: false,
      isLoading: true,
      confirmOpen: false,
      markedForDel: null,
      activePage: 1,
      totalPages: null,
    }
  }

  open = (id) => this.setState({ confirmOpen: true, markedForDel: id })
  close = () => this.setState({ confirmOpen: false })

  call_deleteAnalysis = async () => {
    let response = await deleteAnalysis(this.state.markedForDel)
    if (response.ok) {
      this.getData()
      this.close()
    }
  }

  async getData(newActivePage, user) {
    var response
    let { selectedUser, activePage } = this.state
    selectedUser = user ? user : selectedUser

    var when = this.props.match.params.when
    if (this.props.match.url.includes('todo')) {
      response = await getTodoAnalyses({
        page: newActivePage || activePage,
        itemsPerPage,
        when,
        selectedUser,
      })
    } else {
      response = await getAllAnalyses({
        page: newActivePage || activePage,
        itemsPerPage,
        selectedUser,
      })
    }

    if (response.ok) {
      const data = response.data

      // store the new state object in the component's state
      this.setState({
        tableData: data.rows,
        totalPages: data.count,
        isLoading: false,
        hasError: false,
        when,
      })
      this.setState({ redirectToReferrer: true })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  componentDidMount() {
    this.getData()
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i aria-hidden="true" className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }
  handlePaginationChange = (e, { activePage }) => {
    this.getData(activePage)
    this.setState({ activePage })
  }

  onSearchChange = async (e, value) => {
    let pattern = value.searchQuery
    if (pattern.length < 3) return
    let response = await searchMyAthletes(pattern)
    if (response.ok) {
      let rows = response.data.rows
      if (response.data.count === 0) {
        return
      }
      let userOptions = rows.map((x) => ({
        key: x.id,
        value: x.id,
        text: `${x.firstName} ${x.lastName} - ${x.email}`,
      }))
      this.setState({
        userOptions,
      })
    }
  }
  setSelectedUser = async (selectedUser) => {
    this.setState({ selectedUser })
    this.getData(this.state.activePage, selectedUser)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getData()
    }
  }

  render() {
    const { polyglot } = this.props
    const { totalPages } = this.state
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container style={{ position: 'relative' }}>
          <Confirm
            open={this.state.confirmOpen}
            onCancel={this.close}
            onConfirm={() => this.call_deleteAnalysis()}
            content={polyglot.t('generic.message.delete')}
          />

          <Dropdown
            onSearchChange={this.onSearchChange}
            search
            clearable
            selection
            options={this.state.userOptions}
            onChange={(e, data) => this.setSelectedUser(data.value)}
            value={this.state.selectedUser || ''}
            style={{ float: 'left' }}
          />
          <style>{`
						 .react-bootstrap-table{
							text-align:left;
							background-color:#fff
						 }
						 .modal .content
						 {
							 color:#000;
						 }

						`}</style>

          <Segment
            compact
            style={{ position: 'absolute', right: '20px', zIndex: 30 }}
          >
            <Link
              to="/analyses/todo"
              style={{ marginRight: 20, cursor: 'pointer' }}
            >
              {' '}
              Todo{' '}
            </Link>

            <Link to="/analyses/"> All </Link>
          </Segment>
          <hr style={{ clear: 'both' }} />
          {/*<AnalysisCreate history={this.props.history} />*/}
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                {this.state.tableData && (
                  <>
                    <Pagination
                      defaultActivePage={1}
                      totalPages={Math.floor(totalPages / itemsPerPage)}
                      onPageChange={this.handlePaginationChange}
                    />{' '}
                    <BootstrapTable
                      keyField="id"
                      data={this.state.tableData}
                      columns={this.state.tableColumns}
                      bordered={false}
                    />
                    <Pagination
                      defaultActivePage={1}
                      totalPages={Math.floor(totalPages / itemsPerPage)}
                      onPageChange={this.handlePaginationChange}
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

Analyses.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
}

Analyses = withPolyglot()(Analyses)
export default Analyses
