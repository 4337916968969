import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Header,
  Grid,
  Card,
  Dimmer,
  Loader,
  Tab,
  Feed,
  List,
  Container,
  Button,
  Segment,
  Checkbox,
  Icon,
  Dropdown,
} from 'semantic-ui-react'
import {
  getAthlete,
  getAthletesCourses,
  createCourse,
  updateHomeButtons,
  getFreeAccess,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import ReactGA from 'react-ga'
//import { RIEInput, RIETextArea  } from '@attently/riek'
import moment from 'moment'

import { toast } from 'react-toastify'

import AppError from '../components/AppError'

const freeOptions = [
  {
    text: '1 month - 1 analysis',
    key: '1month1analysis',
    value: JSON.stringify({ premiumDuration: 1, analysisNumber: 1 }),
  },

  {
    text: '6 month - 1 analysis',
    key: '6month1analysis',
    value: JSON.stringify({ premiumDuration: 6, analysisNumber: 1 }),
  },

  {
    text: '6 month - 4 analysis',
    key: '6month4analysis',
    value: JSON.stringify({ premiumDuration: 6, analysisNumber: 4 }),
  },
]
const homeButtonsList = [
  'todayTraining',
  'userTrainings',
  'bookingUrl',
  'userPerfs',
  'paceConverter',
  'runnerProfile',
]

class Athlete extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      players: [],

      athleteData: {},
      athleteCourses: [],
      homeButtons: [],
      freeOptions: freeOptions[0].value,
    }
    // this.call_updateClass=this.call_updateClass.bind(this)
  }
  componentDidMount() {
    if (this.call_getAthlete()) {
      this.call_getAthletesCourses()
      ReactGA.pageview(window.location.pathname + window.location.search)
      //  this.call_getDrills()
    }
  }

  async call_getAthlete() {
    const response = await getAthlete(this.props.match.params.id)
    if (response.ok) {
      this.setState({
        isLoading: false,
        hasError: false,
        athleteData: response.data,
        homeButtons: response.data.homeButtons,
      })
      // store the new state object in the component's state
      return true
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })

      return false
    }
  }

  handleFreeOptions = (e, { value }) => {
    this.setState({ freeOptions: value })
  }

  call_getFreeAccess = async () => {
    let athlete = this.state.athleteData
    let { id: userId, email } = athlete
    const { freeOptions } = this.state

    let { premiumDuration, analysisNumber } = JSON.parse(freeOptions)

    const response = await getFreeAccess({
      userId,
      email,
      premiumDuration,
      analysisNumber,
    })

    if (response.ok) {
      toast.success(this.props.polyglot.t('generic.message.done'))
    } else {
      // store the new state object in the component's state
      toast.error(this.props.polyglot.t('generic.message.error'))
      this.setState({ isLoading: false, hasError: true, error: response })
    }
  }

  async call_getAthletesCourses() {
    const response = await getAthletesCourses(this.props.match.params.id)
    if (response.ok) {
      let data = response.data.rows
      const newState = Object.assign({}, this.state, {
        athleteCourses: data,
        isLoading: false,
        hasError: false,
      })
      // store the new state object in the component's state
      this.setState(newState)
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  call_createCourse() {
    const { polyglot } = this.props
    const defaultParams = {
      label: polyglot.t('courses.new'),
      type: '',
      description: '',
      publicDescription: '',
      players: [this.state.athleteData.id],
    }
    createCourse(defaultParams)
      .then((res) => {
        if (res.ok) {
          const newId = res.data._id
          this.props.history.push('/class/' + newId)
        }
      })
      .catch()
  }
  toggleHomeButton = (button) => {
    var { homeButtons } = this.state
    if (homeButtons.indexOf(button) !== -1) {
      //present
      homeButtons = homeButtons.filter(function (ele) {
        return !!ele && ele.toString() !== button.toString()
      })
    } else {
      homeButtons.push(button)
    }

    this.setState({ homeButtons })
  }

  call_updateHomeButtons = () => {
    const { polyglot } = this.props
    const { homeButtons } = this.state

    this.setState({ isLoading: true })
    updateHomeButtons(this.state.athleteData.id, { homeButtons })
      .then((response) => {
        if (response.ok) {
          //this.setState(newState)
          toast.success(polyglot.t('generic.message.update'), {})
        } else {
          toast.error(polyglot.t('generic.message.error'), {})
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }
  render() {
    const { polyglot } = this.props

    const { athleteCourses, homeButtons } = this.state

    const coursesList = athleteCourses.map(function (course) {
      return (
        <List.Item key={course.id} className="pad-bottom-5">
          <List.Content floated="right">
            {moment(course.date).format('ddd DD/MM/YYYY')}
          </List.Content>
          <List.Content>
            <List.Header className={'bottom-5'}>
              {' '}
              <Link to={'/class/' + course.id}> {course.label} </Link>
              {/*  <Icon name='close' color='red' onClick={()=>_this.removeFromClass('drill',drill)}  style={{marginLeft:'10px'}} />*/}
            </List.Header>
            <List.Description
              className={'bottom-5'}
              style={{ fontWeight: '600' }}
            >
              {course.description}
            </List.Description>
          </List.Content>
          <List.Content>
            <List.Description>{course.publicDescription}</List.Description>
            {course.trainingLog && (
              <Segment>
                {course.trainingLog.done && (
                  <Fragment>
                    <b>{polyglot.t('traininglog.done.label')}</b> &nbsp;{' '}
                    {polyglot.t('traininglog.date.label')} &nbsp;
                    {moment(course.trainingLog.date).format('ddd DD/MM/YYYY')}
                  </Fragment>
                )}
                <br />

                <b>{polyglot.t('traininglog.comments.label')}</b>
                <br />

                {course.trainingLog.comment}
              </Segment>
            )}
          </List.Content>
        </List.Item>
      )
    })

    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <div>
              <AppError
                message={
                  this.state.error.problem ? this.state.error.problem : ''
                }
              />
            </div>
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;

            }

						`}</style>
              <Container>
                <Grid stackable columns={2} divided style={{ height: '100%' }}>
                  <Grid.Row>
                    <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                      <Header as="h2">{this.state.athleteData.name}</Header>
                      <Grid stackable columns={2}>
                        <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                          <Card className="card-description">
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('athlete.details')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              <Feed />
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                        <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                          <Dropdown
                            placeholder="Free Options"
                            selection
                            options={freeOptions}
                            onChange={this.handleFreeOptions}
                            value={this.state.freeOptions}
                          />
                          <Button primary onClick={this.call_getFreeAccess}>
                            Free
                          </Button>

                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('athlete.home')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              {homeButtonsList.map((x) => (
                                <div key={x} style={{ textAlign: 'left' }}>
                                  <Checkbox
                                    label={x}
                                    onChange={() => this.toggleHomeButton(x)}
                                    checked={
                                      homeButtons &&
                                      homeButtons.indexOf(x) !== -1
                                    }
                                  />
                                </div>
                              ))}
                              <Icon
                                name="save outline"
                                onClick={() => this.call_updateHomeButtons()}
                              />
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                      <Fragment>
                        <Tab
                          menu={{ secondary: true, pointing: true }}
                          panes={[
                            {
                              menuItem: polyglot.t('athlete.courses'),
                              render: () => (
                                <Tab.Pane attached={true}>
                                  <Button
                                    circular
                                    icon="plus"
                                    onClick={() => this.call_createCourse()}
                                  />

                                  <List divided style={{ textAlign: 'left' }}>
                                    {coursesList}
                                  </List>
                                </Tab.Pane>
                              ),
                            },
                          ]}
                        />
                      </Fragment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

Athlete.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

Athlete = withPolyglot()(Athlete)
export default Athlete
