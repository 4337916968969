import React, { Component, Suspense, lazy, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Loader,
  Container,
  Icon,
  Modal,
  Header,
  List,
  Transition,
  Segment,
  Button,
} from 'semantic-ui-react'
import { getMe, updateProfile } from '../services/Api'
import { COUNTRY_OPTIONS } from '../services/countriesData.js'
import { withPolyglot } from '../vendor/polyglot-react'
import ProfilePic from '../components/ProfilePic'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/fr'
import 'react-toastify/dist/ReactToastify.min.css'
import { Config } from '../config'
import ReactGA from 'react-ga'
import AppError from '../components/AppError'
import _ from 'lodash'
import avatar from '../images/avatar.png'

const Flag = lazy(() => import('react-world-flags'))
const { translations } = Config
var locale = localStorage.getItem('RWJlocale')
moment.locale(locale)

const options = []
// returns a list of months in the current locale (January, February, etc.)
moment.months().map((m, i) => options.push({ key: i, text: m, value: i }))

class Profile extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      userPicForceReload: '?v=' + localStorage.getItem('RWJProfilePicVersion'),
      language: window.localStorage.getItem('RWJlocale'),
      hasError: false,
      error: false,
      isLoading: true,
      modalCountryOpen: false,
      modalLanguageOpen: false,
      modalAgeOpen: false,
      forceRefresh: '',
      visible: false,
      isEdited: false,
      locale,
    }
    this.call_updateProfile = this.call_updateProfile.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleCountry = this.handleCountry.bind(this)
  }

  componentDidMount() {
    this.getCurrentUser()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleValue(e, key) {
    var user = this.state.user
    user[key] = e.target.value
    this.setState({ user: user })
  }
  handleRadio(e, { value }) {
    var user = this.state.user
    user['gender'] = value
    this.setState({ user: user })
  }
  handleCountry(e, { value }) {
    var user = this.state.user
    user['country'] = value
    this.setState({ user: user })
    this.handleCloseCountry()
  }
  setCountry = (value) => {
    var user = this.state.user
    user['country'] = value
    this.setState({ user: user })
    this.handleCloseCountry()
  }

  handleBirthMonth = (e, { value }) => {
    let { birthYear, birthDay } = this.state
    this.setState({ birthMonth: value })
    this.computeAge(birthYear, value, birthDay)
  }
  handleBirthYear = (e) => {
    let { birthMonth, birthDay } = this.state
    let value = e.target.value
    if (value.toString().length === 4 && moment().year() - value > 100) {
      this.setState({ birthYear: '' })
    } else {
      this.setState({ birthYear: value })
      this.computeAge(value, birthMonth, birthDay)
    }
  }
  handleBirthDay = (e) => {
    let { birthYear, birthMonth } = this.state
    let value = e.target.value

    if (value > 31) {
      this.setState({ birthDay: '' })
    } else {
      this.setState({ birthDay: e.target.value })
      this.computeAge(birthYear, birthMonth, value)
    }
  }
  computeAge = (birthYear, birthMonth, birthDay) => {
    if (birthYear && birthMonth && birthDay) {
      let birthdate = moment()
        .set({
          year: birthYear,
          month: birthMonth,
          date: birthDay,
        })
        .utc()
        .startOf('day')
      var user = this.state.user

      user['birthdate'] = birthdate.toDate()
      user['age'] = moment().diff(birthdate, 'years')

      this.setState({ user: user })
    }
  }

  handleOpenCountry = () => this.setState({ modalCountryOpen: true })
  handleCloseCountry = () => this.setState({ modalCountryOpen: false })

  handleOpenAge = () => this.setState({ modalAgeOpen: true })
  handleCloseAge = () => this.setState({ modalAgeOpen: false })

  async call_updateProfile() {
    const { polyglot } = this.props

    const { firstName, lastName, email } = this.state.user

    if (!lastName || !firstName || !email) {
      toast.error(polyglot.t('signup.message.fields.mandatory'))
      return
    }

    var body = {}
    body.firstName = this.state.user.firstName
    body.lastName = this.state.user.lastName
    body.email = this.state.user.email
    body.gender = this.state.user.gender
    body.country = this.state.user.country
    body.locale = this.state.user.locale
    body.birthdate = this.state.user.birthdate

    const response = await updateProfile(localStorage.RWJUserId, body)

    this.setState({ isLoading: true })
    if (response.ok) {
      const data = response.data
      const language = data.locale || 'en-us'
      window.localStorage.setItem('RWJlocale', language)
      this.setState({ isLoading: true })
      toast.success(polyglot.t('profile.message.update.done'), {
        //onClose: this.getCurrentUser()
        onClose: (window.location = '/profile'),
      })
    } else {
      const data = response.data
      var err_param = response.data.param
      var err_name = response.data.name
      if (err_param === 'email' && err_name === 'match') {
        data.message = 'signup.message.fields.email.wrong'
      } else if (err_param === 'email' && !err_name) {
        data.message = 'signup.message.fields.email.exists'
        this.setState({
          isLoading: false,
          //hasError: true,
          error: response,
        })
        toast.error(polyglot.t(data.message))
      }
    }
  }

  async getCurrentUser() {
    const response = await getMe()
    if (response.ok) {
      const data = response.data
      const responseUser = data
      let birthYear
      let birthMonth
      let birthDay

      if (responseUser.birthdate) {
        let birth = moment(responseUser.birthdate)
        birthYear = birth.year()
        birthMonth = birth.month()
        birthDay = birth.date()

        responseUser.age = moment().diff(birth, 'years')
      } else {
        responseUser.age = ' '
      }

      const newState = Object.assign({}, this.state, {
        user: responseUser,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
        visible: true,
        birthYear,
        birthMonth,
        birthDay,
        isEdited: false,
      })
      // store the new state object in the component's state
      this.setState(newState)
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  refreshAfterNewPic = (newPicUrl) => {
    this.setState({
      isLoading: true,
    })
    //degueu mais pas trouvé de solution
    this.props.history.go(0)
    const updatedUser = this.state.user
    updatedUser.picture = newPicUrl

    this.setState({
      user: updatedUser,
      // userPicForceReload: '?fr=' + Date.now().toString()
    })
  }
  render() {
    const { polyglot } = this.props
    const { user, isEdited, locale } = this.state
    polyglot.locale(locale)
    polyglot.replace(translations[locale])

    let currentPic =
      user.picture && user.picture.indexOf('identicon') === -1
        ? user.picture
        : avatar

    const pixCache =
      currentPic && currentPic.indexOf('data:image') === -1
        ? this.state.userPicForceReload
        : ''
    currentPic += pixCache

    const countryFromCode = (code) => {
      if (!code) {
        return ''
      }
      let country = _.find(COUNTRY_OPTIONS, { value: code })
      return country.text
    }

    return (
      <div className="page ">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {polyglot.t('profile.profile')}
        </Header>
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && !this.state.isLoading && (
          <Transition
            visible={this.state.visible}
            animation="scale"
            duration={100}
          >
            <div className="profile page">
              <style>{`
						.profilePicture img.ui.image{
							display: inline-block;
						}

						.ui.dimmer{
							background-color:#000;
						}
						.ui.modal .scrolling.content {
							max-height: calc(90vh);
							overflow: auto;
					}

						`}</style>

              <div className="profilePic-placeholder">
                {user.picture && (
                  <ProfilePic
                    currentPic={currentPic} // pour les identicons on ne rajoute rien
                    mustRefresh={this.refreshAfterNewPic}
                    editable={isEdited}
                    history={this.props.history}
                  />
                )}
              </div>
              <Container style={{ height: '100%', textAlign: 'left' }}>
                <Segment
                  className="dark-grey services-card top-30"
                  style={{ fontSize: '1.2em' }}
                >
                  <Form style={{ marginTop: '10px' }}>
                    <Form.Field>
                      <label> {polyglot.t('profile.firstname')} * </label>
                      <input
                        defaultValue={user.firstName}
                        onChange={(e) => this.handleValue(e, 'firstName')}
                        readOnly={!isEdited}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label> {polyglot.t('profile.lastname')} * </label>
                      <input
                        defaultValue={user.lastName}
                        onChange={(e) => this.handleValue(e, 'lastName')}
                        readOnly={!isEdited}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label> {polyglot.t('profile.email')} *</label>
                      <input
                        defaultValue={user.email}
                        onChange={(e) => this.handleValue(e, 'email')}
                        readOnly={!isEdited}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label> {polyglot.t('profile.gender')} </label>
                      <Form.Group
                        inline
                        style={{
                          marginTop: '5px',
                          paddingLeft: '1em',
                          borderBottom: '1px solid grey',
                        }}
                      >
                        {isEdited ? (
                          <Fragment>
                            <Form.Radio
                              label={polyglot.t('M')}
                              value="M"
                              checked={this.state.user.gender === 'M'}
                              onChange={this.handleRadio}
                              readOnly={!isEdited}
                            />
                            <Form.Radio
                              label={polyglot.t('F')}
                              value="F"
                              checked={this.state.user.gender === 'F'}
                              onChange={this.handleRadio}
                            />
                            <Form.Radio
                              label={polyglot.t('O')}
                              value="O"
                              checked={user.gender === 'O'}
                              onChange={this.handleRadio}
                            />
                          </Fragment>
                        ) : (
                          <span style={{ color: '#fff' }}>
                            {polyglot.t(user.gender)}
                          </span>
                        )}
                      </Form.Group>
                    </Form.Field>
                    <Form.Field>
                      <label> {polyglot.t('profile.age')} </label>
                      <Form.Group
                        inline
                        style={{
                          marginTop: '5px',
                          borderBottom: '1px solid grey',
                        }}
                      >
                        <Modal
                          trigger={
                            <div
                              className="color-white"
                              style={{
                                minWidth: '14em',
                                minHeight: '20px',
                                paddingLeft: '1em',
                              }}
                              onClick={
                                isEdited
                                  ? this.handleOpenAge
                                  : () => {
                                      return false
                                    }
                              }
                            >
                              {user.age}
                            </div>
                          }
                          open={this.state.modalAgeOpen}
                          onClose={this.handleCloseAge}
                          basic
                          centered={false}
                          className="profile-modal"
                        >
                          <Modal.Header className="discrete">
                            <Icon
                              name="arrow left"
                              style={{
                                color: 'grey',
                                position: 'absolute',
                                top: '-10px',
                              }}
                              onClick={this.handleCloseAge}
                            />
                          </Modal.Header>
                          <Modal.Content style={{ backgroundColor: '#000' }}>
                            <Form>
                              <Form.Group unstackable widths={3}>
                                <Form.Input
                                  value={this.state.birthYear || ''}
                                  placeholder={
                                    polyglot.t('profile.year') + ' (ex : 1999)'
                                  }
                                  onChange={(e) => this.handleBirthYear(e)}
                                />
                                <Form.Select
                                  fluid
                                  value={this.state.birthMonth || ''}
                                  options={options}
                                  placeholder={polyglot.t('profile.month')}
                                  onChange={this.handleBirthMonth}
                                />
                                <Form.Input
                                  value={this.state.birthDay || ''}
                                  placeholder={polyglot.t('profile.day')}
                                  onChange={(e) => this.handleBirthDay(e)}
                                />
                              </Form.Group>
                            </Form>
                          </Modal.Content>
                        </Modal>
                      </Form.Group>
                    </Form.Field>
                    <Form.Field>
                      <label> {polyglot.t('profile.country')} </label>
                      <Form.Group
                        inline
                        style={{
                          marginTop: '5px',
                          borderBottom: '1px solid grey',
                        }}
                      >
                        <Modal
                          trigger={
                            <div
                              className="color-white"
                              style={{ minWidth: '14em', paddingLeft: '1em' }}
                              onClick={
                                isEdited
                                  ? this.handleOpenCountry
                                  : () => {
                                      return false
                                    }
                              }
                            >
                              {countryFromCode(user.country)}
                              <Icon
                                className={isEdited ? 'inherit' : 'hidden'}
                                name="caret down"
                                style={{ float: 'right' }}
                              />
                            </div>
                          }
                          open={this.state.modalCountryOpen}
                          onClose={this.handleCloseCountry}
                          basic
                          centered={false}
                        >
                          <Modal.Header className="discrete">
                            <Icon
                              name="arrow left"
                              style={{
                                color: 'grey',
                                position: 'absolute',
                                top: '-10px',
                              }}
                              onClick={this.handleCloseCountry}
                            />
                          </Modal.Header>
                          <Modal.Content
                            scrolling
                            style={{ backgroundColor: '#000' }}
                          >
                            {' '}
                            <Suspense fallback={<Loader />}>
                              <List inverted verticalAlign="middle">
                                {COUNTRY_OPTIONS.map((country) => (
                                  <List.Item
                                    key={country.key}
                                    style={{ borderColor: 'grey' }}
                                    onClick={() =>
                                      this.setCountry(country.value)
                                    }
                                  >
                                    <List.Content>
                                      <List.Header
                                        style={{
                                          fontWeight: 'normal',
                                          textTransform: 'initial',
                                          padding: '10px 5px',
                                        }}
                                      >
                                        <Flag
                                          code={country.key}
                                          width="16"
                                          height="11"
                                        />{' '}
                                        <span
                                          style={{
                                            marginLeft: '5px',
                                            fontSize: '1.1rem',
                                          }}
                                        >
                                          {' '}
                                          {country.text}
                                        </span>
                                      </List.Header>
                                    </List.Content>
                                  </List.Item>
                                ))}
                              </List>
                            </Suspense>
                          </Modal.Content>
                        </Modal>
                      </Form.Group>
                    </Form.Field>

                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Button
                        circular
                        icon={isEdited ? 'save' : 'edit'}
                        className="bottom-10 app-button"
                        onClick={
                          isEdited
                            ? this.call_updateProfile
                            : () => this.setState({ isEdited: true })
                        }
                      />

                      <Button
                        circular
                        icon="close"
                        className={
                          'bottom-10 app-button ' +
                          (isEdited ? 'inherit' : 'hidden')
                        }
                        style={{ marginLeft: 10 }}
                        onClick={() => this.setState({ isEdited: false })}
                      />
                    </div>
                  </Form>
                </Segment>{' '}
              </Container>
            </div>
          </Transition>
        )}
      </div>
    )
  }
}
Profile.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

Profile = withPolyglot()(Profile)
export default Profile
