import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'

import { setViewedAnalysis } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import Highlighter from 'react-highlight-words'

import UserAnalysisDrawings from './UserAnalysisDrawings'
import {
  detailedAnalysisCriteriaMap,
  generateTextKeyForProperty,
  getFinalScore,
} from './Analysis/analysis.utils'

import AppCard from './AppCard'
import UserDetailedAnalysisTimeLine from './UserDetailedAnalysis.timeline'

const defaultTimeIndex = Object.keys(detailedAnalysisCriteriaMap)[0]
const defaultCriteria = detailedAnalysisCriteriaMap[defaultTimeIndex][0]

class UserDetailedAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTimeIndex: defaultTimeIndex,
      currentCriteria: defaultCriteria,
      noCacheKey: Math.random(),
      side: 'R',
    }
  }

  componentDidMount() {
    let { user, firstViewed, _id } = this.props.analysis
    const currentUser = localStorage.getItem('RWJUserId')
    if (user) {
      // anciennes vidéo créé sans user donc plante pour les anciens de rwj par ex
      const analysisUser = user._id
      if (
        !firstViewed &&
        _id &&
        currentUser.toString() === analysisUser.toString()
      ) {
        setViewedAnalysis(_id)
      }
    }
  }
  setCurrentTimeIndex = (currentTimeIndex) => {
    let currentCriteria = detailedAnalysisCriteriaMap[currentTimeIndex][0]
    let noCacheKey = Math.random()
    this.setState({ currentTimeIndex, currentCriteria, noCacheKey })
  }

  setCurrentCriteria = (currentCriteria) => {
    currentCriteria = `${currentCriteria}`
    this.setState({ currentCriteria })
  }

  generateTextForProp = (prop, side) => {
    const { polyglot } = this.props
    let textKeys = generateTextKeyForProperty(
      prop,
      this.props.analysis,
      side === 'R' ? 'right' : 'left'
    )

    return textKeys.map((criteria) => {
      let text = polyglot.t(criteria.key, {
        side: criteria.side
          ? polyglot.t('analysis.text.' + criteria.side)
          : null,
      })
      let color = ''
      ;['0', '1', '2', '3'].forEach((score) => {
        if (criteria.key.includes(score)) {
          color = ' color color' + score
        }
      })

      return (
        <div key={criteria.key} data-testid={`analysis-text-${prop}${side}`}>
          {color && <Icon className={color} name="circle" size="small" />}
          <Highlighter
            highlightClassName="side-highlight"
            searchWords={[
              ` ${polyglot.t('analysis.text.left')} `,
              ` ${polyglot.t('analysis.text.right')} `,

              ` ${polyglot.t('analysis.text.left')}, `,
              ` ${polyglot.t('analysis.text.right')}, `,
            ]}
            autoEscape={true}
            textToHighlight={text}
          />
        </div>
      )
    })
  }
  toggleSide = () => {
    this.setState({
      side: this.state.side === 'R' ? 'L' : 'R',
    })
  }
  borderColorForCriteria = (currentField) => {
    let color
    color = getFinalScore(currentField, this.props.analysis)

    if (currentField.includes('framesOnSupportAfterFalling')) {
      //    color = color === 0 ? 3 : 2
    }

    return color
  }

  render() {
    let { analysis, toggleshowDetailedAnalysis } = this.props
    let { currentTimeIndex, currentCriteria, noCacheKey, side } = this.state
    let currentField = currentCriteria + (side === 'L' ? 'L' : 'R')

    return (
      <div className="bottom-40">
        <style>{`
			mark.side-highlight {background-color:transparent !important;color:inherit;	text-transform:uppercase;font-weight:bold}
			.active{color:#fff; }
			.active.label, { font-size:1.1em}
			.back-arrow{display:none !important}

			`}</style>

        <Icon
          name={'close'}
          circular
          style={{
            position: 'absolute',
            top: 15,
            right: 5,
            fontSize: '1.2em',
          }}
          onClick={() => toggleshowDetailedAnalysis()}
        />
        <div
          style={{ textAlign: 'center' }}
          className={' top-20 bottom-10 pad-top-15'}
        >
          <UserAnalysisDrawings
            currentTimeIndex={currentTimeIndex + (side === 'L' ? 'L' : '')}
            drawingKey={currentField}
            automaticScoresData={analysis.automaticScoresData}
            drawingsTemplate={analysis.drawingsTemplate}
            analysisId={analysis.id || analysis._id}
            finalScore={getFinalScore(currentField, analysis)}
            noCacheKey={noCacheKey}
          />

          <UserDetailedAnalysisTimeLine
            currentTimeIndex={currentTimeIndex}
            setCurrentTimeIndex={this.setCurrentTimeIndex}
          />
          <div
            className="top-10 bottom-10"
            onClick={() => this.toggleSide()}
            data-testid="toggle-LR"
          >
            <span
              style={{
                fontSize: '0.9em',
              }}
            >
              <span
                style={{
                  width: 90,
                }}
                className={
                  'app-toggle small left ' + (side === 'L' && 'active')
                }
              >
                {this.props.polyglot.t('userAnalysisVideo.L')}
              </span>
              <span
                style={{
                  width: 90,
                }}
                className={
                  'app-toggle small right ' + (side === 'R' && 'active')
                }
              >
                {this.props.polyglot.t('userAnalysisVideo.R')}
              </span>
            </span>
          </div>
        </div>
        <div className="modal" style={{ textAlign: 'left' }}>
          <div className="section">
            {/**
			   *
            <div className="section-name ">
              {polyglot.t('analysis.text.standard')} :{' '}
              {polyglot.t(`detailedanalysis.${currentTimeIndex}`)}
            </div>

            <div className="intro"></div>

            <div className="your-execution">
              {polyglot.t('analysis.text.yourExecution')}
            </div>
			   */}

            <div className="criteria-text top-20 bottom-10">
              {detailedAnalysisCriteriaMap[currentTimeIndex].map((criteria) => (
                <AppCard
                  key={criteria}
                  heightOverride={'auto'}
                  onClick={() => this.setCurrentCriteria(criteria)}
                  additionalClasses={
                    criteria === currentCriteria
                      ? 'border-color' +
                        this.borderColorForCriteria(currentField)
                      : ''
                  }
                >
                  {this.generateTextForProp(criteria, side)}
                </AppCard>
              ))}
            </div>
            {/*
            <Embed
              active
              url="https://molokoy.io/fr/une-introduction-a-la-technique-de-course-partie-1/"
              iframe={{
                scrolling: true,
              }}
            />	*/}
          </div>
        </div>
      </div>
    )
  }
}

UserDetailedAnalysis.propTypes = {
  polyglot: PropTypes.object,
  analysis: PropTypes.object,
  analysisText: PropTypes.object,
  toggleshowDetailedAnalysis: PropTypes.func,
  originalVideo: PropTypes.string,
}
UserDetailedAnalysis = withPolyglot()(UserDetailedAnalysis)
export default UserDetailedAnalysis
