import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class AnalysisGauge extends PureComponent {
  render() {
    const { score, height, style, label, img } = this.props

    //https://mycolor.space/gradient3?ori=to+top&hex=%23E92C2C&hex2=%23BBEE00&hex3=%2321FF00&submit=submit

    //let gradientComplete = '#e92c2c,#f25b1a,#f68000,#f5a200,#efc300,#e4d200,#d4e100,#c1f006,#a8f100,#8bf300,#66f300,#20f400'.split(
    let gradientComplete =
      '#e92c2c, #ee5c14, #ed8200, #e7a400, #dbc400, #cfd300, #bde300, #a5f200, #92f500, #7af900, #5bfc00, #21ff00'.split(
        ','
      )
    //background-image: linear-gradient(to top, #e92c2c, #ee5c14, #ed8200, #e7a400, #dbc400, #cfd300, #bde300, #a5f200, #92f500, #7af900, #5bfc00, #21ff00);
    let gradient =
      score < 10
        ? `linear-gradient(to top, ${gradientComplete.slice(0, 2).join(', ')})`
        : score >= 10 && score <= 90
        ? `linear-gradient(to top, ${gradientComplete
            .slice(0, 1 + Math.round(score / 10))
            .join(', ')})`
        : `linear-gradient(to top, ${gradientComplete.join(', ')})`

    return (
      <div
        style={{
          height,
          width: 40,
          position: 'absolute',
          ...style,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: -25,
            left: '50%',
            transform: 'translateX(-50%)',
            textTransform: 'uppercase',
            fontSize: '0.9em',
          }}
        >
          {`${score}`}
          <span style={{ color: '#afadad', fontSize: '0.8em' }}>%</span>
        </div>
        <div
          style={{
            height,
            width: 10,
            backgroundColor: '#232222',
            borderRadius: 10,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <div
            style={{
              height: score < 60 ? `${score + 5}%` : `${score}%`,
              width: 10,
              backgroundImage: gradient,
              backgroundColor: 'transparent',
              borderRadius: 10,
              position: 'absolute',
              bottom: 0,
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: -50,
            left: '50%',
            transform: 'translateX(-50%)',
            textTransform: 'uppercase',
            fontSize: '0.8em',
            textAlign: 'center',
          }}
        >
          <img src={img} alt="" width="22px" style={{ opacity: 0.5 }} />
          {label}
        </div>
      </div>
    )
  }
}
AnalysisGauge.propTypes = {
  label: PropTypes.string,
  score: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  img: PropTypes.string,
}

export default AnalysisGauge
