import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Container, Header } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import { getAllMyPerfs } from '../services/Api'
import AppError from '../components/AppError'
import 'react-datepicker/dist/react-datepicker.css'
//import {Line} from 'react-chartjs-2';

import PerfDisplaySet from '../components/PerfDisplaySet'

import ReactGA from 'react-ga'

class UserPerfs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      allPerfsArray: [],
    }
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    await this.call_getAllMyPerfs()
    /* this.props.notificationRefresh()*/
  }

  call_getAllMyPerfs() {
    return getAllMyPerfs()
      .then((response) => {
        if (response.ok) {
          var allPerfsArray = response.data

          this.setState({
            allPerfsArray: allPerfsArray,
            hasError: false,
            isLoading: false,
          })
        }
      })
      .catch((err) => {
        const newState = Object.assign({}, this.state, {
          hasError: true,
          error: err,
        })
        // store the new state object in the component's state
        this.setState(newState)
      })
  }
  render() {
    const { polyglot } = this.props

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page ">
        <Container text>
          <style>{`
							.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
						`}</style>
          <Header as="h2" className="color-white center-text uppercase  top-10">
            {polyglot.t('home.perf.all')}
          </Header>
          <Divider />
          <div className="perfs-container">
            <PerfDisplaySet
              isLoading={this.state.isLoading}
              perfArray={this.state.allPerfsArray}
            />
          </div>
        </Container>
      </div>
    )
  }
}

UserPerfs.propTypes = {
  polyglot: PropTypes.object,
}

UserPerfs = withPolyglot()(UserPerfs)
export default UserPerfs
