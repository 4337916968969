import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Button } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import ReactGA from 'react-ga'
import SectionCard from '../components/SectionCard'

class AppError extends PureComponent {
  render() {
    const { polyglot } = this.props
    return (
      <Fragment>
        <style>{`
        .ui.container.app-error{
        margin:10px auto !important;
        height:100%;
        text-align:center;

        }

      `}</style>
        <Container className={'app-error'}>
          <SectionCard
            cardData={{
              key: 'error',
              titleOverride: polyglot.t('error.oops'),
              descriptionOverride: this.props.message
                ? this.props.message
                : polyglot.t('error.message'),
            }}
            asHeader={false}
          />

          <Button
            inverted
            color="red"
            style={{ marginTop: -40 }}
            onClick={() => {
              ReactGA.event({ category: 'Error', action: 'Refresh' })
              window.location.reload(true)
            }}
          >
            {polyglot.t('error.retry')}
          </Button>
        </Container>
      </Fragment>
    )
  }
}
AppError.propTypes = {
  polyglot: PropTypes.object,
  message: PropTypes.string,
}

AppError = withPolyglot()(AppError)
export default AppError
