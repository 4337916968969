import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'semantic-ui-react'

class AnalysisMenu extends Component {
  // default State object

  render() {
    const { activeItem, handleMenuClick } = this.props

    return (
      <Menu>
        <Menu.Item
          name="Video"
          code="video"
          active={activeItem === 'video'}
          onClick={handleMenuClick}
        />
        <Menu.Item
          name={`Fault & Focus`}
          code="focus"
          active={activeItem === 'focus'}
          onClick={handleMenuClick}
        />
        <Menu.Item
          name="Overview"
          code="overview"
          active={activeItem === 'overview'}
          onClick={handleMenuClick}
        />

        <Menu.Item
          name="Preview"
          code="preview"
          active={activeItem === 'preview'}
          onClick={handleMenuClick}
        />
      </Menu>
    )
  }
}
AnalysisMenu.propTypes = {
  handleMenuClick: PropTypes.func,
  activeItem: PropTypes.string,
}
export default AnalysisMenu
