import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header, Container, Icon } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

class Success extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactGA.event({ category: 'Service', action: 'Order validated' })
    this.setState({ isLoading: false })
  }

  render() {
    const { polyglot } = this.props
    return (
      <div className="page ">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {polyglot.t('success.page.title')}
        </Header>

        <Container style={{ height: '100%' }}>
          <Icon
            name="check circle outline"
            color="green"
            size="huge"
            style={{ marginTop: '30px', fontSize: '15rem' }}
          />
          <Container style={{ whiteSpace: 'pre-line' }}>
            <div
              className="top-20"
              style={{ fontSize: '2.5rem', color: '#fff' }}
            >
              {polyglot.t('success.page.content.title')}
            </div>
            <div className="top-10" style={{ fontSize: '1.1rem' }}>
              {polyglot.t('success.page.content')}
            </div>
          </Container>
          <div className="actions-placeholder">
            <div
              className="call-to-action"
              style={{
                height: 'auto',
                width: 'auto',
                maxWidth: '312px',
              }}
            >
              <Link to="/">
                <span className="action top-20 long no-icon standalone">
                  <span>{polyglot.t('analyseme.home.link')}</span>{' '}
                </span>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
Success.propTypes = {
  polyglot: PropTypes.object,
}

Success = withPolyglot()(Success)
export default Success
