import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table } from 'semantic-ui-react'
import { withPolyglot } from '../../vendor/polyglot-react'

class AnalysisOverview extends Component {
  leftRightFramesTableCells = (propName) => (
    <>
      {['R', 'L'].map((side) => (
        <Table.Cell textAlign="center" key={side}>
          {this.props.analysisData[propName + side] || ''}
        </Table.Cell>
      ))}
    </>
  )

  leftRightRadioTableCells = (propName) => (
    <>
      {['R', 'L'].map((side) => (
        <Table.Cell key={side}>
          {this.criteriaRadioReadGroup(propName, propName + side)}
        </Table.Cell>
      ))}
    </>
  )
  criteriaRadioReadGroup = (translationKey, propName) => (
    <span className={'color' + this.props.analysisData[propName]}>
      {this.props.polyglot.t(
        `analysis.execution.${translationKey}.${this.props.analysisData[propName]}`
      )}
    </span>
  )

  render() {
    const { polyglot } = this.props
    return (
      <>
        <style>{`
			.color1,
			.color0{color:red;}
			.color2{font-weight:bold;color:#a9851c;}
			.color3{color:green;}
			`}</style>
        <Card fluid>
          <Card.Content>
            <Card.Header>{polyglot.t('analysis.execution.pose')}</Card.Header>
          </Card.Content>
          <Card.Content style={{ textAlign: 'left' }}>
            <Table definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={6}>RIGHT</Table.HeaderCell>
                  <Table.HeaderCell width={6}>LEFT</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign="center">
                    {polyglot.t('analysis.execution.poseFramesToPose')}
                  </Table.Cell>
                  {this.leftRightFramesTableCells('framesToPose')}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.poseExecutionLanding')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('poseExecutionLanding')}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.poseExecutionKnee')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('poseExecutionKnee')}
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.poseExecutionVertical')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('poseExecutionVertical')}
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Content>
            <Card.Header>{polyglot.t('analysis.execution.fall')}</Card.Header>
          </Card.Content>
          <Card.Content style={{ textAlign: 'left' }}>
            <Table definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={6}>RIGHT</Table.HeaderCell>
                  <Table.HeaderCell width={6}>LEFT</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.fallExecutionFrames')}
                  </Table.Cell>
                  {this.leftRightFramesTableCells('framesFalling')}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.fallExecutionAlignment')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('fallExecutionAlignment')}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.fallExecutionTime')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('fallExecutionTime')}
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Content>
            <Card.Header>{polyglot.t('analysis.execution.pull')}</Card.Header>
          </Card.Content>
          <Card.Content style={{ textAlign: 'left' }}>
            <Table definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={6}>RIGHT</Table.HeaderCell>
                  <Table.HeaderCell width={6}>LEFT</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.pullExecutionTiming')}
                  </Table.Cell>
                  {this.leftRightFramesTableCells(
                    'framesOnSupportAfterFalling'
                  )}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.pullExecutionLegBent')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('pullExecutionLegBent')}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {polyglot.t('analysis.execution.pullExecutionDirection')}
                  </Table.Cell>

                  {this.leftRightRadioTableCells('pullExecutionDirection')}
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>
      </>
    )
  }
}
AnalysisOverview.propTypes = {
  analysisData: PropTypes.object,
  polyglot: PropTypes.object,
}
AnalysisOverview = withPolyglot()(AnalysisOverview)
export default AnalysisOverview
