import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Header, Card, Grid, Icon } from 'semantic-ui-react'

import { withPolyglot } from '../vendor/polyglot-react'
import { Line } from 'react-chartjs-2'
import AppError from '../components/AppError'
import GetAnalysis from '../components/getAnalysis'
import moment from 'moment'
import 'moment/locale/fr'
import { drillsByMonth, getMyAnalyses } from '../services/Api'
import 'chartjs-plugin-datalabels'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import AppCard from '../components/AppCard'

var demo = false /*true*/
//demo = true

var locale = localStorage.getItem('RWJlocale')
let RWJHideDrillsStats = window.localStorage.getItem('RWJHideDrillsStats')
moment.locale(locale)
// returns a list of months in the current locale (January, February, etc.)
const defaultLineColor = '#fff'
class UserStats extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      analyses: [],
      hasError: false,
      error: false,
      isLoading: true,
      activeIndex: 0,
      statsData: [],
      statsDataMaxValue: 0,
      progress: null,
      activeLine: 'pose',
      activeGlobalScore: true,
      displayDrills: null,
    }
    this.scrollerRef = React.createRef()
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    var [drillsByMonth, myAnalyses] = await Promise.all([
      this.call_drillsByMonth(),
      this.call_getMyAnalyses(),
    ])

    if (demo) {
      drillsByMonth = [
        { sum: 130, name: moment().format('YYYY-MM') },
        { sum: 120, name: moment().subtract(1, 'months').format('YYYY-MM') },
        { sum: 80, name: moment().subtract(2, 'months').format('YYYY-MM') },
        { sum: 60, name: moment().subtract(3, 'months').format('YYYY-MM') },
        { sum: 40, name: moment().subtract(4, 'months').format('YYYY-MM') },
      ]

      myAnalyses = [
        {
          date: moment().toISOString(),
          globalScore: 74.33333333333333,
          fallScore: 80,
          poseScore: 80,
          pullScore: 85,
          cadence: 180,
        },
        {
          date: moment().subtract(1, 'months').toISOString(),
          globalScore: 72.33333333333333,
          fallScore: 80,
          poseScore: 75,
          pullScore: 85,
          cadence: 180,
        },

        {
          date: moment().subtract(2, 'months').toISOString(),
          globalScore: 65.33333333333333,
          fallScore: 68,
          poseScore: 60,
          pullScore: 60,
          cadence: 180,
        },
        {
          date: moment().subtract(3, 'months').toISOString(),
          globalScore: 42.33333333333333,
          fallScore: 40,
          poseScore: 40,
          pullScore: 40,
          cadence: 180,
        },
        {
          date: moment().subtract(4, 'months').toISOString(),
          globalScore: 35.33333333333333,
          fallScore: 30,
          poseScore: 40,
          pullScore: 30,
          cadence: 180,
        },
      ]
    }

    const finalData = this.agregateStats(drillsByMonth, myAnalyses)
    let displayDrills = RWJHideDrillsStats ? false : true

    if (finalData !== false) {
      const { statsData, statsDataMaxValue, progress } = finalData
      this.setState({
        isLoading: false,
        hasError: false,
        statsData,
        statsDataMaxValue,
        myAnalyses,
        progress,
        displayDrills,
      })

      setTimeout(() => {
        this.setState({ activeLine: 'fall' })
      }, 2000)
      setTimeout(() => {
        this.setState({ activeLine: 'pull' })
      }, 3000)
      setTimeout(() => {
        this.setState({ activeLine: 'pose' })
      }, 4000)
    } else {
      this.setState({
        isLoading: false,
        hasError: false,
      })
    }
  }

  computeProgress(ob1, ob2) {
    //a most recent, b older
    let sum = {}
    Object.keys(ob1).forEach((key) => {
      if (ob2.hasOwnProperty(key)) {
        sum[key] = ob1[key] - ob2[key]
      }
    })
    return sum
  }

  extractScores(analysisData) {
    return {
      globalScore: this.round(analysisData.globalScore),
      poseScore: this.round(analysisData.poseScore),
      fallScore: this.round(analysisData.fallScore),
      pullScore: this.round(analysisData.pullScore),
    }
  }

  agregateStats = (drillsData, analysesData) => {
    const months = moment.monthsShort()
    let progress = null
    const analysesDataAvailable = analysesData && analysesData.length !== 0
    const drillsDataAvailable = drillsData && drillsData.length !== 0

    if (!drillsDataAvailable && !analysesDataAvailable) {
      // this.setState({ statsData: false })
      return false
    }
    let firstDataMonth = moment().format('MM')
    let firstDataYear = moment().format('YYYY')

    if (drillsDataAvailable) {
      firstDataMonth = drillsData[0].name.split('-')[1]
      firstDataYear = drillsData[0].name.split('-')[0]
    }

    if (analysesDataAvailable) {
      const lastAnalysis = this.extractScores(analysesData[0])
      let previousAnalysis
      if (analysesData.length > 1) {
        previousAnalysis = this.extractScores(analysesData[1])
      } else {
        previousAnalysis = lastAnalysis
      }

      analysesData = analysesData.reverse()
      progress = this.computeProgress(lastAnalysis, previousAnalysis)
      if (
        moment(analysesData[0].date).isBefore(
          firstDataYear + '-' + firstDataMonth + '-01'
        )
      ) {
        firstDataMonth = moment(analysesData[0].date).format('MM')
        firstDataYear = moment(analysesData[0].date).format('YYYY')
      }
    }

    let currentMonth = moment().format('MM')
    let currentYear = moment().format('Y')
    let m = firstDataMonth
    let y = firstDataYear
    let statsData = []
    let statsDataMaxValue = 0

    while (m <= currentMonth || y < currentYear) {
      if (m === 13) {
        // on a changé d'année à l'iteration précédente
        m = 1
        y = parseInt(y) + 1
      }
      let monthName = months[m - 1]
      monthName = monthName.substring(0, 3)
      monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1)

      let statObj = { monthName }
      let lookingFor =
        y.toString() +
        '-' +
        (m.toString().length === 1 ? '0' + m.toString() : m.toString())

      let drillsDataMonth = drillsData.find((x) => x.name === lookingFor)
      statObj.name = lookingFor // on cree quand meme l'objet pour pouvoir l'utiliser pour les analyses
      if (drillsDataMonth) {
        let sum = drillsDataMonth.sum
        statsDataMaxValue = statsDataMaxValue < sum ? sum : statsDataMaxValue
      } else {
        drillsDataMonth = { name: lookingFor, sum: 0, monthName }
      }
      statObj = Object.assign(statObj, drillsDataMonth)
      statsData.push(statObj)
      m++
    }

    if (analysesDataAvailable) {
      let statObj = {}

      for (const d of analysesData) {
        let lookingFor = moment(d.date).format('YYYY-MM')
        statObj = statsData.find((x) => x.name === lookingFor)
        if (statObj) {
          statObj.cadence = d.cadence
          statObj.poseScore = d.poseScore
          statObj.fallScore = d.fallScore
          statObj.pullScore = d.pullScore
          statObj.globalScore = d.globalScore
        }
      }
    }

    return {
      statsData,
      statsDataMaxValue,
      progress,
    }
  }

  call_drillsByMonth = async () => {
    const response = await drillsByMonth()
    if (response.ok) {
      return response.data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  call_getMyAnalyses = async () => {
    const response = await getMyAnalyses()
    if (response.ok) {
      const data = response.data
      if (!data || !data.length) {
        // this.setState({ statsData: false })
        return
      }

      return response.data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  round = (score) => Math.round(score)
  computeGraphDimensions = () => {
    let width = window.innerWidth
    let height = window.innerHeight

    width = width < 700 ? width : 700

    height = height / 3

    let nbmonths = this.state.statsData.length
    let monthsVisible = 6

    if (nbmonths > monthsVisible) {
      width = (nbmonths * width) / monthsVisible
    }
    width = width - 58
    return { width, height }
  }

  setLineColor = (line) => {
    return line === this.state.activeLine ? defaultLineColor : 'transparent'
  }

  displayDrills = () => {
    let displayDrills = !this.state.displayDrills

    this.setState({ displayDrills })
    if (displayDrills) {
      window.localStorage.removeItem('RWJHideDrillsStats')
    } else {
      window.localStorage.setItem('RWJHideDrillsStats', true)
    }
  }
  activateLine = (line) => {
    if (line === this.state.activeLine) {
      this.setState({ activeLine: null })
    } else {
      this.setState({ activeLine: line })
    }
  }

  render() {
    const { polyglot } = this.props
    const {
      statsData,
      statsDataMaxValue,
      progress,
      activeGlobalScore,
      displayDrills,
    } = this.state

    const gradientStart = 'rgba(255, 0, 0, 0)'
    const gradientEndColor = 'rgba(255, 0, 0, 0.5)'
    const gradientMidColor = 'rgba(255, 0, 0, 0.25)'
    const scoreColor = 'rgb(65, 152, 232)'
    const green = 'rgb(143, 214, 1)'
    const red = 'rgb(254, 92, 37)'
    const white = 'rgb(200, 200, 200)'

    const analysisData = (canvas) => {
      const ctx = canvas.getContext('2d')
      const gradient = ctx.createLinearGradient(0, 0, 0, 300)
      gradient.addColorStop(0, gradientEndColor)
      gradient.addColorStop(0.5, gradientMidColor)
      gradient.addColorStop(1, gradientStart)

      if (!statsData || !statsData.length) {
        return {}
      }
      return {
        labels: statsData.map((x) => x.monthName),
        datasets: [
          {
            label: 'globalScore',
            yAxisID: 'A',
            type: 'line',
            datalabels: {
              color: 'transparent',
            },
            borderColor: activeGlobalScore ? scoreColor : 'transparent',
            pointBackgroundColor: activeGlobalScore ? '#fff' : 'transparent',
            pointBorderColor: activeGlobalScore ? scoreColor : 'transparent',
            pointBorderWidth: 2,
            //   showLine: false, // no line shown
            data: statsData.map((x) => this.round(x.globalScore)),
          },
          {
            label: 'drillsByMonth',
            yAxisID: 'B',
            type: 'line',
            datalabels: {
              align: 'top',
              offset: 1,
              color: displayDrills ? 'rgb(200, 200, 200)' : 'transparent',
            },
            borderWidth: 2,
            maxBarThickness: 30,
            borderColor: displayDrills ? '#911215' : 'transparent', //red
            pointBackgroundColor: displayDrills ? 'white' : 'transparent',
            pointBorderColor: displayDrills ? '#911215' : 'transparent', //red
            pointBorderWidth: 1,
            backgroundColor: displayDrills ? gradient : 'transparent', // gradient,
            data: statsData.map((x) => x.sum),
          },
          {
            label: 'pose',
            yAxisID: 'A',
            datalabels: {
              color: 'transparent',
            },
            borderWidth: 1,
            borderColor: this.setLineColor('pose'),
            pointBackgroundColor: this.setLineColor('pose'),
            pointBorderColor: this.setLineColor('pose'),
            pointBorderWidth: 0,
            data: statsData.map((x) => this.round(x.poseScore)),
          },
          {
            label: 'fall',
            yAxisID: 'A',
            datalabels: {
              color: 'transparent',
            },
            borderWidth: 1,
            borderColor: this.setLineColor('fall'),
            pointBackgroundColor: this.setLineColor('fall'),
            pointBorderColor: this.setLineColor('fall'),
            pointBorderWidth: 0,
            data: statsData.map((x) => this.round(x.fallScore)),
          },
          {
            label: 'pull',
            yAxisID: 'A',
            datalabels: {
              color: 'transparent',
            },
            borderWidth: 1,
            borderColor: this.setLineColor('pull'),
            pointBackgroundColor: this.setLineColor('pull'),
            pointBorderColor: this.setLineColor('pull'),
            pointBorderWidth: 0,
            data: statsData.map((x) => this.round(x.pullScore)),
          },
        ],
      }
    }

    const graphDimensions = this.computeGraphDimensions()
    const graphWidth = graphDimensions.width
    const graphHeight = graphDimensions.height

    return (
      <div className="page ">
        <Header
          as="h2"
          className="color-white uppercase  top-10"
          style={{
            position: 'relative',
            paddingBottom: 15,
            textAlign: 'center',
            fontSize: '0.8em',
          }}
        >
          <Link
            style={{
              width: 140,
              fontSize: '0.9em',
            }}
            className="app-toggle left"
            to="/userAnalyses"
            data-testid="useranalyses-link"
          >
            {polyglot.t('useranalyses.analysis')}
          </Link>

          <span
            style={{
              width: 140,
              fontSize: '0.9em',
            }}
            className="app-toggle active right"
          >
            {polyglot.t('userstats.stats')}
          </span>
        </Header>
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}

        {!this.state.hasError && !this.state.isLoading && (
          <div>
            <style>{`
								 .page	.segment {
									border-top: 1px solid grey !important;
									border-right: 1px solid grey !important;
									border-left: 1px solid grey !important;

									border-bottom: 1px solid grey!important;
									/*background: rgba(0, 0, 0, 0.6) !important;*/
									border-radius: 0.28571429rem;
									}
									.ui.celled.grid>.column:not(.row), .ui.celled.grid>.row>.column {
										box-shadow: -1px 0 0 0 #403f3f;
								}
								.ui.celled.grid>.row {
									box-shadow: 0 -1px 0 0 #403f3f;
							}
						`}</style>
            <div>
              <div className="card stats pad-bottom-15 top-20">
                {/**
								 *
                <Container
                  className={'top-0'}
                  style={{ fontSize: '1.0em', color: '#ababab' }}
                >
                  {polyglot.t('userstats.score.drills')}
                </Container>
								 */}
                {(!statsData || statsData.length === 0) && (
                  <div className="top-10">
                    <GetAnalysis {...this.props} />
                  </div>
                )}
                {statsData && statsData.length !== 0 && (
                  <Fragment>
                    <Card.Content className="top-10">
                      <div style={{ overflowX: 'auto' }} ref={this.scrollerRef}>
                        <Card.Description>
                          <div
                            style={{
                              width: graphWidth + 'px',
                              height: graphHeight + 'px',
                            }}
                          >
                            <Line
                              width={graphWidth}
                              height={graphHeight}
                              data={analysisData}
                              legend={{
                                display: false,
                              }}
                              options={{
                                plugins: { datalabels: true },
                                spanGaps: true,
                                tooltips: {
                                  enabled: true,
                                  callbacks: {
                                    title: (tooltipItem) =>
                                      tooltipItem[0].value,
                                    label: () => false,
                                  },
                                  titleFontColor: scoreColor,
                                },
                                animation: {
                                  onComplete: () => {
                                    this.scrollerRef.current.scrollTo(2000, 0)
                                  },
                                },
                                scales: {
                                  xAxes: [
                                    {
                                      gridLines: {
                                        color: 'rgba(171,171,171,0.1)',
                                      },
                                      ticks: {
                                        fontColor: '#CCC', // this here
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      //score
                                      display: true,
                                      id: 'A',
                                      position: 'right',
                                      ticks: {
                                        fontColor: scoreColor,
                                        stepSize: 25,
                                        max: 100,
                                        beginAtZero: true,
                                        callback: function (value) {
                                          value =
                                            value === 100 ? '100 %' : value
                                          return value
                                        },
                                      },
                                      gridLines: {
                                        zeroLineWidth: 0,
                                        color: 'transparent',
                                      },
                                    },
                                    {
                                      //drills
                                      display: false,
                                      id: 'B',
                                      ticks: {
                                        fontColor: 'transparent',
                                        suggestedMin: 10,
                                        suggestedMax:
                                          statsDataMaxValue +
                                          (statsDataMaxValue * 10) / 100,
                                        beginAtZero: true,
                                      },
                                      gridLines: {
                                        zeroLineWidth: 0,
                                        color: 'transparent',
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </div>
                        </Card.Description>
                      </div>
                    </Card.Content>
                    <Card.Content
                      style={{
                        fontSize: '0.9em',
                        letterSpacing: '0.02em',
                        height: '5em',
                      }}
                    >
                      <div
                        style={{
                          width: '66%',
                          textAlign: 'left',
                          margin: '0 auto',
                          marginBottom: 5,
                        }}
                        onClick={() => this.displayDrills()}
                      >
                        <div
                          style={{
                            width: 30,

                            marginRight: 10,
                            display: 'inline-block',
                          }}
                        >
                          <Icon
                            inverted
                            name={'toggle on'}
                            flipped={displayDrills ? null : 'horizontally'}
                            size="big"
                            style={{
                              verticalAlign: 'middle',
                              margin: '0px auto',
                              color: displayDrills
                                ? '#911215'
                                : 'rgba(255, 255, 255, 0.30)',
                              width: 22,
                              display: 'block',
                              fontSize: '1.8em',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'text-bottom',
                            color: '#ababab',
                          }}
                        >
                          {polyglot.t('userstats.drills.performed.display')}
                        </div>
                      </div>

                      {activeGlobalScore && (
                        <div
                          style={{
                            width: '66%',
                            textAlign: 'left',
                            margin: '0 auto',
                          }}
                        >
                          <div
                            style={{
                              border: '1px solid ' + scoreColor,
                              width: 30,
                              height: 1,
                              verticalAlign: 'super',
                              // backgroundColor: '1px solid rgb(162, 36, 173)',
                              marginRight: 10,
                              display: 'inline-block',
                            }}
                          ></div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'text-bottom',
                              color: '#ababab',
                            }}
                          >
                            {polyglot.t('userstats.technical.score')}
                          </div>
                        </div>
                      )}
                      {this.state.activeLine && (
                        <div
                          style={{
                            width: '66%',
                            textAlign: 'left',
                            margin: '0 auto',
                          }}
                        >
                          <div
                            style={{
                              border: '1px solid ' + defaultLineColor,
                              width: 30,
                              height: 1,
                              verticalAlign: 'super',
                              marginRight: 10,
                              display: 'inline-block',
                            }}
                          ></div>
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'text-bottom',
                              color: '#ababab',
                            }}
                          >
                            {polyglot.t(
                              'userstats.' + this.state.activeLine + '.score'
                            )}
                          </div>
                        </div>
                      )}
                    </Card.Content>
                  </Fragment>
                )}

                <AppCard style={{ textAlign: 'center' }} heightOverride={130}>
                  <Container style={{ fontSize: '1.0em', color: '#ababab' }}>
                    {polyglot.t('userstats.evolution')}
                  </Container>

                  <Grid celled="internally" inverted className={'top-20'}>
                    <Grid.Row>
                      <Grid.Column
                        width={16}
                        className="stat-card"
                        style={{
                          height: '5rem',
                          paddingTop: activeGlobalScore ? '0em' : '0.5em',
                          fontSize: activeGlobalScore ? '1.1em' : '0.9em',
                        }}
                        onClick={() =>
                          this.setState({
                            activeGlobalScore: !activeGlobalScore,
                          })
                        }
                      >
                        <div
                          style={{
                            fontSize: '2.4em',
                          }}
                        >
                          {progress && (
                            <Fragment>
                              <span
                                style={{
                                  fontSize: '0.7em',
                                  color: !activeGlobalScore
                                    ? 'grey'
                                    : progress['globalScore'] > 0
                                    ? green
                                    : progress['globalScore'] < 0
                                    ? red
                                    : white,
                                }}
                                className="stat-card-icon"
                              >
                                <Icon
                                  name={
                                    progress.globalScore > 0
                                      ? 'arrow alternate circle up outline'
                                      : progress.globalScore < 0
                                      ? 'arrow alternate circle down outline'
                                      : 'arrow alternate circle right outline'
                                  }
                                />
                              </span>
                              <span
                                style={{
                                  color: !activeGlobalScore ? 'grey' : white,
                                }}
                              >
                                <Icon
                                  name={
                                    progress['globalScore'] < 0
                                      ? 'minus'
                                      : progress['globalScore'] > 0
                                      ? 'plus'
                                      : ''
                                  }
                                  size="mini"
                                  style={{
                                    verticalAlign: 'middle',
                                    paddingBottom: '1.3em',
                                    marginRight:
                                      progress['globalScore'] !== 0
                                        ? '-1px'
                                        : '-12px',
                                  }}
                                />
                                {Math.abs(this.round(progress.globalScore))}
                                <span style={{ fontSize: '0.5em' }}>%</span>
                              </span>
                            </Fragment>
                          )}
                          {!progress && <span>-</span>}
                        </div>
                        <div
                          className="stat-card-label"
                          style={{
                            color: !activeGlobalScore ? 'grey' : scoreColor,
                          }}
                        >
                          {polyglot.t('userstats.technical.score')}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </AppCard>
                <AppCard style={{ textAlign: 'center' }} heightOverride={140}>
                  <Grid celled="internally" inverted>
                    <Grid.Row>
                      {['pose', 'fall', 'pull'].map((x) => {
                        return (
                          <Grid.Column
                            key={x}
                            width={x === 'fall' ? '6' : '5'}
                            className={'stat-card stat-card-' + x}
                            style={{
                              fontSize:
                                this.state.activeLine === x
                                  ? '1.3rem'
                                  : '1.1rem',
                              padding:
                                this.state.activeLine === x ? '10px' : '15px',
                            }}
                            onClick={() => this.activateLine(x)}
                          >
                            <div
                              className="stat-card-label"
                              style={{
                                fontSize: '0.7em',
                                color:
                                  this.state.activeLine === x ? '#fff' : 'grey',
                              }}
                            >
                              {polyglot.t('userstats.' + x + '.score')}
                            </div>
                            <div>
                              {progress && (
                                <Fragment>
                                  <div
                                    style={{ fontSize: '0.7em' }}
                                    className="stat-card-icon"
                                  >
                                    <Icon
                                      name={
                                        progress[x + 'Score'] > 0
                                          ? 'arrow alternate circle up outline'
                                          : progress[x + 'Score'] < 0
                                          ? 'arrow alternate circle down outline'
                                          : 'arrow alternate circle right outline'
                                      }
                                      size="big"
                                      style={{
                                        color:
                                          this.state.activeLine !== x
                                            ? 'grey'
                                            : progress[x + 'Score'] > 0
                                            ? green
                                            : progress[x + 'Score'] < 0
                                            ? red
                                            : white,
                                      }}
                                    />
                                  </div>
                                  <span
                                    style={{
                                      color:
                                        this.state.activeLine === x
                                          ? '#fff'
                                          : 'grey',
                                    }}
                                  >
                                    <Icon
                                      name={
                                        progress[x + 'Score'] < 0
                                          ? 'minus'
                                          : progress[x + 'Score'] > 0
                                          ? 'plus'
                                          : ''
                                      }
                                      size="mini"
                                      style={{
                                        verticalAlign: 'middle',
                                        paddingBottom: '1.3em',
                                        marginRight: '-1px',
                                      }}
                                    />
                                    <span
                                      className="stat-card-score top-5"
                                      style={{ display: 'inline-block' }}
                                    >
                                      {Math.abs(
                                        this.round(progress[x + 'Score'])
                                      )}
                                      <span style={{ fontSize: '0.6em' }}>
                                        %
                                      </span>
                                    </span>
                                  </span>
                                </Fragment>
                              )}
                              {!progress && <span>-</span>}
                            </div>
                          </Grid.Column>
                        )
                      })}
                    </Grid.Row>
                  </Grid>
                </AppCard>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
UserStats.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

UserStats = withPolyglot()(UserStats)
export default UserStats
