import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withPolyglot } from '../vendor/polyglot-react'
import Highlighter from 'react-highlight-words'
import { Icon } from 'semantic-ui-react'

export class AnalysisText extends Component {
  render() {
    const { polyglot, analysisText } = this.props
    if (!this.props.analysisText) return null

    let criterias = Object.keys(analysisText)
    if (this.props.showOnlyCriteria) {
      criterias = [this.props.showOnlyCriteria]
    }

    return (
      <div>
        <style>{`

            mark.side-highlight {
				background-color:transparent !important;
				color:inherit;
				text-transform:uppercase;
				font-weight:bold
			}
			.criteria-text{
				margin-bottom:10px;
			}

		`}</style>
        {!!analysisText &&
          !!Object.keys(analysisText).length &&
          criterias.map((crit) => {
            return (
              <div key={crit} className={'section ' + crit}>
                <div className="sectionName ">
                  {polyglot.t('analysis.text.standard')} :{' '}
                  {polyglot.t('analysis.text.' + crit)}
                </div>
                {analysisText[crit].map((criteria) => {
                  let text = polyglot.t(criteria.key, {
                    side: criteria.side
                      ? polyglot.t('analysis.text.' + criteria.side)
                      : null,
                  })

                  let color = ''
                  ;['0', '1', '2', '3'].forEach((score) => {
                    if (criteria.key.includes(score)) {
                      color = ' color color' + score
                    }
                  })

                  if (text) {
                    text = (
                      <div
                        key={criteria.key}
                        className={
                          criteria.key.indexOf('Intro') !== -1
                            ? 'intro'
                            : criteria.key.includes('yourExecution')
                            ? 'your-execution'
                            : 'criteria-text '
                        }
                        data-testid={criteria.key}
                      >
                        {color && (
                          <Icon className={color} name="circle" size="small" />
                        )}

                        <Highlighter
                          highlightClassName="side-highlight"
                          searchWords={[
                            ` ${polyglot.t('analysis.text.left')} `,
                            ` ${polyglot.t('analysis.text.right')} `,
                          ]}
                          autoEscape={true}
                          textToHighlight={text}
                        />
                      </div>
                    )
                  }

                  return text
                })}
              </div>
            )
          })}
      </div>
    )
  }
}
AnalysisText.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  analysisText: PropTypes.object,
  showOnlyCriteria: PropTypes.string,
}

AnalysisText = withPolyglot()(AnalysisText)
export default AnalysisText
