import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Grid, Segment, Divider, Header } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { passwordChange, getMe } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import ReactGA from 'react-ga'
class PasswordChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      old_password: '',
      password: '',
      password_confirm: '',
      redirectToReferrer: false,
      hasError: false,
      error: false,
      isLoading: false,
      user: {},
    }
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const user = await this.getCurrentUser()

    this.setState({ user })
  }
  async getCurrentUser() {
    const response = await getMe()
    if (response.ok) {
      return response.data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  call_passwordChange = async () => {
    const { polyglot } = this.props
    let canSubmit = this.state.canSubmit
    const { password, old_password, user } = this.state
    if (!old_password || !password) {
      toast.error(polyglot.t('signup.message.fields.mandatory'))
      return
    }
    if (!canSubmit) {
      return
    }

    //export const passwordChange = (email, userId, oldPassword, password) => {
    //	const userId=localStorage.getItem(RWJUserId,)

    const userId = user.id
    const email = user.email

    const response = await passwordChange(email, userId, old_password, password)
    if (response.ok) {
      toast.success(
        polyglot.t('generic.message.done'),

        {
          autoClose: 3000,
          onClose: () =>
            this.setState({
              hasError: false,
              redirectToReferrer: true,
            }),
        }
      )
    } else {
      var data = response.data

      if (data) {
        var err_param = data.param
        var err_name = data.name
        if (err_param === 'password' && err_name === 'minlength') {
          data.message = 'signup.message.fields.password.short'
        }
      }

      if (!data) {
        data = { message: 'passwordChange.error' }
      }

      toast.error(polyglot.t(data.message))
      this.setState({
        hasError: true,
        error: response,
      })
    }
  }
  checkPassword() {
    if (this.state.password.length < 6) {
      this.setState({ passwordToShort: true, canSubmit: false })
    } else {
      this.confirmPassword()
    }
  }

  confirmPassword() {
    if (this.state.password !== this.state.password_confirm) {
      this.setState({ passwordsNoMatch: true, canSubmit: false })
    }
  }

  resetCheckPassword() {
    this.setState({
      passwordToShort: false,
      canSubmit: true,
    })
  }
  resetConfirmPassword() {
    this.setState({
      passwordsNoMatch: false,
      canSubmit: true,
    })
  }

  handleValue(e, key) {
    this.setState({ ...this.state, [key]: e.target.value })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    const { polyglot } = this.props

    if (redirectToReferrer) {
      return <Redirect to={from} />
    }

    return (
      <div className="page change-password">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {polyglot.t('passwordChange.page.title')}
        </Header>

        <style>{``}</style>
        <Grid
          textAlign="center"
          style={{ height: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form size="large">
              <Segment className="dark-grey services-card top-30 pad-top-20">
                <Form.Input
                  fluid
                  placeholder={polyglot.t('passwordChange.password.actual')}
                  type="password"
                  onChange={(e) => this.handleValue(e, 'old_password')}
                  value={this.state.old_password}
                  className="bottom-40"
                />
                <Form.Input
                  fluid
                  placeholder={polyglot.t('passwordChange.password.new')}
                  type="password"
                  onChange={(e) => this.handleValue(e, 'password')}
                  onBlur={() => this.checkPassword()}
                  onFocus={() => this.resetCheckPassword()}
                  value={this.state.password}
                />
                {this.state.passwordToShort && (
                  <div
                    style={{ color: 'red', marginTop: -10, marginBottom: 10 }}
                  >
                    {' '}
                    {polyglot.t('signup.message.fields.password.short')}
                  </div>
                )}
                <Form.Input
                  fluid
                  placeholder={polyglot.t('passwordChange.password.confirm')}
                  type="password"
                  onChange={(e) => this.handleValue(e, 'password_confirm')}
                  onBlur={() => this.confirmPassword()}
                  onFocus={() => this.resetConfirmPassword()}
                  value={this.state.password_confirm}
                />
                {this.state.passwordsNoMatch && (
                  <div
                    style={{ color: 'red', marginTop: -10, marginBottom: 10 }}
                  >
                    {polyglot.t('signup.message.fields.password.no.match')}
                  </div>
                )}
                <div className="top-20">
                  <Button
                    className="app-button"
                    fluid
                    size="large"
                    onClick={this.call_passwordChange}
                  >
                    {polyglot.t('passwordChange.change')}
                  </Button>
                </div>
              </Segment>
            </Form>
            <Divider hidden />
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
PasswordChange.propTypes = {
  polyglot: PropTypes.object,
  location: PropTypes.object,
}
PasswordChange = withPolyglot()(PasswordChange)
export default PasswordChange
