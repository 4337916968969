import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Grid,
  Segment,
  Divider,
  Message,
  Icon,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import {
  sendPasswordReset,
  PasswordResetTokenCheck,
  PasswordReinit,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import ReactGA from 'react-ga'

class PasswordForgot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      redirectToReferrer: false,
      hasError: false,
      hasTokenError: false,
      tokenChecked: false,
      error: false,
      isLoading: false,
      emailSent: false,
      resetDone: false,
    }
  }

  call_sendPasswordReset = async () => {
    const { polyglot } = this.props
    const { email } = this.state
    if (!email) {
      return
    }
    let response = await sendPasswordReset(email)
    var customResponse = {}
    if (response.ok) {
      this.setState({
        isLoading: false,
        hasError: false,
        emailSent: true,
      })
      //this.props.justLoggedIn()
    } else {
      // Error
      customResponse = {
        data: {
          message: polyglot.t('forgot.message.error'),
        },
      }
      response = customResponse
      //toast( 	 polyglot.t('profile.update.done')

      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      toast.error(this.state.error.data.message, {
        // onClose: (window.location="/home")
      })
    }
  }
  call_passwordReinit = async () => {
    const { polyglot } = this.props
    const { password } = this.state
    const token = this.props.match.params.token
    if (!password) {
      return
    }
    let response = await PasswordReinit(this.state.password, token)
    var customResponse = {}
    if (response.ok) {
      this.setState({
        isLoading: false,
        hasError: false,
        tokenChecked: false,
        resetDone: true,
        emailSent: false,
      })
    } else {
      //"signup.message.fields.password.short
      customResponse = {
        data: {
          message: polyglot.t('signup.message.fields.password.short'),
        },
      }
      response = customResponse

      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      toast.error(this.state.error.data.message, {})
    }
  }
  async componentDidMount() {
    const { polyglot } = this.props
    var customResponse = {}
    const token = this.props.match.params.token
    if (!token) {
      return
    }
    let response = await PasswordResetTokenCheck(token)
    if (response.ok) {
      this.setState({
        isLoading: false,
        hasError: false,
        tokenChecked: true,
      })
    } else {
      customResponse = {
        data: {
          message: polyglot.t('forgot.message.error.invalid.link'),
        },
      }
      response = customResponse

      this.setState({
        isLoading: false,
        hasTokenError: true,
        error: response,
      })
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  handleValue(e, key) {
    this.setState({
      ...this.state,
      [key]: e.target.value,
      hasError: false,
      hasTokenError: false,
    })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    const { polyglot } = this.props

    if (redirectToReferrer) {
      return <Redirect to={from} />
    }
    return (
      <div className="login-form">
        <style>{`
              .main-menu .menu-btn, .main-menu .menu-btn icon  {
                display:none!important;
              }
              .message.forgot {
                text-align:left;
              }
						`}</style>
        <Grid
          textAlign="center"
          style={{ height: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            {!this.state.emailSent &&
              !this.state.tokenChecked &&
              !this.state.resetDone && (
                <Fragment>
                  {this.state.hasTokenError && (
                    <Message negative icon className="forgot">
                      <Icon name="warning" />
                      <Message.Content>
                        {polyglot.t('forgot.message.error.invalid.link', {
                          email: this.state.email,
                        })}
                      </Message.Content>
                    </Message>
                  )}
                  <Form size="large">
                    <Segment basic>
                      <Form.Input
                        fluid
                        icon="at"
                        iconPosition="left"
                        placeholder={polyglot.t('forgot.email')}
                        onChange={(e) => this.handleValue(e, 'email')}
                        value={this.state.email}
                      />

                      <Button
                        className="app-button"
                        fluid
                        size="large"
                        onClick={this.call_sendPasswordReset}
                      >
                        {polyglot.t('forgot.reset')}
                      </Button>

                      <Link
                        className="top-10"
                        to="/login"
                        style={{ display: 'block' }}
                      >
                        <Button fluid size="large" color="grey" basic>
                          {polyglot.t('login.login')}
                        </Button>
                      </Link>
                    </Segment>
                  </Form>
                </Fragment>
              )}
            {this.state.emailSent && (
              <Fragment>
                <Message positive icon className="forgot">
                  <Icon name="check" />
                  <Message.Content>
                    {polyglot.t('forgot.message.mail.sent', {
                      email: this.state.email,
                    })}
                  </Message.Content>
                </Message>
                <Divider hidden />
                <Link
                  className="top-10"
                  to="/login"
                  style={{ display: 'block' }}
                >
                  <Button fluid size="large" color="grey" basic>
                    {polyglot.t('login.login')}
                  </Button>
                </Link>
              </Fragment>
            )}
            {this.state.tokenChecked && (
              <Fragment>
                <Form size="large">
                  <Segment basic>
                    <Form.Input
                      fluid
                      icon="lock"
                      iconPosition="left"
                      placeholder={polyglot.t('forgot.password.new')}
                      type="password"
                      onChange={(e) => this.handleValue(e, 'password')}
                      value={this.state.password}
                    />

                    <Button
                      className="app-button"
                      fluid
                      size="large"
                      onClick={this.call_passwordReinit}
                    >
                      {polyglot.t('forgot.password.change')}
                    </Button>

                    <div className="top-5">
                      <Link className="top-10 hidden" to="/login">
                        {polyglot.t('forgot.login')}
                      </Link>
                      <div className="clear" />
                    </div>
                  </Segment>
                </Form>
              </Fragment>
            )}
            {this.state.resetDone && (
              <Fragment>
                <Message positive icon className="forgot">
                  <Icon name="check" />
                  <Message.Content>
                    {polyglot.t('forgot.message.password.changed')}
                  </Message.Content>
                </Message>
                <Divider hidden />
                <Link
                  className="top-10"
                  to="/login"
                  style={{ display: 'block' }}
                >
                  <Button fluid size="large" color="grey" basic>
                    {polyglot.t('login.login')}
                  </Button>
                </Link>
              </Fragment>
            )}
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

PasswordForgot.propTypes = {
  polyglot: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
}

PasswordForgot = withPolyglot()(PasswordForgot)
export default PasswordForgot
