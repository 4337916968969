import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Table, Form, Radio, Grid } from 'semantic-ui-react'

import { withPolyglot } from '../../vendor/polyglot-react'

import { createOrUpdateScore } from '../../services/Api'

import { toast } from 'react-toastify'
import {
  logFieldsRL,
  criteriasToDisplayMap,
  holdingPosePercentScoreSide,
  holdingPoseScoreSide,
} from './analysis.utils'

import {
  analyseImage,
  drawingsMandatoryPoints,
  countLines,
  defaultColor,
  setBrushColor,
  changeLineColor,
  correctHorizontal,
  drawLinesForCriteria,
  pointColors,
} from './analysis.canvas.utils'
import AnalysisCriteriasToolBar from './AnalysisCriteriasToolBar'

const learningMode = false

class AnalysisVideoCriterias extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      isInvalid: 0,
      isPlayerPaused: true,
      fieldsData: {},
      brushColor: defaultColor,
      brushRadius: 3,
      drawingsHasMandatoryPoints: false,
      drawMandatoryPoints: false,
      onScreenDrawings: '',
    }

    this.screenshot = createRef()
  }
  componentDidMount() {
    // this.props.setCurrentField(0)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentFieldIndex !== prevProps.currentFieldIndex) {
      this.props.setCurrentField(this.props.currentFieldIndex)
      this.clearDrawings()
      //this.setState({ drawingsMandatoryPoints: false })
      if (this.state.drawMandatoryPoints) {
        this.drawMandatoryPoints()
      }
      //  this.previewDrawings()
    }

    if (this.props.canvasLinesCount !== prevProps.canvasLinesCount) {
      this.lineCountTrigger()
    }

    // if (prevState.drawMandatoryPoints != this.state.drawMandatoryPoints)
    // if (this.state.drawMandatoryPoints) this.drawMandatoryPoints()
    // Utilisation classique (pensez bien à comparer les props) :
  }

  handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      this.props.goToMenuTarget('video')
    }
  }

  setDrawingColor = (colorOrColorIndex) => {
    let brushColor = setBrushColor(colorOrColorIndex)

    if (brushColor) {
      this.props.setParentState({ brushColor })
    }
  }
  setDrawingBrushRadius = (brushRadius) => {
    this.props.setParentState({ brushRadius })
  }

  clearDrawings = () => {
    let { canvas } = this.props
    canvas && canvas.clear()
  }

  undoDrawings = () => {
    let { canvas } = this.props
    canvas.undo()
  }

  previewDrawings = ({
    drawingsTemplate = this.props?.analysisData?.drawingsTemplate,
    automaticScoresData = this.props.analysisData?.automaticScoresData,
  }) => {
    const { currentFieldIndex } = this.props
    let timeField = logFieldsRL[currentFieldIndex]
    let verificationDrawing = { ...drawingsTemplate, lines: [] }

    criteriasToDisplayMap[timeField].forEach(({ prop: criteria }) => {
      verificationDrawing.lines = [
        ...verificationDrawing.lines,
        ...drawLinesForCriteria({
          criteria,
          automaticScoresData,
          //  finalScore,
        }),
      ]
    })
    //console.log(verificationDrawing)
    let { canvas } = this.props

    if (canvas && verificationDrawing.lines?.length)
      canvas.loadSaveData(JSON.stringify(verificationDrawing), true)

    /*

      setTimeout(() => {
        this.clearDrawings()
      }, 1000)*/
  }

  call_analyseImage = ({ useExistingPoints = false } = {}) => {
    let { canvas } = this.props

    if (canvas) {
      let {
        analysisData: { automaticScoresData },
        analysisData,
        updateAnalysisData,
        currentFieldIndex,
      } = this.props

      let savedDrawings = canvas.getSaveData()
      let drawingsTemplate
      if (!savedDrawings.width) {
        drawingsTemplate = JSON.parse(canvas.getSaveData())
        delete drawingsTemplate.lines
        updateAnalysisData({
          //updates criterias values in analysisData
          drawingsTemplate,
        })
      }

      let timeField = logFieldsRL[currentFieldIndex]

      automaticScoresData = analyseImage({
        timeField,
        savedDrawings,
        automaticScoresData,
        useExistingPoints,
      })

      let criteriasScoresToUpdate = {}
      Object.keys(automaticScoresData).forEach((criteria) => {
        let score = automaticScoresData[criteria].score
        if (score) {
          criteriasScoresToUpdate[criteria] =
            automaticScoresData[criteria].score
        }
      })

      if (!learningMode) {
        updateAnalysisData({
          ...criteriasScoresToUpdate, //updates criterias values in analysisData
          automaticScoresData,
          drawingsTemplate,
        })
      }
      if (learningMode) {
        criteriasToDisplayMap[timeField].forEach((criteriaConfig) => {
          let criteriaProp = criteriaConfig.prop
          let score = analysisData[criteriaProp]
          let angle = automaticScoresData[criteriaProp]?.finalAngle
          let distance = automaticScoresData[criteriaProp]?.distance
          let name = analysisData.user.name

          let data = {
            criteria: criteriaProp,
            angle,
            distance,
            score,
            analysis: analysisData.id,
            name,
          }
          updateAnalysisData({
            automaticScoresData,
          })
          if (score && (angle || distance)) this.call_createOrUpdateScore(data)
        })
      }

      this.previewDrawings({ drawingsTemplate, automaticScoresData })
    }
  }

  updateScore = (criteriaProp) => {
    let {
      analysisData: { automaticScoresData = {} },
      analysisData,
    } = this.props

    let score = this.props.analysisData[criteriaProp]
    let angle = automaticScoresData[criteriaProp]?.finalAngle
    let distance = automaticScoresData[criteriaProp]?.distance
    let name = analysisData.user.name
    let data = {
      criteria: criteriaProp,
      angle,
      distance,
      score,
      name,
      analysis: analysisData.id,
    }
    this.call_createOrUpdateScore(data)
  }

  call_drawLinesForCriteria = ({ criteria }) => {
    let {
      analysisData: { automaticScoresData },
      analysisData,
      canvas,
    } = this.props
    let finalScore = analysisData[criteria]
    let lines = drawLinesForCriteria({
      criteria,
      automaticScoresData,
      finalScore,
    })
    if (!canvas) return
    let drawingsTemplate = JSON.parse(canvas.getSaveData())
    delete drawingsTemplate.lines

    canvas.loadSaveData(
      JSON.stringify({
        ...drawingsTemplate,
        lines,
      }),
      true
    )
  }

  async call_createOrUpdateScore(payload) {
    const response = await createOrUpdateScore(payload)
    if (response.ok) {
      console.log('created ' + payload)
    } else {
      toast.error('generic.message.error')
    }
  }

  toggleShowDrawings = (key) => {
    let { onScreenDrawings } = this.state

    this.clearDrawings()
    //  this.call_drawLinesForCriteria({ criteria: key })
    // this.setState({ onScreenDrawings: key })

    if (onScreenDrawings.includes(key)) {
      // this.clearDrawings()
      this.setState({ onScreenDrawings: '' })
    } else {
      this.call_drawLinesForCriteria({ criteria: key })
      this.setState({ onScreenDrawings: key })
    }
  }

  lineCountTrigger() {
    const { currentFieldIndex } = this.props
    let currentimeField = logFieldsRL[currentFieldIndex]

    if (
      this.props.canvasLinesCount ===
      drawingsMandatoryPoints[currentimeField].length
    ) {
      this.setState({ drawingsHasMandatoryPoints: true })
      this.call_analyseImage()
    }
  }

  call_correctHorizontal = () => {
    let {
      analysisData: { automaticScoresData = {} },
      updateAnalysisData,
      canvas,
    } = this.props
    let savedDrawings = canvas.getSaveData()
    if (countLines(savedDrawings) === 2) {
      let horizontalCorrectionDegrees = correctHorizontal({
        savedDrawings,
      })
      //   drawingsData['horizontalCorrectionDegrees'] = horizontalCorrectionDegrees
      automaticScoresData['horizontalCorrectionDegrees'] =
        horizontalCorrectionDegrees
      updateAnalysisData({ automaticScoresData })
    }
    this.clearDrawings()
  }

  setLineColor = (e, { propname, value: color }) => {
    //récuperation de la ligne existante

    let {
      analysisData: { drawingsData },
      updateAnalysisData,
    } = this.props

    let drawing = drawingsData?.[propname]

    let modifiedDrawing = drawing
    if (drawing) {
      modifiedDrawing = changeLineColor({
        drawingStringOrObject: drawing,
        color,
      })
      drawingsData[propname] = modifiedDrawing
      updateAnalysisData({ drawingsData })
      this.previewDrawings(drawingsData)
    }
  }
  criteriaRadioGroup = ({ key, prop, optionsArray, grouped = true }) => {
    const fields = optionsArray.map((index) => (
      <Form.Field key={index}>
        <Radio
          disabled={['fallExecutionTimeL', 'fallExecutionTimeR'].includes(prop)}
          className={'color' + index}
          label={this.props.polyglot.t(`analysis.execution.${key}.${index}`)}
          value={index}
          propname={prop}
          checked={Number(this.props.analysisData[prop]) === index}
          onChange={this.props.handleRadio}
          onClick={this.setLineColor}
        />
      </Form.Field>
    ))
    return grouped ? <Form.Group>{fields}</Form.Group> : <>{fields}</>
  }
  getBodyPoint(bodyPointName) {
    const {
      currentFieldIndex,
      analysisData: { automaticScoresData },
    } = this.props
    let currentFieldName = logFieldsRL[currentFieldIndex]
    return automaticScoresData?.bodyPoints?.[currentFieldName]?.[bodyPointName]
  }

  toggleDrawMandatoryPoints = () => {
    let { drawMandatoryPoints } = this.state

    this.setState({ drawMandatoryPoints: !drawMandatoryPoints })
    this.clearDrawings()
    if (drawMandatoryPoints) {
      this.clearDrawings()
      //  return
    } else {
      this.drawMandatoryPoints()
    }
  }

  drawMandatoryPoints = () => {
    const {
      currentFieldIndex,
      analysisData: { automaticScoresData },
      canvas,
    } = this.props
    let currentFieldName = logFieldsRL[currentFieldIndex]

    if (!canvas) return
    let drawingsTemplate = JSON.parse(canvas.getSaveData())
    delete drawingsTemplate.lines

    let lines = []
    let bodyPoints = automaticScoresData.bodyPoints?.[currentFieldName]
    if (bodyPoints && Object.keys(bodyPoints).length !== 0) {
      Object.keys(bodyPoints).forEach((pointName) => {
        let point = bodyPoints[pointName] || {}
        if (Object.keys(point).length === 0) return
        lines = [
          ...lines,
          {
            points: [point, point],
            brushRadius: 7,
            brushColor: 'white',
          },
          {
            points: [point, point],
            brushRadius: 5,
            brushColor: pointColors[pointName],
          },
        ]
      })
    }

    canvas.loadSaveData(
      JSON.stringify({
        ...drawingsTemplate,
        lines,
      }),
      true
    )
  }
  displayMaitainingPoseScore = (prop) => {
    const { analysisData } = this.props

    return prop === 'fallExecutionTimeL'
      ? holdingPosePercentScoreSide(analysisData, 'L') +
          '->' +
          holdingPoseScoreSide(analysisData, 'L')
      : holdingPosePercentScoreSide(analysisData, 'R') +
          '->' +
          holdingPoseScoreSide(analysisData, 'R')
  }
  render() {
    const {
      currentFieldIndex,
      polyglot,
      analysisData: { automaticScoresData },
    } = this.props

    let currentFieldName = logFieldsRL[currentFieldIndex]
    return (
      <div
        tabIndex="0"
        onKeyDown={this.handleKeyPress}
        style={{
          position: 'relative',
        }}
      >
        <style>{`
			.ui.checkbox.color1 label, .ui.checkbox.color1 + label,
			.color1,
			.ui.checkbox.color0 label, .ui.checkbox.color0 + label,
			.color0
			{color:red;}
			.color2{color:#fbbd08;}
			.ui.checkbox.color2 label, .ui.checkbox.color2 + label
			{font-weight:bold;color:#a9851c;}
			.ui.checkbox.color3 label, .ui.checkbox.color3 + label,
			.color3{color:green;}
			.ui.form .inline.fields>label {min-width: 10em!important;}
			.ui.dropdown>.text{margin-right:5px}

			`}</style>

        <div>
          <div>
            <Button
              style={{ position: 'relative', top: '-80px' }}
              color="blue"
              onClick={this.call_correctHorizontal}
            >
              {' '}
              <Icon name="arrows alternate" />
              {~~automaticScoresData?.horizontalCorrectionDegrees}°
            </Button>
          </div>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                {' '}
                <Table>
                  <Table.Body>
                    {criteriasToDisplayMap[currentFieldName]?.map(
                      (criteriaConfig) => (
                        <Table.Row key={criteriaConfig.key}>
                          <Table.Cell>
                            {polyglot.t(
                              `analysis.execution.${criteriaConfig.key}`
                            )}
                            <br />
                            {[
                              'fallExecutionTimeL',
                              'fallExecutionTimeR',
                            ].includes(criteriaConfig.prop) ? (
                              <>
                                {this.displayMaitainingPoseScore(
                                  criteriaConfig.prop
                                )}
                              </>
                            ) : (
                              <>
                                {automaticScoresData?.[criteriaConfig.prop]
                                  ?.finalAngle && (
                                  <b>
                                    {
                                      automaticScoresData?.[criteriaConfig.prop]
                                        ?.finalAngle
                                    }
                                    °
                                  </b>
                                )}
                                {automaticScoresData?.[criteriaConfig.prop]
                                  ?.distance && (
                                  <b>
                                    {
                                      automaticScoresData?.[criteriaConfig.prop]
                                        ?.distance
                                    }
                                  </b>
                                )}
                              </>
                            )}

                            <div>
                              <Button
                                basic
                                onClick={() =>
                                  this.call_analyseImage({
                                    useExistingPoints: true,
                                  })
                                }
                              >
                                <Icon name="refresh" />
                                Rescore
                              </Button>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            {this.criteriaRadioGroup(criteriaConfig)}
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              basic
                              icon="upload"
                              onClick={() =>
                                this.toggleShowDrawings(criteriaConfig.prop)
                              }
                            />
                            {learningMode && (
                              <Button
                                basic
                                icon="refresh"
                                onClick={() =>
                                  this.updateScore(criteriaConfig.prop)
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <AnalysisCriteriasToolBar
                  clearDrawings={this.clearDrawings}
                  undoDrawings={this.undoDrawings}
                  setDrawingColor={this.setDrawingColor}
                  setDrawingBrushRadius={this.setDrawingBrushRadius}
                  brushColor={this.state.brushColor}
                  brushRadius={this.state.brushRadius}
                  canvas={this.props.canvas}
                />
                <div
                  className="top-20"
                  style={{
                    fontSize: '1.1em',
                    color: '#747474',
                    textTransform: 'capitalize',
                    textAlign: 'left',
                    fontWeight: '600',
                  }}
                  onClick={this.toggleDrawMandatoryPoints}
                >
                  {drawingsMandatoryPoints[currentFieldName]?.map((point) => (
                    <div key={point} style={{ color: pointColors[point] }}>
                      <Icon
                        name={
                          this.getBodyPoint(point) ? 'circle' : 'circle outline'
                        }
                      />
                      {point}
                    </div>
                  ))}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  }
}

AnalysisVideoCriterias.defaultProps = {
  currentFieldIndex: 0,
}
AnalysisVideoCriterias.propTypes = {
  updateAnalysisData: PropTypes.func,
  updateAnalysisState: PropTypes.func,
  analysisData: PropTypes.object,
  setCurrentField: PropTypes.func,
  currentFieldIndex: PropTypes.number,
  screenshots: PropTypes.object,
  polyglot: PropTypes.object,
  handleRadio: PropTypes.func,
  goToMenuTarget: PropTypes.func,
  canvas: PropTypes.object,
  setParentState: PropTypes.func,
  canvasLinesCount: PropTypes.number,
}

AnalysisVideoCriterias = withPolyglot()(AnalysisVideoCriterias)
export default AnalysisVideoCriterias
