import { Component } from 'react'
import PropTypes from 'prop-types'

import { withPolyglot } from '../vendor/polyglot-react'
import ReactGA from 'react-ga'

class Cancel extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {}

    //		this.refreshAfterNewPerf=this.refreshAfterNewPerf.bind(this)
  }
  componentDidMount() {
    this.setState({ isLoading: false })
    ReactGA.pageview(window.location.pathname + window.location.search)

    var url = decodeURIComponent(this.props.history.location.search).replace(
      '?&src=',
      ''
    )
    this.props.history.replace(url)
  }

  render() {
    return null
  }
}
Cancel.propTypes = {
  history: PropTypes.object,
}

Cancel = withPolyglot()(Cancel)
export default Cancel
