import React, { PureComponent } from 'react'
import { withPolyglot } from '../vendor/polyglot-react'
import PropTypes from 'prop-types'

import pose from '../images/pose.png'
import fall from '../images/fall.png'
import pull from '../images/pull.png'
import { Icon, Label, Modal } from 'semantic-ui-react'

class FocusModal extends PureComponent {
  render() {
    let { polyglot, focus } = this.props

    return (
      <>
        <style>
          {`
					.ui.label.focus>img {
						height:5em!important;
					}
			`}
        </style>
        <Modal
          trigger={
            <div>
              <Label
                circular
                className="focus"
                style={{
                  backgroundImage:
                    'linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%)',
                  backgroundColor: 'transparent',
                  color: '#fff',
                  fontSize: '0.7em',
                  marginLeft: 10,
                }}
                data-testid={`modal-focus-${focus}`}
              >
                {focus === 'neutral' && <img src={pose} alt={pose} />}
                {focus === 'pose' && <img src={pose} alt={pose} />}
                {focus === 'fall' && <img src={fall} alt={fall} />}
                {focus === 'pull' && <img src={pull} alt={pull} />}{' '}
              </Label>
              <Icon
                name="question circle outline"
                color="grey"
                size="small"
                style={{ position: 'relative', right: 0, top: -20 }}
              />
            </div>
          }
          basic
          centered={false}
          closeIcon
        >
          <Modal.Header>
            {polyglot.t('help.focus.header')}
            {polyglot.t(`focus.${focus}`)}
          </Modal.Header>
          <Modal.Content image scrolling>
            {focus && (
              <Modal.Description>
                <p>{polyglot.t('help.focus.content.' + focus)}</p>
                <p>{polyglot.t('help.focus.content.generic.' + focus)}</p>
              </Modal.Description>
            )}
          </Modal.Content>
        </Modal>
        {polyglot.t('protocol.focus')} :{' '}
        {focus ? polyglot.t(`focus.${focus}`) : ''}
      </>
    )
  }
}
FocusModal.propTypes = {
  polyglot: PropTypes.object,
  focus: PropTypes.string,
}

FocusModal = withPolyglot()(FocusModal)
export default FocusModal
