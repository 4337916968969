import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import { getMe } from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { Redirect, Link } from 'react-router-dom'

class Pending extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userIsActive: false,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.call_getMe()
  }
  async call_getMe() {
    const response = await getMe()
    if (response.ok) {
      const data = response.data
      const userIsActive = data.isActive
      {
        localStorage.RWJUserIsActive = userIsActive
        const newState = Object.assign({}, this.state, {
          isActive: true,
          name: data.name,
          isLoading: false,
        })
        // store the new state object in the component's state
        this.setState(newState)
      }
    }
  }
  refreshStatus() {
    this.call_getMe()
  }

  render() {
    if (
      localStorage.RWJUserIsActive &&
      JSON.parse(localStorage.RWJUserIsActive) === true
    ) {
      return <Redirect to="/home" />
    }
    const { polyglot } = this.props
    return (
      <div className="pending-container">
        <Dimmer active={this.state.isLoading}>
          <Loader />
        </Dimmer>
        {!this.state.isLoading && (
          <Grid
            textAlign="center"
            style={{ height: '100%' }}
            verticalAlign="middle"
          >
            <Grid.Column>
              <Fragment>
                <Message
                  info
                  header={polyglot.t('pending.label', {
                    name: this.state.name,
                  })}
                  content={polyglot.t('pending.content')}
                />
                <Link to="/home" />
                <Button
                  circular
                  inverted
                  icon="refresh"
                  onClick={() => this.refreshStatus()}
                />
              </Fragment>
            </Grid.Column>
          </Grid>
        )}
      </div>
    )
  }
}
Pending.propTypes = {
  polyglot: PropTypes.object,
}
Pending = withPolyglot()(Pending)
export default Pending
