import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid, Button, Container } from 'semantic-ui-react'
import { getAllProtocolsPlans, createProtocolsPlan } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { withPolyglot } from '../vendor/polyglot-react'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import filterFactory from 'react-bootstrap-table2-filter'
import moment from 'moment'
import AppError from '../components/AppError'

class ProtocolsPlans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [
        {
          dataField: 'date',
          text: 'Date',
          editable: false,
          formatter: (cell, row) => (
            <Link to={'/protocolsPlans/' + row.id}>
              {' '}
              {moment(cell).format(' ddd DD/MM/YYYY')}{' '}
            </Link>
          ),
        },
        {
          dataField: 'label',
          text: 'Label',
          editable: false,
          formatter: (cell, row) => (
            <Link to={'/protocolsPlans/' + row.id}>{cell}</Link>
          ),
        },
        {
          dataField: 'protocols',
          text: 'Protocols',
          editable: false,
          formatter: (cell) => (
            <span>{cell?.length} sessions</span>
            /*
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((element) => (
                    <li key={element._id}>
                      <Link to={`/protocols/${element._id}`}>
                        {element.label}{' '}
                        {element.gear && element.gear.length
                          ? '(' + element.gear.join(',') + ')'
                          : ''}
                      </Link>
                    </li>
                  ))
                : ''}
            </ol>*/
          ),
        },
        {
          dataField: 'isAvailable',
          text: 'isAvailable',
          editable: false,
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },
        {
          dataField: 'isSequential',
          text: 'isSequential',
          editable: false,
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },
      ],
      hasError: 0,
      error: false,
      isLoading: true,
    }
  }

  async getData() {
    var response = await getAllProtocolsPlans()
    if (response.ok) {
      const data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        tableData: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
      })
      this.setState({ redirectToReferrer: true })
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }
  componentDidMount() {
    this.getData()
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return <i aria-hidden="true" className={formatExtraData[cell]} />
  }

  call_createProtocol() {
    const { polyglot } = this.props
    const defaultParams = {
      label: polyglot.t('protocolsPlan.new'),
    }
    createProtocolsPlan(defaultParams)
      .then((res) => {
        if (res.ok) {
          const newId = res.data._id
          this.props.history.push('/protocolsPlans/' + newId)
        }
      })
      .catch()
  }

  render() {
    //		const { polyglot } = this.props;
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <style>{`
						 .react-bootstrap-table{
							text-align:left;
							background-color:#fff
						 }
						 .sr-only{display:none}

						`}</style>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Button
                  circular
                  icon="plus"
                  onClick={() => this.call_createProtocol()}
                />
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  filter={filterFactory()}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: () => {},
                    beforeSaveCell: (newValue, row, column) => {
                      this.handleCellSave(column.dataField, row.id, newValue)
                    },
                    afterSaveCell: () => {},
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

ProtocolsPlans.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

ProtocolsPlans = withPolyglot()(ProtocolsPlans)
export default ProtocolsPlans
