import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withPolyglot } from '../vendor/polyglot-react'
import 'react-toastify/dist/ReactToastify.min.css'
import AppError from '../components/AppError'
import ReactGA from 'react-ga'

class NotFound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      redirectToReferrer: false,
      hasError: false,
      error: false,
      isLoading: false,
    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    const { polyglot } = this.props

    return (
      <div className="Home">
        <AppError message={polyglot.t('generic.message.notfound')} />
      </div>
    )
  }
}
NotFound.propTypes = {
  polyglot: PropTypes.object,
}
NotFound = withPolyglot()(NotFound)
export default NotFound
