import { scoreFromDataFunctionMap } from './analysis.utils'
export const IMGFOLDER = `https://s3.amazonaws.com/upload.molokoy.io/submitted/`
export const brushColors = ['red', 'yellow', '#00ff00' /*green*/, 'white']
export const codeColors = {
  red: '#e6010f',
  yellow: '#ffeb3b',
  green: '#0cff45',
  white: '#fff',
}

const referenceColor = '#e0e1e2'
export const defaultColor = 'white'
export const imgFieldUrl = (analysisId, fieldName) =>
  `${IMGFOLDER}${analysisId}_${fieldName}.jpg`

export const captureVideoFrame = ({
  video,
  format = 'jpeg',
  quality = 0.92,
  width = 800,
  height = 450,

  // width = 400,
  //height = 225,
}) => {
  if (!video || (format !== 'png' && format !== 'jpeg')) {
    return false
  }

  var initialWidth = video.videoWidth
  var initialHeight = video.videoHeight

  // Change the resizing logic
  if (initialWidth > initialHeight) {
    initialHeight = initialHeight * (width / initialWidth)
    initialWidth = width
  } else {
    initialWidth = initialWidth * (height / initialHeight)
    initialHeight = height
  }

  var canvas = document.createElement('canvas')
  canvas.width = initialWidth
  canvas.height = initialHeight
  var ctx = canvas.getContext('2d')
  ctx.drawImage(video, 0, 0, initialWidth, initialHeight)

  var dataUri = canvas.toDataURL('image/' + format, quality)

  var data = dataUri.split(',')[1]
  var mimeType = dataUri.split(';')[0].slice(5)

  var bytes = window.atob(data)
  var buf = new ArrayBuffer(bytes.length)
  var arr = new Uint8Array(buf)

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i)
  }

  var blob = new Blob([arr], { type: mimeType })
  return { dataUri, format, blob }
}

export const delay = async (duration) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), duration)
  })
}

export const setBrushColor = (colorOrColorIndex) => {
  let brushColor = colorOrColorIndex

  if (!brushColors.includes(colorOrColorIndex)) {
    colorOrColorIndex = ~~colorOrColorIndex
    colorOrColorIndex = colorOrColorIndex === 0 ? 1 : colorOrColorIndex
    brushColor = brushColors[brushColor - 1]
  }

  return brushColor
}
export const straightenLastLine = (savedDrawings, vertical) => {
  let savedDrawingsObject = JSON.parse(savedDrawings)
  if (savedDrawingsObject.lines.length) {
    let lines = savedDrawingsObject.lines
    let lastLine = lines.pop()
    let points = lastLine.points

    let start = points[0]
    let end = points[points.length - 1]

    if (vertical) {
      end.x = start.x
    }

    lastLine.points = [start, end]
    savedDrawingsObject.lines.push(lastLine)
  }
  let modifiedDrawing = JSON.stringify(savedDrawingsObject)
  return modifiedDrawing
}
export const getMiddleCoordonates = (a, b) => {
  let c = { x: (b.x - a.x) / 2 + a.x, y: (b.y - a.y) / 2 + a.y }
  return c
}

const getDistanceBetweenPoints = (a, b) => {
  var x = a.x - b.x
  var y = a.y - b.y

  return Math.sqrt(x * x + y * y)
}

export const getFractionCoordonates = (a, b, fraction) => {
  let c = { x: (b.x - a.x) * fraction + a.x, y: (b.y - a.y) * fraction + a.y }
  return c
}

export const getRadius = (a, b) => {
  let radius = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2)) / 2
  return Math.round(radius)
}

export const computeCirclePoints = (center, radius) => {
  let steps = 12
  let circleLine = []
  for (var i = 0; i < steps; i++) {
    let phase = (2 * Math.PI * i) / steps
    circleLine.push({
      x: center.x + radius * Math.cos(phase),
      y: center.y + radius * Math.sin(phase),
    })
  }
  //on rajoute à la fin le premier point pour fermer le cercle
  circleLine.push({
    x: circleLine[0].x,
    y: circleLine[0].y,
  })
  return circleLine
}

export const transformLastLineToCircle = (savedDrawings) => {
  //  this.clearDrawings()
  let modifiedDrawing = savedDrawings
  if (savedDrawings) {
    let savedDrawingsObject = JSON.parse(savedDrawings)
    if (savedDrawingsObject.lines.length) {
      let lines = savedDrawingsObject.lines
      let lastLine = lines.pop()
      let points = lastLine.points

      let start = points[0]
      let end = points[points.length - 1]
      let center = getMiddleCoordonates(start, end)
      let radius = getRadius(start, end)

      lastLine.points = computeCirclePoints(center, radius)
      savedDrawingsObject.lines.push(lastLine)
      modifiedDrawing = JSON.stringify(savedDrawingsObject)
    }
  }

  return modifiedDrawing

  // this.saveableCanvas.loadSaveData(modifiedDrawing, true)
}

export const countLines = (savedDrawings) => {
  let count = 0
  if (savedDrawings) {
    let savedDrawingsObject = JSON.parse(savedDrawings)
    count = savedDrawingsObject.lines.length
  }
  return count
}

const drawVerticalLine = ({
  criteria,
  lowerPointCode,
  upperPointCode,
  automaticScoresData,
  asAngle = false,
  finalScore,
}) => {
  if (!automaticScoresData[criteria]) {
    console.log('no scoredata to use')
    return []
  }
  let { bodyPoints = {}, score } = automaticScoresData[criteria]

  if (Object.keys(bodyPoints) === 0) {
    console.log('no body points')
    return []
  }

  let upperPoint = bodyPoints[upperPointCode]
  let lowerPoint = bodyPoints[lowerPointCode]

  let templateLine = {
    brushColor: setBrushColor(finalScore || score),
    brushRadius: computeBrushRadius(upperPoint, lowerPoint),
  }

  let newLine = { ...templateLine }

  newLine.points = [upperPoint, lowerPoint]

  return [newLine]
}

const drawDeviationLandingFootFromBody = ({
  criteria,
  lowerPointCode,
  upperPointCode,
  automaticScoresData,
  finalScore,
}) => {
  let verticalFromSupport = {}

  if (!automaticScoresData[criteria]) {
    console.log('no scoredata to use')
    return []
  }
  let { bodyPoints = {}, score } = automaticScoresData?.[criteria]

  if (!bodyPoints || Object.keys(bodyPoints).length === 0) {
    console.log('no body points')
    return []
  }

  let upperPoint = bodyPoints[upperPointCode]
  let lowerPoint = bodyPoints[lowerPointCode]
  let { horizontalCorrectionDegrees } = automaticScoresData

  let templateLine = {
    brushColor: setBrushColor(finalScore || score),
    brushRadius: computeBrushRadius(upperPoint, lowerPoint),
  }

  function toRadians(angle) {
    return angle * (Math.PI / 180)
  }

  let correctedX = horizontalCorrectionDegrees
    ? Math.sin(toRadians(horizontalCorrectionDegrees)) * upperPoint.y
    : 0

  let correctedVerticalUpperPoint = {
    x: lowerPoint.x - correctedX,
    y: upperPoint.y,
  }

  verticalFromSupport.points = [lowerPoint, correctedVerticalUpperPoint]

  verticalFromSupport.brushColor = referenceColor
  verticalFromSupport.brushRadius = templateLine.brushRadius * 0.75

  let correctedVerticalLowerPoint = {
    x: upperPoint.x + correctedX,
    y: lowerPoint.y,
  }

  let verticalFromHips = dottedLine({
    templateLine: verticalFromSupport,
    start: upperPoint,
    end: correctedVerticalLowerPoint,
    as: null,
    fractions3or5: 5,
  })
  templateLine.brushRadius = templateLine.brushRadius * 0.75
  let supportFromBody = dottedLine({
    templateLine,
    start: lowerPoint,
    end: correctedVerticalLowerPoint,
    as: 'segment',
  })

  return [
    ...verticalFromHips,
    ...supportFromBody,
    /*ligne vertical non corrigée
    {
      ...verticalFromSupport,
      ...{
        points: [support, { x: support.x, y: upperPoint.y }],
        brushColor: 'red',
    },
      },*/
  ]
}

const getHipsSupporthHorizontalDistanceRatio = ({ support, hip }) => {
  let supportHipsLength = support.y - hip.y
  let supportHipsLandingDistance = support.x - hip.x

  return (supportHipsLandingDistance / supportHipsLength).toFixed(3)
}

const getHipsShoulderHorizontalDistanceRatio = ({ support, hip, shoulder }) => {
  let supportHipsLength = support.y - hip.y
  let shouldertHipsDistance = shoulder.x - hip.x

  return (shouldertHipsDistance / supportHipsLength).toFixed(3)
}

const getHipsShoulderMiddleHorizontalDistanceRatio = ({
  support,
  hip,
  shoulder,
}) => {
  let supportHipsLength = support.y - hip.y
  let middleOfShouldersHip = getMiddleCoordonates(hip, shoulder)
  let HipsShoulderMiddleHorizontalDistance = middleOfShouldersHip.x - support.x
  return (HipsShoulderMiddleHorizontalDistance / supportHipsLength).toFixed(3)
}

const getPullingApexToPoseDistanceRatio = ({
  hip,
  pulled_foot,
  in_pose_foot,
  support,
}) => {
  let hipSupportDistance = getDistanceBetweenPoints(hip, support)

  if (!pulled_foot) {
    console.log('missing pulled foot point')
    return
  }
  let pulledFootToInPoseFootYDistance = Math.abs(in_pose_foot.y - pulled_foot.y)
  let pulledFootToInPoseFootXDistance = Math.abs(in_pose_foot.x - pulled_foot.x)

  let distanceRatio =
    (pulledFootToInPoseFootYDistance + pulledFootToInPoseFootXDistance) /
    hipSupportDistance
  //  let distanceRatio = pulledFootToInPoseFootDistance / hipSupportDistance
  return distanceRatio
}

const drawAlignementLines = ({
  criteria,
  pointCodes,
  finalScore,
  automaticScoresData,
  drawRoundExtremities = true,
}) => {
  if (!automaticScoresData[criteria]) {
    console.log('no scoredata to use')
    return []
  }
  let { bodyPoints = {}, score } = automaticScoresData?.[criteria]

  if (!bodyPoints || Object.keys(bodyPoints).length === 0) {
    console.log('no body points')
    return []
  }

  let points = pointCodes.map((pointCode) => bodyPoints[pointCode])

  let templateLine = {
    brushColor: setBrushColor(finalScore || score),
    brushRadius: computeBrushRadius(points[0], points[points.length - 1]),
  }

  let extremities = []

  if (drawRoundExtremities) {
    let brushRadius = templateLine.brushRadius * 2
    extremities = points.map((point) => ({
      ...templateLine,
      points: [point, point],
      brushRadius,
    }))
  }

  let links = []
  let index = 0

  while (index + 1 < points.length) {
    links = [
      ...links,
      {
        ...templateLine,
        points: [points[index], points[index + 1]],
      },
    ]
    index++
  }
  return [...extremities, ...links]
}

const drawLinesBetweenFrames = ({
  timeField1,
  timeField2,
  pointToTrackCodes,
  upperPointCode,
  lowerPointCode,
  finalScore,
  automaticScoresData,
  as = 'arrow',
}) => {
  if (
    !automaticScoresData.bodyPoints[timeField1] ||
    !automaticScoresData.bodyPoints[timeField2]
  ) {
    console.log('no scoredata to use')
    return []
  }

  let { bodyPoints, score } = automaticScoresData
  if (!bodyPoints) {
    console.log('no body points')
    return []
  }
  let bodyPoints1 = bodyPoints[timeField1]
  let bodyPoints2 = bodyPoints[timeField2]

  if (
    Object.keys(bodyPoints1).length === 0 ||
    Object.keys(bodyPoints2).length === 0
  ) {
    console.log('no body points')
    return []
  }

  let start = bodyPoints1[pointToTrackCodes[0]]
  let end = bodyPoints2[pointToTrackCodes[1]]
  let upperPoint = bodyPoints1[upperPointCode] || bodyPoints2[upperPointCode]
  let lowerPoint = bodyPoints1[lowerPointCode] || bodyPoints2[lowerPointCode]

  let templateLine = {
    brushColor: setBrushColor(finalScore || score),
    brushRadius: computeBrushRadius(upperPoint, lowerPoint) - 1,
  }
  return dottedLine({ templateLine, start, end, as })
}

const dottedLine = ({ templateLine, start, end, fractions3or5 = 3, as }) => {
  let sections =
    fractions3or5 === 3
      ? [
          { from: 1 / 100, to: 1 / 5 - 1 / 25 },
          { from: 2 / 5, to: 3 / 5 - 1 / 25 },
          { from: 4 / 5, to: 1 },
        ]
      : [
          { from: 1 / 100, to: 1 / 9 - 1 / 45 },
          { from: 2 / 9, to: 3 / 9 - 1 / 45 },
          { from: 4 / 9, to: 5 / 9 - 1 / 45 },
          { from: 6 / 9, to: 7 / 9 - 1 / 45 },
          { from: 8 / 9, to: 1 },
        ]

  return sections.map(({ from, to }, index) => ({
    ...templateLine,
    points: drawLinesBetweenFramesToPoints(
      getFractionCoordonates(start, end, from),
      getFractionCoordonates(start, end, to),
      as === 'segment'
        ? index === 0
          ? 'segmentStart'
          : index === sections.length - 1
          ? 'segmentEnd'
          : null
        : as === 'arrow' && sections.length - 1 === index
        ? as
        : null
    ),
  }))
}

const drawLinesBetweenFramesToPoints = (point1, point2, as = null) => {
  let arrowPoints = []
  if (as === 'arrow') {
    // calculate the angle of the line
    let lineangle = Math.atan2(point2.y - point1.y, point2.x - point1.x)
    let h = 15
    let angle = Math.PI / 6

    let angle1 = lineangle + Math.PI + angle
    let topx = point2.x + Math.cos(angle1) * h
    let topy = point2.y + Math.sin(angle1) * h
    let top = { x: topx, y: topy }

    let angle2 = lineangle + Math.PI - angle
    let botx = point2.x + Math.cos(angle2) * h
    let boty = point2.y + Math.sin(angle2) * h
    let bot = { x: botx, y: boty }

    arrowPoints = [point2, top, point2, bot]
  } else if (as === 'segmentStart') {
    let lineangle = Math.atan2(point2.y - point1.y, point2.x - point1.x)
    let h = 10
    let angle = Math.PI / 2

    let angle1 = lineangle + Math.PI + angle
    let angle2 = lineangle + Math.PI - angle

    let topStartx = point1.x + Math.cos(angle1) * h
    let topStarty = point1.y + Math.sin(angle1) * h
    let topStart = { x: topStartx, y: topStarty }

    let botStartx = point1.x + Math.cos(angle2) * h
    let botStarty = point1.y + Math.sin(angle2) * h
    let botStart = { x: botStartx, y: botStarty }

    arrowPoints = [point1, botStart, topStart]
  } else if (as === 'segmentEnd') {
    let lineangle = Math.atan2(point2.y - point1.y, point2.x - point1.x)
    let h = 10
    let angle = Math.PI / 2

    let angle1 = lineangle + Math.PI + angle
    let topEndx = point2.x + Math.cos(angle1) * h
    let topEndy = point2.y + Math.sin(angle1) * h
    let topEnd = { x: topEndx, y: topEndy }

    let angle2 = lineangle + Math.PI - angle
    let botEndx = point2.x + Math.cos(angle2) * h
    let botEndy = point2.y + Math.sin(angle2) * h
    let botEnd = { x: botEndx, y: botEndy }

    arrowPoints = [point2, botEnd, topEnd]
  }
  let points = [point1, point2]
  if (arrowPoints.length) {
    points = arrowPoints
  }

  return points
}

const drawCircleLines = ({
  criteria,
  radiusScaleHelperPoint1,
  radiusScaleHelperPoint2,
  radiusScaleHelperRatioDivider,
  center,
  finalScore,
  automaticScoresData,
}) => {
  let { bodyPoints = {}, score } = automaticScoresData?.[criteria]

  let fallbackBodyPoints = automaticScoresData?.bodyPoints.initialContactTime
  if (!bodyPoints || Object.keys(bodyPoints).length === 0) {
    console.log('no body points')
    return []
  }
  let point1 =
    bodyPoints[radiusScaleHelperPoint1] ||
    fallbackBodyPoints[radiusScaleHelperPoint1]
  let point2 =
    bodyPoints[radiusScaleHelperPoint2] ||
    fallbackBodyPoints[radiusScaleHelperPoint2]

  let centerPoint = bodyPoints[center]

  let radius = (point1.y - point2.y) / radiusScaleHelperRatioDivider

  let templateLine = {
    brushColor: setBrushColor(finalScore || score),
    brushRadius: computeBrushRadius(point1, point2),
  }
  return drawCircle({
    center: centerPoint,
    radius,
    templateLine,
  })
}
const drawCircle = ({ center, radius, templateLine }) => {
  let newLine = { ...templateLine }

  newLine.points = computeCirclePoints(center, radius)
  return [newLine]
}

export const changeLineColor = ({ drawingStringOrObject, color }) => {
  let modifiedDrawing = drawingStringOrObject
  if (drawingStringOrObject) {
    let drawingsObject =
      typeof drawingStringOrObject === 'string'
        ? JSON.parse(drawingStringOrObject)
        : drawingStringOrObject

    if (drawingsObject?.lines?.length !== 0) {
      drawingsObject.lines.map(
        (line) =>
          (line.brushColor =
            line.brushColor === referenceColor
              ? referenceColor
              : setBrushColor(color))
      )
    }

    modifiedDrawing =
      typeof drawingStringOrObject === 'string'
        ? JSON.stringify(drawingsObject)
        : drawingsObject
  }
  return modifiedDrawing
}
export const correctHorizontal = ({ savedDrawings }) => {
  let savedDrawingsObject = JSON.parse(savedDrawings)

  if (savedDrawingsObject.lines.length === 2) {
    let lines = savedDrawingsObject.lines
    let p1 = lines[0].points[0]
    let p2 = lines[1].points[0]

    var dx1 = p2.x - p1.x
    var dy1 = p2.y - p1.y
    var a1 = Math.atan2(dy1, dx1)

    var a = 360 - (parseInt((a1 * 180) / Math.PI + 360) % 360)
    return a
  }
}

const getAngle = ({ vertex, pt1, pt2 }) => {
  var dx1 = pt1.x - vertex.x
  var dy1 = pt1.y - vertex.y
  var dx2 = pt2.x - vertex.x
  var dy2 = pt2.y - vertex.y
  var a1 = Math.atan2(dy1, dx1)
  var a2 = Math.atan2(dy2, dx2)

  var a = parseInt(((a2 - a1) * 180) / Math.PI + 360) % 360
  return a
}

const computeBrushRadius = (hip, support) => {
  let supportHipsLength = support.y - hip.y
  let brushRadius = 4
  if (supportHipsLength < 70) {
    brushRadius = 3
  }
  return brushRadius
}

const evaluateScoreForCriteria = ({ angle, distance, criteriaRoot }) => {
  let finalAngle = getFinalAngle(angle)
  let score = scoreFromDataFunctionMap[criteriaRoot]?.({
    angle: finalAngle,
    distance,
  })
  return { finalAngle, score, distance }
}

const getFinalAngle = (angle) => {
  return Math.abs(angle > 180 ? 360 - angle : angle)
}

const getMiddleOfLine = (line) => {
  let points = line.points

  let start = points[0]
  let end = points[points.length - 1]
  let center = getMiddleCoordonates(start, end)
  return center
}

const getBodyPoints = ({ timeField, savedDrawings }) => {
  let savedDrawingsObject = JSON.parse(savedDrawings)
  let pointsToGet = drawingsMandatoryPoints[timeField]

  let leftMostPointToGet = handleLefttMostPoint[timeField]

  if (savedDrawingsObject.lines.length === pointsToGet.length) {
    //il faut trois points
    let lines = savedDrawingsObject.lines

    let bodyPoints = {}

    let drawingPoints = lines.map((line) => getMiddleOfLine(line))

    if (leftMostPointToGet) {
      drawingPoints.sort((a, b) => (a.x > b.x ? 1 : a.x < b.x ? -1 : 0))
      bodyPoints[leftMostPointToGet] = drawingPoints.shift()

      pointsToGet = pointsToGet.filter(
        (pointName) => pointName !== leftMostPointToGet
      )
    }

    drawingPoints.sort((a, b) => (a.y > b.y ? 1 : a.y < b.y ? -1 : 0))

    pointsToGet.forEach((bodyPoint, index) => {
      bodyPoints[bodyPoint] = drawingPoints[index]
    })

    // let bodyPoints = { hip, knee, support }

    return bodyPoints
  } else {
    console.log('not enough points')
    return {}
  }
}

export const scoreCriteria = ({
  bodyPoints,
  criteriaRoot,
  side,
  automaticScoresData,
}) => {
  if (Object.keys(bodyPoints).length === 0) {
    console.log('not enough points')
    return
  }

  let score,
    angle,
    distance,
    finalAngle,
    criteria,
    kneeAngle,
    criteriaSide,
    trajectoryAngle

  criteria = criteriaRoot + side
  switch (criteriaRoot) {
    case 'poseExecutionLanding':
      distance = getHipsSupporthHorizontalDistanceRatio({
        support: bodyPoints.support,
        hip: bodyPoints.hip,
      })
      ;({ score, distance } = evaluateScoreForCriteria({
        angle,
        criteriaRoot,
        distance,
      }))

      break

    case 'poseExecutionKnee':
      kneeAngle = getAngle({
        vertex: bodyPoints.knee,
        pt1: bodyPoints.hip,
        pt2: bodyPoints.support,
      })

      angle = kneeAngle
      ;({ score, finalAngle } = evaluateScoreForCriteria({
        angle,
        criteriaRoot,
      }))

      break

    case 'poseExecutionVertical':
      distance = getHipsShoulderMiddleHorizontalDistanceRatio({
        support: bodyPoints.support,
        hip: bodyPoints.hip,
        shoulder: bodyPoints.shoulder,
      })
      //on multiplie par 100 pour faciliter la lecture
      distance = (distance * 10).toFixed(3)
      ;({ score, distance } = evaluateScoreForCriteria({
        angle,
        criteriaRoot,
        distance,
      }))

      break

    case 'fallExecutionAlignment':
      distance = getHipsShoulderHorizontalDistanceRatio({
        support: bodyPoints.support,
        hip: bodyPoints.hip,
        shoulder: bodyPoints.shoulder,
      })
      //on multiplie par 100 pour faciliter la lecture
      distance = (distance * 10).toFixed(3)
      ;({ score, distance } = evaluateScoreForCriteria({
        angle,
        criteriaRoot,
        distance,
      }))

      break

    case 'fallExecutionTime':
      criteriaSide = side === 'L' ? 'L' : ''

      angle = null
      finalAngle = null
      distance = null
      score = null
      break

    case 'pullExecutionLegBent':
      kneeAngle = getAngle({
        vertex: bodyPoints.knee,
        pt1: bodyPoints.hip,
        pt2: bodyPoints.ankle,
      })

      angle = kneeAngle
      ;({ score, finalAngle } = evaluateScoreForCriteria({
        angle,
        criteriaRoot,
      }))

      break

    case 'pullExecutionDirection':
      criteriaSide = side === 'L' ? 'L' : ''

      distance = getPullingApexToPoseDistanceRatio({
        hip: bodyPoints.hip,
        pulled_foot: bodyPoints.pulled_foot,
        in_pose_foot: bodyPoints.in_pose_foot,
        support: bodyPoints.support,
      })
      //on multiplie par 100 pour faciliter la lecture
      //  distance = (distance * 10).toFixed(3)
      distance = (distance * 10).toFixed(2)
      ;({ score, distance } = evaluateScoreForCriteria({
        angle,
        criteriaRoot,
        distance,
      }))

      break

    default:
      break
  }

  automaticScoresData[criteria] = {
    angle,
    finalAngle,
    distance,
    criteriaRoot,
    score,
    bodyPoints,
  }
  return automaticScoresData
}

export const analyseImage = ({
  savedDrawings,
  timeField,
  automaticScoresData = {},
  useExistingPoints = false,
}) => {
  let side = timeField.slice(-1) === 'L' ? 'L' : 'R'

  let bodyPoints = {}

  if (useExistingPoints) {
    bodyPoints = automaticScoresData['bodyPoints'][timeField]
  } else {
    bodyPoints = getBodyPoints({ timeField, savedDrawings })

    if (['fallEndTime', 'fallEndTimeL'].includes(timeField)) {
      //get previous support from in poseTime
      let inPoseTimeFieldSource = timeField.endsWith('L')
        ? 'inPoseTimeL'
        : 'inPoseTime'
      let { support } = automaticScoresData['bodyPoints'][inPoseTimeFieldSource]
      bodyPoints = { ...bodyPoints, support }
    }

    if (['inNextPoseTime', 'inNextPoseTimeL'].includes(timeField)) {
      //get previous points from middleOfpullTime
      let inPoseTimeFieldSource = timeField.endsWith('L')
        ? 'middleOfPullTimeL'
        : 'middleOfPullTime'
      let middleOfPullTimePoints =
        automaticScoresData['bodyPoints'][inPoseTimeFieldSource]
      bodyPoints = { ...middleOfPullTimePoints, ...bodyPoints }
    }

    automaticScoresData['bodyPoints'] = {
      ...automaticScoresData['bodyPoints'],
      [timeField]: bodyPoints,
    }
  }

  // let automaticScoresData = {}
  switch (timeField) {
    case 'initialContactTime':
    case 'initialContactTimeL':
      {
        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'poseExecutionLanding',
          side,
          automaticScoresData,
        })

        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'poseExecutionKnee',
          side,
          automaticScoresData,
        })
      }
      break

    case 'inPoseTime':
    case 'inPoseTimeL':
      {
        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'poseExecutionVertical',
          side,
          automaticScoresData,
        })
        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'fallExecutionAlignment',
          side,
          automaticScoresData,
        })
      }
      break
    case 'fallEndTime':
    case 'fallEndTimeL':
      /* {
        automaticScoresData = scoreCriteria({ //nothing is done there
          bodyPoints,
          criteriaRoot: 'fallExecutionTime',
          side,
          automaticScoresData,
        })
      }*/
      break

    case 'lastContactTime':
    case 'lastContactTimeL':
      {
        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'pullExecutionLegBent',
          side,
          automaticScoresData,
        })
        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'framesOnSupportAfterFalling',
          side,
          automaticScoresData,
        })
      }
      break

    case 'middleOfPullTime':
    case 'middleOfPullTimeL':
      break

    case 'inNextPoseTime':
    case 'inNextPoseTimeL':
      {
        automaticScoresData = scoreCriteria({
          bodyPoints,
          criteriaRoot: 'pullExecutionDirection',
          side,
          automaticScoresData,
        })
      }
      break

    default:
      break
  }

  return automaticScoresData
}
export const drawLinesForCriteria = ({
  criteria,
  automaticScoresData = {},
  finalScore,
}) => {
  let side = criteria.slice(-1) === 'L' ? 'L' : 'R'
  let criteriaSide
  let drawingsData = []
  switch (criteria) {
    case 'poseExecutionLandingL':
    case 'poseExecutionLandingR':
      drawingsData = drawDeviationLandingFootFromBody({
        criteria,
        lowerPointCode: 'support',
        upperPointCode: 'hip',
        automaticScoresData,
        finalScore,
        asAngle: false,
      })
      break

    case 'poseExecutionKneeL':
    case 'poseExecutionKneeR':
      drawingsData = drawAlignementLines({
        criteria,
        pointCodes: ['hip', 'knee', 'support'],
        finalScore,
        automaticScoresData,
      })
      break

    case 'poseExecutionVerticalL':
    case 'poseExecutionVerticalR':
      drawingsData = drawVerticalLine({
        criteria,
        lowerPointCode: 'support',
        upperPointCode: 'shoulder',
        automaticScoresData,
        finalScore,
        asAngle: true,
      })
      break

    case 'fallExecutionAlignmentL':
    case 'fallExecutionAlignmentR':
      drawingsData = drawAlignementLines({
        criteria,
        pointCodes: ['shoulder', 'hip'],
        finalScore,
        automaticScoresData,
      })
      break

    case 'fallExecutionTimeL':
    case 'fallExecutionTimeR':
      criteriaSide = side === 'L' ? 'L' : ''

      drawingsData = [
        ...drawCircleLines({
          criteria: `fallExecutionAlignment${side}`,
          radiusScaleHelperPoint1: 'support',
          radiusScaleHelperPoint2: 'hip',
          radiusScaleHelperRatioDivider: 24,
          center: 'airborne_foot',
          finalScore,
          automaticScoresData,
        }),

        ...drawLinesBetweenFrames({
          timeField1: `inPoseTime${criteriaSide}`,
          timeField2: `fallEndTime${criteriaSide}`,
          upperPointCode: 'hip',
          lowerPointCode: 'support',
          pointToTrackCodes: ['airborne_foot', 'airborne_foot'],
          finalScore,
          automaticScoresData,
        }),
      ]
      /*drawingsData = drawDeviationLinesFromVertical({
          criteria,
          lowerPointCode: 'support',
          upperPointCode: 'hip',
          automaticScoresData,
          finalScore,
          asAngle: true,
        })*/
      break

    case 'framesOnSupportAfterFallingL':
    case 'framesOnSupportAfterFallingR':
      criteriaSide = side === 'L' ? 'L' : ''

      drawingsData = [
        ...drawCircleLines({
          criteria,
          radiusScaleHelperPoint1: 'ankle',
          radiusScaleHelperPoint2: 'hip',
          radiusScaleHelperRatioDivider: 4,
          center: 'ankle',
          finalScore,
          automaticScoresData,
        }),
        /*
        ...drawDottedArrowDirectionBetweenPoints({
          timeField: `lastContactTime${criteriaSide}`,
          pointCodes: ['ankle', 'knee'],
          fraction: 0.75,
          finalScore,
          automaticScoresData,
        }),*/
      ]

      break

    case 'pullExecutionLegBentL':
    case 'pullExecutionLegBentR':
      drawingsData = drawAlignementLines({
        criteria,
        pointCodes: ['hip', 'knee', 'ankle'],
        finalScore,
        automaticScoresData,
      })
      break

    case 'pullExecutionDirectionL':
    case 'pullExecutionDirectionR':
      criteriaSide = side === 'L' ? 'L' : ''

      drawingsData = [
        ...drawCircleLines({
          criteria: `framesOnSupportAfterFalling${side}`,
          radiusScaleHelperPoint1: 'ankle',
          radiusScaleHelperPoint2: 'hip',
          radiusScaleHelperRatioDivider: 24,
          center: 'ankle',
          finalScore,
          automaticScoresData,
        }),

        ...drawLinesBetweenFrames({
          timeField1: `lastContactTime${criteriaSide}`,
          timeField2: `middleOfPullTime${criteriaSide}`,
          upperPointCode: 'hip',
          lowerPointCode: 'ankle',
          pointToTrackCodes: ['ankle', 'pulled_foot'],
          finalScore,
          automaticScoresData,
        }),
      ]

      break
    case 'pullExecutionDirectionNextPoseL':
    case 'pullExecutionDirectionNextPoseR':
      criteriaSide = side === 'L' ? 'L' : ''

      drawingsData = [
        ...drawCircleLines({
          criteria: `pullExecutionDirection${side}`,
          radiusScaleHelperPoint1: 'support',
          radiusScaleHelperPoint2: 'hip',
          radiusScaleHelperRatioDivider: 24,
          center: 'pulled_foot',
          finalScore,
          automaticScoresData,
        }),
        ...drawLinesBetweenFrames({
          timeField1: `middleOfPullTime${criteriaSide}`,
          timeField2: `inNextPoseTime${criteriaSide}`,
          upperPointCode: 'hip',
          lowerPointCode: 'support',
          pointToTrackCodes: ['pulled_foot', 'in_pose_foot'],
          finalScore,
          automaticScoresData,
        }),
      ]

      break

    default:
      break
  }
  return drawingsData
}

//les points les plus hauts doivent etre en premier
export const drawingsMandatoryPoints = {
  initialContactTime: ['hip', 'knee', 'support'],
  inPoseTime: ['shoulder', 'hip', 'airborne_foot', 'support'],
  fallEndTime: ['hip', 'airborne_foot' /*'support'*/],
  lastContactTime: ['hip', 'knee', 'ankle'],
  middleOfPullTime: ['pulled_foot'],
  inNextPoseTime: ['hip', 'in_pose_foot', 'support'],

  initialContactTimeL: ['hip', 'knee', 'support'],
  inPoseTimeL: ['shoulder', 'hip', 'airborne_foot', 'support'],
  fallEndTimeL: ['hip', 'airborne_foot' /*'support'*/],
  lastContactTimeL: ['hip', 'knee', 'ankle'],
  middleOfPullTimeL: ['pulled_foot'],
  inNextPoseTimeL: ['hip', 'in_pose_foot', 'support'],
}

export const handleLefttMostPoint = {
  // middleOfPullTime: 'airborne_foot',
  //middleOfPullTimeL: 'airborne_foot',
}

export const pointColors = {
  shoulder: '#29bf12',
  hip: '#a0bb82',
  knee: '#08bdbd',
  airborne_knee: '#08bdbd',
  support: '#f21b3f',
  airborne_foot: '#ff9914',
  in_pose_foot: '#ff9914',
  pulled_foot: '#ffc107',
  ankle: '#ffc107',
}
