import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from './vendor/polyglot-react'
import App from './App'
//import registerServiceWorker from "./registerServiceWorker"
//import * as serviceWorker from './serviceWorker'-->moved to app to have notification on updates

import { Config } from './config'
import 'moment/locale/fr'
import moment from 'moment'
import 'semantic-ui-css/semantic.min.css'

const { translations } = Config

var locale = navigator.languages[0].includes('fr') ? 'fr-fr' : 'en-us'
try {
  localStorage.getItem && (window.storage = localStorage)
} catch (exception) {
  console.log('no local storage')
}

if (window.storage) {
  window.localStorage.getItem('RWJlocale')
    ? window.localStorage.getItem('RWJlocale')
    : window.localStorage.setItem('RWJlocale', locale)
  locale = window.localStorage.getItem('RWJlocale')
}
moment.locale(locale)

const onMissingKey = (key) => {
  console.log('onMissingKey ' + key)
  return 'missing.translation.key ' + key
}
ReactDOM.render(
  <Provider
    locale={locale}
    phrases={translations[locale]}
    onMissingKey={onMissingKey}
    allowMissing={false}
  >
    <App />
  </Provider>,

  document.getElementById('root')
)
//registerServiceWorker()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()
//serviceWorker.register()
