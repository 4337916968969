import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import AnalyseMe from '../screens/AnalyseMe'
import Analyses from '../screens/Analyses'
import Analysis from '../screens/Analysis'
import Athlete from '../screens/Athlete'
import Athletes from '../screens/Athletes'
import Cancel from '../screens/Cancel'
import Course from '../screens/Course'
import Courses from '../screens/Courses'
import Drill from '../screens/Drill'
import Drills from '../screens/Drills'
import DrillsByCategory from '../screens/DrillsByCategory'
import DrillsCategory from '../screens/DrillsCategory'
import Faults from '../screens/Faults'
import Cues from '../screens/Cues'
import Home from '../screens/Home'
import Library from '../screens/Library'
import LibraryShelf from '../screens/LibraryShelf'
import Login from '../screens/Login'
import NotFound from '../screens/NotFound'
import Notification from '../screens/Notification'
import NotificationCenter from '../screens/NotificationCenter'
import PaceConverter from '../screens/PaceConverter'
import PasswordChange from '../screens/PasswordChange'
import PasswordForgot from '../screens/PasswordForgot'
import Pending from '../screens/Pending'
import PrivateRoute from '../services/PrivateRoute'
import Profile from '../screens/Profile'
import Protocol from '../screens/Protocol'
import Protocols from '../screens/Protocols'
import ProtocolsPlan from '../screens/ProtocolsPlan'
import ProtocolsPlans from '../screens/ProtocolsPlans'
import RuffierTest from '../screens/RuffierTest'
import Services from '../screens/Services'
import Settings from '../screens/Settings'
import Signup from '../screens/Signup'
import Success from '../screens/Success'
import UserAnalyses from '../screens/UserAnalyses'
import UserMetronome from '../screens/UserMetronome'
import UserNewPerf from '../screens/UserNewPerf'
import UserNotifications from '../screens/UserNotifications'
import UserPerfs from '../screens/UserPerfs'
import UserPlan from '../screens/UserPlan'
import UserProtocol from '../screens/UserProtocol'
import UserProtocolDetails from '../screens/UserProtocolDetails'
import UserProtocolsByType from '../screens/UserProtocolsByType'
import UserServices from '../screens/UserServices'
import UserStats from '../screens/UserStats'
import UserTraining from '../screens/UserTraining'
import UserTrainingLog from '../screens/UserTrainingLog'
import UserTrainings from '../screens/UserTrainings'
import ShoesPrescription from '../screens/ShoesPrescriptions'

class Routes extends Component {
  render() {
    return (
      <Switch>
        {/* A user can't go to the HomePage if is not authenticated */}
        <Route path="/login" component={Login} />

        <Route
          path="/signup"
          render={(props) => (
            <Signup
              {...props}
              forceNotificationUpdate={this.props.forceNotificationUpdate}
            />
          )}
        />

        <Route path="/forgot/:token?" component={PasswordForgot} />
        <PrivateRoute path="/passwordChange" component={PasswordChange} exact />
        <PrivateRoute path="/pending" component={Pending} exact />
        <PrivateRoute
          path="/"
          component={Home}
          exact
          forceNotificationUpdate={this.props.forceNotificationUpdate}
        />
        <PrivateRoute
          path="/index.html"
          component={Home}
          exact
          forceNotificationUpdate={this.props.forceNotificationUpdate}
        />
        <PrivateRoute
          path="/home/:action?"
          component={Home}
          forceNotificationUpdate={this.props.forceNotificationUpdate}
          exact
        />
        <PrivateRoute path="/profile" component={Profile} exact />
        <PrivateRoute path="/settings" component={Settings} exact />
        <PrivateRoute path="/userStats" component={UserStats} exact />
        {/*{<PrivateRoute path='/notifications'  component={(props) =>
                    (<Notifications resetNotificationCount={()=>this.resetNotificationCount}  {...props} />)} exact/>}*/}
        <PrivateRoute
          path="/notifications"
          component={UserNotifications}
          forceNotificationUpdate={this.props.forceNotificationUpdate}
          exact
        />
        <PrivateRoute
          path="/notification-center"
          component={NotificationCenter}
          exact
        />
        <PrivateRoute path="/notification" component={Notification} exact />
        <PrivateRoute path="/athletes" component={Athletes} exact />
        <PrivateRoute path="/athlete/:id" component={Athlete} exact />
        <PrivateRoute path="/analyses/todo/:when?" component={Analyses} exact />
        <PrivateRoute path="/analyses" component={Analyses} exact />
        <PrivateRoute path="/userAnalyses" component={UserAnalyses} exact />
        <PrivateRoute path="/analysis/:id" component={Analysis} exact />
        <PrivateRoute path="/classes" component={Courses} exact />
        <PrivateRoute path="/userTrainings" component={UserTrainings} exact />
        <PrivateRoute
          path="/userTraining/:trainingId?"
          component={UserTraining}
          exact
        />
        <PrivateRoute
          path="/todayTraining/:trainingId?"
          component={UserTraining}
          exact
        />
        <PrivateRoute
          path="/userTrainingLog/:trainingId?"
          component={UserTrainingLog}
          exact
        />
        <PrivateRoute path="/class/:id" component={Course} exact />
        <PrivateRoute path="/faults" component={Faults} exact />
        <PrivateRoute path="/cues" component={Cues} exact />
        <PrivateRoute
          path="/drills/category"
          component={DrillsByCategory}
          exact
        />
        <PrivateRoute
          path="/protocols/type/:type"
          component={UserProtocolsByType}
          exact
        />

        <PrivateRoute
          path="/protocols/type/:type/:id"
          component={UserProtocolDetails}
          exact
        />

        <PrivateRoute
          path="/drills/category/:category"
          component={DrillsCategory}
          exact
        />

        <PrivateRoute
          path="/drills/category/:category/:id"
          component={DrillsCategory}
          exact
        />

        <PrivateRoute path="/drills" component={Drills} exact />
        <PrivateRoute path="/drills/:id" component={Drill} exact />
        <PrivateRoute path="/paceConverter" component={PaceConverter} exact />
        <PrivateRoute path="/ruffier" component={RuffierTest} exact />
        <PrivateRoute path="/userPerfs" component={UserPerfs} exact />
        <PrivateRoute path="/userNewPerf" component={UserNewPerf} exact />
        <PrivateRoute
          path="/userProtocol/view/:id"
          component={UserProtocolDetails}
          exact
        />
        <PrivateRoute
          path="/userProtocol/:id?"
          component={UserProtocol}
          exact
        />

        <PrivateRoute
          path="/userSelfCare/:id?"
          component={UserProtocol}
          exact
        />

        <PrivateRoute
          path="/analyseMe"
          component={AnalyseMe}
          forceNotificationUpdate={this.props.forceNotificationUpdate}
          exact
        />

        <PrivateRoute path="/services" component={Services} exact />
        <PrivateRoute path="/userServices" component={UserServices} exact />
        <PrivateRoute path="/plan/:label" component={UserPlan} exact />
        <PrivateRoute
          path="/plan/session/:id"
          component={UserProtocolDetails}
          exact
        />

        <PrivateRoute path="/services/cancel" component={Cancel} exact />

        <PrivateRoute path="/success" component={Success} exact />

        <PrivateRoute
          path="/protocols-bytype/:type"
          component={Protocols}
          exact
        />

        <PrivateRoute path="/protocols" component={Protocols} exact />

        <PrivateRoute path="/protocols/:id" component={Protocol} exact />

        <PrivateRoute path="/protocolsPlans" component={ProtocolsPlans} exact />

        <PrivateRoute
          path="/protocolsPlans/:id"
          component={ProtocolsPlan}
          exact
        />
        <PrivateRoute path="/library" component={Library} exact />
        <PrivateRoute path="/shelf" component={LibraryShelf} exact />
        <PrivateRoute path="/metronome" component={UserMetronome} exact />
        <PrivateRoute
          path="/shoesPrescriptions"
          component={ShoesPrescription}
          exact
        />

        <Route component={NotFound} />
      </Switch>
    )
  }
}
Routes.propTypes = {
  forceNotificationUpdate: PropTypes.func,
}
export default Routes
