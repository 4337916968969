import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Header } from 'semantic-ui-react'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import ReactGA from 'react-ga'

import SectionCard from '../components/SectionCard'
import { getSubscriptionStatus } from '../services/Api'

class LibraryShelf extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      hasError: 0,
      error: false,
      shelf: [],
    }
  }

  async componentDidMount() {
    this.call_getSubscriptionStatus()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  render() {
    const { polyglot } = this.props
    const shelf = this.props.location.state.plan
    const focus = this.props.location.state.focus

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <Container className="page shelf">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {/*polyglot.t('sections.title')*/}
        </Header>

        <Grid style={{ minHeight: '90vh', marginTop: '0px' }} relaxed>
          <Grid.Row stretched style={{ paddingTop: '0px' }}>
            <Grid.Column>
              {
                <div>
                  <Header as="h3" className="color-white top-00">
                    {polyglot.t(`sections.category.${shelf.category}`)}
                  </Header>

                  {shelf?.content?.map((x) => (
                    <div style={{ marginBottom: 30 }} key={x.key}>
                      <SectionCard
                        cardData={x}
                        history={this.props.history}
                        asHeader={true}
                        focus={x.useFocus ? focus : null}
                      />
                    </div>
                  ))}
                </div>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}
LibraryShelf.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  polyglot: PropTypes.object,
}
LibraryShelf = withPolyglot()(LibraryShelf)
export default LibraryShelf
