import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Header, Icon } from 'semantic-ui-react'
import {
  getMyAnalyses,
  getMyProtocolsPlan,
  getSubscriptionStatus,
} from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import ReactGA from 'react-ga'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

import SectionCard from '../components/SectionCard'
import { Link } from 'react-router-dom'

const standardPlans = [
  {
    category: 'programs',
    content: [
      {
        key: 'learn',
      },
      {
        key: 'cadence',
      },
      /*
      {
        key: 'transition',
      },
			{
				key: 'speed',
				to: '/plan/speed',
				analysisRecommended: true,
				premiumRequired: true,
			},
			*/
    ],
  },
  {
    category: 'sessions',
    content: [
      {
        key: 'work',
        useFocus: true,
      },
      {
        key: 'short',
        useFocus: true,
      },
      {
        key: 'long',
        useFocus: true,
      },
      {
        key: 'focus',
        useFocus: true,
      },
    ],
  },

  {
    category: 'race',
    content: [
      {
        key: '5k',
        useFocus: true,
      },

      /*
      {
        key: '10k',
        useFocus: true,
      },
      {
        key: 'half',
        useFocus: true,
      },

      {
        key: 'marathon',
        useFocus: true,
      },*/
    ],
  },
  {
    category: 'drills',
    content: [
      { key: 'warmup' },
      {
        key: 'pose',
      },
      {
        key: 'fall',
      },
      {
        key: 'pull',
      },
      { key: 'strength' },
      {
        key: 'flexibility',
      },
    ],
  },
  {
    category: 'selfcare',
    content: [
      {
        key: 'selfcare_lowerleg',
      },
      {
        key: 'selfcare_upperleg',
      },
      {
        key: 'selfcare_back',
      },
    ],
  },
]

class Library extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myPrograms: [],
      hasError: 0,
      error: false,
      isLoading: true,
      focus: null,
    }
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const [plans, subscriptionStatus, analysis] = await Promise.all([
      this.getPlans(),
      this.call_getSubscriptionStatus(),
      this.call_getMyAnalyses(),
    ])
    let focus = analysis?.focus
    sessionStorage.setItem(
      'RWJSubscriptionStatus',
      JSON.stringify(subscriptionStatus)
    )
    this.setState({
      plans,
      focus,
      subscriptionStatus,
      isLoading: false,
      hasError: false,
    })
  }

  async getPlans() {
    const response = await getMyProtocolsPlan()
    if (response.ok) {
      const data = response.data

      let myPrograms = data.rows.map((x) => ({
        key: 'custom',
        programData: x,
        to: `/plan/${x.label}`,
        titleOverride: x.label,
      }))
      let plans = standardPlans

      if (data.count !== 0) {
        plans = [{ category: 'custom', content: myPrograms }, ...standardPlans]
      }
      return plans

      //	this.setState({ redirectToReferrer: true })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  async call_getMyAnalyses() {
    let response = await getMyAnalyses({ limit: 1, fields: 'focus' })
    if (response.ok) {
      return response.data[0] || {}
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  render() {
    const { polyglot } = this.props
    const { plans, focus } = this.state

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page sections">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {/*polyglot.t('sections.title')*/}
        </Header>

        <Grid style={{ minHeight: '90vh', marginTop: '0px' }} relaxed>
          <Grid.Row stretched style={{ paddingTop: '0px' }}>
            <Grid.Column>
              {plans.length !== 0 &&
                plans.map((plan) =>
                  plan.content.length === 1 ? (
                    plan.content.map((x) => (
                      <SectionCard
                        key={x.key}
                        cardData={x}
                        history={this.props.history}
                      />
                    ))
                  ) : (
                    <div key={plan.category}>
                      <Header
                        as="h3"
                        className="color-white top-00"
                        data-testid={`${plan.category}`}
                      >
                        <Link
                          to={{
                            pathname: '/shelf',
                            state: { plan: plan, focus: this.state.focus },
                          }}
                          className="right floated"
                        >
                          <Icon name="chevron right" size="small" />
                        </Link>
                        {polyglot.t(`sections.category.${plan.category}`)}
                      </Header>
                      <Carousel
                        key={plan.category}
                        showArrows={false}
                        showThumbs={false}
                        swipeable={true}
                        centerMode={true}
                        centerSlidePercentage={80}
                        showIndicators={false}
                        showStatus={false}
                        emulateTouch={true}
                        className="bottom-50"
                      >
                        {plan.content.map((x) => (
                          <div style={{ paddingRight: 10 }} key={x.key}>
                            <SectionCard
                              cardData={x}
                              history={this.props.history}
                              focus={x.useFocus ? focus : null}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  )
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}
Library.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
Library = withPolyglot()(Library)
export default Library
