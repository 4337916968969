import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Header, Segment } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import SwipeableViews from 'react-swipeable-views'
import UserAnalysisAbstract from '../components/UserAnalysisAbstract'
import GetAnalysis from '../components/getAnalysis'
import AppError from '../components/AppError'
import { getMyAnalyses } from '../services/Api'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import UserDetailedAnalysis from '../components/UserDetailedAnalysis'

class UserAnalyses extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      analyses: [],
      hasError: false,
      error: false,
      isLoading: true,
      activeIndex: 0,
      showDetailedAnalysis: /*true*/ false,
    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    this.call_getMyAnalyses()
  }
  call_getMyAnalyses() {
    getMyAnalyses()
      .then((response) => {
        if (response.ok) {
          let analyses = response.data.reverse()
          const newState = Object.assign({}, this.state, {
            analyses,
            activeIndex: analyses.length - 1,
            hasError: false,
            isLoading: false,
          })
          this.setState(newState)
        }
      })
      .catch((err) => {
        const newState = Object.assign({}, this.state, {
          hasError: true,
          isLoading: false,
          error: err,
        })
        // store the new state object in the component's state
        this.setState(newState)
      })
  }
  handleChangeIndex = (activeIndex) => {
    this.setState({
      activeIndex,
    })
  }
  nextView = () => {
    var { activeIndex = 0 } = this.state
    this.setState({ activeIndex: activeIndex + 1 })
  }
  prevView = () => {
    var { activeIndex = 0 } = this.state
    this.setState({ activeIndex: activeIndex - 1 })
  }

  toggleshowDetailedAnalysis = () => {
    if (!this.state.showDetailedAnalysis) {
      //demande d'affichage
      ReactGA.event({
        category: 'Analysis',
        action: 'Display details',
      })
    }
    this.setState({ showDetailedAnalysis: !this.state.showDetailedAnalysis })
  }

  render() {
    const { polyglot } = this.props
    const { analyses, activeIndex } = this.state
    var swipeableElements = []
    var showLeftIcon = (index) => {
      return index !== 0
    }
    var showRightIcon = (index) => {
      return index < analyses.length - 1
    }

    for (let index = 0; index < analyses.length; index++) {
      const element = analyses[index]
      const isLast = analyses.length === index + 1
      let prevElement
      if (index < analyses.length) {
        prevElement = analyses[index - 1]
      }
      swipeableElements.push(
        <UserAnalysisAbstract
          key={element._id || element.id}
          toggleshowDetailedAnalysis={this.toggleshowDetailedAnalysis}
          isLast={isLast}
          prevElement={prevElement || null}
          {...element}
          leftIcon={{ show: showLeftIcon(index), action: this.prevView }}
          rightIcon={{ show: showRightIcon(index), action: this.nextView }}
        />
      )
    }

    return (
      <div className="page page-useranalysis pad-bottom-30">
        {this.state.isLoading ? (
          <div className="ui active centered inline loader" />
        ) : this.state.hasError ? (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        ) : (
          <Fragment>
            {this.state.showDetailedAnalysis ? (
              <UserDetailedAnalysis
                analysis={analyses[activeIndex]}
                toggleshowDetailedAnalysis={this.toggleshowDetailedAnalysis}
              />
            ) : this.state.analyses?.length !== 0 ? (
              <>
                {' '}
                <Header
                  as="h2"
                  className="color-white uppercase  top-10 bottom-10"
                  style={{
                    position: 'relative',

                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      width: 140,
                      fontSize: '0.9em',
                    }}
                    className="app-toggle active left"
                  >
                    {polyglot.t('useranalyses.analysis')}
                  </span>

                  <Link
                    to="/userStats"
                    style={{
                      width: 140,
                      fontSize: '0.9em',
                    }}
                    className="app-toggle right"
                    data-testid="userstats-link"
                  >
                    {polyglot.t('userstats.stats')}
                  </Link>
                </Header>
                <SwipeableViews
                  enableMouseEvents={true}
                  index={activeIndex}
                  onChangeIndex={this.handleChangeIndex}
                  disabled={analyses.length === 0}
                >
                  {swipeableElements}
                </SwipeableViews>
              </>
            ) : (
              <Segment>
                <GetAnalysis {...this.props} />
              </Segment>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}
UserAnalyses.propTypes = {
  polyglot: PropTypes.object,
}

UserAnalyses = withPolyglot()(UserAnalyses)
export default UserAnalyses
