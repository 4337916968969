import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Card, Icon, Modal, Container, Label, Header } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'
import moment from 'moment'
import 'moment/locale/fr'

import { setToUpdate, setViewedAnalysis } from '../services/Api'

import ReactGA from 'react-ga'
import pose from '../images/pose.png'
import fall from '../images/fall.png'
import pull from '../images/pull.png'

import AnalysisGauge from './AnalysisGauge'
import AppCard from './AppCard'
import AnalysisGaugeCircular from './AnalysisGaugeCircular'

import { getColor, round, cadenceColor } from './ComputedAnalysis.utils'
import { checkNewFormatComplete } from './Analysis/analysis.utils'

class UserAnalysisAbstract extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      isAnimated: false,
      totalScore: 0,
      showAnalysis: false,
      dissociated: false,
      modalOpen: false,
      totalImprovementVisible: false,
      updateInProgress: null,
    }
  }

  componentDidMount() {
    const currentUser = localStorage.getItem('RWJUserId')
    if (this.props.user) {
      // anciennes vidéo créé sans user donc plante pour les anciens de rwj par ex
      const analysisUser = this.props.user._id
      if (
        !this.props.firstViewed &&
        this.props._id &&
        currentUser.toString() === analysisUser.toString()
      ) {
        setViewedAnalysis(this.props._id)
      }
    }

    setTimeout(() => {
      this.animateTotalScore()
    }, 500)
    setTimeout(() => {
      this.setState({ totalImprovementVisible: true })
    }, 2000)
  }

  totalScore = () => {
    return this.props.globalScore
  }
  totalImprovement = () => {
    return this.props.prevElement
      ? round(this.props.globalScore) -
          round(this.props.prevElement.globalScore)
      : false
  }

  animateTotalScore = () => {
    let score = this.totalScore()
    this.setState({ totalScore: score })
  }

  call_setToUpdate = async () => {
    const response = await setToUpdate(this.props._id, { toUpdate: true })
    if (response.ok) {
      //  const data = response.data

      this.setState({ updateInProgress: true })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  render() {
    const {
      polyglot,
      leftIcon,
      rightIcon,
      poseScore,
      fallScore,
      pullScore,
      toggleshowDetailedAnalysis,
      globalScore,
      isAvailable,
      toUpdate,
    } = this.props
    const totalScore = round(this.state.totalScore)
    const totalImprovement =
      this.totalImprovement() !== false ? this.totalImprovement() : false

    const { totalImprovementVisible } = this.state
    const iconClassLeft = 'floated left color-grey top-0'
    const iconClassRight = 'floated right color-grey top-0'
    const transparentClassLeft = 'floated left  top-0 transparent'
    const transparentClassRight = 'floated right  top-0 transparent'
    const showLeftIcon = leftIcon ? leftIcon.show : false
    const showRightIcon = rightIcon ? rightIcon.show : false
    const green = 'rgb(143, 214, 1)'
    const red = 'rgb(254, 92, 37)'
    const white = 'rgb(200, 200, 200)'
    const mustBeUpdated =
      globalScore &&
      !checkNewFormatComplete(this.props) &&
      isAvailable &&
      !toUpdate
        ? true
        : false

    const updateInProgress = toUpdate || this.state.updateInProgress

    const prevView = () => {
      if (leftIcon && showLeftIcon) {
        leftIcon.action()
      } else {
        return false
      }
    }
    const nextView = () => {
      if (rightIcon && showRightIcon) {
        rightIcon.action()
      } else {
        return false
      }
    }

    return (
      <Fragment>
        <Card
          fluid
          className={'card-dark transparent color-white analysis'}
          style={{ margin: 'auto' }}
        >
          <Card.Content style={{ padding: '1em 0em' }}>
            <Card.Meta>
              <span
                className="date"
                style={{ color: '#fff', fontWeight: 600, fontSize: '1.2em' }}
              >
                {moment(this.props.date).format('LL')}
              </span>
            </Card.Meta>
            <Card.Description className="color-white">
              <Fragment>
                <Icon
                  name="chevron left"
                  size="large"
                  className={
                    showLeftIcon ? iconClassLeft : transparentClassLeft
                  }
                  onClick={prevView}
                  style={{ position: 'absolute', left: '0px', top: '10px' }}
                />
                <Icon
                  name="chevron right"
                  size="large"
                  className={
                    showRightIcon ? iconClassRight : transparentClassRight
                  }
                  onClick={nextView}
                  style={{ position: 'absolute', right: '0px', top: '10px' }}
                />
              </Fragment>

              <style>{`
				.user-list{text-align:left }
				.number{ width:10px;}
				.fluid .ui.card { width:100% }
				.execution-block .ui.grid .column,
				.execution-block .ui.grid>.row
				{padding-top:0.1em;	padding-bottom:0.1em;}
				.criteria{margin-top:5px;}
				.ui.progress{background: #bcbdbd59 !important;}
				.ui.label.focus>img {height:1.5em!important;}
				.ui.circular.label.cadence{padding-top: 1.0em!important;}
				.description .section{text-align:justify}
				.framecount-color-red{color:#ff0000;}
				.framecount-color-orange{color:#ff8700;}
				.framecount-color-yellow{color:#ffd308;}
				.framecount-color-green{color:#00ff00;}
			`}</style>

              <Container className="pad-top-20 bottom-30">
                <AppCard>
                  <div
                    style={{
                      width: '49%',
                      position: 'absolute',
                      textAlign: 'center',
                      bottom: 20,
                    }}
                  >
                    <AnalysisGaugeCircular
                      getColor={getColor}
                      score={totalScore}
                      totalImprovement={totalImprovement}
                      totalImprovementVisible={totalImprovementVisible}
                      improvementColor={
                        totalImprovement > 0
                          ? green
                          : totalImprovement < 0
                          ? red
                          : white
                      }
                    />
                    <div
                      style={{
                        color: '#d7d7d7',
                        textTransform: 'uppercase',
                        marginTop: '-5px',
                      }}
                    >
                      {polyglot.t('analysis.technique.score')}
                    </div>
                  </div>{' '}
                  <div
                    style={{
                      width: '49%',
                      position: 'absolute',
                      textAlign: 'center',
                      right: 0,
                      bottom: 20,
                    }}
                  >
                    <div
                      style={{
                        paddingTop: 10,
                      }}
                    >
                      <Modal
                        trigger={
                          <span>
                            <Label
                              circular
                              className="focus"
                              style={{
                                backgroundImage:
                                  'linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%)',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                fontSize: '2.5em',
                              }}
                              onClick={() =>
                                ReactGA.event({
                                  category: 'Analysis',
                                  action: 'Display focus',
                                })
                              }
                            >
                              {this.props.focus === 'neutral' && (
                                <img src={pose} alt={pose} />
                              )}
                              {this.props.focus === 'pose' && (
                                <img src={pose} alt={pose} />
                              )}
                              {this.props.focus === 'fall' && (
                                <img src={fall} alt={fall} />
                              )}
                              {this.props.focus === 'pull' && (
                                <img src={pull} alt={pull} />
                              )}
                            </Label>
                            <Icon
                              name="question circle outline"
                              color="grey"
                              style={{ position: 'absolute', right: 15 }}
                            />
                          </span>
                        }
                        basic
                        centered={false}
                        closeIcon
                      >
                        <Modal.Header>
                          {polyglot.t('help.focus.header')} {' - '}
                          {this.props.focus}
                        </Modal.Header>
                        <Modal.Content image scrolling>
                          {this.props.focus && (
                            <Modal.Description>
                              <div
                                style={{ textAlign: 'center' }}
                                className="bottom-40"
                              >
                                <Label
                                  circular
                                  className="focus"
                                  style={{
                                    backgroundImage:
                                      'linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%)',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    fontSize: '1.5em',
                                    margin: '0 auto',
                                  }}
                                >
                                  {this.props.focus === 'neutral' && (
                                    <img src={pose} alt={pose} />
                                  )}
                                  {this.props.focus === 'pose' && (
                                    <img src={pose} alt={pose} />
                                  )}
                                  {this.props.focus === 'fall' && (
                                    <img src={fall} alt={fall} />
                                  )}
                                  {this.props.focus === 'pull' && (
                                    <img src={pull} alt={pull} />
                                  )}
                                </Label>
                              </div>
                              <p>
                                {polyglot.t(
                                  'help.focus.content.' + this.props.focus
                                )}
                              </p>
                              <p>
                                {polyglot.t(
                                  'help.focus.content.generic.' +
                                    this.props.focus
                                )}
                              </p>
                            </Modal.Description>
                          )}
                        </Modal.Content>
                      </Modal>
                      <div
                        style={{
                          fontSize: '0.7em',
                          //                    marginTop: 8,
                          color: 'grey',
                          textTransform: 'uppercase',
                          textAlign: 'center',
                        }}
                      >
                        <span>{polyglot.t('analysis.focus.display')} </span>:
                        <span
                          style={{
                            color: '#fff',
                            fontSize: '1.5em',
                            marginLeft: 2,
                          }}
                        >
                          {polyglot.t(`focus.${this.props.focus}`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </AppCard>

                <AppCard>
                  <div
                    style={{
                      width: '49%',
                      position: 'absolute',
                      paddingTop: 30,
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        width: 120,
                        margin: '0 auto',
                      }}
                    >
                      <AnalysisGauge
                        height={95}
                        score={round(poseScore)}
                        label={polyglot.t(`focus.pose`)}
                        style={{ marginTop: -10 }}
                        img={pose}
                      />
                      <AnalysisGauge
                        height={95}
                        score={round(fallScore)}
                        style={{ marginTop: -10, left: 45 }}
                        label={polyglot.t(`focus.fall`)}
                        img={fall}
                      />
                      <AnalysisGauge
                        height={95}
                        score={round(pullScore)}
                        style={{ marginTop: -10, left: 90 }}
                        label={polyglot.t(`focus.pull`)}
                        img={pull}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      width: '49%',
                      position: 'absolute',
                      textAlign: 'center',
                      right: 0,
                      bottom: 10,
                    }}
                  >
                    <Modal
                      trigger={
                        <div
                          style={{
                            fontSize: '1.4em',
                            marginTop: 18,
                          }}
                        >
                          <Icon
                            name="question circle outline"
                            color="grey"
                            style={{
                              position: 'absolute',
                              right: 15,
                              fontSize: 14,
                            }}
                          />
                          <Label
                            circular
                            className="focus"
                            style={{
                              backgroundImage:
                                'linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%)',
                              backgroundColor: 'transparent',
                              color: '#fff',
                              fontSize: '1.8em',
                            }}
                            onClick={() =>
                              ReactGA.event({
                                category: 'Analysis',
                                action: 'Display focus',
                              })
                            }
                          >
                            <div
                              style={{
                                color: cadenceColor(this.props.cadence),
                                display: 'inline-block',
                                margin: '10px 2px',
                                fontSize: '0.9em',
                              }}
                            >
                              {this.props.cadence}
                            </div>
                          </Label>

                          <div
                            style={{
                              fontSize: '0.7em',
                              marginTop: 8,
                              color: 'grey',
                              //textTransform: 'uppercase',
                              textAlign: 'center',
                            }}
                          >
                            <span
                              style={{
                                color: '#fff',

                                marginLeft: 2,
                              }}
                            >
                              {polyglot.t('analysis.execution.cadence')}
                            </span>
                          </div>
                        </div>
                      }
                      closeIcon
                      centered={false}
                      basic
                    >
                      <Modal.Header>
                        {polyglot.t('help.cadence.header')}
                      </Modal.Header>
                      <Modal.Content image scrolling>
                        <Modal.Description>
                          <p> {polyglot.t('help.cadence.content')}</p>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                  </div>
                </AppCard>

                {mustBeUpdated && !updateInProgress && (
                  <>
                    <AppCard heightOverride={'auto'}>
                      <Header
                        style={{
                          marginTop: 0,
                          fontSize: '1.1em',
                          fontWeight: 600,
                        }}
                      >
                        {polyglot.t('analysis.update.needed')}
                      </Header>
                      <span style={{ fontSize: '1.2em' }}>
                        {polyglot.t('analysis.update.explanation')}
                      </span>
                    </AppCard>

                    <div className="actions-placeholder">
                      <div
                        className="call-to-action incard bottom-10"
                        style={{ flexGrow: 'unset' }}
                      >
                        <span
                          className={'action top-0 highlight fullwidth'}
                          style={{
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: 0,
                            fontSize: '1.3rem',
                            paddingTop: 15,
                            textTransform: 'uppercase',
                            height: 50,
                          }}
                          onClick={() => this.call_setToUpdate()}
                          data-testid="update-full-analysis"
                        >
                          {polyglot.t('analysis.update.get')}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {updateInProgress && (
                  <>
                    <AppCard heightOverride={'auto'}>
                      <Header
                        style={{
                          marginTop: 0,
                          fontSize: '1.1em',
                          fontWeight: 600,
                        }}
                      >
                        {polyglot.t('analysis.update.inprogress')}
                      </Header>
                      <span style={{ fontSize: '1.2em' }}>
                        {polyglot.t('analysis.update.inprogress.explanation')}
                      </span>
                    </AppCard>
                  </>
                )}
                {!mustBeUpdated && !updateInProgress && (
                  <div className="actions-placeholder">
                    <div
                      className="call-to-action incard bottom-10"
                      style={{ flexGrow: 'unset' }}
                    >
                      <span
                        className={'action top-0  fullwidth'}
                        style={{
                          marginLeft: 0,
                          marginRight: 0,
                          marginBottom: 0,
                          fontSize: '1.3rem',
                          paddingTop: 15,
                          textTransform: 'uppercase',
                          height: 50,
                          border: '1px solid #3c3a3a',
                        }}
                        onClick={() => toggleshowDetailedAnalysis()}
                        data-testid="view-full-analysis"
                      >
                        {polyglot.t('analysis.full')}
                      </span>
                    </div>
                  </div>
                )}
                {this.props.isLast && polyglot.has(`cues.${this.props.cue}`) && (
                  <AppCard heightOverride={'auto'}>
                    <Header
                      style={{
                        marginTop: 0,
                        fontSize: '1.2em',
                        fontWeight: 600,
                      }}
                    >
                      {polyglot.t('cues.label')}
                    </Header>
                    <Icon name="quote left" />
                    {polyglot.t(`cues.${this.props.cue}`)}
                    <Icon name="quote right" />
                  </AppCard>
                )}
              </Container>
            </Card.Description>
          </Card.Content>
        </Card>
      </Fragment>
    )
  }
}

UserAnalysisAbstract.propTypes = {
  polyglot: PropTypes.object,
  date: PropTypes.string,
  _id: PropTypes.string,
  analysisText: PropTypes.object,
  automaticScoresData: PropTypes.object,
  cadence: PropTypes.number,
  cadenceMark0: PropTypes.number,
  cadenceMark1: PropTypes.number,
  cadenceMark2: PropTypes.number,
  cue: PropTypes.string,
  fallEndTime: PropTypes.number,
  fallEndTimeL: PropTypes.number,
  fallExecutionAlignment: PropTypes.number,
  fallExecutionAlignmentL: PropTypes.number,
  fallExecutionAlignmentR: PropTypes.number,
  fallExecutionTime: PropTypes.number,
  fallExecutionTimeL: PropTypes.number,
  fallExecutionTimeR: PropTypes.number,
  fallScore: PropTypes.number,
  firstViewed: PropTypes.string,
  focus: PropTypes.string,
  fps: PropTypes.number,
  framesFalling: PropTypes.number,
  framesFallingL: PropTypes.number,
  framesFallingR: PropTypes.number,
  framesFromPose: PropTypes.number,
  framesFromPoseL: PropTypes.number,
  framesFromPoseR: PropTypes.number,
  framesOnSupportAfterFalling: PropTypes.number,
  framesOnSupportAfterFallingL: PropTypes.number,
  framesOnSupportAfterFallingR: PropTypes.number,
  framesToPose: PropTypes.number,
  framesToPoseL: PropTypes.number,
  framesToPoseR: PropTypes.number,
  globalScore: PropTypes.number,
  history: PropTypes.object,
  inPoseTime: PropTypes.number,
  inPoseTimeL: PropTypes.number,
  initialContactTime: PropTypes.number,
  initialContactTimeL: PropTypes.number,
  isAvailable: PropTypes.bool,
  isLast: PropTypes.bool,
  lastContactTime: PropTypes.number,
  lastContactTimeL: PropTypes.number,
  leftIcon: PropTypes.object,
  originalVideo: PropTypes.string,
  poseExecutionKnee: PropTypes.number,
  poseExecutionKneeL: PropTypes.number,
  poseExecutionKneeR: PropTypes.number,
  poseExecutionLanding: PropTypes.number,
  poseExecutionLandingL: PropTypes.number,
  poseExecutionLandingR: PropTypes.number,
  poseExecutionVertical: PropTypes.number,
  poseExecutionVerticalL: PropTypes.number,
  poseExecutionVerticalR: PropTypes.number,
  poseScore: PropTypes.number,
  prevElement: PropTypes.object,
  pullExecutionDirection: PropTypes.number,
  pullExecutionDirectionL: PropTypes.number,
  pullExecutionDirectionR: PropTypes.number,
  pullExecutionLegBent: PropTypes.number,
  pullExecutionLegBentL: PropTypes.number,
  pullExecutionLegBentR: PropTypes.number,
  pullScore: PropTypes.number,
  rightIcon: PropTypes.object,
  toggleshowDetailedAnalysis: PropTypes.func,
  user: PropTypes.object,
}

UserAnalysisAbstract = withPolyglot()(UserAnalysisAbstract)
export default UserAnalysisAbstract
