import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Container, Dropdown } from 'semantic-ui-react'
import { getDrills, getDrillsByCategory, updateDrill } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import DrillCreate from '../components/DrillCreate'
import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
const categories = [
  'warmup',
  'pose',
  'fall',
  'pull',
  // 'combined',
  'correction',
  'strength',
  'flexibility',
  'other',
  'selfcare',
  'selfcare_lowerleg',
  'selfcare_upperleg',
  'selfcare_back',
]

const selectOptions = categories.map((d) => ({ key: d, text: d, value: d }))

class Drills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      faults: [],
      clues: [],
      tableData: [],
      tableColumns: [
        {
          dataField: 'name',
          text: 'Name',
        },
        {
          dataField: 'category',
          text: 'Category',
          formatter: (cell) =>
            Array.isArray(cell) && cell[0] ? cell.join(', ') : cell,

          editorRenderer: (editorProps, value) => (
            <MultiSelect {...editorProps} value={value} />
          ),
        },
        {
          dataField: 'image',
          text: 'Image',
          formatter: (cell) => (
            <img alt="" style={{ height: '40px' }} src={'/drills/' + cell} />
          ),
        },

        {
          dataField: 'video',
          text: 'VideoID',
        },
        {
          dataField: 'id',
          text: 'Drill id',
        },
        {
          dataField: 'gear',
          text: 'Equipement',
        },

        {
          dataField: 'isPublished',
          text: 'Published',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },

        {
          dataField: 'isFree',
          text: 'Free',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon unlock color-green',
            false: 'icon lock color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },
      ],
      hasError: 0,
      error: false,
      isLoading: true,
      category: false,
    }
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }
  async handleCellSave(dataField, row, value) {
    let body = Object.assign(row, { [dataField]: value })

    var response = await updateDrill(row.id, body)
    if (!response.ok) {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  async getData(filter) {
    let response
    if (!filter) {
      response = await getDrills()
    } else response = await getDrillsByCategory(filter)

    if (response.ok) {
      const tableData = response.data.rows
      const count = response.data.count

      // store the new state object in the component's state
      this.setState({
        category: filter,
        count,
        tableData,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
      })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      this.setState(newState)
    }
  }
  componentDidMount() {
    this.getData()
  }
  setFilter = (e, { value }) => {
    this.getData(value)
  }

  render() {
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <style>{`
						 .react-bootstrap-table{
							margin-top:0px;
							text-align:left;
							background-color:#fff
						 }
						 table {
							 white-space: pre-line;
						 }
						 .filter-label .sr-only{display:none}
						`}</style>
          {localStorage.getItem('RWJRole') === 'admin' && <DrillCreate />}
          <Dropdown
            selection={true}
            value={this.state.category}
            placeholder="Category"
            options={selectOptions}
            onChange={this.setFilter}
          />{' '}
          <span style={{ color: 'white' }}>({this.state.count})</span>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      this.handleCellSave(column.dataField, row, newValue)
                    },
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}
Drills.propTypes = {
  polyglot: PropTypes.object,
}
Drills = withPolyglot()(Drills)
export default Drills

class MultiSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
    }
  }

  getValue = () => {
    return this.state.selected
  }

  componentDidMount = () => {
    this.setState({ selected: this.props.value })
  }

  setValue = (e, { value }) => {
    this.setState({ selected: value })
  }

  render() {
    const { onBlur } = this.props
    return [
      <Dropdown
        value={this.state.selected}
        key="category"
        placeholder="Category"
        ref={(node) => (this.category = node)}
        multiple
        selection
        options={selectOptions}
        onChange={this.setValue}
        onBlur={() => onBlur()}
      />,
    ]
  }
}

MultiSelect.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func,
}
