import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from 'semantic-ui-react'

import { withPolyglot } from '../../vendor/polyglot-react'

import {
  straightenLastLine,
  transformLastLineToCircle,
} from './analysis.canvas.utils'

class AnalysisCriteriasToolBar extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      //drawingsData: {},
    }

    this.screenshot = createRef()
  }
  componentDidMount() {}

  call_straightenLastLine = (vertical) => {
    let { canvas } = this.props
    let savedDrawings = canvas.getSaveData()
    if (savedDrawings) {
      let drawingWithLastStraightLine = straightenLastLine(
        savedDrawings,
        vertical
      )
      canvas.loadSaveData(drawingWithLastStraightLine, true)
    }
  }

  call_transformLastLineToCircle = () => {
    let { canvas } = this.props
    let savedDrawings = canvas.getSaveData()
    if (savedDrawings) {
      let drawingWithLastLineAsCircle = transformLastLineToCircle(savedDrawings)
      canvas.loadSaveData(drawingWithLastLineAsCircle, true)
    }
  }
  handleLineTransformation = (e, { value }) => {
    switch (value) {
      case 'straight':
        this.call_straightenLastLine()

        break
      case 'vertical':
        this.call_straightenLastLine(true)

        break
      case 'circle':
        this.call_transformLastLineToCircle()

        break

      default:
        break
    }
  }

  render() {
    let { clearDrawings, undoDrawings } = this.props
    return (
      <>
        <Button.Group>
          <Button onClick={clearDrawings}>
            <Icon name="erase" />
          </Button>
          <Button onClick={undoDrawings}>
            <Icon name="undo" />
          </Button>
        </Button.Group>
      </>
    )
  }
}
AnalysisCriteriasToolBar.propTypes = {
  clearDrawings: PropTypes.func,
  undoDrawings: PropTypes.func,
  setDrawingColor: PropTypes.func,
  setDrawingBrushRadius: PropTypes.func,
  brushColor: PropTypes.string,
  brushRadius: PropTypes.number,
  canvas: PropTypes.object,
}
AnalysisCriteriasToolBar = withPolyglot()(AnalysisCriteriasToolBar)
export default AnalysisCriteriasToolBar
