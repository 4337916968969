import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon, Segment, Image } from 'semantic-ui-react'
import { getDrillsByCategory } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import warmup from '../images/sections/warmup.jpg'
import pose from '../images/sections/pose.jpg'
import fall from '../images/sections/fall.jpg'
import pull from '../images/sections/pull.jpg'
import strength from '../images/sections/strength.jpg'
import flexibility from '../images/sections/flexibility.jpg'
import lowerleg from '../images/sections/lowerleg.jpg'
import upperleg from '../images/sections/upperleg.jpg'
import back from '../images/sections/back.jpg'
import ReactGA from 'react-ga'
import SectionCard from '../components/SectionCard'

import { withPolyglot } from '../vendor/polyglot-react'
import Drill from './Drill'
import AppError from '../components/AppError'

const drillsDisplayData = [
  { key: 'warmup', img: warmup },
  { key: 'pose', img: pose },
  { key: 'fall', img: fall },
  { key: 'pull', img: pull },
  { key: 'strength', img: strength },
  {
    key: 'flexibility',
    img: flexibility,
  },
  {
    key: 'selfcare_lowerleg',
    img: lowerleg,
  },
  {
    key: 'selfcare_upperleg',
    img: upperleg,
  },
  {
    key: 'selfcare_back',
    img: back,
  },
]
class DrillsCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drills: [],
      hasError: 0,
      error: false,
      isLoading: true,
      currentDrillProps: false,
    }
  }

  async getData() {
    const response = await getDrillsByCategory(this.props.match.params.category)
    if (response.ok) {
      const data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        drills: data,
        isLoading: false,
        hasError: false,
      })

      if (this.props.location.hash !== '') {
        let drillId = this.props.location.hash.replace('#', '')
        this.setCurrentDrill(drillId)
      }
      //	this.setState({ redirectToReferrer: true })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.hash !== this.props.location.hash) {
      let drillId = this.props.location.hash.replace('#', '')
      // if (prevProps.match.params.id !== this.props.match.params.id) {
      //let drillId = this.props.match.params.id
      //Perform some operation here
      //this.setState({someState: someValue});

      this.setCurrentDrill(drillId)
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    this.getData()
  }
  setCurrentDrill(drillId) {
    //return false
    if (drillId) {
      let customProps = this.props
      customProps.match.params.id = drillId
      this.setState({ currentDrillProps: customProps })
    } else {
      this.setState({ currentDrillProps: false })
    }
  }
  render() {
    const { polyglot } = this.props

    const { currentDrillProps } = this.state

    let wheight = window.innerHeight - 100
    wheight += 'px'

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <Fragment>
        <div
          className={'page ' + (!currentDrillProps ? '' : 'hidden')}
          style={{ height: wheight, overflow: 'auto' }}
        >
          <style>{`
					@media  (min-width: 768px)
					{
						.ui.relaxed.grid
						{
							margin-left: 0em!important;
							margin-right: 0!important;
						}
					}
					.segments{
						background: rgba(0, 0, 0, 0.6) !important;
						background: transparent !important;
					}
					.segments .segment {
						/*border-top: 1px solid #3a3939 !important;
						border-right: 1px solid #3a3939 !important;
						border-left: 1px solid #3a3939 !important;*/
						clear:both;
						height: 72px;
					}

					.segments .segment:after {
						border-bottom: 1px solid #1f1f1f;
						content: '';
						display: block;
						width: 75%;
						margin-left: auto;
						margin-right: auto;
					}

					.segments .segment .content-text
					{
						margin-left: 60px;
						height: 72px;
						display: flex;
						align-items: center;
					}

					.segments .segment:last-child 					{
						border-bottom: 1px solid #3a3939 !important;
					}
					.segment .image {
						margin-right:1em !important;
					}

					.page	.ui.avatar.image {
						width: 4.5em;
						height: 4.5em;
						margin-top: 0.2rem;
						margin-bottom: 0.2rem;
						float:left;
					}
					.page .ui.segment
					{
						padding:0.4em;
					}
						`}</style>

          <SectionCard
            cardData={drillsDisplayData.find(
              (e) => e.key === this.props.match.params.category
            )}
            history={this.props.history}
            asHeader={true}
          />
          <Segment.Group
            className={!currentDrillProps ? '' : 'hidden'}
            id="segment-container"
          >
            {this.state.drills.length !== 0 &&
              this.state.drills.map((drill) => (
                <Segment
                  key={drill.id}
                  id={drill.id}
                  basic
                  textAlign="left"
                  // onClick={() => this.setCurrentDrill(drill.id)}
                  style={{
                    position: 'relative',
                  }}
                >
                  <Link
                    to={
                      drill.hideLink
                        ? '/services'
                        : '/drills/category/' +
                          this.props.match.params.category +
                          '#' +
                          drill.id
                    }
                    data-testid={drill.hideLink ? 'no-link' : 'link-to-drill'}
                    style={{
                      color: '#fff',
                      fontSize: '1.0rem',
                      fontWeight: 600,
                      position: 'relative',
                    }}
                  >
                    {' '}
                    <Icon
                      name={drill.hideLink ? 'lock' : 'chevron right'}
                      color={drill.hideLink ? 'blue' : 'grey'}
                      size="small"
                      className="floated right  top-30"
                    />
                    {drill.image ? (
                      <Image src={'/drills/' + drill.image} avatar />
                    ) : (
                      <Image src={'/icons/icon-72x72.png'} avatar />
                    )}
                    <div className="content-text">{drill.name}</div>
                  </Link>
                </Segment>
              ))}
            {this.state.drills.length === 0 && (
              <span>{polyglot.t('generic.message.empty')}</span>
            )}
          </Segment.Group>
        </div>
        {currentDrillProps && <Drill {...currentDrillProps} />}
      </Fragment>
    )
  }
}

DrillsCategory.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

DrillsCategory = withPolyglot()(DrillsCategory)
export default DrillsCategory
