import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Container,
  Transition,
  Icon,
  Progress,
  List,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { withPolyglot } from '../vendor/polyglot-react'
import { isEmpty } from 'lodash'
import {
  getS3Credentials,
  uploadVideo,
  createAnalysis,
  getSubscriptionStatus,
} from '../services/Api'
import SwipeableViews from 'react-swipeable-views'
import pose_flat from '../images/pose_flat.png'
import setup_zoom from '../images/setup_zoom.png'
import setup_run from '../images/setup_run.gif'
import sun from '../images/sun.png'
import moment from 'moment'
import AppError from '../components/AppError'
import ReactGA from 'react-ga'
import 'video-react/dist/video-react.css'
import { Player } from 'video-react'

class AnalyseMe extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      success: false,
      finalUrl: '',
      progress: { loaded: 0, total: 1 },
      selectedFile: '',
      isUploaded: false,
      isUploading: false,
      uploadPct: 0,
      step: 'select', //'select', selected, uploading, registering, done,
      index: 0,
      delay: 5,
      analysisInProgress: false,
      verifications: {},
    }
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)

    const subscriptionStatus = await this.call_getSubscriptionStatus()
    const { lastAnalysis, analysesAvailable } = subscriptionStatus

    if (!analysesAvailable) {
      // this.props.history.replace('/services')
    }

    const analysisInProgress =
      !isEmpty(lastAnalysis) && lastAnalysis.isAvailable !== true

    if (analysisInProgress) {
      this.setState({
        analysisInProgress: true,
        isLoading: false,
        subscriptionStatus,
      })
    } else {
      this.setState({ isLoading: false, subscriptionStatus })
    }
  }

  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  handleChange = (e) => {
    let file = e.target.files[0]
    if (!file) return

    let fileName = file.name
    let fileType = fileName.split('.').pop().toLowerCase()

    if (['mov', 'mp4'].indexOf(fileType) === -1) {
      toast.error(this.props.polyglot.t('error.wrong.video.ext'))
      return
    }

    var videoPreviewUrl = URL.createObjectURL(file)

    this.setState({
      success: false,
      url: '',
      selectedFile: file,
      step: 'selected',
      videoPreviewUrl,
    })
  }
  progressTracker = (progress) => {
    const { loaded, total } = progress

    let uploadPct = Math.round((loaded / total) * 100) - 1
    uploadPct = uploadPct < 0 ? 0 : uploadPct
    this.setState({ progress: { loaded, total }, uploadPct })
  }

  handleUpload = async () => {
    const { analysesAvailable } = this.state.subscriptionStatus

    if (!analysesAvailable) {
      this.props.history.replace('/services')
      return
    }

    let file = this.state.selectedFile
    let fileName = file.name.toLowerCase()
    let fileType = fileName.split('.').pop()
    if (!fileName) return

    this.setState({ step: 'uploading' })
    const response = await getS3Credentials({
      fileName,
      fileType,
    })

    if (!response.ok) {
      //if error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }

    var data = response.data
    var uploadUrl = data.uploadUrl
    var finalUrl = data.finalUrl
    this.setState({ finalUrl })

    let upload = await uploadVideo(
      uploadUrl,
      file,
      fileType,
      this.progressTracker
    )

    if (!upload.ok) {
      //if error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }

    //vidéo bien reçue
    // on initialise la fiche d'analyse
    const payload = {
      originalVideo: finalUrl,
      subscriptionId:
        this.state.subscriptionStatus.analysesAvailableService._id,
    }

    const analysis = await createAnalysis(payload)

    if (!analysis.ok) {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
        isUploading: false,
      })
      return false
    }

    const analysisData = analysis.data
    let estimated = analysisData.estimatedPublished

    let delay =
      moment(estimated).startOf('day').diff(moment().startOf('day'), 'days') + 1

    this.props.forceNotificationUpdate()

    this.setState({ uploadPct: 100, step: 'done', delay })
  }
  handleChangeIndex = (index) => {
    this.setState({
      index,
    })
  }
  nextView = () => {
    var { index = 0 } = this.state
    this.setState({ index: index + 1 })
  }
  prevView = () => {
    var { index = 0 } = this.state
    this.setState({ index: index - 1 })
  }
  toggleCheck(name) {
    let { verifications } = this.state
    let newVerifications = { ...verifications, [name]: !verifications?.[name] }

    this.setState({ verifications: newVerifications })
  }
  render() {
    const { polyglot } = this.props
    const { step, uploadPct, index, verifications, videoPreviewUrl } =
      this.state
    const iconClassLeft = ' color-grey top-0'
    const iconClassRight = 'right color-grey top-0'
    let tourProgressPercent = 0
    let tourSlidesNumber = 7

    let verified =
      verifications.side &&
      verifications.nomove &&
      verifications.direction &&
      verifications.strides
    var showLeftIcon = () => {
      return index !== 0
    }
    var showRightIcon = () => {
      return index < tourSlidesNumber - 1
    }
    if (tourSlidesNumber) {
      tourProgressPercent =
        ((parseInt(index) + 1) / parseInt(tourSlidesNumber)) * 100
    }
    return (
      <div className="page ">
        <Header
          as="h2"
          className="color-white center-text uppercase  top-00"
          data-testid="send-video-title"
        >
          {step !== 'done'
            ? polyglot.t('analyseme.send.video')
            : polyglot.t('analyseme.sent.video')}
        </Header>
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError &&
          this.state.analysisInProgress &&
          !this.state.isLoading && (
            <span>
              <div className="top-20 intro">
                <div className="icon-wrapper">
                  <Icon
                    name="check square outline"
                    size="large"
                    style={{ fontSize: '8rem' }}
                  ></Icon>
                </div>
                <List
                  className="top-20"
                  size="big"
                  style={{
                    textAlign: 'left',
                    width: '250px',
                    margin: '0 auto',
                  }}
                >
                  <List.Item className="top-10">
                    <Icon name="circle" color="green" />{' '}
                    <List.Content>
                      {polyglot.t('analyseme.inprogress')}{' '}
                    </List.Content>
                  </List.Item>
                </List>
              </div>
            </span>
          )}

        {!this.state.hasError &&
          !this.state.analysisInProgress &&
          !this.state.isLoading && (
            <Transition
              text
              visible={this.state.visible}
              animation="scale"
              duration={100}
            >
              <div className="profile page">
                <style>{`

		.ui.dimmer{
			background-color:#000;
		}
		.ui.modal .scrolling.content {
			max-height: calc(90vh);
			overflow: auto;

		}
		.icon-wrapper{
			padding-top:40px;
			height:220px;
		}
		.list .icon {
			vertical-align: bottom;
		}
		`}</style>
                <Container
                  style={{
                    textAlign: 'center',
                    position: 'relative',
                  }}
                >
                  {showLeftIcon() && (
                    <Icon
                      name="chevron left"
                      size="large"
                      className={iconClassLeft}
                      onClick={() => this.prevView()}
                      style={{
                        position: 'absolute',
                        left: '0px',
                        top: '12px',
                        zIndex: 12,
                      }}
                    />
                  )}
                  {showRightIcon() && (
                    <Icon
                      name="chevron right"
                      size="large"
                      className={iconClassRight}
                      onClick={() => this.nextView()}
                      style={{
                        position: 'absolute',
                        right: '0px',
                        top: '12px',
                        zIndex: 12,
                      }}
                    />
                  )}

                  <Progress
                    percent={tourProgressPercent}
                    size="tiny"
                    inverted
                    style={{ width: '53%', margin: '0 auto' }}
                  />
                  <SwipeableViews
                    style={{ zIndex: 10 }}
                    enableMouseEvents={true}
                    index={index}
                    onChangeIndex={this.handleChangeIndex}
                    slideStyle={{ height: window.innerHeight * 0.75 + 'px' }}
                  >
                    <div className="top-20 intro" data-testid="intro">
                      <div className="icon-wrapper">
                        <Icon
                          name="info circle"
                          size="huge"
                          style={{ fontSize: '12rem' }}
                        ></Icon>
                      </div>
                      <List
                        className="top-20"
                        size="big"
                        style={{
                          textAlign: 'left',
                          width: '250px',
                          margin: '0 auto',
                        }}
                      >
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.intro')}{' '}
                          </List.Content>
                        </List.Item>

                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.intro.1')}{' '}
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                    <div className="top-20 outside" data-testid="outside">
                      <div className="icon-wrapper">
                        <Icon size="huge" style={{ fontSize: '10rem' }}>
                          <img
                            style={{ width: '160px' }}
                            src={sun}
                            alt={'sun'}
                          />
                        </Icon>
                      </div>
                      <List
                        className="top-20"
                        size="big"
                        style={{
                          textAlign: 'left',
                          width: '250px',
                          margin: '0 auto',
                          paddingLeft: '50px',
                        }}
                      >
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.outside')}{' '}
                          </List.Content>
                        </List.Item>

                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.midday')}{' '}
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                    <div className="top-20 surface" data-testid="surface">
                      <div className="icon-wrapper">
                        <Icon size="huge" style={{ fontSize: '10rem' }}>
                          <img
                            style={{ width: '150px', borderRadius: '10px' }}
                            src={pose_flat}
                            alt={'flat'}
                          />
                        </Icon>
                      </div>
                      <List
                        className="top-20"
                        size="big"
                        style={{
                          textAlign: 'left',
                          width: '250px',
                          margin: '0 auto',
                          paddingLeft: '30px',
                        }}
                      >
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.flat')}{' '}
                          </List.Content>
                        </List.Item>

                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.even')}{' '}
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                    <div className="top-20 zoom" data-testid="zoom">
                      <div className="icon-wrapper">
                        <img
                          className="top-20"
                          style={{
                            width: '90%',
                            maxWidth: '360px',
                            maxHeight: '160px',
                          }}
                          src={setup_zoom}
                          alt={'Indication'}
                        />
                      </div>
                      <List
                        className="top-20"
                        size="big"
                        style={{
                          textAlign: 'left',
                          width: '250px',
                          margin: '0 auto',
                        }}
                      >
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />
                          <List.Content>
                            {polyglot.t(
                              'analyseme.instructions.zoom.landscape'
                            )}
                          </List.Content>
                        </List.Item>
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />
                          <List.Content>
                            {polyglot.t('analyseme.instructions.zoom.profile')}
                          </List.Content>
                        </List.Item>
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />
                          <List.Content>
                            {polyglot.t('analyseme.instructions.zoom.distance')}
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                    <div className="top-20 run" data-testid="run">
                      <div className="icon-wrapper">
                        <img
                          style={{
                            width: '90%',
                            maxWidth: '360px',
                            maxHeight: '160px',
                          }}
                          className="top-20"
                          src={setup_run}
                          alt={'Indication'}
                        />
                        <div
                          style={{
                            width: '90%',
                            maxWidth: '360px',
                            textAlign: 'center',
                            // width: '350px',
                            margin: '0 auto',
                            textTransform: 'uppercase',
                            color: '#21ba45',
                            fontWeight: 'bold',
                          }}
                          className="top-10"
                        >
                          <div style={{ float: 'left' }}>
                            {polyglot.t('analyseme.instructions.run.left')}
                          </div>
                          <Icon name="arrow right"></Icon>
                          <div style={{ float: 'right' }}>
                            {polyglot.t('analyseme.instructions.run.right')}
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <List
                        className="top-50"
                        size="big"
                        style={{
                          textAlign: 'left',
                          width: '250px',
                          margin: '0 auto',
                        }}
                      >
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />
                          <List.Content>
                            {polyglot.t('analyseme.instructions.run.no.move')}
                          </List.Content>
                        </List.Item>
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />
                          <List.Content>
                            {polyglot.t(
                              'analyseme.instructions.run.left.to.right'
                            )}
                          </List.Content>
                        </List.Item>
                        <List.Item className="top-10">
                          <Icon name="circle" color="green" />
                          <List.Content>
                            {polyglot.t('analyseme.instructions.run.speed.1')}
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                    <div className="top-20 film" data-testid="film">
                      <div className="icon-wrapper">
                        <Icon
                          name="video"
                          size="huge"
                          style={{ fontSize: '10rem' }}
                        ></Icon>
                      </div>
                      <List
                        className="top-20"
                        size="big"
                        style={{
                          textAlign: 'left',
                          width: '250px',
                          margin: '0 auto',
                        }}
                      >
                        <List.Item className="top-10">
                          <Icon name="cog" color="green" />{' '}
                          <List.Content
                            style={{ fontSize: '1.2em', color: '#fff' }}
                          >
                            {polyglot.t('analyseme.instructions.go.settings')}{' '}
                          </List.Content>
                        </List.Item>
                        <List.Item className="top-10">
                          <Icon name="mobile alternate" color="green" />{' '}
                          <List.Content>
                            {polyglot.t('analyseme.instructions.equipement')}{' '}
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                    <div className="actions-placeholder">
                      <div
                        className="call-to-action"
                        style={{
                          height: 'auto',
                          width: 'auto',
                          maxWidth: '312px',
                        }}
                      >
                        {step === 'select' && (
                          <Fragment>
                            <>
                              <input
                                onChange={this.handleChange}
                                ref={(ref) => {
                                  this.uploadInput = ref
                                }}
                                type="file"
                                id="fileinput"
                                className="inputfile"
                              />
                              <label
                                htmlFor="fileinput"
                                style={{ width: '50px' }}
                              >
                                <UploadIcon disabled={!verified} />
                                <span className="action top-20 long no-icon standalone ">
                                  {polyglot.t('analyseme.select.video')}
                                </span>
                              </label>
                              <br />
                            </>
                          </Fragment>
                        )}

                        {step === 'selected' && (
                          <Fragment>
                            <style>
                              {`
								.video-react .video-react-big-play-button {

									left: 50%;
									top: 50%;
									transform: translate(-50%, -50%);
								}
								`}
                            </style>
                            <div className="top-40">
                              {' '}
                              <Player
                                playsInline
                                src={videoPreviewUrl}
                                fluid={false}
                                width={300}
                              />
                            </div>
                            <List
                              className="top-20"
                              size="big"
                              style={{
                                textAlign: 'left',
                                width: '250px',
                                margin: '0 auto',
                              }}
                            >
                              {/*https://stackoverflow.com/questions/69231773/upload-and-preview-video-in-react */}

                              {[
                                {
                                  key: 'side',
                                  translationKey: 'zoom.landscape',
                                },
                                {
                                  key: 'nomove',
                                  translationKey: 'run.no.move',
                                },
                                {
                                  key: 'profile',
                                  translationKey: 'zoom.profile',
                                },
                                {
                                  key: 'direction',
                                  translationKey: 'run.left.to.right',
                                },
                                {
                                  key: 'strides',
                                  translationKey: 'check.strides',
                                },
                              ].map((item) => (
                                <List.Item
                                  key={item.key}
                                  className="top-10"
                                  onClick={() => this.toggleCheck(item.key)}
                                >
                                  <Icon
                                    name={
                                      verifications[item.key]
                                        ? 'square check outline'
                                        : 'square outline'
                                    }
                                    size="large"
                                    color={
                                      verifications[item.key] ? 'green' : null
                                    }
                                  />
                                  <List.Content>
                                    {polyglot.t(
                                      'analyseme.instructions.' +
                                        item.translationKey
                                    )}
                                  </List.Content>
                                </List.Item>
                              ))}
                            </List>

                            <span
                              onClick={verified ? this.handleUpload : () => {}}
                            >
                              <span
                                className="action top-10 long no-icon standalone"
                                style={verified ? {} : { opacity: '0.25' }}
                              >
                                <span>{polyglot.t('analyseme.tosend')} </span>{' '}
                              </span>
                            </span>
                          </Fragment>
                        )}

                        {step === 'uploading' && (
                          <Fragment>
                            <div>{uploadPct + '%'}</div>
                            <Progress percent={uploadPct} size="tiny" />
                          </Fragment>
                        )}

                        {step === 'done' && (
                          <Fragment>
                            <Icon
                              name="check circle outline"
                              color="green"
                              size="huge"
                              style={{ marginTop: '30px', fontSize: '10rem' }}
                            />
                            <Container style={{ whiteSpace: 'pre-line' }}>
                              <div
                                className="top-20"
                                style={{ fontSize: '2.5rem', color: '#fff' }}
                              >
                                {polyglot.t('analyseme.message.sucess.header')}
                              </div>

                              <div
                                className="top-20"
                                style={{ fontSize: '1.2rem' }}
                              >
                                {polyglot.t(
                                  'analyseme.message.sucess.content' +
                                    (this.state.delay === 1 ? '.tomorrow' : ''),

                                  {
                                    delay: this.state.delay,
                                  }
                                )}
                              </div>
                            </Container>
                            <Link to="/">
                              <span className="action top-20 long no-icon standalone">
                                <span>{polyglot.t('analyseme.home.link')}</span>{' '}
                              </span>
                            </Link>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </SwipeableViews>
                </Container>
              </div>
            </Transition>
          )}
      </div>
    )
  }
}

AnalyseMe.propTypes = {
  forceNotificationUpdate: PropTypes.func,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}

AnalyseMe = withPolyglot()(AnalyseMe)
export default AnalyseMe

const UploadIcon = (props) => {
  let { disabled } = props
  return (
    <Icon
      name="cloud upload"
      size="huge"
      style={{
        marginTop: '40px',
        fontSize: '14rem',
        opacity: disabled ? 0.2 : 1,
      }}
    />
  )
}
UploadIcon.propTypes = {
  disabled: PropTypes.bool,
}
