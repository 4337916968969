import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Placeholder, Segment, Button } from 'semantic-ui-react'
import { Slider } from 'react-semantic-ui-range'
import ReactAvatarEditor from 'react-avatar-editor'
import { uploadProfilePic } from '../services/Api'
import { toast } from 'react-toastify'
import { withPolyglot } from '../vendor/polyglot-react'

var timestamp = +new Date()

class ProfilePicEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: this.props.currentPic,
      allowZoomOut: true,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 100,
      height: 100,
      loading: false,
    }
  }

  componentDidMount = () => {
    this.setState({ image: this.props.currentPic })
  }

  handleFileUpload = async (event) => {
    //this.setState({file: event.target.files});
    const newState = Object.assign({}, this.state, {
      isLoading: true,
    })
    this.setState(newState)
    event.preventDefault()
    const formData = new FormData()

    // formData.append('file', this.state.image)
    formData.append(
      'file',
      dataURLToBlob(this.editor.getImageScaledToCanvas().toDataURL())
    )

    formData.append('userId', localStorage.RWJUserId)

    var response = await uploadProfilePic(formData)
    if (response.ok) {
      var updatedPic = response.data.picture
      const newState = Object.assign({}, this.state, {
        isLoading: false,
      })
      this.setState(newState)
      this.props.mustRefresh(updatedPic)
      localStorage.setItem('RWJProfilePicVersion', timestamp)
      this.props.closeModal()
      // handle your response;
    } else {
      toast.error(this.props.polyglot.t('generic.message.error'))
    }
  }
  handleNewImage = (e) => {
    let file = e.target.files[0]
    if (!file) return

    let fileName = file.name
    let fileType = fileName.split('.').pop().toLowerCase()

    if (['jpg', 'jpeg', 'png', 'gif'].indexOf(fileType) === -1) {
      toast.error(this.props.polyglot.t('error.wrong.image.ext'))
      return
    }

    this.setState({ image: e.target.files[0] })
  }

  handleSave = () => {
    const img = this.editor.getImageScaledToCanvas().toDataURL()
    const rect = this.editor.getCroppingRect()

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    })
  }

  handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }
  handleScaleValue = (value) => {
    const scale = parseFloat(value)
    this.setState({ scale })
  }

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut })
  }

  rotateLeft = (e) => {
    e.preventDefault()

    this.setState({
      rotate: this.state.rotate - 90,
    })
  }

  rotateRight = (e) => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90,
    })
  }

  handleXPosition = (e) => {
    const x = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, x } })
  }

  handleYPosition = (e) => {
    const y = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, y } })
  }

  handleWidth = (e) => {
    const width = parseInt(e.target.value)
    this.setState({ width })
  }

  handleHeight = (e) => {
    const height = parseInt(e.target.value)
    this.setState({ height })
  }

  logCallback() {
    // eslint-disable-next-line
  }

  setEditorRef = (editor) => {
    if (editor) this.editor = editor
  }

  handlePositionChange = (position) => {
    this.setState({ position })
  }
  onLoadSuccess = () => {
    this.setState({ isLoading: false })
  }

  render() {
    return (
      <Segment basic compact style={{ margin: ' 0 auto', minWidth: '300px' }}>
        <div className="avatar-upload">
          <div>
            <ReactAvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(this.state.scale)}
              width={this.state.width}
              height={this.state.height}
              position={this.state.position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(this.state.rotate)}
              onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
              onLoadSuccess={this.onLoadSuccess}
              onImageReady={this.logCallback.bind(this, 'onImageReady')}
              image={this.state.image}
              className="editor-canvas"
              crossOrigin="anonymous"
              style={
                this.state.isLoading
                  ? { display: 'none' }
                  : { display: 'inline-block' }
              }
            />

            {this.state.isLoading && (
              <div
                style={{ width: '150px', height: '150px', margin: '0 auto' }}
              >
                <Placeholder
                  inverted
                  style={{ height: 100, width: 100, margin: '0 auto' }}
                >
                  <Placeholder.Image />
                </Placeholder>
              </div>
            )}
          </div>
          <input
            name="newImage"
            type="file"
            id="fileinput"
            className="inputfile"
            onChange={this.handleNewImage}
            onClick={() => this.setState({ isLoading: true })}
          />
          <label htmlFor="fileinput">
            <Button
              as="a"
              circular
              icon="images"
              className={'bottom-10 app-button '}
              style={{ margin: '10px' }}
            />
          </label>
          <br />

          <Slider
            style={{ marginTop: '20px' }}
            color="red"
            value={this.state.scale}
            settings={{
              start: this.state.scale,
              min: this.state.allowZoomOut ? 0.1 : 1,
              max: 2,
              step: 0.01,
              onChange: (value) => {
                this.handleScaleValue(value)
              },
            }}
          />
          <br />
          <Icon
            link
            name="undo alternate"
            size="big"
            inverted
            style={{ margin: '10px' }}
            onClick={this.rotateLeft}
          />
          <Icon
            link
            name="redo alternate"
            size="big"
            inverted
            style={{ margin: '10px' }}
            onClick={this.rotateRight}
          />
          <br />

          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Button
              as="a"
              circular
              icon="save"
              className={'bottom-10 app-button '}
              onClick={this.handleFileUpload}
            />
          </div>
          <br />
        </div>
      </Segment>
    )
  }
}

ProfilePicEditor.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  currentPic: PropTypes.string,
  mustRefresh: PropTypes.bool,
  closeModal: PropTypes.func,
}

ProfilePicEditor = withPolyglot()(ProfilePicEditor)
export default ProfilePicEditor

var dataURLToBlob = function (dataURL) {
  var BASE64_MARKER = ';base64,'
  var parts
  var contentType
  var raw
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(',')
    contentType = parts[0].split(':')[1]
    raw = parts[1]
    return new Blob([raw], { type: contentType })
  }

  parts = dataURL.split(BASE64_MARKER)
  contentType = parts[0].split(':')[1]
  raw = window.atob(parts[1])
  var rawLength = raw.length
  var uInt8Array = new Uint8Array(rawLength)
  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}
