import React from 'react'
import PropTypes from 'prop-types'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Icon } from 'semantic-ui-react'
import { Howl } from 'howler'

import beepFileMP3 from './sounds/beep.mp3'
import beepFileWAV from './sounds/beep.wav'

class Countdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
      key: 0,
    }
  }

  togglePlay = () => {
    let newState = {}
    if (this.currentTime === 0) {
      newState = {
        key: this.state.key + 1,
        isPlaying: true,
      }
    } else {
      newState = { isPlaying: !this.state.isPlaying }
    }
    this.setState({ ...newState })
  }

  beep = new Howl({
    src: [beepFileMP3, beepFileWAV],
    preload: true,
  })

  currentTime = 0

  displayTime = (currentTime) => {
    this.currentTime = currentTime
    if (currentTime === this.props.seconds && !this.state.isPlaying) {
      return (
        <Icon
          circular
          name="hourglass start"
          style={{ margin: '2px 1px 3px 2px' }}
        />
      )
    } else {
      return currentTime
    }
  }

  reset = () => {
    this.setState({ key: this.state.key + 1, isPlaying: false })
  }
  render() {
    const { isPlaying, key } = this.state
    const { seconds, style } = this.props
    return (
      <div onClick={this.togglePlay} style={style}>
        <CountdownCircleTimer
          isPlaying={isPlaying}
          duration={seconds}
          colors={'#e0e0e0'}
          strokeWidth={3}
          trailColor={'#2c2b2b'}
          rotation="counterclockwise"
          size={35}
          key={key}
          onComplete={() => {
            this.reset()
            this.beep.play()
            // do your stuff here

            //return [true, 1500] // repeat animation in 1.5 seconds
          }}
        >
          {({ remainingTime }) => this.displayTime(remainingTime)}
        </CountdownCircleTimer>
        {this.currentTime !== 0 &&
          this.currentTime !== this.props.seconds &&
          !this.state.isPlaying && (
            <Icon
              name="redo"
              size="small"
              onClick={() => this.reset()}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 40,
                color: 'grey',
              }}
            />
          )}
      </div>
    )
  }
}
Countdown.propTypes = {
  seconds: PropTypes.number,
  polyglot: PropTypes.object,
  style: PropTypes.object,
}

export default Countdown
