import React, { Component } from 'react'
import { withPolyglot } from '../vendor/polyglot-react'
import { Button, Dropdown, Header, List, Search } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { createShoePrescription, searchMyAthletes } from '../services/Api'

const fields = [
  { key: 'surface', options: ['road', 'off-road', 'trail'] },
  { key: 'trainingFrequency', options: ['1', '3', 'more'] },
  { key: 'cushion', options: ['none', 'light', 'medium'] },
  { key: 'drop', options: ['0', '4', '6'] },
  { key: 'stack', options: ['minimalist', 'light', 'medium'] },
]

class ShoePrescriptionEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shoesOptions: [],
      searchLoading: false,
    }
  }

  componentDidMount = () => {
    let defaultState = {}
    fields.forEach((field) => {
      defaultState = { ...defaultState, [field.key]: field.options?.[0] }
    })

    console.log(defaultState)
    this.setState({ ...defaultState })
  }

  searchAthletes = async (e, { value }) => {
    this.setState({ searchLoading: true })
    let pattern = value
    if (pattern.length < 3 && pattern.length > 0) return
    let response = await searchMyAthletes(pattern)
    if (response.ok) {
      let data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        myAthletes: data.map((user) => ({ title: user.name, id: user.id })),
        isLoading: false,
        hasError: false,
      })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
    this.setState({ searchLoading: false })
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })
  save = async () => {
    const { user, surface, trainingFrequency, cushion, drop, stack } =
      this.state

    if (user?.id) {
      await createShoePrescription({
        user: user.id,
        surface,
        trainingFrequency,
        cushion,
        drop,
        stack,
      })
      const { afterSave } = this.props
      afterSave()
    }
  }

  render() {
    const { polyglot } = this.props
    const { myAthletes, searchLoading } = this.state
    return (
      <>
        <List divided inverted relaxed>
          <Search
            fluid
            input={{ fluid: true, inverted: true }}
            loading={searchLoading}
            onResultSelect={(e, data) => this.setState({ user: data.result })}
            icon="user"
            onSearchChange={this.searchAthletes}
            results={myAthletes}
            value={this.state.user?.name}
            minCharacters={3}
          />

          {fields.map((field) => (
            <List.Item key={field.key}>
              <List.Content>
                <Header>{polyglot.t(`shoesPrescriptions.${field.key}`)}</Header>
                <Dropdown
                  fluid
                  basic
                  options={field.options.map((option) => ({
                    value: option,
                    text: option,
                  }))}
                  name={field.key}
                  value={this.state[field.key]}
                  onChange={this.handleChange}
                />
              </List.Content>
            </List.Item>
          ))}
        </List>

        <div className="top-10" style={{ textAlign: 'center' }}>
          <Button
            circular
            icon="save"
            className="app-button"
            onClick={this.save}
          />
        </div>
      </>
    )
  }
}

ShoePrescriptionEdit.propTypes = {
  polyglot: PropTypes.object,
  afterSave: PropTypes.func,
}
ShoePrescriptionEdit = withPolyglot()(ShoePrescriptionEdit)
export default ShoePrescriptionEdit
