const AppConfig = {
  //homepage
  'technique': {
    to: '/userProtocol',
    analysisRequired: true,
    premiumRequired: true,
  },
  'selfcare': {
    to: '/userSelfCare',
    premiumRequired: true,
  },

  'library': {
    to: '/library',
  },

  //programs
  'learn': {
    to: '/plan/learn',
    //analysisRecommended: true,
    premiumRequired: true,
  },
  'cadence': {
    to: '/plan/cadence',
    premiumRequired: true,
  },
  'transition': {
    to: '/plan/transition',
    //  analysisRecommended: true,
    premiumRequired: true,
  },
  'speed': {
    to: '/plan/speed',
    analysisRecommended: true,
    premiumRequired: true,
  },

  //training programs
  'marathon': {
    to: '/plan/marathon',
    analysisRequired: true,
    premiumRequired: true,
  },
  'half': {
    to: '/plan/half',
    analysisRequired: true,
    premiumRequired: true,
  },
  '5k': {
    to: '/plan/5k',
    analysisRequired: true,
    premiumRequired: true,
  },
  '10k': {
    to: '/plan/10k',
    analysisRequired: true,
    premiumRequired: true,
  },

  //trainings library
  'work': {
    to: '/protocols/type/work',
    analysisRequired: true,
    premiumRequired: true,
  },
  'short': {
    to: '/protocols/type/short',
    analysisRecommended: true,
    premiumRequired: true,
  },
  'long': {
    to: '/protocols/type/long',
    analysisRecommended: true,
    premiumRequired: true,
  },
  'focus': {
    to: '/protocols/type/focus',
    analysisRequired: true,
    premiumRequired: true,
  },

  //drills library
  'warmup': { to: '/drills/category/warmup' },
  'pose': {
    to: '/drills/category/pose',
    premiumRequired: true,
  },
  'fall': {
    to: '/drills/category/fall',
    premiumRequired: true,
  },
  'pull': {
    to: '/drills/category/pull',
    premiumRequired: true,
  },
  'strength': {
    to: '/drills/category/strength',
    premiumRequired: true,
  },
  'flexibility': {
    to: '/drills/category/flexibility',
    //  premiumRequired: true,
  },

  //selfcareœ
  'selfcare_lowerleg': {
    to: '/drills/category/selfcare_lowerleg',
    //  premiumRequired: true,
  },
  'selfcare_upperleg': {
    to: '/drills/category/selfcare_upperleg',
    //  premiumRequired: true,
  },

  'selfcare_back': {
    to: '/drills/category/selfcare_back',
    //  premiumRequired: true,
  },
}
export default AppConfig
