import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Container,
  Transition,
  List,
  Icon,
  Modal,
  Button,
} from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'

import AppError from '../components/AppError'
import {
  getShoePrescriptions,
  getPreviousShoesList,
  deleteShoePrescription,
} from '../services/Api'
import AppCard from '../components/AppCard'
import ShoeEdit from '../components/ShoeEdit'
import ShoePrescriptionEdit from '../components/ShoePrescriptionEdit'

const fields = ['surface', 'trainingFrequency', 'cushion', 'drop', 'stack']

class ShoesPrescription extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      //   shoesPrescriptions: [],
      previousShoesList: [],
      openedShoesPrescriptions: [],
      open: false,
    }
  }

  async getData() {
    let response = await getShoePrescriptions()
    let nextState = {}
    if (response.ok) {
      let {
        data: { rows },
      } = response
      nextState = { ...nextState, shoesPrescriptions: rows }
    }

    response = await getPreviousShoesList()
    if (response.ok) {
      let { data } = response
      nextState = { ...nextState, previousShoesList: data.shoes }
    }

    this.setState({ ...nextState })
  }

  afterShoePrescriptionSave = (shoesPrescriptionId) => {
    this.getData()
    if (shoesPrescriptionId) this.toggleOpen(shoesPrescriptionId)
    this.setState({ open: false })
  }

  async componentDidMount() {
    this.getData()
    this.setState({ isLoading: false })
  }

  toggleOpen = (shoesPrescriptionId) => {
    let { openedShoesPrescriptions } = this.state
    if (openedShoesPrescriptions.includes(shoesPrescriptionId)) {
      openedShoesPrescriptions = openedShoesPrescriptions.filter(
        (prescription) => {
          console.log(prescription + '/' + shoesPrescriptionId)
          return '' + prescription !== '' + shoesPrescriptionId
        }
      )
    } else {
      openedShoesPrescriptions.push(shoesPrescriptionId)
    }
    this.setState({ openedShoesPrescriptions })
  }

  shoesPrescriptionsList = () => {
    const { polyglot } = this.props
    const { shoesPrescriptions, openedShoesPrescriptions, previousShoesList } =
      this.state
    const isVendor = JSON.parse(localStorage.getItem('RWJUserIsVendor'))

    const isCoach = 'coach' === localStorage.getItem('RWJRole')

    return shoesPrescriptions?.map((prescription) => (
      <AppCard
        key={prescription.id}
        heightOverride="auto"
        header={
          <div onClick={() => this.toggleOpen(prescription.id)}>
            <Header
              style={{
                marginLeft: 5,
                marginBottom: 5,
                color: prescription.shoe ? 'grey' : 'inherit',
              }}
            >
              {prescription.user.name}
            </Header>
            <Icon
              name={
                openedShoesPrescriptions.includes(prescription.id)
                  ? 'chevron up'
                  : 'chevron down'
              }
              style={{ position: 'absolute', right: 5, top: 5 }}
            />
            <div
              style={{
                marginLeft: 5,
                marginBottom: 5,
                color: prescription.shoe ? 'grey' : 'inherit',
              }}
            >
              {prescription.shoe}
            </div>
          </div>
        }
      >
        {openedShoesPrescriptions.includes(prescription.id) ? (
          <List divided inverted relaxed>
            {fields.map((field) => (
              <List.Item key={field}>
                <List.Content>
                  <span style={{ color: 'grey' }}>
                    {polyglot.t(`shoesPrescriptions.${field}`)}
                  </span>
                  <List.Header style={{ textTransform: 'lowercase' }}>
                    {polyglot.has(
                      `shoesPrescriptions.${field}.${prescription[field]}`
                    )
                      ? polyglot.t(
                          `shoesPrescriptions.${field}.${prescription[field]}`
                        )
                      : prescription[field]}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}

            {isVendor && (
              <List.Item>
                <List.Content>
                  <span style={{ color: 'grey' }}>
                    {polyglot.t(`shoesPrescriptions.model`)}
                  </span>
                  <ShoeEdit
                    shoePrescription={prescription}
                    previousShoesList={previousShoesList}
                    afterSave={this.afterShoePrescriptionSave}
                  />
                </List.Content>
              </List.Item>
            )}
            {isCoach && (
              <List.Item>
                <List.Content>
                  <Button
                    circular
                    icon="trash"
                    color="red"
                    size="tiny"
                    onClick={() => this.delete(prescription.id)}
                  />
                </List.Content>
              </List.Item>
            )}
          </List>
        ) : null}
      </AppCard>
    ))
  }
  delete = async (shoePrescriptionId) => {
    await deleteShoePrescription(shoePrescriptionId)
    this.afterShoePrescriptionSave(shoePrescriptionId)
  }
  render() {
    const { polyglot } = this.props
    const { shoesPrescriptions } = this.state
    const isCoach = 'coach' === localStorage.getItem('RWJRole')
    return (
      <div className="page bottom-40">
        <Header as="h2" className="color-white center-text uppercase  top-00">
          {polyglot.t('shoesPrescriptions.page.title')}
        </Header>

        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}

        {!this.state.hasError && !this.state.isLoading && (
          <>
            {isCoach && (
              <div className="top-10 bottom-10" style={{ textAlign: 'center' }}>
                <Button
                  circular
                  icon="add"
                  className="app-button"
                  onClick={() => this.setState({ open: true })}
                />
              </div>
            )}
            <Transition
              text
              visible={this.state.visible}
              animation="scale"
              duration={100}
            >
              <div className="page shoes-prescription">
                <Container style={{ height: '100%', textAlign: 'left' }}>
                  {shoesPrescriptions && shoesPrescriptions?.length === 0
                    ? polyglot.t('generic.message.empty')
                    : this.shoesPrescriptionsList()}
                </Container>
              </div>
            </Transition>
            <Modal
              basic
              onClose={() => this.setState({ open: false })}
              onOpen={() => this.setState({ open: true })}
              open={this.state.open}
              size="tiny"
              closeIcon
              centered={false}
              className="discrete"
              closeOnDimmerClick={false}
            >
              <Modal.Content className="top-30">
                <ShoePrescriptionEdit
                  afterSave={this.afterShoePrescriptionSave}
                />
              </Modal.Content>
            </Modal>
          </>
        )}
      </div>
    )
  }
}

ShoesPrescription.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
ShoesPrescription = withPolyglot()(ShoesPrescription)
export default ShoesPrescription
