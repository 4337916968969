const en_us = {
  "undefined": "",
  "locale": "en-US",
  "loaded.check": true,
  "generic": {
    "message.app.update": "New update available !",
    "message.app.update.button": "Update",
    "message.save": "Save",
    "message.refresh": "Refresh",
    "message.update": "Update done",
    "message.created": "Creation done",
    "message.error": "Opération failed",
    "message.done": "Modification effectuée",
    "message.empty": "Nothing to display yet",
    "message.delete": "This will delete the element! ",
    "message.notfound":
      "Sorry, we could not find the information you asked ... ",
    "proud.partner": "Proud to partner with ",
    "select.empty": "- Select one -",
    "select.label": "Choose",
    "phone.use.title": "Crafted for Mobile",
    "phone.use": `Molokoy Plaform is made for mobile use so you can have it anywhere, anytime!
 Open your mobile browser and go to:`,

    "or": "or",
    "today": "today",
    "week": "Week",
  },
  "error": {
    "oops": "Oops,",
    "message": "Something went wrong...",
    "retry": "Retry",
    "unknown": "Unknon error",
    "wrong.video.ext": `Please upload a video file (.mov ou .mp4)`,
    "wrong.image.ext": `Please upload an image file (.jpg, .jpeg, .png or .gif)`,
  },
  "focus": {
    pose: "Pose",
    fall: "Fall",
    pull: "Pull",
    neutral: "Neutral",
  },
  "sidebar": {
    "home": "Home",
    "trainings": "Trainings",
    "profile": "Profile",
    "notification.center": "Notification Center",
    "courses": "Classes",
    "athletes": "Athletes",
    "analyses": "Technique Analysis",
    "faults": "Faults",
    "clues": "Cues",
    "drills": "Drills",
    "protocols": "Protocols",
    "protocols.plans": "Plans",
    "update.check": "Check for updates",
    "help": `Need help ?`,
    "faq": `FAQ`,
    "faq.url": "https://molokoy.io/faq",
  },

  "athlete": {
    details: "Details",
    home: "Home",
    courses: "Training",
  },

  "app": {
    "ios.prompt.title": "Add to Home Screen",
    "ios.prompt.body": `This website has app functionality.
Add it to your home screen to access it easily, in fullscreen`,
    "ios.prompt.shareButtonLabel": `1) Press the 'Share' button`,
    "ios.prompt.addHomeButtonLabel": `2) Press 'Add to Home Screen`,
    "ios.prompt.closePrompt": "Cancel",
  },

  "login": {
    "login": "Login",
    "email": "Email",
    "password": "Password",
    "signup": "Register",
    "password.forgot": "Forgotten password",
    "message.error": "Autenthication failed",
  },

  "forgot": {
    "email": "Email",
    "reset": "Reset",
    "login": "S'identifier",
    "message.error": "Unknown mail address!",
    "password.new": "New password",
    "message.mail.sent": `An email has been sent to %{email} to reset your password.
Check you spam folder.`,
    "message.error.invalid.link": "Invalid link !",
    "password.change": "Change",
    "message.fields.password.short":
      "Votre mot de passe devrait contenir au moins 6 caractères",
    "message.password.changed":
      "Your password has been successfully reset, you can login with this new password",
  },

  "signup": {
    "firstname": "Firstname",
    "lastname": "Lastname",
    "email": "Email",
    "password": "Password",
    "password.confirm": "Password (again)",
    "register": "Register",
    "login.go": "Already registered ? Login here",
    "message.fields.mandatory": "Please fill all mandatory fields (*)",
    "message.fields.email.wrong": "Email format not valid",
    "message.fields.email.exists": "Email déja enregistré",
    "message.fields.password.short":
      "Your password must be at least 6 characters",
    "message.fields.password.no.match": "Passwords do not match",
    "message.fields.terms": `Please, accept the terms of use`,
    "message": `I have read and accept the`,
    "terms": `terms of use`,
  },
  "ruffier": {
    "title": "Ruffier's test",
    "heart.rate.instructions": {
      1: `Lay down for 3 minutes, then enter your heart rate`,
      2: `Do 30 complete squats in 45 seconds, then immediately enter your heart rate`,
      3: `Lay down for 1 minute, then enter your heart rate`,
    },
    "heart.rate": {
      1: "heartbeats/minute",
      2: "heartbeats/minute",
      3: "heartbeats/minute",
    },
    "score.label": {
      100: "Very good adaptation to effort",
      75: "Good adaptation to effort",
      50: "Average adaptation to effort",
      15: "Insufficient adaptation to effort",
      5: "Bad adaptation to effort",
    },
    "compute": "Compute",
    "reset": "Reset",
  },

  "ligne.align": "",
  "passwordChange": {
    "page.title": "Change password",
    "password.actual": "Current Password",
    "password.new": "New Password",
    "password.confirm": "New password (again)",
    "change": "Change",
    "error": "Error, wrong password",
  },
  "account": {
    "page.title": "Account",
    "general.settings": "General settings",
    "tools": "Usefull",
    "premium": "Premium until %{endDate}",
    "no.valid.premium": "Get Premium",
    "analysis.available": "Remaining analyses : %{analysesAvailable}",
    "no.analysis.available": "Have your technique analyzed",
    "last.analysis.date": "Last analysis:",
    "next.analysis.date": "Next analysis:",
    "next.analysis.date.available": "Available",
    "manage.stripe": "Subscription and payment",
    "subscription": "Subscription and analysis",
    "find.us": "Find us",
    "personal.infos": "Personal informations",
    "services": "My services",
    "my": "Mon compte",
    "password.change": "Change password",
    "sign.out": "Log out",
    "delete": "Delete my account",
    "delete.warning": `This action will remove your account and all the data associated. It cannot be undone.
Do you wishto proceed?
`,
    "delete.cancel": "No, keep my account",
    "delete.confirm": "Yes, delete",
  },
  "profile": {
    "profile": "Personal informations",
    "firstname": "Firstname",
    "lastname": "Lastname",
    "email": "Email",
    "gender": "Gender",
    "age": "Age",
    "year": "Year",
    "month": "Month",
    "day": "Day",
    "country": "Country",
    "language": "Language",
    "distance.unit": "Distance unit",
    "distance.mile": "Mile",
    "distance.kilometer": "Kilometer",
    "message.update.done": "Profile updated",
  },
  "M": "Male",
  "F": "Female",
  "O": "Other",

  "pending": {
    label: "Welcome %{firstName} !",
    content: `Your registration is pending validation.
Please be patient !`,
  },

  "home": {
    "shoesPrescription": "Shoe advice",
    "coach": "Coach :",
    "slowingFactor": "Slowing factor :",
    "improvement.area": "Improvement area:",
    "improvement.area.speed": "Speed",
    "improvement.area.endurance": "Endurance",
    "action.times": "My times",
    "action.times.add": "Add",
    "action.training.next": "Next session",
    "action.training.sessions": "Sessions",
    "action.runner.profile": "Runner profile",
    "action.course": "Session",
    "action.book": "Book",
    "action.analysis": "My Technique Analyses",
    "action.drills": "Exercises Library",
    "action.learn": "Online course",
    "action.programs": "Programs",
    "action.stats": "Stats",
    "action.paceSpeed": "Pace converter",
    "action.warmup.technique": "Warm-up & Technique",
    "action.auto.masssage": "Self Massage (soon)",
    "action.analysis.get": "Get a running technique analysis",
    "action.premium.get": "Get premium!",
    "action.analysis.wait": "%{days} days left before next analysis",
    "action.analysis.inprogress.0": "Today is the day !",
    "action.analysis.inprogress.1":
      "Analysis available tomorrow, or sooner :-)",
    "action.analysis.inprogress": "Analysis available in %{days} days ...",
    "action.analysis.inprogress.late":
      "Oops, it looks like I was overwhelmed ...",
    "action.analysis.ready": `Yay! Your analysis is available !!!`,
    "action.analysis.invalid":
      "Please resend video, see notifications for details",
    "action.daily.cue": "Cue of the day",
    "class.next": "Next session",
    "class.next.none": "No session to display yet",
    "perf.time": "time",
    "perf.date": "date",
    "perf.distance": "distance",
    "perf.new": "New time",
    "perf.isReal": "Done",
    "perf.isReal.not": "Working pace",
    "perf.new.hour": "hours",
    "perf.new.minute": "minutes",
    "perf.new.seconds": "seconds",
    "perf.all": "Lastest times",
    "perf.none": "No times to display yet",
    "perf.log": "Previous times",
    "perf.error.submission": "Please fill all mandatory fields",
  },

  "usertraining": {
    "course.warmup": "Warm-up",
    "course.technique": "Technique",
    "course.warmup.technique": "Warm-up & Technique",
    "course.training": "Training",
    "course.focus": "Focus / Comments",
    "course.cooldown": "Cool down",
    "course.speed": "Speed",
    "course.pace": "Pace",
  },
  "paceSpeed": {
    minutes: "minutes",
    seconds: "seconds",
    Semi: "Half",
  },
  "metronome": {
    title: "Metronome",
  },

  "faults": {
    label: "Area of improvement:",
    scratching_landing: "Scratchy landing",
    overpulling: "Overpulling",
    excessive_arm_movement: "Excessive arm movement",
    vertical_oscillation: "Vertical oscillation ",
    resistance_to_falling: "Resistance to falling",
    active_landing: "Active landing",
    heel_striking: "Heel striking",
    bending_waist: "Bending at the waist",
    overstriding: "Overstriding",
    pulling_late: "Pulling late",
    noisy_landing: "Noisy landing",
  },

  "AdviceEdit.title": "Clue from the coach",

  "runnerprofile": {
    "runner.profile": "Runner profile",
    "fatigueFactor": "Fatigue factor",
    "noPerfs": `No data available,
 enter your 400m and 1600m times`,
    "improvement.area": "Improvement area",
    "improvement.area.speed": "Speed",
    "improvement.area.endurance": "Endurance",
    "main.deviation": `Main technique deviation`,
    "technique.focus": `Technique focus configured`,
    "perf.all": "Lastest times",
  },

  "courses.new": "New session",

  "course": {
    "isAvailable": "Published",
    "isAvailable.not": "Draft",

    "attendees": "Attendees",
    "group": "Group",
    "group.or.new": " Or create ",
    "flexibility": "Flexibility",
    "warmup": "Warm-up",
    "warmup.drills": "Warm-up & Technique",
    "training": "Training",
    "drills": "Drills",
    "technique": "Technique",
    "strength": "Strength",
    "selfcare": "Selfcare",
    "mobility": "Mobility",
    "preview": "Preview",
    "lesson": "Lesson",
    "focus": "Focus/Comments",
    "cooldown": "Cool-Down",
    "athletes.my": "My athletes",
    "description": "Description",
    "publicDescription": "Public description",
    "trainingLogs": "Comments",
    "drill.name": "Name",
    "drill.category": "Category",
    "drill.description": "Description",
    "drill.duration": "Duration",
    "drill.video.url": "Analysis",
    "notification.default.title": "New training session",
    "notification.default.message":
      "Hello, your next training session is available!",
  },

  "help": {
    "focus.header": "Focus ",
    "focus.content.pose": `Your technique analysis shows that your main improvement area is Pose.`,
    "focus.content.fall": `Your technique analysis shows that your main improvement area is the Falling phase.`,
    "focus.content.pull": `Your technique analysis shows that your main improvement area is the Pulling phase.`,
    "focus.content.neutral": `Your technique analysis shows that your do not have a specific improvement area.
(Or, your first analysis is in progress at the moment, and you do not have a specific focus yet)`,
    "focus.content.generic.pose": `The generated protocols will target an improvement of your Pose.
They will include more drills to help you arrive faster in Pose with better accuracy, to be more efficient.`,
    "focus.content.generic.fall": `The generated protocols will target an improvement of your Falling phase.
They will include more drills to help you to use gravity more efficiently and help you run faster.`,
    "focus.content.generic.pull": `The generated protocols will target an improvement of your Pulling phase.
They will include more drills to help you reduce time on support, improve the timing of your pull and help you run longer.`,
    "focus.content.generic.neutral": `The generated protocols will help you impove your overall technique, Pose, Fall and Pull.
They will help you improve the accuracy of your Pose, the use of gravity, and help you run longer.`,
    "cadence.header": "Cadence",
    "cadence.content": `Cadence is tyour number of steps per minute.
The lowest number recommended is 180.
 Such or higher level of frequency allows to use the elastic property of our muscles.
 After an adaptation period, usage of elastic properties of muscles reduces oxygen consumption around 20%.`,
    "slowingFactorAndProfile.header": "Slowing factor and improvement area",
    "slowingFactorAndProfile.content": `Your slowing factor is computed with your latest times done on 400 and 1600m.
If done in :
- less than 30 days, the factor is accurate (green check mark),
- less than 60 days the factor is average (orange minus sign).
- more than 60 days not very accurate ( red exclamation point).`,
    "framecount.header": "Frame count",
    "framecount.content": `The framecount is one of the tools used to assess the quality of a stride.
The best theorical score with a 60 fps camera is 2:2;
- 2 frames from initial contact to Pose (yours is %{framesToPose})
- 2 frames from pose to end take-off of the support foot (yours is %{framesFromPose})`,
    "faults.overstriding.content": `Overstring reduces efficiency.
Landing to far ahead of the body leads to a breaking effect.
Trying to push off, increases the delay to get back in pose`,
    "faults.scratching_landing.content": "Scratchy landing",
    "faults.overpulling.content": "Overpulling",
    "faults.excessive_arm_movement.content": "Excessive arm movement",
    "faults.vertical_oscillation.content": `Vertical oscillation is a loss of efficicency in a movement that should be as linear as possible.
The cause may be an attempt to push off with the foot or the quadriceps or a constant tension on the foot with a heel that never touches the ground.`,
    "faults.resistance_to_falling.content": `Resistance to falling is the main raison for a lack of speed.
Running is free falling, that fall is the only way to create speed.`,
    "faults.active_landing.content": "Active landing",
    "faults.heel_striking.content": "Heel striking",
    "faults.bending_waist.content": "Bending at the waist",
    "faults.pulling_late.content": "Pulling late",
    "faults.noisy_landing.content": "Noisy landing",

    "gear.available.content": `If some gear are not available, Molokoy will not generate any session with those.
Also, even if they are available, it does not mean that the protocol will necessarily include them.`,
  },

  "linealign": `









`,
  "notifications.none": "No notification to display yet !",
  "notifications.item.deleted": "Notification deleted !",

  "notification": {
    "notification.athletes.my": "My athletes",
    "sendTo": "Send to",
    "notification": "Notification",
    "notifications": "Notifications",
    "content": "Content",
    "title": "Title",
    "message": "Message",
  },
  "user.notification": {
    "welcome.title": "Welcome to Molokoy, %{firstName}!",
    "welcome.content": `Molokoy was crafted to provide a little support in your journey to become a better runner,
 I hope it will help you along the way.
 Feel free to give any feedback or remark, drop me an email at help@molokoy.io.
 See you on video,
 Jey.`,
    "quick.access.title": `Tip : Add Molokoy to your phone's homescreen`,
    "quick.access.content": `For a better experience, add Molokoy to your phone's homescreen to access it easily like any other app.
 `,

    "video.received.title": "%{firstName}! I received your video!",
    "video.received.content": `You can start using the Warm-up & Technique generic sessions right away.
Just use the button "Your warm-up & Drills of the day" and enjoy a guided session !
Once your analysis is done, the generated sessions will be personalized to you.
See you on video,
Jey.`,
    "video.ready.title": "Your analysis is ready!",
    "video.ready.content": `It is available using the first button of the main menu.
From now on, the different sessions will be personalized and will target your main improvement area.
Enjoy your drills!
See you on video,
Jey.`,
    "analysis.8.reminder.title": "It is time for a new analysis !",
    "analysis.8.reminder.content": `It has been a long time since your last analysis, let's see how your technique has evolved!
Don't forget to send me your video,
Jey.`,

    "video.invalid.title": `Your video is not usable :-(`,
    "video.invalid.content.1": `I am sorry, but I could not analyse your video because I can not see enough steps.
Please redo a video, and make sure your phone is in LANDSCAPE mode.
Thank you!
Jey.`,

    "video.invalid.content.2": `I am sorry, but I could not analyze your video.
Please redo a video meeting the requirements, make sure the runner is properly filmed from the side and is running from LEFT to RIGHT.
 Thank you!
Jey.`,

    "video.invalid.content.3": `I am sorry, but I could not analyze your video.
Please redo a video meeting the requirements, make sure the runner is running on an even surface with his feet clearly visible at every moment.
 Thank you!
See you on video,
Jey.`,
  },
  "drillByCategory": {
    title: "Drills",
    warmup: "Warm-Up",
    pose: "Pose",
    fall: "Fall",
    pull: "Pull",
    combined: "Combined",
    strength: "Strength",
    correction: "Correction",
    flexibility: "Flexibility",
  },

  "drillCreate": {
    "name": "Drill name",
    "category": "Category",
    "description": "Description",
    "image.url": "Image url ",
    "video.url": "Video url",
    "duration": "Duration",
    "gear": "Gear",
  },

  "sessionElement": {
    setup: "Setup",
    execution: "Execution",
    focus: "Focus",
    instructions: "Instructions",
    repetitions: "Repetitions",
  },

  "usertrainings": {
    none: "No training to display yet",
    nextWeeks: "Next weeks",
  },

  "traininglog": {
    "comments.label": "Times, feelings ...",
    "comments.placeholder": "Times, feelings ...",
    "done.label": "Done",
    "done.not.label": " Not done",
    "date.label": "on",
  },
  "protocolsPlan": {
    new: "New plan",
    protocols: "Protocols",
  },
  "protocol": {
    "page.title": "Warm-up & Technique",
    "warmup": "Warm-up",
    "drills": "Technique",
    "technique": "Technique",
    "strength": "Strength",
    "selfcare": "SelfCare",
    "selfCare": "SelfCare",
    "flexibility": "Flexibility",
    "lightFlexibility": "Light Flexibility",
    "cooldown": "Cool down",
    "workout": "Workout",
    "focus": "Focus",
    "pose": "Pose",
    "fall": "Fall",
    "pull": "Pull",
    "neutral": "Neutral",
    "new": "New protocol",
    "isAvailable.not": "Draft",
    "isAvailable": "Published",
    "suggestRunWarmup": "Running Warmup",
    "copy": "Copy",
    "delete": "Delete",
    "isSequential.not": "Not sequential",
    "isSequential": "Sequential",
    "type": "Type",
    "gear.needed": "Equipement you will need:",
    "gear.available": "Available equipment",
    "gear.additional": "Additionnal available equipment",
    "gear.roller": "Foam roller",
    "gear.lacrosse": "Lacrosse ball",
    "gear.bar": "Barbell",
    "gear.wall": "A wall near by",
    "gear.wall.details": "or something to lean on",
    "gear.jump rope": "Jump Rope",
    "gear.band": "Band",
    "generate": `Create today's session`,
    "start": "Let's roll !",

    "workout.amrap": "For %{amrap}",
    "workout.repeat": "Repeat %{repeat} time ",
    "workout.repeats": "Repeat %{repeat} times ",
    "workout.at.cadence": "at a cadence of ",
    "workout.rest.for": "rest ",
    "workout.drill": "Drill : ",
    "workout.drill.not.available":
      "Your personalized drill will be available once your analysis is done.",
    "workout.into": " into run for ",
    "workout.run.for": "Run for ",
    "include.prerun": "Include my warm-up & technique work",
    "include.prerun.start": "Start",
    "pace": {
      "faster": "faster",
      "faster.detail": "Sprint pace that you can hold on to for all sets",
      "fast": "fast",
      "fast.detail": "Mile best time pace",
      "tempo": "tempo",
      "tempo.detail": "A challenging but sustainable pace",
      "moderate.fast": "moderate/fast",
      "moderate.fast.detail": "5k pace",
      "moderate": "moderate",
      "moderate.detail": "10k pace",
      "easy": "easy",
      "easy.detail": "Slower than your marathon pace",
      "slow": "slow",
      "slow.detail": "Recovery pace",
      "race pace": "race pace",
      "race pace.detail": "Your goal pace for your upcoming race",
      "race pace ++": "race pace ++",
      "race pace ++.detail":
        "Slightly faster than your goal pace for your upcoming race",
    },
  },
  "workout.edit": {
    repeat: "Repeats",
    distance: "Distance",
    time: "Time",
    drill: "Drill",
    drillPose: "Pose Drill",
    drillFall: "Fall Drill",
    drillPull: "Pull Drill",
    custom: "Custom",
    cadence: "Cadence",
    pace: "Pace",
    rest: "Rest",
    instruction: "Instruction",
  },

  "analysisCreate": {
    athlete: "Athlete's name",
    urlCode: "Youtube video code",
  },

  "analysis": {
    "analysis": "Analysis",
    "update.needed": "New features available!",
    "update.explanation": `We have released a new awesome format, with new features to help you understand better your analysis.
The old format is not available anymore, but you can ask for a free update for this analysis!`,
    "update.get": "Upgrade this analysis!",
    "update.inprogress": "Upgrade in progress!",
    "update.inprogress.explanation": `Your updated analysis will be available in one or two days!`,
    "fps": "frames/s",
    "isAvailable.not": "Draft",
    "isAvailable": "Published",
    "isInvalid": "Invalid",
    "framecount": "Framecount",
    "frameToPose": "Frames to Pose",
    "frameFromPose": "Frames from Pose",
    "display": "View details",
    "score.display": "Hide details",
    "technique.score": "Technique score",
    "time.initialContact": "Initial contact",
    "time.inPose": "Pose",
    "time.endFall": "End of Fall",
    "time.lastContact": "End of contact",
    "time.pulling": "middle of pullinge",
    "count.frames": "Count frames",
    "execution.cadence": "Steps/min",
    "cadence.mark": "Mark",
    "cadence.compute": "Compute",
    "unified": "Unified",
    "right": "Right",
    "left": "Left",
    "dissociated": "Left/Right",
    "support.foot": "Support foot",
    "video": "Full analysis",
    "full": "View analysis",
    "initialContactTime": "Landing",
    "inPoseTime": "Pose",
    "fallEndTime": "Fall",
    "lastContactTime": "Take off",
    "middleOfPullTime": "Pulling",
    "inNextPoseTime": "Next pose",
    "initialContactTimeL": "Landing",
    "inPoseTimeL": "Pose",
    "fallEndTimeL": "Fall",
    "lastContactTimeL": "Take off",
    "middleOfPullTimeL": "Pulling",
    "inNextPoseTimeL": "Next pose",
    "cadenceMark0": "Cad. 0",
    "cadenceMark3": "Cad. 3",
    "cadenceMark4": "Cad. 4",

    "execution.pose": "POSE",
    "execution.framesToPose": "Initial contact to Pose",
    "execution.poseExecutionLanding": "Initial contact",
    "execution.poseExecutionLanding.1": "Far from body",
    "execution.poseExecutionLanding.2": "Close to body",
    "execution.poseExecutionLanding.3": "Under the body",
    "execution.poseFramesToPose": `Frames to Pose`,
    "execution.poseExecutionKnee": "Bent knee",
    "execution.poseExecutionKnee.1": "Locked",
    "execution.poseExecutionKnee.3": "Bent",
    "execution.poseExecutionVertical": "Verticality at Pose",
    "execution.poseExecutionVertical.1": "Drastically ahead",
    "execution.poseExecutionVertical.2": "Ahead",
    "execution.poseExecutionVertical.3": "Vertical",
    "execution.fall": "FALL",
    "execution.fallExecutionAlignment": "Body alignment",
    "execution.framesFalling": "Frames falling",
    "execution.fallExecutionAlignment.0": "Shoulders back",
    "execution.fallExecutionAlignment.1": "Bent at the waist",
    "execution.fallExecutionAlignment.2": "Slightly bent at the waist",
    "execution.fallExecutionAlignment.3": "Aligned",
    "execution.fallExecutionTime": "Maintaining Pose",
    "execution.fallExecutionTime.1": "Short",
    "execution.fallExecutionTime.2": "Average",
    "execution.fallExecutionTime.3": "Long",
    "execution.fallExecutionFrames": `Frames falling`,
    "execution.pull": "PULL",
    "execution.pullExecutionTiming": "Frames on support after falling,(timing)",
    "execution.framesOnSupportAfterFalling": "Unnecessary time on support",
    "execution.pullExecutionTiming.1": "Late",
    "execution.pullExecutionTiming.2": "Average",
    "execution.pullExecutionTiming.3": "Good",
    "execution.pullExecutionLegBent": "Trailing leg bent",
    "execution.pullExecutionLegBent.1": "Competely extended",
    "execution.pullExecutionLegBent.2": "Almost extended",
    "execution.pullExecutionLegBent.3": "Bent",
    "execution.pullExecutionDirection": "Pulling direction",
    "execution.pullExecutionDirection.0": "Far back/No pulling",
    "execution.pullExecutionDirection.1": "Back",
    "execution.pullExecutionDirection.2": "Slightly back",
    "execution.pullExecutionDirection.3": "Towards the hips",
    "execution.pullBreakingAngle": "Breaking angle",
    "execution.notes": "Remarks",
    "fault.focus": "Fault & Technique focus",
    "fault": "Main fault",
    "focus": "Main improvement area",
    "focus.display": "Focus",
    "video.loading": "Loading your video",
    "video.searching": "Almost there...",

    "cues": {
      initialContactTime: "Usually one frame after initial contact",
      initialContactTimeL: "Usually one frame after initial contact",
      inPoseTime: "Usually one frame before heel moves up",
      inPoseTimeL: "Usually one frame before heel moves up",
      fallEndTime: "Frame where Ankle passes support knee",
      fallEndTimeL: "Frame where Ankle passes support knee",
      lastContactTimeL: "Frame where toes are not flexed anymore",
      lastContactTime: "Frame where toes are not flexed anymore",
      middleOfPullTime:
        "One frame after foots start to move forward more than up",
      middleOfPullTimeL:
        "One frame after foots start to move forward more than up",
      inNextPoseTime: "Next pose, support foot close to vertical",
      inNextPoseTimeL: "Next pose, support foot close to vertical",
    },
  },

  "cues": {
    "label": `Coach's cue`,
    "general.loose.ankle": `Keep the ankle relaxed, excessive tension in the air will only put useless strain on your lower leg. Your ankle will naturally adjust to the needed tension when on support.`,
    "general.less.noise": `Run as silently as possible.Too much noise on landing means a lack of impact absorption. Trying to run silently will unconsciously help to reduce the impact on the ground.`,
    "general.heels.touch": `Allow your heels to kiss the ground with each stride to take the strain off your Achilles tendon. Do not try to run only on the ball of your of foot.`,

    "pose.alignement": `Keep your torso upright, with a neutral gaze. When you are broken at the hips with your butt back, you cannot fall forward efficiently.`,
    "pose.bodyweight": `Feel your body weight on your ball of foot, but allow your heel to slightly kiss the ground at each step.`,
    "pose.knee.bent.reaching": `Keep the knee of your support leg bent when you run, it prevents your leg reaching out in front what causes a damaging breaking effect.`,
    "pose.knee.bent": `Keep your knees bent when you run to reduce the impacts of the landing and maintain muscle elasticity.`,

    "fall.from.hips": `Feel the fall from the hips, not the shoulders, with an upright torso. It is the correct way to create running speed from gravity.`,
    "fall.intention": `Keep in mind your "intent" to fall at any speed and especially when you start to get tired.`,
    "fall.waist.pulled": `Imagine being pulled forward by a string tied to your waist, It will help you feel how moving your hips forward creates movement and speed.`,
    "fall.minimal.effort": `Focus on intentional falling and letting gravity pull you forward, try to use the least amount of muscle effort`,

    "pull.actively": `Pull actively your support foot towards your hips to get back in POSE, do not let it lag behind, it hinders the proper timing of your stride and slows you down.`,

    "pull.active.landing": `Pull your support foot in a straight line to your hips, do not focus on how your other foot lands. This will help you to stop from reaching out in front trying actively to land on your forefoot.`,
    "pull.support.foot.first": `Focus on having your support foot getting of the floor before your airborne foot lands. Do not focus at all on how your foot lands.`,

    "pull.too.high": `Maintain your timing of your pull, which is good, but reduce the magnitude. You are pulling your foot too high regarding your speed, thus wasting energy. `,
    "pull.to.hips": `Pull your foot off the ground in a straight line toward your hips rather than in an arc to return to Pose with optimal timing.`,
    "pull.cadence": `Try to pull your foot from the floor at a slightly higher cadence that you would normally do. (The minimum required numbers of steps par minute is around 180). On the long term, you will be more efficient and use less energy.`,
  },

  "useranalyses": {
    "analysis": "Analysis",
    "technique.analysis": "Running Technique analysis",
    "technique.analysis.none": `No analysis yet ?`,
  },

  "detailedanalysis": {
    initialContactTime: "Landing",
    inPoseTime: "Pose",
    fallEndTime: `Acceleration`,
    lastContactTime: `Take off`,
    middleOfPullTime: "Pull",
    timeLine: {
      initialContactTime: "Landing",
      inPoseTime: "Pose",
      fallEndTime: `Acceleration`,
      lastContactTime: `Take off`,
      middleOfPullTime: "Pull",
    },
  },

  "userstats": {
    "stats": "Statistics",
    "drills.performed": "Drills performed",
    "drills.performed.display": "Display drills",
    "technical.score": "Technique Score",
    "pose.score": "Pose",
    "fall.score": "Fall",
    "pull.score": "Pull",
    "score.drills": "Technique score and drills by month",
    "evolution": "Evolution of my technique score",
  },

  "analyseme": {
    "send.video": "Send my video",
    "sent.video": "Video uploaded !",
    "select.video": "Select video file",
    "tosend": "Send",
    "message.sucess.header": `Awesome!`,
    "message.sucess.content": `I got your video,
your analysis will be available in about %{delay} days!`,
    "message.sucess.content.tomorrow": `I got your video,
your analysis will be available tomorrow.`,
    "message.sucess.content.1": `Check your email for what's next :-) `,
    "home.link": `Back to home screen`,
    "inprogress": "Your video is already scheduled for analysis :-)",
    "instructions.intro": "Please, follow the instructions for your filming",
    "instructions.intro.1": `You will need a friend ... or a tripod`,
    "instructions.outside": "Outside",
    "instructions.midday": "Midday",
    "instructions.flat": "Flat surface",
    "instructions.even": "Stable and even ground",
    "instructions.zoom.landscape": "LANDSCAPE mode",
    "instructions.zoom.profile": `The runner is viewed from THE SIDE`,
    "instructions.zoom.distance": `Film at 5 large steps away from the runner's trajectory`,
    "instructions.run.left": "Left",
    "instructions.run.right": "Right",
    "instructions.run.no.move": "Camera DOES NOT MOVE",
    "instructions.run.left.to.right": "Runner is going from LEFT to RIGHT",
    "instructions.run.speed.1": `Accelerate to next goal pace BEFORE entering frame`,
    "instructions.go.settings": `Set your video capture settings at 60fps, for more accurate analysis`,
    "instructions.equipement": `Use the same device for all your videos sent`,
    "instructions.check.review": `Please check the following items`,
    "instructions.check.strides": `4 COMPLETE strides are visible`,
  },
  "services": {
    "services": "Services",
    "page.title": "Improve and track your running technique!",

    "promise": "Run better, faster, longer",
    "promise.content.1":
      "Your technique analyzed by a Pose Method® Head Instructor",
    "promise.content.2":
      "Programs and sessions that adapt to your analysis results",
    "promise.content.3": "Innovative system to track your progress easily",
    "choose.plan": "Choose your plan",
    "current.plan": "Your current plan",
    "choose.analyses": "Choose your pack",
    "analyses.explanation": `The innovative analysis report helps you understand the deviation of your running technique.
Molokoy continuously adjusts your technical work based on the results of your most recent analysis.
The analyses packs do not expire, you can use them as you want, with or without an active plan.`,
    "shop": "Sign me in !",
    "checkout": "Checkout",
    "coupon": "Discount Code",
    "coupon.couponNotFoundOrInvalid": "Discount code not found or expired",
    "item.unavailable.title": "Service unnecessary",
    "item.unavailable.content": `Your current services make this one useless for now :-)`,
    "userservice.link.label": `View your current services`,
    "premium.monthly": "monthly",
    "premium.biannual": "half-yearly",
    "premium.analysis.included": "1 free analysis",
    "analysis_1": "1 Analysis",
    "analysis_2": "2 Analyses",
    "analysis_4": "4 Analyses",
    "analysis": "analysis",
    "analyses": "analyses",
    "per.month": "month",
  },
  "line_alignment.1": `



`,
  "userServices": {
    "page.title": "My services",
    "active.services": "Active services",
    "inactive.services": "Past services",
    "running": "Services en cours",
    "available.analyses": "Analyses available",
    "used.analysis": `Past analyses`,
    "available.analyses.left": "left",
    "available.analyses.lefts": "left",
    "until": "End",
    "since": "Start",
    "buy": "View the services",
    "none": "No active subscription, yet...",
  },

  "shoesPrescriptions": {
    "page.title": "Shoes advices",
    "surface": "Surface",
    "trainingFrequency": "Weekly frequency",
    "cushion": "Cushion",
    "drop": "Drop",
    "stack": "Stack",
    "model": "model",

    "surface.road": "Road",
    "surface.off-road": "Off road",
    "surface.trail": "Trail",

    "trainingFrequency.1": "once ",
    "trainingFrequency.3": "3 times",
    "trainingFrequency.more": "more than 3 times",

    "cushion.none": "none",
    "cushion.light": "light",
    "cushion.medium": "medium",

    "stack.minimalist": "minimalist",
    "stack.light": "light",
    "stack.medium": "medium",
  },

  "success": {
    "page.title": "Right on !",
    "page.content.title": "Registration done",
    "page.content": `The corresponding services are available !`,
  },
  "analysis.text": {
    "left": "left",
    "right": "right",
    "nl": `
`,

    "standard": "The reference",
    "yourExecution": "Your analysis",
    "pose": `pose`,
    "fall": `fall`,
    "pull": `pull`,
    "misc": `Miscellaneous`,
    "analysing": "Analysing ",

    "********": `
	***************************************************************************************************
	`,

    "POSEcriteria": `POSE`,
    "poseIntro": `Pose is the body position that allows us to move by falling under the effect of gravity.
The goal here is to be in POSE as fast as possible. Your foot should land directly underneath your body, with a bent knee.
Your ball of foot should touch the ground first, and followed by your heel.
Your point of support, hips and shoulders should be on a straight and vertical line.`,
    "poseExecutionLanding.1": `You are landing too far in front of your body.
That creates a breaking effect on each landing and puts unnecessary pressure on your knees.
`,
    "poseExecutionLanding.2": `You are landing close to your body but still a bit in front.
That puts unnecessary leverage on your knees and creates a breaking effect that slows you down.`,
    "poseExecutionLanding.3": `You are landing very close to your body, on your ball of foot. This reduces to a minimum the breaking effect from landing. That's amazing!
`,
    "poseExecutionLanding.side.1": `Your %{side} foot is landing too far in front of your body.
That creates a breaking effect on each landing and puts unnecessary pressure on your knees. `,
    "poseExecutionLanding.side.2": `Your %{side} foot is landing close to your body but still a bit in front.
That puts unnecessary leverage on %{side} your knees and creates a breaking effect that slows you down.`,
    "poseExecutionLanding.side.3": `Your %{side} foot is landing very close to your body, on your ball of foot.
This reduces to a minimum the breaking effect from landing. That's amazing! `,

    "poseExecutionKnee.1": `You are also landing with your ankle, knee and hip locked.
Landing with a bent knee would reduce the impact on your joints.`,
    "poseExecutionKnee.3": `You land with a bent knee. It helps to reduce the impact of running on your joints. Awesome !`,
    "poseExecutionKnee.side": ``,
    "poseExecutionKnee.side.1": `On your %{side} side, you are landing with your ankle, knee, and hip locked.
Landing with a bent knee would reduce the impact on your joints %{side} knee`,
    "poseExecutionKnee.side.3": `On your %{side} side, you are landing with a bent knee. It helps to reduce the impact of running on your joints. Awesome !`,

    "poseExecutionVertical.1": `At midstance (POSE) you are inclined already. You should be completely vertical to take advantage of your full falling potential.
`,
    "poseExecutionVertical.2": `At midstance (POSE) you are inclined already. You should be completely vertical to take advantage of your full falling potential.
`,
    "poseExecutionVertical.3": `At midstance (POSE) you are completely vertical, good job ! This is the ideal starting point of falling  to make the most effective use of gravity acceleration.`,
    "poseExecutionVertical.side": ``,
    "poseExecutionVertical.side.1": `At midstance (POSE) on your %{side} foot, you are too inclined already. You should be completely vertical to take advantage of your full falling potential.


`,
    "poseExecutionVertical.side.2": `On the %{side}, at midstance (POSE) you are inclined already. You should be completely vertical to take advantage of your full falling potential and get faster.`,
    "poseExecutionVertical.side.3": `On the %{side} side, you are completely vertical. That is good! This is the ideal starting point of falling  to make the most effective use of gravity acceleration.`,

    "FALLcriteria": `FALL`,
    "fallIntro": `The key to speed is the ability to fall forward efficiently under the effect of gravity.
The higher your angle of fall, the faster you will run.

It has been shown that to fall efficiently you must keep your airborne foot under your hips as long as possible.
As soon as your foot starts to swing forward the geometry of the falling system changes and stops accelerating using gravity.

The fear of falling is very deep-rooted in adults, and is a psychological barrier that often manifests itself in unconscious postures that tend to slow the fall forward (shoulders or pelvis backwards)
You should fall with a rather upright torso, keeping your shoulders over your hips.

You should keep your airborne foot under your hip, and avoid the knee drive.`,
    "fallExecutionAlignment.0": `You are resisting falling by keeping your shoulders back, they should be above your hips almost on the same vertical line. You could run much faster with the same amount of effort by using gravity more efficiently.`,
    "fallExecutionAlignment.1": `You are bent at the waist, your hips are BEHIND your shoulders, they should be UNDER them almost on the same vertical line.
Bending at the waist hinders your ability to use your center of mass to fall, you could go faster with the same amount of effort.`,
    "fallExecutionAlignment.2": `You are slightly bent at the waist, your hips are BEHIND your shoulders, they should be UNDER them almost on the same vertical line.
Bending at the waist hinders your ability to use your center of mass to fall, you could go faster with the same amount of effort.`,
    "fallExecutionAlignment.3": `You are falling with a rather upright torso, keeping your shoulders over your hips. That is excellent!
This is the ideal position that allows the best alignment of your body to create speed using gravity.`,
    "fallExecutionAlignment.side.1": `Standing on your %{side}, you are bent at the waist, your hips are BEHIND your shoulders, they should be UNDER them almost on the same vertical line.
Bending at the waist hinders your ability to use your center of mass to fall, you could go faster with the same amount of effort.`,
    "fallExecutionAlignment.side.2": `Standing on your %{side}, you are slightly bent at the waist, your hips are a little BEHIND your shoulders, they should be UNDER your shoulders should be almost on the same vertical line.
Bending at the waist hinders your ability to use your center of mass to fall, you could run faster with the same amount of effort.`,
    "fallExecutionAlignment.side.3": `Standing on your %{side}, you are falling with a rather upright torso, keeping your shoulders over your hips. That is excellent!
This is the ideal position that allows the best alignment of your body to create speed using gravity.`,

    "fallExecutionTime.1": `Your airborne foot swings forward and down too soon, it should be kept under your hip.
Focus on falling, not about landing, your foot will drop naturally (instinctively) at the end of your falling phase.`,
    "fallExecutionTime.2": `Your airborne foot swings forward and down a little bit too soon, it should be kept under your hip.
Focus on falling, not about landing, your foot will drop naturally (instinctively) at the end of your falling phase.

`,
    "fallExecutionTime.3": `Your foot stays under your hip during your falling phase! You are falling as a whole unit, and making the most of your fall.`,
    "fallExecutionTime.side": ``,
    "fallExecutionTime.side.1": `Your %{side} foot swings forward and down too soon.
Focus on falling, not about landing, your foot will drop naturally (instinctively) at the end of your falling phase.`,
    "fallExecutionTime.side.2": `Your %{side} foot swings forward and down a little bit too soon, it should be kept under your hip.
If it stayed a little longer under your hip, you could gain some more speed.`,
    "fallExecutionTime.side.3": `Your %{side} foot stays under your hip during your falling phase! You are falling as a whole unit very efficiently, and making the most of your fall.
You have to do the same with the other foot now!`,

    "PULLcriteria": `PULL`,
    "pullIntro": `Once the falling phase has ended, it is time to change support to arrive in POSE on the other foot, and continue falling.
The only action required to do so is to PULL your foot from the ground, the other foot will land automatically without you having to think about it.

Trying to push off the ground has no effect on the forward motion. In reality, if you try to push, your foot being far behind takes more time to get back in the next Pose, this is wasted time not creating speed (falling).

In fact, Pulling is the only conscious muscular action you have to focus on to run well.
Pull! Pull! Pull!

You must pull your foot under your hips (and not back) to arrive in the next Pose ON TIME and maximize the amount of time you will spend falling.
An effective pull - On time and in the proper direction - will eliminate all the unnecessary movement to make your running more efficient.`,
    "framesOnSupportAfterFalling.1": `Your support foot spends too much time on the ground, it should be pulled actively from the ground after the falling phase to reduce your ground contact time.`,
    "framesOnSupportAfterFalling.2": `Your support foot spends too much time on the ground, it should be pulled actively from the ground after the falling phase to reduce your ground contact time.`,
    "framesOnSupportAfterFalling.3": `Your pulling timing is ideal, you are pulling your foot as soon as the falling phase has ended. You are optimizing your ground contact time, this is remarkable!`,
    "framesOnSupportAfterFalling.side": ``,
    "framesOnSupportAfterFalling.side.1": `You are pulling your %{side} foot late. It should be pulled actively from the ground after the falling phase to reduce your ground contact time.
`,
    "framesOnSupportAfterFalling.side.2": `You are pulling your %{side} foot late. It should be pulled actively from the ground after the falling phase to reduce your ground contact time.
`,
    "framesOnSupportAfterFalling.side.3": `Your pulling timing is ideal, you are pulling your %{side} foot as soon as the falling phase has ended. You are optimizing your ground contact time, this is remarkable! Focus on doing the same with the other foot!`,

    "pullExecutionLegBent.1": `Your back leg is straight when your foot breaks contact with the ground. You are trying to propel yourself by pushing off, this is useless.
You must PULL your foot instead to bring it back under your hip on time.`,
    "pullExecutionLegBent.2": `Your back leg is almost straight when your foot breaks contact with the ground. You are NOT trying to propel yourself by pushing off, which is good, but your foot should be pulled more aggressively to get back to pose on time.`,
    "pullExecutionLegBent.3": `Your back leg is bent when your foot breaks contact with the ground, this is great because, you are pulling your foot from and optimizing the timing of your movement. This is the key to efficient running, good job!`,
    "pullExecutionLegBent.side": ``,
    "pullExecutionLegBent.side.1": `Your %{side} back leg is straight when your rear foot breaks contact with the ground, you are trying to propel yourself by pushing off on this side.
You must PULL your foot instead to bring it back under your hip on time.`,
    "pullExecutionLegBent.side.2": `Your %{side} back leg is almost straight when your rear foot breaks contact with the ground.
You are NOT trying to propel yourself by pushing off, which is good, but your foot should be pulled more aggressively to get back to pose on time.`,
    "pullExecutionLegBent.side.3": `Your %{side} back leg is bent when your rear foot breaks contact with the ground, this side is doing ok!
You are pulling your foot from and optimizing the timing of your movement with this foot.`,

    "pullExecutionDirection.0": `You are not pulling your foot at all, it is lagging behind this hinders the efficiency of your movement.
Your foot should be actively pulled under your hips.`,
    "pullExecutionDirection.1": `Your foot is going up and staying behind, instead of moving toward under your hips.
Your foot's trajectory is not optimal, it should travel in a straight line from the ground to under your hips.
`,
    "pullExecutionDirection.2": `Your foot is going up and staying slightly behind, instead of moving toward under your hips.
Your foot's trajectory is almost optimal,but your foot should travel in a straight line from the ground to under your hips.
`,
    "pullExecutionDirection.3": `Your foot travels in a straight line from the ground to under your hips. Your foot's trajectory is optimal!`,
    "pullExecutionDirection.side": ``,
    "pullExecutionDirection.side.0": `You are not pulling your %{side} foot at all, it is lagging behind this hinders the efficiency of your movement.
Your foot should be actively pulled under your hips.`,
    "pullExecutionDirection.side.1": `You are pulling your %{side} foot behind, it is going up and staying behind, instead of moving toward under your hips.
Your foot's trajectory is not optimal, your foot should travel in a straight line from the ground to under your hips. `,
    "pullExecutionDirection.side.2": `You are pulling your %{side} foot slightly behind, it is going up and staying a little bit behind your body, instead of moving toward under your hips.
Your foot's trajectory is almost optimal, but your foot should travel in a straight line from the ground to under your hips.`,
    "pullExecutionDirection.side.3": `You are pulling your %{side} foot directly towards the hip. Its trajectory is optimal!`,

    "*******": `
	***************************************************************************************************
	`,
  },
  "userAnalysisVideo": {
    "isright.true": "Right",
    "isright.false": "Left",
    "R": "Right",
    "L": "Left",
  },
  "sections": {
    "analysis.explanation": {
      required:
        "Analysis required : your running technique analysis is required to create your individualized technique work.",
      recommended:
        "Analysis required : you can choose to include your individualized technique work from your running technique analysis.",
    },
    "premium.required": "premium",
    "sessions": "sessions",
    "title": "Programs",
    "description":
      "The programs are designed to help you improve your technique and help you run injury free",
    "category": {
      programs: "Basics",
      sessions: "Workouts",
      drills: "Exercise & drills library",
      selfcare: "Self-care library",
      race: "Training programs",
      custom: "Programmes spécifiques",
    },
    "welcome": {
      title: `Welcome, %{firstname} !`,
      description: "",
    },
    "technique": {
      title: `Your warm-up & Drills of the day`,
      description: `A complete warm up and technique work before your today's run.
Every day, you get a different personalized session.
The selected technique exercises are focused on your main area of improvement, highlighted by your video analysis.`,
    },
    "selfcare": {
      title: `Daily mobility & selfcare`,
      description: `Self-care session to help you stay injury free.
Every day you get a different flow to keep it fun.`,
    },

    "library": {
      title: `Programs & exercises`,
      description: "Everything you need to be a better runner",
    },
    "learn": {
      title: "Learn to run better",
      description: `Running better means running faster.
With better technique, you will easily improve your running times!
Improving your running technique will allow you to make the most of your athletic potential. Get after it!
`,
      sessions: "8",
    },
    "cadence": {
      title: "Improve your efficiency",
      description: `By improving your running cadence, you can become more efficient.
This program allows you to gradually build your ability to use your muscle-tendon elasticity to increase your cadence without additional fatigue.
It follows on from the work begun in the"Learn to Run Better"program.`,
      sessions: "12",
    },
    "transition": {
      title: "Improve your technique endurance",
      description: `Once your technique begins to improve, it is important to be able to keep it going lengthwise.
The goal of this program is to help you get past the first milestone, being able to run for 30 minutes with good running technique.`,
      sessions: "12",
    },
    "speed": {
      title: "Run faster",
      description: "Increase your speed",
      sessions: "12",
    },
    "short": {
      title: "Short Intervals",
      description: "Improve & maintain your speed",
    },
    "long": {
      title: "Long Intervals",
      description: "Improve & maintain your stamina",
    },
    "focus": {
      title: "Make your easy run count",
      description: `30 or 45 minutes easy run with personalized drills.
Those sessions are designed to help you reinforce proper technique during your easy runs.`,
    },
    "work": {
      title: "Technique session",
      description: `Personalized technique sessions that can be done even on rest days.
They focus on technique and will not wear you out.`,
    },

    "selfcare_lowerleg": {
      title: "Lower leg",
      description: "Foot, calfs and anterior tibial muscles",
    },
    "selfcare_upperleg": {
      title: "Upper leg",
      description: "Quads, hamstrings and glutes",
    },
    "selfcare_back": {
      title: "Back",
      description: "Lower and upper back",
    },

    "warmup": {
      title: "Warm up",
      description: "",
    },
    "pose": {
      title: "Pose",
      description: "",
    },
    "fall": {
      title: "Fall",
      description: "",
    },
    "pull": {
      title: "Pull",
      description: "",
    },
    "strength": {
      title: "Strength",
      description: "",
    },
    "flexibility": {
      title: "Flexibility",
      description: "",
    },
    "marathon": {
      title: "Marathon",
      description: `This 16 weeks half-marathon program is designed to help you run your best marathon.
The 4 first weeks (16-13) are for foundation work you can choose to skip them and prepare your marathon from week 12.
The paces are for a mid-level runner, feel free to adapt as needed, the goal is to hold the same pace for every interval.
You must have done a running analysis before you start. Then you have two options :
- Three analyses, on weeks 12, 8 and 4, or
- One on the 8th week
After each analysis, Molokoy adapts to your progress to update the technique work.`,
    },

    "half": {
      title: "Half-marathon",
      description: `This 12 weeks half-marathon program is designed to help you run better and take the most out of your athletic potential.
The paces are for a mid-level runner, feel free to adapt as needed, the goal is to hold the same pace for every interval.
You must have done a running analysis before you start. Then you have two options :
- Two analyses, on week 8 and week 4, or
- One on the 6th week
After each analysis, Molokoy adapts to your progress to update the technique work.`,
    },

    "5k": {
      title: "5k",
      description: `This 8 weeks program is designed to help you do your best on your next 5k.
The paces are for a mid-level runner, feel free to adapt as needed, the goal is to hold the same pace for every interval.
You must have done a running analysis before you start. Then you should do a second one on the 4th week
After each analysis, Molokoy adapts to your progress to update the technique work.`,
    },
    "10k": {
      title: "10k",
      description: `This 8 weeks program is designed to make you crush your next 10k.
The paces are for a mid-level runner, feel free to adapt as needed, the goal is to hold the same pace for every interval.
You must have done a running analysis before you start. Then you should do a second one on the 4th week
After each analysis, Molokoy adapts to your progress to update the technique work.`,
    },
  },

  "plan.details":
    "**********************************************************************************************",
  "Half-marathon": "Half-marathon",

  "in place": "in place",
  "run": "run",
  "into": "into",
  "left": "left",
  "right": "right",
  "plank": "Plank (pushup position)",
  "plank face up": "plank face up",
  "alternate left and right": "alternate left and right",

  "5fe370cb576a965e62f8d306": {
    label: "Introduction",
    focus: ``,
    text: `Welcome to this introduction to a better running technique.

It will make you familiar with the core concepts of Pose Method created by Dr. Nicholas Romanov, and guide you gradually through different kinds of flexibility exercises, drills and basic strength for runners.

Focus on improving your technique during these lessons and try not to add more running volume on top of this program.
A good pace is to do 2 to 3 sessions per week, each session will require between 15 up to 40 minutes of your time.
In general, take your time and let your perceptions and body adjust.
Feel free to insist on a particular drill you struggle with, or redo a lesson at your next session before going to the next one.
If you are a complete beginner you should do each lesson 2 times in 2 consecutive sessions.

At the end of this introduction you will know all the basic drills, and have already improved your running technique. You can then use customized Warm up & Technique sessions to continue refining your technique and keep running faster and further while limiting the risk of injury.

Each lesson will have a theoretical explanation and exercises or drills like the ones showed below as example. Once you have completely done a lesson, the next one becomes available.

The exercises will modify the way you run, if you feel any pain, stop immediately and consult your physician.`,
  },
  "5fe21c3f2272d45e4ff01271": {
    label: "Feel the fall",
    focus: `Focus on feeling how gravity can pull you forward while reducing the muscular effort needed to move`,
    text: `It is by learning to better use gravity that we become better runners.
Gravity is mandatory to move, and to run fast. As an illustration, consider that there is less gravity on the moon, do you see how astronauts move slowly?
Your ability to use gravity effectively is directly related to your technique. This means that with the same athletic potential, improving your technique will make you run faster!

Our speed is correlated to the angle at which we fall.

Usain Bolt has the fastest 100 meter dash time and the greatest fall angle achieved: 21.5°!
Our first lesson will be to start feeling gravity while running.
`,
  },
  "5fe2e7292272d45e4ff01272": {
    label: "Correcting the fall",
    focus: ``,
    text: `When we start to focus on falling, we tend to bring the torso forward and fall from the shoulders.
Actually true falling comes from our General Center of Mass (GCM) approximately located slightly above the hips, around our belly button.

That means than to fall efficiently when we have to focus on keeping a rather vertical torso, and try to"fall from the hips".
You must slightly bring your hip forward without arching your back.

Today's drills will focus on keeping a vertical torso while falling.`,
  },
  "5fe370e9576a965e62f8d307": {
    label: "Best falling Position: The Pose",
    focus: `Getting in a correct Pose Position`,
    text: `The most efficient position to fall is called Pose, it is the position where your body is loaded with potential energy that can be transformed to acceleration.
In this position:
- your ball of foot, hips and shoulders are aligned.
- your airborne foot is under your hip (do not mind the height, will talk about that in lesson 6)
- your support leg is bent
- your body weight is on the ball of your foot

Get in this position and feel how your body is stable but can quickly fall forward, when body weight is on the ball of your foot.

In this lesson we will get familiar with this key Pose of running`,
  },
  "5fe370f1576a965e62f8d308": {
    label: "Connecting Pose and Fall",
    focus: ``,
    text: `To take the most out of your falling angle you must keep your airborne foot under your hip as long as possible.
Keeping the airborne foot under the hip maintains the system's equilibrium and allows your body to accelerate as much as gravity allows it.

In this lesson we will connect the perception of falling in Pose
`,
  },
  "5fe370f9576a965e62f8d309": {
    label: "Pull, do not push!",
    focus: `Pull your foot into the Running Pose. Your mental image is to land already in the running Pose, airborne foot under your hip`,
    text: `Ok, we have now understood that the acceleration comes from Falling in Pose.
Once the falling phase has ended, we need to change support, and get back in the Pose as fast as possible to Fall again.
This is done by PULLING the foot from the ground.
If we don't, we are just spending useless time on support.

Running has 3 invariable elements : Pose, Fall, Pull.
Pose is the best body position allowing us to Fall.
The Fall is the main element allowing us to move fast using gravity.
Pull is the fastest way to get back in Pose and start all over again.

Those elements are the only necessary elements of running. A good runner will do only those, in proper timing.
A"bad"runner will add elements that will hinder his efficiency.
Overstriding will delay the time to get in Pose inducing a breaking effect.
Trying to"Push Off"will increase the time to get back in Pose, increasing the time wasted"not falling"

During the run think of pulling the foot of the ground as soon as it touches the ground.

Warning : do not stiffen your foot, let it loose to make sure that you do not run only on your ball of foot. Your hell must slightly touch the ground after your ball of foot at each stride, to avoid useless tension on your Achilles tendon.`,
  },
  "5fe37101576a965e62f8d30a": {
    label: "Adjust pulling height",
    focus: `Adjust pulling height`,
    text: `When we pull our foot from the ground it must be a quick and short action using the hamstrings.
The ideal height of your pull is dictated by your angle of falling (your speed). A low pull when jogging, a high pull when sprinting.
The goal of this lesson is to learn how to pull your foot towards your hip into the running POSE at different heights.`,
  },
  "5fe37112576a965e62f8d30b": {
    label: "Muscle-tendon elasticity and cadence",
    focus: `Muscle-tendon elasticity and cadence`,
    text: `Saving and reusing energy is key to improve your ability to run better.
One of the gratuitous force that can be used for running is your muscle-tendon elasticity. It has been shown that correctly trained and used it reduces oxygen consumption and increases efficiency up to 50%.

This muscle-tendon complex is activated with a minimum cadence of 180 steps per minute, learning to run at this cadence is a big part of your skill development as a runner.

To work on this skill we use rhythmic hopping drills and cadence work with a metronome.

When working on the metronome you must focus on synchronizing the pull of the airborne foot into Pose on the beep. This is essential!
If you focus on synchronizing the beep with your foot hitting the floor, it will lead to a wrong movement pattern, called active landing.
If you focus on synchronizing the beep on puling your foot in Pose, it will help you to get in Pose quickly, ready to fall.

The goal of this section is to start improving your cadence.`,
  },
  "5fe37122576a965e62f8d30c": {
    label: "180 is the key",
    focus: `180 is the key`,
    text: `In the previous session you might have struggled running at 180 step per minute without getting winded. Do not worry, this is normal, your muscles and tendons need time to develop this skill.
Moreover, this kind of work is quite new to you, it is very likely, that you pulled higher than necessary with highly tense muscles!

In this session we will work on keeping a high cadence but focusing on relaxing as much as possible.`,
  },
  "5ffc3b1b92910d5db9167e45": {
    label: "Conclusion",
    focus: ``,
    text: `Congratulations!
Your initiation to a better running technique is finished, it is time for a Running Technique Analysis to view your progress!

Keep improving by using the programs of Molokoy !

To go further:
- Register to a Pose Method seminar : https://posemethod.com/seminars/pose-method-of-running/
- Get int touch with a certified Pose Method coach in your area at https://techniquespecialist.com/,`,
  },

  "instructions": {
    "01": { 1: "Focus on the sensation of falling forward" },
    "02": {
      1: "Focus on falling from the hips while maintaining an upright torso",
    },
    "03": {
      1: "Perceive the brief moment you are in Pose in every running cycle",
    },
    "04": {
      1: "Do not swing your airborne foot forward. Try to keep it under your hip as long as possible",
    },
    "05": {
      1: "Focus on pulling your foot from the ground into the running Pose",
    },
    "06": {
      1: "slow, pulling as low as possible",
      2: "moderate, pulling higher than previously",
      3: "moderate fast, pulling higher than previously",
    },
    "07": {
      1: "Your airborne foot must be in Pose (under your hip) at the beep",
    },
    "08": {
      1: "Focus on having your airborne foot under your hip at the beep, using the least energy possible",
    },
    "focus.cadence.30": `When you cannot maintain the cadence, rest for 1 minute. Do not stop the clock.
The goal is to build overtime your ability yo run the whole 30 minutes at a 180 cadence.`,
    "focus.cadence.01": `Your airborne foot must be in Pose (under your hip) at the beep.`,
    "focus.pose.01": `Focus on trying to be already in Pose, each time your foot lands.`,
    "focus.fall.01": `Focus on falling from the hips while maintaining an upright torso.`,
    "focus.pull.01": `Focus on the pull as your only muscular action when running (Falling and Pose are rather passive).`,
  },

  "cadence.details":
    "**********************************************************************************************",

  "601152b4f3accc319ae01648": {
    label: "Session 1",
    focus: ``,
    text: ``,
  },
  "60115a4c20828c437c0ee184": {
    label: "Session 2",
    focus: ``,
    text: ``,
  },
  "60129debf8115d436e31d037": {
    label: "Session 3",
    focus: ``,
    text: ``,
  },
  "6012bcf6f8115d436e31d057": {
    label: "Session 4",
    focus: ``,
    text: ``,
  },
  "6012beb7f8115d436e31d06e": {
    label: "Session 5",
    focus: ``,
    text: ``,
  },
  "6012c4a39c2aef437d5c91d0": {
    label: "Session 6",
    focus: ``,
    text: ``,
  },
  "6012c59c333ff7436fddfc3b": {
    label: "Session 7",
    focus: ``,
    text: ``,
  },
  "6012c0b69c2aef437d5c91b5": {
    label: "Session 8",
    focus: ``,
    text: ``,
  },
  "6012cf8f20828c437c0ee1b4": {
    label: "Session 9",
    focus: ``,
    text: ``,
  },
  "6012d19ef8115d436e31d082": {
    label: "Session 10",
    focus: ``,
    text: ``,
  },
  "6012d316f8115d436e31d08d": {
    label: "Session 11",
    focus: ``,
    text: ``,
  },
  "6012d472333ff7436fddfc6a": {
    label: "Session 12",
    focus: ``,
    text: ``,
  },

  "drills.details": `**********************************************************************************************
    **********************************************************************************************
    **********************************************************************************************`,

  "5dbee93cd3bebd1001b278db": {
    "label": "Banded run",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place ",
    "execution.2": "Press the hips into the band",
    "execution.3": "",
    "focus.1": "Keep body in proper alignment ",
    "focus.2": "Maintain a high cadence",
    "focus.3": "Focus on pulling, not pushing on the foot",
    "repetitions.1": "30s with band ",
    "repetitions.2": "Run 10 meters",
    "repetitions.3": "",
  },
  "5d797bd5da8c422b903f2e9c": {
    "label": "Body weight perception",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Shift body weight to the front then to the back",
    "execution.2": "Hold position forward then back",
    "execution.3": "",
    "focus.1":
      "Perceive the changes of pressure on the ground relatively to body position",
    "focus.2": "Keep body in proper alignment",
    "focus.3": "Stay in balance",
    "repetitions.1": "10 body weight shifts to the front then to the back ",
    "repetitions.2": "10s hold body weight towards the back/10s hold in front",
    "repetitions.3": "Run 10 meters",
  },
  "5d797bdeda8c422b903f2e9d": {
    "label": "Body weight perception in Pose",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Shift body weight to the front then to the back",
    "execution.2": "Hold position forward then back",
    "execution.3": "Repeat on the other side",
    "focus.1":
      "Perceive the changes of pressure on the ground relatively to body position",
    "focus.2": "Keep body in proper alignment",
    "focus.3": "Stay in balance",
    "repetitions.1": "10x body weight shifts to the front then to the back ",
    "repetitions.2": "10s hold body weight towards the back/10s hold in front",
    "repetitions.3": "Switch side and Run 10 meters",
  },
  "5c13d0b8df79e52f10967f03": {
    "label": "Bunny Hops",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Hop in place then fall forward",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "Find the least amount of fall needed to move forward",
    "focus.3": "Minimize the muscular efforts",
    "repetitions.1": "2 sets :",
    "repetitions.2":
      "Alternate 3 hops in place, 3 hops moving forward, 10 times",
    "repetitions.3": "Run for 10 meters",
  },
  "5c14199489d7e907146f58c7": {
    "label": "Bunny hops backward",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Hop in place then fall slightly backwards",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving backwards",
    "focus.2": "Find the least amount of fall needed to move ",
    "focus.3": "Minimize the muscular efforts",
    "repetitions.1":
      "Alternate 3 hops in place, 3 hops moving backward, 10 times",
    "repetitions.2": "Turn around and run for 10 meters",
    "repetitions.3": "",
  },
  "5d797e07da8c422b903f2ead": {
    "label": "Carioca",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Fall slightly on one side",
    "execution.2":
      "Place your foot alternately in front of you and in the back",
    "execution.3": "",
    "focus.1": "Limit the movement of the shoulders",
    "focus.2": "Perceive body weight on the ball of foot",
    "focus.3": "",
    "repetitions.1": "20 steps to the right",
    "repetitions.2": "20 steps to the left",
    "repetitions.3": "Run for 10 meters",
  },
  "5d797d21da8c422b903f2eaa": {
    "label": "Change of support",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Hop in place then pull your foot from the ground, straight under the hip",
    "execution.2": "Land in Pose",
    "execution.3": "Switch side",
    "focus.1":
      "The support foot must move first, before the airborne foot lands",
    "focus.2": "Let the airborne foot drop and land naturally",
    "focus.3":
      "During the hop in place, make sure that the ankle of the airborne foot is at opposite knee height",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5c0515517590d85294234f18": {
    "label": "Change of support on line",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Hop in place then pull your foot from the ground, straight under the hip",
    "execution.2": "Land in Pose, foot on the line",
    "execution.3": "Switch side",
    "focus.1": "Pull the foot straight under the hip",
    "focus.2": "Let the airborne foot drop and land naturally on the line",
    "focus.3":
      "During the hop in place, make sure that the ankle of the airborne foot is at opposite knee height",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5c14167d89d7e907146f58bf": {
    "label": "Criss-Cross",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump in place and fall forward",
    "execution.2": "Cross your feet, alternating the foot in front",
    "execution.3": "",
    "focus.1": "Heel slightly kisses the ground",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33aeeb9b46709fe0d32e17": {
    "label": "Criss-Cross high & wide",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump in place and fall forward",
    "execution.2": "Cross your feet, alternating the foot in front",
    "execution.3":
      "Emphasize the jump height and the distance between the feet.",
    "focus.1": "Heel slightly kisses the ground",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f3cf1dfd6692d69800007c1": {
    "label": "Curb jumps",
    "setup": "Spring position facing the curb",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump on and off the curb ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Shrug your shoulder to unweigh yourself",
    "focus.2": "Focus on pulling your feet from the floor and not pushing ",
    "focus.3": "Keep a steady rhythm",
    "repetitions.1": "10 repetitions",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f3cf1e8d6692d69800007c2": {
    "label": "Curb jumps land in pose",
    "setup": "Spring position facing the curb",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump off the floor with two feet",
    "execution.2": "Land on the curb in Pose ",
    "execution.3": "Switch Pose each time",
    "focus.1": "Shrug your shoulder to unweigh yourself",
    "focus.2": "Focus on pulling your foot from the floor and not pushing ",
    "focus.3": "Land in a correct Pose ",
    "repetitions.1": "10 repetitions",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f3cf1f3d6692d69800007c3": {
    "label": "Curb jumps in pose",
    "setup": "Pose, facing the curb",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Jump on and off the curb in Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Shrug your shoulder to unweigh yourself",
    "focus.2": "Focus on pulling your foot from the floor and not pushing ",
    "focus.3": "Keep a correct Pose during all the execution",
    "repetitions.1":
      "5 repetitions on left foot / 5 repetitions on right foot ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f3cf1fcd6692d69800007c4": {
    "label": "Curb foot switch",
    "setup": "Spring position facing the curb, left foot on the curb",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump and swap your feet positions",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Shrug your shoulder to unweigh yourself",
    "focus.2": "Keep a steady rhythm",
    "focus.3": "",
    "repetitions.1": "20 repetitions",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f3cf205d6692d69800007c5": {
    "label": "Curb drop pull",
    "setup": "Pose, one foot on the curb parallel to the edge",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Let the airborne foot drop to the ground, pull the other one in Pose",
    "execution.2": "As soon as your foot  touches the ground pull it back up",
    "execution.3": "Get back in the starting position as fast as possible",
    "focus.1":
      "The pulling reflex is fired by the contact of the foot on the ground",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1":
      "10 repetitions on left foot / 10 repetitions on right foot ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f3cf20ed6692d69800007c6": {
    "label": "Curb lunge back foot elevated",
    "setup": "Lunge position, back leg on the curb",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Pull your front foot in Pose, under your hip",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Shrug your shoulder to unweigh yourself",
    "focus.2": "Minimize vertical oscillation",
    "focus.3": "",
    "repetitions.1":
      "5 repetitions on left foot / 5 repetitions on right foot ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797d12da8c422b903f2ea9": {
    "label": "Double lunge",
    "setup": "Small lunge",
    "setup.1": "One foot in front of the other, one foot length apart",
    "setup.2": "Knees bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Put the front foot under the hip then the back foot, alternating",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Pull both foot under the hip in a straight line",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1":
      "10 times left foot in front, 10 times right foot in front",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d8921a7a518513ee9179ea3": {
    "label": "Double pull",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Pull both feet under the hip, use and aggressive shrug from the shoulders",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Focus on pulling, not pushing on the feet",
    "focus.2": "Minimize the vertical oscillations of the head",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "5 in place",
    "repetitions.3": "Run for 10 meters",
  },
  "5d797e74da8c422b903f2eb0": {
    "label": "Double pull forward",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Pull both feet under the hip, use and aggressive shrug from the shoulders",
    "execution.2": "Fall forward",
    "execution.3": "",
    "focus.1": "Focus on pulling, not pushing on the feet",
    "focus.2": "Minimize the vertical oscillations of the head",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "5 in place / 5 forward",
    "repetitions.3": "Run for 10 meters",
  },
  "5d8a82b75d8e453dc992784e": {
    "label": "Double pull to switch lunge in place",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Pull both feet under the hip, use and aggressive shrug from the shoulders",
    "execution.2": "Land in a lunge",
    "execution.3": "Repeat to land on the opposite lunge",
    "focus.1": "Focus on pulling, not pushing on the feet",
    "focus.2": "Minimize the vertical oscillations of the head",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "5 in place / 5 forward",
    "repetitions.3": "Run for 10 meters",
  },
  "5d892188a518513ee9179ea2": {
    "label": "Double pull to switch lunge forward",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Pull both feet under the hip, use and aggressive shrug from the shoulders",
    "execution.2": "Land in a lunge and repeat to land on the opposite lunge",
    "execution.3": "Fall forward",
    "focus.1": "Focus on pulling, not pushing on the feet",
    "focus.2": "Minimize the vertical oscillations of the head",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "6 in place / 6 forward",
    "repetitions.3": "Run for 10 meters",
  },
  "5d87c15fcdbf8aa87234c258": {
    "label": "Exaggerated high pull one foot",
    "setup": "Pose, one arm straight pointing down",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run, pulling the foot as high as possible",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the torso upright, no bending to the side or in front",
    "focus.2": "Pull your foot in a straight, vertical line",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward then",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5d797ca9da8c422b903f2ea3": {
    "label": "Exaggerated pull one foot",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run, pulling the foot at opposite knee height",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "After the pull, let the airborne foot drop and land naturally",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5d797cbfda8c422b903f2ea4": {
    "label": "Exaggerated pull one foot 3 heights",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Run, pulling the foot at mid-shin height, opposite knee height, and glute height",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "The difference between the three heights must be clear ",
    "focus.2": "After the pull, let the airborne foot drop and land naturally",
    "focus.3": "",
    "repetitions.1": "5 times on each side",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797e8dda8c422b903f2eb2": {
    "label": "Face up hip dips",
    "setup": "Seated with straight legs",
    "setup.1": "Hands on the floor behind the hips",
    "setup.2": "Shoulders down",
    "setup.3": "",
    "execution.1": "Raise the hips to align them with the shoulders and ankles",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the legs straight",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3X 8 repetitions",
    "repetitions.2": "Run for 10 meters between each set",
    "repetitions.3": "",
  },
  "5d797e9eda8c422b903f2eb3": {
    "label": "Face up hip dips one leg up",
    "setup": "Seated on the floor with straight legs, one foot up",
    "setup.1": "Hands on the floor behind the hips",
    "setup.2": "Shoulders down",
    "setup.3": "",
    "execution.1": "Raise the hips to align them with the shoulders and ankles",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the legs straight",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x10 repetitions on each side",
    "repetitions.2": "Run for 10 meters between each set",
    "repetitions.3": "",
  },
  "5f34e829ee7ed9a31442c749": {
    "label": "Fast Pony",
    "setup": "Pony Pose",
    "setup.1": "Airborne foot under the hip, toes touching the ground",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Pull the support from the ground and land in Pony Pose on the other foot",
    "execution.2": "Repeat immediately",
    "execution.3": "",
    "focus.1": "Pull the support foot as little as possible",
    "focus.2": "Shrug your shoulder for easier pull",
    "focus.3": "",
    "repetitions.1": "10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d8921dba518513ee9179ea5": {
    "label": "Finger on belly button",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place, put two fingers on the belly button",
    "execution.2": "Fall forward to apply pressure on your fingers",
    "execution.3": "",
    "focus.1":
      "Feel the pressure on your fingers while keeping an upright torso",
    "focus.2": "Feel the fall when running",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "10 strides then run for 10 meters",
    "repetitions.3": "",
  },
  "5d797d02da8c422b903f2ea8": {
    "label": "Foot tapping",
    "setup": "Spring position, one arm straight pointing down",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Bring the ankle to the hand while hopping",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the torso upright, no bending to the side or in front",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5d797ce1da8c422b903f2ea6": {
    "label": "Front lunge",
    "setup": "Small lunge",
    "setup.1": "One foot in front of the other, one foot length apart",
    "setup.2": "Knees bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Bring forward foot under the hip",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Pull foot up under the hip in a straight line",
    "focus.2": "Maintain the gap between the feet",
    "focus.3": "",
    "repetitions.1": "10 strides right foot in front",
    "repetitions.2": "10 strides left foot in front",
    "repetitions.3": "Run for 10 meters",
  },
  "5c1416d889d7e907146f58c1": {
    "label": "Front to back Pendulum",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Lift a straight leg in front and bring it back ",
    "execution.2":
      "Switch support before ground contact, while bringing the other leg straight to the rear",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and when moving forward",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 strides right leg in front",
    "repetitions.2": "10 strides left leg in front",
    "repetitions.3": "Run for 10 meters",
  },
  "5d83fd73e778a0ed50b04215": {
    "label": "Get in Pose",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Bring your ankle at opposite knee height",
    "execution.2": "Bring the airborne foot back down",
    "execution.3": "",
    "focus.1": "Keep the ankle relaxed",
    "focus.2": "Knee of support leg is bent",
    "focus.3": "Body weight on the ball of foot",
    "repetitions.1": "20 reps right foot  / 20 Reps  left foot",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f4571ada84cf8784884e0a6": {
    "label": "Get in Pose hands overhead",
    "setup": "Spring position, arms straight overhead",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Bring your ankle at opposite knee height",
    "execution.2": "Bring the airborne foot back down",
    "execution.3": "",
    "focus.1": "Keep the ankle relaxed",
    "focus.2": "Knee of support leg is bent",
    "focus.3": "Body weight on the ball of foot",
    "repetitions.1": "20 reps right foot  / 20 Reps  left foot",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d8921f3a518513ee9179ea6": {
    "label": "Hands clasped behind",
    "setup": "Pose, arms straight in the back, hand clasped ",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "Fall forward driving the hips away from the hands",
    "execution.3": "",
    "focus.1": "Keep the falling sensation when running after the drill",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "10 in place, 10 forward ",
    "repetitions.3": "Run for 10 meters",
  },
  "5d89211aa518513ee9179ea0": {
    "label": "Hands clasped in front",
    "setup": "Pose, arms straight, hand clasped in front",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Run in place while minimizing the oscillations of the hands",
    "execution.2": "Fall forward keep the hands as still as possible",
    "execution.3": "",
    "focus.1": "Keep the falling sensation when running after the drill",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "2 times : ",
    "repetitions.2": "10 in place, 10 forward ",
    "repetitions.3": "Run for 10 meters",
  },
  "5d797e4eda8c422b903f2eae": {
    "label": "Hip dips",
    "setup": "Plank position on the hands",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring the hips down and up",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the arms straight",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x 8 repetitions",
    "repetitions.2": "Run for 10 meters between each set",
    "repetitions.3": "",
  },
  "5d797e5cda8c422b903f2eaf": {
    "label": "Hip dips one leg up",
    "setup": "Plank position on the hands, one foot up",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring the hips down and up",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the arms straight",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x 5 right foot up/5 left foot up",
    "repetitions.2": "Run for 10 meters between each set",
    "repetitions.3": "",
  },
  "5f3c2685d6692d69800007b9": {
    "label": "Hip dips one leg  on arm",
    "setup": "Plank position on the hands, left foot and right arm up",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring the hips down and up",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the arms straight",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2":
      "5 with left foot - right arm / 5 with right foot - left arm",
    "repetitions.3": "Run for 10 meters between each set",
  },
  "5d797e81da8c422b903f2eb1": {
    "label": "Hip dips one arm",
    "setup": "Plank position, one hand at shoulder height",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring the hips down and up",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the support arm straight",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2": "5 right arm up / 5 left arm up",
    "repetitions.3": "Run for 10 meters between each set",
  },
  "5d797ea8da8c422b903f2eb4": {
    "label": "Hip dips lateral",
    "setup": "Side plank",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your hips down and up",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Body aligned, no butt going back",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2": "5 on the right/5 on the left",
    "repetitions.3": "Run for 10 meters between each set",
  },
  "5f3c2691d6692d69800007ba": {
    "label": "Hip dips lateral one leg",
    "setup": "Side plank, top leg up",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your hips down and up",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Body aligned, no butt going back",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2": "5 on the right/5 on the left",
    "repetitions.3": "Run for 10 meters between each set",
  },
  "5d892135a518513ee9179ea1": {
    "label": "Hops in Pose",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Hop in place maintaining Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Keep the airborne foot in Pose, under the hip, at opposite knee height",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5f33b0289b46709fe0d32e19": {
    "label": "Lateral Hops in Pose",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Hop in place maintaining Pose",
    "execution.2": "Alternate landing on the left and on the right",
    "execution.3": "",
    "focus.1":
      "Keep the airborne foot in Pose, under the hip, at opposite knee height",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward ",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5d8a822d5d8e453dc992784c": {
    "label": "Jump rope change of support",
    "setup": "Pose, with a jump rope",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Jump rope in Pose",
    "execution.2": "Switch feet every two jumps",
    "execution.3": "",
    "focus.1":
      "Keep the airborne foot in Pose, under the hip, at opposite knee height",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 jumps ",
    "repetitions.2": "Drop the rope and run for 10 meters",
    "repetitions.3": "",
  },
  "5d8a83095d8e453dc9927850": {
    "label": "Jump rope double pull",
    "setup": "Spring position, with a jump rope",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump rope feet together",
    "execution.2": "Bring both feet at Pose height",
    "execution.3": "",
    "focus.1":
      "Try to keep the head at the same height as when hopping in place",
    "focus.2": "Focus on pulling, not pushing on the foot",
    "focus.3": "",
    "repetitions.1": "20 jumps ",
    "repetitions.2": "Drop the rope and run for 10 meters",
    "repetitions.3": "",
  },
  "5d8a81f45d8e453dc992784b": {
    "label": "Jump rope in Pose",
    "setup": "Pose, with a jump rope",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Jump rope in Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Keep the airborne foot in Pose, under the hip, at opposite knee height",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3x 10 jumps on the right foot/10 on the left foot ",
    "repetitions.2": "Drop the rope down and run for 10 meters",
    "repetitions.3": "",
  },
  "5d8a832e5d8e453dc9927851": {
    "label": "Jump rope pony",
    "setup": "Pony Pose, with a jump rope",
    "setup.1": "Airborne foot under the hip, toes touching the ground",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Jump rope in Pony Pose",
    "execution.2": "Hop once then switch foot",
    "execution.3": "",
    "focus.1": "Jump as little as possible",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 jumps",
    "repetitions.2": "Drop the rope and run for 10 meters",
    "repetitions.3": "",
  },
  "5bf48c43a3e5f36b48bfec46": {
    "label": "Jump rope pony quick",
    "setup": "Pony Pose, with a jump rope",
    "setup.1": "Airborne foot under the hip, toes touching the ground",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Jump rope in Pony Pose",
    "execution.2": "Switch foot for each jump",
    "execution.3": "",
    "focus.1": "Jump as little as possible",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 jumps",
    "repetitions.2": "Drop the rope and run for 10 meters",
    "repetitions.3": "",
  },
  "5d8a828e5d8e453dc992784d": {
    "label": "Jump rope run in place",
    "setup": "Pose, with a jump rope",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place with the jump Rope",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Pull the foot from the ground before the airborne foot is allowed to drop",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2": "20 in place",
    "repetitions.3": "Drop the rope and run for 10 meters",
  },
  "5d797eb2da8c422b903f2eb5": {
    "label": "Jump rope run",
    "setup": "Pose, with a jump rope",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place with the jump Rope then fall forward",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Pull the foot from the ground before the airborne foot is allowed to drop",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2": "10 meters ",
    "repetitions.3": "Drop the rope and run for another 10 meters",
  },
  "5d8a82d95d8e453dc992784f": {
    "label": "Jump rope simple under",
    "setup": "Spring position, with a jump rope",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump rope feet together",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the  Knees bent",
    "focus.2": "Heels kiss the ground at each jump",
    "focus.3": "",
    "repetitions.1": "3 sets : ",
    "repetitions.2": "20 jumps",
    "repetitions.3": "Drop the rope and run for 10 meters",
  },
  "5f3aa469ee7ed9a31442c773": {
    "label": "Jump rope support foot pull",
    "setup": "Pose, with a jump rope",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Jump rope on support foot, bring it to the height of the airborne foot ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Shrug your shoulders for an easier pull",
    "focus.2": "Focus on pulling, not pushing on the foot",
    "focus.3":
      "Focus on actively pulling the foot from the ground when running",
    "repetitions.1": "2 times : ",
    "repetitions.2": "5 on right foot/5 on left foot ",
    "repetitions.3": "Drop the rope and run for 10 meters",
  },
  "5f3aa56eee7ed9a31442c774": {
    "label": "Jump rope skip",
    "setup": "Spring position, with a jump rope",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump rope while pulling one foot up, ",
    "execution.2": "Land simultaneously on both feet",
    "execution.3": "Switch side",
    "focus.1": "Pull the foot straight up",
    "focus.2": "Let the airborne foot drop and land effortlessly on the ground",
    "focus.3": "",
    "repetitions.1": "20 jumps",
    "repetitions.2": "Drop the rope and run for 10 meters",
    "repetitions.3": "",
  },
  "5d8931b7befa6e434dc8f8f8": {
    "label": "Jump rope foot tapping",
    "setup": "Spring position, with a jump rope",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Bring the left foot in pose while jumping with the rope",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the torso upright, no bending to the side or in front",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 jumps",
    "repetitions.2": "Drop the rope and run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5c13d155df79e52f10967f04": {
    "label": "Jumping jack to Pose",
    "setup": "Spring position, feet apart",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "From the start position feet apart, jump and land in Pose",
    "execution.2": "Alternate Pose on the right foot and left foot",
    "execution.3": "",
    "focus.1":
      "Land in proper Pose each time, airborne foot under the hip, knee pointing forward",
    "focus.2": "Avoid side to side movement of the body",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797b7bda8c422b903f2e9b": {
    "label": "Jumps feet together and apart",
    "setup": "Spring position,",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump, spreading your feet in the air",
    "execution.2": "Land with feet joined",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "Fall forward to move, no muscular effort",
    "focus.3": "Heels kiss the ground at each jump",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797b62da8c422b903f2e9a": {
    "label": "Jumps feet apart and together",
    "setup": "Spring position, feet apart",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "From the start position feet apart, jump and join your feet in the air",
    "execution.2": "Land feet apart",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "Fall forward to move, no muscular effort",
    "focus.3": "Heels kiss the ground at each jump",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b2b2ee7ed9a31442c743": {
    "label": "Lunge switch",
    "setup": "Small lunge",
    "setup.1": "One foot in front of the other, one foot length apart",
    "setup.2": "Knees bent",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump up and land in the opposite lunge",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "Fall forward to move, no muscular effort",
    "focus.3": "Heels kiss the ground at each jump",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d8921c2a518513ee9179ea4": {
    "label": "Palm on lower back",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Place the palm on one hand on the lower back",
    "execution.2":
      "Fall forward, as if you drived your lower back away from your hands",
    "execution.3": "",
    "focus.1": "Feel the fall from the hips",
    "focus.2": "Keep an upright torso",
    "focus.3": "",
    "repetitions.1": "2 sets :",
    "repetitions.2": "10 in place, 10 forward",
    "repetitions.3": "Run for 10 meters",
  },
  "5d797c92da8c422b903f2ea2": {
    "label": "Pony",
    "setup": "Pony Pose",
    "setup.1": "Airborne foot under the hip, toes touching the ground",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Pull the support from the ground and land in Pony Pose on the other foot",
    "execution.2": "One hop in place and repeat",
    "execution.3": "",
    "focus.1": "Pull the support foot as little as possible",
    "focus.2": "Shrug your shoulder for easier pull",
    "focus.3": "",
    "repetitions.1": "10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797cf8da8c422b903f2ea7": {
    "label": "Running backwards",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "Fall backward",
    "execution.3": "",
    "focus.1": "Initial contact is on the ball of foot",
    "focus.2": "Heels kiss the ground at each stride",
    "focus.3": "",
    "repetitions.1": "10 backwards",
    "repetitions.2": "Turn around and run for 10 meters",
    "repetitions.3": "",
  },
  "5f376515ee7ed9a31442c769": {
    "label": "Running forward",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "Fall forward",
    "execution.3": "",
    "focus.1": "Initial contact is on the ball of foot",
    "focus.2": "Heels kiss the ground at each stride",
    "focus.3": "",
    "repetitions.1": "Run for 10 meters",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5d8920d3a518513ee9179e9f": {
    "label": "Running hands overhead",
    "setup": "Pose, arms straight overhead",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "Fall forward",
    "execution.3": "",
    "focus.1": "The torso is vertical, shoulders over the hips",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797ccdda8c422b903f2ea5": {
    "label": "Running in place",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "10 in place then run for 10 meters",
    "execution.3": "",
    "focus.1": "Initial contact is on the ball of foot",
    "focus.2": "Heels kiss the ground at each stride",
    "focus.3": "",
    "repetitions.1": "3 sets : ",
    "repetitions.2": "20 in place",
    "repetitions.3": "Run for 10 meters",
  },
  "5f356b56ee7ed9a31442c75c": {
    "label": "Run into fall over line",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Stand in front of the line, heels kissing the line",
    "execution.2":
      "Start running run in place, then land your feet behind the line",
    "execution.3": "Keep the falling angle and run forward",
    "focus.1": "Keep the torso in the same place when landing behind the line",
    "focus.2": "Keep the falling angle ",
    "focus.3": "",
    "repetitions.1": "3 sets",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797c58da8c422b903f2e9e": {
    "label": "Running Pose",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Pull foot up straight under the hip, at opposite knee height",
    "execution.2": "Keep the knee of the support leg bent",
    "execution.3": "Keep body weight on the ball of foot",
    "focus.1": "Pull the foot in a straight line",
    "focus.2": "Ankle is at knee height (make contact to check)",
    "focus.3": "",
    "repetitions.1": "30s on the right side",
    "repetitions.2": "30s on the left side",
    "repetitions.3": "Run for 10 meters",
  },
  "5f4571a2a84cf8784884e0a5": {
    "label": "Running Pose hands overhead",
    "setup": "Spring position, arms straight overhead",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Pull foot up straight under the hip, at opposite knee height",
    "execution.2": "Keep the knee of the support leg bent",
    "execution.3": "Keep body weight on the ball of foot",
    "focus.1": "Pull the foot in a straight line",
    "focus.2": "Do not drive with the knee",
    "focus.3": "",
    "repetitions.1": "30s on the right side",
    "repetitions.2": "30s on the left side",
    "repetitions.3": "Run for 10 meters",
  },
  "5d87c249cdbf8aa87234c259": {
    "label": "Running with exaggerated pull",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "Pull alternately the foot as high as possible",
    "execution.3": "Fall forward",
    "focus.1": "Foot goes up in a straight line",
    "focus.2": "Initial contact is on the ball of foot",
    "focus.3": "Heels kiss the ground at each stride",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d801d8fa7450b4876bb8a94": {
    "label": "Side to side pendulum",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Lift a straight leg on the side while hoping, then bring it back to the middle",
    "execution.2":
      "Before the lifted foot touches the ground, bring the other leg on the opposite side",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33afc49b46709fe0d32e18": {
    "label": "Side pendulum to pose",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Lift a straight leg on the side while hoping, then bring it back to the middle",
    "execution.2":
      "Before the lifted foot touches the ground, bring the other leg in Pose",
    "execution.3": "",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5c1417a489d7e907146f58c2": {
    "label": "Skip",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump and actively pull one foot up, under the hip",
    "execution.2": "Land simultaneously on both feet",
    "execution.3": "",
    "focus.1": "Pull the foot straight up",
    "focus.2": "Let the airborne foot drop and land effortlessly on the ground",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch foot",
  },
  "5c1417be89d7e907146f58c3": {
    "label": "Skip alternating ",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump while pulling one foot up, ",
    "execution.2": "Land simultaneously on both feet",
    "execution.3": "Switch side",
    "focus.1": "Pull the foot straight up",
    "focus.2": "Let the airborne foot drop and land effortlessly on the ground",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b0f6c30309477cb647e0": {
    "label": "Skip alternating fast",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Jump while pulling one foot up quickly and as little as possible, ",
    "execution.2": "Land simultaneously on both feet",
    "execution.3": "Switch side",
    "focus.1": "Pull the foot straight up",
    "focus.2": "Let the airborne foot drop and land effortlessly on the ground",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b2d4ee7ed9a31442c744": {
    "label": "Spring position to lunge",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Jump up and land in a lunge, left foot in front",
    "execution.2": "Hop back in spring position",
    "execution.3": "Jump up and land in a lunge, right foot in front",
    "focus.1": "Maintain the same cadence in place and moving forward",
    "focus.2": "Fall forward to move, no muscular effort",
    "focus.3": "Heels kiss the ground at each jump",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797d6bda8c422b903f2eab": {
    "label": "Support foot pull",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Hop on support foot, bring it to the height of the foot in Pose",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Shrug your shoulders for an easier pull",
    "focus.2": "Focus on pulling, not pushing on the foot",
    "focus.3":
      "Focus on actively pulling the foot from the ground when running",
    "repetitions.1": "2 sets : ",
    "repetitions.2": "5 on right foot/5 on left foot ",
    "repetitions.3": "Run for 10 meters",
  },
  "5c14185a89d7e907146f58c5": {
    "label": "Support foot pull forward",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1":
      "Hop on support foot, bring it to the height of the foot in Pose",
    "execution.2": "Fall forward",
    "execution.3": "",
    "focus.1": "Shrug your shoulders for an easier pull",
    "focus.2": "Focus on pulling, not pushing on the foot",
    "focus.3":
      "Focus on actively pulling the foot from the ground when running",
    "repetitions.1": "5 in place, 5 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch side",
  },
  "5d797c65da8c422b903f2e9f": {
    "label": "Timber drill",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Fall forward and catch yourself as late as possible",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Feel the fall from the hips",
    "focus.2": "Keep the torso upright",
    "focus.3": "",
    "repetitions.1": "5 on right foot/5 on left foot ",
    "repetitions.2": "run for 10 meters",
    "repetitions.3": "",
  },
  "5c0520ec1d7f3447b4c01cc1": {
    "label": "Timber drill in Pose",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Fall forward and catch yourself as late as possible",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Feel the fall from the hips",
    "focus.2": "Keep the torso upright",
    "focus.3": "",
    "repetitions.1": "5 on right foot/5 on left foot ",
    "repetitions.2": "run for 10 meters",
    "repetitions.3": "",
  },
  "5c141c0289d7e907146f58c8": {
    "label": "Timber Drill with change of support",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Fall forward ",
    "execution.2": "Pull support foot to land in Pose on the airborne foot",
    "execution.3": "",
    "focus.1": "Pull the support foot at the end of the fall",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 on right foot/5 on left foot ",
    "repetitions.2": "run for 10 meters",
    "repetitions.3": "",
  },
  "5f356aa9ee7ed9a31442c759": {
    "label": "Timber Drill with change of support alternating",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Fall forward ",
    "execution.2": "Pull support foot to land in Pose on the airborne foot",
    "execution.3": "Repeat on the other side",
    "focus.1": "Pull the support foot at the end of the fall",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 times ",
    "repetitions.2": "run for 10 meters",
    "repetitions.3": "",
  },
  "5c1417f589d7e907146f58c4": {
    "label": "Toes in and Out",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Hop and point your toes to the outside, to the front then to the inside",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Heels must slightly kiss the ground",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b18eee7ed9a31442c73f": {
    "label": "Toes in and out, feet apart",
    "setup": "Spring position, feet apart",
    "setup.1": "Feet apart, knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Hop and point your toes to the outside, then to the inside",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Heels must slightly kiss the ground",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b273ee7ed9a31442c740": {
    "label": "Toes in and out in Pose",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Hop and point your toes to the outside, then to the inside",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Heels must slightly kiss the ground",
    "focus.2": "Maintain Pose, airborne foot under the hip",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "Switch sides",
  },
  "5c14192e89d7e907146f58c6": {
    "label": "Twist",
    "setup": "Spring position",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Hop and point alternately your pelvis to one side and to the other",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep your shoulders facing forward",
    "focus.2": "Heels must slightly kiss the ground",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b28eee7ed9a31442c741": {
    "label": "Twist feet apart",
    "setup": "Spring position, feet apart",
    "setup.1": "Feet apart, knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1":
      "Hop and point alternately your pelvis to one side and to the other",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep your shoulders facing forward",
    "focus.2": "Heels must slightly kiss the ground",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f33b2a0ee7ed9a31442c742": {
    "label": "Twist feet apart",
    "setup": "Spring position, feet apart",
    "setup.1": "Feet apart, knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "Elbows bent at 90°",
    "execution.1": "Hop and point your pelvis to the left",
    "execution.2": "Land in a crossed lunge pointing to the left",
    "execution.3": "Hop back in the spring position and switch side",
    "focus.1": "Keep your shoulders facing forward",
    "focus.2": "Heels must slightly kiss the ground",
    "focus.3": "",
    "repetitions.1": "10 in place, 10 forward",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5f34e6adee7ed9a31442c748": {
    "label": "Wall Pose",
    "setup": "Spring position, wall in the back",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "",
    "execution.1": "Pull your foot in Pose without touching the wall",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Pull your foot straight up, underneath your hip",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 with the left foot / 10 with the right foot",
    "repetitions.2": "Run for 10 meters",
    "repetitions.3": "",
  },
  "5d797c75da8c422b903f2ea0": {
    "label": "Wall fall",
    "setup": "Spring position, about one meter away from the wall",
    "setup.1": "Knees bent",
    "setup.2": "Body weight on the ball of foot",
    "setup.3": "",
    "execution.1": "Fall forward ",
    "execution.2": "Catch yourself as late as possible with hands on the wall",
    "execution.3": "",
    "focus.1": "Fall from the hips",
    "focus.2": "Keep the torso upright",
    "focus.3": "",
    "repetitions.1": "3 sets :",
    "repetitions.2": "8 repetitions",
    "repetitions.3": "Run for 10 meters",
  },
  "5d797c83da8c422b903f2ea1": {
    "label": "Wall fall in Pose",
    "setup": "Spring position, about one meter away from the wall",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Fall forward ",
    "execution.2": "Catch yourself as late as possible with hands on the wall",
    "execution.3": "",
    "focus.1": "Fall from the hips",
    "focus.2": "Keep the torso upright",
    "focus.3": "",
    "repetitions.1": "2 sets :",
    "repetitions.2": "5 on right foot, 5 on left foot ",
    "repetitions.3": "Run for 10 meters",
  },
  "5f356aebee7ed9a31442c75b": {
    "label": "Wall fall with change of support",
    "setup": "Pose, about 1.20 meter away from the wall",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Fall forward ",
    "execution.2": "Pull your foot of the ground, and land in Pose",
    "execution.3": "Maintain the fall and catch yourself on the wall",
    "focus.1": "Fall from the hips",
    "focus.2": "Maintain the fall while changing support",
    "focus.3": "Keep the torso upright",
    "repetitions.1": "2 sets :",
    "repetitions.2": "5 on right foot, 5 on left foot ",
    "repetitions.3": "Run for 10 meters",
  },
  "5f356ad7ee7ed9a31442c75a": {
    "label": "Wall run",
    "setup": "Pose, about 1.20 meter away from the wall",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Lean in to the wall ",
    "execution.2": "Run in place falling from the hips",
    "execution.3": "",
    "focus.1": "Fall from the hips",
    "focus.2": "Keep the torso upright",
    "focus.3": "",
    "repetitions.1": "2 sets :",
    "repetitions.2": "20 pulls ",
    "repetitions.3": "Run for 10 meters",
  },
  "5f463240198824d49ce9aed0": {
    "label": "90 to 90",
    "setup": "Feet apart wider than shoulder width, knees bent at 90°",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles around the hip joint",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f459585f53aec6cb4d40395": {
    "label": "90 to 90 in half lotus",
    "setup": "One knee bent at 90°, opposite ankle on the knee",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles around the hip joint",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463269198824d49ce9aed3": {
    "label": "Alternate knee to floor hands in front",
    "setup": "Wide stance, hands on the floor",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Internal face of the foot should be on the floor when the knee touches the floor",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46328d198824d49ce9aed7": {
    "label": "Ankle supination alternate feet crossed",
    "setup": "Feet crossed, one foot on the external edge",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles of the external face of the ankles",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463284198824d49ce9aed6": {
    "label": "Ankle supination foot in front",
    "setup": "Hip width stance, one foot  in front",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles of the external face of the ankle",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463273198824d49ce9aed4": {
    "label": "Ankle supination, one foot",
    "setup": "Hip width stance",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles of the external face of the ankles",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46327c198824d49ce9aed5": {
    "label": "Ankle supination two feet",
    "setup": "Shoulder width stance",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles of the external face of the ankles",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463295198824d49ce9aed8": {
    "label": "Floor touch",
    "setup": "Hip width stance",
    "setup.1": "Legs straight",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "5f46329d198824d49ce9aed9": {
    "label": "Floor touch feet apart",
    "setup": "Wide stance",
    "setup.1": "Legs straight",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4632a5198824d49ce9aeda": {
    "label": "Floor touch feet inline",
    "setup": "One foot in front, aligned with the other",
    "setup.1": "Legs straight",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "Switch side",
  },
  "5f4632b1198824d49ce9aedb": {
    "label": "Floor touch feet supinated",
    "setup": "Hip width stance, both feet on the external edge",
    "setup.1": "Legs straight",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4632c2198824d49ce9aedd": {
    "label": "Floor touch toes out",
    "setup": "Hip width stance, toes pointing out",
    "setup.1": "Legs straight",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4632b9198824d49ce9aedc": {
    "label": "Front bend hands clasped in the back",
    "setup": "Hip width stance, hands clasped in the back",
    "setup.1": "Legs straight",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the hamstrings",
    "focus.2": "Let the arms passively hang",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "5f463261198824d49ce9aed2": {
    "label": "Half lotus head to foot",
    "setup": "One knee bent at 90°, opposite ankle on the knee",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles around the hip joint",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "5f4631ec198824d49ce9aec7": {
    "label": "Hand between shoulder blades overhead stretch",
    "setup": "One hand between the shoulder blades, elbow up",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles around the shoulder joint",
    "focus.2": "The hand must go down as much as possible ",
    "focus.3": "Keep a vertical torso",
    "repetitions.1": "20 seconds on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4631e4198824d49ce9aec6": {
    "label": "Hand between shoulder blades stretch",
    "setup": "One hand between the should blade, elbow down",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles around the shoulder joint",
    "focus.2": "The hand must go up as much as possible ",
    "focus.3": "Keep the rib cage down",
    "repetitions.1": "20 seconds on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46320c198824d49ce9aecb": {
    "label": "Interlaced wrist rotation",
    "setup": "Arms interlaced, hands clasped, right hand on top ",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles of the forearms",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 repetitions right hand on top",
    "repetitions.2": "5 repetitions left hand on top",
    "repetitions.3": "",
  },
  "5f463220198824d49ce9aecc": {
    "label": "Interlaced wrist rotation down to overhead",
    "setup": "Arms interlaced, hands clasped, right hand on top ",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles of the forearms",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 repetitions right hand on top",
    "repetitions.2": "5 repetitions left hand on top",
    "repetitions.3": "",
  },
  "5f463204198824d49ce9aeca": {
    "label": "Inverted prayer",
    "setup": "Prayer position upside down",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Elbows slightly in front",
    "focus.2": "Extend the wrists, but keep the palms in contact",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f48c3c3c378c23548cf4d38": {
    "label": "Lateral hurdle",
    "setup": "Narrow stance",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Knee must go above hip crease",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 repetitions back and forth",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463121198824d49ce9aec3": {
    "label": "Rear fists bump shoulder stretch",
    "setup": "Both fists closed in the back",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Relax the muscle of the shoulder while bringing the elbows forward",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4630f9198824d49ce9aebf": {
    "label": "Reverse prayer",
    "setup": "Prayer position hands in the back",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1":
      "Relax the muscle of the shoulder while bringing the elbows forward",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463105198824d49ce9aec0": {
    "label": "Shoulder blade scratch",
    "setup":
      "One hand in the back, fingers touching the corresponding shoulder blade",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep  the rib cage down ",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f46310f198824d49ce9aec1": {
    "label": "Shoulder blade scratch two hands",
    "setup":
      "Both hands in the back, fingers touching the corresponding shoulder blade",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep  the rib cage down ",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4631f3198824d49ce9aec8": {
    "label": "Shoulder stretch palms on the back",
    "setup": "Palms on the back as high as possible, fingers pointing down",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the muscles around the shoulder joint",
    "focus.2": "Keep the shoulders down",
    "focus.3": "",
    "repetitions.1": "Back and forth 10 times",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463228198824d49ce9aecd": {
    "label": "Standing hip inward rotation",
    "setup": "One knee up on the side",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the knee above hip crease while moving side to front",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions on each leg",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463230198824d49ce9aece": {
    "label": "Standing hip outward rotation",
    "setup": "One knee up in front",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the knee above hip crease while moving front to side",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions on each leg",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f463238198824d49ce9aecf": {
    "label": "Standing hip rotation holding foot",
    "setup": "One knee up in front, hand holding the lifted foot",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the torso as vertical as possible",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "5 repetitions on each leg",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f4631fc198824d49ce9aec9": {
    "label": "Standing wrist opener",
    "setup": "One arm bent, wrist extended by the opposite hand",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Relax the forearm of the extended wrist",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6016fb19a9a25e7d498454cb": {
    "label": "standing quad stretch",
    "setup": "",
    "setup.1": "One leg bent, hold the ankle",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your foot to your butt",
    "execution.2": "Bring your hips forward",
    "execution.3": "",
    "focus.1": "Feel the stretch on the quad",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds minimum on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6016fb4ab4d2ad7d5aeb2f35": {
    "label": "standing quad stretch with floor touch ",
    "setup": "",
    "setup.1": "One leg bent, hold the ankle",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your foot to your butt",
    "execution.2":
      "Bend forward, trying to keep knee, hips and shoulder aligned",
    "execution.3": "",
    "focus.1": "Feel the stretch on the quad",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds minimum on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6017c649bffb647d5bc52639": {
    "label": "seated legs apart head to floor",
    "setup": "",
    "setup.1": "Seated with legs wide apart",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your hands far in front",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Feel the stretch on the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum ",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6017c6a23157887d48886fab": {
    "label": "seated legs apart head to knee",
    "setup": "",
    "setup.1": "Seated with legs wide apart",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your head towards one knee",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Feel the stretch on the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "",
  },
  "6017c665bffb647d5bc5263a": {
    "label": "seated one leg behind head to knee",
    "setup": "",
    "setup.1": "Seated with leg in front, the other one bent behind",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your head towards the knee in front",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Feel the stretch on the hamstrings",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "10 small bounces at the end of range of motion",
    "repetitions.2": "20 seconds static ",
    "time": "20",
    "repetitions.3": "Switch sides",
  },
  "5f463117198824d49ce9aec2": {
    "label": "Upper and lower hands touch in the back",
    "setup":
      "Grab your hands in the back, one elbow overhead, the other elbow down",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the rib cage down",
    "focus.2": "Relax the muscles of the shoulders",
    "focus.3": "",
    "repetitions.1": "20 seconds on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5ffc856692910d5db9167e4b": {
    "label": "Warm-up run",
    "setup": "Pose",
    "setup.1": "Foot under the hip, ankle at opposite knee height",
    "setup.2": "Knee of support leg slightly bent",
    "setup.3": "Body weight on the ball of foot",
    "execution.1": "Run in place",
    "execution.2": "Fall forward",
    "execution.3": "",
    "focus.1": "Initial contact is on the ball of foot",
    "focus.2": "Heels kiss the ground at each stride",
    "focus.3": "",
    "repetitions.1": "Run for 5 to 15 minutes",
    "repetitions.2": "",
    "repetitions.3": "",
  },

  "5fe21af84d1d8c5b2d3f3711": {
    "label": "Achilles eccentric ",
    "setup": "",
    "setup.1": "Standing ball of feet on a step, heels outside",
    "setup.2": "Legs straight",
    "setup.3": "",
    "execution.1": "Lift both heels then remove one foot from the step",
    "execution.2": "Lower the heel of the foot left on the step,",
    "execution.3": "Put the airborne foot back on the step",
    "focus.1":
      "You must feel the stretch in your Achilles tendon on the way down",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 to 30 reps on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa0e3db441bdc7f651759": {
    "label": "Achilles eccentric stretch bent knee",
    "setup": "Standing ball of feet on a step, heels outside",
    "setup.1": "Legs bent",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Lift both heels then remove one foot from the step",
    "execution.2": "Lower the remaining heel",
    "execution.3": "Put the airborne foot back on the step",
    "focus.1":
      "You must feel the stretch in your Achilles tendon on the way down",
    "focus.2": "Keep your legs bent throughout the movement",
    "focus.3": "",
    "repetitions.1": "20 to 30 reps on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa164db441bdc7f65175a": {
    "label": "achilles eccentric straight to bent knee",
    "setup": "",
    "setup.1": "Standing ball of feet on a step, heels outside",
    "setup.2": "Legs straight",
    "setup.3": "",
    "execution.1": "Lift both heels then remove one foot from the step",
    "execution.2": "Lower the remaining heel while slightly bending your knee",
    "execution.3": "Put the airborne foot back on the step",
    "focus.1":
      "You must feel the stretch in your Achilles tendon on the way down",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 to 30 reps on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa18ddb441bdc7f65175b": {
    "label": "achilles eccentric weighted",
    "setup": "",
    "setup.1": "Standing ball of feet on a step, heels outside,",
    "setup.2": "Holding the weight on the working side",
    "setup.3": "Use a moderate weight you are comfortable with",
    "execution.1": "Lift both heels then remove one foot from the step",
    "execution.2": "Lower the remaining heel",
    "execution.3": "Put the airborne foot back on the step",
    "focus.1":
      "You must feel the stretch in your Achilles tendon on the way down",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 reps on each side",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1b6db441bdc7f65175c": {
    "label": "Achilles myofascial release",
    "setup": "",
    "setup.1": "Achilles tendon on the roller, other leg on top",
    "setup.2": "Butt off the ground",
    "setup.3": "",
    "execution.1": "Ankle circles in both directions",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1ccdb441bdc7f65175d": {
    "label": "Achilles roll",
    "setup": "",
    "setup.1": "Achilles tendon on the roller, other leg on top",
    "setup.2": "Butt off the ground",
    "setup.3": "",
    "execution.1": "Controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole tendon then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1dedb441bdc7f65175e": {
    "label": "Achilles smash",
    "setup": "",
    "setup.1": "Achilles tendon on the  ball",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa3d3db441bdc7f651760": {
    "label": "Achilles transverse massage",
    "setup": "",
    "setup.1": "Seated, one leg crossed",
    "setup.2": "Both thumbs on your Achilles tendon",
    "setup.3": "",
    "execution.1": "Massage perpendicularly to the achilles tendon",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole tendon then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa3e3db441bdc7f651761": {
    "label": "Adductor roll",
    "setup": "",
    "setup.1": "Leg bent on the side at 90° on the roller",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa1efdb441bdc7f65175f": {
    "label": "calf bar smash",
    "setup": "",
    "setup.1": "Bar perpendicular to the calf",
    "setup.2": "Insist on sore areas",
    "setup.3": "",
    "execution.1": "Roll bar on calf",
    "execution.2": "Adjust the pressure of the bar",
    "execution.3": "",
    "focus.1": "Keep the calf relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4bedb441bdc7f651763": {
    "label": "calf smash myofascial release",
    "setup": "",
    "setup.1": "One calf on the ball",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Find a sensitive point and stay there ",
    "execution.2": "Cross the other leg over",
    "execution.3": "Ankle circles in both directions",
    "focus.1": "Keep the calf relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4c9db441bdc7f651764": {
    "label": "calf myofascial release",
    "setup": "",
    "setup.1": "One calf on the roller, other leg on top",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Find a sensitive point and stay there ",
    "execution.2": "Ankle circles in both directions",
    "execution.3": "",
    "focus.1": "Keep the calf relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4d3db441bdc7f651765": {
    "label": "calf roll one leg",
    "setup": "",
    "setup.1": "One calf on the roller, other leg on top",
    "setup.2": "Butt off the ground",
    "setup.3": "",
    "execution.1": "Controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fb180db441bdc7f65177c": {
    "label": "calf roll two legs",
    "setup": "",
    "setup.1": "Both calves on the roller",
    "setup.2": "Butt off the ground",
    "setup.3": "",
    "execution.1": "Controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fb1b3db441bdc7f65177d": {
    "label": "calf smash",
    "setup": "",
    "setup.1": "One calf on the ball ",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keep the calf relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fb20adb441bdc7f65177e": {
    "label": "external shin myofascial release",
    "setup": "",
    "setup.1": "Leg bent with external shin muscle on the ball",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Find a sensitive point and stay there ",
    "execution.2": "Ankle circles in both directions",
    "execution.3": "",
    "focus.1": "Keep the muscle relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa3f0db441bdc7f651762": {
    "label": "external shin smash",
    "setup": "",
    "setup.1": "Leg bent with external shin muscle on the ball",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4f1db441bdc7f651767": {
    "label": "shin myofascial release",
    "setup": "",
    "setup.1": "Anterior muscles of the Anterior muscles of the shin on roller",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Find a sensitive point and stay there ",
    "execution.2": "Ankle circles in both directions",
    "execution.3": "",
    "focus.1": "Keep the muscle relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa4f9db441bdc7f651768": {
    "label": "shin roll one leg",
    "setup": "",
    "setup.1": "Anterior muscles of the shin on roller",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa501db441bdc7f651769": {
    "label": "shin roll two legs",
    "setup": "",
    "setup.1": "Anterior muscles of both shins",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa50adb441bdc7f65176a": {
    "label": "glute roll",
    "setup": "",
    "setup.1": "Sit on the roller",
    "setup.2": "Crossed legs, ankle on the opposite knee",
    "setup.3": "",
    "execution.1": "Roll the butt cheek of the crossed leg",
    "execution.2": "Slow and controlled movements ",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa513db441bdc7f65176b": {
    "label": "glute smash",
    "setup": "",
    "setup.1": "Crossed legs, ankle on the opposite knee",
    "setup.2": "Put the ball under the butt cheek of the crossed leg",
    "setup.3": "",
    "execution.1": "Roll the butt cheek of the crossed leg",
    "execution.2": "Slow and controlled movements ",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa51bdb441bdc7f65176c": {
    "label": "hamstring roll one leg",
    "setup": "",
    "setup.1": "Hamstring on the roller",
    "setup.2": "Butt  off the ground",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa523db441bdc7f65176d": {
    "label": "hamstring roll two legs",
    "setup": "",
    "setup.1": "Both hamstrings on the roller",
    "setup.2": "Butt off the ground",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa52adb441bdc7f65176e": {
    "label": "hamstring roll crossed leg",
    "setup": "",
    "setup.1": "Hamstring on the roller",
    "setup.2": "Other leg crossed on top",
    "setup.3": "Butt off the ground",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa534db441bdc7f65176f": {
    "label": "Internal shin myofascial release",
    "setup": "",
    "setup.1": "Ball on the inner side of the tibia, slightly behind",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Find a sensitive point and stay there ",
    "execution.2": "Ankle circles in both directions",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa53bdb441bdc7f651770": {
    "label": "Internal shin smash",
    "setup": "",
    "setup.1": "Ball inner side of the tibia, slightly behind",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa544db441bdc7f651771": {
    "label": "kneeling toe stretch one foot",
    "setup": "",
    "setup.1": "Kneeling on one side, toes flexed",
    "setup.2": "Other foot flat on the ground",
    "setup.3": "",
    "execution.1": "Apply gentle pressure on your flexed toes by sitting back",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Apply the tension in a progressive way",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa54edb441bdc7f651772": {
    "label": "kneeling toe stretch two feet",
    "setup": "",
    "setup.1": "Kneeling on both side, toes flexed",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Apply gentle pressure one your flexed toes by sitting back",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Apply the tension in a progressive way",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa556db441bdc7f651773": {
    "label": "lower leg shin saw",
    "setup": "",
    "setup.1": "Kneeling on one side",
    "setup.2": "Other shin perpendicularly the calf",
    "setup.3": "",
    "execution.1":
      "Apply gentle pressure to massage the whole calf with your shin",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Keeping the muscle relaxed",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa55fdb441bdc7f651774": {
    "label": "Piriformis Roll",
    "setup": "",
    "setup.1": "Sit on the roller",
    "setup.2": "Crossed legs, ankle on the opposite knee",
    "setup.3": "Incline the crossed leg to the side",
    "execution.1": "Roll the butt cheek of the crossed leg",
    "execution.2": "Slow and controlled movements ",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa567db441bdc7f651775": {
    "label": "Piriformis smash",
    "setup": "",
    "setup.1": "Crossed legs, ankle on the opposite knee",
    "setup.2": "Put the ball under the butt cheek of the crossed leg",
    "setup.3": "Incline the crossed leg to the side",
    "execution.1": "Roll the butt cheek of the crossed leg",
    "execution.2": "Slow and controlled movements ",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa570db441bdc7f651776": {
    "label": "quad roll one leg",
    "setup": "",
    "setup.1": "Quad on the roller",
    "setup.2": "Rest on your forearms",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa579db441bdc7f651777": {
    "label": "quad roll two legs",
    "setup": "",
    "setup.1": "Both quads on the roller",
    "setup.2": "Rest on your forearms",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "6017c513a9a25e7d498454cd": {
    "label": "quad smash",
    "setup": "",
    "setup.1": "Quad on the ball",
    "setup.2": "Rest on your forearms",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa582db441bdc7f651778": {
    "label": "quad bar smash",
    "setup": "",
    "setup.1": "Sitting, bar placed perpendicularly on the quadriceps",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the whole muscle then insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa58ddb441bdc7f651779": {
    "label": "toe to calf stretch",
    "setup": "",
    "setup.1": "Toes flexed against a wall or step",
    "setup.2": "Rest of the foot flat on the ground",
    "setup.3": "",
    "execution.1": "Bring gently your knee forward",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Apply the tension in a progressive way",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa595db441bdc7f65177a": {
    "label": "Underfoot ball of foot smash",
    "setup": "",
    "setup.1": "Foot on the ball,",
    "setup.2": "Ball just behind the forefoot of foot",
    "setup.3": "",
    "execution.1": "Keep the heel on the floor",
    "execution.2": "Move forefoot from one side to the other",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds minimum on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "600fa5a4db441bdc7f65177b": {
    "label": "Underfoot Pre heel  smash",
    "setup": "",
    "setup.1": "Foot on the ball,",
    "setup.2": "Ball just in front of heel",
    "setup.3": "",
    "execution.1": "Keep the forefoot on the floor",
    "execution.2": "Move heel from one side to the other",
    "execution.3": "",
    "focus.1": "Insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "20 seconds minimum on each side",
    "time": "20",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f225c7dc86d3d655edeee": {
    "label": "Lower back roll",
    "setup": "",
    "setup.1": "Roller on the lower back",
    "setup.2": "Butt  off the ground",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the lumbar muscles and insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f233e7dc86d3d655edeef": {
    "label": "Upper back roll",
    "setup": "",
    "setup.1": "Roller on the upper back",
    "setup.2": "Butt  off the ground",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the upper back muscles and insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "60 seconds minimum",
    "time": "60",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f23987dc86d3d655edef0": {
    "label": " Lower back Side roll",
    "setup": "",
    "setup.1": "Roller on the side of lower back",
    "setup.2": "Butt  off the ground",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Work on the lower back side muscles and insist on sore areas",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "607f23d27dc86d3d655edef1": {
    "label": "T-spine extensions",
    "setup": "",
    "setup.1": "Roller on the upper back, under the shoulder blades",
    "setup.2": "Hands opened, wrist crossed behind the head",
    "setup.3": "",
    "execution.1": "Slow and controlled movements ",
    "execution.2": "",
    "execution.3": "",
    "focus.1": "Inhale while opening the rib cage, exhale on the way back",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "45 seconds minimum on each side",
    "time": "45",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f3c29a7d6692d69800007bd": {
    "label": "forward lunge",
    "setup": "Stand tall with your feet hip distance apart.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Take a large step forward",
    "execution.2":
      "Lower your body until your rear foot slightly kiss the ground",
    "execution.3":
      "Press into your front heel to get back to starting position. Switch leg.",
    "focus.1": "Keep your torso upright",
    "focus.2": "Your front knee should not pass over your toes",
    "focus.3": "",
    "repetitions.1": "12 lunges alternating",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f3c285fd6692d69800007bc": {
    "label": "backward lunge",
    "setup": "Stand tall with your feet hip distance apart.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Take a large step backward",
    "execution.2":
      "Lower your body until your rear foot slightly kiss the ground",
    "execution.3":
      "Press into your rear foot to get back to the starting position. Switch leg.",
    "focus.1": "Keep your torso upright",
    "focus.2": "Your front knee should not pass over your toes",
    "focus.3": "",
    "repetitions.1": "12 lunges alternating",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fb75961e44ba484f6ab04": {
    "label": "Sit ups",
    "setup": "Sited with both feet on the floor, arms crossed on the chest",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring your shoulder blades to the floor",
    "execution.2": "Get back to the starting position ",
    "execution.3": "",
    "focus.1": "Keep a neutral head position",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fb76761e44ba484f6ab05": {
    "label": "Leg raises",
    "setup": "Laying down on your back, knee slightly bent",
    "setup.1": "Heels off the ground",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Bring both legs to the vertical",
    "execution.2": "Go back to the starting position",
    "execution.3": "",
    "focus.1": "",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fb77161e44ba484f6ab06": {
    "label": "Good morning",
    "setup": "Stand tall with your feet hip distance apart.",
    "setup.1": "Arms crossed on the chest",
    "setup.2": "Knees slightly bent",
    "setup.3": "",
    "execution.1": "Push your hip back, keeping a neutral lumbar curve",
    "execution.2": "Descend until your chest is parallel with floor",
    "execution.3": "Get back to starting position",
    "focus.1": "No rounding the lower back",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "15 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fd18261e44ba484f6ab08": {
    "label": "Burpee",
    "setup": "Stand tall with your feet hip distance apart.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1":
      "Place hands on the floor about shoulder width, jump back in the push-up position",
    "execution.2": "Lower chest and thighs to the ground",
    "execution.3": "Push up, jump the feet towards the hands and jump tall",
    "focus.1": "Keep a steady rythm",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "8 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "609fd1d061e44ba484f6ab09": {
    "label": "Air Squat",
    "setup": "Stand tall with your feet shoulder width.",
    "setup.1": "",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Hips descend back and down",
    "execution.2": "No rounding the lower back",
    "execution.3": "Knee in line with toes",
    "focus.1": "Keep your weight on your heels",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "12 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
  "5f3c2772d6692d69800007bb": {
    "label": "Runner squat",
    "setup": "Stand tall with your feet shoulder width.",
    "setup.1": "Shift your weight towards your ball of foot",
    "setup.2": "",
    "setup.3": "",
    "execution.1": "Hips descend back and down",
    "execution.2": "No rounding the lower back",
    "execution.3": "Knee in line with toes",
    "focus.1": "Focus on keeping your balance",
    "focus.2": "",
    "focus.3": "",
    "repetitions.1": "12 repetitions",
    "repetitions.2": "",
    "repetitions.3": "",
  },
}
export default en_us
