import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid, Button, Container, Icon, Dropdown } from 'semantic-ui-react'
import {
  getAllProtocols,
  createProtocol,
  updateProtocolIsAvailable,
  copyProtocol,
  updateProtocol,
} from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { withPolyglot } from '../vendor/polyglot-react'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import moment from 'moment'
import AppError from '../components/AppError'

const selectOptions = [
  { key: 'custom', text: 'Custom', value: 'custom' },
  { key: 'neutral', text: 'Neutral', value: 'neutral' },
  { key: 'pose', text: 'Pose', value: 'pose' },
  { key: 'fall', text: 'Fall', value: 'fall' },
  { key: 'pull', text: 'Pull', value: 'pull' },
  { key: 'learn', text: 'Learn', value: 'learn' },
  { key: 'work', text: 'Work', value: 'work' },
  { key: 'short', text: 'Short', value: 'short' },
  { key: 'long', text: 'Long', value: 'long' },
  { key: 'focus', text: 'Focus', value: 'focus' },
  { key: 'cadence', text: 'Cadence', value: 'cadence' },
  { key: 'selfcare', text: 'SelfCare', value: 'selfcare' },

  { key: '5k', text: '5k', value: '5k' },
  { key: '10k', text: '10k', value: '10k' },
  { key: 'half', text: 'Half-marathon', value: 'half' },
  { key: 'marathon', text: 'Marathon', value: 'marathon' },
]
class Protocols extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [
        {
          dataField: 'date',
          text: 'Date',
          editable: false,
          formatter: (cell, row) => (
            <Link to={'/protocols/' + row.id}>
              {' '}
              {moment(cell).format(' ddd DD/MM/YYYY')}{' '}
            </Link>
          ),
        },
        {
          dataField: 'label',
          text: 'Label',
          editable: false,
          formatter: (cell, row) => (
            <Link to={'/protocols/' + row.id}>{cell}</Link>
          ),
        },
        {
          dataField: 'type',
          text: 'Type ',
          editable: false,
        },
        {
          dataField: 'lightFlexibilityDrills',
          text: 'Light Flexibility',
          editable: false,
          formatter: (cell) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          ),
        },

        {
          dataField: 'warmupDrills',
          text: 'Warmup',
          editable: false,
          formatter: (cell) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          ),
        },
        {
          dataField: 'strengthDrills',
          text: 'Strength',
          editable: false,
          formatter: (cell) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          ),
        },
        {
          dataField: 'drills',
          text: 'Drills',
          editable: false,
          formatter: (cell) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          ),
        },

        {
          dataField: 'flexibilityDrills',
          text: 'Flexibility',
          editable: false,
          formatter: (cell) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          ),
        },
        {
          dataField: 'selfCareDrills',
          text: 'selfCare',
          editable: false,
          formatter: (cell) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          ),
        },
        {
          dataField: 'gear',
          text: 'Gear',
          editable: false,
          formatter: (cell) => (
            <span>{Array.isArray(cell) && cell[0] ? cell.join(', ') : ''}</span>
          ),
        },
        {
          dataField: 'isAvailable',
          text: 'isAvailable',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },
        {
          dataField: 'isFree',
          text: 'Free',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon  unlock color-green',
            false: 'icon lock color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },

        {
          dataField: '',
          text: '',
          editable: false,
          formatter: (cell, row) => (
            <Icon name="copy" onClick={() => copyProtocol(row.id)} />
          ),
        },
      ],
      hasError: 0,
      error: false,
      isLoading: true,
    }
  }

  async getData(type) {
    let response

    var filter = type || this.props.match.params.type || 'custom'
    if (filter) {
      response = await getAllProtocols(filter)
    } else {
      response = await getAllProtocols()
    }

    if (response.ok) {
      const data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        tableData: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
      })
      this.setState({ redirectToReferrer: true })
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }
  componentDidMount() {
    this.getData()
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i aria-hidden="true" className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }
  handleCellSave(dataField, row, value) {
    switch (dataField) {
      case 'isAvailable': {
        updateProtocolIsAvailable(row.id, value)
        break
      }
      case 'isFree': {
        let body = Object.assign(row, { [dataField]: value })
        updateProtocol(row.id, body)
        break
      }
      default:
        break
    }
  }

  call_createProtocol() {
    const { polyglot } = this.props
    const defaultParams = {
      label: polyglot.t('protocol.new'),
    }
    createProtocol(defaultParams)
      .then((res) => {
        if (res.ok) {
          const newId = res.data._id
          this.props.history.push('/protocols/' + newId)
        }
      })
      .catch()
  }
  setFilter = (e, { value }) => {
    this.getData(value)
  }
  render() {
    //		const { polyglot } = this.props;
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container fluid>
          <style>{`
		 .react-bootstrap-table{
		text-align:left;
		background-color:#fff
		 }
		.sr-only{display:none}


	      .ui.fluid.container.pushable {
		   padding-left:200px !important;
		   }
	`}</style>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Button
                  circular
                  icon="plus"
                  onClick={() => this.call_createProtocol()}
                />
                <Dropdown
                  selection={true}
                  value={this.props.match?.params?.type}
                  placeholder="Type"
                  options={selectOptions}
                  onChange={this.setFilter}
                />

                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: () => {},
                    beforeSaveCell: (oldvalue, newValue, row, column) => {
                      this.handleCellSave(column.dataField, row, newValue)
                    },
                    afterSaveCell: () => {},
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

Protocols.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}
Protocols = withPolyglot()(Protocols)
export default Protocols
