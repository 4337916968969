import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Icon, Image } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'

import DrillDetails from './DrillDetails'
import DrillVideo from './DrillVideo'

import Workout from './Workout'
import workout from '../images/workout.jpg'

class SessionElement extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      showSetupDetails: false,
      playing: true,
      loading: true,
      showInstructions: false,
      showFocus: false,
      issueReported: false,
    }
  }

  toggleSetupDetailsVisibility = () => {
    this.setState({ showSetupDetails: !this.state.showSetupDetails })
  }

  togglePlay = () => {
    this.setState({ playing: !this.state.playing })
  }
  showInstructions = () => {
    this.setState({
      showFocus: false,
    })
    this.setState({
      showInstructions: !this.state.showInstructions,
    })
  }
  showFocus = () => {
    this.setState({
      showInstructions: false,
    })
    this.setState({
      showFocus: !this.state.showFocus,
    })
  }

  isSaved = false
  render() {
    const {
      leftIcon,
      rightIcon,
      doneIcon,
      trainingMode,
      learningMode,
      element,
      drillIndex,
      activeIndex,
      drillsTotalCount,
      drillsIdByType,
      protocolId,
    } = this.props
    const iconClassLeft = 'floated left color-grey top-10'
    const iconClassRight = 'floated right color-grey top-10'
    const iconDoneClassRight = 'floated right app-button green'
    const transparentClassLeft = 'floated left  top-10 transparent'
    const transparentClassRight = 'floated right  top-10 transparent'
    const showLeftIcon = leftIcon ? leftIcon.show : false
    const showRightIcon = rightIcon ? rightIcon.show : false
    const showDoneIcon = doneIcon ? doneIcon.show : false

    element.id = element.id ? element.id : element._id

    if (showDoneIcon) {
      //des qu'on est sur le dernier slide on sauvegarde

      if (drillsTotalCount !== 1 && activeIndex + 1 === drillsTotalCount) {
        if (!this.isSaved) {
          doneIcon.action()
          this.isSaved = true
        }
      }
    }

    const prevView = () => {
      if (leftIcon) {
        leftIcon.action()
      } else {
        return false
      }
    }
    const nextView = () => {
      if (rightIcon) {
        rightIcon.action()
      } else {
        return false
      }
    }
    const done = () => {
      let workoutUrl = window.sessionStorage.getItem('RWJWorkoutUrl')
      let lastPlanUrl = window.sessionStorage.getItem('RWJLastPlanUrl')
      if (doneIcon) {
        doneIcon.action()

        if (workoutUrl) {
          this.props.history.push({
            pathname: workoutUrl,
            state: { displayWorkout: true },
          })
        } else if (learningMode === true && lastPlanUrl) {
          let lastPlanUrl = window.sessionStorage.removeItem('RWJLastPlanUrl')
          window.sessionStorage.removeItem('RWJLastPlanUrl')

          this.props.history.push(lastPlanUrl)
        } else {
          this.props.history.push('/')
        }
      } else {
        return false
      }
    }

    const progress = () => {
      let result = []
      let currentSection
      ;[
        'lightFlexibility',
        'warmup',
        'strength',
        'technique',
        'workout',
        'flexibility',
        'selfCare',
      ].forEach((e) => {
        if (drillsIdByType && drillsIdByType.hasOwnProperty(e)) {
          let section = drillsIdByType[e]

          let isCurrentSection = section.includes(element.id)
          if (isCurrentSection) currentSection = e

          result.push(<span style={{ paddingLeft: '2px' }} key={e + '_1'} />)
          result = result.concat(
            section.map((x) => (
              <Icon
                key={x}
                name="circle"
                inverted
                size={x === '' + element.id ? 'small' : 'mini'}
                color={isCurrentSection ? 'grey' : 'black'}
              />
            ))
          )
          if (section.length !== 0)
            result.push(<span style={{ paddingLeft: '2px' }} key={e + '_2'} />)
        }
      })
      return (
        drillsIdByType && (
          <span>
            <div style={{ fontWeight: 600 }}>
              {this.props.polyglot.t(`protocol.${currentSection}`)}
            </div>
            <span>{result} </span>
          </span>
        )
      )
    }
    return (
      <Fragment>
        <style>
          {`

				.icon-container {
					color: white;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					text-align: center;

				}
				.icon-container .icon
				{
				/*	color:#db2828*/
				}

        `}
        </style>
        {element.video && (
          <DrillVideo
            element={element}
            activeIndex={activeIndex}
            drillIndex={drillIndex}
          />
        )}
        {element._id === 'workout' && <Image src={workout} />}
        <style>{`
					.drill-video {
						min-height:300px !important;
					}
						.ui.tiny.progress .bar {
							height: .2em;
						}
						.ui.progress {
							background: #bcbdbd4a;}


						`}</style>

        <Card fluid className="dark  transparent drill-video">
          <Card.Content style={{ paddingTop: 0 }}>
            <Icon
              name="chevron left"
              size="large"
              className={showLeftIcon ? iconClassLeft : transparentClassLeft}
              onClick={showLeftIcon ? prevView : () => {}}
            />
            {showRightIcon && (
              <Icon
                name="chevron right"
                size="large"
                className={
                  showRightIcon ? iconClassRight : transparentClassRight
                }
                onClick={showRightIcon ? nextView : () => {}}
              />
            )}
            {showDoneIcon && (
              <Button
                circular
                icon="check"
                className={iconDoneClassRight}
                onClick={showDoneIcon ? done : () => {}}
              />
            )}
            {progress()}

            {element._id === 'workout' ? (
              <Fragment>
                {' '}
                <div className="top-20" />
                <Workout
                  workout={element}
                  location={this.props.location}
                  protocolId={protocolId}
                />{' '}
              </Fragment>
            ) : (
              <Fragment>
                <DrillDetails
                  element={element}
                  trainingMode={trainingMode}
                  learningMode={learningMode}
                />
              </Fragment>
            )}
          </Card.Content>
        </Card>
      </Fragment>
    )
  }
}

SessionElement.propTypes = {
  element: PropTypes.object,
  drillIndex: PropTypes.number,
  activeIndex: PropTypes.number,

  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  doneIcon: PropTypes.object,
  trainingMode: PropTypes.bool,
  learningMode: PropTypes.bool,
  drillsTotalCount: PropTypes.number,
  drillsIdByType: PropTypes.object,
  protocolId: PropTypes.string,
  location: PropTypes.object,
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
SessionElement = withPolyglot()(SessionElement)
export default SessionElement
