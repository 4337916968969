import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Card } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'

class AppCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      hasError: 0,
      error: false,
      isLoading: true,
    }
  }

  render() {
    const {
      asHeader,
      heightOverride,
      style = { textAlign: 'left' },
      onClick,
      children,
      header,
      additionalClasses,
    } = this.props

    return (
      <Card
        fluid
        className={'app-card ' + additionalClasses}
        style={{
          height: heightOverride ? heightOverride : asHeader ? 140 : 190,
          ...style,
        }}
        onClick={onClick}
      >
        <style>
          {`
			.ui.card.app-card {
			color:#fff;
			overflow: hidden;
			border-radius: 0.75rem !important	;
			background-color: #1d1d1d;
			box-shadow:none !important;
			background-image: linear-gradient(to right bottom, #1a1a1a, #1c1c1c, #1d1d1d, #1f1f1f, #212121);*/

		}
		.ui.card.app-card>.content>.header, .header{
			color:#fff;
		}
		.ui.card.app-card:hover{
			background: inherit;
		}
		`}
        </style>

        <span>{header} </span>
        {children && children.length !== 0 && (
          <Card.Content>{children}</Card.Content>
        )}
      </Card>
    )
  }
}
AppCard.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  asHeader: PropTypes.bool,
  heightOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  additionalClasses: PropTypes.string,
}
AppCard = withPolyglot()(AppCard)
export default AppCard
