import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Header,
  Grid,
  Card,
  Dimmer,
  Loader,
  Tab,
  Feed,
  List,
  Image,
  Button,
  Table,
  Icon,
  Confirm,
} from "semantic-ui-react"
import {
  getProtocol,
  updateProtocol,
  getDrills,
  copyProtocol,
  addDrillToProtocol,
  removeDrillFromProtocol,
  addWarmupDrillToProtocol,
  removeWarmupDrillFromProtocol,
  addFlexibilityDrillToProtocol,
  removeFlexibilityDrillFromProtocol,
  addLightFlexibilityDrillToProtocol,
  removeLightFlexibilityDrillFromProtocol,
  addStrengthDrillToProtocol,
  removeStrengthDrillFromProtocol,
  addSelfCareDrillToProtocol,
  removeSelfCareDrillFromProtocol,
  updateProtocolDrills,
  saveWorkout,
  deleteProtocol,
} from "../services/Api"
import { withPolyglot } from "../vendor/polyglot-react"
import { differenceBy, filter } from "lodash"
import { RIEInput, RIESelect } from "@attently/riek"
import RLDD from "react-list-drag-and-drop/lib/RLDD"
import moment from "moment"

import AppError from "../components/AppError"
import WorkoutEdit from "../components/WorkoutEdit"
import { toast } from "react-toastify"
import RIETextArea from "@attently/riek/lib/RIETextArea"
import Workout from "../components/Workout"
const types = [
  { id: "custom", text: "Custom" },
  { id: "neutral", text: "Neutral" },
  { id: "pose", text: "Pose" },
  { id: "fall", text: "Fall" },
  { id: "cadence", text: "Cadence" },
  { id: "pull", text: "Pull" },
  { id: "learn", text: "Learn" },
  { id: "work", text: "Work" },
  { id: "short", text: "Short" },
  { id: "long", text: "Long" },
  { id: "focus", text: "Focus" },
  { id: "selfcare", text: "SelfCare" },

  { id: "5k", text: "5k" },
  { id: "10k", text: "10k", value: "10k" },
  { id: "half", text: "Half-marathon" },
  { id: "marathon", text: "Marathon" },
]
class Protocol extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      players: [],
      allDrills: [],
      drills: [],
      warmupDrills: [],
      flexibilityDrills: [],
      lightFlexibilityDrills: [],
      strengthDrills: [],
      slefcareDrills: [],
      currentDrillBloc: "warmup",
      protocolData: {},
      notificationData: {},
      activeTabIndex: 0,
      workoutPaneExpanded:
        JSON.parse(localStorage.getItem("RWJWorkoutPaneExpanded")) || false,
    }
    const { polyglot } = props
    this.state.notificationData.message = polyglot.t(
      "course.notification.default.message"
    )
    this.state.notificationData.title = polyglot.t(
      "course.notification.default.title"
    )
  }
  componentDidMount() {
    if (this.call_getProtocol()) {
      this.call_getDrills()
    }
  }

  async call_getProtocol() {
    const response = await getProtocol(this.props.match.params.id)
    if (response.ok) {
      let data = response.data

      this.setState({
        showDrillsPane: JSON.parse(localStorage.getItem("RWJShowDrillsPane")),
        isLoading: false,
        hasError: false,
        drills: data.drills || [],
        warmupDrills: data.warmupDrills || [],
        flexibilityDrills: data.flexibilityDrills || [],
        lightFlexibilityDrills: data.lightFlexibilityDrills || [],
        strengthDrills: data.strengthDrills || [],
        selfCareDrills: data.selfCareDrills || [],
        drillsOrdered: data.drills.map((x, i) => ({
          id: i,
          drill: x,
        })),
        warmupDrillsOrdered: data.warmupDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        flexibilityDrillsOrdered: data.flexibilityDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        lightFlexibilityDrillsOrdered: data.lightFlexibilityDrills.map(
          (x, i) => ({
            id: i,
            drill: x,
          })
        ),

        strengthDrillsOrdered: data.strengthDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        selfCareDrillsOrdered: data.selfCareDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        workout: data.workout,
        protocolData: data,
      })
      return true
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  async call_getDrills() {
    const response = await getDrills()
    if (response.ok) {
      // const { polyglot } = this.props;
      const data = response.data.rows
      this.setState({
        allDrills: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
      })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  async addToProtocol(type, object) {
    const drill = object

    var response
    if (this.state.currentDrillBloc === "warmup") {
      response = await addWarmupDrillToProtocol(this.props.match.params.id, {
        drillId: drill.id,
      })
    } else if (this.state.currentDrillBloc === "flexibility") {
      response = await addFlexibilityDrillToProtocol(
        this.props.match.params.id,
        {
          drillId: drill.id,
        }
      )
    } else if (this.state.currentDrillBloc === "lightFlexibility") {
      response = await addLightFlexibilityDrillToProtocol(
        this.props.match.params.id,
        {
          drillId: drill.id,
        }
      )
    } else if (this.state.currentDrillBloc === "strength") {
      response = await addStrengthDrillToProtocol(this.props.match.params.id, {
        drillId: drill.id,
      })
    } else if (this.state.currentDrillBloc === "selfcare") {
      response = await addSelfCareDrillToProtocol(this.props.match.params.id, {
        drillId: drill.id,
      })
    } else {
      response = await addDrillToProtocol(this.props.match.params.id, {
        drillId: drill.id,
      })
    }

    if (response.ok) {
      this.setState({
        drills: response.data.drills,
        drillsOrdered: response.data.drills.map((x, i) => ({
          id: i,
          drill: x,
        })),
        warmupDrills: response.data.warmupDrills,
        warmupDrillsOrdered: response.data.warmupDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        flexibilityDrills: response.data.flexibilityDrills,
        flexibilityDrillsOrdered: response.data.flexibilityDrills.map(
          (x, i) => ({
            id: i,
            drill: x,
          })
        ),

        lightFlexibilityDrills: response.data.lightFlexibilityDrills,
        lightFlexibilityDrillsOrdered: response.data.lightFlexibilityDrills.map(
          (x, i) => ({
            id: i,
            drill: x,
          })
        ),

        strengthDrills: response.data.strengthDrills,
        strengthDrillsOrdered: response.data.strengthDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        selfCareDrills: response.data.selfCareDrills,
        selfCareDrillsOrdered: response.data.selfCareDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),
      })
    }
  }
  async removeFromProtocol(type, drill) {
    // this.addToProtocol('lightFlexibilityDrill', drill)
    var response
    if (type === "warmupDrill") {
      response = await removeWarmupDrillFromProtocol(
        this.props.match.params.id,
        {
          drillId: drill._id || drill.id,
        }
      )
    } else if (type === "flexibilityDrill") {
      response = await removeFlexibilityDrillFromProtocol(
        this.props.match.params.id,
        {
          drillId: drill._id || drill.id,
        }
      )
    } else if (type === "lightFlexibilityDrill") {
      response = await removeLightFlexibilityDrillFromProtocol(
        this.props.match.params.id,
        {
          drillId: drill._id || drill.id,
        }
      )
    } else if (type === "strengthDrill") {
      response = await removeStrengthDrillFromProtocol(
        this.props.match.params.id,
        {
          drillId: drill._id || drill.id,
        }
      )
    } else if (type === "selfCareDrill") {
      response = await removeSelfCareDrillFromProtocol(
        this.props.match.params.id,
        {
          drillId: drill._id || drill.id,
        }
      )
    } else {
      response = await removeDrillFromProtocol(this.props.match.params.id, {
        drillId: drill._id || drill.id,
      })
    }

    if (response.ok) {
      // pullAllBy(newArray, [player], "email")

      this.setState({
        drills: response.data.drills,
        warmupDrills: response.data.warmupDrills,
        drillsOrdered: response.data.drills.map((x, i) => ({
          id: i,
          drill: x,
        })),
        warmupDrillsOrdered: response.data.warmupDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),
        flexibilityDrills: response.data.flexibilityDrills,
        flexibilityDrillsOrdered: response.data.flexibilityDrills.map(
          (x, i) => ({
            id: i,
            drill: x,
          })
        ),
        lightFlexibilityDrills: response.data.lightFlexibilityDrills,
        lightFlexibilityDrillsOrdered: response.data.lightFlexibilityDrills.map(
          (x, i) => ({
            id: i,
            drill: x,
          })
        ),
        strengthDrills: response.data.strengthDrills,
        strengthDrillsOrdered: response.data.strengthDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),

        selfCareDrills: response.data.selfCareDrills,
        selfCareDrillsOrdered: response.data.selfCareDrills.map((x, i) => ({
          id: i,
          drill: x,
        })),
      })
    }
  }
  onDatePickerChange(date) {
    this.call_updateProtocol({ date: date })
  }
  call_updateProtocol = (prop) => {
    if (Object.prototype.hasOwnProperty.call(prop, "date")) {
      prop["date"] = moment(prop.date, "DD-MM-YYYY")
    }
    if (typeof prop.type === "object") {
      prop = { type: prop.type.id }
    }

    let newprotocolData = this.state.protocolData
    Object.assign(newprotocolData, prop)
    const newState = Object.assign({}, this.state, {
      protocolData: newprotocolData,
    })
    updateProtocol(this.props.match.params.id, newprotocolData)
      .then((response) => {
        if (response.ok) {
          toast.success(this.props.polyglot.t("generic.message.done"))
          this.setState(newState)
        } else {
          toast.error(this.props.polyglot.t("generic.message.error"))
        }
      })
      .catch()
  }

  updateAllDrills() {
    let newprotocolData = this.state.protocolData
    Object.assign(newprotocolData, {
      warmupDrills: this.state.warmupDrills.map((x) => x.id || x._id),
      drills: this.state.drills.map((x) => x.id || x._id),
      flexibilityDrills: this.state.flexibilityDrills.map((x) => x.id || x._id),
      lightFlexibilityDrills: this.state.lightFlexibilityDrills.map(
        (x) => x.id || x._id
      ),
      strengthDrills: this.state.strengthDrills.map((x) => x.id || x._id),
      selfCareDrills: this.state.selfCareDrills.map((x) => x.id || x._id),
    })
    updateProtocolDrills(this.props.match.params.id, newprotocolData)
      .then((response) => {
        if (response.ok) {
          toast.success(this.props.polyglot.t("generic.message.done"))
          this.setState({
            isLoading: false,
            hasError: false,
            drills: response.data.drills || [],
            drillsOrdered: response.data.drills.map((x, i) => ({
              id: i,
              drill: x,
            })),
            warmupDrills: response.data.warmupDrills || [],
            warmupDrillsOrdered: response.data.warmupDrills.map((x, i) => ({
              id: i,
              drill: x,
            })),
            strengthDrills: response.data.strengthDrills || [],
            strengthDrillsOrdered: response.data.strengthDrills.map((x, i) => ({
              id: i,
              drill: x,
            })),
            selfCareDrills: response.data.selfCareDrills || [],
            selfCareDrillsOrdered: response.data.selfCareDrills.map((x, i) => ({
              id: i,
              drill: x,
            })),
            flexibilityDrills: response.data.flexibilityDrills || [],
            flexibilityDrillsOrdered: response.data.flexibilityDrills.map(
              (x, i) => ({
                id: i,
                drill: x,
              })
            ),
            lightFlexibilityDrills: response.data.lightFlexibilityDrills || [],
            lightFlexibilityDrillsOrdered:
              response.data.lightFlexibilityDrills.map((x, i) => ({
                id: i,
                drill: x,
              })),
            protocolData: response.data,
          })
        } else {
          toast.error(this.props.polyglot.t("generic.message.error"))
        }
      })
      .catch()
  }
  handleRLDDChangeWarmupDrills = (newItems) => {
    this.setState({
      warmupDrillsOrdered: newItems,
      warmupDrills: newItems.map((x) => {
        return x.drill
      }),
    })
  }

  handleRLDDChangeDrills = (newItems) => {
    this.setState({
      drillsOrdered: newItems,
      drills: newItems.map((x) => {
        return x.drill
      }),
    })
  }

  handleRLDDChangeFlexibilityDrills = (newItems) => {
    this.setState({
      flexibilityDrillsOrdered: newItems,
      flexibilityDrills: newItems.map((x) => {
        return x.drill
      }),
    })
  }
  handleRLDDChangeLightFlexibilityDrills = (newItems) => {
    this.setState({
      lightFlexibilityDrillsOrdered: newItems,
      lightFlexibilityDrills: newItems.map((x) => {
        return x.drill
      }),
    })
  }

  handleRLDDChangeStrengthDrills = (newItems) => {
    this.setState({
      strengthDrillsOrdered: newItems,
      strengthDrills: newItems.map((x) => {
        return x.drill
      }),
    })
  }

  handleRLDDChangeSelfCareDrills = (newItems) => {
    this.setState({
      selfCareDrillsOrdered: newItems,
      selfCareDrills: newItems.map((x) => {
        return x.drill
      }),
    })
  }

  call_copyProtocol = async () => {
    const response = await copyProtocol(this.props.match.params.id)
    if (response.ok) {
      if (response.data && response.data.id) {
        this.props.history.push("/protocols/" + response.data.id)
        window.location.reload()
      }
      return false
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
      return false
    }
  }

  call_deleteProtocol = async () => {
    const response = await deleteProtocol(this.props.match.params.id)
    if (response.ok) {
      toast.success(this.props.polyglot.t("generic.message.done"))
      this.props.history.push("/protocols/")

      return false
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
      return false
    }
  }
  saveWorkout = async (workout) => {
    let workoutWithoutPersonalizedDrill = JSON.parse(
      JSON.stringify(workout, (k, v) =>
        k === "personalizedDrill" ? undefined : v
      )
    )
    workout = workoutWithoutPersonalizedDrill
    const response = await saveWorkout(this.props.match.params.id, { workout })

    if (response.ok) {
      toast.success(this.props.polyglot.t("generic.message.done"))
      await this.call_getProtocol()
      //  this.setState({ workout })
    } else {
      toast.error(this.props.polyglot.t("generic.message.error"))
    }
  }

  handleRangeChange = (e) => this.setState({ activeTabIndex: e.target.value })
  handleTabChange = (e, { activeTabIndex }) => this.setState({ activeTabIndex })
  toggleDrillPane = () => {
    localStorage.setItem("RWJShowDrillsPane", !this.state.showDrillsPane)
    this.setState({ showDrillsPane: !this.state.showDrillsPane })
  }
  toggleWorkoutPaneExpanded = () => {
    let workoutPaneExpanded = JSON.parse(
      localStorage.getItem("RWJWorkoutPaneExpanded")
    )
    workoutPaneExpanded = !workoutPaneExpanded
    localStorage.setItem(
      "RWJWorkoutPaneExpanded",
      JSON.stringify(workoutPaneExpanded)
    )
    this.setState({ workoutPaneExpanded })
  }
  render() {
    const { polyglot } = this.props
    const { showDrillsPane, workoutPaneExpanded } = this.state

    var _this = this

    let {
      allDrills,
      drills,
      warmupDrills,
      flexibilityDrills,
      lightFlexibilityDrills,
      strengthDrills,
      selfCareDrills,
      protocolData,
    } = this.state

    let drillsList = (category) =>
      filter(
        differenceBy(
          allDrills,
          [
            ...drills,
            ...lightFlexibilityDrills,
            ...warmupDrills,
            ...flexibilityDrills,
            ...strengthDrills,
            ...selfCareDrills,
          ],
          "name"
        ),
        function (o) {
          if (
            category === "warmup" ||
            category === "strength" ||
            category === "flexibility" ||
            category === "selfcare"
          ) {
            return o.category.indexOf(category) > -1
          } else {
            return (
              o.category.indexOf("warmup") === -1 &&
              o.category.indexOf("strength") === -1 &&
              o.category.indexOf("flexibility") === -1 &&
              o.category.indexOf("selfcare") === -1
            )
          }
        }
      ).map(function (drill) {
        return (
          <Table.Row key={drill.id}>
            <Table.Cell>
              {drill.image && (
                <Image src={"/drills/" + drill.image} avatar size="mini" />
              )}
            </Table.Cell>
            <Table.Cell>{drill.name}</Table.Cell>
            <Table.Cell>
              <Button
                icon="check"
                color="green"
                size="mini"
                onClick={() => _this.addToProtocol("drill", drill)}
                style={{ marginLeft: "10px", verticalAlign: "top" }}
              />
            </Table.Cell>

            <Table.Cell>{drill.category.join(", ")}</Table.Cell>
          </Table.Row>
        )
      })

    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ""}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
            .react-datepicker__input-container input {
              border:none;
						}
						.ui.header>.icon.small
						{
							font-size: .75em!important;
							cursor:pointer;

						}
						.drill-label{
							width:200px;
							display:inline-block;
						}

						`}</style>

              <Grid stackable columns={2} divided style={{ height: "100%" }}>
                <Grid.Row
                  style={{
                    paddingLeft: "2rem",
                  }}
                >
                  <Grid.Column
                    width={showDrillsPane ? 12 : 16}
                    className="card-white pad-bottom-30 pad-top-15"
                  >
                    <Button
                      icon={`angle double ${
                        this.state.showDrillsPane ? "right" : "left"
                      }`}
                      floated="right"
                      basic
                      onClick={this.toggleDrillPane}
                    />
                    <div style={{ float: "right", marginRight: 10 }}>
                      <Button.Group basic>
                        <Button
                          icon
                          basic
                          color={protocolData.suggestRunWarmup ? "green" : null}
                          labelPosition="left"
                          onClick={() =>
                            this.call_updateProtocol({
                              suggestRunWarmup: !protocolData.suggestRunWarmup,
                            })
                          }
                        >
                          <Icon
                            color={
                              protocolData.suggestRunWarmup ? "green" : null
                            }
                            name={
                              protocolData.suggestRunWarmup
                                ? "toggle on"
                                : "toggle off"
                            }
                          />
                          {polyglot.t("protocol.suggestRunWarmup")}
                        </Button>

                        <Button
                          icon
                          basic
                          labelPosition="left"
                          onClick={() =>
                            this.call_updateProtocol({
                              isAvailable: !protocolData.isAvailable,
                            })
                          }
                        >
                          <Icon
                            name={
                              protocolData.isAvailable
                                ? "check circle outline"
                                : "circle outline"
                            }
                          />
                          {protocolData.isAvailable
                            ? polyglot.t("protocol.isAvailable")
                            : polyglot.t("protocol.isAvailable.not")}
                        </Button>

                        <Button
                          icon
                          basic
                          labelPosition="left"
                          onClick={this.call_copyProtocol}
                        >
                          <Icon name="copy" />
                          {polyglot.t("protocol.copy")}
                        </Button>
                        <Button
                          basic
                          icon
                          labelPosition="left"
                          onClick={() => this.setState({ open: true })}
                        >
                          <Icon name="trash" color="red" />
                          {polyglot.t("protocol.delete")}
                        </Button>
                      </Button.Group>

                      <Confirm
                        basic
                        open={this.state.open}
                        onCancel={() => this.setState({ open: false })}
                        onConfirm={this.call_deleteProtocol}
                      />
                    </div>
                    <Header as="h2">
                      <RIEInput
                        value={this.state.protocolData.label || ""}
                        change={this.call_updateProtocol}
                        propName="label"
                        className={
                          this.state.protocolData.label ? " " : " riek"
                        }
                        classLoading="loading"
                        classInvalid="invalid"
                      />{" "}
                    </Header>
                    <Grid stackable>
                      <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                        {!workoutPaneExpanded && (
                          <Card fluid>
                            <Card.Content textAlign="left">
                              <Card.Header>
                                {polyglot.t("protocol.type")}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content textAlign="left">
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  {polyglot.t("generic.select.label")} :{""}
                                </span>
                                <span
                                  style={{
                                    display: "inline-block",
                                    minWidth: "50px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <RIESelect
                                    value={{
                                      id: this.state.protocolData.type,
                                      text: types.find(
                                        (x) =>
                                          x.id === this.state.protocolData.type
                                      )?.text,
                                    }}
                                    className={
                                      this.state.protocolData.type
                                        ? " "
                                        : " riek"
                                    }
                                    change={this.call_updateProtocol}
                                    options={types}
                                    classLoading="loading"
                                    propName="type"
                                    style={{
                                      display: "inline-block",
                                      width: "auto",
                                    }}
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.state.protocolData.gear?.join(", ")}
                              </div>
                            </Card.Content>
                          </Card>
                        )}
                        {[
                          {
                            block: "lightFlexibility",
                            items: this.state.lightFlexibilityDrillsOrdered,
                            drillCategory: "lightFlexibilityDrill",
                            activeDrillTabIndex: 0,
                            onChange:
                              this.handleRLDDChangeLightFlexibilityDrills,
                          },
                          {
                            block: "warmup",
                            items: this.state.warmupDrillsOrdered,
                            drillCategory: "warmupDrill",
                            activeDrillTabIndex: 1,
                            onChange: this.handleRLDDChangeWarmupDrills,
                          },
                          {
                            block: "strength",
                            items: this.state.strengthDrillsOrdered,
                            drillCategory: "strengthDrill",
                            activeDrillTabIndex: 3,
                            onChange: this.handleRLDDChangeStrengthDrills,
                          },
                          {
                            block: "technique",
                            items: this.state.drillsOrdered,
                            drillCategory: "drill",
                            activeDrillTabIndex: 2,
                            onChange: this.handleRLDDChangeDrills,
                          },
                          { block: "workout" },
                          {
                            block: "flexibility",
                            items: this.state.flexibilityDrillsOrdered,
                            drillCategory: "flexibilityDrill",
                            activeDrillTabIndex: 0,
                            onChange: this.handleRLDDChangeFlexibilityDrills,
                          },
                          {
                            block: "selfcare",
                            items: this.state.selfCareDrillsOrdered,
                            drillCategory: "selfCareDrill",
                            activeDrillTabIndex: 4,
                            onChange: this.handleRLDDChangeSelfCareDrills,
                          },
                        ].map((e) =>
                          e.block === "workout" ? (
                            <Card fluid key={e.block}>
                              <Card.Content textAlign="left">
                                <Card.Header>
                                  {polyglot.t(`protocol.workout`)}
                                  <span
                                    style={{ float: "right" }}
                                    onClick={this.toggleWorkoutPaneExpanded}
                                  >
                                    <Icon
                                      name={
                                        workoutPaneExpanded
                                          ? "compress"
                                          : "expand"
                                      }
                                    />
                                  </span>
                                </Card.Header>
                              </Card.Content>
                              <Card.Content textAlign="left">
                                Focus :{" "}
                                <RIETextArea
                                  value={this.state.protocolData.comments || ""}
                                  change={this.call_updateProtocol}
                                  propName="comments"
                                  className={
                                    this.state.protocolData.comments
                                      ? " "
                                      : " riek"
                                  }
                                  classLoading="loading"
                                  classInvalid="invalid"
                                  defaultValue=""
                                />{" "}
                              </Card.Content>
                              <Card.Content textAlign="left">
                                <WorkoutEdit
                                  workout={this.state.workout}
                                  allDrills={allDrills}
                                  saveWorkout={this.saveWorkout}
                                />
                              </Card.Content>
                            </Card>
                          ) : (
                            !workoutPaneExpanded && (
                              <Card
                                key={e.block}
                                fluid
                                style={
                                  this.state.currentDrillBloc === e.block
                                    ? { border: "1px solid grey" }
                                    : {}
                                }
                                onClick={() =>
                                  this.setState({
                                    currentDrillBloc: e.block,
                                    activeTabIndex: e.activeDrillTabIndex,
                                  })
                                }
                              >
                                <Card.Content textAlign="left">
                                  <Card.Header as="h3">
                                    {polyglot.t(`protocol.${e.block}`)}
                                  </Card.Header>
                                </Card.Content>
                                {e.items.length !== 0 && (
                                  <>
                                    <Card.Content textAlign="left">
                                      <Feed>
                                        {/*{protocolWarmupDrillsList}*/}
                                        <RLDD
                                          items={e?.items}
                                          itemRenderer={(item) => {
                                            return (
                                              <List.Item>
                                                <List.Content>
                                                  <List.Header>
                                                    <span className="drill-label">
                                                      {item.id + 1} -{" "}
                                                      {item.drill.name}
                                                    </span>
                                                    <Button
                                                      icon="close"
                                                      color="red"
                                                      size="mini"
                                                      onClick={() =>
                                                        _this.removeFromProtocol(
                                                          e.drillCategory,
                                                          item.drill
                                                        )
                                                      }
                                                      style={{
                                                        marginLeft: "10px",
                                                        marginBottom: "5px",
                                                        verticalAlign: "top",
                                                      }}
                                                    />
                                                  </List.Header>
                                                  <List.Description />
                                                </List.Content>
                                              </List.Item>
                                            )
                                          }}
                                          onChange={e.onChange}
                                        />
                                      </Feed>
                                    </Card.Content>
                                    <Card.Content extra>
                                      <Button
                                        className={"app-button	"}
                                        onClick={() => this.updateAllDrills()}
                                      >
                                        Save
                                      </Button>{" "}
                                    </Card.Content>
                                  </>
                                )}{" "}
                              </Card>
                            )
                          )
                        )}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  {showDrillsPane && (
                    <Grid.Column
                      width={4}
                      className="card-white pad-bottom-30 pad-top-15"
                    >
                      <Fragment>
                        <Tab
                          menu={{ secondary: true, pointing: true }}
                          activeIndex={this.state.activeTabIndex}
                          onTabChange={this.handleTabChange}
                          panes={[
                            "preview",
                            "flexibility",
                            "warmup",
                            "drills",
                            "strength",
                            "selfcare",
                          ].map((key) => ({
                            menuItem: polyglot.t(`course.${key}`),
                            render: () =>
                              key === "preview" ? (
                                <Tab.Pane attached={false}>
                                  <Table basic="very" celled collapsing>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell> </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      <Table.Row>
                                        <Table.Cell>
                                          <style>
                                            {`
																	.ui.segment{background-color:black}
																.segments{
																	background: rgba(0, 0, 0, 0.6) !important;
																}
														.segments .segment {
																color: #ababab;
																 border-top: 1px solid #3a3939 !important;
																 border-right: 1px solid #3a3939 !important;
																 border-left: 1px solid #3a3939 !important;
															 }
															 .page	.segments .segment a{
																color: #ababab;
															 }

															 .segments .segment:last-child 					{
																 border-bottom: 1px solid #3a3939 !important;
															 }
																`}
                                          </style>
                                          <Workout
                                            polyglot={polyglot}
                                            workout={this.state.workout}
                                          />{" "}
                                        </Table.Cell>
                                      </Table.Row>
                                    </Table.Body>
                                  </Table>
                                </Tab.Pane>
                              ) : (
                                <Tab.Pane attached={false}>
                                  <Table basic="very" celled collapsing>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell> </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t("course.drill.name")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>{drillsList(key)}</Table.Body>
                                  </Table>
                                </Tab.Pane>
                              ),
                          }))}
                        />
                      </Fragment>
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

Protocol.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}
Protocol = withPolyglot()(Protocol)
export default withRouter(Protocol)
