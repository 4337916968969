import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'semantic-ui-react'
import { getDrill, logDrills } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import SessionElement from '../components/SessionElement'
import { findIndex } from 'lodash'
import SwipeableViews from 'react-swipeable-views'
import ReactGA from 'react-ga'
class Drill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drills: [],
      hasError: 0,
      error: false,
      isLoading: true,
      drill: false,
      activeIndex: 0,
      isSaved: false,
    }
  }

  async getData() {
    let drill
    let drillIndex = 0
    const drillsList = this.props.location.state
      ? this.props.location.state.drills
      : []

    let currentDrillId =
      this.props.location.hash === '#workout'
        ? 'workout'
        : this.props.match.params.id

    let storageIndex = window.localStorage.getItem('activeIndex')

    if (drillsList && drillsList.length !== 0) {
      drillIndex = storageIndex
        ? ~~storageIndex // ~~astuce pour transformer un string en nombre
        : findIndex(drillsList, {
            _id: currentDrillId,
          })
      storageIndex && window.localStorage.removeItem('activeIndex')
      drill = drillsList[drillIndex]
    } else {
      const response = await getDrill(this.props.match.params.id)
      if (response.ok) {
        drill = response.data
      } else {
        this.setState({
          isLoading: false,
          hasError: true,
          error: response,
        })
        return
      }
    }

    this.setState({
      activeIndex: drillIndex,
      drill,
      isLoading: false,
      hasError: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.id !== this.props.match.params.id &&
      this.props.location.hash !== ''
    ) {
      this.getData()
    }
  }
  componentDidMount() {
    this.getData()
  }
  handleChangeIndex = (activeIndex) => {
    this.setState({
      activeIndex,
    })
  }
  nextView = () => {
    var { activeIndex = 0 } = this.state

    this.setState({ activeIndex: activeIndex + 1 })
  }
  prevView = () => {
    var { activeIndex = 0 } = this.state

    this.setState({ activeIndex: activeIndex - 1 })
  }

  logDrills = async () => {
    const drillsList = this.props.location.state
      ? this.props.location.state.drills
      : []
    const protocolId = this.props.location.state
      ? this.props.location.state.protocolId
      : ''
    const doneDrills = []

    const drillsIdByType = this.props.location.state
      ? this.props.location.state.drillsIdByType
      : {}

    drillsList.forEach((x) => {
      //one ne loggue ni la flexibilité ni le selfcare
      if (x.category && x.category.indexOf('flexibility') === -1) {
        //on ne logue pas la flexibility
        doneDrills.push(x._id)
      }
    })
    await logDrills({
      doneDrills: [
        ...drillsIdByType['warmup'],
        ...drillsIdByType['technique'],
        ...drillsIdByType['strength'],
      ],
      flexibilityDrills: [
        ...drillsIdByType['lightFlexibility'],
        ...drillsIdByType['flexibility'],
      ],
      selfCareDrills: [...drillsIdByType['selfCare']],
      protocolId,
    })

    ReactGA.event({ category: 'Protocol', action: 'Log drills and Protocol' })
  }

  render() {
    const { activeIndex } = this.state
    const drillsList = this.props.location.state
      ? this.props.location.state.drills
      : []
    const drillsIdByType = this.props.location.state
      ? this.props.location.state.drillsIdByType
      : {}

    const trainingMode = this.props.location.state
      ? this.props.location.state.trainingMode
      : false

    const learningMode = this.props.location.state
      ? this.props.location.state.learningMode
      : false

    const protocolId = this.props.location.state
      ? this.props.location.state.protocolId
      : ''
    var swipeableElements = []
    var showLeftIcon = (index) => {
      return index !== 0
    }
    var showRightIcon = (index) => {
      return index < drillsList.length - 1
    }
    var showDoneIcon = (index) => {
      return index === drillsList.length - 1
    }

    if (!drillsList || drillsList.length === 0) {
      swipeableElements.push(
        <SessionElement
          element={this.state.drill}
          trainingMode={trainingMode}
          protocolId={protocolId}
          key={this.state.drill.id}
        />
      )
    } else {
      for (let index = 0; index < drillsList.length; index++) {
        const element = drillsList[index]

        swipeableElements.push(
          <SessionElement
            key={element._id}
            element={element}
            drillsIdByType={drillsIdByType}
            drillIndex={index}
            activeIndex={activeIndex}
            drillsTotalCount={drillsList.length}
            trainingMode={trainingMode}
            learningMode={learningMode}
            leftIcon={{ show: showLeftIcon(index), action: this.prevView }}
            rightIcon={{ show: showRightIcon(index), action: this.nextView }}
            doneIcon={{ show: showDoneIcon(index), action: this.logDrills }}
            history={this.props.history}
            location={this.props.location}
            protocolId={protocolId}
          />
        )
      }
    }

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page page-drill-full">
        <Container>
          <style>{`
					.page-drill-full
					{
						margin-left: -2em!important;
						margin-right: -2em!important;
					}
					@media  (min-width: 768px)
					{
						.page-drill-full
						{
							margin-left: 0em!important;
							margin-right: 0!important;
						}
					}


						`}</style>

          <div className="pad-top-5"></div>

          <SwipeableViews
            enableMouseEvents={true}
            index={activeIndex}
            onChangeIndex={this.handleChangeIndex}
            disabled={!drillsList || drillsList.length === 0}
          >
            {swipeableElements}
          </SwipeableViews>
        </Container>
      </div>
    )
  }
}

Drill.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}
Drill = withPolyglot()(Drill)
export default Drill
