import React, { PureComponent } from 'react'
import { withPolyglot } from '../vendor/polyglot-react'
import PropTypes from 'prop-types'
class Footer extends PureComponent {
  render() {
    const { polyglot } = this.props
    return (
      <div className="footer bottom-30">
        <div>{polyglot.t('generic.proud.partner')}</div>
        <a
          href="https://posemethod.com/running/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/pose_method_logo.png" alt="Pose Method Logo" />
        </a>
        <a
          href="https://www.smithptrun.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/smithpt_logo.png" alt="Smith PT Logo" />
        </a>
      </div>
    )
  }
}
Footer.propTypes = {
  polyglot: PropTypes.object,
}

Footer = withPolyglot()(Footer)
export default Footer
