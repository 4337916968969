import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, List, Icon, Segment, Modal } from 'semantic-ui-react'
import {
  getProtocol,
  getMyAnalyses,
  pickProtocolFromPlan,
  getSubscriptionStatus,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import { isEmpty } from 'lodash'

import AppError from '../components/AppError'

import SectionCard from '../components/SectionCard'

import ReactGA from 'react-ga'
import FocusModal from '../components/FocusModal'
const runOptionalGearList = ['wall', 'jump rope', 'band']
const selfCareMandatoryGearList = ['roller']
const selfCareUnavailableGearList = ['lacrosse', 'bar']

class UserProtocol extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      protocol: {},
      gearUnAvailable: [],
      type: '',
      buttonText: '',
    }
  }
  getButtonText = () => {
    const protocolId = this.props.match.params.id

    if (protocolId) {
      return (
        <span>
          <Icon name="check" color="green" />{' '}
          {this.props.polyglot.t('protocol.start')}
        </span>
      )
    } else {
      return this.props.polyglot.t('protocol.generate')
    }
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    this.call_getSubscriptionStatus()

    //a workout url can be passed along
    const workoutUrl = this.props.location.state
      ? this.props.location.state.workoutUrl
      : ''

    if (workoutUrl) {
      sessionStorage.setItem('RWJWorkoutUrl', workoutUrl)
    }

    var type = ''
    if (this.props.location.pathname.indexOf('userSelfCare') !== -1) {
      type = 'selfcare'
    } else {
      var myAnalysis = await this.call_getMyAnalyses()
      type = 'neutral'
      if (myAnalysis && myAnalysis.analysis && myAnalysis.analysis.focus) {
        type = myAnalysis.analysis.focus
      }
    }
    const protocolId = this.props.match.params.id
    if (protocolId) {
      this.call_pickProtocol()
    }

    let gearStorageKey =
      type === 'selfcare' ? 'RWJSelfCareExcludeGear' : 'RWJRunExcludeGear'

    let gearUnAvailable = localStorage.getItem(gearStorageKey)
      ? localStorage.getItem(gearStorageKey).split(',')
      : []

    this.setState({
      isLoading: false,
      type,
      hasError: false,
      buttonText: this.getButtonText(),
      gearUnAvailable,
    })
  }

  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  async call_getMyAnalyses() {
    let response = await getMyAnalyses({ limit: 1, fields: 'focus' })
    if (response.ok) {
      return {
        analysis: response.data[0],
      }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }
  call_pickProtocol = async () => {
    this.setState({
      buttonText: <Icon loading name="circle notch" />,
    })

    const { type } = this.state
    const protocolId = this.props.match.params.id
    let response
    //let optionalGear = this.state.gearUnAvailable

    if (protocolId) {
      //protocol already chosen display it
      response = await getProtocol(protocolId)
    } else {
      response = await pickProtocolFromPlan(type, this.state.gearUnAvailable)
      if (response.ok && !isEmpty(response.data)) {
        this.props.history.replace(
          this.props.location.pathname + '/' + response.data.id
        )
        //   this.props.history.replace('/userProtocol/5fe21c3f2272d45e4ff01271')
      } else if (response.data?.code === 'subscription-required') {
        this.props.history.push('/services')
        return
      }

      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }

    if (response.ok && !isEmpty(response.data)) {
      let data = response.data

      let {
        warmupDrills,
        strengthDrills,
        drills,
        flexibilityDrills,
        lightFlexibilityDrills,
        selfCareDrills,
      } = data
      let allDrills = [
        ...lightFlexibilityDrills,
        ...warmupDrills,
        ...strengthDrills,
        ...drills,
        ...flexibilityDrills,
        ...selfCareDrills,
      ]

      this.setState({
        protocol: data,
        isLoading: false,
        hasError: false,
        drills: allDrills,
        drillsIdByType: {
          lightFlexibility: lightFlexibilityDrills.map((x) => x._id),
          warmup: warmupDrills.map((x) => x._id),
          strength: strengthDrills.map((x) => x._id),
          technique: drills.map((x) => x._id),
          flexibility: flexibilityDrills.map((x) => x._id),
          selfCare: selfCareDrills.map((x) => x._id),
        },
        buttonText: this.getButtonText(),
      })
      return false
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  startProtocol() {
    ReactGA.event({ category: 'Protocol', action: 'Start Protocol' })

    this.props.history.push({
      pathname: '/drills/' + this.state.drills[0]._id,
      state: {
        protocolId: this.props.match.params.id,
        drills: this.state.drills,
        drillsIdByType: this.state.drillsIdByType,
        trainingMode: true,
      },
    })
  }
  toggleGear(gear) {
    let gears = this.state.gearUnAvailable
    if (this.state.gearUnAvailable.indexOf(gear) !== -1) {
      //on enleve
      gears = gears.filter((x) => x !== gear)
    } else {
      //on ajoute
      let gears = this.state.gearUnAvailable
      gears.push(gear)
    }
    gears = [...new Set(gears)]

    let gearStorageKey =
      this.state.type === 'selfcare'
        ? 'RWJSelfCareExcludeGear'
        : 'RWJRunExcludeGear'

    localStorage.setItem(gearStorageKey, gears.join(','))
    this.setState({
      gearUnAvailable: gears,
    })
  }

  render() {
    const { polyglot } = this.props
    const { type } = this.state
    const protocolId = this.props.match.params.id
    return (
      <div className="page">
        <SectionCard
          asDrawer
          cardData={{
            key:
              this.props.location.pathname.indexOf('userSelfCare') !== -1
                ? 'selfcare'
                : 'technique',
          }}
          history={this.props.history}
          asHeader={true}
        />
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && (
          <Container className="pad-bottom-40">
            <style>{`
				.next-class .item .content .header{
					border-bottom : 1px solid #636363;
				}
				.next-class .item .content .description{
					padding-left:5px;
				}
				.segments{
					background: rgba(0, 0, 0, 0.6) !important;
				}
				.page	.segments .segment {
					color: #ababab;
					 border-top: 1px solid #3a3939 !important;
					 border-right: 1px solid #3a3939 !important;
					 border-left: 1px solid #3a3939 !important;
				}
				.page	.segments .segment a{
					color: #ababab;
				}

				.page	.ui.avatar.image {
					width: 3.5em;
					height: 3.5em;
					margin-top: 0.2rem;
					margin-bottom: 0.2rem;
				}

				.segments .segment:last-child 					{
					 border-bottom: 1px solid #3a3939 !important;
				 }
				.ui.divided.list>.item {
					border-top: 1px solid rgba(128, 128, 128, 0.15);
				}
			`}</style>
            {type === 'selfcare' ? (
              <>
                <Segment
                  className="dark-grey services-card"
                  style={{ fontSize: '1.1em' }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    {polyglot.t('protocol.gear.needed')}
                  </span>
                  <List
                    divided
                    inverted
                    style={{ textAlign: 'left' }}
                    className="bottom-15"
                  >
                    {selfCareMandatoryGearList.map((x) => (
                      <List.Item key={x} disabled={!!protocolId}>
                        <List.Content
                          style={{ fontSize: '1.2em', color: 'white' }}
                        >
                          {polyglot.t('protocol.gear.' + x)}
                          {polyglot.has('protocol.gear.' + x + '.details') && (
                            <div style={{ fontSize: '0.8em', color: 'grey' }}>
                              {polyglot.t('protocol.gear.' + x + '.details')}
                            </div>
                          )}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>

                  <span style={{ fontWeight: 'bold' }}>
                    {polyglot.t('protocol.gear.additional')}

                    <Modal
                      trigger={
                        <Icon
                          name="question circle outline"
                          color="grey"
                          size="small"
                          style={{ position: 'relative', right: -10, top: -10 }}
                        />
                      }
                      basic
                      centered={false}
                      closeIcon
                    >
                      <Modal.Header>
                        {polyglot.t('protocol.gear.additional')}
                      </Modal.Header>
                      <Modal.Content>
                        {type && (
                          <Modal.Description>
                            <p>{polyglot.t('help.gear.available.content')}</p>
                          </Modal.Description>
                        )}
                      </Modal.Content>
                    </Modal>
                  </span>

                  <List
                    divided
                    inverted
                    style={{ textAlign: 'left' }}
                    className="bottom-5"
                  >
                    {selfCareUnavailableGearList.map((x) => (
                      <List.Item key={x} disabled={!!protocolId}>
                        <List.Content
                          floated="right"
                          onClick={() => this.toggleGear(x)}
                        >
                          <Icon
                            inverted
                            disabled={!!protocolId}
                            name={'toggle on'}
                            flipped={
                              this.state.gearUnAvailable.indexOf(x) === -1
                                ? null
                                : 'horizontally'
                            }
                            color={
                              this.state.gearUnAvailable.indexOf(x) === -1
                                ? 'green'
                                : 'grey'
                            }
                            size="large"
                          />
                        </List.Content>
                        <List.Content
                          style={{
                            fontSize: '1.2em',
                            color: !protocolId ? 'white' : 'none',
                          }}
                        >
                          {polyglot.t('protocol.gear.' + x)}
                          {polyglot.has('protocol.gear.' + x + '.details') && (
                            <div style={{ fontSize: '0.8em', color: 'grey' }}>
                              {polyglot.t('protocol.gear.' + x + '.details')}
                            </div>
                          )}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>{' '}
              </>
            ) : (
              <>
                <Container className="top-30">
                  {type && <FocusModal focus={type} />}
                </Container>
                <Segment
                  className="dark-grey services-card"
                  style={{ fontSize: '1.1em' }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      position: 'relative',
                      display: 'inline-block',
                    }}
                  >
                    {polyglot.t('protocol.gear.available')}

                    <Modal
                      trigger={
                        <Icon
                          name="question circle outline"
                          color="grey"
                          size="small"
                          style={{ position: 'absolute', right: -20, top: -10 }}
                        />
                      }
                      basic
                      centered={false}
                      closeIcon
                    >
                      <Modal.Header>
                        {polyglot.t('protocol.gear.available')}
                      </Modal.Header>
                      <Modal.Content>
                        {type && (
                          <Modal.Description>
                            <p>{polyglot.t('help.gear.available.content')}</p>
                          </Modal.Description>
                        )}
                      </Modal.Content>
                    </Modal>
                  </span>

                  <List
                    divided
                    inverted
                    style={{ textAlign: 'left' }}
                    className="bottom-5"
                  >
                    {runOptionalGearList.map((x) => (
                      <List.Item key={x} disabled={!!protocolId}>
                        <List.Content
                          floated="right"
                          onClick={() =>
                            !protocolId ? this.toggleGear(x) : false
                          }
                        >
                          <Icon
                            inverted
                            disabled={!!protocolId}
                            name={'toggle on'}
                            flipped={
                              this.state.gearUnAvailable.indexOf(x) === -1
                                ? null
                                : 'horizontally'
                            }
                            color={
                              this.state.gearUnAvailable.indexOf(x) === -1
                                ? 'green'
                                : 'grey'
                            }
                            size="large"
                          />
                        </List.Content>
                        <List.Content
                          style={{
                            fontSize: '1.2em',
                            color: !protocolId ? 'white' : 'none',
                          }}
                        >
                          {polyglot.t('protocol.gear.' + x)}
                          {polyglot.has('protocol.gear.' + x + '.details') && (
                            <div style={{ fontSize: '0.8em', color: 'grey' }}>
                              {polyglot.t('protocol.gear.' + x + '.details')}
                            </div>
                          )}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>{' '}
              </>
            )}
            <div className="actions-placeholder">
              <div
                className="call-to-action bottom-5"
                style={{
                  height: 'auto',
                  width: 'auto',
                  maxWidth: '312px',
                }}
              >
                {/**

								<div>{this.state.protocol.label}</div>
								   */}
                <span
                  className="action top-10 long no-icon standalone "
                  style={{ float: 'none', display: 'block' }}
                  onClick={() =>
                    protocolId ? this.startProtocol() : this.call_pickProtocol()
                  }
                  data-testid={protocolId ? 'start-protocol' : 'create-session'}
                >
                  <span>{this.state.buttonText}</span>{' '}
                </span>
              </div>
            </div>
          </Container>
        )}
      </div>
    )
  }
}
UserProtocol.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
}
UserProtocol = withPolyglot()(UserProtocol)
export default UserProtocol
