import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Modal,
  Header,
  List,
  Segment,
  Button,
  Container,
} from 'semantic-ui-react'
import {
  getMe,
  logout,
  setMyLocale,
  deleteAccount,
  getPortalUrl,
  getSubscriptionStatus,
} from '../services/Api'
import { withPolyglot } from '../vendor/polyglot-react'
import moment from 'moment'
import 'moment/locale/fr'
import { Config } from '../config'
import ReactGA from 'react-ga'
import AppError from '../components/AppError'
import { toast } from 'react-toastify'
import { version } from '../version'

const { localeOptions, translations } = Config
var locale = localStorage.getItem('RWJlocale')
moment.locale(locale)

class Account extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      user: {},

      language: window.localStorage.getItem('RWJlocale'),
      hasError: false,
      error: false,
      isLoading: true,
      modalDeleteOpen: false,
      modalLanguageOpen: false,
      modalDistanceUnitOpen: false,
      forceRefresh: '',
      visible: false,
      locale: window.localStorage.getItem('RWJlocale'),
      subscriptionStatus: null,
    }
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    this.getCurrentUser()

    const [user, subscriptionStatus] = await Promise.all([
      this.getCurrentUser(),
      this.call_getSubscriptionStatus(),
    ])

    this.setState({
      user,
      subscriptionStatus,
      locale: user.locale,
      isLoading: false,
      hasError: false,
      //redirectToReferrer: true,
      visible: true,
      isEdited: false,
    })
  }

  async getCurrentUser() {
    const response = await getMe()
    if (response.ok) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }
  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  call_deleteAccount = async () => {
    const response = await deleteAccount()
    if (response.ok) {
      this.setState({
        isLoading: true,
      })
      logout()
      window.location = '/'
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }
  redirectToPortal = async () => {
    let response = await getPortalUrl()

    if (response.ok) {
      window.location = response.data.url
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  openUrl = (url) => {
    console.log('setting state')
    this.setState({
      isLoading: true,
    })
    window.location = url
  }

  setLocale = async (value) => {
    var user = this.state.user
    user['locale'] = value
    moment.locale(locale)
    this.handleCloseLanguage()
    const response = await setMyLocale(value)
    if (response.ok) {
      localStorage.getItem && (window.storage = localStorage)
      if (window.storage) {
        window.localStorage.setItem('RWJlocale', value)
      }
      toast.success(this.props.polyglot.t('generic.message.done'))
      this.setState({ user, locale: value })
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  getDistanceUnit = () => {
    const { polyglot } = this.props
    let distanceUnit = localStorage.getItem('RWJMileOrKm')

    if (!distanceUnit) {
      let localStorageLocale = localStorage.getItem('RWJlocale')
      distanceUnit = localStorageLocale === 'en-us' ? 'mi' : 'km'
    }
    return distanceUnit === 'mi'
      ? `(${polyglot.t('profile.distance.mile')})`
      : `(${polyglot.t('profile.distance.kilometer')})`
  }

  handleOpenLanguage = () => this.setState({ modalLanguageOpen: true })
  handleCloseLanguage = () => this.setState({ modalLanguageOpen: false })
  handleOpenDistanceUnit = () => this.setState({ modalDistanceUnitOpen: true })
  handleCloseDistanceUnit = () =>
    this.setState({ modalDistanceUnitOpen: false })
  handleOpenDelete = () => this.setState({ modalDeleteOpen: true })
  handleCloseDelete = () => this.setState({ modalDeleteOpen: false })

  render() {
    const { polyglot } = this.props
    const { user, subscriptionStatus, locale } = this.state
    const lastAnalysis = subscriptionStatus?.lastAnalysis

    let lastAnalysisDate = null
    let nextAnalysisDate = null

    if (lastAnalysis?.published) {
      nextAnalysisDate = moment(lastAnalysis?.published).add(1, 'month')

      nextAnalysisDate = moment(nextAnalysisDate).isSameOrBefore(
        moment(),
        'day'
      )
        ? polyglot.t('account.next.analysis.date.available')
        : moment(nextAnalysisDate).format('ll')

      lastAnalysisDate = moment(lastAnalysis?.published).format('ll')
    }

    polyglot.locale(locale)
    polyglot.replace(translations[locale])

    return (
      <div className="page account bottom-20">
        <Header
          as="h2"
          className="color-white center-text uppercase  top-00"
        ></Header>
        {this.state.isLoading && (
          <div className="ui active centered inline loader" />
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && !this.state.isLoading && (
          <Container className="main-container">
            <style>{`
		.ui.list>.item>.icon+.content, .ui.list>.item>.image+.content {
			display: table-cell;
			width: 100%;
			padding: 0 0 0 .5em;
			vertical-align: middle;
		}
			.ui.modal .scrolling.content {
				max-height: calc(90vh);
				overflow: auto;
		}
		.main-container
		{
		//	padding-top:40px;
		}
		@media only screen and (max-width: 350px) {
			/* iPhone 5 only */
			.account.page {
				padding-top:0px;
			}
		}

	`}</style>
            <Header sub textAlign="left">
              {polyglot.t('account.general.settings')}
            </Header>
            <Segment
              className="dark-grey services-card "
              style={{ fontSize: '1.2em', padding: 0, marginTop: 0 }}
            >
              <List
                divided
                inverted
                style={{ textAlign: 'left' }}
                className="bottom-5"
              >
                {[
                  {
                    icon: 'language',
                    text: (
                      <Fragment>
                        {polyglot.t('profile.language')}
                        <span style={{ marginLeft: '5px', fontSize: '0.8em' }}>
                          {localStorage.getItem('RWJlocale')
                            ? '(' +
                              localStorage
                                .getItem('RWJlocale')
                                .split('-')[0]
                                .toUpperCase() +
                              ')'
                            : ''}
                        </span>
                      </Fragment>
                    ),
                    onClick: this.handleOpenLanguage,
                    testid: 'language-link',
                  },
                  {
                    icon: 'sliders horizontal',
                    text: (
                      <Fragment>
                        {polyglot.t('profile.distance.unit')}
                        <span style={{ marginLeft: '5px', fontSize: '0.8em' }}>
                          {this.getDistanceUnit()}
                        </span>
                      </Fragment>
                    ),
                    onClick: this.handleOpenDistanceUnit,
                    testid: 'distanceunit-link',
                  },
                  {
                    icon: 'question circle outline',
                    text: polyglot.t('sidebar.faq'),
                    onClick: () => {
                      ReactGA.event({ category: 'User', action: 'FAQ' })
                      this.setState({ isLoading: true })
                      window.location = polyglot.t('sidebar.faq.url')
                    },
                    testid: 'faq-link',
                  },

                  {
                    icon: 'life ring',
                    text: polyglot.t('sidebar.help'),
                    onClick: () => {
                      ReactGA.event({ category: 'User', action: 'Help' })
                      this.setState({ isLoading: true })
                      window.location = 'mailto:help@molokoy.io'
                    },
                    testid: 'help-link',
                  },
                  {
                    icon: 'refresh',
                    text: polyglot.t('sidebar.update.check'),
                    onClick: () => {
                      ReactGA.event({ category: 'User', action: 'refresh' })
                      this.setState({ isLoading: true })
                      window.location.reload()
                    },
                    testid: 'reload-link',
                  },
                ].map((x) => (
                  <List.Item
                    key={x.icon}
                    onClick={x.onClick}
                    data-testid={x.testid}
                  >
                    <List.Content floated="right">
                      <List.Icon
                        name="chevron right"
                        verticalAlign="middle"
                        size="small"
                      />
                    </List.Content>
                    <List.Icon
                      flipped={x.flipped || null}
                      name={x.icon}
                      size="small"
                      verticalAlign="middle"
                    />

                    <List.Content>
                      <List.Description as="a">{x.text}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>

            <Header sub textAlign="left">
              {polyglot.t('account.tools')}
            </Header>
            <Segment
              className="dark-grey services-card "
              style={{ fontSize: '1.2em', padding: 0, marginTop: 0 }}
            >
              <List
                divided
                inverted
                style={{ textAlign: 'left' }}
                className="bottom-5"
              >
                {[
                  {
                    icon: 'calculator',
                    text: polyglot.t('home.action.paceSpeed'),
                    onClick: () => this.props.history.push('/paceConverter'),
                    testid: 'pace-converter-link',
                  },
                  {
                    icon: 'heartbeat',
                    text: polyglot.t('ruffier.title'),
                    onClick: () => this.props.history.push('/ruffier'),
                    testid: 'ruffier-link',
                  },
                  {
                    icon: 'long arrow alternate up',
                    text: polyglot.t('metronome.title'),
                    onClick: () => this.props.history.push('/metronome'),
                    testid: 'metronome-link',
                  },
                ].map((x) => (
                  <List.Item
                    key={x.icon}
                    onClick={x.onClick}
                    data-testid={x.testid}
                  >
                    <List.Content floated="right">
                      <List.Icon
                        name="chevron right"
                        verticalAlign="middle"
                        size="small"
                      />
                    </List.Content>
                    <List.Icon
                      flipped={x.flipped || null}
                      name={x.icon}
                      size="small"
                      verticalAlign="middle"
                    />

                    <List.Content>
                      <List.Description as="a">{x.text}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>

            <Header sub textAlign="left">
              {polyglot.t('account.services')}
            </Header>
            <Segment
              className="dark-grey services-card"
              style={{ fontSize: '1.2em', padding: 0 }}
            >
              <List
                divided
                inverted
                style={{ textAlign: 'left' }}
                className="bottom-5"
              >
                {[
                  {
                    icon: 'star',
                    text:
                      subscriptionStatus?.validSubscriptionEnd &&
                      moment().isBefore(
                        subscriptionStatus?.validSubscriptionEnd
                      )
                        ? polyglot.t('account.premium', {
                            endDate: moment(
                              subscriptionStatus?.validSubscriptionEnd
                            ).format('ll'),
                          })
                        : polyglot.t('account.no.valid.premium'),

                    // onClick: () => this.redirectToPortal(),
                    testid: subscriptionStatus?.validSubscriptionEnd
                      ? 'valid-subscription'
                      : 'no-valid-subscription',

                    onClick: () => this.props.history.push('/services'),
                  },
                  {
                    icon: 'chart bar',
                    text: subscriptionStatus?.analysesAvailable
                      ? polyglot.t('account.analysis.available', {
                          analysesAvailable:
                            subscriptionStatus.analysesAvailable,
                        })
                      : polyglot.t('account.no.analysis.available'),
                    testid: subscriptionStatus?.analysesAvailable
                      ? 'analysis-available'
                      : 'no-analysis-available',

                    onClick: () => this.props.history.push('/services'),
                  },
                  {
                    hideEntry: !lastAnalysisDate,
                    icon: 'calendar check outline',
                    text: `${polyglot.t(
                      'account.last.analysis.date'
                    )} ${lastAnalysisDate}`,
                    testid: 'last-analysis-date',
                  },
                  {
                    hideEntry: !nextAnalysisDate,
                    icon: 'calendar plus outline',
                    text: `${polyglot.t(
                      'account.next.analysis.date'
                    )} ${nextAnalysisDate}`,
                    testid: 'next-analysis-date',
                  },

                  {
                    hideEntry: !subscriptionStatus?.hasStripeCustomerId,
                    icon: 'credit card',
                    text: polyglot.t('account.manage.stripe'),
                    onClick: () => {
                      this.redirectToPortal()
                      this.setState({ isLoading: true })
                    },
                    testid: 'stripe-portal-link',
                  },
                ].map(
                  (x) =>
                    !x.hideEntry && (
                      <List.Item
                        key={x.icon}
                        onClick={x.onClick}
                        data-testid={x.testid}
                      >
                        {x.onClick && (
                          <List.Content floated="right">
                            <List.Icon
                              name="chevron right"
                              verticalAlign="middle"
                              size="small"
                            />
                          </List.Content>
                        )}
                        <List.Icon
                          flipped={x.flipped || null}
                          name={x.icon}
                          size="small"
                          verticalAlign="middle"
                        />

                        <List.Content>
                          <List.Description as={x.onClick ? 'a' : ''}>
                            {x.text}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    )
                )}
              </List>
            </Segment>

            <Header sub textAlign="left">
              {polyglot.t('account.find.us')}
            </Header>
            <Segment
              className="dark-grey services-card"
              style={{
                fontSize: '1.2em',
                padding: '0 0 0 20px',
                textAlign: 'left',
              }}
            >
              <List inverted horizontal style={{ textAlign: 'left' }}>
                {[
                  {
                    icon: 'globe',

                    // onClick: () => this.redirectToPortal(),
                    testid: 'web-link',

                    onClick: () => this.openUrl('https://molokoy.io'),
                  },
                  {
                    icon: 'instagram',

                    // onClick: () => this.redirectToPortal(),
                    testid: 'instagram-link',

                    onClick: () =>
                      this.openUrl('https://instagram.com/molokoy.io'),
                  },
                  {
                    icon: 'facebook',

                    // onClick: () => this.redirectToPortal(),
                    testid: 'facebook-link',

                    onClick: () =>
                      this.openUrl('https://facebook.com/molokoy.io'),
                  },
                ].map(
                  (x) =>
                    !x.hideEntry && (
                      <List.Item
                        key={x.icon}
                        onClick={x.onClick}
                        data-testid={x.testid}
                        as={x.onClick ? 'a' : ''}
                      >
                        <List.Icon
                          flipped={x.flipped || null}
                          name={x.icon}
                          size="large"
                        />

                        {x.text && (
                          <List.Content>
                            <List.Description>{x.text}</List.Description>
                          </List.Content>
                        )}
                      </List.Item>
                    )
                )}
              </List>
            </Segment>

            <Header sub textAlign="left">
              {user.name}
            </Header>
            <Segment
              className="dark-grey services-card"
              style={{ fontSize: '1.2em', padding: 0 }}
            >
              <List
                divided
                inverted
                style={{ textAlign: 'left' }}
                className="bottom-5"
              >
                {[
                  {
                    icon: 'address card outline',
                    text: polyglot.t('account.personal.infos'),
                    onClick: () => this.props.history.push('/profile'),
                    testid: 'personal-information-link',
                  },
                  {
                    icon: 'lock',
                    text: polyglot.t('account.password.change'),
                    onClick: () => this.props.history.push('/passwordChange'),
                    testid: 'change-password-link',
                  },

                  {
                    icon: 'window close outline',
                    text: polyglot.t('account.delete'),
                    onClick: this.handleOpenDelete,
                    testid: 'delete-link',
                  },
                  {
                    icon: 'sign out',

                    text: polyglot.t('account.sign.out'),
                    onClick: () => {
                      logout()
                      this.props.history.push('/')
                    },
                    testid: 'logout-link',
                  },
                ].map((x) => (
                  <List.Item
                    key={x.icon}
                    onClick={x.onClick}
                    data-testid={x.testid}
                  >
                    <List.Content floated="right">
                      <List.Icon
                        name="chevron right"
                        verticalAlign="middle"
                        size="small"
                      />
                    </List.Content>
                    <List.Icon
                      flipped={x.flipped || null}
                      name={x.icon}
                      size="small"
                      verticalAlign="middle"
                    />

                    <List.Content>
                      <List.Description as="a">{x.text}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
            <div
              style={{
                color: 'grey',
                padding: '10px',
                height: '48px',
                width: '100%',

                fontSize: '0.7rem',
              }}
            >
              {version}
            </div>
            <Modal
              open={this.state.modalLanguageOpen}
              onClose={this.handleCloseLanguage}
              basic
              centered={false}
            >
              <Modal.Header className="discrete">
                <Icon
                  name="arrow left"
                  style={{
                    color: 'grey',
                    position: 'absolute',

                    top: '-10px',
                  }}
                  data-testid="close-arrow"
                  onClick={this.handleCloseLanguage}
                />
              </Modal.Header>
              <Modal.Content scrolling style={{ backgroundColor: '#000' }}>
                <List inverted verticalAlign="middle">
                  {localeOptions
                    .filter(
                      (x) => x.value !== localStorage.getItem('RWJlocale')
                    )
                    .map((lang) => (
                      <List.Item
                        key={lang.value}
                        style={{ borderColor: 'grey' }}
                        onClick={() => this.setLocale(lang.value)}
                        data-testid="switch-to-language"
                      >
                        <List.Content>
                          <List.Header
                            style={{
                              fontWeight: 'normal',
                              textTransform: 'initial',
                              padding: '10px 5px',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '5px',
                                fontSize: '1.1rem',
                              }}
                            >
                              {' '}
                              {lang.text}
                            </span>
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    ))}
                </List>
              </Modal.Content>
            </Modal>

            <Modal
              open={this.state.modalDistanceUnitOpen}
              onClose={this.handleCloseDistanceUnit}
              basic
              centered={false}
            >
              <Modal.Header className="discrete">
                <Icon
                  name="arrow left"
                  style={{
                    color: 'grey',
                    position: 'absolute',

                    top: '-10px',
                  }}
                  data-testid="close-arrow"
                  onClick={this.handleCloseDistanceUnit}
                />
              </Modal.Header>
              <Modal.Content scrolling style={{ backgroundColor: '#000' }}>
                <List inverted verticalAlign="middle">
                  {['mi', 'km']
                    .filter((x) => x !== localStorage.getItem('RWJMileOrKm'))
                    .map((unit) => (
                      <List.Item
                        key={unit}
                        style={{ borderColor: 'grey' }}
                        onClick={() => {
                          localStorage.setItem('RWJMileOrKm', unit)
                          this.handleCloseDistanceUnit()
                        }}
                        data-testid="switch-to-distance"
                      >
                        <List.Content>
                          <List.Header
                            style={{
                              fontWeight: 'normal',
                              textTransform: 'initial',
                              padding: '10px 5px',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '5px',
                                fontSize: '1.1rem',
                              }}
                            >
                              {unit === 'mi'
                                ? `${polyglot.t('profile.distance.mile')}`
                                : `${polyglot.t('profile.distance.kilometer')}`}
                            </span>
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    ))}
                </List>
              </Modal.Content>
            </Modal>

            <Modal
              open={this.state.modalDeleteOpen}
              onClose={this.handleCloseDelete}
              size="mini"
              basic
              //centered={false}
            >
              <Modal.Header className="discrete">
                <Icon
                  name="close"
                  style={{
                    color: 'grey',
                    position: 'absolute',
                    top: '-10px',
                    right: '0px',
                  }}
                  onClick={this.handleCloseDelete}
                />
              </Modal.Header>
              <Modal.Content style={{ backgroundColor: '#000' }}>
                {polyglot.t('account.delete.warning')}
              </Modal.Content>
              <Modal.Actions
                style={{ textAlign: 'center', backgroundColor: '#000' }}
              >
                <Button
                  content={polyglot.t('account.delete.cancel')}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onClick={this.handleCloseDelete}
                  className="app-button"
                />
                <Button
                  content={polyglot.t('account.delete.confirm')}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onClick={this.call_deleteAccount}
                  negative
                  size="mini"
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )}
      </div>
    )
  }
}

Account.propTypes = {
  history: PropTypes.object,
  polyglot: PropTypes.object,
}
Account = withPolyglot()(Account)
export default Account
