import moment from 'moment'
import { getS3Credentials, uploadScreenshot } from '../../services/Api'
import { drawingsMandatoryPoints } from './analysis.canvas.utils'

export const logFieldsR = [
  'initialContactTime',
  'inPoseTime',
  'fallEndTime',
  'lastContactTime',
  'middleOfPullTime',
  'inNextPoseTime',
]

export const logFieldsL = [
  'initialContactTimeL',
  'inPoseTimeL',
  'fallEndTimeL',
  'lastContactTimeL',
  'middleOfPullTimeL',
  'inNextPoseTimeL',
]
export const focus = ['neutral', 'pose', 'fall', 'pull']
export const logFieldsCadence = ['cadenceMark0', 'cadenceMark3', 'cadenceMark4']

export const logFieldsRL = [...logFieldsR, ...logFieldsL]
export const logFields = [...logFieldsR, ...logFieldsL, ...logFieldsCadence]

export const msToFrames = (msStart, msEnd) => {
  let msDelta = msEnd - msStart
  let count = msDelta / 1000 / (1 / 60)
  return Math.round(count)
}

export const timeToMs = (currentTime) => {
  let t =
    currentTime.indexOf(':') !== -1
      ? moment(currentTime, 'm:s.S')
      : moment(currentTime, 's.S')
  let tms = t.milliseconds() + t.seconds() * 1000 + t.minutes() * 60 * 1000
  return tms
}

export const mstoTime = (time) =>
  moment('1900-01-01 00:00:00').add(time, 'milliseconds').format('s.SSSS')

export const countFrames = (analysisData) => {
  const {
    initialContactTime,
    inPoseTime,
    fallEndTime,
    lastContactTime,
    initialContactTimeL,
    inPoseTimeL,
    fallEndTimeL,
    lastContactTimeL,
  } = analysisData

  let framesToPoseL = msToFrames(initialContactTimeL, inPoseTimeL)
  let framesFromPoseL = msToFrames(inPoseTimeL, lastContactTimeL)
  let framesFallingL = msToFrames(inPoseTimeL, fallEndTimeL)

  let framesToPoseR = msToFrames(initialContactTime, inPoseTime)
  let framesFromPoseR = msToFrames(inPoseTime, lastContactTime)
  let framesFallingR = msToFrames(inPoseTime, fallEndTime)

  let framesToPose = (framesToPoseL + framesToPoseR) / 2
  let framesFromPose = (framesFromPoseL + framesFromPoseR) / 2
  let framesFalling = (framesFallingL + framesFallingR) / 2

  let framesOnSupportAfterFallingL = msToFrames(fallEndTimeL, lastContactTimeL)
  let framesOnSupportAfterFallingR = msToFrames(fallEndTime, lastContactTime)
  let framesOnSupportAfterFalling =
    (framesOnSupportAfterFallingL + framesOnSupportAfterFallingR) / 2

  return {
    framesToPoseL,
    framesFromPoseL,
    framesFallingL,
    framesOnSupportAfterFallingL,

    framesToPoseR,
    framesFromPoseR,
    framesFallingR,
    framesOnSupportAfterFallingR,

    framesToPose,
    framesFromPose,
    framesFalling,
    framesOnSupportAfterFalling,
  }
}

export const computePoseScore = (data) => {
  return (poseScoreSide(data, 'R') + poseScoreSide(data, 'L')) / 2
}

const poseScoreSide = (data, side) => {
  let kneeCoefficient = 0.5
  let landingCoefficient = 1.5
  const maxScore = 3 * landingCoefficient + 3 * kneeCoefficient + 3 /* +6*/
  const minScore =
    1 * landingCoefficient +
    1 * kneeCoefficient +
    1 /*+ 0  frames peuvent avoir score de 0 */

  const score1 = data['poseExecutionLanding' + side]
    ? parseInt(data['poseExecutionLanding' + side]) * landingCoefficient
    : 1
  const score2 = data['poseExecutionKnee' + side]
    ? parseInt(data['poseExecutionKnee' + side]) * kneeCoefficient
    : 1
  let score3 = data['poseExecutionVertical' + side]
    ? parseInt(data['poseExecutionVertical' + side])
    : 1

  let score =
    ((score1 + score2 + score3 + -minScore) * 100) / (maxScore - minScore)

  score = score === 0 ? 5 : Math.round(score)
  return score
}

export const framesToPoseDetailedScoreSide = (data, side) => {
  let score = 0
  let count = parseInt(data['framesToPose' + side])

  if (data.fps === 30) {
    count = count * 2
  }
  if (data.fps === 120) {
    count = count / 2
  }
  //for 60fps standard
  if (count <= 2) {
    score = 6
  }
  if (count === 3) {
    score = 5
  }
  if (count === 4) {
    score = 4
  }
  if (count === 5) {
    score = 3
  }
  if (count === 6) {
    score = 2
  }
  if (count === 7) {
    score = 1
  }
  if (count > 7) {
    score = 0
  }
  return score
}

export const computeFallScore = (data) => {
  return (fallScoreSide(data, 'R') + fallScoreSide(data, 'L')) / 2
}

const fallScoreSide = (data, side) => {
  const maxScore = 3 + /* 3 +*/ 3 + 10
  const minScore = 0 + /* 1 +*/ 1 + 0 /*l'alignement peut avoir un score à 0 */
  const framesFallingScore = framesFallingScoreSide(data, side)
  const holdingPoseScore = holdingPoseDetailedScoreSide(data, side)

  const score1 = data['fallExecutionAlignment' + side]
    ? parseInt(data['fallExecutionAlignment' + side])
    : 0
  const score2 = framesFallingScore ? parseInt(framesFallingScore) : 1
  const score3 = holdingPoseScore ? parseInt(holdingPoseScore) : 1

  let score =
    ((score1 +
      /*  (data['fallExecutionTime' + side]
        ? data['fallExecutionTime' + side]
        : 1) +*/
      score2 +
      score3 -
      minScore) *
      100) /
    (maxScore - minScore)
  //score = (score + holdingPoseScore) / 2 //(moyenne  des 2scores sur 100

  score = score === 0 ? 5 : Math.round(score) //juste pour ne pas avoir un score null

  return score
}

export const holdingPosePercentScoreSide = (data, side) => {
  let framesFromPose = parseInt(data['framesFromPose' + side])
  let framesFalling = parseInt(data['framesFalling' + side])

  return parseFloat(((framesFalling * 100) / framesFromPose).toFixed(2))
}

export const holdingPoseDetailedScoreSide = (data, side) => {
  if (!data['framesFromPose' + side || 'framesFalling' + side]) return 1
  let ratio = holdingPosePercentScoreSide(data, side)

  return ratio / 10
}

export const holdingPoseScoreSide = (data, side) => {
  if (!data['framesFromPose' + side || 'framesFalling' + side]) return 1
  let ratio = holdingPosePercentScoreSide(data, side)
  if (ratio <= 50) {
    return 1
  }
  if (ratio < 90) {
    return 2
  }
  return 3
}

const framesFallingScoreSide = (data, side) => {
  let score = 0
  if (!data['framesFalling' + side]) return score
  let count = parseInt(data['framesFalling' + side])
  if (count < 2) {
    // ne se laisse pas chuter
    return 1
  }
  if (count > 4) {
    // se laisse chuter avec intention, mais freine par ailleurs (charles)
    return 2
  }
  return 3
}

export const computePullScore = (data) => {
  return (pullScoreSide(data, 'R') + pullScoreSide(data, 'L')) / 2
}

const pullScoreSide = (data, side) => {
  const maxScore = 3 + 3 + 5
  const minScore = 1 + 0 + 0 /* frames peuvent avoir score de 0 */
  const pullExecutionTiming = framesPullTimingScoreSide(data, side)

  const score1 = data['pullExecutionLegBent' + side]
    ? parseInt(data['pullExecutionLegBent' + side])
    : 1
  const score2 = data['pullExecutionDirection' + side]
    ? parseInt(data['pullExecutionDirection' + side])
    : 0
  const score3 = pullExecutionTiming ? parseInt(pullExecutionTiming) : 0

  let score =
    ((score1 + score2 + score3 - minScore) * 100) / (maxScore - minScore)

  score = score === 0 ? 5 : Math.round(score)
  return score
}

const framesPullTimingScoreSide = (data, side) => {
  let score = 0
  let count = parseInt(data['framesOnSupportAfterFalling' + side])

  if (data.fps === 30) {
    count = count * 2
  }
  if (data.fps === 120) {
    count = count / 2
  }
  //for 60fps standard
  if (count === 0) {
    score = 5
  }
  if (count === 1) {
    score = 4
  }
  if (count === 2) {
    score = 3
  }
  if (count === 3) {
    score = 2
  }
  if (count === 4) {
    score = 1
  }
  if (count >= 5) {
    score = 0
  }

  return score
}

export const getFinalScore = (criteria, analysisData) => {
  let score = analysisData[criteria]
  if (criteria.includes('framesOnSupportAfterFalling')) {
    let count = score // facilite la compréhension et logique
    score = count === 0 ? 3 : [1, 2].includes(count) ? 2 : /*1*/ 1
  }
  return score
}
export const computeGlobalScore = (poseScore, fallScore, pullScore) => {
  return (poseScore + fallScore + pullScore) / 3
}
export const computeCadence = (analysisData) => {
  const { cadenceMark0, cadenceMark3, cadenceMark4 } = analysisData

  let cadence = cadenceMark4
    ? (240 / (cadenceMark4 - cadenceMark0)) * 1000
    : (180 / (cadenceMark3 - cadenceMark0)) * 1000

  cadence = Math.round(cadence)
  return cadence
}

export const generateTextKeyForProperty = (prop, analysisData, currentSide) => {
  if (prop.indexOf('Intro') !== -1) {
    return [{ key: 'analysis.text.' + prop, side: null }]
  }
  if (prop.indexOf('nl') !== -1) {
    return [{ key: 'analysis.text.nl', side: null }]
  }
  if (prop.indexOf('yourExecution') !== -1) {
    return [{ key: 'analysis.text.yourExecution', side: null }]
  }

  let leftScore = getFinalScore(prop + 'L', analysisData)
  let rightScore = getFinalScore(prop + 'R', analysisData)

  /*
	let leftScore = analysisData[prop + 'L']
	let rightScore = analysisData[prop + 'R']
	if (prop.indexOf('framesOnSupportAfterFalling') !== -1) {
    leftScore = leftScore === 0 ? 3 : 2
    rightScore = rightScore === 0 ? 3 : 2
  }
*/
  if (leftScore === undefined || rightScore === undefined) {
    //on attend d'avoir les deux
    return
  }
  let textKey = {}

  if (leftScore === rightScore) {
    return [{ key: 'analysis.text.' + prop + '.' + leftScore, side: null }] //or rightScore
  }
  // attention pour l'analyse de fall le pied dont on parle est l'opposé de celui de support
  else if (prop.includes('fallExecutionTime')) {
    if (currentSide === 'left')
      textKey = {
        key: 'analysis.text.' + prop + '.side.' + leftScore,
        side: 'right',
      }
    else
      textKey = {
        key: 'analysis.text.' + prop + '.side.' + rightScore,
        side: 'left',
      }
  }

  //     { key: 'analysis.text.' + prop + '.side', side: null },
  else if (currentSide === 'left')
    textKey = {
      key: 'analysis.text.' + prop + '.side.' + leftScore,
      side: 'left',
    }
  else
    textKey = {
      key: 'analysis.text.' + prop + '.side.' + rightScore,
      side: 'right',
    }

  return [textKey]
}
export const generateAnalysisText = (analysisData) => {
  const structure = {
    pose: [
      'poseIntro',
      'yourExecution',
      // 'framesToPose',
      'poseExecutionLanding',
      'poseExecutionKnee',
      'poseExecutionVertical',
    ],
    fall: [
      'fallIntro',
      'yourExecution',
      'fallExecutionAlignment',
      'fallExecutionTime',
    ],
    pull: [
      'pullIntro',
      'yourExecution',
      'framesOnSupportAfterFalling',
      'pullExecutionLegBent',
      'pullExecutionDirection',
    ],
  }

  Object.keys(structure).map((crit) => {
    let analysis = []
    structure[crit].map((prop) => {
      let propTextKey = generateTextKeyForProperty(prop, analysisData)
      if (propTextKey) {
        analysis = analysis.concat(
          generateTextKeyForProperty(prop, analysisData)
        )
      }
      return analysis
    })
    structure[crit] = analysis
    return ''
  })
  return structure
}

/*
progressTracker = (progress) => {
    const { loaded, total } = progress

    let uploadPct = Math.round((loaded / total) * 100) - 1
    uploadPct = uploadPct < 0 ? 0 : uploadPct
   // this.setState({ progress: { loaded, total }, uploadPct })
  }
*/
export const handleUpload = async ({
  blob,
  fileName,
  setState,
  progressTracker,
}) => {
  //let file = this.state.selectedFile

  let fileType = 'multipart/form-data'
  if (!fileName) return

  setState({ step: 'uploading' })
  const response = await getS3Credentials({
    fileType,
    newFileName: fileName,
  })
  if (!response.ok) {
    //if error
    setState({
      isLoading: false,
      hasError: true,
      error: response,
    })
    return false
  }

  let {
    data: { uploadUrl },
  } = response

  // this.setState({ finalUrl })

  let upload = await uploadScreenshot(
    uploadUrl,
    blob,
    fileType,
    progressTracker
  )

  if (!upload.ok) {
    //if error
    setState({
      isLoading: false,
      hasError: true,
      error: response,
    })
    return false
  }
  return true
  // setState({ uploadPct: 100, step: 'done' })
}

export const criteriasToDisplayMap = {
  initialContactTime: [
    {
      key: 'poseExecutionLanding',
      prop: 'poseExecutionLandingR',
      optionsArray: [1, 2, 3],
    },
    {
      key: 'poseExecutionKnee',
      prop: 'poseExecutionKneeR',
      optionsArray: [1, 3],
    },
  ],
  inPoseTime: [
    {
      key: 'poseExecutionVertical',
      prop: 'poseExecutionVerticalR',
      optionsArray: [1, 2, 3],
    },
    {
      key: 'fallExecutionAlignment',
      prop: 'fallExecutionAlignmentR',
      optionsArray: [0, 1, 2, 3],
    },
  ],
  fallEndTime: [],
  lastContactTime: [
    {
      key: 'fallExecutionTime',
      prop: 'fallExecutionTimeR',
      optionsArray: [1, 2, 3],
    },
    {
      key: 'pullExecutionLegBent',
      prop: 'pullExecutionLegBentR',
      optionsArray: [1, 2, 3],
    },
  ],
  middleOfPullTime: [
    /*    {
      key: 'pullExecutionDirection',
      prop: 'pullExecutionDirectionR',
      optionsArray: [0, 1, 2, 3],
    },*/
  ],
  inNextPoseTime: [
    {
      key: 'pullExecutionDirection',
      prop: 'pullExecutionDirectionR',
      optionsArray: [0, 1, 2, 3],
    },
  ],

  initialContactTimeL: [
    {
      key: 'poseExecutionLanding',
      prop: 'poseExecutionLandingL',
      optionsArray: [1, 2, 3],
    },
    {
      key: 'poseExecutionKnee',
      prop: 'poseExecutionKneeL',
      optionsArray: [1, 3],
    },
  ],
  inPoseTimeL: [
    {
      key: 'poseExecutionVertical',
      prop: 'poseExecutionVerticalL',
      optionsArray: [1, 2, 3],
    },
    {
      key: 'fallExecutionAlignment',
      prop: 'fallExecutionAlignmentL',
      optionsArray: [0, 1, 2, 3],
    },
  ],
  fallEndTimeL: [],
  lastContactTimeL: [
    {
      key: 'fallExecutionTime',
      prop: 'fallExecutionTimeL',
      optionsArray: [1, 2, 3],
    },
    {
      key: 'pullExecutionLegBent',
      prop: 'pullExecutionLegBentL',
      optionsArray: [1, 2, 3],
    },
  ],
  middleOfPullTimeL: [
    /* {
      key: 'pullExecutionDirection',
      prop: 'pullExecutionDirectionL',
      optionsArray: [0, 1, 2, 3],
    },*/
  ],

  inNextPoseTimeL: [
    {
      key: 'pullExecutionDirection',
      prop: 'pullExecutionDirectionL',
      optionsArray: [0, 1, 2, 3],
    },
  ],
}

export const detailedAnalysisCriteriaMap = {
  initialContactTime: ['poseExecutionLanding', 'poseExecutionKnee'],
  inPoseTime: ['fallExecutionAlignment', 'poseExecutionVertical'],
  fallEndTime: ['fallExecutionTime'],
  lastContactTime: ['framesOnSupportAfterFalling', 'pullExecutionLegBent'],
  middleOfPullTime: ['pullExecutionDirection'],
}

export const scoreFromDataFunctionMap = {
  poseExecutionLanding: ({ distance }) =>
    distance <= 0.2 ? 3 : distance <= 0.308 ? 2 : 1,

  poseExecutionKnee: ({ angle }) => (angle <= 175 /*2°  */ ? 3 : 1),

  poseExecutionVertical: ({ distance }) =>
    distance <= 0.4 ? 3 : distance <= 1.5 ? 2 : 1,
  fallExecutionAlignment: ({ distance }) =>
    distance <= 0.7 ? 3 : distance <= 1.12 ? 2 : 1,

  fallExecutionTime: ({ angle, distance }) => false, // calculé par frames falling/frames from pose

  pullExecutionLegBent: ({ angle }) =>
    angle <= 160 ? 3 : angle <= 165 /*8° */ ? 2 : 1,

  pullExecutionDirection: ({ distance }) =>
    distance <= 4 ? 3 : distance <= 7.5 ? 2 : distance <= 11 ? 1 : 0,
}

export const checkAnalysisFieldsAndPointsComplete = (analysisData) => {
  let missingFields = []
  fieldsToCheck.forEach((field) => {
    if (!analysisData[field] && analysisData[field] !== 0) {
      if (['cadenceMark3', 'cadenceMark4'].includes(field)) {
        // si au moins un des deux est rempli c'est ok

        if (!analysisData['cadenceMark3'] && !analysisData['cadenceMark4'])
          missingFields = [...missingFields, field]
      } else {
        missingFields = [...missingFields, field]
      }
    }
  })

  let { automaticScoresData } = analysisData
  let missingPoints = []

  let mandatoryPoints = drawingsMandatoryPoints

  Object.keys(mandatoryPoints).forEach((fieldTime) => {
    let pointsToCheckNames = mandatoryPoints[fieldTime]
    let points = automaticScoresData?.bodyPoints?.[fieldTime]

    pointsToCheckNames.forEach((pointName) => {
      if (!points || !Object.keys(points)?.includes(pointName)) {
        missingPoints = [...missingPoints, fieldTime + ' - ' + pointName]
      }
    })
  })
  return { missingFields, missingPoints }
}

export const checkNewFormatComplete = (analysisData) => {
  let { missingFields, missingPoints } =
    checkAnalysisFieldsAndPointsComplete(analysisData)

  return missingFields.length === 0 && missingPoints.length === 0
}
const fieldsToCheck = [
  ...logFields,
  'cue',
  'poseExecutionLandingR',
  'poseExecutionLandingL',
  'poseExecutionKneeR',
  'poseExecutionKneeL',
  'fallExecutionAlignmentR',
  'fallExecutionAlignmentL',
  'fallExecutionTimeR',
  'fallExecutionTimeL',
  'pullExecutionLegBentR',
  'pullExecutionLegBentL',
  'pullExecutionDirectionR',
  'pullExecutionDirectionL',
]
