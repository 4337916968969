import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import { withPolyglot } from '../vendor/polyglot-react'

import ReactPlayer from 'react-player'

class SessionElement extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      playing: true,
      loading: true,
      hiddenVideo: false,
    }
  }

  togglePlay = () => {
    this.setState({ playing: !this.state.playing })
  }
  setIsPlaying = (isPlaying) => {
    this.setState({ playing: isPlaying })
  }

  reloadVideo = () => {
    //this.setState({ hiddenVideo: true })
    //this.props.history.go(0)
    const { activeIndex } = this.props
    window.localStorage.setItem('activeIndex', activeIndex)
    window.location.reload()
  }

  render() {
    const { element, activeIndex, drillIndex } = this.props

    element.id = element.id || element._id

    return (
      <Fragment>
        <style>
          {`
       .overlay-container {
				position: relative;
				width: 100%;
			}
				.overlay {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: opacity 13.25s ease-in-out;
					-moz-transition: opacity 13.25s ease-in-out;
					-webkit-transition: opacity 13.25s ease-in-out;
					transition: .3s ease;
					background-color: rgb(0 0 0 / 30%);

				}
				.overlay.off {
					opacity: 0;
				}

				.overlay.on {
					opacity: 0.9;
				}
				.icon-container {
					color: white;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -75%);
					-ms-transform: translate(-50%, -75%);
					text-align: center;

				}
				.icon-container span
				{
				   padding:10px;
				}


        `}
        </style>
        {element.video && (
          <Fragment>
            <div className="overlay-container">
              <ReactPlayer
                url={'https://stream.mux.com/' + element.video + '/low.mp4'}
                playing={this.state.playing && drillIndex === activeIndex}
                onClick={this.togglePlay}
                controls={false}
                muted
                volume={0}
                playsinline
                loop={true}
                width="100%"
                height="100%"
                className="react-player"
                onBuffer={() => this.setState({ loading: true })}
                onBufferEnd={() => this.setState({ loading: false })}
                //  onStart={() => this.setState({ loading: false })}
                onEnded={() => {
                  this.setState({ playing: false })
                }}
                config={{
                  file: {
                    attributes: {
                      poster:
                        'https://image.mux.com/' +
                        element.video +
                        '/thumbnail.jpg?time=0&width=500',
                    },
                  },
                }}
              />
              <div
                className={this.state.playing ? 'overlay off' : 'overlay on'}
                {...(this.state.playing && {
                  onClick: () => this.setIsPlaying(false),
                })}
              >
                <div href="#" className="icon-container">
                  <span onClick={() => this.setIsPlaying(true)}>
                    <Icon name="play" size="big" />
                  </span>
                  <span onClick={() => this.reloadVideo()}>
                    <Icon name="sync" size="big" />
                  </span>
                </div>
              </div>

              {this.state.loading && (
                <div className={' overlay ui active centered  loader'}></div>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
SessionElement.propTypes = {
  element: PropTypes.object,
  drillIndex: PropTypes.number,
  activeIndex: PropTypes.number,
}

SessionElement = withPolyglot()(SessionElement)
export default SessionElement
