import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Header, Icon, Image } from 'semantic-ui-react'
import {
  getMe,
  getNextClass,
  getSubscriptionStatus,
  setReadNotification,
  getUnreadNotificationsForMe,
  getMyAnalyses,
  getShoePrescriptions,
} from '../services/Api'
import { Config } from '../config'
import { filter } from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Footer from '../components/Footer'
import { withPolyglot } from '../vendor/polyglot-react'
import AppError from '../components/AppError'
import ReactGA from 'react-ga'
import avatar from '../images/avatar.png'
import { Link, Redirect } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import SectionCard from '../components/SectionCard'
import GetAnalysis from './GetAnalysis'
import AppCard from '../components/AppCard'
import HomeButtons from '../components/HomeButtons'
import GetPremium from './GetPremium'

import MediaQuery from 'react-responsive'
import shoeShopLogo from '../images/shoe_shop.png'

const { translations } = Config

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      hasError: 0,
      error: false,
      isLoading: true,

      // isLoading: true,
      showHeader: true,

      myNextClasses: [],
      myNextClass: false,
      visible: false,
      subscriptionStatus: null,
    }
  }

  async componentDidMount() {
    const [
      user,
      classes,
      subscriptionStatus,
      unreadNotifications,
      analysis,
      shoesPrescriptionsCount,
    ] = await Promise.all([
      this.getCurrentUser(),
      this.call_getNextClass(),
      this.call_getSubscriptionStatus(),
      this.call_getUnreadNotificationsForMe(),
      this.call_getMyAnalyses(),
      this.call_getShoePrescription(),
    ])

    const { polyglot } = this.props
    const currentLocale = polyglot.locale()

    if (user) {
      //  if (user.user.role === 'coach') this.props.history.push('/analyses/todo')

      const userLocale = user.user.locale

      if (currentLocale !== userLocale) {
        this.setState({
          isLoading: true,
        })
        polyglot.locale(currentLocale)
        polyglot.replace(translations[currentLocale])
        window.localStorage.setItem('RWJlocale', userLocale)
        window.location.reload()
      }

      if (!window.localStorage.getItem('RWJlocale') !== userLocale) {
        window.localStorage.setItem('RWJlocale', userLocale)
      }

      user.user.picture =
        user.user.picture && user.user.picture.indexOf('identicon') === -1
          ? user.user.picture
          : avatar

      sessionStorage.setItem(
        'RWJSubscriptionStatus',
        JSON.stringify(subscriptionStatus)
      )
      let focus = analysis?.focus
      let cue = analysis?.cue
      this.setState(
        Object.assign(user, classes, {
          hasError: false,
          isLoading: false,
          visible: true,
          focus,
          cue,
          subscriptionStatus,
          unreadNotifications,
          shoesPrescriptionsCount,
        })
      )
    }

    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  async call_getSubscriptionStatus() {
    var response = await getSubscriptionStatus()
    if (response.ok && response.data) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }
  async call_getUnreadNotificationsForMe() {
    var response = await getUnreadNotificationsForMe()
    if (response.ok && response.data) {
      var data = response.data

      return data.rows
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  async call_getNextClass() {
    var response = await getNextClass()
    if (response.ok && response.data) {
      var myNextClasses = response.data.rows
      myNextClasses = filter(myNextClasses, function (o) {
        if (!o.trainingLog || o.trainingLog.done === false) {
          return true
        }
        return false
      })
      // store the new state object in the component's state
      return {
        myNextClasses: myNextClasses,
        myNextClass: myNextClasses[0],
      }
    } else {
      return false
    }
  }

  async getCurrentUser() {
    const response = await getMe()
    if (response.ok) {
      const data = response.data
      const user = {
        firstName: data.firstName,
        picture: data.picture,
        updatedAt: data.updatedAt,
        homeButtons: data.homeButtons,
        isVendor: data.isVendor,
        role: data.role,
        locale: data.locale,
      }
      window.localStorage.setItem('RWJFirstName', data.firstName)
      window.localStorage.setItem('RWJPicture', data.picture)

      return {
        user,
      }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  async call_getMyAnalyses() {
    let response = await getMyAnalyses({ limit: 1, fields: 'focus, cue' })
    if (response.ok) {
      return response.data[0] || {}
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  async call_getShoePrescription() {
    let countOnly = true
    let response = await getShoePrescriptions(countOnly)
    if (response.ok) {
      return response.data.count
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  async call_setReadNotification(id) {
    var response = await setReadNotification(id)
    this.setState({ deleting: id })
    if (response.ok && response.data) {
      let { unreadNotifications } = this.state

      unreadNotifications = unreadNotifications.filter((x) => x.id !== id)

      this.setState({ deleting: null, unreadNotifications })
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response,
      })
      return false
    }
  }

  render() {
    const { polyglot, history } = this.props
    const {
      subscriptionStatus,
      user,
      unreadNotifications,
      focus,
      cue,
      shoesPrescriptionsCount,
    } = this.state

    const sections = [
      {
        category: 'main_welcome',
        content: [
          {
            key: 'welcome',
            titleOverride: (
              <>
                {polyglot.t(`sections.welcome.title`, {
                  firstname: user?.firstName,
                })}
                <Link to="/profile">
                  <Image
                    src={
                      user?.picture +
                      (!user?.picture.startsWith('data:image')
                        ? '?v=' + user?.updatedAt
                        : '')
                    }
                    circular
                    className={this.state.isLoading ? 'hidden' : 'visible'}
                    style={{
                      margin: '0 auto',
                      display: 'block',
                      width: 50,
                      backgroundColor: 'black',
                    }}
                  />
                </Link>
              </>
            ),
            titleStyleOverride: {
              textTransform: 'none',
              fontSize: '1.6rem',
              position: 'absolute',
              bottom: -25,
              top: 'initial	',
              width: '90%',
              textAlign: 'center',
            },
          },
        ],
      },
      {
        category: 'call_to_premium',
      },
      {
        category: 'call_to_action',
      },
      {
        category: 'notifications',
      },
      {
        category: 'shoes_prescription',
      },
      /*   {
        category: 'coach_cue',
      },*/
      {
        category: 'home_buttons',
      },
      {
        category: 'main',
        content: [
          {
            key: 'technique',
            useFocus: true,
          },
        ],
      },
      {
        category: 'main_selfcare',
        content: [
          {
            key: 'selfcare',
          },
        ],
      },

      {
        category: 'main_library',
        content: [{ key: 'library' }],
      },
    ]

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page Home">
        {user?.role === 'coach' && (
          <MediaQuery minWidth={1224}>
            <Redirect to="/analyses/todo" />
          </MediaQuery>
        )}
        <Grid style={{ minHeight: '90vh', marginTop: '0px' }} relaxed>
          <Grid.Row stretched style={{ paddingTop: '0px' }}>
            <Grid.Column>
              {sections.length !== 0 &&
                sections.map((plan) =>
                  plan.category === 'call_to_premium'
                    ? user?.role === 'user' && (
                        <GetPremium
                          key={plan.category}
                          history={history}
                          subscriptionStatus={subscriptionStatus}
                        />
                      )
                    : plan.category === 'call_to_action'
                    ? user?.role === 'user' && (
                        <GetAnalysis
                          key={plan.category}
                          history={history}
                          subscriptionStatus={subscriptionStatus}
                        />
                      )
                    : plan.category === 'home_buttons'
                    ? user.homeButtons.length !== 0 && (
                        <AppCard heightOverride="auto" key={plan.category}>
                          <HomeButtons
                            homeButtons={user.homeButtons}
                            history={history}
                          />
                        </AppCard>
                      )
                    : plan.category === 'coach_cue'
                    ? polyglot.has(`cues.${cue}`) && (
                        <AppCard heightOverride={'auto'}>
                          <Header
                            style={{
                              marginTop: 0,
                              fontSize: '1.2em',
                              fontWeight: 600,
                            }}
                          >
                            {polyglot.t('cues.label')}
                          </Header>
                          <Icon name="quote left" />
                          {polyglot.t(`cues.${cue}`)}
                          <Icon name="quote right" />
                        </AppCard>
                      )
                    : plan.category === 'shoes_prescription'
                    ? shoesPrescriptionsCount !== 0 && (
                        <AppCard
                          key={plan.category}
                          style={{
                            backgroundColor: 'red',
                            backgroundImage: 'none',
                            maxHeight: '100px',
                          }}
                          heightOverride={'100px'}
                          onClick={() => history.push('/shoesPrescriptions')}
                        >
                          <Image
                            src={shoeShopLogo}
                            size="small"
                            floated="left"
                          />

                          <Header
                            style={{
                              marginTop: 0,
                              fontSize: '1.2em',
                              fontWeight: 600,
                            }}
                          >
                            {polyglot.t('home.shoesPrescription')}
                          </Header>
                        </AppCard>
                      )
                    : plan.category === 'notifications'
                    ? unreadNotifications.length !== 0 &&
                      unreadNotifications.map((notification) => (
                        <AppCard
                          key={notification.id}
                          heightOverride="auto"
                          header={
                            <Icon
                              name={
                                this.state.deleting !== notification.id
                                  ? 'close'
                                  : 'circle notch'
                              }
                              style={{ position: 'absolute', right: 5, top: 5 }}
                              onClick={() =>
                                this.call_setReadNotification(notification.id)
                              }
                            />
                          }
                        >
                          <Header as="h3">
                            {notification.title.indexOf(
                              'user.notification.'
                            ) !== -1
                              ? polyglot.t(notification.title, {
                                  firstName:
                                    localStorage.getItem('RWJFirstName'),
                                })
                              : notification.title}
                          </Header>

                          {notification.message.indexOf(
                            'user.notification.'
                          ) !== -1
                            ? polyglot.has(notification.message)
                              ? polyglot.t(notification.message, {
                                  firstName:
                                    localStorage.getItem('RWJFirstName'),
                                })
                              : ''
                            : notification.message}
                        </AppCard>
                      ))
                    : plan.content.map((x) => (
                        <SectionCard
                          onHome
                          key={x.key}
                          cardData={x}
                          history={this.props.history}
                          focus={x.useFocus ? focus : null}
                        />
                      ))
                )}
              <Footer />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

Home.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
}
Home = withPolyglot()(Home)
export default Home
