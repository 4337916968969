import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Container } from 'semantic-ui-react'
import {
  getMyAthletes,
  getAllAthletes,
  getFaults,
  //  getMyClues,
  updateUserFault,
  // updateUserClue,
  updateUserIsActive,
} from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { withPolyglot } from '../vendor/polyglot-react'
import AdviceEdit from '../components/AdviceEdit'

import AppError from '../components/AppError'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'

//const homeButtons = [  'todayTraining',  'userTrainings',  'bookingUrl',  'userPerfs',  'paceConverter']

class Athletes extends Component {
  constructor(props) {
    super(props)
    //const { polyglot } = this.props
    this.state = {
      faults: [],
      clues: [],
      tableData: [],
      tableColumns: [
        {
          dataField: 'picture',
          text: 'Photo',
          editable: false,
          formatter: (cell) => (
            <img alt="" style={{ height: '40px' }} src={cell} />
          ),
        },
        {
          dataField: 'name',
          text: 'Name',
          editable: false,
          formatter: (cell, row) => <a href={'/athlete/' + row.id}> {cell} </a>,
        },
        {
          dataField: 'isActive',
          text: 'isActive',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red',
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
        },
        /*
				{
					dataField: "faults",
					text: "Faults",
					formatter: (cell, row) =>
						Array.isArray(cell) && cell[0] && cell[0].fault
							? polyglot.t("faults." + cell[0].fault.code)
							: this.state.faults[cell],
					editor: {
						type: Type.SELECT,
						options: []
					}
				},
				*/
        {
          dataField: 'homeButtons',
          text: 'Home Buttons',
          //formatter: (cell, row) => ( Array.isArray(cell) && cell[0] && cell[0].clue) ?  cell[0].clue.code : (cell) => this.state.clues.find(v => v['value'] === cell)["label"],
          formatter: (cell) =>
            Array.isArray(cell) && !!cell.length ? cell.join(', ') : '',
          editor: {
            type: Type.SELECT,
            options: [],
          },
        },
        /* {
          dataField: 'clues',
          text: 'Clues',
          //formatter: (cell, row) => ( Array.isArray(cell) && cell[0] && cell[0].clue) ?  cell[0].clue.code : (cell) => this.state.clues.find(v => v['value'] === cell)["label"],
          formatter: (cell) =>
            Array.isArray(cell) && cell[0] && cell[0].clue
              ? cell[0].clue.code
              : this.state.clues[cell],
          editor: {
            type: Type.SELECT,
            options: [],
          },
        },*/
        {
          dataField: 'lastAdvice',
          text: 'Advice',
          editable: false,
          formatter: (cell, row) => {
            return (
              <Fragment>
                <AdviceEdit
                  userId={row.id}
                  cellContent={cell && cell.message ? cell.message : ''}
                  cellAdviceId={cell && cell._id ? cell._id : ''}
                  mode="edit"
                />
              </Fragment>
            )
          },
        },
      ],
      hasError: 0,
      error: false,
      isLoading: true,
    }
  }
  async callGetFaults() {
    const response = await getFaults()
    if (response.ok) {
      const data = response.data.rows
      const { polyglot } = this.props
      const data_translated = data.map((obj) => {
        var rObj = {}
        rObj['id'] = obj.id
        rObj['code'] = polyglot.t('faults.' + obj.code)
        return rObj
      })

      const faultsMap = {}
      const result = data_translated.map((x) => {
        var rObj = {}
        rObj['value'] = x.id
        rObj['label'] = x.code
        faultsMap[x.id] = x.code
        return rObj
      })

      const newState = Object.assign({}, this.state, {
        faults: faultsMap,
      })
      newState.tableColumns.forEach((field) => {
        if (field.dataField === 'faults') {
          field.editor.options = result
        }
      })
      this.setState(newState)
    }
  }
  /*
  async callGetMyClues() {
    const response = await getMyClues()
    if (response.ok) {
      const data = response.data.rows
      const cluesMap = {}
      const result = data.map((x) => {
        var rObj = {}
        rObj['value'] = x.id
        rObj['label'] = x.code
        cluesMap[x.id] = x.code
        return rObj
      })
      const newState = Object.assign({}, this.state, {
        clues: cluesMap,
      })
      newState.tableColumns.forEach((field) => {
        if (field.dataField === 'clues') {
          field.editor.options = result
        }
      })
      this.setState(newState)
    }
  }*/

  async getData() {
    var response

    if (localStorage.getItem('RWJRole') === 'admin') {
      response = await getAllAthletes()
    } else {
      response = await getMyAthletes()
    }

    if (response.ok) {
      const data = response.data.rows
      data.sort(function (a, b) {
        if (!a.name && !b.name) return 0
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })

      const newState = Object.assign({}, this.state, {
        tableData: data,
        isLoading: false,
        hasError: false,
      })
      // store the new state object in the component's state
      this.setState(newState)
      this.setState({ redirectToReferrer: true })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response,
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  componentDidMount() {
    this.getData()
    this.callGetFaults()
    // this.callGetMyClues()
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }

  handleCellSave(dataField, userId, value) {
    switch (dataField) {
      case 'faults':
        updateUserFault(userId, value)
        break
      /*  case 'clues':
        updateUserClue(userId, value)
        break*/
      case 'isActive':
        updateUserIsActive(userId, value)
        break
      default:
        break
    }
  }
  render() {
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <style>{`
						 .react-bootstrap-table{
							margin-top:80px;
							text-align:left;
							background-color:#fff
						 }

						`}</style>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: () => {},
                    beforeSaveCell: (newValue, row, column) => {
                      this.handleCellSave(column.dataField, row.id, newValue)
                    },
                    afterSaveCell: () => {},
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

Athletes.propTypes = {
  polyglot: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

Athletes = withPolyglot()(Athletes)
export default Athletes
