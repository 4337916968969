import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Icon, Label } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import { withPolyglot } from '../vendor/polyglot-react'

import back from '../images/sections/back.jpg'
import cadence from '../images/sections/cadence.jpg'
import fall from '../images/sections/fall.jpg'
import flexibility from '../images/sections/flexibility.jpg'
import focus from '../images/sections/focus.jpg'
import learn from '../images/sections/learn.jpg'
import library from '../images/sections/library.jpg'
import long from '../images/sections/long.jpg'
import lowerleg from '../images/sections/lowerleg.jpg'
import pose from '../images/sections/pose.jpg'
import pull from '../images/sections/pull.jpg'
import selfcare from '../images/sections/selfcare.jpg'
import short from '../images/sections/short.jpg'
import speed from '../images/sections/speed.jpg'
import strength from '../images/sections/strength.jpg'
import technique from '../images/sections/technique.jpg'
import transition from '../images/sections/transition.jpg'
import upperleg from '../images/sections/upperleg.jpg'
import warmup from '../images/sections/warmup.jpg'
import welcome from '../images/sections/welcome.jpg'
import work from '../images/sections/work.jpg'
import error from '../images/error.jpg'
import fivek from '../images/sections/5k.jpg'
import tenk from '../images/sections/10k.jpg'
import half from '../images/sections/half.jpg'
import marathon from '../images/sections/marathon.jpg'

import AppCard from './AppCard'

import poseIcon from '../images/pose.png'
import fallIcon from '../images/fall.png'
import pullIcon from '../images/pull.png'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import AppConfig from '../AppConfig'

const ImgHeaderMarginTop = {
  learn: -10,
  cadence: -10,
  speed: -30,
  transition: -80,
  short: -10,
  long: 0,
  focus: 0,
  warmup: -60,
  pose: -50,
  fall: -40,
  pull: -40,
  strength: -30,
  flexibility: 0,
  selfcare_lowerleg: -10,
  selfcare_upperleg: -50,
  selfcare_back: -20,
}

const cardImages = {
  welcome,
  technique,
  selfcare,
  library,
  warmup,
  learn,
  cadence,
  transition,
  speed,
  focus,
  pose,
  fall,
  pull,
  strength,
  flexibility,
  short,
  long,
  'selfcare_lowerleg': lowerleg,
  'selfcare_upperleg': upperleg,
  'selfcare_back': back,
  work,
  error,
  '5k': fivek,
  '10k': tenk,
  half,
  marathon,
  'custom': '/images/custom.jpg',
}

class SectionCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      hasError: 0,
      error: false,
      isLoading: true,
      isSwiping: false,
      isDrawerOpened: false,
    }
  }
  toggleDrawer() {
    let isDrawerOpened = !this.state.isDrawerOpened
    this.setState({ isDrawerOpened })

    let closedDrawers =
      JSON.parse(localStorage.getItem('RWJClosedDrawers')) || []

    if (!isDrawerOpened) {
      //if current drawer is being closed add it to the closed drawers
      closedDrawers.push(this.props.cardData.key)
    } else {
      closedDrawers = closedDrawers.filter((x) => x !== this.props.cardData.key)
    }
    localStorage.setItem('RWJClosedDrawers', JSON.stringify(closedDrawers))
  }
  componentDidMount() {
    let subscriptionStatus = sessionStorage.getItem('RWJSubscriptionStatus')
    subscriptionStatus = subscriptionStatus
      ? JSON.parse(subscriptionStatus)
      : {}
    this.setState({ subscriptionStatus })

    if (!this.props.asDrawer) {
      return
    }
    let closedDrawers =
      JSON.parse(localStorage.getItem('RWJClosedDrawers')) || []

    if (closedDrawers.includes(this.props.cardData.key)) {
      this.setState({ isDrawerOpened: false })
    } else {
      this.setState({ isDrawerOpened: true })
    }
  }

  render() {
    const { polyglot, cardData, asHeader, onHome, asDrawer, focus } = this.props
    const { isDrawerOpened, subscriptionStatus } = this.state
    const has_premium = subscriptionStatus?.hasValidSubscription
    const has_analysis = subscriptionStatus?.lastAnalysis?._id
    const cardKey = cardData.key
    let img =
      focus === 'fall'
        ? fallIcon
        : focus === 'pull'
        ? pullIcon
        : focus === 'pose'
        ? poseIcon
        : poseIcon

    let backgroundImage = Object.prototype.hasOwnProperty.call(
      cardImages,
      cardKey
    )
      ? `url(${cardImages[cardKey]})`
      : `url(${cardImages['custom']})`

    /*let backgroundColor = !cardImages.hasOwnProperty(cardKey)
      ? stringToColor(cardKey)
      : 'none'
*/

    let cardTo = Object.prototype.hasOwnProperty.call(cardData, 'to')
      ? cardData.to
      : AppConfig[cardKey]?.to

    const { premiumRequired, analysisRequired, analysisRecommended } =
      AppConfig[cardKey] || {}

    const analysisRequiredIcon = (
      <Label
        color="red"
        circular
        size="tiny"
        className={
          'analysis-tag card-float-adjust-analysis' +
          (premiumRequired ? '-premium' : '')
        }
        style={{ marginTop: -2, marginRight: 5 }}
        data-testid="analysis-required"
      >
        <Icon name="chart bar" size="large" style={{ margin: 1 }} />
        {/*polyglot.t(`sections.analysis.required`)*/}
      </Label>
    )

    const analysisRecommendedIcon = (
      <Label
        color="orange"
        circular
        size="tiny"
        className={
          'analysis-tag card-float-adjust-recommended' +
          (premiumRequired ? '-premium' : '')
        }
        style={{ marginTop: -2, marginRight: 5 }}
        data-testid="analysis-recommended"
      >
        <Icon name="chart bar" size="large" style={{ margin: 1 }} />
      </Label>
    )

    return (
      <>
        <Card
          key={cardKey}
          fluid
          className={'program-card '}
          {...(isMobile &&
            cardTo && {
              onClick: () =>
                this.props.history.push({
                  pathname: cardTo,
                  state: { focus },
                }),
            })}
          style={{
            backgroundPositionY: asHeader
              ? ImgHeaderMarginTop[cardKey] || 0
              : '50%',
            backgroundImage,
            //  backgroundColor,
            height: 300,
            maxHeight: onHome ? 150 : asHeader ? 180 : 200,
          }}
        >
          <style>
            {`

					.ui.small.label{
					padding: .4em .33em .3em .33em;
					font-size:0.8em;
					min-height: 2em;
					margin-top:2px;
					}
					.ui.card.program-card > .content > .header:not(.ui).card-header{
						position: relative;
						margin-top:20px;
						top: 40%;
						transform: translateY(-50%)
					}


				`}
          </style>

          <Card.Content className="overlay" style={{ textAlign: 'left' }}>
            <Card.Header
              {...(!isMobile &&
                cardTo && {
                  onClick: () =>
                    this.props.history.push({
                      pathname: cardTo,
                      state: { focus },
                    }),
                })}
              data-testid={cardKey}
              className={'card-header'}
              style={
                cardTo
                  ? {
                      cursor: 'pointer',
                    }
                  : {
                      ...cardData.titleStyleOverride,
                    }
              }
            >
              {cardData.titleOverride
                ? cardData.titleOverride
                : polyglot.has(`sections.${cardKey}.title`)
                ? polyglot.t(`sections.${cardKey}.title`)
                : cardKey}
              <div style={{ marginTop: 3 }}>
                {premiumRequired && !has_premium && (
                  <Label
                    color="blue"
                    size="small"
                    className={'premium-tag'}
                    data-testid="premium-tag"
                  >
                    {polyglot.t(`sections.premium.required`)}
                  </Label>
                )}
                {analysisRequired && !has_analysis && analysisRequiredIcon}
                {analysisRecommended &&
                  !has_analysis &&
                  analysisRecommendedIcon}
              </div>
            </Card.Header>
            {/**
          <Card.Meta>
            {cardData.descriptionOverride ||
              polyglot.t(`sections.${cardKey}.description`)}
          </Card.Meta>

           */}
            <Card.Meta
              style={{ position: 'absolute', bottom: 10, color: '#f5f5f5' }}
            >
              <span style={{ marginRight: 5 }}>
                {polyglot.has(`sections.${cardKey}.sessions`) &&
                  `${polyglot.t(`sections.${cardKey}.sessions`)} ${polyglot.t(
                    `sections.sessions`
                  )}`}
              </span>
              {focus && (
                <span>
                  <img
                    src={img}
                    alt=""
                    style={{
                      width: 22,
                      border: '2px solid white',
                      borderRadius: 500,
                      padding: 2,
                      marginRight: 2,
                      marginLeft: 2,
                    }}
                  />{' '}
                  Focus :
                  <span
                    style={{ textTransform: 'capitalize' }}
                    data-testid={`card-focus-${focus}`}
                  >
                    {polyglot.t(`focus.${focus}`)}
                  </span>
                </span>
              )}
            </Card.Meta>
          </Card.Content>
        </Card>
        {asDrawer && polyglot.has(`sections.${cardKey}.description`) && (
          <div onClick={() => this.toggleDrawer()}>
            <AppCard
              heightOverride="auto"
              style={{
                marginTop: -30,
                marginBottom: -10,
                paddingBottom: 0,
                zIndex: -1,
                textAlign: 'left',
              }}
              contentStyle={{ textAlign: 'left' }}
            >
              <SlideDown className={'my-dropdown-slidedown'}>
                {isDrawerOpened ? (
                  <div style={{ marginTop: 10, fontSize: '1.1em' }}>
                    {polyglot.t(`sections.${cardKey}.description`)}
                    <div style={{ marginTop: 5 }} className="analysis-tag ">
                      {(analysisRecommended || analysisRequired) && (
                        <span>
                          <span style={{ float: 'left', marginTop: 0 }}>
                            {analysisRecommended
                              ? analysisRecommendedIcon
                              : analysisRequiredIcon}
                          </span>
                          <span
                            style={{
                              fontSize: '0.8em',
                              fontStyle: 'italic',
                              color: '#b7b7b7',
                              lineHeight: '1.0em',
                              display: 'block',
                            }}
                          >
                            {polyglot.t(
                              `sections.analysis.explanation.${
                                analysisRecommended ? 'recommended' : 'required'
                              }`
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                    <Divider />
                  </div>
                ) : null}
              </SlideDown>
            </AppCard>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '-1em',
                marginTop: -5,
                zIndex: -2,
                position: 'relative',
              }}
            >
              <Icon
                name={isDrawerOpened ? 'close' : 'chevron down'}
                size="large"
                style={{
                  backgroundColor: '#212121',
                  paddingTop: '0.9em',
                  marginTop: -12,
                  border: 'none',
                  boxShadow: 'none',
                  borderRadius: '500em',
                  width: '2em',
                  height: '2em',
                }}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

SectionCard.defaultProps = {
  cardData: { key: 'custom', to: '/' },
}

SectionCard.propTypes = {
  cardData: PropTypes.object,
  asDrawer: PropTypes.bool,
  asHeader: PropTypes.bool,
  polyglot: PropTypes.object,
  history: PropTypes.object,
  focus: PropTypes.string,
  onHome: PropTypes.bool,
}

SectionCard = withPolyglot()(SectionCard)
export default SectionCard
